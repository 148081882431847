import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import Dropdown from '../../../components/Dropdown';
import UnreadCount from './UnreadCount';

const ProfileMobile = (props) => {
  const {
    avatar,
    user = {},
    frontEndBellCount,
    totalUnreadCount,
    closeAside,
    t,
  } = props;
  const { first_name, last_name, email } = user;
  const hasSomeNotifications = frontEndBellCount + totalUnreadCount;
  const links = [
    {
      to: '/messenger?tab=chats',
      onClick: closeAside,
      title: (
        <LinkWithNumber number={totalUnreadCount}>
          {t.header[0].MESSAGES}
        </LinkWithNumber>
      ),
    },
    {
      to: '/messenger?tab=notifications',
      onClick: closeAside,
      title: (
        <LinkWithNumber number={frontEndBellCount}>
          {t.header[0].NOTIFICATIONS}
        </LinkWithNumber>
      ),
    },
    { to: '/settings', onClick: closeAside, title: t.header[0].MY_ACCOUNT },
    { to: '/my-network', onClick: closeAside, title: t.header[0].MY_CONTACTS },
    { to: '/my-items', onClick: closeAside, title: t.header[0].MY_ITEMS },
    {
      to: '/my-favorites',
      onClick: closeAside,
      title: t.header[0].MY_FAVORITES,
    },
    {
      onClick: () => {
        props.logout(props.login, props.userId);
        props.closeAside();
      },
      title: t.header[0].LOGOUT,
    },
  ];
  return (
    <div className='header-profile-mobile'>
      <Dropdown exact links={links}>
        <div className='header-profile-mobile__top'>
          <div className='header-profile-mobile__image header-button'>
            <img src={avatar} alt='' />
            <UnreadCount unreadCount={hasSomeNotifications} />
          </div>
          <div>
            <div className='header-profile__name'>{`${first_name} ${last_name}`}</div>
            <p className='header-profile__email' title={email}>
              {email}
            </p>
          </div>
        </div>
      </Dropdown>
    </div>
  );
};

const LinkWithNumber = ({ children, number }) => (
  <Fragment>
    {children}{' '}
    {number ? (
      <span className='pl-circle pl-circle--dark pl-circle--sm pl-circle--centered'>
        {number}
      </span>
    ) : null}
  </Fragment>
);

const mapStateToProps = (state) => ({
  t: state.language.t,
});

export default connect(mapStateToProps)(ProfileMobile);
