import React from 'react';

const Select = ({ children }) => (
  <div className='pl-select pl-select--dropdown'>
    {children}
    <div className='pl-btn-dropdown' />
  </div>
);

export default Select;
