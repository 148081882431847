import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import Progress from '../components/Progress';
import formatNumber from '../helpers/format-number';

const STATUS_DEFAULT = 0;
const STATUS_DOWNLOADING = 1;
const STATUS_COMPLETE = 2;

const initialState = {
  status: STATUS_DEFAULT,
  progress: 0,
};

export default class DownloadLink extends Component {
  static propTypes = {
    url: PropTypes.string,
    name: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    noBorder: PropTypes.bool,
  };

  static defaultProps = {
    url: 'javascript: void null',
    noBorder: false,
  };

  constructor() {
    super();

    this.state = initialState;
  }

  onLinkClick() {
    const { status, interval } = this.state;

    switch (status) {
      case STATUS_DEFAULT:
        return this.mimicDownload();

      case STATUS_DOWNLOADING:
        if (interval) {
          clearInterval(interval);
        }

        return this.setState({
          ...initialState,
        });
      default:
        return null;
    }
  }

  mimicDownload() {
    this.setState({
      status: STATUS_DOWNLOADING,
    });

    const interval = setInterval(() => {
      const { progress } = this.state;
      const newProgress = progress + 0.2 * Math.random();

      if (newProgress < 1) {
        this.setState({
          progress: newProgress,
          interval,
        });
      } else {
        clearInterval(interval);

        this.setState({
          status: STATUS_COMPLETE,
          progress: 1,
          interval: undefined,
        });
      }
    }, 400);
  }
  render() {
    const { url, name, noBorder, preview } = this.props;
    const { status, progress } = this.state;
    let size;
    try {
      size = formatNumber({
        number: Number(this.props.size),
        postfix: 'B',
        digitsAfterComma: 1,
      });
    } catch (e) {
      size = this.props.size;
    }

    let action;
    let sizeText;

    switch (status) {
      case STATUS_DEFAULT: {
        action = 'Download';
        sizeText = size;
        break;
      }

      case STATUS_DOWNLOADING: {
        const sizeFloat = parseFloat(size);
        const sizeUnits = size.match(/[A-Za-z]+/)[0];
        const downloadedVolume = (progress * sizeFloat).toFixed(1);
        sizeText = `${downloadedVolume} ${sizeUnits} / ${size}`;
        action = 'Cancel';
        break;
      }

      case STATUS_COMPLETE: {
        action = 'Open';
        sizeText = size;
        break;
      }

      default: {
        return null;
      }
    }
    if (preview === true) {
      var target = '';
    } else {
      var target = '_blank';
    }
    return (
      <Fragment>
        <a
          target={target}
          href={url}
          className={`download-link${
            noBorder ? ' download-link_no-border' : ''
          }${status === STATUS_COMPLETE ? ' download-link_complete' : ''}${
            status === STATUS_DOWNLOADING ? ' download-link_downloading' : ''
          }`}
        >
          <i className='icon-file' />
          <span className='download-link__name'>{name}</span>
          <span className='download-link__size'>{sizeText}</span>
          <span className='download-link__download'>{action}</span>
        </a>

        {status === STATUS_DOWNLOADING && (
          <Progress small progress={progress} />
        )}
      </Fragment>
    );
  }
}
