import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import Preloader from '../components/Preloader';
import languages from '../helpers/languages';

var language = languages['en'];
var common = language.common[0];
var labels = language.Offerings[0];

const mapStateToProps = (state) => {
  return {
    lan: state.language.lan,
    user: state.auth.user,
  };
};

class Discover extends React.Component {
  state = {
    isModalOpened: false
  };

  componentDidMount() {

  }

  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    labels = language.Offerings[0];

    const { showPreloader } = this.props;
    const {
      isModalOpened,
    } = this.state;
    const url =
      'https://learn.shetrades.com/local/itcacademy/setupuser.php?academyid=9&autoissuerid=2&wantsurl=/my/index.php#showdashcat';

    const satUrl =
      'https://learn.shetrades.com/enrol/index.php?id=1555';

    const attributes = { href: url };
    const satAttributes = { href: satUrl };
    return (
      <CustomizedDocumentTitle title={common.MY_DASHBOARD}>
        <div className='transition-page'>
          {showPreloader ? (
            <Preloader size='medium' relative />
          ) : (
            <div>
              {/*header content */}
              <header>
                <div className="innerheader innerheader-aboutouroffering">
                  <h1>{labels.OUR_GATEWAY_TO_BUSINESS__SHETRADESCOM}</h1>
                </div>
              </header>
              {/*header content */}
              {/*welcome text */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container-2col">
                    <div>{labels.SHETRADESCOM_IS_OUR_DIGITAL_ONE_STOP_SHOP_F}</div>
                    <div />
                  </div>
                </div>
              </div>
              {/*welcome text */}
              {/*access */}
              <div className="bgwhite">
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon bigicon-sellandbuy bigiconhide" />
                    <div className="bigicontextblock"><h2>{labels.ACCESS_THE_GLOBAL_MARKET_WITH_SELL_BUY}
                      <Link to="/offers">{common.BUY_SELL}<i className="fal fa-arrow-right" /></Link></h2>
                      <p>{labels.GET_STARTED_BY_CREATING_YOUR_ONLINE_BUSINES}</p></div>
                  </div>
                </div>
              </div>
              {/*access */}
              {/*build */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon bigicon-network bigiconhide" />
                    <div className="bigicontextblock"><h2>{labels.GROW_YOUR_NETWORK}</h2>
                      <p>{labels.ONCE_YOUVE_REGISTERED_YOUR_COMPANY_PROFILE}.</p>
                      <Link to="/publiccommunity">{labels.MORE_ABOUT_OUR_COMMUNITY} <i className="fal fa-arrow-right" /></Link>
                    </div>
                  </div>
                </div>
              </div>
              {/*build */}
              {/*free block */}
              <div className="bgdark">
                <div className="wrap">
                  <div className="blueblockbgcircles">
                    <div className="container-2col">
                      <div>
                        <h2>{labels.ITS_ALL_FREE_FOR_OUR_MEMBERS}</h2>
                        <p>{labels.ACCESS_ALL_THE_TOOLS} </p></div>
                      <Link to="/sign-up" className="btn">{common.REGISTER_OR_LOGIN}</Link>
                    </div>
                  </div>
                </div>
              </div>
              {/*free block */}
              {/*improve */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon bigicon-learn bigiconhide" />
                    <div className="bigicontextblock"><h2>Enrol on one of our many <a {...attributes} target='_blank' rel="noreferrer">e-learning courses <i className="fal fa-arrow-right" /></a></h2>
                      <p>Learn new skills and broaden your knowledge on a wide range of business and international trade related topics. From ecommerce strategies to finance, packaging and logistics, we’ve got you covered! Get learning today via online courses, webinars and a range of market tools to help you navigate the global marketplace.</p>
                    </div>
                  </div>
                </div>
              </div>
              {/*improve */}
              {/*grow */}
              <div className="bgwhite">
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon  bigicon-grow bigiconhide" />
                    <div className="bigicontextblock"><h2>Events and opportunities to grow your business</h2>
                      <p>On SheTrades.com you can find events and opportunities delivered together with our partners. Gain access to tips and strategies from leaders in logistics, sector experts and ecommerce giants, all tailored to your business needs.</p>
                      <div className="container-ll-bottomlinks">
                        <Link to="/publicevents">See all the open opportunities <i className="fal fa-arrow-right" /></Link><Link to="/">See all the upcoming events <i className="fal fa-arrow-right" /></Link>
                      </div></div>
                  </div>
                </div>
              </div>
              {/*grow */}
              {/*Peruse */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon  bigicon-policy_maker bigiconhide" />
                    <div className="bigicontextblock"><h2>For the latest data and policy updates, visit
                      <Link to="/publicoutlook">Policy <i className="fal fa-arrow-right" /></Link></h2>
                      <p>The first tool of it’s kind, SheTrades Outlook exists to make trade possible for women by promoting a more inclusive policy ecosystem. It provides comprehensive data and analysis on trade and women’s economic empowerment, and shares experiences from around the world.</p>
                    </div>
                  </div>
                </div></div>
              {/*Peruse */}
              {/*inspiration */}
              <div className="bgwhite">
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon  bigicon-stories bigiconhide" />
                    <div className="bigicontextblock"><h2>Hear from real women in our community on
                      <Link to="/publicblog">Blog <i className="fal fa-arrow-right" /></Link></h2>
                      <p>Stay up to date with SheTrades activities, projects and hubs while sharing the success of our growing community of women entrepreneurs.</p>
                    </div>
                  </div>
                </div></div>
              {/*inspiration */}
              {/*help */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon  bigicon-help bigiconhide" />
                    <div className="bigicontextblock"><h2>Find all the support you need in our
                      <Link to="/publichelpcenter">Help Center <i className="fal fa-arrow-right" /></Link></h2>
                      <p>Our help centre is well equipped with useful tools and resources to help you on your entrepreneurship journey. Access tutorials on setting up your business online, strategies to build resilience during the pandemic, guides on finding funding or reach out to our team with your questions.</p>
                    </div>
                  </div>
                </div>
              </div>
              {/*help */}
            </div>
          )}
        </div>
      </CustomizedDocumentTitle>
    );
  }
}

Discover.propTypes = {
  showPreloader: PropTypes.bool,
};

export default connect(mapStateToProps)(Discover);
