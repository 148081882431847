/**
 * Standard component for visualising multi step forms
 */

import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import queryString from 'query-string';
import { connect } from 'react-redux';

import { pageTransition } from '../constants';
import MultiStepForm from '../components/MultiStepForm';

const defaultSteps = ({ t }) => [
  { title: t.common[0].MAIN_INFORMATION, nextButton: t.common[0].NEXT },
  { title: t.common[0].DESCRIPTION, nextButton: t.common[0].SEE_PREVIEW },
  { title: t.common[0].PREVIEW, nextButton: t.common[0].PUBLISH },
];

const AfterTitle = ({ t }) => (
  <p 
  // className='gray'
  >{t.common[0].FILL_MESSAGE}</p>
);

const CreateNew = (props) => {
  const {
    t,
    location,
    renderStep,
    pageTitle,
    steps = defaultSteps({ t }),
    renderAfterTitle = AfterTitle,
    fullWidth,
    onFinish = () => {},
    history,
    beforeNext = () => true,
  } = props;
  const queryObject = queryString.parse(location.search);
  const step = Number(queryObject.step) || 1;
  const stepIndex = step - 1;
  const { title, nextButton } = steps[stepIndex];
  const prevStep = steps[stepIndex - 1];
  const nextStep = steps[stepIndex + 1];
  const makeLink = (search) =>
    `${location.pathname}?${queryString.stringify(search)}`;
  const prevLink = makeLink({ ...queryObject, step: step - 1 });
  const nextLink = makeLink({ ...queryObject, step: step + 1 });

  let { finishLink } = props;
  if (!finishLink) {
    finishLink = makeLink({ ...queryObject, step: steps.length });
  }

  return (
    <MultiStepForm>
      {(formState) => {
        const onNextClick = () => {
          if (beforeNext(step, formState)) {
            if (!nextStep) {
              onFinish(formState);
            }

            history.push(nextStep ? nextLink : finishLink);
          }
        };

        return (
          <div className='transition-page'>
            {/* <div className='innerheader innerheader-helpcenter'>
              <h1 className='h2'>{pageTitle}</h1>
            </div> */}

            {renderAfterTitle({ t })}

            {/* {steps.length > 1 && (
              <ul className='create-new-progress'>
                {steps.map(({ title }, i) => (
                  <li
                    key={i}
                    className={
                      i === stepIndex
                        ? 'current'
                        : i < stepIndex
                        ? 'completed'
                        : ''
                    }
                  >
                    {i > 0 && <span className='create-new-line' />}{' '}
                    {i < stepIndex ? (
                      <span className='create-new-index'>
                        <i className='icon-done' />
                      </span>
                    ) : (
                      <span className='create-new-index'>{i + 1}</span>
                    )}{' '}
                    <b>{title}</b>
                  </li>
                ))}
              </ul>
            )} */}

            <div className='row'>
              <div className={fullWidth ? 'col-lg-12' : 'col-lg-9'}>
                <TransitionGroup className='transition-container'>
                  <CSSTransition
                    key={step}
                    {...pageTransition}
                    onEnter={() =>
                      setTimeout(() => {
                        window.scrollTo(0, 0);
                      }, 400)
                    }
                  >
                    <div className='transition-page'>
                      <div className='create-new'>
                        {/* <div className='member-tab-title'>
                          <h3>{title}</h3>
                        </div> */}

                        {renderStep(step, formState)}

                        <div className='create-new__bottom flex'>
                          {prevStep && (
                            <div className='left'>
                              <Link className='back' to={prevLink}>
                                {t.common[0].BACK_TO} {prevStep.title}
                              </Link>
                            </div>
                          )}

                          <div className='right'>
                            <div className='buttons'>
                              <button
                                type='button'
                                className='btn'
                                onClick={onNextClick}
                              >
                                <i className='icon-arrow--small' />
                                <span className='button__text'>
                                  {nextButton}
                                </span>
                              </button>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </CSSTransition>
                </TransitionGroup>
              </div>
            </div>
          </div>
        );
      }}
    </MultiStepForm>
  );
};

const mapStateToProps = (state) => ({ t: state.language.t });

export default withRouter(connect(mapStateToProps)(CreateNew));
