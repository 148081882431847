/* eslint-disable no-unused-expressions */
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  //countries dropdown
  return {
    isMobile: state.responsive.isMobile,
    t: state.language.t,
  };
};

class TopFilter extends React.Component {
  constructor() {
    super();
  }

  componentDidMount() {
    
  }

  static propTypes = {
    modified: PropTypes.bool,
    buttonText: PropTypes.string.isRequired,
    contentStyle: PropTypes.object,
    onApply: PropTypes.func,
    onCancel: PropTypes.func,
    t: PropTypes.object,
  };

  static defaultProps = {
    modified: false,
    contentStyle: {},
    onApply: () => {},
  };

  render() {
    const {
      modified,
      buttonText,
      contentStyle,
      children,
      onCancel,
      onApply,
      isMobile,
      t,
      ...modal
    } = this.props;
    return (
      <Fragment>
            {isMobile && (
              <h4
                className='m-filter-title'
              >
                <i className='icon-arrow--small' /> {buttonText}
              </h4>
            )}
            {children}
      </Fragment>
    );
  }
}

export default connect(mapStateToProps)(TopFilter);
