import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import InputBlock from '../../components/InputBlock';
import languages from '../../helpers/languages';

const Form = (props) => {
  const currLang = languages[props.lan];

  const fields = [
    {
      name: 'password',
      placeholder: currLang.common[0].NEW_PASSWORD,
      required: true,
      type: 'password',
    },
    {
      name: 'confirmPassword',
      placeholder: currLang.common[0].CONFIRM_NEW_PASSWORD,
      required: true,
      type: 'password',
    },
  ];

  const fieldsJsx = fields.map((field) => (
    <div key={field.name}>
      <InputBlock
        error={props.errors[field.name]}
        inputAttrs={field}
        // label={field.placeholder}
        onChange={props.onChange}
        value={props.values[field.name]}
      />
    </div>
  ));

  return (
    <form onSubmit={props.onSubmit}>
      {fieldsJsx}
      <div className='itc-input-spacing__block'>
        <button
          disabled={props.buttonDisabled}
          type='submit'
          className='itc-input button button_block'
        >
          <span className='button__text'>
            {currLang.common[0].UPDATE_PASSWORD}
          </span>
        </button>
      </div>
      <div className='gray gray--brand text-center itc-input-spacing__block'>
        {currLang.common[0].BACK_TO}{' '}
        <Link to='/sign-in'>{currLang.sign_in[0].SIGN_IN}</Link>
      </div>
    </form>
  );
};

Form.propTypes = {
  buttonDisabled: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  lan: state.language.lan,
});

export default connect(mapStateToProps)(Form);
