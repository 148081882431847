import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import lifecycle from 'react-pure-lifecycle';
import isEmail from 'validator/lib/isEmail';
import axios from '../../../../requests/platformApi';
import Tab from '../../../../components/settings/Tab';
import Input from '../../../../components/settings/Input';
import {
  fetchSettingsGeneral,
  saveGeneralData,
} from '../../../../store/actions';
import languages from '../../../../helpers/languages';

import { password,oldPassword } from '../../../../validations/validations';

var language = languages['en'];
var common = language.common[0];
var registration = language.registration[0];
var forgot_password = language.forgot_password[0];

const checkCurrentPassword = (v) => {
  if (v !== '') {
    return axios
      .post('/setting/checkPassword', { password: v })
      .then((res) => {
        if (res.data.data) {
          return null;
        } else {
          return common.THE_CURRENT_PASSWORD_IS_INCORRECT;
        }
      })
      .catch(function (error) {
        return common.PASSWORD_CHECK_FAILED;
      });
  }
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  defaultData: state.setting.loadData,
  lan: state.language.lan,
});

const mapDispatchToProps = {
  fetchSettingsGeneral,
  saveGeneralData,
};

const methods = {
  componentDidMount(props) {
    props.fetchSettingsGeneral();
  },
};

function ifPasswordTouched(formState, obj) {
  const {
    values: { currentPassword, newPassword, newPasswordConfirm },
  } = formState;
  const passwordTouched = currentPassword || newPassword || newPasswordConfirm;
  return passwordTouched ? obj : null;
}

const General = (props) => {
  language = languages[props.lan];
  common = language.common[0];
  registration = language.registration[0];
  forgot_password = language.forgot_password[0];
  return (
    <Tab
      title={common.GENERAL_SETTINGS}
      formProps={{
        initialValues: props.defaultData && {
          subscribeToNewsletter: props.defaultData.hasOwnProperty('news_letter')
            ? props.defaultData.news_letter
            : '',
          currentPhoneNumber: props.defaultData.hasOwnProperty('phone')
            ? props.defaultData.phone
            : '',
          currentEmail: props.defaultData.hasOwnProperty('email')
            ? props.defaultData.email
            : '',
          additionalEmail: props.defaultData.hasOwnProperty('additionalEmail')
            ? props.defaultData.additionalEmail
            : '',
          additionalPhoneNumber: props.defaultData.hasOwnProperty(
            'additionalPhone'
          )
            ? props.defaultData.additionalPhone
            : '',
        },
        onSubmit: (values) => {
          props.saveGeneralData(values);
        },
      }}
      showPreloader={!props.defaultData}
      renderContents={({ formState }) => (
        <Fragment>
          <div className='inputs-group'>
            <div className='inputs-group__title'>
              <p className='gray'>{common.CHANGE_PASSWORD}</p>
            </div>

            <div className='inputs'>
              <Input
                id='currentPassword'
                label={common.CURRENT_PASSWORD}
                placeholder={common.ENTER_YOUR_CURRENT_PASSWORD}
                type='password'
                informedProps={ifPasswordTouched(formState, {
                  validate: oldPassword,
                  asyncValidate: checkCurrentPassword,
                  asyncValidateOnBlur: true,
                })}
                hasError={
                  'currentPassword' in formState.errors ||
                  'currentPassword' in formState.asyncErrors
                }
                errors={
                  formState.errors.currentPassword ||
                  formState.asyncErrors.currentPassword
                }
              />

              <Input
                id='newPassword'
                label={common.NEW_PASSWORD}
                placeholder={common.ENTER_NEW_PASSWORD}
                type='password'
                informedProps={ifPasswordTouched(formState, {
                  validate: password,
                })}
                hasError={'newPassword' in formState.errors}
                errors={formState.errors.newPassword}
              />

              <Input
                id='newPasswordConfirm'
                label={common.RE_TYPE_NEW_PASSWORD}
                type='password'
                placeholder={common.CONFIRM_NEW_PASSWORD}
                informedProps={ifPasswordTouched(formState, {
                  validate: (v) =>
                    v === formState.values.newPassword
                      ? null
                      : common.PASSWORD_DONT_MATCHED,
                })}
                hasError={'newPasswordConfirm' in formState.errors}
                errors={formState.errors.newPasswordConfirm}
              />
            </div>
          </div>

          <div className='inputs-group'>
            <div className='inputs-group__title'>
              <p className='gray'>{registration.EMAIL}</p>
            </div>

            <div className='inputs'>
              <Input
                id='currentEmail'
                label={common.CURRENT_EMAIL}
                placeholder={common.ENTER_EMAIL_ADDRESS}
                disabled={true}
                required
                type='email'
                informedProps={{
                  validate: (v) =>
                    v && isEmail(v) ? null : forgot_password.EMAIL_INCORRECT,
                }}
              />

              <Input
                id='additionalEmail'
                label={common.ADDITINAL_MAIL}
                placeholder={common.ENTER_EMAIL_ADDRESS}
                type='email'
                informedProps={{
                  validate: (v) =>
                    !v || isEmail(v) ? null : forgot_password.EMAIL_INCORRECT,
                }}
              />
            </div>
          </div>

          <div className='inputs-group'>
            <div className='inputs-group__title'>
              <p className='gray'>{common.PHONE_NUMBER}</p>
            </div>

            <div className='inputs'>
              <Input
                id='currentPhoneNumber'
                label={common.CURRENT_PHONE_NUMBER}
                placeholder={common.ENTER_PHONE_NUMBER}
                required
                type='phone'
              />

              <Input
                id='additionalPhoneNumber'
                label={common.ADDITIONAL_PHONE_NUMBER}
                placeholder={common.ENTER_PHONE_NUMBER}
                type='phone'
              />
            </div>
          </div>

          <div className='inputs-group'>
            <div className='inputs-group__title'>
              <p className='gray'>{common.NEWSLETTER}</p>
            </div>

            <div className='inputs'>
              <Input
                id='subscribeToNewsletter'
                label={common.SUBSCRIBE_TO_NEWSLETTER}
                type='checkbox'
              />
            </div>
          </div>
        </Fragment>
      )}
    />
  );
};

const pageGeneralCycle = lifecycle(methods)(General);
const List = connect(mapStateToProps, mapDispatchToProps)(pageGeneralCycle);
export default List;
