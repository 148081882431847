import React from 'react';
import PropTypes from 'prop-types';
import Sort from '../../../../../../components/Sort';

const Header = ({ title, sort }) => (
  <div className='list-animation-item notifications-tab-name'>
    <div className='flex flex_align-middle'>
      <div className='left'>
        <h1 className='h3'>{title}</h1>
      </div>

      {!!sort.length && (
        <div className='right'>
          <Sort items={sort} />
        </div>
      )}
    </div>
  </div>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  sort: PropTypes.array,
};

Header.defaultProps = {
  sort: [],
};

export default Header;
