import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
// import { NavLink, Link } from 'react-router-dom';
import { NavHashLink as NavLink, HashLink as Link } from 'react-router-hash-link';
import LangSwitcher from '../../Profile/components/LangSwitcher';
import { Form } from 'informed';
import { reset, searchSubmit } from '../../../reducers/search';
import {
  fetchSearchResult,
} from '../../../store/actions';
import languages from '../../../helpers/languages';

var language = languages['en'];
var common = language.common[0];
var header = language.header[0];
var newmenu = language.NewMenu[0];

const mapStateToProps = (state) => {
  return {
    lan: state.language.lan,
    isMobile: state.responsive.isMobile,
  };
};
class TopBar extends React.Component {
  static propTypes = {
    fetchSearchResult: PropTypes.func,
    onQueryChange: PropTypes.func,
    onResetClick: PropTypes.func.isRequired,
    onSearchSubmit: PropTypes.func,
    toggleRef: PropTypes.func.isRequired,
    isMobile: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      formApi: null,
      queryInput: '',
    };

    this.clear = this.clear.bind(this);
    this.onQueryInputChange = this.onQueryInputChange.bind(this);
    this.setFormApi = this.setFormApi.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    const queryObject = queryString.parse(decodeURIComponent(location?.search));
    if (
      typeof queryObject.query === 'string' &&
      queryObject.query.length !== 0
    ) {
      this.setState({
        queryInput: queryObject.query,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { isMobile } = this.props;
    if (isMobile) return;
    const { formApi } = this.state;
  }

  clear() {
    const { onResetClick } = this.props;
    onResetClick();
    this.setState({
      queryInput: '',
    });
  }

  onQueryInputChange(e) {
    this.setState({
      queryInput: e.target.value,
    });
  }

  persist(values, searchUrl = null) {
    const { fetchSearchResult, onSearchSubmit } = this.props;
    const { formApi, queryInput } = this.state;
    formApi.setValues(values);
    onSearchSubmit(values);
    if (searchUrl) {
      fetchSearchResult(searchUrl, values, true);
    } else {
      fetchSearchResult(window.location.hash, values, true);
    }
  }

  setFormApi(formApi) {
    this.setState({
      formApi,
    });
  }

  submit() {
    const { history } = this.props;
    const { formApi, queryInput } = this.state;
    const trimmedQuery = encodeURIComponent(queryInput.trim());
    var pageUrl = window.location.hash.split('/');
    var currentTab = '';
    if (pageUrl[1]) {
      pageUrl = pageUrl[1];
      pageUrl = pageUrl.split('?');
      if (pageUrl[0] && pageUrl[0] === 'publicsearch') {
        var qParam = queryString.parse(decodeURIComponent(pageUrl[1]));
        if (qParam['tab']) {
          currentTab = 'tab=' + qParam['tab'];
        }
      }
    }
    const formState = formApi.getState();
    if (Object.keys(formState.touched) !== 0 || trimmedQuery.length !== 0) {
      const location = `/publicsearch?query=${trimmedQuery}&${currentTab}`;
      this.persist(formState.values, location);
      this.props.history.push(location);
    }
  }

  getInitialValues() {
  }

  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    header = language.header[0];
    newmenu = language.NewMenu[0];


    const {
      isMobile,
      toggleRef,
    } = this.props;
    const { queryInput } = this.state;
    const initialValues = this.getInitialValues();
    return (
      <div className="wrapper">
        <NavLink to="/" className="logo"><img src="images/svg/SheTrades-logo.svg" alt=" " /></NavLink>
        <div id="top_user_panel">
          <div id="top_user_panel-left">
            <NavLink to='/sign-in' className="login">
              <img src="images/ico-profile.png" alt="" />
              <span className="username">{newmenu.LOGIN}</span>
            </NavLink>
          </div>
          <label htmlFor="top_user_panelswitcher" id="top_user_panelsw" />
          <input id="top_user_panelswitcher" name="top_user_panelsw" type="checkbox" />
          <div id="top_user_panel-right">
            <NavLink to='/sign-up' className="register">{newmenu.REGISTER}</NavLink></div>
        </div>
        <hr />
        {/*main nav */}
        <input id="mobilenavswitcher" name="mobilenavswitcher" type="checkbox" />
        <label htmlFor="mobilenavswitcher">
          <i className="far fa-bars" /><i className="fal fa-times" /></label>
        <ul id="top_menu">
          <li className="top_user_panel-buttons">
            <NavLink to='/sign-in' className="login">{newmenu.LOGIN}</NavLink>
            <NavLink to='/sign-up' className="register">{newmenu.REGISTER}</NavLink>
          </li>
          <li><a href="#">{newmenu.THE_INITIATIVE}<i className="fal fa-chevron-down" /></a>
            <ul>
              <li className="bgdark"><span className="dropdownlinks-block">
                <Link to="/publicaboutus">{newmenu.WHY_SHETRADES}</Link>
                <Link to="/publicpartners">{newmenu.OUR_PARTNERS_AND_SPONSORS}</Link>
                {/* <Link to="/publicdiscover" className="red">{newmenu.DISCOVER_OUR_FULL_OFFERING}<i className="fal fa-arrow-right" /></Link> */}
              </span>
              </li>
            </ul>
          </li>
          {/* <li> <Link to="/profilesearch">{newmenu.SHETRADES_NETWORK} </Link></li> */}
          <li><Link to="/publiclearn">{newmenu.LEARN}</Link></li>
          <li className="navcommunity">
            <a href="#">{newmenu.COMMUNITY}<i class="fal fa-chevron-down"></i></a>
            <ul>
              <li class="bgdark firstdropdownblock">
                <span class="dropdownlinks-list">
                  <Link to="/profilesearch">{newmenu.SHETRADES_NETWORK} </Link>
                  {/* <Link to="/publicprojects">{newmenu.SHETRADES_PROJECTS}</Link> */}
                  <Link to="/publichubs">{newmenu.SHETRADES_HUB} </Link>
                  <Link to="/publicevents">{newmenu.EVENTS_ACTIVITIES}</Link>
                  <Link to="/publicpartners">{newmenu.MEET_THE_PARTNERS}</Link>
                </span></li>
              { /* <li className="bgdarkblue dropdownbg firstdropdownblock"><h4>
                <Link to="/publiccommunity">{newmenu.HOW_CAN_I_BE_PART_OF_SHETRADES}<i className="fal fa-arrow-right" /></Link></h4>
                <p>{newmenu.DISCOVER_ALL_THE_WAYS_IN_WHICH}</p> </li>
              <li className="bgdarker seconddropdownblock"><h4>
                <Link to="/publicblog">{newmenu.MEET_THE_WOMEN_ON_OUR_}<i className="fal fa-arrow-right" /></Link></h4>
                <p>{newmenu.READ_AND_LISTEN_TO_OUR_MEMBERS}</p> </li> */ }
            </ul>
          </li>
          <li><Link to={this.props.lan === 'en' ? "/publicproject/9" : this.props.lan === 'es' ? "/publicproject/28" : "/publicproject/39"}>{newmenu.FINANCE}</Link></li>
          <li><Link to="/publicproject/5">{newmenu.POLICY_DATA}</Link></li>
          <li><a href="#">{newmenu.OPPORTUNITIES_BOARD}<i className="fal fa-chevron-down" /></a>
            <ul>
              <li className="bgdark"><span className="dropdownlinks-block">
                <Link to="/publicprojects">{newmenu.SHETRADES_PROJECTS}</Link>
                <Link to="/publicevents?tab=allevents">{newmenu.EVENTS_ACTIVITIES}</Link>
              </span>
              </li>
            </ul>
          </li>
          <li><Link to="/publictools">{newmenu.TOOLS}</Link></li>
          <li><a href="#">{newmenu.PUBLICATIONS_RESOURCES}<i className="fal fa-chevron-down" /></a>
            <ul>
              <li className="bgdark"><span className="dropdownlinks-block">
                <Link to="/publicsuccessstories">{common.SUCCESS_STORIES}</Link>
                <Link to="/publicvideos">{newmenu.VIDEOS}</Link>
                <Link to="/publicnews">{newmenu.SHETRADES_NEWS}</Link>
                <Link to="/publicpublications">{newmenu.PUBLICATIONS}</Link>
                { /* <Link to="/publichelpcenter">{newmenu.RESOURCES}</Link> */}
              </span>
              </li>
            </ul>
          </li>
        </ul>
        {/*main nav */}
        <Form
          onSubmit={this.submit}
          initialValues={initialValues}
          getApi={this.setFormApi}
          className="searchform"
          render={({ formState: { touched, values } }) => (
            <Fragment>
              <input id="searchcheckbox" name="searchcheckbox" type="checkbox" />
              <label htmlFor="searchcheckbox"><i className="fal fa-search" /></label>
              <div className="searchformcontainer">
                <input
                  type='text'
                  placeholder={header.HEADER_SEARCH_PLACEHOLDER}
                  className='input-with-icon__input'
                  value={queryInput}
                  onChange={this.onQueryInputChange}
                />
                <button name="searchform" value="search" type="submit">{header.SEARCH}</button>
              </div>
            </Fragment>
          )}
        />
        <LangSwitcher></LangSwitcher>
      </div>
    )
  };
}

const mapDispatchToProps = {
  fetchSearchResult,
  onResetClick: reset,
  onSearchSubmit: searchSubmit,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopBar));
