import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import axios from '../../../../requests/platformApi';
import folderImage from '../../../../static/images/folder.svg';
import ListOfNotifications from '../../../../components/ListOfNotifications';
import Modal from '../../../../components/Modal';
import Input from '../../../../components/settings/Input';
import MultiInputField from '../../../../components/settings/MultiInput/components/Field';
import { Form } from 'informed';
import {
  reloadMessenger,
  getContactsOptions,
  fetchMessageTabData,
  appendUserOrGroup,
} from '../../../../store/actions';
import { connect } from 'react-redux';
import {
  fetchChats,
  createGroup,
  getChatGroups,
} from '../../../../pages/Messenger/components/MessengerTabs/fetchApplozic';
import Preloader from '../../../../components/Preloader';
import languages from '../../../../helpers/languages';

var language = languages['en'];
var common = language.common[0];
var messages = language.messages[0];

const mapStateToProps = (state) => ({
  applozicApi: state.messenger.applozicApi,
  contactsCache: state.messenger.cache.chats,
  contactOptions: state.messenger.contactOptions,
  userId: state.auth.user && state.auth.user.id,
  lan: state.language.lan,
});

const mapDispatchToProps = {
  appendUserOrGroup,
  reloadMessenger,
  getContactsOptions,
  fetchMessageTabData,
};

class Groups extends React.Component {
  static propTypes = {
    applozicApi: PropTypes.object,
    contactsCache: PropTypes.object,
    reloadMessenger: PropTypes.func,
    searchQuery: PropTypes.string,
  };

  static defaultProps = {
    contactsCache: {},
  };

  static mapContactsToOptions = (contacts) => {
    if (contacts && contacts.length) {
      return contacts
        .filter(({ isGroup }) => !isGroup)
        .map(({ id, name }) => ({ label: name, value: id }));
    }
    return [];
  };

  constructor() {
    super();
    this.state = {
      createGroupPending: false,
      formApi: null,
      showModal: false,
      showTopMessage: false,
    };
  }

  async componentDidMount() {
    const { applozicApi } = this.props;
    if (applozicApi !== null) {
      this.fetch();
    }

    this.props.getContactsOptions();
  }

  componentDidUpdate({ applozicApi: oldApplozicApi }) {
    const { applozicApi } = this.props;
    if (oldApplozicApi === null && applozicApi !== null) {
      this.fetch();
    }
  }

  fetch() {
    const { applozicApi, contactsCache, reloadMessenger } = this.props;
    const { value, isPending } = contactsCache;
    if (!value && !isPending) {
      //reloadMessenger({ key: 'chats', fetchItems: () => fetchChats(applozicApi) });
    }
  }

  toggleModal(showModal) {
    this.setState({
      ...this.state,
      showModal,
    });
  }

  toggleTopMessage(showTopMessage) {
    this.setState({
      ...this.state,
      showTopMessage,
    });
  }

  toggleCreateGroupPending(createGroupPending) {
    this.setState({
      ...this.state,
      createGroupPending,
    });
  }

  setFormApi(formApi) {
    this.setState({
      ...this.state,
      formApi,
    });
  }

  async createGroup() {
    const { applozicApi, appendUserOrGroup, reloadMessenger } = this.props;
    const { formApi } = this.state;
    formApi.submitForm();
    const { values, errors } = formApi.getState();

    if (!Object.keys(errors).length) {
      this.toggleCreateGroupPending(true);
      const applozicResponse = await createGroup(applozicApi, values).catch(
        (e) => {
          this.toggleCreateGroupPending(false);
          this.toggleTopMessage(true);
          throw e;
        }
      );
      appendUserOrGroup({ group: applozicResponse.response });

      // here update data on laravel server
      if (applozicResponse.status === 'success') {
        values.groupId = applozicResponse.response.id;
        await axios.post('/addChatGroup', values).catch((e) => {
          this.toggleTopMessage(true);
          throw e;
        });
      }
      reloadMessenger({
        key: 'groups',
        fetchItems: () => getChatGroups(applozicApi),
      });
      this.toggleCreateGroupPending(false);
      this.toggleTopMessage(false);
      this.toggleModal(false);
    }
  }

  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    messages = language.messages[0];
    const {
      applozicApi,
      contactsCache,
      contactOptions,
      searchQuery,
    } = this.props;
    const { value } = contactsCache;
    const contactsOptions = Groups.mapContactsToOptions(value);
    const { createGroupPending, showModal, showTopMessage } = this.state;

    return (
      <Fragment>
        <ListOfNotifications
          fetchItems={() => getChatGroups(applozicApi)}
          stateKey='groups'
          button={{
            title: messages.ADD_NEW_CONTACT_GROUP,
            onClick: this.toggleModal.bind(this, true),
          }}
          noCircles
          tab='groups'
          fullyClickable
          defaultAvatar={folderImage}
          itemClassName='notification notification_big'
          searchQuery={searchQuery}
        />

        <Modal
          isOpen={showModal}
          onRequestClose={this.toggleModal.bind(this, false)}
          noBottom={createGroupPending}
          buttons={{
            left: {
              text: common.CANCEL,
              className: 'gray',
              onClick: this.toggleModal.bind(this, false),
            },
            right: {
              text: common.CREATE_GROUP,
              className: 'brand',
              onClick: this.createGroup.bind(this),
            },
          }}
        >
          <div className='modal-content__title'>
            <h4>{common.NEW_GROUP}</h4>

            {createGroupPending ? (
              <Preloader size='small' relative />
            ) : (
              <div className='inputs'>
                <Form
                  getApi={(formApi) => this.setFormApi(formApi)}
                  render={({ formState: { errors = {} } }) => (
                    <Fragment>
                      <Input
                        id='name'
                        label={messages.NAME}
                        placeholder={common.TYPE_GROUP_NAME_HERE}
                        informedProps={{
                          validate: (v) =>
                            !v ? common.ENTER_THE_NAME_GROUP : null,
                        }}
                        hasError={'name' in errors}
                        errors={errors.name}
                      />

                      <MultiInputField
                        field='members'
                        label={common.MEMBERS}
                        placeholder={common.TYPE_TO_FIND_MEMBERS}
                        options={contactOptions}
                        informedProps={{
                          validate: (v) =>
                            v && v.length ? null : common.ADD_MEMBERS_TO_GROUP,
                        }}
                        hasError={'members' in errors}
                        errors={errors.members}
                      />
                    </Fragment>
                  )}
                />
              </div>
            )}
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
