import axios from '../../../requests/platformApi';

export const ROUTE_FETCH_PRODUCTS = '/fetchProducts';
export const ROUTE_FETCH_SERVICES = '/fetchServices';
export const ROUTE_FETCH_COUNTRIES = '/fetchContinents';

export async function fetchLevel({ parentId, route }) {
  const {
    data: { data },
  } = await axios.get(route, { params: { parentId } });
  return data.map(({ id, name, children }) => ({
    id,
    name,
    hasChildren: children === 'true',
  }));
}

export async function search({ query, route }) {
  const {
    data: { data },
  } = await axios.get(route, { params: { name: query } });
  return data;
}
