import React from 'react';
import { asField } from 'informed';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromHTML, EditorState, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';

export function createEditorStateFromHTML(html) {
  if (html === null) {
    return null;
  }
  const blocksFromHTML = convertFromHTML(html);
  if (!blocksFromHTML.contentBlocks) {
    return null;
  }
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  return EditorState.createWithContent(contentState);
}

class EditorInput extends React.Component {
  constructor() {
    super();
    this.state = {
      editorState: null,
    };
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

  componentDidMount() {
    const {
      fieldState: { value },
    } = this.props;

    if (value) {
      this.setState({
        ...this.state,
        editorState: createEditorStateFromHTML(value),
      });
    } else {
      this.setState({
        ...this.state,
        editorState: EditorState.createEmpty(),
      });
    }
  }

  onEditorStateChange(editorState) {
    const {
      fieldApi: { setValue, setTouched },
      onChange = () => {},
    } = this.props;

    const content = editorState.getCurrentContent();
    const html = stateToHTML(content);

    setValue(html);
    setTouched(true);
    onChange(html);

    this.setState({
      ...this.state,
      editorState,
    });
  }

  render() {
    const { editorProps } = this.props;
    const { editorState } = this.state;

    return (
      <Editor
        editorState={editorState}
        onEditorStateChange={this.onEditorStateChange}
        editorClassName='inputbox'
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'list',
            'textAlign',
            'link',
            'image',
            'remove',
            'history',
          ],
          inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline'],
          },
          blockType: {
            inDropdown: true,
          },
          fontSize: {
            inDropdown: false,
            options: [14, 18],
          },
          list: {
            options: ['unordered', 'ordered'],
          },
          textAlign: {
            inDropdown: false,
          },
        }}
        {...editorProps}
      />
    );
  }
}

export default asField(EditorInput);
