/* eslint-disable no-unused-expressions */
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import formatDate from '../helpers/format-date';
import Region from '../components/Region';
import Tag from '../components/Tag';
import GoogleMap from '../components/GoogleMap';
import Share from '../components/Share';
import { Editor } from 'react-draft-wysiwyg';

import { connect } from 'react-redux';
import { editEventItem } from '../store/actions';
import GalleryFullscreen from '../components/Gallery/components/Fullscreen';
import SupportedBy from '../components/SupportedBy';
import { createEditorStateFromHTML } from '../components/settings/Input/components/EditorInput/EditorInput.js';
import AdditionalFiles from '../components/Detail/components/AdditionalFiles';
import languages from '../helpers/languages';

var language = languages['en'];
var common = language.common[0];
var createOffer = language.create_offer[0];

const DATE_FORMAT = 'DD MMM. YYYY';

const mapStateToProps = (state) => ({
  lan: state.language.lan,
});

const mapDispatchToProps = {
  editEventItem,
};
function editItem(props) {
  props.editEventItem(window.location.hash);
}
const ApplyOrShare = (props) => (
  <div className='apply-or-share'>
    <a href={props.applyUrl}  rel="noreferrer" target='_blank'  className='button button_arrow'>
      <span className='button__text'>{common.APPLY_NOW}</span>
    </a>

    <Share
      inline
      pageUrl={props.pageUrl}
      pageTitle={props.title}
      image={props.bigImage}
    />
  </div>
);

const bookStyles = {
  borderColor: '#39424C',
  color: 'white',
  background: '#39424C',
};

const CardForFullNews = (props) => {
  language = languages[props.lan];
  common = language.common[0];
  createOffer = language.create_offer[0];
  const {
    tags,
    region,
    date,
    start,
    listType,
    end,
    title,
    creatorId,
    bookmarked,
    description,
    bigImage = '',
    editorHtml,
    galleryImages,
    supportedBy,
    details,
    isEvent,
    pageUrl,
    noSharing,
    applyUrl,
    lat,
    lng,
    detailBookmarked,
    addBookmark,
    previousUrl,
    editFlag,
    loggedInUser,
    bookmarkFlag,
    detailsType,
    files,
  } = props;

  let viewDate = null;

  if (start && end) {
    viewDate = formatDate(start, end, listType);
  } else if (date) {
    viewDate = moment(date).format(DATE_FORMAT);
  }

  var isPast = moment().isAfter(props.end);

  const editorState = createEditorStateFromHTML(editorHtml);

  return (
    <section className='card card_for-news card_for-full-news'>
      <div className='card__image'>
        <img src={bigImage} alt='' />
      </div>

      <div className='card__top'>
        {tags.map((tag, i) => (
          <Tag {...tag} key={i} />
        ))}

        {viewDate && (
          <span className='info'>
            <i className='icon-calendar' />
            <span className='info__text'>{viewDate}</span>
          </span>
        )}

        {region && props.details[1].value !== 'webinars' && (
          <Region {...region} />
        )}
      </div>

      {title && (
        <div className='card__title'>
          <h1 className='h2'>{title}</h1>
        </div>
      )}

      {!noSharing && isEvent && isPast === false && listType !== 'webinars' && (
        <ApplyOrShare
          pageUrl={pageUrl}
          pageTitle={title}
          applyUrl={applyUrl}
          image={bigImage}
        />
      )}

      {bookmarkFlag !== 'bookmarkFlag' &&
        isEvent === true &&
        isPast === false &&
        listType !== 'webinars' && (
          <a
            href='javascript: void null'
            className='button button_border button-icon'
            style={bookmarked === true ? bookStyles : {}}
            onClick={(e) => {
              addBookmark
                ? addBookmark(null, null, true, props.previousUrl)
                : '';
            }}
          >
            <i className='icon-bookmark' />
          </a>
        )}
      {editFlag === 'myItems' && props.loggedInUser === creatorId && (
        <a
          href='javascript:void null'
          className='button button_minimal'
          onClick={(e) => editItem(props)}
        >
          Edit Item
        </a>
      )}
      {!noSharing && !isEvent && (
        <Share inline pageUrl={window.location.href} pageTitle={title} />
      )}

      <div className='flex'>
        <div className='card__middle'>
          {description && (
            <div className='card__description'>{description}</div>
          )}
        </div>
      </div>

      {editorState && (
        <Editor
          wrapperClassName='content content_p-margins'
          readOnly={true}
          toolbar={{ options: [] }}
          toolbarStyle={{ display: 'none' }}
          editorState={editorState}
        />
      )}

      <div className='content content_p-margins'>
        {files && (
          <section>
            <AdditionalFiles files={files} />
          </section>
        )}

        {galleryImages.length > 0 && (
          <section>
            <h3>{createOffer.GALLERY_IMAGES}</h3>

            <GalleryFullscreen
              slides={galleryImages}
              getSlickOverrides={() => ({ slidesToShow: 1 })}
            />
          </section>
        )}

        {supportedBy.length > 0 && (
          <section>
            <h3>{common.SUPPORTED_BY}</h3>

            <SupportedBy logos={supportedBy} />
          </section>
        )}

        {details.length > 0 && (
          <section className='content content_p-margins'>
            <h3>{createOffer.DETAILS}</h3>

            {details
              .filter(({ value }) => value !== null)
              .map(({ name, value }) => (
                <p key={name}>
                  <b>{name}:</b>{' '}
                  {typeof value === 'object' && value.link ? (
                    <a
                      target='_blank' rel="noreferrer"
                      href={
                        value.link.includes('http')
                          ? value.link
                          : '//' + value.link
                      }
                    >
                      {value.link}
                    </a>
                  ) : typeof value === 'object' && value.label ? (
                    value.label
                  ) : (
                    value
                  )}
                </p>
              ))}
          </section>
        )}
      </div>

      {isEvent && lat !== null && (
        <GoogleMap
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: '400px' }} />}
          mapElement={<div style={{ height: '100%' }} />}
          lat={lat}
          lng={lng}
        />
      )}

      {!noSharing && isEvent && isPast === false && listType !== 'webinars' && (
        <ApplyOrShare
          pageUrl={pageUrl}
          pageTitle={title}
          applyUrl={applyUrl}
          image={bigImage}
        />
      )}
    </section>
  );
};

CardForFullNews.propTypes = {
  tags: PropTypes.array,
  region: PropTypes.object,
  date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  start: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  end: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  description: PropTypes.string,
  bigImage: PropTypes.string,
  isEvent: PropTypes.bool,
  noSharing: PropTypes.bool,
  pageUrl: PropTypes.string,
  applyUrl: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
  detailBookmarked: PropTypes.bool,
  previousUrl: PropTypes.string,
  addBookmark: PropTypes.func,
  editFlag: PropTypes.string,
  loggedInUser: PropTypes.number,
  creatorId: PropTypes.number,
  editorHtml: PropTypes.string,
  galleryImages: PropTypes.array,
  supportedBy: PropTypes.array,
  details: PropTypes.array,
  bookmarkFlag: PropTypes.string,
  detailsType: PropTypes.string,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string.isRequired,
      size: PropTypes.string.isRequired,
      noBorder: PropTypes.bool,
    })
  ),
};

CardForFullNews.defaultProps = {
  date: '',
  tags: [],
  region: null,
  start: '',
  end: '',
  title: '',
  description: '',
  bigImage: '',
  applyUrl: null,
  isEvent: false,
  noSharing: false,
  lat: null,
  lng: null,
  galleryImages: [],
  supportedBy: [],
  details: [],
  editorHtml: '',
  detailBookmarked: false,
  previousUrl: '',
  addBookmark: null,
  editFlag: '',
  loggedInUser: 0,
  creatorId: 0,
  bookmarkFlag: '',
  detailsType: '',
};

const List = connect(mapStateToProps, mapDispatchToProps)(CardForFullNews);
export default List;
