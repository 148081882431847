import _ from 'lodash';
import { store } from '../store/createStore';

const SET_IS_MOBIILE = 'SET_IS_MOBILE';
const SET_IS_LANDSCAPE = 'SET_IS_LANDSCAPE';

const checkPhone = () => window.innerWidth < 640;
const checkMobile = () => window.innerWidth < 1024;
const checkLandscape = () => Math.abs(window.orientation) === 90;

const handleResize = _.debounce(() => {
  const { isMobile, isPhone } = store.getState().responsive;
  const newIsPhone = checkPhone();
  const newIsMobile = checkMobile();
  if (newIsMobile !== isMobile || newIsPhone !== isPhone) {
    return store.dispatch({
      type: SET_IS_MOBIILE,
      payload: {
        isMobile: newIsMobile,
        isPhone: newIsPhone,
      },
    });
  }
}, 100);
const handleOrientationChange = () => {
  const { isLandscape } = store.getState().responsive;
  const newIsLandscape = checkLandscape();
  if (newIsLandscape !== isLandscape) {
    return store.dispatch({
      type: SET_IS_LANDSCAPE,
      payload: {
        isLandscape: newIsLandscape,
      },
    });
  }
};
window.addEventListener('resize', handleResize);
window.addEventListener('orientationchange', handleOrientationChange);

const responsiveReducer = (
  state = {
    isLandscape: checkLandscape(),
    isMobile: checkMobile(),
    isPhone: checkPhone(),
  },
  action
) => {
  const { type, payload } = action;
  const { isLandscape, isMobile, isPhone } = payload || {};

  switch (type) {
    case SET_IS_MOBIILE:
      return {
        ...state,
        isMobile,
        isPhone,
      };
    case SET_IS_LANDSCAPE:
      return {
        ...state,
        isLandscape,
      };
    default:
      return state;
  }
};

export default responsiveReducer;
