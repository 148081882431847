import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Picker } from 'emoji-mart';
import { connect } from 'react-redux';
import Textarea from 'react-autosize-textarea';
import { EMOJI_SET } from '../../../../../../../../constants';
import _ from 'lodash';
import 'emoji-mart/css/emoji-mart.css';

const mapStateToProps = (state) => ({
  applozicApi: state.messenger.applozicApi,
  enableEmojis: !state.responsive.isMobile,
  typingStatus: state.messenger.typingStatus,
  t: state.language.t,
});

class TextareaWithEmojis extends Component {
  static propTypes = {
    chatId: PropTypes.string.isRequired,
    enableEmojis: PropTypes.bool,
    maxLength: PropTypes.number,
    maxRows: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    pickerBottom: PropTypes.number,
    value: PropTypes.string,
    t: PropTypes.object,
  };

  static defaultProps = {
    className: 'textarea',
    pickerBottom: -20,
  };

  constructor(props) {
    super(props);
    this.state = {
      showEmojiPicker: false,
    };
    this.onEmojiSelect = this.onEmojiSelect.bind(this);
    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.onTextKeydown = this.onTextKeydown.bind(this);
    this.stopTyping = _.debounce(
      this.setTypingStatus.bind(this, [0, 1, 1]),
      2000
    );
    this.updateCaretPosition = this.updateCaretPosition.bind(this);
  }

  isSubmitting = false;

  caretPosition = 0;

  componentDidMount() {
    const { applozicApi, chatId } = this.props;
    const { ALSocket } = applozicApi;
    ALSocket.subscibeToTypingChannel(chatId);
    document.addEventListener('click', this.onDocumentClick);
  }

  componentWillUnmount() {
    const { applozicApi } = this.props;
    if (applozicApi) {
      const { ALSocket } = applozicApi;
      ALSocket.unsubscibeToTypingChannel();
    }
    document.removeEventListener('click', this.onDocumentClick);
  }

  onDocumentClick(e) {
    if (this.props.enableEmojis && !this.emojiPicker.contains(e.target)) {
      this.toggleEmojiPicker(false);
    }
  }

  onTextChange(e) {
    if (this.isSubmitting) {
      const { onSubmit } = this.props;
      this.isSubmitting = false;
      onSubmit();
    } else {
      const { onChange } = this.props;
      onChange(e.target.value);
    }
  }

  onTextKeydown({ shiftKey, ctrlKey, key }) {
    const { onSubmit } = this.props;
    this.setTypingStatus(1);
    this.stopTyping();
    this.updateCaretPosition();
    if (onSubmit && key === 'Enter' && !shiftKey && !ctrlKey) {
      this.isSubmitting = true;
    }
  }

  setTypingStatus(typingStatus) {
    const { applozicApi } = this.props;
    const { ALSocket } = applozicApi;
    ALSocket.sendTypingStatus(typingStatus);
  }

  onEmojiSelect(emoji) {
    const { onChange, value: prevText } = this.props;
    const text =
      prevText.slice(0, this.caretPosition) +
      emoji.native +
      prevText.slice(this.caretPosition);
    onChange(text);
    this.updateCaretPosition();
  }

  toggleEmojiPicker(showEmojiPicker) {
    if (this.state.showEmojiPicker !== showEmojiPicker) {
      this.updateCaretPosition();
      this.setState({
        showEmojiPicker,
      });
    }
  }

  updateCaretPosition() {
    this.caretPosition = this.textarea.selectionStart;
  }

  render() {
    const {
      maxRows,
      maxLength,
      enableEmojis,
      pickerBottom,
      value,
      t,
    } = this.props;
    const { showEmojiPicker } = this.state;
    return (
      <div className='textarea-with-emojis'>
        <Textarea
          className='textarea-with-emojis__textarea'
          innerRef={(el) => (this.textarea = el)}
          placeholder={t.common[0].TYPE_SOMETHING}
          rows={1}
          maxRows={maxRows}
          maxLength={maxLength}
          onChange={this.onTextChange}
          onKeyDown={this.onTextKeydown}
          onClick={this.updateCaretPosition}
          value={value}
        />
        {enableEmojis && (
          <button
            className='textarea-with-emojis__picker'
            ref={(el) => (this.emojiPicker = el)}
            type='button'
            onClick={() => this.toggleEmojiPicker(true)}
          >
            <i className='icon-smile' />
          </button>
        )}

        {showEmojiPicker && (
          <Picker
            autoFocus
            color='rgb(220, 82, 71)'
            style={{
              position: 'absolute',
              right: `-20px`,
              bottom: `${pickerBottom}px`,
              border: 'none',
              boxShadow: '0 10px 40px rgba(0, 0, 0, .1)',
            }}
            title={t.common[0].PICK_EMOJI}
            set={EMOJI_SET}
            onSelect={this.onEmojiSelect}
          />
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps)(TextareaWithEmojis);
