import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import Preloader from '../components/Preloader';
import languages from '../helpers/languages';
import {
  fetchOfferDetails
} from '../store/actions';

var language = languages['en'];
var common = language.common[0];
var dashboard = language.dashboard[0];

const mapDispatchToProps = {
  fetchOfferDetails,
};
const mapStateToProps = (state) => {
  return {
    lan: state.language.lan,
    user: state.auth.user,
    product: state.offers.offer,
  };
};

class PageProduct extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props.match.params.id)
  }
  state = {
    isModalOpened: false
  };

  componentDidMount() {
      this.props.fetchOfferDetails(this.props.match.params.id);
  }

  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    const { showPreloader } = this.props;
    const {
      isModalOpened,
    } = this.state;
    return (
      <CustomizedDocumentTitle title={common.MY_DASHBOARD}>
        <div className='transition-page'>
          {showPreloader ? (
            <Preloader size='medium' relative />
          ) : (
            <div className="bginnergrey">
            <div className="wrap">
              <div className="container-2col">
                <div>
                  <div className="rowcontainer">
                    <div className="bslinktitle"><h2>Selling
                       {this.props.product.title}</h2> <a href="#" className="btnoutline"><i className="fas fa-pen" /> Edit</a></div>
                    <p>Offer posted on 12.12.21 (3 days ago)</p>
                    <p className="bslinktitle"><a href="#" className="btn">Contact this seller</a><a href="#" className="btnshare"><i className="fas fa-share" />share this</a></p>
                  </div>
                  <div className="rowcontainer">
                    <div className="bslinktitle"><h4>About our product</h4> <a href="#" className="btnoutline"><i className="fas fa-pen" /> Edit</a></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec diam metus, molestie eu mollis a, feugiat non dui. Sed placerat eget neque a tempor. Suspendisse ornare tempor nisl, vel sagittis nisi feugiat id. Nulla in orci consectetur, porta mi ac, interdum nisi. Maecenas non quam in tellus pellentesque facilisis non ut ligula.<br />
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec diam metus, molestie eu mollis a, feugiat non dui. Sed placerat eget neque a tempor. Suspendisse ornare tempor nisl, vel sagittis nisi feugiat id. Nulla in orci consectetur, porta mi ac, interdum nisi. Maecenas non quam in tellus pellentesque facilisis non ut ligula.</p>
                  </div>
                  <div className="bslinktitle"><h4>Product gallery</h4> <a href="#" className="btnoutline"><i className="fal fa-plus-circle" /> Upload</a></div>
                  <div className="productgallery">
                    <div className="productgallerythumbnails">
                      <div><a href="#" className="removephotogallerypicture"><i className="far fa-times-circle" /></a><a href="#"><img src="images/product01.jpg" srcSet="images/product01.jpg 1x, images/product01@2x.jpg 2x, images/product01@3x.jpg 3x, images/product01@4x.jpg 4x" alt /></a></div>
                      <div><a href="#" className="removephotogallerypicture"><i className="far fa-times-circle" /></a><a href="#"><img src="images/product02.jpg" srcSet="images/product02.jpg 1x, images/product02@2x.jpg 2x, images/product02@3x.jpg 3x, images/product02@4x.jpg 4x" alt /></a></div>
                      <div><a href="#" className="removephotogallerypicture"><i className="far fa-times-circle" /></a><a href="#"><img src="images/product03.jpg" srcSet="images/product03.jpg 1x, images/product03@2x.jpg 2x, images/product03@3x.jpg 3x, images/product03@4x.jpg 4x" alt /></a></div>
                      <div><a href="#" className="removephotogallerypicture"><i className="far fa-times-circle" /></a><a href="#"><img src="images/product05.jpg" srcSet="images/product05.jpg 1x, images/product05@2x.jpg 2x, images/product05@3x.jpg 3x, images/product05@4x.jpg 4x" alt /></a></div>
                    </div>
                    <div className="productgallerybigpreviewcontainer">
                      <a href="#" className="productgallerycontrolbtn-left"><i className="fas fa-chevron-left" /></a>
                      <a href="#" className="productgallerycontrolbtn-right"><i className="fas fa-chevron-right" /></a>
                      <div className="productgallerybigpreview"><img src="images/product05@4x.jpg" alt /></div>
                    </div>
                  </div>
                  <div className="rowcontainer">
                    <div className="bslinktitle"><h4>Documents</h4> <a href="#" className="btnoutline"><i className="fal fa-plus-circle" /> Upload</a></div>
                    <p><a href="#"><i className="fas fa-file-pdf" /> Inventory_list.pdf <i className="far fa-times-circle" /></a><br />
                      <a href="#"><i className="fas fa-file-spreadsheet" /> Some-spreadsheet-about-this-Product.xls <i className="far fa-times-circle" /></a></p>
                  </div>
                  <p className="bslinktitle"><a href="#" className="btn"><i className="fas fa-envelope" /> Apply until 12.12.2022</a><a href="#" className="btnshare"><i className="fas fa-share" />share this</a></p>
                </div>
                <div className="bdsidebar_buyerinfo">
                  <div className="buysell_sideblock">
                    <h2>Buyer</h2>
                    <div className="container">
                      <span className="managecompanyimg"><img src="images/Charity_Crafts.jpg" srcSet="images/Charity_Crafts.jpg 1x, images/Charity_Crafts@2x.jpg 2x, images/Charity_Crafts@3x.jpg 3x, images/Charity_Crafts@4x.jpg 4x" alt /></span>
                      <div className="managecompanydesc">
                        <a href="#">Charity Crafts</a>  <i className="fas fa-check-circle" />             
                        <div>Handmade beauty products</div>
                      </div>
                    </div>
                    <span className="tagblue"><i className="far fa-venus" /> Women-owned business</span>
                    <p className="buyerlocation"><i className="fas fa-map-marker" /> South Africa<br />
                      <i className="fas fa-chart-pie" /> Sector: Beauty  &amp; Wellness</p>
                  </div>
                  <div className="buysell_sideblock"><h3>Offer posted by</h3>
                    <div className="container">
                      <span className="managecompanyimg"><img src="images/communitypic1.jpg" srcSet="images/communitypic1.jpg 1x, images/communitypic1@2x.jpg 2x, images/communitypic1@3x.jpg 3x, images/communitypic1@4x.jpg 4x" alt className="roundpic" /></span>
                      <div className="managecompanydesc">
                        <a href="#">Charity Phiri</a>             
                        <div>Owner at Charity Crafts</div>
                      </div>
                      <p><a href="#"><i className="fas fa-comment-alt-lines" /> Send private message</a> </p>
                    </div>
                  </div>
                </div>
              </div></div>
            <div className="dotbg l2 dotb" />
          </div>
          )}
        </div>
      </CustomizedDocumentTitle>
    );
  }
}

PageProduct.propTypes = {
  showPreloader: PropTypes.bool,
};


export default connect(mapStateToProps, mapDispatchToProps)(PageProduct);
