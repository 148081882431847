import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import queryString from 'query-string';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';

import Region from '../../components/Region';
import Tag from '../../components/Tag';
import UserNameWithAvatar from '../../components/UserNameWithAvatar';

const DATE_FORMAT = 'DD.MM.YYYY';

const mapStateToProps = (state) => ({
  isMobile: state.responsive.isMobile,
  t: state.language.t,
});

const Applied = ({ applied, t }) => {
  if (applied !== null && applied > 0) {
    return (
      <div className='card__applied'>
        <span className='info'>
          <i className='icon-user' />
          <span className='info__text'>
            {applied} {t.common[0].SHETRADES_MEMBERS_HAVE_APPLIED}
          </span>
        </span>
      </div>
    );
  }
  return null;
};

const Answers = ({ count, last, t }) => (
  <span className='answers info'>
    <i className='icon-comment' />
    <span className='info__text'>
      {`${count} ${t.forum[0].ANSWERS}${
        last ? `  •  ${t.forum[0].LAST} ${last}` : ''
      }`}
    </span>
  </span>
);

Applied.propTypes = {
  applied: PropTypes.number,
};

const Card = (props) => {
  const {
    id,
    tags = [],
    region,
    userNameWithAvatar,
    date,
    value,
    title,
    description,
    dates,
    applied,
    comments,
    isLoading,
    parentLocation,
    showId,
    renderAdditionalButtons,
    location,
    queryMode,
    queryParent,
    bookmarked,
    offerimage,
    isMobile,
    hasValueAndDates,
    subtitle,
    t,
  } = props;

  var qParam = queryString.parse(
    decodeURIComponent(location.search.split('?')[1])
  );
  var listPage = '/offers';
  if (window.location.pathname === '/search') {
    if (qParam['tab']) {
      listPage = '/' + qParam['tab'];
      if (qParam['tab'] === 'topics') listPage = '/forums';
    }
  }
  const href = queryMode
    ? `${queryParent}/${id}`
    : window.location.pathname === '/search'
    ? `${listPage}/${id}`
    : `${parentLocation || location.pathname}/${id}`;

  const tagsToRender = [...tags];
  if (id && showId && !isMobile) {
    tagsToRender.push({ type: 'default', text: `Id: ${id}` });
  }
  var type = '';
  if (qParam['tab'] == 'commitments') {
    type = 'commitments';
  }
  return (
    <section
      className={classNames('card list-animation-item', {
        card_loading: isLoading,
      })}
    >
      <div className='card__top'>
        {tagsToRender.map((tag, i) => (
          <Tag {...tag} key={i} />
        ))}
        {region && <Region {...region} />}

        {userNameWithAvatar && userNameWithAvatar.mini && (
          <UserNameWithAvatar {...userNameWithAvatar} />
        )}

        {date && (
          <span className='card__date'>{moment(date).format(DATE_FORMAT)}</span>
        )}

        {value && !isMobile && (
          <div className='right'>
            <span className='value'>
              <span className='value__text'>{t.common[0].VALUE}:</span>{' '}
              <span className='value__number'>{value}</span>
            </span>
          </div>
        )}
      </div>

      {type === 'commitments' && (
        <div className='card__title'>
          {title && subtitle && (
            <span>
              {title}. <span className='gray'>{subtitle}</span>
            </span>
          )}
        </div>
      )}

      {type === '' && (
        <div className='card__title'>
          <span className='productimg'>
          {offerimage === '' ? (
            <img src="images/no_image_available-product.png" alt="" />
          ) : (
            <img src={offerimage} alt="" />
          )}
          </span>
          {title && <Link to={href}>{title}</Link>}
        </div>
      )}

      {!isMobile ? (
        <div className='flex'>
          <div className='left'>
            <div className='card__middle'>
              {description && (
                <div className='card__description'>{description}</div>
              )}

              <Applied applied={applied} t={t} />
            </div>
          </div>

          {dates && (
            <div className='right'>
              <div className='dates'>
                <p>
                  <span className='color-light'>
                    {t.common[0].OPENING_DATE}:
                  </span>{' '}
                  <b>{moment(dates.opening).format(DATE_FORMAT)}</b>
                </p>
                <p>
                  <span className='color-light'>
                    {t.common[0].CLOSING_DATE}:
                  </span>{' '}
                  <b>{moment(dates.closing).format(DATE_FORMAT)}</b>
                </p>
              </div>
            </div>
          )}
        </div>
      ) : hasValueAndDates ? (
        <div className='card-mobile-value'>
          <div className='card-mobile-value__left'>
            <p className='info'>{t.common[0].VALUE}:</p>
            <div className='value__number card-mobile-value__margin-top'>
              {value || 'n/a'}
            </div>
          </div>
          <div className='card-mobile-value__right'>
            <div className='info'>
              <span>{t.common[0].OPENING_DATE}:</span>{' '}
              <span className='color-text'>
                {dates === null
                  ? 'n/a'
                  : moment(dates.opening).format(DATE_FORMAT)}
              </span>
            </div>
            <div className='info card-mobile-value__margin-top'>
              <span>{t.common[0].CLOSING_DATE}:</span>{' '}
              <span className='color-text'>
                {dates === null
                  ? 'n/a'
                  : moment(dates.closing).format(DATE_FORMAT)}
              </span>
            </div>
          </div>
        </div>
      ) : null}

      {isMobile ? (
        type !== 'commitments' && (
          <div className='card__bottom'>
            <div className='buttons buttons_2x buttons_flex'>
              {renderAdditionalButtons &&
                renderAdditionalButtons(id, bookmarked)}

              <Link to={href} className='button button_arrow button_grow'>
                <i className='icon-arrow--small' />
                <span className='button__text'>
                  {t.common[0].DISCOVER_MORE}
                </span>
              </Link>
            </div>

            <Applied applied={applied} t={t} />

            {comments && <Answers {...comments} t={t} />}
          </div>
        )
      ) : (
        <div className='card__bottom'>
          <div className='left'>
            <div className='flex flex_align-middle flex_spaced'>
              {userNameWithAvatar && !userNameWithAvatar.mini && (
                <UserNameWithAvatar {...userNameWithAvatar} />
              )}

              {comments && <Answers {...comments} t={t} />}
            </div>
          </div>

          {type !== 'commitments' && (
            <div className='right'>
              <div className='buttons buttons_space-2x'>
                {renderAdditionalButtons &&
                  renderAdditionalButtons(id, bookmarked)}

                <Link to={href} className='button button_arrow'>
                  <i className='icon-arrow--small' />
                  <span className='button__text'>
                    {t.common[0].DISCOVER_MORE}
                  </span>
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

Card.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tags: PropTypes.array,
  region: PropTypes.object,
  dates: PropTypes.object,
  userNameWithAvatar: PropTypes.object,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  applied: PropTypes.number,
  comments: PropTypes.object,
  isLoading: PropTypes.bool,
  showId: PropTypes.bool,
  parentLocation: PropTypes.string,
  renderAdditionalButtons: PropTypes.func,
  location: PropTypes.object.isRequired,
  queryMode: PropTypes.bool,
  queryParent: PropTypes.string,
  singleName: PropTypes.string,
  isMobile: PropTypes.bool,
  hasValueAndDates: PropTypes.bool,
  subtitle: PropTypes.string,
};

Card.defaultProps = {
  tags: [],
  region: null,
  dates: null,
  userNameWithAvatar: null,
  date: '',
  value: '',
  title: '',
  description: '',
  applied: 0,
  comments: null,
  isLoading: false,
  showId: false,
  parentLocation: '',
  renderAdditionalButtons: null,
  queryMode: false,
  queryParent: '',
  singleName: '',
  subtitle: '',
};

export default withRouter(connect(mapStateToProps)(Card));
