import { SOCIAL_MISSING_INFO } from '../store/actions';

const initialState = {
  message: '',
  status: 200,
};

export default function socialMissingInfo(state = initialState, action) {
  switch (action.type) {
    case SOCIAL_MISSING_INFO:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
      };
    default:
      return state;
  }
}
