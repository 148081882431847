import React from 'react';
import queryString from 'query-string';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import MessengerTabs from '../MessengerTabs';
import MessengerBody from '../MessengerBody';

const MobileMessenger = ({ location, t }) => {
  const queryObject = queryString.parse(location.search);
  const { item } = queryObject;
  if (item) {
    return (
      <div className='transition-page'>
        <NavLink
          className='back'
          to={{
            ...location,
            search: queryString.stringify({ ...queryObject, item: undefined }),
          }}
        >
          {t.common[0].BACK}
        </NavLink>
        <MessengerBody />
      </div>
    );
  }
  return (
    <div className='transition-page'>
      <div className='innerheader innerheader-helpcenter'>
        <h1 className='h2'>{t.messages[0].MY_MESSAGES}</h1>
      </div>
      <MessengerTabs />
    </div>
  );
};

const mapStateToProps = (state) => ({
  t: state.language.t,
});

export default withRouter(connect(mapStateToProps)(MobileMessenger));
