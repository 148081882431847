import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import InputRange from 'react-input-range';

import Modal from '../../../../../components/Modal';
import noAvatarImage from '../../../../../static/images/noavatar.svg';

const amp = 10;

function exp(x) {
  return Math.exp(amp * (x - 0.5));
}

function log(x) {
  return Math.log(x) / amp + 0.5;
}

const mapStateToProps = (state) => ({
  isPhone: state.responsive.isPhone,
  t: state.language.t,
});

class Editor extends Component {
  static propTypes = {
    imageUrl: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onChange: PropTypes.func,
    onFileChange: PropTypes.func,
    onTakePhotoClick: PropTypes.func,
    t: PropTypes.object,
  };

  static defaultProps = {
    imageUrl: noAvatarImage,
    isOpen: false,
    onClose: () => {},
    onChange: () => {},
    onFileChange: () => {},
    onTakePhotoClick: () => {},
    photoFile: null,
  };

  static initialState = {
    reflectX: false,
    reflectY: false,
    rotation: 0,
    transform: '',
    zoom: 1,
  };

  static calculateTransform(state) {
    const { zoom, rotation, reflectY, reflectX } = state;
    const zoomHorizontal = zoom * (reflectY ? -1 : 1);
    const zoomVertical = zoom * (reflectX ? -1 : 1);
    const adjustedRotation = reflectX !== reflectY ? -rotation : rotation;

    return `translate(-50%, -50%) scale(${zoomHorizontal}, ${zoomVertical}) rotate(${adjustedRotation}deg)`;
  }

  setStateWithTransform(state) {
    this.setState({
      ...state,
      transform: Editor.calculateTransform(state),
    });
  }

  constructor() {
    super();

    this.state = Editor.initialState;
    this.save = this.save.bind(this);
  }

  setZoom(zoom) {
    this.setStateWithTransform({
      ...this.state,
      zoom,
    });
  }

  rotate(clockwise = true) {
    this.setStateWithTransform({
      ...this.state,
      rotation: this.state.rotation + (clockwise ? 90 : -90),
    });
  }

  setReflectY() {
    this.setStateWithTransform({
      ...this.state,
      reflectY: !this.state.reflectY,
    });
  }

  setReflectX() {
    this.setStateWithTransform({
      ...this.state,
      reflectX: !this.state.reflectX,
    });
  }

  save() {
    const { onChange } = this.props;
    onChange(this.state);
    this.setState(Editor.initialState);
  }

  render() {
    const {
      imageUrl,
      isOpen,
      isPhone,
      onClose,
      onTakePhotoClick,
      onFileChange,
      t,
    } = this.props;
    const { reflectX, reflectY, transform, zoom } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        buttons={{
          left: {
            text: t.common[0].CANCEL,
            className: 'gray',
            onClick: onClose,
          },
          right: {
            text: t.common[0].SAVE,
            className: 'brand',
            onClick: this.save,
          },
        }}
      >
        <div className='modal-content__title'>
          <p>{t.common[0].SET_YOUR_PROFILE_PICTURE}</p>
        </div>

        <div className='row'>
          <div className='col-sm-5'>
            <div className='upload-photo-avatar upload-photo-avatar--edit'>
              <img src={imageUrl} alt='' style={{ transform }} />
            </div>

            <div className='upload-photo-zoom'>
              <InputRange
                value={log(zoom)}
                minValue={0}
                maxValue={1}
                step={0.01}
                onChange={(value) => this.setZoom(exp(value))}
              />
            </div>
          </div>

          {!isPhone && <div className='col-1' />}

          {!isPhone && (
            <div className='col-5'>
              <div className='upload-photo-avatar upload-photo-avatar--preview'>
                <img src={imageUrl} alt='' style={{ transform }} />
              </div>
            </div>
          )}
        </div>

        <div className='row'>
          <div className='col-5'>
            <div className='upload-photo-controls'>
              <button
                className='rotate rotate--counter-clockwise'
                onClick={() => this.rotate(false)}
              >
                <i className='icon-rotate' />
              </button>

              <button
                className='rotate rotate--clockwise'
                onClick={() => this.rotate()}
              >
                <i className='icon-rotate' />
              </button>

              <button
                className={classnames([
                  'reflect reflect--horizontally',
                  { 'reflect--active': reflectY },
                ])}
                onClick={() => this.setReflectY(!reflectY)}
              >
                <i className='icon-reflect' />
              </button>

              <button
                className={classnames([
                  'reflect reflect--vertically',
                  { 'reflect--active': reflectX },
                ])}
                onClick={() => this.setReflectX(!reflectX)}
              >
                <i className='icon-reflect' />
              </button>
            </div>
          </div>

          <div className='col-7'>
            <div className='buttons'>
              <button
                className='button '
                onClick={onTakePhotoClick}
              >
                <i className='icon-camera' />

                <span className='button__text'>{t.common[0].TAKE_A_PHOTO}</span>
              </button>

              <label className='button '>
                <i className='icon-upload' />

                <span className='button__text'>{t.common[0].UPLOAD_PHOTO}</span>

                <input
                  type='file'
                  className='visually-hidden'
                  onChange={onFileChange}
                />
              </label>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default connect(mapStateToProps)(Editor);
