import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import UserNameWithAvatar from '../components/UserNameWithAvatar';
import gsap from "gsap";
import { TweenMax, Power1 } from 'gsap';
import Textarea from 'react-autosize-textarea';
import Stars from '../components/Stars';
import { ScrollToPlugin } from 'gsap/all'

//gsap.registerPlugin(ScrollToPlugin)

class Comment extends React.Component {
  static propTypes = {
    id: PropTypes.number,
    isNested: PropTypes.bool,
    userNameWithAvatar: PropTypes.object.isRequired,
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    rating: PropTypes.number,
    text: PropTypes.string.isRequired,
    commentable: PropTypes.bool,
    comments: PropTypes.array,
    subComments: PropTypes.bool,
    addSubComment: PropTypes.func,
    showedAnswers: PropTypes.number,
  };

  static defaultProps = {
    isNested: false,
    rating: 0,
    commentable: false,
    comments: [],
    subComments: true,
    addSubComment: null,
    showedAnswers: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      add: false,
      busy: false,
      comments: false,
      newCommentText: '',
    };

    this.submitAddComment = this.submitAddComment.bind(this);
  }

  handleAddCommentClick() {
    if (this.state.busy) return;

    this.setState((state) => ({
      add: !state.add,
      busy: true,
    }));

    TweenMax.to(this.add, 0.3, {
      autoAlpha: +!this.state.add,
      y: 15 * +this.state.add,
      height: this.add.scrollHeight * +!this.state.add,
      ease: Power1.easeOut,
      onComplete: () => {
        if (this.state.add) {
          this.addComment.focus();
          this.add.style.height = 'auto';
        }

        this.setState({ busy: false });
      },
    });
  }

  handleViewCommentsClick() {
    if (this.state.busy) return;

    this.setState((state) => ({
      busy: true,
      comments: !state.comments,
    }));

    TweenMax.to(this.comments, 0.3, {
      height: this.comments.scrollHeight * +!this.state.comments,
      ease: Power1.easeOut,
    });

    TweenMax.staggerTo(
      this.comments.children,
      0.2,
      {
        autoAlpha: +!this.state.comments,
        delay: 0.15,
        ease: Power1.easeOut,
        y: 15 * +this.state.comments,
      },
      0.05,
      () => {
        this.setState({ busy: false });
      }
    );
  }

  convertDate(date) {
    var date = new Date(date);
    var hour =
      date.getHours().toString().length === 1
        ? '0' + date.getHours()
        : date.getHours();
    var min =
      date.getMinutes().toString().length === 1
        ? '0' + date.getMinutes()
        : date.getMinutes();
    var day =
      date.getDate().toString().length === 1
        ? '0' + date.getDate()
        : date.getDate();
    var month =
      (date.getMonth() + 1).toString().length === 1
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1;
    var year =
      date.getFullYear().toString().length === 1
        ? '0' + date.getFullYear()
        : date.getFullYear();
    var time = hour + ':' + min + ' - ' + day + '.' + month + '.' + year;

    return time;
  }

  onAddCommentKeyUp({ shiftKey, ctrlKey, key }) {
    if (key === 'Enter' && !shiftKey && !ctrlKey) {
      this.submitAddComment();
    }
  }

  onAddCommentChange(e) {
    this.setState({
      ...this.state,
      newCommentText: e.target.value,
    });
  }

  submitAddComment() {
    const { newCommentText } = this.state;

    if (!newCommentText.replace(/[\s\n]+/g, '').length) return;

    const { id } = this.props;
    let commentText = `${newCommentText}`;
    let answerId = `${id}`;
    this.props.addSubComment(
      window.location.hash,
      answerId,
      commentText,
      this.props.showedAnswers
    );

    setTimeout(
      function () {
        this.setState((state) => ({
          busy: true,
        }));
        TweenMax.to(this.comments, 0.3, {
          height: this.comments.scrollHeight * +this.state.comments,
          ease: Power1.easeOut,
        });

        TweenMax.staggerTo(
          this.comments.children,
          0.2,
          {
            autoAlpha: +this.state.comments,
            delay: 0.15,
            ease: Power1.easeOut,
            y: 15 * +!this.state.comments,
          },
          0.05,
          () => {
            this.setState({ busy: false });
          }
        );
      }.bind(this),
      1000
    );

    this.setState({
      ...this.state,
      newCommentText: '',
    });
  }

  render() {
    const {
      isNested,
      userNameWithAvatar,
      date,
      rating,
      text,
      commentable,
      comments,
      subComments,
      t,
    } = this.props;

    const { newCommentText } = this.state;

    return (
      <div className={classNames('comment', { comment_nested: isNested })}>
        <div className='comment__top'>
          <UserNameWithAvatar {...userNameWithAvatar} />

          <div className='card__date'>{this.convertDate(date)}</div>
        </div>

        {!!rating && <Stars rating={rating} />}

        <div className='comment__text'>{text}</div>

        {(!!comments.length || commentable) && subComments && (
          <div className='comment__bottom'>
            {commentable && (
              <div className='left'>
                <a
                  href='javascript: void null'
                  className='info'
                  onClick={this.handleAddCommentClick.bind(this)}
                >
                  <i className='icon-comment' /> {t.common[0].COMMENT}
                </a>
              </div>
            )}

            {!!comments.length && (
              <div className='right'>
                <a
                  href='javascript: void null'
                  className='info'
                  onClick={this.handleViewCommentsClick.bind(this)}
                >
                  {this.state.comments ? 'Hide' : 'Show'} {comments.length}{' '}
                  Comments
                </a>
              </div>
            )}

            <div
              className='comment__comments'
              ref={(el) => {
                this.comments = el;
              }}
            >
              {comments.map((el, i) => (
                <Comment isNested {...el} key={el.id || i} />
              ))}
            </div>

            <div className='comment__add' ref={(el) => (this.add = el)}>
              <Textarea
                rows={1}
                innerRef={(el) => (this.addComment = el)}
                placeholder='Add your comment'
                maxLength={300}
                onKeyPress={this.onAddCommentKeyUp.bind(this)}
                value={newCommentText}
                onChange={this.onAddCommentChange.bind(this)}
              />

              <div className='flex'>
                <div className='right'>
                  <button
                    className='button'
                    onClick={this.submitAddComment}
                    style={{ marginTop: '15px' }}
                  >
                    <span className='button__text'>
                      {t.forum[0].ADD_COMMENT}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ t: state.language.t });

export default connect(mapStateToProps)(Comment);
