import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tag from '../../../components/Tag';
import Input from '../../../components/settings/Input';

class MultiInput extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.array,
  };

  static defaultProps = {
    value: [],
  };

  toggleOption(optionValue, isSelected) {
    const { value = [], onChange } = this.props;
    const index = value.indexOf(optionValue);

    if (isSelected && index === -1) {
      onChange([...value, optionValue]);
    }

    if (!isSelected && index !== -1) {
      onChange([...value.slice(0, index), ...value.slice(index + 1)]);
    }
  }

  render() {
    const { options, value, onChange, ...rest } = this.props;

    const engagedOptions = options.filter(({ value: optionValue }) =>
      value.includes(optionValue)
    );
    const vacantOptions = options.filter(
      ({ value: optionValue }) => !value.includes(optionValue)
    );

    return (
      <div className='multi-select input'>
        <Input
          onChange={(value) => this.toggleOption(value, true)}
          type='select'
          options={vacantOptions}
          selectProps={{
            noInformedField: true,
            searchable: true,
          }}
          {...rest}
        />

        {engagedOptions.length ? (
          <div className='input'>
            <label>&nbsp;</label>

            <div className='inputbox-wrapper'>
              <p className='tags'>
                {engagedOptions.map(({ label: optionLabel, value }, i) => (
                  <Tag type='default' text={optionLabel} maxLength={30} key={i}>
                    <i
                      className='icon-close'
                      onClick={() => this.toggleOption(value, false)}
                    />
                  </Tag>
                ))}
              </p>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default MultiInput;
