import moment from 'moment';

export default (start, end, listType) => {
  if (listType === 'events' || listType === 'webinars') {
    const startMoment = moment(start);
    const endMoment = moment(end);
    if (startMoment.isSame(endMoment, 'day')) {
      return `${startMoment.utc().format('MMMM D ')} @ ${moment
        .utc(startMoment)
        .format('LT')} - ${moment.utc(endMoment).format('LT')}`;
    }
    return `${startMoment
      .utc()
      .format('MMMM D, YYYY')} - ${endMoment.utc().format('MMMM D, YYYY')}`;
  } else {
    const startMoment = moment(start);
    const endMoment = moment(end);
    if (startMoment.isSame(endMoment, 'day')) {
      return `${startMoment.format('MMMM D ')} @ ${startMoment.format(
        'HH:MM A'
      )} - ${endMoment.format('HH:MM A')}`;
    }
    return `${startMoment.format('MMMM D, YYYY')} - ${endMoment.format(
      'MMMM D, YYYY'
    )}`;
  }
};
