import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import InputBlock from '../../components/InputBlock';
import languages from '../../helpers/languages';

const Form = (props) => {
  const currLang = languages[props.lan];
  const regLang = currLang.registration[0];
  const labels = currLang.forgot_password[0];
  const fields = [
    {
      name: 'email',
      placeholder: regLang.EMAIL,
      required: true,
      type: 'email',
    },
  ];

  const fieldsJsx = fields.map((field) => (
    <div key={field.name}>
      <label for={field.name}>{field.placeholder}</label>
      <InputBlock
        error={props.errors[field.name]}
        inputAttrs={field}
        // label={field.placeholder}
        onChange={props.onChange}
        value={props.values[field.name]}
      />
    </div>
  ));

  return (
    <div className="wrap">
      <div className="container-2col">
        <div className="formpage-content">
          <div className="whiteformtoptext" style={{ textAlign: 'center' }}>
            <h3>{regLang.WELCOME_TO}</h3>
            <img src="images/svg/shetrades-logo-black.svg" alt=" " />
          </div>
          <div>
            <h3>{regLang.FORGOT_YOUR_PASSWORD}?</h3>
            <p>{regLang.FORGOT_PASSWORD_INFO}</p>
          </div>
          <form onSubmit={props.onSubmit}>
            {fieldsJsx}
            <button
              disabled={props.buttonDisabled}
              type='submit'
              className='btn'
            >
              <span className='button__text'>{regLang.RESET_YOUR_PASSWORD}</span>
            </button>
          </form>
          <hr />
          <p>{labels.NOT_A_SHE_TRADES_MEMBER}?  <Link to='/sign-up'>
           {labels.CREATE_AN_ACCOUNT}</Link></p>
        </div>
        <div className="sidebg loginbgpicture1" />
      </div>
    </div>
  );
};

Form.propTypes = {
  buttonDisabled: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  lan: state.language.lan,
});

export default connect(mapStateToProps)(Form);
