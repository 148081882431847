import {
  TENDERS,
  TENDERDETAILS,
  TENDERAPPLIED,
  ADD_TENDER_BOOKMARK,
  HANDLE_TENDER_ERROR,
  SET_TENDER_PREVIEW,
  ADD_TENDER_SUCCESS,
  TOGGLE_APPLY_SUCCESS,
  TOGGLE_DETAIL_APPLY,
  CLOSE_SUCCESS,
} from '../store/actions';

const initialState = {
  errorMessages: [],
  hasError: false,
  detailPreview: '',
  tenderApplied: false,
  isApplySuccessShown: false,
  isDetailApplyShown: false,
  closeSuccess: false,
};

export default function tenders(state = initialState, action) {
  switch (action.type) {
    case TENDERS:
      return {
        ...state,
        tenders: action.payload.data,
        totalItems: action.payload.count,
        sortLabel: action.payload.sortLabel,
      };

    case TENDERDETAILS:
      return {
        ...state,
        tenderDetails: action.payload.data,
        tenderApplied: action.payload.data.alreadyApplied,
        tenderBookmark: action.payload.data.bookmarked,
      };

    case TENDERAPPLIED:
      return {
        ...state,
        tenderApplied: action.payload.status,
        isApplySuccessShown: true,
        isDetailApplyShown: false,
      };
    case ADD_TENDER_BOOKMARK:
      return {
        ...state,
        tenderBookmark: !state.tenderBookmark,
      };
    case HANDLE_TENDER_ERROR:
      return {
        ...state,
        formHasError: action.payload.hasError,
        errorMessages: action.payload.errors,
        mainErrorMessage: action.payload.message,
      };
    case SET_TENDER_PREVIEW:
      return {
        ...state,
        detailPreview: action.payload,
      };
    case ADD_TENDER_SUCCESS:
      return {
        ...state,
        saveSucess: true,
      };
    case TOGGLE_APPLY_SUCCESS:
      return {
        ...state,
        isApplySuccessShown: action.payload,
      };
    case TOGGLE_DETAIL_APPLY:
      return {
        ...state,
        isDetailApplyShown: action.payload,
      };
    case CLOSE_SUCCESS:
      return {
        ...state,
        closeSuccess: action.payload,
      };
    default:
      return state;
  }
}
