import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ManageItem = (props) => {
  const { title, isHidden, onToggle, isDragging, t } = props;

  return (
    <section
      className={classnames('dashboard-manage-item', {
        'dashboard-manage-item_dragging': isDragging,
      })}
    >
      <i className='icon-drag' />
      <div className='dashboard-manage-item__title'>
        <h3>{title}</h3>
      </div>
      <button className='info dashboard-manage-item__status' onClick={onToggle}>
        {t.video[0][isHidden ? 'SHOW' : 'HIDE']}
      </button>
    </section>
  );
};

ManageItem.propTypes = {
  title: PropTypes.string,
  isHidden: PropTypes.bool,
  onToggle: PropTypes.func,
  isDragging: PropTypes.bool,
  t: PropTypes.object.isRequired,
};

export default ManageItem;
