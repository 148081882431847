import React from 'react';
import PropTypes from 'prop-types';

const Progress = (props) => {
  const { small, progress } = props;

  return (
    <div
      className={`progress${small ? ' progress_small' : ''}${
        progress === 1 ? ' progress_complete' : ''
      }`}
    >
      <div
        className='progress__progress'
        style={{ transform: `translateX(${100 * (progress - 1)}%)` }}
      />
    </div>
  );
};

Progress.propTypes = {
  small: PropTypes.bool,
  progress: PropTypes.number.isRequired,
};

Progress.defaultProps = {
  small: false,
};

export default Progress;
