import React, { Fragment } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';
import LoginChecker from './LoginChecker';
import SignInView from '../auth-modal/views/sign-in';
import SignUpView from '../auth-modal/views/sign-up';
import ForgotView from '../auth-modal/views/forgot';
import SetNewView from '../auth-modal/views/set-new';
import ProtectedRoute from './ProtectedRoute';
import FlashMessagesDisplay from './FlashMessagesDisplay';
import PublicApp from '../publicpages/PublicApp';
import Blog from '../publicpages/Blog';
import HomePage from '../publicpages/HomePage';


const RedirectWithParams = (props) => (
  <Route
    exact
    path={props.from}
    render={({ location }) => (
      <Redirect to={{ pathname: props.to, search: location.search }} />
    )}
  />
);

export default () => (
  <LoginChecker>
    <HashRouter basename={process.env.BASE_ROUTE} >
      <Switch>
        <Redirect exact from='/en' to='/'    component={HomePage} />
        <Redirect exact from='/fr'to='/'    component={HomePage} />
        <Redirect exact from='/es' to='/'    component={HomePage} />
        <Redirect exact from='/login' to='/sign-in' />
        <RedirectWithParams from='/login/sign-in' to='/sign-in' />'
        <Redirect from='/login/sign-up' to='/sign-up' />'
        <Redirect from='/login/sign-up/seller' to='/sign-up' />
        <Redirect from='/login/sign-up/buyer' to='/sign-up' />
        <Redirect from='/login/sign-up/verifier' to='/sign-up' />
        <ProtectedRoute fromUsers basename={process.env.BASE_ROUTE} path='/sign-in'
          component={SignInView}
        />
        <ProtectedRoute fromUsers
          basename={process.env.BASE_ROUTE} path='/sign-up'
          component={SignUpView}
        />
        <Redirect exact from='/login/forgot-password' to='/forgot-password' />
        <ProtectedRoute
          fromUsers
          basename={process.env.BASE_ROUTE} path='/forgot-password'
          component={ForgotView}
        />
        <RedirectWithParams
          from='/login/update-password'
          to='/set-new-password'
        />
        <ProtectedRoute
          fromUsers
          basename={process.env.BASE_ROUTE} path='/set-new-password'
          component={SetNewView}
        />
        <Route
          exact
          basename={process.env.BASE_ROUTE} path='/publicblog'
          component={Blog}
        />
        <Route component={PublicApp} />

      </Switch>
    </HashRouter>
    <FlashMessagesDisplay />
  </LoginChecker>
);
