import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import languages from '../../../helpers/languages';
const mapStateToProps = (state) => ({
  lan: state.language.lan,
  t: state.language.t,
});

const LangSwitcher = (props) => {
  const { location, t } = props;
  const qParam = queryString.parse(location.search);
  const getLangName = (langCode) => {
    switch (langCode) {

      case "en": return "English";
      case "es": return "Spanish";
      case "fr": return "French";
      default: return "English";
    }
  }
  return (
    <div className="langselect">
      <span className="mainlang">
        {getLangName(props.lan)}
        <i className="fal fa-chevron-down" /></span>
      <ul>
        {Object.keys(languages).map((langCode) => {
          const queryObj = { ...qParam, lan: langCode };
          const isActive = langCode === props.lan;

          const handleItemClick = () => {
          localStorage.setItem('shetrades-language', langCode);
          window.location.reload();
            
          };

          return (
            <li
              key={langCode}
              onClick={isActive ? undefined : handleItemClick}
            >

              <NavLink to={`${location.pathname}?${queryString.stringify(
                queryObj
              )}`}>
                {getLangName(langCode)}
              </NavLink></li>
          );
        })}
      </ul>
    </div>
  );
};

LangSwitcher.propTypes = {
  lan: PropTypes.string.isRequired,
  t: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

LangSwitcher.defaultProps = {
  lan: 'en',
};

export default withRouter(connect(mapStateToProps)(LangSwitcher));
