import { SUCCESSTORY, SUCCESSTORY_DETAILS } from '../store/actions';

const initialState = {};

export default function successStory(state = initialState, action) {
  switch (action.type) {
    case SUCCESSTORY:
      return {
        ...state,
        stories: action.payload.data,
        totalItems: action.payload.count,
        sortLabel: action.payload.sortLabel,
      };
    case SUCCESSTORY_DETAILS:
      return {
        ...state,
        storyDetails: action.payload.data,
      };
    default:
      return state;
  }
}
