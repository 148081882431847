import React from 'react';
import PropTypes from 'prop-types';

const passwordKeys = ['password', 'confirmPassword'];

class FormHandler extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      buttonDisabled: false,
      globalError: '',
      values: { ...props.initialValues },
      errors: {},
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleCaptchaChange = this.handleCaptchaChange.bind(this);
    if (props.withCaptcha) {
      this.captchaRef = React.createRef();
      this.state.recaptchaResponse = '';
    }
  }

  onChange(e) {
    const input = e.target;
    const values = { ...this.state.values };
    values[input.name] =
      input.type === 'checkbox' ? input.checked : input.value;
    this.setState({ values });
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({
      buttonDisabled: true,
      errors: {},
      globalError: '',
    });

    const { isValid, errors } = this.validate();
    if (!isValid) {
      return this.setState({
        buttonDisabled: false,
        errors,
      });
    }

    if (this.props.withCaptcha && !this.state.recaptchaResponse) {
      this.pausedSubmitEvent = e;
      return this.captchaRef.current.execute();
    }

    const params = { ...this.state.values };

    Object.keys(params).forEach((key) => {
      if (passwordKeys.includes(key)) {
        params[key] = btoa(params[key]);
      }
    });

    if (this.props.withCaptcha) {
      params['g-recaptcha-response'] = this.state.recaptchaResponse;
    }

    this.props.submitRequest(params).then((res) => {
      if (res.status === true || res.status === 200) {
        this.setState(
          {
            buttonDisabled: false,
            values: { ...this.props.initialValues },
          },
          () => {
            this.props.onSubmitSuccess(res);
          }
        );
      } else {
        const stateUpdates = {
          buttonDisabled: false,
          globalError: res.message,
        };
        if (typeof res.errors === 'object') {
          stateUpdates.errors = Object.keys(res.errors).reduce(
            (acc, curr) => ({
              ...acc,
              [curr]: res.errors[curr].join('; '),
            }),
            {}
          );
        }
        this.setState(stateUpdates);
      }
    });
  }

  validate() {
    const errors = {};
    Object.keys(this.state.values).forEach((key) => {
      const validation = this.props.mapFieldsToValidations[key];
      const error =
        validation && validation(this.state.values[key], this.state.values);
      if (typeof error === 'string') {
        errors[key] = error;
      }
    });
    const isValid = Object.keys(errors).length === 0;
    return { errors, isValid };
  }

  handleCaptchaChange(v) {
    this.setState({ recaptchaResponse: v }, () => {
      if (this.pausedSubmitEvent) {
        this.onSubmit(this.pausedSubmitEvent);
      }
    });
  }

  render() {
    const args = [this.state, this.onChange, this.onSubmit];
    if (this.props.withCaptcha) {
      args.push(this.captchaRef);
      args.push(this.handleCaptchaChange);
    }
    return this.props.children(...args);
  }
}

FormHandler.propTypes = {
  children: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  mapFieldsToValidations: PropTypes.object.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  submitRequest: PropTypes.func.isRequired,
  withCaptcha: PropTypes.bool,
};

FormHandler.defaultProps = {
  initialValues: {},
  mapFieldsToValidations: {},
};

export default FormHandler;
