import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import ButtonsList from '../../../../components/ButtonsList';
import CustomizedDocumentTitle from '../../../../components/CustomizedDocumentTitle';
import { fetchForumTopics } from '../../../../store/actions';
import languages from '../../../../helpers/languages';

var language = languages['en'];
var label = language.forum[0];
var common = language.common[0];

const BeforeToolbar = (props) => {
  language = languages[props.lan];
  label = language.forum[0];
  common = language.common[0];
  const { location, forums, fetchForumTopics } = props;
  const queryObject = queryString.parse(location.search);
  const clearObject = { ...queryObject, forum: undefined, page: undefined };
  const clearRoute = `${location.pathname}?${queryString.stringify(
    clearObject
  )}`;
  const buttons = forums.map(({ text, slug }) => {
    const toQueryObject = {
      ...queryObject,
      forum: slug,
      page: undefined,
    };
    const to = `${location.pathname}?${queryString.stringify(toQueryObject)}`;
    return {
      title: text,
      to,
      onClick: fetchForumTopics.bind(null, to),
      isActive: () => slug === queryObject.forum,
    };
  });
  let activeTitle;
  buttons.forEach(({ isActive, title }) => {
    if (isActive()) {
      activeTitle = title;
    }
  });
  return (
    <CustomizedDocumentTitle title={activeTitle}>
      <Fragment>
        <div className='after-title flex'>
          <div className='left'>
            <p className='gray'>{label.INFO}</p>
          </div>
        </div>
        <div className='toolbar toolbar_forums'>
          <div className='left'>
            <ButtonsList items={buttons} />
          </div>
          <div className='top-filters__clear'>
            <NavLink
              to={clearRoute}
              onClick={() => fetchForumTopics(clearRoute)}
              isActive={() => !queryObject.forum}
            >
              <i className='icon-close' /> {common.CLEAR_ALL_FILTERS}
            </NavLink>
          </div>
        </div>
      </Fragment>
    </CustomizedDocumentTitle>
  );
};

BeforeToolbar.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  forums: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      slug: PropTypes.string,
      text: PropTypes.string,
    })
  ).isRequired,
  fetchForumTopics: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  forums: state.forum.forumTags,
  lan: state.language.lan,
});

const mapDispatchToProps = {
  fetchForumTopics,
};

export default connect(mapStateToProps, mapDispatchToProps)(BeforeToolbar);
