export default function (errors) {
  const containers = Object.keys(errors)
    .map((key) => document.querySelector(`[data-input-id="${key}"]`))
    .filter((el) => !!el);
  const firstContainer = containers.reduce((prev, curr) => {
    const { top: prevTop, left: prevLeft } = prev.getBoundingClientRect();
    const { top, left } = curr.getBoundingClientRect();
    if (top < prevTop) {
      return curr;
    } else if (top > prevTop) {
      return prev;
    } else if (left < prevLeft) {
      return curr;
    } else {
      return prev;
    }
  }, containers[0]);
  firstContainer.scrollIntoView();
  const y = 'scrollY' in window ? window.scrollY : window.pageYOffset;
  window.scrollTo(0, y - 115);
  const input = firstContainer.querySelector(`
    input.inputbox,
    textarea.inputbox,
    .Select-input input,
    .calendar_input .DateInput_input
 `);
  if (input) {
    input.focus();
  }
}
