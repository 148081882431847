import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CustomizedDocumentTitle from '../../../../components/CustomizedDocumentTitle';
import Preloader from '../../../../components/Preloader';
import ManageItem from '../components/ManageItem';
import languages from '../../../../helpers/languages';

var language = languages['en'];
var common = language.common[0];
var dashboard = language.dashboard[0];

const getListStyle = (isDraggingOver) => null;

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
});

const CustomizedDraggableList = ({
  droppableId,
  list = [],
  toggleSection,
  isHidden,
  isPhone,
  t,
}) => (
  <Droppable droppableId={droppableId}>
    {(provided, snapshot) => (
      <div
        ref={provided.innerRef}
        style={getListStyle(snapshot.isDraggingOver)}
      >
        {list.map((section, index) => (
          <Draggable
            key={section.identifier}
            draggableId={section.identifier}
            index={index}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
              >
                <ManageItem
                  title={section.title}
                  isDragging={snapshot.isDragging}
                  isHidden={isHidden}
                  onToggle={toggleSection.bind(null, {
                    sourceDroppableId: droppableId,
                    sourceIndex: index,
                    isPhone,
                  })}
                  t={t}
                />
              </div>
            )}
          </Draggable>
        ))}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
);

const mapStateToProps = (state) => ({
  lan: state.language.lan,
});

class Manage extends React.Component {
  componentDidMount() {
    this.props.fetchDashboard();
  }

  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    dashboard = language.dashboard[0];
    const {
      topLeft,
      topRight,
      bottomLeft,
      bottomRight,
      reorderDashboard,
      toggleSection,
      showPreloader,
      isPhone,
      t,
    } = this.props;
    return (
      <DragDropContext onDragEnd={(e) => reorderDashboard(e, isPhone)}>
        <CustomizedDocumentTitle title={dashboard.MANAGE_SECTIONS}>
          <div className='transition-page dashboard-manage'>
            <div className='innerheader innerheader-helpcenter'>
              <h1 className='h2'>{dashboard.MANAGE_SECTIONS}</h1>
            </div>
            {showPreloader ? (
              <Preloader size='medium' relative />
            ) : (
              <div className='dashboard-manage-sections'>
                <section className='dashboard-manage-section'>
                  <div className='content'>
                    <p>{common.ACTIVE_SECTIONS}</p>
                  </div>
                  <div className='row'>
                    <div className='col-sm-7'>
                      <CustomizedDraggableList
                        droppableId={isPhone ? 'visible' : 'visibleLeft'}
                        list={topLeft}
                        toggleSection={toggleSection}
                        isHidden={false}
                        isRight={false}
                        isPhone={isPhone}
                        t={t}
                      />
                    </div>
                    {!isPhone && (
                      <div className='col-sm-5'>
                        <CustomizedDraggableList
                          droppableId={isPhone ? 'visible' : 'visibleRight'}
                          list={topRight}
                          toggleSection={toggleSection}
                          isHidden={false}
                          isRight={true}
                          isPhone={isPhone}
                          t={t}
                        />
                      </div>
                    )}
                  </div>
                </section>
                <section className='dashboard-manage-section'>
                  <div className='content'>
                    <p>{common.HIDDEN_SECTIONS}</p>
                  </div>
                  <div className='row'>
                    <div className='col-sm-7'>
                      <CustomizedDraggableList
                        droppableId={isPhone ? 'hidden' : 'hiddenLeft'}
                        list={bottomLeft}
                        toggleSection={toggleSection}
                        isHidden={true}
                        isRight={false}
                        isPhone={isPhone}
                        t={t}
                      />
                    </div>
                    {!isPhone && (
                      <div className='col-sm-5'>
                        <CustomizedDraggableList
                          droppableId={isPhone ? 'hidden' : 'hiddenRight'}
                          list={bottomRight}
                          toggleSection={toggleSection}
                          isHidden={true}
                          isRight={true}
                          isPhone={isPhone}
                          t={t}
                        />
                      </div>
                    )}
                  </div>
                </section>
              </div>
            )}
          </div>
        </CustomizedDocumentTitle>
      </DragDropContext>
    );
  }
}

const sectionShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  isRight: PropTypes.bool,
  isHidden: PropTypes.bool,
});

Manage.propTypes = {
  topLeft: PropTypes.arrayOf(sectionShape),
  topRight: PropTypes.arrayOf(sectionShape),
  bottomLeft: PropTypes.arrayOf(sectionShape),
  bottomRight: PropTypes.arrayOf(sectionShape),
  reorderDashboard: PropTypes.func.isRequired,
  toggleSection: PropTypes.func.isRequired,
  showPreloader: PropTypes.bool,
  isPhone: PropTypes.bool,
};

//export default Manage;
export default connect(mapStateToProps)(Manage);
