/**
 * Location API:
 * https://ipgeolocation.io/documentation
 *
 * Weather API:
 * https://openweathermap.org/current
 *
 * Icon font:
 * hawcons https://icomoon.io
 * mapped to https://openweathermap.org/weather-conditions
 */

import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import {
  GEOIP_URL,
  GEOIP_KEY,
  WEATHER_URL,
  WEATHER_API_KEY,
} from '../constants';

const mapOpenweatherToHawcons = {
  '01d': 'sun',
  '01n': 'moon-stars',
  '02d': 'cloud-sun',
  '02n': 'cloud-moon',
  '03d': 'cloud',
  '03n': 'cloud',
  '04d': 'clouds',
  '04n': 'clouds',
  '09d': 'cloud-raindrops',
  '09n': 'cloud-raindrops',
  '10d': 'cloud-sun-raindrops',
  '10n': 'cloud-moon-raindrops',
  '11d': 'cloud-lightning',
  '11n': 'cloud-lightning',
  '13d': 'cloud-snowflakes',
  '13n': 'cloud-snowflakes',
  '50d': 'cloud-fog',
  '50n': 'cloud-fog',
};

export default class WeatherWidget extends Component {
  state = {
    interval: null,
    region: '',
    time: Date.now(),
    temperature: '',
    weatherIcon: '',
  };

  getLocation() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(resolve);
      } else {
        console.log('no geolocation');
        reject();
      }
    });
  }

  async componentDidMount() {
    const interval = setInterval(
      () =>
        this.setState({
          time: Date.now(),
        }),
      1000
    );
    this.setState({
      interval,
    });
    const position = await this.getLocation();
    if (position) {
      const weatherParams = {
        appid: WEATHER_API_KEY,
        lat: position.coords.latitude || 0.0,
        lon: position.coords.longitude || 0.0,
        units: 'metric',
      };

      const paramUrl = Object.entries(weatherParams)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

      const query = WEATHER_URL + '?' + paramUrl;

      const weather = await fetch(query).then((res) => res.json());
      if (!this.unmounted) {
        this.setState({
          region: weather.name,
          temperature: weather.main.temp,
          weatherIcon: mapOpenweatherToHawcons[weather.weather[0].icon],
        });
      }
    }
  }

  componentWillUnmount() {
    const { interval } = this.state;
    clearInterval(interval);
    this.unmounted = true;
  }

  render() {
    const { region, temperature, time, weatherIcon } = this.state;
    return (
      <div className='widget__white'>
        <div className='widget__items'>
          <WidgetSection
            mainText={moment(time).format('h:mm A')}
            secondaryText={moment(time).format('dddd, MMM D')}
            iconClass='icon-clock'
          />
          {region && (
            <WidgetSection
              mainText={`${Math.round(temperature) > 0 ? '+' : ''}${Math.round(
                temperature
              )}°C`}
              secondaryText={region}
              iconClass={`icon-${weatherIcon}`}
            />
          )}
        </div>
      </div>
    );
  }
}

const WidgetSection = ({ iconClass, secondaryText, mainText }) => (
  <div className='widget__item'>
    <div className='weather-section'>
      {iconClass && <i className={iconClass} />}

      <div className='weather-section__right'>
        <div className='info'>{secondaryText}</div>

        <div className='h3'>{mainText}</div>
      </div>
    </div>
  </div>
);
