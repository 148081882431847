import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Emoji, emojiIndex } from 'emoji-mart';
import { EMOJI_SET } from '../../../../../../../../constants';
import _ from 'lodash';
import emojiRegex from 'emoji-regex';

export const renderNativeEmoji = ({ id, colons }) =>
  (emojiIndex.emojis[id] && emojiIndex.emojis[id].native) || colons;

export const unemojify = (text) => {
  const regex = emojiRegex();
  return text.replace(regex, (emoji) => {
    // replace native emoji that is different across devices
    const item = _.find(emojiIndex.emojis, (el) => el.native === emoji);
    if (item && item.id) {
      return item.colons;
    }
    return emoji;
  });
};

export default (text = '', size = 24) => {
  // const regex = emojiRegex();
  // const renderEmoji = ({ id, skin = 1 }) => ReactDOMServer.renderToStaticMarkup(
  //   <span contentEditable={false}
  //         dangerouslySetInnerHTML={{
  //           __html: Emoji({
  //             html: true,
  //             emoji: id,
  //             set: EMOJI_SET,
  //             size,
  //             skin,
  //             fallback: (emoji, props) => emoji ? `:${emoji.short_names[0]}:` : props.emoji,
  //           }),
  //         }}/>);

  return (
    text
      // .replace(regex, emoji => {
      //   // replace native emoji that is different across devices
      //   const item = _.find(emojiIndex.emojis, el => el.native === emoji);
      //   if (item && item.id) {
      //     return renderEmoji({ id: item.id });
      //   }
      //   return emoji;
      // })
      .replace(/:([a-z0-9+_\-])+::skin-tone-\d:/g, (colons) => {
        const [id, skinTone] = colons.split(':').filter((el) => !!el);
        // const skin = skinTone.substr(-1);
        // return renderEmoji({ id, skin });
        return renderNativeEmoji({ id, colons });
      })
      .replace(/:([a-z0-9+_\-])+:(?!:skin-tone-\d:)/g, (colons) => {
        const [id] = colons.split(':').filter((el) => !!el);
        // return emojiIndex.emojis[id] ? renderEmoji({ id }) : colons;
        return renderNativeEmoji({ id, colons });
      })
  );
};
