import {
  SET_GENERAL_TAB,
  SET_DETAILS_TAB,
  SET_VERIFIER_TAB,
  SET_SERVICES_TAB,
  SET_COMMITMENTS,
  SET_CERTIFICATES,
  PROFILE_PROGRESS,
  PROFILE_IMAGE_ERROR,
  UPDATE_PROFILE,
  UPDATE_LOADER,
  RESET_TABS,
  SET_SAVE_ERRORS_FRONTEND
} from '../store/actions';

const initialState = {
  errorMessages: [],
  hasError: false,
  mainErrorMessage:null,
  loadData: null,
  setData: [],
  setCommitments: [],
  setProfileProgress: [],
  serviceData: null,
  setCertificates: [],
  saveSuccess: false,
  loaderSuccess: false,
  profileImageError: false,
  
};

export default function setting(state = initialState, action) {
  switch (action.type) {
    case SET_GENERAL_TAB:
      return {
        ...state,
        loadData: action.payload.data,
      };

    case SET_DETAILS_TAB:
      return {
        ...state,
        loadData: action.payload.data,
      };
    case SET_VERIFIER_TAB:
      return {
        ...state,
        setData: action.payload.data,
      };
    case SET_SERVICES_TAB:
      const adjust = (res) => {
        if (Array.isArray(res)) {
          return null;
        }
        if (typeof res === 'object') {
          return JSON.stringify(res);
        }
        return res;
      };
      const adjustFields = (obj, fieldsArray) =>
        Object.entries(obj).reduce((acc, [key, value]) => {
          if (fieldsArray.indexOf(key) === -1) {
            return { ...acc, [key]: value };
          }
          return { ...acc, [key]: adjust(value) };
        }, {});
      return {
        ...state,
        serviceData: adjustFields(action.payload.data, [
          'exportFrom',
          'exportTo',
          'productdataLooking',
          'productdataSelling',
          'serviceOffering',
          'servicesLooking',
        ]),
      };
    case SET_COMMITMENTS:
      return {
        ...state,
        setCommitments: action.payload.data,
      };
    case PROFILE_PROGRESS:
      return {
        ...state,
        setProfileProgress: action.payload.data,
      };
    case SET_CERTIFICATES:
      return {
        ...state,
        setCertificates: action.payload.data,
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        saveSuccess: action.payload,
        profileImageError: false,
      };
    case RESET_TABS:
      return {
        ...state,
        loadData: null,
        serviceData: null,
      };
    case PROFILE_IMAGE_ERROR:
      return {
        ...state,
        profileImageError: true,
      };
    case UPDATE_LOADER:
      return {
        ...state,
        loaderSuccess: action.payload,
      };
    case SET_SAVE_ERRORS_FRONTEND:
        return {
          ...state,
          hasError: action.payload.hasError,
          errorMessages: action.payload.errors,
          mainErrorMessage: action.payload.message,
        };
    default:
      return state;
  }
}
