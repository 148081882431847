import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import UserNameWithAvatar from '../../components/UserNameWithAvatar';
import Tag from '../../components/Tag';

const VerificationWidget = (props) => (
  <div className='verified-widget'>
    {props.verifiers.map(({ name, avatar, verified, dataId, type }, i) => (
      <div className='verified-widget-item' key={i}>
        {verified === '2' && (
          <UserNameWithAvatar {...{ name, avatar, dataId, type }} />
        )}
        {verified === '2' && (
          <Tag type='success' text={props.t.common[0].VERIFIED} />
        )}

        {verified === '1' && (
          <UserNameWithAvatar {...{ name, avatar, dataId, type }} />
        )}
        {verified === '1' && (
          <Tag type='success' text={props.t.common[0].PENDING} />
        )}

        {verified === '3' && (
          <UserNameWithAvatar {...{ name, avatar, dataId, type }} />
        )}

        {verified === '3' && (
          <Fragment>
            <Tag type='error' text={props.t.common[0].VERIFICATION_REJECTED} />
          </Fragment>
        )}
      </div>
    ))}
  </div>
);

VerificationWidget.propTypes = {
  name: PropTypes.string,
  avatar: PropTypes.string,
  verified: PropTypes.string,
  verifiers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      avatar: PropTypes.string,
      verified: PropTypes.string,
      dataId: PropTypes.number,
      type: PropTypes.string,
    })
  ),
  t: PropTypes.object,
};

VerificationWidget.defaultProps = {
  name: '',
  avatar: '',
  verified: '',
  verifiers: [],
};

const mapStateToProps = (state) => ({
  t: state.language.t,
});

export default connect(mapStateToProps)(VerificationWidget);
