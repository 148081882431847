import { SET_MY_ITEMS } from '../store/actions';

const initialState = {
  errorMessages: [],
  listData: [],
  totalItems: 0,
  sortLabel: 'Value Low to High',
};

export default function myItems(state = initialState, action) {
  switch (action.type) {
    case SET_MY_ITEMS:
      return {
        ...state,
        listData: action.payload.data,
        totalItems: action.payload.count,
        sortLabel: action.payload.sortLabel,
      };

    default:
      return state;
  }
}
