import React, { Component } from 'react';
import PropTypes from 'prop-types';
import VideoPlayButton from '../../../../../../../../../../components/VideoPlayButton';
import { GOOGLE_APIS_KEY } from '../../../../../../../../../../constants';

export default class TypeYoutubeVideo extends Component {
  constructor() {
    super();

    this.state = {};
  }

  static propTypes = {
    videoId: PropTypes.string.isRequired,
  };

  componentDidMount() {
    const { videoId } = this.props;

    fetch(
      `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=snippet&fields=items(snippet(title,thumbnails))&key=${GOOGLE_APIS_KEY}`
    )
      .then((response) => response.json())
      .then(({ items }) => {
        if (items.length < 1) {
          return;
        }

        const {
          snippet: { title, thumbnails },
        } = items[0];

        this.setState({
          thumb: thumbnails.default.url,
          title,
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }

  render() {
    const { videoId } = this.props;
    const { title, thumb } = this.state;
    const link = `https://www.youtube.com/watch?v=${videoId}`;

    return (
      <div className='chat-video'>
        {thumb && (
          <a href={link} className='chat-video__thumb' target='_blank' rel="noreferrer">
            <VideoPlayButton
              index='0'
              height='60'
              rBig={25}
              rSmall={17}
              href={thumb}
            />
          </a>
        )}

        <div className='chat-video__right'>
          {}
          <a className='chat-source-link' href={link} target='_blank' rel="noreferrer">
            {link}
          </a>

          <p className='chat-video__title'>{title}</p>
        </div>
      </div>
    );
  }
}
