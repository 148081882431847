import React from 'react';
import lifecycle from 'react-pure-lifecycle';
import { connect } from 'react-redux';

import Page from '../components/Page';
import CardOfMember from '../components/CardOfMember';
import NoDataCard from '../components/Card/NoDataCard';
import Member from '../components/Member';
import {
  fetchMemberDetails,
  updateContact,
  addContact,
  fetchRequest,
  fetchReviews,
  addReview,
} from '../store/actions';
import {
  COUNTRY,NAME 
} from '../components/Search/TopFilters/types';

const toolbar = ({ t }) => ({
  tabs: {
    values: {},
    filterByKeys: (card) => [card.type],
  },
  sort: [
    { orderBy: 'date', order: 'ASC', label: t.common[0].OLDEST },
    { orderBy: 'date', order: 'DESC', label: t.common[0].RECENT },
  ],
});

const mapStateToProps = (state) => {
  return {
    cards: state.network.request,
    totalItems: state.network.totalRequestItems,
    sortLabel: state.network.sortRequestLabel,
    detail: state.members.memberDetails,
    reviews: state.members.reviews,
    totalReviews: state.members.totalReviews,
    userRole: state.auth.user.userRole,
    t: state.language.t,
  };
};
const mapDispatchToProps = {
  fetchMemberDetails,
  updateContact,
  addContact,
  fetchRequest,
  fetchReviews,
  addReview,
};

const methods = {
  componentDidMount(props) {
    props.fetchRequest(window.location.hash);
    var queryParam = window.location.hash.split('/');
    if (queryParam[3] && queryParam[4]) {
      props.fetchMemberDetails(window.location.hash);
      props.fetchReviews(window.location.hash, 0, 1);
    }
  },
};

const PageRequestContacts = (props) => {
  // eslint-disable-next-line no-shadow
  if (props.userRole !== 'member') {
    var roleFlag = false;
  } else {
    var roleFlag = false;
  }
  const { cards, totalItems, detail, reviews, t } = props;
  return (
    <Page
      name={t.common[0].REQUEST_CONTACTS}
      handleIds={true}
      canCreateNew={roleFlag}
      topfilters={[NAME, COUNTRY]}
      toolbar={toolbar({ t })}
      fetchFunction={props.fetchRequest}
      totalItems={totalItems}
      sortLabel={props.sortLabel}
      cards={cards}
      // eslint-disable-next-line no-shadow
      renderCards={(cards, filter, page, cardsPerPage) => (
        <div className='cards-list'>
          {cards.length > 0 &&
            cards.map((card, i) => (
              <CardOfMember
                key={card.id || i}
                detailUrl='request_contacts'
                pageType='request_contacts'
                {...card}
                detailFunction={props.fetchMemberDetails}
                updateContactFunction={props.updateContact}
              />
            ))}
          {cards.length === 0 && <NoDataCard />}
        </div>
      )}
      cardsPerPage={6}
      {...props}
      renderDetail={(match) => (
        <Member
          {...detail}
          pageType='requestContactsDetail'
          updateContactFunction={props.updateContact}
          addContactFunction={props.addContact}
          reviews={reviews}
          totalReviews={props.totalReviews}
          fetchReviewsFunction={props.fetchReviews}
          addReviewFunction={props.addReview}
        />
      )}
    />
  );
};

PageRequestContacts.defaultProps = {
  cards: [],
};

const PageRequestContactsCycle = lifecycle(methods)(PageRequestContacts);
const List = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageRequestContactsCycle);

export default List;
