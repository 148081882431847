import DropdownTree from '../components/settings/DropdownTree';
import {
  COUNTRIES_CHANGE,
  DATE_CHANGE,
  FOCUS_CHANGE,
  MULTISELECT_ADD,
  MULTISELECT_REMOVE,
  RANGE_CHANGE,
  RANGE_CHANGE_COMPLETE,
  RESET,
  SELECT_CHANGE,
  PRICE_RANGE_CHANGE,
  PRODUCT_ID_CHANGE,
  REFRESH_RESULTS,
  OFFERS,
  TENDERS,
  EVENTS,
  SEARCH_SUBMIT,
  SET_COUNTRIES_FILTER_OPTIONS,
  SET_MY_ITEMS,
  SET_MY_FAVORITES,
  SET_EVENTS_SECTOR_FILTER_OPTIONS,
  SET_EVENTS_TYPE_FILTER_OPTIONS,
  EVENT_TYPE_CHANGE,
  EVENT_SECTOR_CHANGE,
  NAME_CHANGE,
  VERIFIERS,
  WEBINAR_SECTOR_CHANGE,
  SET_WEBINARS_SECTOR_FILTER_OPTIONS,
  WEBINARS,
} from '../store/constants';
import queryString from 'query-string';
import axios from '../requests/platformApi';

const initialState = {
  filtersTouched: false,
  query: '',

  // topbar product id
  productId: null,
  productIdCount: 0,

  // topbar service id
  serviceId: null,
  serviceIdCount: 0,

  // country
  countriesTopOption: [],
  selectedCountries: [],
  selectedCountriesIds: [],

  // topbar start end date
  datepicker: {
    opening: {
      end_date: null,
      focused_input: null,
      start_date: null,
    },
    closing: {
      end_date: null,
      focused_input: null,
      start_date: null,
    },
  },

  // topbar value range
  priceRange: null,
  results: [
    { value: 1100 },
    { value: 1200 },
    { value: 1150 },
    { value: 1500 },
    { value: 1400 },
    { value: 1220 },
  ],

  // topbar event type
  eventsTypeTopOption: [],
  selectedEventsType: [],
  selectedEventsTypeIds: [],

  // topbar event sector
  eventsSectorTopOption: [],
  selectedEventsSector: [],
  selectedEventsSectorIds: [],

  // topbar name filter
  name: '',

  // search dropdown products and services
  productsBuy: null,
  servicesBuy: null,
  productsOffer: null,
  servicesOffer: null,

  // search dropdown women percentage
  womenPercentage: 0,

  // search dropdown experience
  experience: undefined,

  // topbar webinar sector
  webinarsSectorTopOption: [],
  selectedWebinarsSector: [],
  selectedWebinarsSectorIds: [],
};

const selectCountriesFromList = (list, selectionIds) =>
  list.filter(({ value }) => selectionIds.indexOf(value) !== -1);

const actionHandlers = {
  [COUNTRIES_CHANGE]: (state, action) => {
    const { payload = {} } = action;
    const { selectedCountries } = payload;
    return {
      ...state,
      filtersTouched: true,
      selectedCountries,
      selectedCountriesIds: selectedCountries.map(({ value }) => value),
    };
  },

  [EVENT_TYPE_CHANGE]: (state, action) => {
    const { payload = {} } = action;
    const { selectedEventsType } = payload;
    return {
      ...state,
      filtersTouched: true,
      selectedEventsType,
      selectedEventsTypeIds: selectedEventsType.map(({ value }) => value),
    };
  },

  [EVENT_SECTOR_CHANGE]: (state, action) => {
    const { payload = {} } = action;
    const { selectedEventsSector } = payload;
    return {
      ...state,
      filtersTouched: true,
      selectedEventsSector,
      selectedEventsSectorIds: selectedEventsSector.map(({ value }) => value),
    };
  },

  [WEBINAR_SECTOR_CHANGE]: (state, action) => {
    const { payload = {} } = action;
    const { selectedWebinarsSector } = payload;
    return {
      ...state,
      filtersTouched: true,
      selectedWebinarsSector,
      selectedWebinarsSectorIds: selectedWebinarsSector.map(
        ({ label }) => label
      ),
    };
  },

  [DATE_CHANGE]: (state, action) => ({
    ...state,
    filtersTouched: true,
    datepicker: {
      ...state.datepicker,
      [action.picker]: {
        ...state.datepicker[action.picker],
        start_date: action.startDate,
        end_date: action.endDate,
      },
    },
  }),

  [FOCUS_CHANGE]: (state, action) => ({
    ...state,
    filtersTouched: true,
    datepicker: {
      ...state.datepicker,
      [action.picker]: {
        ...state.datepicker[action.picker],
        focused_input: action.focusedInput,
      },
    },
  }),

  // this action allows to set translated countries options
  [SET_COUNTRIES_FILTER_OPTIONS]: (state, action) => {
    // selected countries labels are updated based on selected country ids
    const selection = selectCountriesFromList(
      action.payload,
      state.selectedCountriesIds
    );
    return {
      ...state,
      countriesTopOption: action.payload,
      selectedCountries: selection,
    };
  },

  [SET_EVENTS_SECTOR_FILTER_OPTIONS]: (state, action) => {
    const sectorsList = action.payload.data;
    return {
      ...state,
      eventsSectorTopOption: sectorsList.map(({ sector, id }) => ({
        label: sector,
        value: id,
      })),
    };
  },

  [SET_EVENTS_TYPE_FILTER_OPTIONS]: (state, action) => {
    const typesList = action.payload.data;
    return {
      ...state,
      eventsTypeTopOption: typesList.map(({ type, id }) => ({
        label: type,
        value: id,
      })),
    };
  },

  [SET_WEBINARS_SECTOR_FILTER_OPTIONS]: (state, action) => {
    const sectorsList = action.payload.data;
    return {
      ...state,
      webinarsSectorTopOption: sectorsList.map(({ sector, id }) => ({
        label: sector,
        value: id,
      })),
    };
  },

  [MULTISELECT_ADD]: (state, action) => ({
    ...state,
    filtersTouched: true,
    [action.inputId]: {
      ...state[action.inputId],
      options: {
        ...state[action.inputId].options,
        [action.optionId]: {
          ...state[action.inputId].options[action.optionId],
          selected: action.type === MULTISELECT_ADD,
        },
      },
    },
  }),

  [MULTISELECT_REMOVE]: (state, action) =>
    actionHandlers[MULTISELECT_ADD](state, action),

  [RANGE_CHANGE]: (state, action) => {
    if (action.type === RANGE_CHANGE_COMPLETE) {
      // eslint-disable-next-line no-console
      //console.log('range changed, need to query server here...');
    }
    return {
      ...state,
      filtersTouched: true,
      womenPercentage: action.value,
    };
  },
  [RANGE_CHANGE_COMPLETE]: (state, action) =>
    actionHandlers[RANGE_CHANGE](state, action),

  [RESET]: (state) => ({
    ...initialState,
    countriesTopOption: state.countriesTopOption,
    eventsTypeTopOption: state.eventsTypeTopOption,
    eventsSectorTopOption: state.eventsSectorTopOption,
    webinarsSectorTopOption: state.webinarsSectorTopOption,
  }),

  [SELECT_CHANGE]: (state, action) => ({
    ...state,
    filtersTouched: true,
    [action.inputId]: {
      ...state[action.inputId],
      selectedId: action.optionId,
    },
  }),

  [PRICE_RANGE_CHANGE]: (state, action) => ({
    ...state,
    filtersTouched: true,
    priceRange: action.payload,
  }),

  [PRODUCT_ID_CHANGE]: (state, action) => {
    const {
      productId,
      serviceId,
      productsOffer,
      servicesOffer,
      productsBuy,
      servicesBuy,
    } = action.payload;
    const result = { ...state, filtersTouched: true };
    const isJsonParseable = (v) => typeof v === 'string' || v === null;
    if (isJsonParseable(productId)) {
      const parsed = JSON.parse(productId);
      result.productId = productId;
      result.productIdCount = DropdownTree.countSelection(parsed);
    }
    if (isJsonParseable(serviceId)) {
      const parsed = JSON.parse(serviceId);
      result.serviceId = serviceId;
      result.serviceIdCount = DropdownTree.countSelection(parsed);
    }
    if (isJsonParseable(productsOffer)) {
      result.productsOffer = productsOffer;
    }
    if (isJsonParseable(servicesOffer)) {
      result.servicesOffer = servicesOffer;
    }
    if (isJsonParseable(productsBuy)) {
      result.productsBuy = productsBuy;
    }
    if (isJsonParseable(servicesBuy)) {
      result.servicesBuy = servicesBuy;
    }
    return result;
  },

  [NAME_CHANGE]: (state, action) => ({
    ...state,
    filtersTouched: true,
    name: action.payload,
  }),

  [SEARCH_SUBMIT]: (state, action) => {
    const { countries, womenPercentage, experience } = action.payload;
    return {
      ...state,
      experience,
      selectedCountries: countries
        ? selectCountriesFromList(state.countriesTopOption, countries)
        : state.selectedCountries,
      selectedCountriesIds: countries || state.selectedCountriesIds,
      womenPercentage: womenPercentage || state.womenPercentage,
      filtersTouched: !!countries,
    };
  },
};

function refreshResults(getState, dispatch) {
  const { search } = getState();
  var sortLabel = 'Recent';
  let url = window.location.hash.replace('#', '');
  let verifierFlag = 0;
  if (url.indexOf('verification') !== -1) {
    url = '/verifiers';
    verifierFlag = 1;
  }
  let param = window.location.hash;

  if (param) {
    let queryParam = param.split('?')[1];
    let sortLabel = 'Value Low to High';
    if (queryParam != undefined && queryParam['orderBy'] == 'date') {
      if (queryParam['order'] == 'ASC') sortLabel = 'Oldest';
      else sortLabel = 'Recent';
    } else if (queryParam != undefined && queryParam['order'] == 'DESC') {
      sortLabel = 'Value High to Low';
    }
    if (verifierFlag == 1) {
      let queryParamNew = queryString.parse(
        decodeURIComponent(param.split('?')[1])
      );
      sortLabel = 'Recent';
      if (queryParamNew != undefined && queryParamNew['order'] === 'ASC') {
        sortLabel = 'Oldest';
      }
    }
    if (param.split('?')[1]) {
      if (verifierFlag == 1) {
        url = url + '?' + param.split('?')[1];
      }
      url = url + '&';
    }
  }

  let decodeFilter = decodeFilters(search);
  if (url.indexOf('?') !== -1) {
    url = url + '&' + decodeFilter;
  } else {
    url = url + '?' + decodeFilter;
  }

  return axios.get(url).then((res) => {
    let data = res.data;
    data.sortLabel = sortLabel;
    if (url.indexOf('offers') !== -1 && url.indexOf('my-items') == -1) {
      dispatch({
        type: OFFERS,
        payload: data,
      });
    } else if (url.indexOf('tenders') !== -1 && url.indexOf('my-items') == -1) {
      dispatch({
        type: TENDERS,
        payload: data,
      });
    } else if (url.indexOf('events') !== -1) {
      dispatch({
        type: EVENTS,
        payload: data,
      });
    } else if (url.indexOf('webinars') !== -1) {
      dispatch({
        type: WEBINARS,
        payload: data,
      });
    } else if (verifierFlag == 1) {
      dispatch({
        type: VERIFIERS,
        payload: data,
      });
    }

    if (url.indexOf('my-favorites') == 1) {
      dispatch({
        type: SET_MY_FAVORITES,
        payload: data,
      });
    }

    if (url.indexOf('my-items') == 1) {
      dispatch({
        type: SET_MY_ITEMS,
        payload: data,
      });
    }
  });
}

export const countriesChange = (selectedCountries) => {
  return async (dispatch, getState) => {
    dispatch({
      type: COUNTRIES_CHANGE,
      payload: {
        selectedCountries,
      },
    });
    refreshResults(getState, dispatch);
  };
};

export const eventsTypeChange = (selectedEventsType) => {
  return async (dispatch, getState) => {
    dispatch({
      type: EVENT_TYPE_CHANGE,
      payload: {
        selectedEventsType,
      },
    });
    refreshResults(getState, dispatch);
  };
};

export const eventsSectorChange = (selectedEventsSector) => {
  return async (dispatch, getState) => {
    dispatch({
      type: EVENT_SECTOR_CHANGE,
      payload: {
        selectedEventsSector,
      },
    });
    refreshResults(getState, dispatch);
  };
};

export const webinarsSectorChange = (selectedWebinarsSector) => {
  return async (dispatch, getState) => {
    dispatch({
      type: WEBINAR_SECTOR_CHANGE,
      payload: {
        selectedWebinarsSector,
      },
    });
    refreshResults(getState, dispatch);
  };
};

export const dateChange = ({ startDate, endDate }, picker) => {
  return async (dispatch, getState) => {
    dispatch({
      type: DATE_CHANGE,
      startDate,
      endDate,
      picker,
    });
    refreshResults(getState, dispatch);
  };
};

export const focusChange = (focusedInput, picker) => ({
  type: FOCUS_CHANGE,
  focusedInput,
  picker,
});

export const multiselectAdd = (inputId, optionId) => ({
  type: MULTISELECT_ADD,
  inputId,
  optionId,
});

export const multiselectRemove = (inputId, optionId) => ({
  type: MULTISELECT_REMOVE,
  inputId,
  optionId,
});

export const rangeChange = (value) => ({
  type: RANGE_CHANGE,
  value,
});

export const rangeChangeComplete = (value) => ({
  type: RANGE_CHANGE_COMPLETE,
  value,
});

export const reset = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: RESET,
    });
    refreshResults(getState, dispatch);
  };
};

export const selectChange = (inputId, optionId) => ({
  type: SELECT_CHANGE,
  inputId,
  optionId,
});

export const priceRangeChange = (payload) => ({
  type: PRICE_RANGE_CHANGE,
  payload,
});

export const priceRangeChangeEnd = (payload) => {
  return async (dispatch, getState) => {
    dispatch(priceRangeChange(payload));
    refreshResults(getState, dispatch);
  };
};

export const productIdChange = ({
  productId,
  serviceId,
  productsOffer,
  servicesOffer,
  productsBuy,
  servicesBuy,
}) => ({
  type: PRODUCT_ID_CHANGE,
  payload: {
    productId,
    serviceId,
    productsOffer,
    servicesOffer,
    productsBuy,
    servicesBuy,
  },
});

export const updateTopFilterTrees = ({ productId, serviceId }) => {
  return async (dispatch, getState) => {
    dispatch(productIdChange({ productId, serviceId }));
    refreshResults(getState, dispatch);
  };
};

export const nameChange = (payload) => {
  return async (dispatch, getState) => {
    dispatch({
      type: NAME_CHANGE,
      payload,
    });
    refreshResults(getState, dispatch);
  };
};

export const searchSubmit = (payload) => {
  return (dispatch) => {
    const {
      productsOffer,
      servicesOffer,
      productsBuy,
      servicesBuy,
      ...rest
    } = payload;
    dispatch(
      productIdChange({
        productsOffer,
        servicesOffer,
        productsBuy,
        servicesBuy,
      })
    );
    dispatch({
      type: SEARCH_SUBMIT,
      payload: rest,
    });
  };
};

export default (state = initialState, action) => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state;
};

function decodeFilters(search) {
  let returnObj = {};

  returnObj.opening = {};
  returnObj.closing = {};

  let query = '';
  if (search.datepicker.opening.start_date != null) {
    returnObj.opening.start = search.datepicker.opening.start_date.format(
      'YYYY-MM-DD'
    );
    query = query + 'openingStartDate=' + returnObj.opening.start + '&';
  } else {
    returnObj.opening.start = null;
    query = query + 'openingStartDate=null&';
  }

  if (search.hasOwnProperty('priceRange') && search.priceRange != null) {
    query = query + 'priceMin=' + search.priceRange.min + '&';
    query = query + 'priceMax=' + search.priceRange.max + '&';
  }

  if (search.datepicker.opening.end_date != null) {
    returnObj.opening.end = search.datepicker.opening.end_date.format(
      'YYYY-MM-DD'
    );
    query = query + 'openingEndDate=' + returnObj.opening.end + '&';
  } else {
    returnObj.opening.end = null;
    query = query + 'openingEndDate=null&';
  }

  if (search.datepicker.closing.start_date != null) {
    returnObj.closing.start = search.datepicker.closing.start_date.format(
      'YYYY-MM-DD'
    );
    query = query + 'closingStartDate=' + returnObj.closing.start + '&';
  } else {
    query = query + 'closingStartDate=null&';
  }

  if (search.datepicker.closing.end_date != null) {
    returnObj.closing.end = search.datepicker.closing.end_date.format(
      'YYYY-MM-DD'
    );
    query = query + 'closingEndDate=' + returnObj.closing.end + '&';
  } else {
    query = query + 'closingEndDate=null&';
  }

  if (search.name != null) {
    returnObj.name = search.name;
    query = query + 'name=' + returnObj.name + '&';
  } else {
    query = query + 'name=null&';
  }

  returnObj.countries = search.selectedCountriesIds;
  query += search.selectedCountriesIds
    .map((id, i) => `&countryId[${i}]=${id}`)
    .join('');

  query += search.selectedEventsTypeIds
    .map((id, i) => `&eventsType[${i}]=${id}`)
    .join('');
  query += search.selectedEventsSectorIds
    .map((id, i) => `&eventsSector[${i}]=${id}`)
    .join('');

  query += search.selectedWebinarsSectorIds
    .map((id, i) => `&webinarsSector[${i}]=${id}`)
    .join('');

  query += treeToQuery(search.productId, 'productId');
  query += treeToQuery(search.serviceId, 'serviceId');

  return query;
}

export function treeToQuery(tree, param) {
  const allItemsId = JSON.parse(tree);
  let selectItemId = [];
  let run = 0;
  let res = '';
  for (let grandKey in allItemsId) {
    //check gp exist
    if (!allItemsId.hasOwnProperty(grandKey)) {
      continue;
    }

    //check parent selected checked ADDD
    if (allItemsId[grandKey]['checked'] == true) {
      selectItemId[run] = grandKey;
      run++;
      continue;
    }

    //parent code
    if (allItemsId[grandKey].hasOwnProperty('children')) {
      for (let parentKey in allItemsId[grandKey]['children']) {
        if (!allItemsId.hasOwnProperty(grandKey)) {
          continue;
        }

        //check parent selected checked ADDD
        if (allItemsId[grandKey]['children'][parentKey]['checked'] == true) {
          selectItemId[run] = parentKey;
          run++;
          continue;
        }

        if (
          allItemsId[grandKey]['children'][parentKey].hasOwnProperty(
            'children'
          )
        ) {
          for (let childKey in allItemsId[grandKey]['children'][parentKey][
            'children'
          ]) {
            //child code
            if (
              allItemsId[grandKey]['children'][parentKey]['children'][
                childKey
              ]['checked'] == true
            ) {
              selectItemId[run] = childKey;
              run++;
              continue;
            }
          }
        }
      }
    }
  }
  if (selectItemId.length > 0) {
    // const selectionObject = JSON.parse(search.productId);
    //const ids = getIdsArrayFromDropdownTreeSelection(selectionObject);
    res += selectItemId.map((id, i) => `&${param}[${i}]=${id}`).join('');
  }
  return res;
}
