import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import InputBlock from '../../components/InputBlock';
import Select from '../../components/Select';
import languages from '../../helpers/languages';

var language = languages['en'];
var common = language.common[0];

function SignUpFormStep2(props) {
  const currLang = languages[props.lan];
  const regLang = currLang.registration[0];
  const labels = currLang.NewRegister[0];

  const fields = [

    {
      name: 'typeUser',
      placeholder: regLang.I_AM,
      required: true,
      type: 'select',
      options: [
        { value: '1', label: regLang.I_OWN_OR_I_AM_PART },
        { value: '2', label: regLang.I_AM_A_SHE_TRADES_PARTNER },
        { value: '3', label: regLang.I_AM_A_SHE_TRADES }
      ],
    },
    {
      name: 'jobPosition',
      placeholder: regLang.MAIN_ROLE,
      required: true,
      options: [
        { value: '1', label: regLang.CEO_DIRECTOR },
        { value: '2', label:  regLang.BUSINESS_OWNER},
        { value: '3', label:  regLang.SELF_EMPLOYED },
        { value: '4', label:  regLang.EMPLOYEE },
        { value: '5', label:  regLang.STUDENT },
        { value: '6', label:  regLang.OTHER }
      ],
    },
    {
      name: 'password',
      placeholder: regLang.PASSWORD,
      required: true,
      type: 'password',
    },
    {
      name: 'confirmPassword',
      placeholder: regLang.CONFIRM_PASSWORD,
      required: true,
      type: 'password',
    },
  ];
  const fieldsJsx = fields.map((field) => {
    const { options, ...inputAttrs } = field;
    const fieldProps = {
      error: props.errors[inputAttrs.name],
      inputAttrs,
      onChange: props.onChange,
      value: props.values[field.name],
      options,
    };
    return (
      <div>
        {React.createElement(options ? Select : InputBlock, fieldProps)}
      </div>
    );
  });
  return (
    <div>
      <h5>{labels.WHAT_WOULD_BEST_DESCRIBE_YOU}?</h5>
      <div class="custom-select">
        <i class="far fa-chevron-down"></i>
        {fieldsJsx[0]}
      </div>

      <h5>{labels.MY_MAIN_ROLE}</h5>
      <div class="custom-select"><i class="far fa-chevron-down"></i>
        {fieldsJsx[1]}
      </div>
      <p></p>
      <h5>{labels.TYPE_YOUR_DESIRED_PASSWORD}</h5>
      {fieldsJsx[2]}
      <h5>{labels.CONFIRM_PASSWORD}</h5>
      {fieldsJsx[3]}

      <p>{labels.BY_CREATING_AN_ACCOUNT_YOU_AGREE_TO_OUR}
        <Link to="/terms"> {regLang.TERMS}</Link> &amp;
        <Link to="/policy"> {regLang.PRIVACY}</Link></p>
      <div className='col-sm-12 col-md-12 itc-input-spacing__block'>
        <button
          className='btn'
          disabled={props.buttonDisabled}
          type='submit'
        >
          <span className='button__text'>{labels.GET_STARTED_AND_CREATE_YOUR_PROFILE}</span>
        </button>
      </div>
    </div>

  );
}

SignUpFormStep2.propTypes = {
  buttonDisabled: PropTypes.bool.isRequired,
  // captchaRef: PropTypes.object.isRequired,
  errors: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    gender: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    confirmPassword: PropTypes.string,
    country: PropTypes.string,
    jobPosition: PropTypes.string,
    typeUser: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  // onCaptchaChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    gender: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    confirmPassword: PropTypes.string,
    country: PropTypes.string,
    jobPosition: PropTypes.string,
    typeUser: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    lan: state.language.lan,
  };
};

export default connect(mapStateToProps)(SignUpFormStep2);
