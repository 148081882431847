import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import Preloader from '../components/Preloader';
import PartnersData from '../data/partners.json';
import languages from '../helpers/languages';

var language = languages['en'];
var common = language.common[0];
var labels = language.Partner[0];

const mapStateToProps = (state) => {
  return {
    lan: state.language.lan,
    user: state.auth.user,
  };
};

class Partner extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props.match.params.id)
  }
  
  state = {
    isModalOpened: false
  };

  componentDidMount() {
    
  }

  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    labels = language.Partner[0];
    
    const { showPreloader } = this.props;
    const partnerInfo=PartnersData.find(pd=>pd.id ===this.props.match.params.id)
    return (
      <CustomizedDocumentTitle title={common.MY_DASHBOARD}>
        <div className='transition-page'>
          {showPreloader ? (
            <Preloader size='medium' relative />
          ) : (
           <div>
          {/*header content */}
          <header>
            <div className="innerheader innerheader-partners_landing">
              <h1><b>{labels.MEET_OUR_PARTNER}</b>{partnerInfo.title.toUpperCase()}</h1>
            </div>
          </header>
          {/*header content */}  
          {/*access */}
          <div className="bggrey">
            <div className="wrap">
              <div className="container-2col">
                <div className="container-formating"><p dangerouslySetInnerHTML={{__html: partnerInfo.description}} /></div>
                <div><img src={partnerInfo.image} alt={partnerInfo.title} height="120" /></div>
              </div>
            </div>
          </div>
          {/*access */}
          {/*MAERSK x SheTrades */}
          <div className="bgwhite">
            <div className="whiteblockbgcircles"><div className="wrap">
                <div className="container-2col">
                  <div className="container-formating"><h2>{partnerInfo.title.toUpperCase()} x SheTrades</h2> 
                    <p dangerouslySetInnerHTML={{__html: partnerInfo.partnership}} />
                  </div>
                </div>
              </div></div>
          </div>
          {/*MAERSK x SheTrades */}
          <div className="bggrey">
            <div className="wrap">
              <div className="container-2col">
                <div className="container-formating"><h2>{labels.OPPORTUNITIES_FOR_OUR_MEMBERS}</h2>
                    <p dangerouslySetInnerHTML={{__html: partnerInfo.opportunity}} />
                </div>
                <div>
                <a href={partnerInfo.opportunity_link}  rel="noreferrer" className="btn" target="_blank">{partnerInfo.opportunity_cta}</a>
                </div>
              </div>
            </div>
          </div>
          <div className="bgwhite">
                 <div className="wrap">
                 <p dangerouslySetInnerHTML={{__html: partnerInfo.learnmore}} />
                </div>
            </div>
        </div>        
          )}
        </div>
      </CustomizedDocumentTitle>
    );
  }
}

Partner.propTypes = {
  showPreloader: PropTypes.bool,
};

export default connect(mapStateToProps)(Partner);
