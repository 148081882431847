import {
  MEMBERS,
  MEMBERDETAILS,
  REVIEWS,
  MEMBER_MESSAGE_HIDE,
  PURGE_MEMBERS,
} from '../store/actions';

const initialState = {
  members: null,
  messageShow: false,
};

export default function members(state = initialState, action) {
  switch (action.type) {
    case MEMBERS:
      return {
        ...state,
        members: action.payload.data,
        totalItems: action.payload.count,
        sortLabel: action.payload.sortLabel,
        messageShow: action.payload.data.messageShow,
      };
    case MEMBERDETAILS:
      return {
        ...state,
        memberDetails: action.payload.data,
        messageShow: action.payload.data.messageShow,
      };

    case MEMBER_MESSAGE_HIDE:
      return {
        ...state,
        messageShow: false,
      };

    case REVIEWS:
      var reviewsTemp = action.payload.data;
      if (
        state.reviewId === action.id &&
        state.userType === action.userType &&
        action.newReviewFlag !== 1
      ) {
        if (state.reviews) {
          reviewsTemp = state.reviews.concat(reviewsTemp);
        }
      }

      return {
        ...state,
        reviews: reviewsTemp,
        totalReviews: action.payload.count,
        reviewId: action.id,
        userType: action.userType,
      };

    case PURGE_MEMBERS:
      const { memberSection } = action.payload;
      if (memberSection === 'members') {
        return initialState;
      }
      return state;

    default:
      return state;
  }
}
