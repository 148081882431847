import React from 'react';
import { SelectInput } from '../../../../../components/settings/SelectInput';
import moment from 'moment';

const hoursOptions = Array(24)
  .fill(0)
  .map((el, i) => ({ label: i > 9 ? i : `0${i}`, value: i }));
const minutesOptions = Array(60)
  .fill(0)
  .map((el, i) => ({ label: i > 9 ? i : `0${i}`, value: i }));

const TimeInput = ({ value, onChange, disabled }) => {
  const m = moment(value);
  const hours = value ? m.hours() : undefined;
  const minutes = value ? m.minutes() : undefined;

  return (
    <div className='time-input'>
      <span className='time-input__half'>
        <SelectInput
          value={hours}
          onChange={(v) => onChange(m.hours(v))}
          reactSelectProps={{
            className: 'inputbox',
            placeholder: '00',
            options: hoursOptions,
            disabled,
            searchable: true,
            arrowRenderer: null,
            clearable: false,
          }}
        />
      </span>

      <span className='time-input__dots'>:</span>

      <span className='time-input__half'>
        <SelectInput
          value={minutes}
          style={{ display: 'inline-block' }}
          onChange={(v) => onChange(m.minutes(v))}
          reactSelectProps={{
            className: 'inputbox',
            placeholder: '00',
            options: minutesOptions,
            disabled,
            searchable: true,
            arrowRenderer: null,
            clearable: false,
          }}
        />
      </span>
    </div>
  );
};

export default TimeInput;
