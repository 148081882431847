import { NEWS, DETAIL_NEWS } from '../store/actions';

const initialState = {};

export default function news(state = initialState, action) {
  switch (action.type) {
    case NEWS:
      return {
        ...state,
        news: action.payload.data,
        totalItems: action.payload.count,
        sortLabel: action.payload.sortLabel,
      };
    case DETAIL_NEWS:
      return {
        ...state,
        detail: action.payload.data,
        relatednews: action.payload.relatedNews,
      };
    default:
      return state;
  }
}
