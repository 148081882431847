import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import lifecycle from 'react-pure-lifecycle';
import MultiInputField from '../../../../components/settings/MultiInput/components/Field';
import VerificationWidget from '../../../../components/settings/VerificationWidget';
import Input from '../../../../components/settings/Input';
import Tab from '../../../../components/settings/Tab';
import {
  fetchVerifiersList,
  fetchSettingsServices,
  searchCountries,
  saveProfileServicesData,
  fetchMyCommitments,
} from '../../../../store/actions';

import {
  ROUTE_FETCH_COUNTRIES,
  ROUTE_FETCH_PRODUCTS,
  ROUTE_FETCH_SERVICES,
} from '../../../../components/settings/DropdownTree/fetchDropdownTrees';
import languages from '../../../../helpers/languages';

var language = languages['en'];
var common = language.common[0];
var detail_members = language.detail_members[0];
var registration = language.registration[0];
var validation = language.registration_validation[0];

const mapStateToProps = (state) => {
  if (state.setting.hasOwnProperty('setData')) {
    if (state.setting.setData.length > 0) {
      var verifiers = [];
      var i = 0;
      for (var item in state.setting.setData) {
        verifiers[i] = {};
        verifiers[i]['dataId'] = state.setting.setData[item].dataId;
        verifiers[i]['type'] = state.setting.setData[item].type;
        verifiers[i]['name'] = state.setting.setData[item].name;
        verifiers[i]['avatar'] = state.setting.setData[item].filePath;
        verifiers[i]['verified'] =
          state.setting.setData[item].verificationRequested;
        i++;
      }
    } else {
      var verifiers = [];
    }
  } else {
    var verifiers = [];
  }

  return {
    user: state.auth.user,
    defaultData: verifiers,
    userType: state.auth.user.userRole, // TODO: check API
    serviceData: state.setting.serviceData,
    commitments: state.setting.setCommitments,
    setCertifications: state.setting.setCertificates,
    lan: state.language.lan,
  };
};

const mapDispatchToProps = {
  fetchVerifiersList,
  searchCountries,
  fetchSettingsServices,
  fetchMyCommitments,
  saveProfileServicesData,
};

const methods = {
  componentDidMount(props) {
    props.fetchVerifiersList();
    props.searchCountries();
    props.fetchSettingsServices();
    props.fetchMyCommitments();
  },
};

const commitments = {
  marginTop: '30px',
};

const ServicesAndResolutions = (props) => {
  language = languages[props.lan];
  common = language.common[0];
  detail_members = language.detail_members[0];
  registration = language.registration[0];
  validation = language.registration_validation[0];
  return (
    <Tab
      title={registration.PRODUCT_SERVICES_INFO}
      showPreloader={!props.serviceData}
      formProps={{
        initialValues: props.serviceData && {
          countries_export: props.serviceData.hasOwnProperty('exportTo')
            ? props.serviceData.exportTo
            : '',
          countries_import: props.serviceData.hasOwnProperty('exportFrom')
            ? props.serviceData.exportFrom
            : '',
          products_selling: props.serviceData.hasOwnProperty(
            'productdataSelling'
          )
            ? props.serviceData.productdataSelling
            : '',
          products_looking_for: props.serviceData.hasOwnProperty(
            'productdataLooking'
          )
            ? props.serviceData.productdataLooking
            : '',
          services_offering: props.serviceData.hasOwnProperty('serviceOffering')
            ? props.serviceData.serviceOffering
            : '',
          services_looking_for: props.serviceData.hasOwnProperty(
            'servicesLooking'
          )
            ? props.serviceData.servicesLooking
            : '',
          certifications: props.serviceData.hasOwnProperty('certificates')
            ? props.serviceData.certificates
            : '',
        },
        onSubmit: (values) => {
          props.saveProfileServicesData(values);
        },
      }}
      renderContents={({ formState }) => (
        <Fragment>
          <div className='inputs-group'>
            <div className='inputs-group__title'>
              <p className='gray'>{common.PROFILE_VERIFICATION}</p>
            </div>

            <div className='inputs'>
              <Input label={common.MY_VERIFIERS}>
                {props.defaultData !== '' && (
                  <VerificationWidget verifiers={props.defaultData} />
                )}
                {props.defaultData === '' && (
                  <span>{common.NO_VERIFIERS_FOUND}</span>
                )}
              </Input>
            </div>
          </div>
          <div className='inputs-group'>
            <div className='inputs-group__title'>
              <p className='gray'>{detail_members.CERTIFICATION}</p>
            </div>

            <div className='inputs'>
              <MultiInputField
                field='certifications'
                id='certifications'
                label={common.CHOOSE_YOUR_CERTIFICATIONS}
                placeholder={common.CERTIFICATIONS_LIST}
                options={[
                  {
                    value: 'ISO 22000:2015',
                    label: common.CERTIFICATIONS_LIST_ONE,
                  },
                  { value: 'CMMI 5', label: common.CERTIFICATIONS_LIST_TWO },
                  {
                    value: 'ISO 27000',
                    label: common.CERTIFICATIONS_LIST_THREE,
                  },
                  { value: 'ISO 9000', label: common.CERTIFICATIONS_LIST_FOUR },
                  {
                    value: 'WOTLS 53040:2013',
                    label: common.CERTIFICATIONS_LIST_FIVE,
                  },
                  { value: 'ISO9001', label: common.CERTIFICATIONS_LIST_SIX },
                  {
                    value: 'ISO10004',
                    label: common.CERTIFICATIONS_LIST_SEVEN,
                  },
                  {
                    value: 'ISO50001',
                    label: common.CERTIFICATIONS_LIST_EIGHT,
                  },
                  { value: 'ISO27001', label: common.CERTIFICATIONS_LIST_NINE },
                  { value: 'ISO22000', label: common.CERTIFICATIONS_LIST_TEN },
                  { value: 'Other', label: registration.OTHER_PLEASE_SPECIFY },
                ]}
              />
            </div>
          </div>

          <div className='inputs-group'>
            <div className='inputs-group__title'>
              <p className='gray'>{registration.EXPORT_IMPORT}</p>
            </div>

            <div className='inputs'>
              <Input
                id='countries_export'
                label={registration.COUNTRIES_EXPORT_TO}
                placeholder={registration.COUNTRY_PLACEHOLDER}
                type='dropdown-tree'
                dropdownTreeOptions={{
                  route: ROUTE_FETCH_COUNTRIES,
                  position: 'top',
                  defaultOpenedToplevel: ['ASIA'],
                }}
              />

              <Input
                id='countries_import'
                label={registration.COUNTRIES_WHERE_DO_YOU_EXPORT_FROM}
                placeholder={registration.COUNTRY_PLACEHOLDER}
                type='dropdown-tree'
                dropdownTreeOptions={{
                  route: ROUTE_FETCH_COUNTRIES,
                  position: 'top',
                  defaultOpenedToplevel: ['ASIA'],
                }}
              />

              {props.userType !== 'buyer' && (
                <Input
                  id='products_selling'
                  label={registration.PRODUCTS_IAM_SELLING}
                  required={props.userType === 'seller' ? true : false}
                  placeholder={common.CATEGORY_NAME_NUMBER_PLACEHOLDER}
                  type='dropdown-tree'
                  dropdownTreeOptions={{
                    route: ROUTE_FETCH_PRODUCTS,
                    position: 'top',
                  }}
                  informedProps={{
                    validate: (v) => {
                      if (
                        props.userType === 'seller' &&
                        (v.indexOf('"checked":true') <= 0 || v === '') &&
                        (formState.values.services_offering.indexOf(
                          '"checked":true'
                        ) <= 0 ||
                          formState.values.services_offering === '')
                      ) {
                        return validation.PRODUCT_MY_COMPANY_SELLING_ERROR;
                      }
                      return null;
                    },
                  }}
                  hasError={'products_selling' in formState.errors}
                  errors={formState.errors.products_selling}
                />
              )}

              {props.userType !== 'seller' && (
                <Input
                  id='products_looking_for'
                  required={props.userType === 'buyer' ? true : false}
                  label={registration.PRODUCTS_IAM_LOOKING_FOR}
                  placeholder={common.CATEGORY_NAME_NUMBER_PLACEHOLDER}
                  type='dropdown-tree'
                  dropdownTreeOptions={{
                    route: ROUTE_FETCH_PRODUCTS,
                    position: 'top',
                  }}
                  informedProps={{
                    validate: (v) => {
                      if (
                        (props.userType === 'buyer' &&
                          v.indexOf('"checked":true') <= 0) ||
                        v === ''
                      ) {
                        return validation.PRODUCT_MY_COMPANY_SELLING_ERROR;
                      }
                      return null;
                    },
                  }}
                  hasError={'products_looking_for' in formState.errors}
                  errors={formState.errors.products_looking_for}
                />
              )}

              {props.userType !== 'buyer' && (
                <Input
                  id='services_offering'
                  required={props.userType === 'seller' ? true : false}
                  label={registration.SERVICESS_IAM_OFFERING}
                  placeholder={common.CATEGORY_NAME_NUMBER_PLACEHOLDER}
                  type='dropdown-tree'
                  dropdownTreeOptions={{
                    route: ROUTE_FETCH_SERVICES,
                    position: 'top',
                  }}
                  informedProps={{
                    validate: (v) => {
                      if (
                        props.userType === 'seller' &&
                        (v.indexOf('"checked":true') <= 0 || v === '') &&
                        (formState.values.products_selling.indexOf(
                          '"checked":true'
                        ) <= 0 ||
                          formState.values.products_selling === '')
                      ) {
                        return validation.SERVICE_MY_COMPANY_OFFERING_ERROR;
                      }
                      return null;
                    },
                  }}
                  hasError={'services_offering' in formState.errors}
                  errors={formState.errors.services_offering}
                />
              )}

              {props.userType !== 'seller' && (
                <Input
                  id='services_looking_for'
                  required={props.userType === 'buyer' ? true : false}
                  label={registration.SERVICESS_IAM_LOOKING_FOR}
                  placeholder={common.CATEGORY_NAME_NUMBER_PLACEHOLDER}
                  type='dropdown-tree'
                  dropdownTreeOptions={{
                    route: ROUTE_FETCH_SERVICES,
                    position: 'top',
                  }}
                  informedProps={{
                    validate: (v) => {
                      if (
                        (props.userType === 'buyer' &&
                          v.indexOf('"checked":true') <= 0) ||
                        v === ''
                      ) {
                        return validation.SERVICE_MY_COMPANY_LOOKING_ERROR;
                      }
                      return null;
                    },
                  }}
                  hasError={'services_looking_for' in formState.errors}
                  errors={formState.errors.services_looking_for}
                />
              )}
            </div>
          </div>
        </Fragment>
      )}
    />
  );
};

const ServicesAndResolutionsCycle = lifecycle(methods)(ServicesAndResolutions);
const List = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServicesAndResolutionsCycle);
export default List;
