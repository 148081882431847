import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import queryString from 'query-string';
import { Form } from 'informed';
import {
  ROUTE_FETCH_COUNTRIES,
  ROUTE_FETCH_PRODUCTS,
  ROUTE_FETCH_SERVICES,
} from '../../../components/settings/DropdownTree/fetchDropdownTrees';
import Input from '../../../components/settings/Input';
import languages from '../../../helpers/languages';
import SearchFilter from '../../Search/HeaderDropdown/components/SearchFilter';
import Select from '../../../components/Search/HeaderDropdown/components/Select';
import { reset, searchSubmit } from '../../../reducers/search';
import {
  fetchSearchResult,
  fetchRegistrationInitialStates,
} from '../../../store/actions';

var language = languages['en'];
var label = language.report_abuse[0];
var menu = language.menu[0];
var dashboard = language.dashboard[0];
var common = language.common[0];
var header = language.header[0];

const mapStateToProps = (state) => {
  //countries dropdown
  var countryList = [];
  if (state.auth.hasOwnProperty('countries')) {
    countryList = state.auth.countries.map((country) => ({
      value: country.id.toString(),
      label: country.country_name,
    }));
  }
  return {
    ...state.search,
    countryList: countryList,
    lan: state.language.lan,
    isMobile: state.responsive.isMobile,
  };
};

class AsideSearchMain extends React.Component {
  static propTypes = {
    dropdownRef: PropTypes.func.isRequired,
    experience: PropTypes.number,
    fetchSearchResult: PropTypes.func,
    fetchTopFilterCountries: PropTypes.func,
    onQueryChange: PropTypes.func,
    onResetClick: PropTypes.func.isRequired,
    onSearchSubmit: PropTypes.func,
    productsBuy: PropTypes.string,
    servicesBuy: PropTypes.string,
    productsOffer: PropTypes.string,
    servicesOffer: PropTypes.string,
    selectedCountriesIds: PropTypes.array,
    toggleDropdown: PropTypes.func,
    toggleRef: PropTypes.func.isRequired,
    womenPercentage: PropTypes.number,
    isMobile: PropTypes.bool,
    location: PropTypes.object.isRequired,
    onLinkClick: PropTypes.func,
    isFull: PropTypes.bool,
  };

  static experienceOptions = [
    { label: '< 1 ' + common.YEAR, value: 2 },
    { label: '1-2 ' + common.YEARS, value: 3 },
    { label: '2-5 ' + common.YEARS, value: 4 },
    { label: '> 5 ' + common.YEARS, value: 5 },
  ];

  constructor(props) {
    super(props);
    this.state = {
      formApi: null,
      queryInput: '',
    };

    this.clear = this.clear.bind(this);
    this.onQueryInputChange = this.onQueryInputChange.bind(this);
    this.setFormApi = this.setFormApi.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    const queryObject = queryString.parse(decodeURIComponent(location.search));
    if (
      typeof queryObject.query === 'string' &&
      queryObject.query.length !== 0
    ) {
      this.setState({
        queryInput: queryObject.query,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { isMobile } = this.props;
    if (isMobile) return;
    const { formApi } = this.state;
    if (
      prevProps.selectedCountriesIds.length !==
      this.props.selectedCountriesIds.length
    ) {
      formApi.setValue('countries', this.props.selectedCountriesIds);
    }
    if (prevProps.productsOffer !== this.props.productsOffer) {
      formApi.setValue('productsOffer', this.props.productsOffer);
    }
    if (prevProps.servicesOffer !== this.props.servicesOffer) {
      formApi.setValue('servicesOffer', this.props.servicesOffer);
    }
    if (prevProps.productsBuy !== this.props.productsBuy) {
      formApi.setValue('productsBuy', this.props.productsBuy);
    }
    if (prevProps.servicesBuy !== this.props.servicesBuy) {
      formApi.setValue('servicesBuy', this.props.servicesBuy);
    }
    if (prevProps.womenPercentage !== this.props.womenPercentage) {
      formApi.setValue('womenPercentage', this.props.womenPercentage || 0);
    }
  }

  clear() {
    //const { onResetClick } = this.props;
    reset();
    this.persist({
      countries: [],
      experience: 0,
      productsBuy: null,
      servicesBuy: null,
      productsOffer: null,
      servicesOffer: null,
      womenPercentage: 0,
    });
    this.setState({
      queryInput: '',
    });
  }

  onQueryInputChange(e) {
    this.setState({
      queryInput: e.target.value,
    });
  }

  persist(values, searchUrl = null) {
    //const { fetchSearchResult, onSearchSubmit } = this.props;
    const { formApi } = this.state;
    formApi.setValues(values);
    searchSubmit(values);
    if (searchUrl) {
      fetchSearchResult(searchUrl, values, true);
    } else {
      fetchSearchResult(window.location.hash, values, true);
    }
  }

  setFormApi(formApi) {
    this.setState({
      formApi,
    });
  }

  submit() {

    const { history } = this.props;
    const { formApi, queryInput } = this.state;
    let trimmedQuery = "a";
    if (queryInput) {
      trimmedQuery = encodeURIComponent(queryInput.trim());
    }

    const formState = formApi.getState();
    if (Object.keys(formState.touched) !== 0 || trimmedQuery.length !== 0) {
      console.log(formState.values);
      const location = `${window.location.hash.split('#')[0]}?query=${trimmedQuery}`;
      this.persist(formState.values, location);
      history.push(location);
    }
  }

  getInitialValues() {
    const {
      experience,
      productsBuy,
      servicesBuy,
      productsOffer,
      servicesOffer,
      selectedCountriesIds,
      womenPercentage,
    } = this.props;

    return {
      countries: selectedCountriesIds,
      experience,
      productsBuy,
      servicesBuy,
      productsOffer,
      servicesOffer,
      womenPercentage,
    };
  }

  render() {

    const countryList = this.props.countryList;

    const {
      dropdownRef,
      isMobile,
      toggleDropdown,
      onLinkClick
    } = this.props;
    const { queryInput } = this.state;
    const initialValues = this.getInitialValues();

    language = languages[this.props.lan];
    label = language.report_abuse[0];
    menu = language.menu[0];
    dashboard = language.dashboard[0];
    common = language.common[0];
    header = language.header[0];

    return (
      <div>

        {!isMobile && (
          <Form
            onSubmit={this.submit}
            initialValues={initialValues}
            getApi={this.setFormApi}
            render={({ formState: { touched, values } }) => (
              <Fragment>
                <div
                  ref={dropdownRef}
                >
                  <div>
                    <section>
                      <h4>
                        {header.SELECT_COUNTRIES_PLACEHOLDER}</h4>

                      <Input
                        id='countries'
                        required={false}
                        type='select'
                        options={countryList}
                      />


                    </section>
                    <section>
                      <h4><i className="far fa-venus"> </i>
                        {header.OWNED_BY_WOMEN}</h4>
                      <Input
                        id='womenPercentage'
                        vertical
                        type='range'
                        rangeInputProps={{ minValue: 0, maxValue: 100 }}
                      />
                      `{values.womenPercentage}% `
                    </section>
                    <section>
                      <h4>{header.OFFER_THESE_PRODUCTS}</h4>


                      <Input
                        id='productsOffer'
                        vertical
                        type='dropdown-tree'
                        dropdownTreeOptions={{
                          route: ROUTE_FETCH_PRODUCTS,
                          dropdownType: 'dropdown',
                        }}
                      />


                    </section>
                    <section>
                      <h4>{header.OFFER_THESE_SERVICES}</h4>

                      <Input
                        id='servicesOffer'
                        vertical
                        type='dropdown-tree'
                        dropdownTreeOptions={{
                          route: ROUTE_FETCH_SERVICES,
                          dropdownType: 'dropdown',
                        }}
                      />

                    </section>
                    <section>

                      <h4>{header.BUY_THESE_PRODUCTS}</h4>

                      <Input
                        id='productsBuy'
                        vertical
                        type='dropdown-tree'
                        dropdownTreeOptions={{
                          route: ROUTE_FETCH_PRODUCTS,
                          dropdownType: 'dropdown',
                        }}
                      />

                    </section>
                    <section>
                      <h4>{header.BUY_THESE_SERVICES}</h4>

                      <Input
                        id='servicesBuy'
                        vertical
                        type='dropdown-tree'
                        dropdownTreeOptions={{
                          route: ROUTE_FETCH_SERVICES,
                          dropdownType: 'dropdown',
                        }}
                      />

                    </section>
                    <section>
                      <h4>{common.EXPORTING_EXPERIENCE}</h4>

                      <Input
                        id='experience'
                        vertical
                        type='select'

                        options={AsideSearchMain.experienceOptions}
                      />

                    </section>
                  </div>

                  <div className='buttons'>
                    <button
                      className='button'
                      type='submit'
                      onClick={this.submit}
                    >
                      <i className='icon-search' />
                      <span className='button__text'>{header.SEARCH}</span>
                    </button>

                    <button
                      className='button'
                      type='button'
                      onClick={this.clear}
                    >
                      <span className='button__text'>{header.NEW_SEARCH}</span>
                    </button>
                  </div>
                </div>
              </Fragment>
            )}
          />)}
      </div >
    );
  }
}

const mapDispatchToProps = {
  fetchSearchResult,
  fetchRegistrationInitialStates,
  onResetClick: reset,
  onSearchSubmit: searchSubmit,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AsideSearchMain));
