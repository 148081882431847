import React from 'react';
import { CountClearButton } from '../../../../components/settings/DropdownTree/DropdownTree';
import TopFilter from './TopFilter';
import Input from '../../../../components/settings/Input';
import { fetchOptions } from '../../../../services/shared';
import Select from '../../HeaderDropdown/components/Select';

const SelectTopFilter = (props) => {
  const { value, onChange, optionUrl,labelKey, id, label, buttonLabel,placeholder} = props;
  return (
    <TopFilter
      buttonText={`${buttonLabel}${value.length ? ` — ${value.length}` : ''}`}
      modified={!!value.length}
      onAfterOpen={null}
      onCancel={onChange.bind(null, [])}
      contentStyle={{ width: 690 }}
    >
      <div className='inputs'>
        <label htmlFor={id}>{label}</label>
        <Select>
        <Input
            type='select'
            id={id}
            value={value}
            selectProps={{
              async: true,
              loadOptions: fetchOptions(optionUrl, {
                labelKey: labelKey,
              }),
            }}
            onChange={onChange}
            placeholder={placeholder}
        />
        </Select>
        {value.length ? (
          <CountClearButton
            count={value.length}
            onClick={onChange.bind(null, [])}
          />
        ) : null}
      </div>
    </TopFilter>
  );
};

export default SelectTopFilter;