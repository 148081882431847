import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import Preloader from '../components/Preloader';
import languages from '../helpers/languages';

var language = languages['en'];
var common = language.common[0];

const mapStateToProps = (state) => {
  return {
    lan: state.language.lan,
    user: state.auth.user,
  };
};

class ContactUs extends React.Component {
  state = {
    isModalOpened: false
  };

  componentDidMount() {
    
  }

  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    const { showPreloader } = this.props;
    const {
      isModalOpened,
    } = this.state;
    return (
      <CustomizedDocumentTitle title={common.MY_DASHBOARD}>
        <div className='transition-page'>
          {showPreloader ? (
            <Preloader size='medium' relative />
          ) : (
            <div>
              {/*header content */}
              <header>
                <div className="innerheader innerheader_learn">
                  <h1>Contact Us</h1>
                </div>
              </header>
              {/*header content */}  
              {/*contact form */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container">
                    <p>SheTrades is here to provide you with more information and answer any questions you may have.</p>
                    <form action="/action_page.php" className="customform">
                      <label htmlFor="fname">Name *</label>
                      <input type="text" id="fname" name="firstname" />
                      <label htmlFor="femail">Email *</label>
                      <input type="text" id="femail" name="your email" />
                      <label htmlFor="fsubject">Subject *</label>
                      <div className="custom-select">
                        <select>
                          <option value={0}>Select Question:</option>
                          <option value={1}>Partnership</option>
                          <option value={2}>Account issues</option>
                          <option value={3}>Questions?</option>
                          <option value={4}>Others</option>
                        </select>
                      </div>
                      <label htmlFor="finquiry">Your inquiry *</label>
                      <textarea id="finquiry" name="finquiry" defaultValue={"\n"} />
                      <input type="submit" defaultValue="Submit request" className="btn" />
                    </form>
                  </div>
                </div>
              </div>
              {/*contact form */}
              {/* social subscribe  */}
              <div className="bgwhite">
                <div className="wrap">
                  <h2>The more, the merrier</h2>
                  <div className="container-2even">
                    <div>
                      <p>Invite friends ans colleagues to join SheTrades community. Let’s thrive together.</p>
                      <form action="/" method="post" className="subscribe_form">
                        <input name="subscribe_form" defaultValue required type="text" placeholder="Enter email…" id="subscribe_form" />
                        <button name="subscribe_form" value="search" type="submit">subscribe</button>
                      </form>
                    </div>
                    <div className="subscribesocial">
                      <p>Or share SheTrades.com on your favorite social media platform!</p>
                      <div className="socialmediaicons">
                        <NavLink to="/youtube"><i className="fab fa-youtube-square" /></NavLink>
                        <NavLink to="/facebook"><i className="fab fa-facebook-square" /></NavLink>
                        <NavLink to="/twitter"><i className="fab fa-twitter-square" /></NavLink>
                        <NavLink to="/linkedin"><i className="fab fa-linkedin" /></NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* social subscribe  */}
              {/*build */}
              <div className="bggrey">
                <div className="wrap"> <h2>Or contact directly here</h2> 
                  <div className="container-3col">
                    <p>SheTrades Team<br />
                      International Trade Centre<br />
                      Palais des Nations,<br />
                      1211 Geneva 10,<br />
                      Switzerland.</p>
                    <p>T: +41 22 730 01 11<br />
                      E: womenandtrade@intracen.org</p>
                  </div>
                </div>
              </div>
              {/*build */}
         </div>
          )}
        </div>
      </CustomizedDocumentTitle>
    );
  }
}

ContactUs.propTypes = {
  showPreloader: PropTypes.bool,
};

export default connect(mapStateToProps)(ContactUs);
