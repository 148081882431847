
export const COUNTRIES_CHANGE = 'COUNTRIES_CHANGE';
export const DATE_CHANGE = 'DATE_CHANGE';
export const EVENT_TYPE_CHANGE = 'EVENT_TYPE_CHANGE';
export const EVENT_SECTOR_CHANGE = 'EVENT_SECTOR_CHANGE';
export const FOCUS_CHANGE = 'FOCUS_CHANGE';
export const MULTISELECT_ADD = 'MULTISELECT_ADD';
export const MULTISELECT_REMOVE = 'MULTISELECT_REMOVE';
export const RANGE_CHANGE = 'RANGE_CHANGE';
export const RANGE_CHANGE_COMPLETE = 'RANGE_CHANGE_COMPLETE';
export const RESET = 'RESET';
export const SELECT_CHANGE = 'SELECT_CHANGE';
export const PRICE_RANGE_CHANGE = 'PRICE_RANGE_CHANGE';
export const PRODUCT_ID_CHANGE = 'PRODUCT_ID_CHANGE';
export const NAME_CHANGE = 'NAME_CHANGE';
export const REFRESH_RESULTS = 'REFRESH_RESULTS';
export const SEARCH_SUBMIT = 'SEARCH_SUBMIT';

export const FETCH_DASHBOARD = 'FETCH_DASHBOARD';
export const DASHBOARD_FETCHED = 'DASHBOARD_FETCHED';
export const TOGGLE_DASHBOARD_SECTION = 'TOGGLE_DASHBOARD_SECTION';
export const DASHBOARD_REORDER = 'DASHBOARD_REORDER';

export const FORUM_TAGS = 'FORUM_TAGS';
export const FORUM_TOPIC = 'FORUM_TOPIC';
export const FORUM_DETAIL = 'FORUM_DETAIL';
export const FORUM_ANSWERS = 'FORUM_ANSWERS';
export const FORM_TAG_OPTIONS = 'FORM_TAG_OPTIONS';
export const FORUM_ALL_ANSWERS = 'FORUM_ALL_ANSWERS';
export const CREATE_FORUM_SUCCESS = 'CREATE_FORUM_SUCCESS';
export const MARKET_TOOLS = 'MARKET_TOOLS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const HANDLE_FORUM_ERROR = 'HANDLE_FORUM_ERROR';
export const COUNTRIES = 'COUNTRIES';
export const SET_CITIES = 'SET_CITIES';
export const SET_USER_TYPE_VALIDATION_TRUE = 'SET_USER_TYPE_VALIDATION_TRUE';
export const SET_USER_TYPE_VALIDATION_FALSE = 'SET_USER_TYPE_VALIDATION_FALSE';
export const SAVE_INFO_TAB_STATE = 'SAVE_INFO_TAB_STATE';
export const SAVE_COMPANIES_TAB_STATE = 'SAVE_COMPANIES_TAB_STATE';
export const SAVE_PRODUCTS_TAB_STATE = 'SAVE_PRODUCTS_TAB_STATE';
export const MEMBERS = 'MEMBERS';
export const ADD_EVENT_BOOKMARK = 'ADD_EVENT_BOOKMARK';
export const MEMBERDETAILS = 'MEMBERDETAILS';
export const REVIEWS = 'REVIEWS';
export const VERIFIERS = 'VERIFIERS';
export const ADD_FORUM_BOOKMARK = 'ADD_FORUM_BOOKMARK';

export const EVENTS = 'EVENTS';
export const DETAIL_EVENTS = 'DETAIL_EVENTS';
export const SET_EVENT_PREVIEW = 'SET_EVENT_PREVIEW';
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS';

export const NEWS = 'NEWS';
export const DETAIL_NEWS = 'DETAIL_NEWS';

export const VERIFIER_STATUS = 'VERIFIER_STATUS';

export const REGISTER = 'REGISTER';
export const FILL_ALL_FORMS = 'FILL_ALL_FORMS';
export const REGISTER_SAVE_ERRORS = 'REGISTER_SAVE_ERRORS';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTER_SAVE_ERRORS_FRONTEND = 'REGISTER_SAVE_ERRORS_FRONTEND';
export const DECLARATION_ERROR = 'DECLARATION_ERROR';
export const MY_NETWORK = 'MY_NETWORK';
export const KNOWN_USER = 'KNOWN_USER';
export const USER_REQUEST = 'USER_REQUEST';
export const TENDERS = 'TENDERS';
export const TENDERDETAILS = 'TENDERDETAILS';
export const TENDERAPPLIED = 'TENDERAPPLIED';
export const OFFERS = 'OFFERS';
export const OFFERDETAILS = 'OFFERDETAILS';
export const OFFERAPPLIED = 'OFFERAPPLIED';
export const ADD_OFFER_BOOKMARK = 'ADD_OFFER_BOOKMARK';
export const ADD_TENDER_BOOKMARK = 'ADD_TENDER_BOOKMARK';
export const CLOSE_VALIDATION_ERROR = 'CLOSE_VALIDATION_ERROR';
export const CLOSE_REGISTRATION_MESSAGE = 'CLOSE_REGISTRATION_MESSAGE';
export const BUSINESS = 'BUSINESS';
export const PRODUCT_SEARCH = 'PRODUCT_SEARCH';
export const COUNTRY_SEARCH = 'COUNTRY_SEARCH';
export const HANDLE_OFFER_ERROR = 'HANDLE_OFFER_ERROR';
export const HANDLE_EVENT_ERROR = 'HANDLE_EVENT_ERROR';
export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_EXISTING_COMPANY = 'SET_EXISTING_COMPANY';
export const SET_NEW_COMPANY = 'SET_NEW_COMPANY';

export const SET_OFFER_PREVIEW = 'SET_OFFER_PREVIEW';
export const ADD_OFFER_SUCCESS = 'ADD_OFFER_SUCCESS';
export const HANDLE_TENDER_ERROR = 'HANDLE_TENDER_ERROR';
export const SET_TENDER_PREVIEW = 'SET_TENDER_PREVIEW';
export const ADD_TENDER_SUCCESS = 'ADD_TENDER_SUCCESS';
export const SET_COUNTRIES_FILTER_OPTIONS = 'SET_COUNTRIES_FILTER_OPTIONS';
export const SET_EVENTS_SECTOR_FILTER_OPTIONS =
  'SET_EVENTS_SECTOR_FILTER_OPTIONS';
export const SET_EVENTS_TYPE_FILTER_OPTIONS = 'SET_EVENTS_TYPE_FILTER_OPTIONS';
export const LOGOUT = 'LOGOUT';
export const TOGGLE_APPLY_SUCCESS = 'TOGGLE_APPLY_SUCCESS';
export const TOGGLE_DETAIL_APPLY = 'TOGGLE_DETAIL_APPLY';
export const VIDEOS = 'VIDEOS';
export const VIDEO_DETAILS = 'VIDEO_DETAILS';
export const VIDEO_COMMENTS = 'VIDEO_COMMENTS';
export const PUBLICATIONS = 'PUBLICATIONS';
export const PUBLICATION_DETAILS = 'PUBLICATION_DETAILS';
export const SUCCESSTORY = 'SUCCESSTORY';
export const SUCCESSTORY_DETAILS = 'SUCCESSTORY_DETAILS';
export const PURGE_MEMBERS = 'PURGE_MEMBERS';

export const E_LEARN_CONTENT = 'E_LEARN_CONTENT';
export const SAT_CONTENT = 'SAT_CONTENT';

export const E_INVEST_CONTENT = 'E_INVEST_CONTENT';

export const DO_NOTHING = 'DO_NOTHING';
export const SET_MY_ITEMS = 'SET_MY_ITEMS';
export const SET_MY_FAVORITES = 'SET_MY_FAVORITES';
//settings
export const SET_GENERAL_TAB = 'SET_GENERAL_TAB';
export const SET_DETAILS_TAB = 'SET_DETAILS_TAB';
export const SET_VERIFIER_TAB = 'SET_VERIFIER_TAB';
export const SET_SERVICES_TAB = 'SET_SERVICES_TAB';
export const RESET_TABS = 'RESET_TABS';
export const SET_SAVE_ERRORS_FRONTEND = 'SET_SAVE_ERRORS_FRONTEND';

export const COACHES = 'COACHES';
export const COACH_STATUS = 'COACH_STATUS';
export const SEARCH_RESULT_FETCHED = 'SEARCH_RESULT_FETCHED';
export const FETCH_SEARCH_RESULT = 'FETCH_SEARCH_RESULT';
export const ATTEMPT_RESET_PASSWORD = 'ATTEMPT_RESET_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CLOSE_RESET_MESSAGE = 'CLOSE_RESET_MESSAGE';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const RESET_ON_MOUNT = 'RESET_ON_MOUNT';

export const FAQS = 'FAQS';
export const STATIC_PAGES = 'STATIC_PAGES';

export const COMMITMENTS_LIST = 'COMMITMENTS_LIST';
export const COMMITMENTS_QUESTIONS = 'COMMITMENTS_QUESTIONS';
export const COMMITMENT_SUCCESS = 'COMMITMENT_SUCCESS';
export const SET_COMMITMENTS = 'SET_COMMITMENTS';
export const PROFILE_PROGRESS = 'PROFILE_PROGRESS';
export const SET_CERTIFICATES = 'SET_CERTIFICATES';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_LOADER = 'UPDATE_LOADER';
export const PROFILE_IMAGE_ERROR = 'PROFILE_IMAGE_ERROR';
export const CLOSE_UPDATE_SUCCESS = 'CLOSE_UPDATE_SUCCESS';
export const ABUSESUCCESS = 'ABUSESUCCESS';
// messenger
export const UPDATE_MESSENGER_CACHE = 'UPDATE_MESSENGER_CACHE';
export const APPLOZIC_SOCKET_EVENT = 'APPLOZIC_SOCKET_EVENT';
export const SET_APPLOZIC_API = 'SET_APPLOZIC_API';
export const TOGGLE_APPLOZIC_API_FETCHING = 'TOGGLE_APPLOZIC_API_FETCHING';
export const APPEND_CURRENT_CHAT = 'APPEND_CURRENT_CHAT';
export const RELOAD_CHAT = 'RELOAD_CHAT';
export const SET_TOTAL_UNREAD_COUNT = 'SET_TOTAL_UNREAD_COUNT';
export const TYPING_STATUS_CHANGE = 'TYPING_STATUS_CHANGE';
export const SET_CHATS = 'SET_CHATS';
export const SET_CONTACTS_OPTIONS = 'SET_CONTACTS_OPTIONS';
export const SET_GROUPS = 'SET_GROUPS';
export const SET_GROUPS_USER_LIST = 'SET_GROUPS_USER_LISTSET_GROUPS_USER_LIST';
export const SET_CONTACTS_OPTIONS_FOR_SEARCH =
  'SET_CONTACTS_OPTIONS_FOR_SEARCH';
export const APPEND_USER_OR_GROUP = 'APPEND_USER_OR_GROUP';
export const RESET_CHATS = 'RESET_CHATS';
export const READ_UPDATE = 'READ_UPDATE';

export const PROFILE_BELL_NOTIFICATION_COUNT =
  'PROFILE_BELL_NOTIFICATION_COUNT';
export const FETCHED_BELL_NOTIFICATIONS = 'FETCHED_BELL_NOTIFICATIONS';
export const SET_NOTIFICATION_TAB_DATA = 'SET_NOTIFICATION_TAB_DATA';

export const COACH_MESSAGE_HIDE = 'COACH_MESSAGE_HIDE';
export const VERIFIER_MESSAGE_HIDE = 'VERIFIER_MESSAGE_HIDE';
export const MEMBER_MESSAGE_HIDE = 'MEMBER_MESSAGE_HIDE';
export const NETWORK_MESSAGE_HIDE = 'NETWORK_MESSAGE_HIDE';
export const BUSINESS_MESSAGE_HIDE = 'BUSINESS_MESSAGE_HIDE';

export const APPEND_NOTIFICATIONS = 'APPEND_NOTIFICATIONS';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const CLOSE_SUCCESS = 'CLOSE_SUCCESS';
export const CLOSE_SUCCESS_OFFER = 'CLOSE_SUCCESS_OFFER';
export const VERIFIER_REGISTER_SUCCESS = 'VERIFIER_REGISTER_SUCCESS';
export const CONTACT_REMOVE_FLAG = 'CONTACT_REMOVE_FLAG';

export const WEBINARS = 'WEBINARS';
export const DETAIL_WEBINARS = 'DETAIL_WEBINARS';
export const WEBINAR_SECTOR_CHANGE = 'WEBINAR_SECTOR_CHANGE';
export const SET_WEBINARS_SECTOR_FILTER_OPTIONS =
  'SET_WEBINARS_SECTOR_FILTER_OPTIONS';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';
export const CLOSE_RESET_ERROR_MESSAGE = 'CLOSE_RESET_ERROR_MESSAGE';
export const VERIFIER_SUCCESS_MESSAGE = 'VERIFIER_SUCCESS_MESSAGE';
export const CLOSE_VERIFIER_SUCCESS_MESSAGE = 'CLOSE_VERIFIER_SUCCESS_MESSAGE';

export const PREVIEW_ACCESS_ERROR = 'PREVIEW_ACCESS_ERROR';
export const SOCIAL_MISSING_INFO = 'SOCIAL_MISSING_INFO';
export const SET_SESSION = 'SET_SESSION';
// export const SET_GOOGLE_TRANSLATE = 'SET_GOOGLE_TRANSLATE';

export const PROJECTPAGES = 'PROJECTPAGES ';
export const DETAIL_PROJECTPAGES = 'DETAIL_PROJECTPAGES';
export const HUBPAGES = 'HUBPAGES ';
export const DETAIL_HUBPAGES = 'DETAIL_HUBPAGES';
