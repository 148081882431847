import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { listTransition } from '../../../../constants';
import ListOfNotifications from '../../../../components/ListOfNotifications';
import { fetchMessageTabData } from '../../../../store/actions';
import CustomizedDocumentTitle from '../../../../components/CustomizedDocumentTitle';
import Search from './components/Search';
import { fetchContacts, fetchNotifications } from './fetchApplozic';
import Groups from '../Groups';
import languages from '../../../../helpers/languages';

var language = languages['en'];
var messages = language.messages[0];

const mapStateToProps = (state) => ({
  chats: state.messenger.chats.chatList,
  contactDropOptions: state.messenger.contactDropOptions,
  lan: state.language.lan,
});

const mapDispatchToProps = {
  fetchContacts,
  fetchMessageTabData,
};

class MessengerTabs extends Component {
  static propTypes = {
    chats: PropTypes.array,
    contactDropOptions: PropTypes.array,
    fetchContacts: PropTypes.func,
    fetchMessageTabData: PropTypes.func,
    location: PropTypes.object.isRequired,
    tabs: PropTypes.array.isRequired,
  };

  static defaultProps = {
    tabs: [
      {
        id: 'chats',
        icon: 'messenger--chats',
      },
      {
        id: 'groups',
        icon: 'messenger--contacts',
      },
      {
        id: 'notifications',
        icon: 'messenger--activity',
      },
    ],
    notificationFilters: [
      {
        id: 'all',
        icon: 'info',
        name: messages.ALL_NOTIFICATIONS,
        text: messages.NOTIFICATIONS,
      },
      {
        id: 'tenders-and-offers',
        icon: 'gavel',
        name: messages.TENDERS_AND_OFFERS,
        text: messages.NOTIFICATION_CATEGORY,
      },
      {
        id: 'verification-requests',
        icon: 'check--circle',
        name: messages.VERIFICATION_REQUESTS,
        text: messages.NOTIFICATION_CATEGORY,
      },
      {
        id: 'comments',
        icon: 'comment--notification',
        name: messages.NEW_COMMENTS,
        text: messages.NOTIFICATION_CATEGORY,
      },
    ],
  };

  state = {
    search: '',
  };

  componentDidMount() {
    const { fetchContacts } = this.props;
    if (fetchContacts) {
      // fetchContacts();
    }
  }

  render() {
    language = languages[this.props.lan];
    messages = language.messages[0];

    const {
      location,
      tabs,
      fetchMessageTabData,
      chats,
      contactDropOptions,
    } = this.props;
    const { search } = this.state;
    const queryObject = queryString.parse(location.search);
    const tab = queryObject.tab || tabs[0].id;

    return (
      <aside className='messenger-sidebar'>
        <Search
          value={search}
          onChange={(search) => this.setState({ search })}
        />

        <ul className='messenger-tabs-toggles'>
          {tabs.map(({ id, icon }) => {
            const toObject = {
              ...queryObject,
              tab: id,
              item: undefined,
              order: undefined,
              orderBy: undefined,
            };

            return (
              <li key={id}>
                <NavLink
                  onClick={() =>
                    fetchMessageTabData(
                      `${location.pathname}?${queryString.stringify(toObject)}`
                    )
                  }
                  to={`${location.pathname}?${queryString.stringify(toObject)}`}
                  isActive={() => tab === id}
                >
                  <i className={`icon-${icon}`} />
                </NavLink>
              </li>
            );
          })}
        </ul>

        <div className='messenger-tabs-outer'>
          <Scrollbars autoHide>
            <div className='messenger-tabs'>
              <TransitionGroup className='transition-container'>
                <CSSTransition key={tab} {...listTransition}>
                  <div className='transition-page'>
                    {tab === 'chats' && (
                      <CustomizedDocumentTitle title={messages.MY_MESSAGES}>
                        <ListOfNotifications
                          items={chats}
                          fullyClickable
                          tab='chats'
                          itemClassName='notification notification_big'
                          searchQuery={search}
                        />
                      </CustomizedDocumentTitle>
                    )}

                    {tab === 'groups' && (
                      <CustomizedDocumentTitle title={messages.ALL_CONTACTS}>
                        <Groups searchQuery={search} />
                      </CustomizedDocumentTitle>
                    )}

                    {tab === 'notifications' && (
                      <CustomizedDocumentTitle title={messages.NOTIFICATIONS}>
                        <ListOfNotifications
                          fetchItems={fetchNotifications}
                          stateKey='notifications'
                          fullyClickable
                          tab='notifications'
                          itemClassName='notification notification_big'
                        />
                      </CustomizedDocumentTitle>
                    )}
                  </div>
                </CSSTransition>
              </TransitionGroup>
            </div>
          </Scrollbars>
        </div>
      </aside>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MessengerTabs)
);
