import {
  EVENTS,
  DETAIL_EVENTS,
  SET_EVENT_PREVIEW,
  HANDLE_EVENT_ERROR,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_BOOKMARK,
} from '../store/actions';

const initialState = {
  errorMessages: [],
  hasError: false,
  detailPreview: '',
  eventBookmark: false,
};

export default function events(state = initialState, action) {
  switch (action.type) {
    case EVENTS:
      return {
        ...state,
        events: action.payload.data,
        totalItems: action.payload.count,
        featureItems: action.payload.feature,
      };
    case ADD_EVENT_BOOKMARK:
      return {
        ...state,
        eventBookmark: !state.eventBookmark,
      };
    case DETAIL_EVENTS:
      return {
        ...state,
        detail_events: action.payload.data || [],
        pageUrl: action.payload.url,
        upcoming_events: action.payload.upcomingData,
      };
    case HANDLE_EVENT_ERROR:
      return {
        ...state,
        formHasError: action.payload.hasError,
        errorMessages: action.payload.errors,
        mainErrorMessage: action.payload.message,
      };
    case SET_EVENT_PREVIEW:
      return {
        ...state,
        detailPreview: action.payload,
      };
    case ADD_EVENT_SUCCESS:
      return {
        ...state,
        saveSucess: true,
      };
    default:
      return state;
  }
}
