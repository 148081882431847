import { WEBINARS, DETAIL_WEBINARS } from '../store/actions';

const initialState = {
  errorMessages: [],
  hasError: false,
  detailPreview: '',
  eventBookmark: false,
};

export default function webinars(state = initialState, action) {
  switch (action.type) {
    case WEBINARS:
      return {
        ...state,
        webinars: action.payload.data,
        totalItems: action.payload.count,
        featureItems: action.payload.feature,
      };
    case DETAIL_WEBINARS:
      return {
        ...state,
        detail_webinars: action.payload.data || [],
        pageUrl: action.payload.url,
        upcoming_webinars: action.payload.upcomingData,
      };
    default:
      return state;
  }
}
