/*
 Helper function to validate the piece of registration.js state.
 The crucial point is that it doesn't return function (dispatch) => dispatch({}).
 Instead, it returns the validation result which we can use to dispatch actions after.
 */

export default function (
  data,
  tab,
  as,
  existingCompanyId,
  exportDisplay,
  language
) {
  if (error == undefined) {
    var error = {
      response: {
        data: {
          errors: {},
        },
      },
    };
  }

  var isThereError = false;
  var fieldError = {};
  var validation = language.registration_validation[0];

  if (data[1] !== undefined && tab === 1) {
    var email,
      password,
      firstName,
      lastName,
      userType,
      companyName,
      jobPosition,
      gender,
      iagree,
      confirmPassword;

    email = password = firstName = lastName = userType = companyName = jobPosition = gender = iagree = confirmPassword = true;

    var userTypeError = validation.USER_TYPE_ERROR;
    var companyNameError = validation.COMPANY_NAME_ERROR;
    var jobPositionError = validation.JOB_POSITION_ERROR;

    if (
      (data[1].values.hasOwnProperty('typeUser') &&
        data[1].values['typeUser'] != '') ||
      as === 'Verifier'
    ) {
      userType = false;
      userTypeError = '';
    } else {
      error.response.data.errors.typeUser = [userTypeError];
    }

    if (data[1].values['typeUser'] === 2 || as === 'Verifier') {
      if (
        data[1].values.hasOwnProperty('companyName') &&
        data[1].values['companyName'] !== ''
      ) {
        companyName = false;
        companyNameError = '';
      } else {
        error.response.data.errors.companyName = [companyNameError];
      }

      // if (
      //   data[1].values.hasOwnProperty('jobPosition') &&
      //   data[1].values['jobPosition'].trim() !== ''
      // ) {
      //   jobPosition = false;
      //   jobPositionError = '';
      // } else {
      //   error.response.data.errors.jobPosition = [jobPositionError];
      // }
    }
  }

  //usertype individual
  if (
    existingCompanyId == false &&
    data[2] !== undefined &&
    tab === 2 &&
    data[1] !== undefined &&
    data[1].values['typeUser'] === 1
  ) {
    var phone, zip, city, zip;

    //var countryError = validation.COUNTRY_ERROR;

    phone = city = zip = false;

    if (
      data[2].values.hasOwnProperty('phone') &&
      data[2].values['phone'].trim() != ''
    ) {
        if (data[2].values['phone'] < 0) {
          phone = true;
          phoneError = 'Phone should contain only positive value.';
        } else {
          phone = false;
        }
    }

    if (phone === true) {
      error.response.data.errors.phone = [phoneError];
    }

    if (data[2].values.hasOwnProperty('fax') && isNaN(data[2].values['fax'])) {
      var fax = true;
      var faxError = 'Fax should contain only numeric field.';
    } else {
      if (data[2].values['fax'] < 0) {
        var fax = true;
        var faxError = 'Fax should contain only positive value.';
      } else {
        var fax = false;
      }
    }
    if (fax === true) {
      error.response.data.errors.fax = [faxError];
    }

    if (
      data[2].values.hasOwnProperty('zip') &&
      data[2].values['zip'].trim() != ''
    ) {
      if (data[2].values['zip'] < 0) {
        zip = true;
        var zipCodeError = 'Zip code should not contain negative value.';
      } else {
        zip = false;
      }
    }
    if (zip === true) {
      error.response.data.errors.zipCode = [zipCodeError];
    }

    // if (
    //   data[2].values.hasOwnProperty('country') &&
    //   data[2].values['country'] != ''
    // ) {
    //   country = false;
    // } else {
    //   error.response.data.errors.country = [countryError];
    // }

    if (data[2].values.hasOwnProperty('city') && data[2].values['city'] != '') {
      if (/\d/.test(data[2].values['city'])) {
        error.response.data.errors.city = ['Value cannot be numeric'];
      }
    }
  }
  if (
    existingCompanyId == false &&
    data[2] !== undefined &&
    tab === 2 &&
    ((data[1] !== undefined && data[1].values['typeUser'] === 2) ||
      as == 'Verifier')
  ) {
    var companyType,
      companySize,
      address,
      phone,
      zip,
      // country,
      city,
      yearOfEstablishment,
      numberOfEmployees,
      numberOfEmployeesWomen,
      numberOfEmployeesWomen,
      noOfManagementTeam,
      noOfWomenManagementTeam,
      managedByWomen;

    companyType = companySize = address = phone = city = yearOfEstablishment = numberOfEmployees = noOfManagementTeam = noOfWomenManagementTeam = numberOfEmployeesWomen = managedByWomen = true;
    var zip = false;

    var companyTypeError = validation.COMPANY_TYPE_ERROR;
    var companySizeError = validation.COMPANY_SIZE_ERROR;
    var addressError = validation.ADDRESS_ERROR;
    var phoneError = validation.PHONE_ERROR;
    var zipCodeError = validation.ZIP_CODE_ERROR;
    // var countryError = validation.COUNTRY_ERROR;
    var cityError = validation.CITY_ERROR;
    var yearOfEstablishmentError = validation.YEAR_ESTABLISHMENT_ERROR;
    var noOfEmployeesError = validation.EMPLOYEES_NO_ERROR;
    var noOfWomenEmployeesError = validation.WOMEN_NO_ERROR;
    var noOfManagementWomenError = validation.MANAGEMENT_WOMAN_ERROR;
    var managedByWomenError = validation.MANAGED_BY_WOMAN_ERROR;
    var numberOfEmployeesWomenEmpty;

    if (
      (data[2].values.hasOwnProperty() &&
        data[2].values.numberOfEmployeesWomen === '') ||
      isNaN(data[2].values.numberOfEmployeesWomen)
    ) {
      error.response.data.errors.numberOfEmployeesWomen = [
        yearOfEstablishmentError,
      ];
    } else {
      numberOfEmployeesWomen = false;
    }

    if (
      data[2].values.hasOwnProperty('companyType') &&
      data[2].values['companyType'] != ''
    ) {
      companyType = false;
    } else {
      error.response.data.errors.companyType = [companyTypeError];
    }
    if (
      data[2].values.hasOwnProperty('companySize') &&
      data[2].values['companySize'] != ''
    ) {
      companySize = false;
    } else {
      error.response.data.errors.companySize = [companySizeError];
    }
    if (
      data[2].values.hasOwnProperty('address') &&
      data[2].values['address'].trim() != ''
    ) {
      address = false;
    } else {
      error.response.data.errors.address = [addressError];
    }
    if (
      data[2].values.hasOwnProperty('phone') &&
      data[2].values['phone'].trim() != ''
    ) {
      if (isNaN(data[2].values['phone'])) {
        phone = true;
        phoneError = validation.PHONE_NUMERIC_ERROR;
      } else {
        if (data[2].values['phone'] < 0) {
          phone = true;
          phoneError = validation.PHONE_POSITIVE_ERROR;
        } else {
          phone = false;
        }
      }
    }

    if (phone === true) {
      error.response.data.errors.phone = [phoneError];
    }

    if (data[2].values.hasOwnProperty('fax') && isNaN(data[2].values['fax'])) {
      var fax = true;
      var faxError = validation.FAX_NUMERIC;
    } else {
      if (data[2].values['fax'] < 0) {
        var fax = true;
        var faxError = validation.FAX_POSITIVE;
      } else {
        var fax = false;
      }
    }
    if (fax === true) {
      error.response.data.errors.fax = [faxError];
    }

    if (
      data[2].values.hasOwnProperty('zip') &&
      data[2].values['zip'].trim() != ''
    ) {
      if (data[2].values['zip'] < 0) {
        zip = true;
        var zipCodeError = validation.ZIP_NEGATIVE;
      } else {
        zip = false;
      }
    }
    if (zip === true) {
      error.response.data.errors.zipCode = [zipCodeError];
    }

    // if (
    //   data[2].values.hasOwnProperty('country') &&
    //   data[2].values['country'] != ''
    // ) {
    //   country = false;
    // } else {
    //   error.response.data.errors.country = [countryError];
    // }
    if (data[2].values.hasOwnProperty('city') && data[2].values['city'] != '') {
      city = false;
    } else {
      error.response.data.errors.city = [cityError];
    }

    if (city === false) {
      if (/\d/.test(data[2].values['city'])) {
        error.response.data.errors.city = [validation.CITY_NUMERIC_ERROR];
      }
    }

    if (
      data[2].values.hasOwnProperty('yearOfEstablishment') &&
      data[2].values['yearOfEstablishment'].trim() != ''
    ) {
      if (isNaN(data[2].values['yearOfEstablishment'])) {
        yearOfEstablishment = true;
        yearOfEstablishmentError = validation.YEAR_ESTABLISHMENT_NUMERIC_ERROR;
      } else {
        if (data[2].values['yearOfEstablishment'] < 0) {
          yearOfEstablishment = true;
          yearOfEstablishmentError =
            validation.YEAR_ESTABLISHMENT_POSITIVE_ERROR;
        } else {
          yearOfEstablishment = false;
        }
        var currentYear = new Date().getFullYear();

        if (data[2].values['yearOfEstablishment'] > currentYear) {
          yearOfEstablishment = true;
          yearOfEstablishmentError =
            validation.YEAR_ESTABLISHMENT_GREATER_ERROR;
        }
      }
    }
    if (yearOfEstablishment === true) {
      error.response.data.errors.yearOfEstablishment = [
        yearOfEstablishmentError,
      ];
    }

    if (
      data[2].values.hasOwnProperty('numberOfEmployees') &&
      data[2].values['numberOfEmployees'] != ''
    ) {
      numberOfEmployees = false;
      if (isNaN(data[2].values.numberOfEmployees)) {
        numberOfEmployees = true;
        noOfEmployeesError = validation.EMPLOYEE_NUMERIC_ERROR;
      }

      //new validation
      if (
        data[2].values['companySize'] !== '' &&
        data[2].values['companySize'] !== undefined
      ) {
        switch (data[2].values['companySize']) {
          case '1':
            if (data[2].values.numberOfEmployees !== 0) {
              numberOfEmployees = true;
              noOfEmployeesError = validation.COMPANY_GREATER_SIZE_ERROR;
            }
            break;
          case '2':
            if (
              data[2].values.numberOfEmployees < 1 ||
              data[2].values.numberOfEmployees > 9
            ) {
              numberOfEmployees = true;
              noOfEmployeesError = validation.COMPANY_GREATER_SIZE_ERROR;
            }
            break;
          case '3':
            if (
              data[2].values.numberOfEmployees < 10 ||
              data[2].values.numberOfEmployees > 49
            ) {
              numberOfEmployees = true;
              noOfEmployeesError = validation.COMPANY_GREATER_SIZE_ERROR;
            }
            break;
          case '4':
            if (
              data[2].values.numberOfEmployees < 50 ||
              data[2].values.numberOfEmployees > 99
            ) {
              numberOfEmployees = true;
              noOfEmployeesError = validation.COMPANY_GREATER_SIZE_ERROR;
            }
            break;
          case '5':
            if (
              data[2].values.numberOfEmployees < 100 ||
              data[2].values.numberOfEmployees > 249
            ) {
              numberOfEmployees = true;
              noOfEmployeesError = validation.COMPANY_GREATER_SIZE_ERROR;
            }
            break;
          case '6':
            if (
              data[2].values.numberOfEmployees < 250 ||
              data[2].values.numberOfEmployees > 499
            ) {
              numberOfEmployees = true;
              noOfEmployeesError = validation.COMPANY_GREATER_SIZE_ERROR;
            }
            break;
          case '7':
            if (
              data[2].values.numberOfEmployees < 500 ||
              data[2].values.numberOfEmployees > 999
            ) {
              numberOfEmployees = true;
              noOfEmployeesError = validation.COMPANY_GREATER_SIZE_ERROR;
            }
            break;
          case '8':
            if (
              data[2].values.numberOfEmployees < 1000 ||
              data[2].values.numberOfEmployees > 9999
            ) {
              numberOfEmployees = true;
              noOfEmployeesError = validation.COMPANY_GREATER_SIZE_ERROR;
            }
            break;
          case '9':
            if (data[2].values.numberOfEmployees < 10000) {
              numberOfEmployees = true;
              noOfEmployeesError = validation.COMPANY_GREATER_SIZE_ERROR;
            }
            break;
        }
      }
    }

    if (numberOfEmployees === true) {
      error.response.data.errors.numberOfEmployees = [noOfEmployeesError];
    }

    if (
      data[2].values.hasOwnProperty('numberOfEmployeesWomen') &&
      data[2].values['numberOfEmployeesWomen'] != ''
    ) {
      numberOfEmployeesWomen = false;
      if (isNaN(data[2].values.numberOfEmployeesWomen)) {
        numberOfEmployeesWomen = true;
        noOfWomenEmployeesError = validation.EMPLOYEE_NUMERIC_ERROR;
      }

      //new validations
      if (
        parseInt(data[2].values.numberOfEmployeesWomen) >
        parseInt(data[2].values.numberOfEmployees)
      ) {
        numberOfEmployeesWomen = true;
        noOfWomenEmployeesError = validation.EMPLOYEE_COUNT_ERROR;
      }
    } else {
      numberOfEmployeesWomen = false;
    }

    if (numberOfEmployeesWomen === true) {
      error.response.data.errors.numberOfEmployeesWomen = [
        noOfWomenEmployeesError,
      ];
    }

    if (
      data[2].values.hasOwnProperty('noOfWomenManagementTeam') &&
      data[2].values['noOfWomenManagementTeam'] !== ''
    ) {
      noOfWomenManagementTeam = false;
    } else {
      error.response.data.errors.noOfWomenManagementTeam = [
        noOfManagementWomenError,
      ];
    }
    if (data[2].values.hasOwnProperty('managedByWomen')) {
      managedByWomen = false;
    } else {
      error.response.data.errors.managedByWomen = [managedByWomenError];
    }
  }

  if (
    data[2] !== undefined &&
    tab === 2 &&
    as !== 'Verifier' &&
    existingCompanyId == false
  ) {
    var noOfManagement = true;
    var noOfManagementError = validation.WOMEN_PERCENTAGE_ERROR;
    if (
      data[2].values.hasOwnProperty('noOfManagementTeam') &&
      data[2].values['noOfManagementTeam'] != ''
    ) {
      if (isNaN(data[2].values['noOfManagementTeam'])) {
        noOfManagement = true;
        noOfManagementError = validation.EMPLOYEE_NUMERIC_ERROR;
      } else if (data[2].values['noOfManagementTeam'] > 100) {
        noOfManagement = true;
        noOfManagementError = validation.MANAGEMENT_COUNT_ERROR;
      } else if (
        as === 'Seller' &&
        (data[2].values['noOfManagementTeam'] < 30 ||
          data[2].values['noOfManagementTeam'] > 100)
      ) {
        noOfManagement = true;
        noOfManagementError = validation.SELLER_PERCENTAGE_ERROR;
      } else {
        noOfManagement = false;
      }
    }

    if (noOfManagement === true) {
      error.response.data.errors.noOfManagementTeam = [noOfManagementError];
    }
  }

  if (existingCompanyId == false && data[3] !== undefined && tab === 3) {
    var annualValueOfExports,
      annualValueOfSales,
      primaryCustomers,
      hasExportExperience,
      yearsOfExportingExperience,
      sellingProducts,
      offeringServices,
      lookingForProducts,
      lookingForServices;

    annualValueOfExports = annualValueOfSales = hasExportExperience = yearsOfExportingExperience = true;

    var annualValueOfExportsError = validation.VALUE_EMPTY_ERROR;
    var annualValueOfSalesError = validation.VALUE_EMPTY_ERROR;
    var hasExportExperienceError = validation.VALUE_EMPTY_ERROR;
    var yearsOfExportingExperienceError = validation.VALUE_EMPTY_ERROR;
    var productsLookingForError = 'For user type buyer value cannot be empty';
    var lookingForProducts = 'For Buyer products cannot be empty';

    if (
      data[3].values.hasOwnProperty('hasExportExperience') &&
      String(data[3].values['hasExportExperience']).trim() != ''
    ) {
      hasExportExperience = false;
      hasExportExperienceError = '';
    } else {
      error.response.data.errors.hasExportExperience = [
        hasExportExperienceError,
      ];
    }
    if (
      (data[3].values.hasOwnProperty('yearsOfExportingExperience') &&
        String(data[3].values['yearsOfExportingExperience']).trim() != '') ||
      exportDisplay === false
    ) {
      yearsOfExportingExperience = false;
      yearsOfExportingExperienceError = '';
    } else {
      error.response.data.errors.yearsOfExportingExperience = [
        yearsOfExportingExperienceError,
      ];
    }

    if (
      data[3].values.hasOwnProperty('primaryCustomers') &&
      data[3].values['primaryCustomers'].length > 0
    ) {
      primaryCustomers = false;
    } else {
      error.response.data.errors.primaryCustomers = [
        validation.PRIMARY_CUSTOMER_ERROR,
      ];
    }

    if (
      existingCompanyId === false ||
      as !== 'Buyer' ||
      data[3].values.hasOwnProperty('looking-for-products') ||
      data[3].values['looking-for-products'] === '' ||
      data[3].values['looking-for-products'] === '{}'
    ) {
      lookingForProducts = false;
    } else {
      error.response.data.errors.lookingForProducts = [lookingForProducts];
    }
  }

  if (existingCompanyId === false && tab === 3) {
    var values = data[3]['values'];
    var sellerFlag = 0;

    if (as === 'Seller') {
      if (
        !values.hasOwnProperty('sellingProducts') ||
        values.sellingProducts.indexOf('"checked":true') <= 0
      ) {
        //error.response.data.errors.sellingProducts = [validation.PRODUCT_MY_COMPANY_SELLING_ERROR];
      } else {
        sellerFlag++;
      }

      if (
        !values.hasOwnProperty('offeringServices') ||
        values.offeringServices.indexOf('"checked":true') <= 0
      ) {
        //error.response.data.errors.offeringServices = [validation.SERVICE_MY_COMPANY_OFFERING_ERROR];
      } else {
        sellerFlag++;
      }

      if (sellerFlag === 0) {
        error.response.data.errors.sellingProducts = [
          validation.PRODUCT_MY_COMPANY_SELLING_ERROR,
        ];
        error.response.data.errors.offeringServices = [
          validation.SERVICE_MY_COMPANY_OFFERING_ERROR,
        ];
      } else {
        sellingProducts = false;
        offeringServices = false;
      }
    }

    if (as === 'Buyer') {
      if (
        !values.hasOwnProperty('lookingForProducts') ||
        values.lookingForProducts.indexOf('"checked":true') <= 0
      ) {
        error.response.data.errors.lookingForProducts = [
          validation.PRODUCT_MY_COMPANY_BUYING_ERROR,
        ];
      } else {
        lookingForProducts = false;
      }

      if (
        !values.hasOwnProperty('lookingForServices') ||
        values.lookingForServices.indexOf('"checked":true') <= 0
      ) {
        error.response.data.errors.lookingForServices = [
          validation.SERVICE_MY_COMPANY_LOOKING_ERROR,
        ];
      } else {
        lookingForServices = false;
      }
    }
  }

  for (var item in error.response.data.errors) {
    if (error.response.data.errors.item === true) {
      isThereError = true;
    }
  }
  var redirectUrl = '';
  if (isThereError === true) {
    redirectUrl = '/login/sign-up/' + as + '?step=' + tab;
  } else {
    if (tab < 3) {
      tab++;
    }
    redirectUrl = '/login/sign-up/buyer-seller?step=' + tab;
  }

  var data = {};
  data.errors = error.response.data.errors;
  data.redirectUrl = redirectUrl;

  if (isEmpty(error.response.data.errors)) {
    isThereError = true;
  }

  if (error.response.data.errors.hasOwnProperty('iagree')) {
    data.message = validation.TERMS_CONDTION_ERROR;
  } else {
    data.message = validation.COMMNON_ERROR;
  }

  data.hasError = isThereError;
  return data;
}

function isEmpty(data) {
  for (var item in data) {
    return true;
  }

  return false;
}
