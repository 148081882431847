import React, { Fragment } from 'react';
import { asField } from 'informed';
import Textarea from 'react-autosize-textarea';

export const AutosizeTextareaMaxlength = (props) => {
  const { value = '', onChange, textareaProps } = props;
  const { maxLength } = textareaProps;
  return (
    <Fragment>
      <Textarea value={value} onChange={onChange} {...textareaProps} />

      {maxLength && (
        <div className='flex'>
          <p className='right info'>
            {value.length} / {maxLength}
          </p>
        </div>
      )}
    </Fragment>
  );
};

AutosizeTextareaMaxlength.defaultProps = {
  textareaProps: {
    rows: 1,
  },
};

export default asField(
  ({
    fieldApi: { setValue, setTouched },
    fieldState: { value },
    textareaProps,
  }) => (
    <AutosizeTextareaMaxlength
      value={value}
      onChange={(e) => {
        setTouched(true);
        setValue(e.target.value);
      }}
      textareaProps={textareaProps}
    />
  )
);
