import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import axios from '../../requests/platformApi';

import FormHandler from '../form-handler';
import Form from '../forms/sign-in';
import GlobalError from '../global-error';
import Layout from '../layout';
import { login, setCurrentUser } from '../../actions/auth';
import { email } from '../../validations/validations';
import SocialLogin from '../../components/SocialLogin';
import Preloader from '../../components/Preloader';
import TopMessage from '../../components/TopMessage';
import languages from '../../helpers/languages';


import loginPic1 from '../../static/images/sign-in1.jpg';
import loginPic2 from '../../static/images/sign-in2.jpg';
import loginPic3 from '../../static/images/sign-in3.jpg';

let imgIndex = 0;

class View extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailIsBeingVerified: false,
      emailVerificationStatus: null,
      emailVerificationMessage: '',
      resendLink: null,
      showAlert: true,
    };
  };

  loginImages = [
    {
      headline: "bbh-singapore",
      link: loginPic1
    },
    {
      headline: "bbh-singapore",
      link: loginPic2
    },
    {
      headline: "bbh-singapore",
      link: loginPic3
    }
  ];

  handleAxiosResponse(response) {
    const regLang = languages[this.props.lan].registration[0];
    const diff = {
      emailIsBeingVerified: false,
    };
    if (typeof response === 'object') {
      diff.emailVerificationStatus =
        'status' in response.data
          ? response.data.status
          : response.status === 200;
      if ('link' in response.data) {
        diff.emailVerificationMessage = regLang.REQUEST_NEW_CONFIRMATION_TOKEN;
        diff.resendLink = response.data.link;
      } else if (response.status === 200) {
        diff.emailVerificationMessage = regLang.CONFIRM_EMAIL_SUCCESS;
      } else {
        diff.emailVerificationMessage = regLang.CONFIRM_EMAIL_ERROR;
      }
    }
    this.setState(diff);
  }

  componentDidMount() {
    const arr = window.location.hash.split('?');
    const diff = {};
    if (arr[1]) {
      const queryObj = queryString.parse(arr[1]);
      if ('token' in queryObj && 'id' in queryObj) {
        const url = `/api/v2/email/verify/${queryObj.id}/${queryObj.token}?expires=${queryObj.expires}&signature=${queryObj.signature}`;
        diff.emailIsBeingVerified = true;
        axios
          .get(url)
          .then((response) => this.handleAxiosResponse(response))
          .catch((e) => this.handleAxiosResponse(e.response));
      }
    }
    this.setState(diff);
    this.displayRandomImage();
  }

  displayRandomImage = () => {
    imgIndex = Math.floor(Math.random() * 3);
  };

  closeAlert() {
    this.setState({ showAlert: false });
  }

  handleRequestVerifiactionLinkClick(e) {
    e.preventDefault();
    axios.get(this.state.resendLink);
    // .then(() =>
    //   this.setState({
    //     emailVerificationMessage: 'Verification email was re-sent',
    //     resendLink: null,
    //   })
    // )
  }

  render() {
    const regLang = languages[this.props.lan].registration[0];
    const signInLang = languages[this.props.lan].sign_in[0];
    const queryObject = queryString.parse(this.props.location?.search);
    const onSubmitSuccess = (res) => {
      this.props.setCurrentUser(res.user);
      if (queryObject.wantsurls) {
        this.props.history.push(queryObject.wantsurls);
      } else {
        this.props.history.push('/dashboard');
      }
    };
    if (this.state.emailIsBeingVerified) {
      return (
        <Layout title='Loading' noHeading>
          <Preloader />
        </Layout>
      );
    }
    const alertContents = this.state.resendLink ? (
      <Fragment>
        {regLang.REQUEST_NEW_CONFIRMATION_TOKEN}{' '}
        <a
          href='#'
          onClick={this.handleRequestVerifiactionLinkClick.bind(this)}
        >
          {regLang.CLICK_HERE}
        </a>
      </Fragment>
    ) : (
      this.state.emailVerificationMessage
    );
    return (
      <Layout title={signInLang.WELCOME_BACK}>
        <div className="formpage-content">
          <div className="whiteformtoptext" style={{ textAlign: 'center' }}>
            <h3>{signInLang.WELCOME_TO}</h3>
            <img src="images/svg/shetrades-logo-black.svg" alt=" " />
            <br></br>
            {this.state.emailVerificationStatus !== null && (
              <TopMessage
                isShown={this.state.showAlert}
                type={this.state.emailVerificationStatus ? 'success' : 'error'}
                text={alertContents}
                onCloseClick={this.closeAlert.bind(this)}
              />
            )}
            <FormHandler
              initialValues={initialValues}
              submitRequest={this.props.login}
              onSubmitSuccess={onSubmitSuccess}
              mapFieldsToValidations={mapFieldsToValidations}
            >
              {(formState, onChange, onSubmit) => (
                <GlobalError error={formState.globalError}>
                  <Form
                    values={formState.values}
                    errors={formState.errors}
                    onChange={onChange}
                    onSubmit={onSubmit}
                    buttonDisabled={formState.buttonDisabled}
                  />
                </GlobalError>
              )}
            </FormHandler>
            <h5 class="titlewithlinecentered"><span>{signInLang.OR_LOG_IN_WITH}</span></h5>
            <div class="loginlogos">
              <a href="https://shetrades.com/sociallogin/facebook"><i class="fab fa-facebook"></i><p>Facebook</p></a>
              <a href="https://shetrades.com/sociallogin/google"><span class="ico-google"></span><p>Google</p></a>
              <a href="https://shetrades.com/sociallogin/linkedin"><i class="fab fa-linkedin"></i><p>Linkedin</p></a>
              <a href="https://shetrades.com/sociallogin/twitter"><i class="fab fa-twitter"></i><p>Twitter</p></a>
              <a href="https://shetrades.com/sociallogin/itc"><span class="ico-itclogo"></span><p>ITC SSO</p></a>
            </div>
            <p>
              {signInLang.DONT_HAVE_AN_ACCOUNT_YET}{' '}
              <Link to='/sign-up'>{signInLang.REGISTER_NOW}</Link>
            </p>
          </div>
        </div>
        <div className="sidebg loginbgpicture">
          <img height="100%"
            src={this.loginImages[imgIndex].link}
            alt={this.loginImages[imgIndex].headline}
          />
        </div>
      </Layout>
    );
  }
}

View.propTypes = {
  login: PropTypes.func.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  lan: state.language.lan,
});

const mapDispatchToProps = {
  login,
  setCurrentUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);

const initialValues = {
  email: '',
  password: '',
};

const mapFieldsToValidations = {
  email,
};
