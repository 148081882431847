import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from '../../../../../components/Modal';

class ConfirmButton extends React.Component {
  static propTypes = {
    param: PropTypes.string,
    dashboard: PropTypes.string,
  };

  static defaultProps = {
    param: null,
    dashboard: null,
  };

  constructor() {
    super();
    this.state = {
      isModalOpened: false,
    };
  }

  setIsModalOpened(isModalOpened) {
    this.setState({
      ...this.state,
      isModalOpened,
    });
  }

  onConfirm() {
    const { onConfirm, param, dashboard } = this.props;
    if (dashboard) {
      onConfirm(param, dashboard);
    } else {
      onConfirm(param);
    }
    this.setIsModalOpened(false);
  }

  render() {
    const { buttonStatus, type, label, t } = this.props;
    var isTouched =
      type === 'verifier'
        ? buttonStatus === '1' || buttonStatus === '2'
        : buttonStatus === '0' || buttonStatus === '1';

    let buttonText;
    let iconClass;
    if (type === 'verifier') {
      switch (buttonStatus) {
        case '1':
          iconClass = 'icon-clock--small';
          buttonText = t.common[0].PENDING;
          break;
        case '2':
          iconClass = 'icon-verified';
          buttonText = t.common[0].VERIFIED;
          break;
        default:
          iconClass = 'icon-verify';
          buttonText = t.common[0].REQUEST_VERIFICATION;
      }
    } else if (type === 'coach') {
      switch (buttonStatus) {
        case '0':
          buttonText = t.common[0].PENDING;
          break;
        case '1':
          buttonText = t.common[0].MENTORED;
          break;
        default:
          buttonText = t.common[0].BE_MY_MENTOR;
      }
    } else if (type === 'member') {
      buttonText = label;
      if (label === t.common[0].PENDING) {
        isTouched = true;
        iconClass = 'icon-clock--small';
      } else {
        isTouched = false;
        iconClass = 'icon-add';
      }
    }

    const buttonStyle = {};
    if (isTouched) {
      buttonStyle.pointerEvents = 'none';
    }

    const { isModalOpened } = this.state;

    return (
      <Fragment>
        <a
          href='javascript: void null'
          onClick={() => this.setIsModalOpened(true)}
          className={
            isTouched ? 'button button_gray button_hover-brand' : 'button'
          }
          style={buttonStyle}
        >
          {iconClass && <i className={iconClass} />}
          <span className='button__text'>{buttonText}</span>
        </a>

        <Modal
          isOpen={isModalOpened}
          onRequestClose={() => this.setIsModalOpened(false)}
          smallOnMobile
          buttons={{
            left: {
              text: t.common[0].CANCEL,
              className: 'gray',
              onClick: () => this.setIsModalOpened(false),
            },
            right: {
              text: t.common[0].CONFIRM,
              className: 'brand',
              onClick: () => this.onConfirm(),
            },
          }}
        >
          <section className='content content_p-margins modal-content__title'>
            <h4>{t.common[0].CONFIRM_ACTION}</h4>
            <p></p>
          </section>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({ t: state.language.t });

export default connect(mapStateToProps)(ConfirmButton);
