import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import CustomizedDocumentTitle from '../../components/CustomizedDocumentTitle';
import Progress from '../../components/Progress';
import Toolbar from '../../components/Toolbar';
import TopMessage from '../../components/TopMessage';
import languages from '../../helpers/languages';
import { fetchProgressProgess, onErrorCloseClick, onSuccessCloseClick } from '../../store/actions';
import TabDetailsInformation from './components/DetailsInformation';
import TabGeneral from './components/General';
import TabMainInformation from './components/MainInformation';
import TabMyInterest from './components/MyInterest';
import TabServicesAndResolutions from './components/ServicesAndResolutions';

var language = languages['en'];
var common = language.common[0];
var header = language.header[0];

const mapStateToProps = (state) => {
  var progressCount = 0;
  if (state.setting.hasOwnProperty('setProfileProgress')) {
    progressCount = 30;

    for (var item in state.setting.setProfileProgress) {
      if (state.setting.setProfileProgress[item].done === true) {
        progressCount =
          +progressCount + +state.setting.setProfileProgress[item].points;
      }
    }
  } else {
    var progressCount = 30;
  }
  if (progressCount) {
    var progressCount = progressCount * 0.01;
  }

  return {
    user: state.auth.user,
    todo: state.setting.setProfileProgress,
    progress: progressCount,
    updateSuccess: state.setting.saveSuccess,
    updateError: state.setting.hasError,
    updateErrorMessages: state.setting.errorMessages,
    updateMainErrorMessage: state.setting.mainErrorMessage,
    lan: state.language.lan,
  };
};

const mapDispatchToProps = {
  fetchProgressProgess,
  onSuccessCloseClick,
  onErrorCloseClick,
};

class PageSettings extends React.Component {
  toggleTopMessage() {
    this.props.onSuccessCloseClick(false);
  }
  toggleTopErrorMessage() {
    this.props.onErrorCloseClick(false);
  }
  componentDidMount() {
    this.props.fetchProgressProgess();
  }
  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    header = language.header[0];
    const { progress } = this.props;
    const { location, history } = this.props;


    const queryObject = queryString.parse(location.search);
    const tabIsDefault =
      !('tab' in queryObject) || queryObject.tab === 'main-information';
      
      return (
        <CustomizedDocumentTitle title={header.MY_ACCOUNT}>
          <div className='transition-page'>
            <Toolbar
              tabs={{
                values: {
                  'main-information': { text: common.MAIN_INFORMATION },
                  'details-information': { text: common.DETAILED_INFORMATION },
                  'services-and-resolutions': {
                    text: common.SERVICES_RESOLUTIONS,
                  },
                  'my-interest': { text: 'My Interests' },
                  settings: { text: language.dashboard[0].SETTINGS },
                },
              }}
            />

            <div className='row'>
              <div className='col-lg-8'>
                {tabIsDefault && <TabMainInformation />
                }

                {queryObject.tab === 'details-information' && (
                  <TabDetailsInformation />
                )}

                {queryObject.tab === 'services-and-resolutions' && (
                  <TabServicesAndResolutions />
                )}
                {queryObject.tab === 'my-interest' && (
                  <TabMyInterest />
                )}
                {queryObject.tab === 'settings' && <TabGeneral />}
              </div>

              <div className='col-lg-4'>
                <div className='widgets'>
                  <div className='row'>
                    <div className='col-sm-6 col-lg-12'>
                      <section className='widget profile-completeness'>
                        <div className='widget__top'>
            
                        </div>
                        <div className="profilessidebar">
                          <div className="buysell_sideblock">
                            <h3>{this.props.user.displayName}</h3>
                            <div className="container">
                              <span className="managecompanyimg">
                                <img src={this.props.user.avatar} alt =""/></span>
                              <div className="managecompanydesc">
                                <a href="/member/">{this.props.user.companyId}</a>  <i className="far fa-venus" />
                                <div>{this.props.user.displayName}</div>
                              </div>
                            </div>
                            {/* <div className="container">
                              <Link to="/registration/seller" className="addcompanybtn">
                                <i className="fal fa-plus-circle" />Add a Company page</Link>
                            </div> */}
                          </div>
                        </div>
                      </section>
                      <section className='widget profile-completeness'>
                        <div className='widget__top'>
                          <h4>{common.PROFILE_COMPLETENESS}</h4>
                        </div>

                        <div className='widget__white'>
                          <div className='flex flex_align-middle gray'>
                            <div className='left'>
                              {common.COMPLETE_YOUR_PROFILE}
                            </div>

                            <div className='right'>
                              <h2 className='h2'>
                                {Math.round(100 * progress)}%
                              </h2>
                            </div>
                          </div>

                          <Progress progress={this.props.progress} />

                          <div className='progress-todo'>
                            {this.props.todo !== undefined &&
                              this.props.todo.map(
                                ({ title, points, done }, i) => (
                                  <div
                                    className={`progress-todo-item${done ? ' progress-todo-item_done' : ''
                                      }`}
                                    key={i}
                                  >
                                    <div className='info'>
                                      <i className='icon-check'>
                                        <span className='path1' />
                                        <span className='path2' />
                                      </i>

                                      <span className='info__text'>
                                        {/* eslint-disable-next-line no-nested-ternary */}
                                        {title}{' '}
                                        {points > 0 ? '+' : points < 0 ? '-' : ''}
                                        {points}%
                                      </span>
                                    </div>
                                  </div>
                                )
                              )}
                          </div>
                        </div>
                      </section>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            {this.props.updateSuccess === true && (
              <TopMessage
                isShown={true}
                onCloseClick={this.toggleTopMessage.bind(this, false)}
                type='success'
                text={common.PROFILE_UPDATED_SUCCESSFULLY}
              />
            )}
            {this.props.updateError === true && (
              <TopMessage
                isShown={true}
                onCloseClick={this.toggleTopErrorMessage.bind(this, false)}
                type='error'
                text={this.props.updateMainErrorMessage}
              />
            )}
          </div>
        </CustomizedDocumentTitle>
      );
    
  }
}

PageSettings.propTypes = {
  location: PropTypes.object.isRequired,
  progress: PropTypes.number,
  onSuccessClose: PropTypes.func,
};

PageSettings.defaultProps = {
  progress: 0,
};

const List = connect(mapStateToProps, mapDispatchToProps)(PageSettings);
export default List;
