import React from 'react';
import { asField } from 'informed';
import PropTypes from 'prop-types';
import geolocation from 'geolocation';
import { connect } from 'react-redux';

import Modal from '../../../../../components/Modal';
import GoogleMap from '../../../../../components/GoogleMap';
import GoogleMapCoordinates from '../../../../../components/GoogleMapCoordinates';

class LocationInputContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      location: {},
      forceMapMove: undefined,
    };
  }

  onMapClick({ latLng }) {
    this.setState({
      ...this.state,
      location: {
        lat: latLng.lat(),
        lng: latLng.lng(),
      },
    });
  }

  onMyLocationClick() {
    geolocation.getCurrentPosition((err, position) => {
      if (err) throw err;

      const latLng = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };

      this.setState({
        ...this.state,
        location: latLng,
        forceMapMove: latLng,
      });
    });
  }

  render() {
    const { isOpen, onChange, onClose, t } = this.props;
    const { location, forceMapMove } = this.state;
    const { lat, lng } = location;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={() => onClose()}
        buttons={{
          left: {
            text: t.common[0].CANCEL,
            className: 'gray',
            onClick: () => onClose(),
          },
          right: {
            text: t.common[0].SHARE_LOCATION,
            className: 'brand',
            disabled: !lat && !lng,
            style:
              !lat && !lng ? { opacity: 0.5, pointerEvents: 'none' } : null,
            onClick: () => {
              onChange(location);
              onClose();
            },
          },
        }}
      >
        <div className='modal-content__title'>
          <h4>{t.common[0].CHOOSE_LOCATION}</h4>
        </div>

        <GoogleMap
          lat={lat}
          lng={lng}
          onClick={this.onMapClick.bind(this)}
          showMarker={!!lat && !!lng}
          forceMove={forceMapMove}
        />

        <div className='flex'>
          <div className='left'>
            {lat && lng && <GoogleMapCoordinates lat={lat} lng={lng} />}
          </div>

          <div className='right'>
            <button
              type='button'
              className='button '
              onClick={this.onMyLocationClick.bind(this)}
            >
              <i className='icon-precision' />

              <span className='button__text'>
                {t.common[0].CHOOSE_MY_LOCATION}
              </span>
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

LocationInputContainer.propTypes = {
  isOpen: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  value: PropTypes.object,
  t: PropTypes.object,
};

const mapStateToProps = (state) => ({
  t: state.language.t,
});

export const LocationInput = connect(mapStateToProps)(LocationInputContainer);

export default asField(
  ({ fieldApi: { setValue }, fieldState: { value }, ...rest }) => (
    <LocationInput value={value} onChange={setValue} {...rest} />
  )
);
