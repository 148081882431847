import React from 'react';
import lifecycle from 'react-pure-lifecycle';
import { connect } from 'react-redux';

import { getSATContent } from '../store/actions';
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import Preloader from '../components/Preloader';
import languages from '../helpers/languages';

var language = languages['en'];
var common = language.common[0];
var menu = language.menu[0];

const mapStateToProps = (state) => ({
  // satContent: state.learn.satContent,
  lan: state.language.lan,
  userRole: state.auth.user.userRole,
});

const mapDispatchToProps = {
  getSATContent,
};

const methods = {
  componentDidMount(props) {
    props.getSATContent();
  },
};

const PageSAT = (props) => {
  var roleFlag = true;
  if (props.userRole === 'member' || typeof props.userRole === 'undefined') {
    roleFlag = false;
  }
  language = languages[props.lan];
  common = language.common[0];
  menu = language.menu[0];

  const isLoading =
    props.satContent === '' || props.satContent === 'undefined';

  const url =
    'https://learn.shetrades.com/mod/itcselfassesment/view.php?id=31959';

  const attributes = roleFlag ? { href: url } : {};

  return (
    <CustomizedDocumentTitle title={menu.E_LEARNING}>
      <div className='transition-page'>
        {isLoading ? (
          <Preloader />
        ) : (
          <section className='card card_for-news card_for-full-news'>
            <div className='card__image'>
              <img src='../images/image--elearning.jpg' alt='e-learning' />
            </div>
            <div className='card__top' />
            <div
              className='content content_p-margins'
              dangerouslySetInnerHTML={{ __html: "<h3>Self Assessment Tool </h3> <p>This tool provides a simple, quick, and effective way to understand how well you currently perform against your business competitiveness knowledge and skills, and identifying unknown strengths and weaknesses</p> <h3>How does it work? </h3> <p>You need to fill a survey to assess your competitiveness in each category.  The platform provides detailed guidelines on how to rate your knowledge and skills, , including a comprehensive description of technical terms used in the tool.</p> <h3>The four Competences of Competitiveness:</h3> <p><b>Strategic competence:</b> To define which of all possible business opportunities the company will pursue and how the company will go about seizing those opportunities</p> <p><b>Marketing and Sales competence: </b> To engage in market transactions and take care of the operational aspects of reaching clients, closing profitable sales orders, defining a corporate identity, and setting up distribution channels.</p> <p><b>Production competence: </b> To produce and ship the offer envisioned in the business strategy</p> <p><b>Resource management competence:</b> To ensure the company has access to the necessary resources and makes an efficient use of them.</p>" }}
            />
            <a {...attributes} target='_blank' className='button' rel="noopener noreferrer">
              <i className='icon-redirect' />
              <span className='button__text'>{common.LETS_GO}</span>
            </a>
          </section>
        )}
      </div>
    </CustomizedDocumentTitle>
  );
};

const SATCycle = lifecycle(methods)(PageSAT);
const List = connect(mapStateToProps, mapDispatchToProps)(SATCycle);
export default List;
