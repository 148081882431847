import React from 'react';
import { asField } from 'informed';
import DropdownTree from '../../';

export default asField(
  ({
    fieldApi: { setValue, setTouched },
    fieldState: { value },
    onChange = () => {},
    ...rest
  }) => {
    return (
      <DropdownTree
        value={value}
        onSubmit={(val) => {
          setTouched();
          setValue(val);
          onChange(val);
        }}
        {...rest}
      />
    );
  }
);
