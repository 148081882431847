import React from 'react';
import PropTypes from 'prop-types';
import lifecycle from 'react-pure-lifecycle';
import { connect } from 'react-redux';

import Accordion from '../components/Accordion';
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import Preloader from '../components/Preloader';
import { fetchFaq } from '../store/actions';
import languages from '../helpers/languages';

var language = languages['en'];
var common = language.common[0];

const mapStateToProps = (state) => {
  return { faq: state.staticPages.faqs, lan: state.language.lan };
};

const mapDispatchToProps = {
  fetchFaq,
};

const methods = {
  componentDidMount(props) {
    props.fetchFaq();
  },
};

const ItemComponent = (props) => {
  const { isOpened, question, answer, onToggleClick, bottomRef } = props;

  return (
    <div
      className={`accordion-item accordion-item_faq ${
        isOpened ? ' accordion-item_opened' : ''
      }`}
    >
      <div className='accordion-item__top' onClick={onToggleClick}>
        <p>
          <b>{question}</b>
        </p>

        <div className='accordion-item__icon'>
          {isOpened ? '—' : <i className='icon-accordion--plus' />}
        </div>
      </div>

      <div className='accordion-item__bottom' ref={bottomRef}>
        <div
          className='content content_p-margins'
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      </div>
    </div>
  );
};

ItemComponent.propTypes = {
  isOpened: PropTypes.bool,
  question: PropTypes.string,
  answer: PropTypes.string,
  onToggleClick: PropTypes.func,
  bottomRef: PropTypes.func,
};

ItemComponent.defaultProps = {
  isOpened: false,
  bottomRef: () => {},
};

const PageFaq = (props) => {
  language = languages[props.lan];
  common = language.common[0];

  const isLoading = props.faq === '' || props.faq === 'undefined';

  return (
    <CustomizedDocumentTitle title={common.FREQUENTLY_ASKED_QUESTIONS}>
      <div className='transition-page'>
        {isLoading ? (
          <Preloader />
        ) : (
          <div className='detail detail_big detail_symmetric'>
            <div className='detail__whole'>
              <div className='content content_p-margins'>
                <h1 className='h2'>{common.FREQUENTLY_ASKED_QUESTIONS}</h1>
              </div>
              <Accordion
                items={props.faq}
                ItemComponent={ItemComponent}
                className='accordion accordion_faq'
              />
            </div>
          </div>
        )}
      </div>
    </CustomizedDocumentTitle>
  );
};
const faqlifeCycle = lifecycle(methods)(PageFaq);
const List = connect(mapStateToProps, mapDispatchToProps)(faqlifeCycle);

export default List;
//export default PageFaq;
