import { E_INVEST_CONTENT } from '../store/actions';

const initialState = { marketTools: [], eLearnContent: [] };

export default function learn(state = initialState, action) {
  switch (action.type) {
    case E_INVEST_CONTENT:
      return {
        ...state,
        investContent: action.payload.data,
      };
    default:
      return state;
  }
}
