import { SET_CURRENT_USER } from './../actions/auth';
import { COUNTRIES } from '../store/actions';

const initialState = {
  checkedOnServer: false,
  isLoggedIn: false,
  user: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        checkedOnServer: true,
        isLoggedIn:
          typeof action.payload === 'object' && action.payload !== null,
        user: action.payload,
      };
    case COUNTRIES:
      return {
        ...state,
        countries: action.payload.data,
      };
    default:
      return state;
  }
}
