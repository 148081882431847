import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import languages from '../../helpers/languages';

var language = languages['en'];
var common = language.common[0];

const mapStateToProps = (state) => ({
  lan: state.language.lan,
});

const NoDataCard = (props) => {
  language = languages[props.lan];
  common = language.common[0];
  return (
    <section className={classNames('card list-animation-item')}>
      <div>{common.CONTENT_NOT_FOUND}</div>
    </section>
  );
};

NoDataCard.propTypes = {};
NoDataCard.defaultProps = {};

//export default NoDataCard;
export default connect(mapStateToProps)(NoDataCard);
