export const GOOGLE_APIS_KEY = 'AIzaSyDTAMHRuXCykZeq07FnkE7Ih8nYyhMj-Bo';

export const GEOIP_URL = 'https://api.ipgeolocation.io/ipgeo';
export const GEOIP_KEY = '3df9c2769c3740df84b1450444b5a628';
export const WEATHER_URL = 'https://api.openweathermap.org/data/2.5/weather';
export const WEATHER_API_KEY = '29e6f445fac455a6f3243e6f4299d78e';

export const REACT_ROOT_ID = 'app-root';
export const APPLOZIC_APP_ID = 'shetrades10a439cdd7a1a665d407c';

export const EMOJI_SET = 'apple';
export const MCK_FILE_URL = 'https://applozic.appspot.com';
export const FILE_PREVIEW_URL = '/rest/ws/aws/file/';

export const pageTransition = {
  classNames: {
    appear: 'page-appear',
    appearActive: 'page-appear--active',
    enter: 'page-enter',
    enterActive: 'page-enter--active',
    exit: 'page-leave',
    exitActive: 'page-leave--active',
  },
  timeout: 800,
};
export const listTransition = {
  classNames: {
    appear: 'list-appear',
    appearActive: 'list-appear--active',
    enter: 'list-enter',
    enterActive: 'list-enter--active',
    exit: 'list-leave',
    exitActive: 'list-leave--active',
  },
  timeout: 1200,
};

// TODO: separate groups from members with additional API field
export const distinguishGroupId = (id) => id > 1e6;
