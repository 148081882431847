import React from 'react';
import { connect } from 'react-redux';

const PageNotFound = (props) => (
  <section className='detail detail_big'>
    <div className='detail__left'>
      <h1 className='h2'>{props.t.common[0].PAGE_NOT_FOUND}</h1>
      <div className='detail__description'>{props.t.common[0].PAGE_NOT_FOUND_MESSAGE}</div>
    </div>
  </section>
);

const mapStateToProps = (state) => ({
  t: state.language.t,
});

export default connect(mapStateToProps)(PageNotFound);
