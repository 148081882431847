import React, { Fragment } from 'react';
import LocationInput from '../LocationInput';

class WithButton extends React.Component {
  state = {
    isOpen: false,
  };

  render() {
    const { id, placeholder } = this.props;
    const { isOpen } = this.state;
    return (
      <Fragment>
        <button
          className='button '
          onClick={() => this.setState({ isOpen: true })}
          type='button'
        >
          <i className='icon-precision' />

          <span className='button__text'>{placeholder}</span>
        </button>

        <LocationInput
          field={id}
          isOpen={isOpen}
          onClose={() => this.setState({ isOpen: false })}
        />
      </Fragment>
    );
  }
}

export default WithButton;
