import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink, Link, withRouter } from 'react-router-dom';
import Questionnaire from '../../components/Questionnaire';
import Modal from '../../components/Modal';
import languages from '../../helpers/languages';
import { InstallButton } from '../Header/components/installButton';
var language = languages['en'];
var label = language.report_abuse[0];
var menu = language.menu[0];
var dashboard = language.dashboard[0];
var common = language.common[0];
var main = label.MAIN_INFO;
var sub = label.SUB_INFO;
var newmenu = language.NewMenu[0];

const mapStateToProps = (state) => ({
  isMobile: state.responsive.isMobile,
  lan: state.language.lan,
});

class Footer extends React.Component {
  static propTypes = {
    isMobile: PropTypes.bool,
    location: PropTypes.object.isRequired,
    onLinkClick: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
    this.submitAbuseReport = this.submitAbuseReport.bind(this);
  }

  toggleModal(showModal) {
    this.setState({
      showModal,
    });
  }

  submitAbuseReport(stepResults) {
    return new Promise((resolve, reject) => setTimeout(() => resolve(), 1000));
  }

  render() {
    language = languages[this.props.lan];
    newmenu = language.NewMenu[0];
    label = language.report_abuse[0];
    menu = language.menu[0];
    dashboard = language.dashboard[0];
    common = language.common[0];
    main = label.MAIN_INFO;
    sub = label.SUB_INFO;
    
    const abuseQuestionnaire = [
      {
        id: '1',
        title: dashboard.ABUSE_REPORT,
        questions: [
          {
            id: '1',
            title: dashboard.TELL_US_WHAT_HAPPENED,
            type: 'radio-group',
            options: [
              {
                id: '1',
                name: dashboard.THIS_IS_INSULTING,
              },
              {
                id: '2',
                name: dashboard.MISLEADING_OR_FRAUDLENT,
              },
              {
                id: '3',
                name: dashboard.THIS_IS_SPAM,
              },
              {
                id: '4',
                name: dashboard.OTHER,
              },
            ],
          },
          {
            id: '2',
            title: common.PLEASE_DESCRIBE,
            type: 'text',
          },
        ],
      },
    ];
    const { location, isMobile, onLinkClick } = this.props;
    const { showModal } = this.state;
    return (
    <div>
    {/*footer links */}

        <div id="footer_links">
          <div className="wrap">
            <div className="container-4col">
              <div><p>{newmenu.FOLLOW_US_ON_SOCIAL_MEDIA}</p>
                <div className="socialmediaicons">
                  <Link to="/youtube"><i className="fab fa-youtube-square" /></Link>
                  <Link to="/facebook"><i className="fab fa-facebook-square" /></Link>
                  <Link to="/twitter"><i className="fab fa-twitter-square" /></Link>
                  <Link to="/linkedin"><i className="fab fa-linkedin" /></Link>
                </div></div>
              <div><strong>{newmenu.SHETRADES_COMMUNITY} </strong>
                <Link to="/publicevents">{newmenu.COMMUNITY_BLOG}</Link>
                {/*<Link to="/publicblog">SheTrades Blog</Link>*/}
                {/*<Link to="/publichubs">SheTrades Hubs</Link>*/}
                <Link to="/publicprojects">{newmenu.OUR_PROJECTS}</Link></div>
              <div><strong>{newmenu.SHETRADES_TOOLS}</strong>
                {/* <Link to="/publicoffers">{newmenu.SELL_BUY}</Link> */}
                {/*<Link to="/opportunities">Opportunities Board</Link>*/}
                <Link to="/publicproject/5">{newmenu.POLICY_DATA} </Link></div>
              <div><strong>{newmenu.USEFUL_LINKSITCRESOURCE_CENTRE}</strong>
                <Link to="/itcwebsite">{newmenu.ITC}</Link>
                {/*<Link to="/publichelpcenter">Resource Centre</Link>*/}
                {/*<Link to="/covid">COVID-19 Support</Link>*/}
                <Link to="/news">{newmenu.NEWS}</Link></div>
            </div>
          </div>
        </div>

    {/*footer links */}
    {/*footer */}
    <footer>
      <div className="wrapper">
        <img src="images/itc-logo.png" srcSet="images/itc-logo.png 1x, images/itc-logo@2x.png 2x, images/itc-logo@3x.png 3x, images/itc-logo@4x.png 4x" alt="" className="footerlogo" />
        <p>{common.COPYRIGHT}</p>
        <span className="footerbuttons">
          { <InstallButton></InstallButton> }
          {/* <NavLink to="#" className="btn"><i class="fal fa-life-ring">
            </i>Get help</NavLink> */}
          {/* <NavLink to='#' 
            className="btn" 
            onClick={() => this.toggleModal(true)}>
                  {menu.REPORT_AN_ABUSE}
              </NavLink> */}
          </span>
      </div>
      <div className="dotbg" />
    </footer>
    {/*footer */}

    <Modal
    ariaHideApp={false}
    isOpen={showModal}
    onRequestClose={this.toggleModal.bind(this, false)}
    noBottom
  >
    <Questionnaire
      steps={abuseQuestionnaire}
      afterTitleMain={main}
      afterMain={sub}
      submit={this.submitAbuseReport}
      close={this.toggleModal.bind(this, false)}
      //withConfirmStep={false}
      confirmText={common.SEND}
      formMessage='true'
      common='true'
      disableProgress
    />
  </Modal>
</div>
);
}
}

export default connect(mapStateToProps)(withRouter(Footer));
