/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import noAvatarImage from '../static/images/noavatar.svg';

const UserNameWithAvatar = (props) => {
  const {
    name,
    avatar,
    regionName,
    mini,
    addToNetwork,
    hasRequestedAdd,
    noAvatarImage,
    type,
    dataId,
    addContactFunction,
    param,
    pageType,
    updateContactFunction,
    requestedBy,
    redirectId,
  } = props;

  if (redirectId !== undefined && redirectId > 0) {
    var to = '/profilesearch/' + redirectId;
  } else {
    var to = `/members/${encodeURIComponent(name)
      .replace(/%20/g, '+')
      .replace(/%/g, '')}/${type}/${dataId}`;
  }

  const isComplex = addToNetwork || hasRequestedAdd;
  return (
    <div
      className={`user-name-with-avatar${
        mini ? ' user-name-with-avatar_mini' : ''
      }${isComplex ? ' user-name-with-avatar_complex' : ''}`}
    >
      <Link to={to} className='user-name-with-avatar__avatar'>
        {avatar ? (
          <img src={avatar} alt='' />
        ) : (
          <img src={noAvatarImage} alt={name} />
        )}
      </Link>

      <div className='user-name-with-avatar__right'>
        <Link to={to} className='user-name-with-avatar__name'>
          {name}
        </Link>

        {addToNetwork && (
          <a
            href='javascript: void null'
            onClick={(e) => {
              addContactFunction
                ? addContactFunction(type + ',' + dataId + ',' + pageType)
                : '';
            }}
            className='user-name-with-avatar__add'
          >
            + {props.t.my_network[0].ADD_TO_NETWORK}
          </a>
        )}

        {hasRequestedAdd && (
          <div className='buttons'>
            <a
              href='javascript: void null'
              onClick={(e) => {
                updateContactFunction
                  ? updateContactFunction(
                      type +
                        ',' +
                        dataId +
                        ',' +
                        pageType +
                        ',' +
                        requestedBy +
                        ',1'
                    )
                  : '';
              }}
              className='button'
            >
              {props.t.common[0].CONFIRM}
            </a>

            <a
              href='javascript: void null'
              onClick={(e) => {
                updateContactFunction
                  ? updateContactFunction(
                      type +
                        ',' +
                        dataId +
                        ',' +
                        pageType +
                        ',' +
                        requestedBy +
                        ',3'
                    )
                  : '';
              }}
              className='button '
            >
              {props.t.common[0].REJECT}
            </a>
          </div>
        )}

        {regionName && (
          <span className='user-name-with-avatar__region info'>
            {regionName}
          </span>
        )}
      </div>
    </div>
  );
};

UserNameWithAvatar.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  avatar: PropTypes.string,
  regionName: PropTypes.string,
  mini: PropTypes.bool,
  addToNetwork: PropTypes.bool,
  hasRequestedAdd: PropTypes.bool,
  noAvatarImage: PropTypes.string,
  type: PropTypes.string,
  dataId: PropTypes.number,
  addContactFunction: PropTypes.func,
  param: PropTypes.string,
  pageType: PropTypes.string,
  updateContactFunction: PropTypes.func,
  requestedBy: PropTypes.string,
};

UserNameWithAvatar.defaultProps = {
  avatar: noAvatarImage,
  regionName: '',
  mini: false,
  addToNetwork: false,
  hasRequestedAdd: false,
  noAvatarImage: noAvatarImage,
  type: '',
  dataId: 0,
  addContactFunction: null,
  param: '',
  pageType: ',',
  updateContactFunction: null,
  requestedBy: null,
};

const mapStateToProps = (state) => ({ t: state.language.t });

export default connect(mapStateToProps)(UserNameWithAvatar);
