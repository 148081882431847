/* eslint-disable no-console */
import axios from 'axios';

// axios.defaults.xsrfCookieName = 'csrftoken'
// axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const apiHeaders = {
  'Content-Type': 'application/json',
};
/*
const platformApi = axios.create({
  baseURL: '/',
  headers: apiHeaders,
});

export default platformApi;
*/

// import {message} from'antd';

const apiSecureHeaders = {
  //Authorization: 'Basic c4aa7808d0d92c6bdcfce0d0105bb34c',
};


const platformApi = axios.create({
  //baseURL: '/',
  baseURL: 'https://old.shetrades.dev/',
  withCredentials: true,
  headers: apiSecureHeaders,
});

// request interceptor
platformApi.interceptors.request.use(
  function (config) {
    // what to do before sending
    return config;
  },
  function (error) {
    // What to do with request errors
    console.log(error);
    return Promise.reject(error);
  }
);

// response interceptor
platformApi.interceptors.response.use(
  function (response) {
    // What to do with response data
    //console.log('Response:', response);
    return response;
  },
  function (error) {
    // What to do with response errors
    console.log(error);
    return Promise.reject(error);
  }
);

export default platformApi;