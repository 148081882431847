import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import Preloader from '../components/Preloader';
import hubsData from '../data/hubs.json';
import languages from '../helpers/languages';

var language = languages['en'];
var common = language.common[0];
var hubslbl = language.Hubs[0];

const mapStateToProps = (state) => {
  return {
    lan: state.language.lan,
    user: state.auth.user,
  };
};

class PageHubs extends React.Component {
  state = {
    isModalOpened: false
  };

  componentDidMount() {

  }

  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    hubslbl = language.Hubs[0];

    const { showPreloader } = this.props;
    const sliderSettings = {
      arrows: true,
      dots: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 1000,
      cssEase: "linear",
    };

    let renderBreakOrder = hubsData.filter((_, i) => i % 4 == 0);
    return (
      <CustomizedDocumentTitle title={common.MY_DASHBOARD}>
        <div className='transition-page'>
          {showPreloader ? (
            <Preloader size='medium' relative />
          ) : (
            <div>
              {/*header content */}
              <header>
                <div className="innerheader innerheader-hubs_landing">
                  <h1>SheTrades Hubs</h1>
                </div>
              </header>
              {/*header content */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container-2col">
                    <div>
                      <p>{hubslbl.UNDER_THE_SHETRADES_PLATFORM_SEL}</p></div>
                    <div className="memberofblock"><b className="hubscounting">12</b>
                      <p class="center">{hubslbl.NATIONAL_HUBS}<br />{hubslbl.AND_EXPANDING}</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* map area  */}
              <div className="bgdark">
                <div className="innermapcontainer">
                  <div className="worldmap">
                    <div className="maphomeicon h1"><i className="fas fa-home-alt" /></div>
                    <div className="maphomeicon h2"><i className="fas fa-home-alt" /></div>
                    <div className="maphomeicon h3"><i className="fas fa-home-alt" /></div>
                    <div className="maphomeicon h4"><i className="fas fa-home-alt" /></div>
                    <div className="maphomeicon h5"><i className="fas fa-home-alt" /></div>
                    <div className="maphomeicon h6"><i className="fas fa-home-alt" /></div>
                    <div className="maphomeicon h7"><i className="fas fa-home-alt" /></div>
                    <div className="maphomeicon h8"><i className="fas fa-home-alt" /></div>
                    <div className="maphomeicon h9"><i className="fas fa-home-alt" /></div>
                    <div className="maphomeicon h10"><i className="fas fa-home-alt" /></div>
                    <div className="maphomeicon h11"><i className="fas fa-home-alt" /></div>
                    <div className="maphomeicon h12"><i className="fas fa-home-alt" /></div>
                    {/*box }
                    <div className="hovermapbox">
                      <div className="hovermapbox-hubcontainer">
                        <div><img src="images/temphubpic.jpg" srcSet="images/temphubpic.jpg 1x, images/temphubpic@2x.jpg 2x, images/temphubpic@3x.jpg 3x, images/temphubpic@4x.jpg 4x" alt="" className="roundpic" /></div>
                        <div><h3><Link to="/hub/1">South Africa <i className="fal fa-arrow-right" /></Link></h3>
                          <p className="textgrey"><b>343 {hubslbl.MEMBERS}</b><br /><b>42 {hubslbl.DISCUSSION_GROUPS}</b></p>
                        </div>
                      </div>
                    </div>
                    {/*box */}
                  </div>
                </div>
              </div>
              {/* map area  */}
              {/* ST in the world  */}
              <div className="bgwhite">
                <div className="wrap">
                  <div className="container-3col">
                    <div>
<span class="bred">SheTrades MENA Hub<br /></span>
<span class="bred">SheTrades Kenya Hub<br /></span>
<span class="bred">SheTrades Nigeria Hub<br /></span>
<span class="bred">SheTrades Argentina Hub<br /></span>
                      {/* hubsData.slice(0, 3).map((hub, idx) => (
                        <span><Link to={`/publichub/${hub.id}`}>{hub.title}</Link>{<br />}</span>
                      ))
                      */}
                    </div>
                    <div>
<span class="bred">SheTrades Rwanda Hub<br /></span>
<span class="bred">SheTrades Trinidad and Tobago Hub<br /></span>
<span class="bred">SheTrades Uruguay Hub<br /></span>
<span class="bred">SheTrades Vietnam Hub<br /></span>
                      {/*hubsData.slice(6, 9).map((hub, idx) => (
                        <span><Link to={`/publichub/${hub.id}`}>{hub.title}</Link>{<br />}</span>
                      ))
                      */}
                    </div>
                    <div>
<span class="bred">SheTrades South Africa Hub<br /></span>
<span class="bred">SheTrades Philippines Hub<br /></span>
<span class="bred"><Link to={`/publichub/5`}>SheTrades Gambia Hub</Link><br /></span>
<span class="bred">SheTrades Mauritius Hub<br /></span>
                      {/*hubsData.slice(12, 16).map((hub, idx) => (
                        <span><Link to={`/publichub/${hub.id}`}>{hub.title}</Link>{<br />}</span>
                      ))
                      */}
                    </div>
                  </div>
                </div>
              </div>
              {/* ST in the world  */}
              {/*free block */}
              <div className="bgdark">
                <div className="wrap">
                  <div className="blueblockbgcircles">
                    <div className="container-2col">
                      <div>
                        <h2>{hubslbl.HOW_DO_I_JOIN_MY_LOCAL_HUB}</h2>
                        <p>{hubslbl.REGISTERED_WOMEN_AND_INSTITUTIONS}&nbsp;
                          {common.CONTACT_US}&nbsp;<a href="mailto:shetradeshubs@intracen.org">shetradeshubs@intracen.org</a></p></div>
                      <Link to="/sign-up" className="btn"> {hubslbl.REGISTER_OR_LOGIN}</Link>
                    </div>
                  </div>
                </div>
              </div>
              {/*free block */}
              {/* why i am  */}
              <div className="bggrey">
                <div className="wrap">
                  <h2>{hubslbl.WHY_JOIN_A_LOCAL_SHETRADES_HUB}</h2>
                  <div className="container-2even">
                    <div><h3>{hubslbl.AS_A_BUSINESS}</h3>
                      <p className="whyjoinblock"><span className="circlenumber">1</span>{hubslbl.ACCESS_THE_ENTIRE_SHETRADES_OFFER}
                      <a href="#">{hubslbl.SHE_TRADES_OFFERING}</a></p>
                      <p className="whyjoinblock"><span className="circlenumber">2</span>{hubslbl.CONNECT_TO_POTENTIAL_BUYERS_INVE}</p>
                      <p className="whyjoinblock"><span className="circlenumber">3</span>{hubslbl.RECEIVE_ONSITE_LOCAL_SUPPORT_TO_A}</p>
                      <p className="whyjoinblock"><span className="circlenumber">4</span>{hubslbl.GAIN_ACCESS_TO_ONSITE_WORKSHOPS}</p>
                      <p className="whyjoinblock"><span className="circlenumber">5</span>{hubslbl.JOIN_DISCUSSION_GROUPS_NETWORK_AN}</p>
                      <p className="whyjoinblock"><span className="circlenumber">6</span>{hubslbl.GET_NOTIFIED_OF_NATIONALLOCAL_OPP}</p>
                      <p className="whyjoinblock"><span className="circlenumber">7</span>{hubslbl.B2B_MEETINGS}</p>
                    </div>
                    <div><h3>{hubslbl.AS_A_CORPORATION_BUYER_OR_INVEST}</h3>
                      <p className="whyjoinblock"><span className="circlenumber">1</span>{hubslbl.CONNECT_WITH_LOCAL_OVER_WOMEN_OWN}</p>
                      <p className="whyjoinblock"><span className="circlenumber">2</span>{hubslbl.IDENTIFY_UNIQUE_AND_INNOVATIVE_LO}</p>
                      <p className="whyjoinblock"><span className="circlenumber">3</span>{hubslbl.LEARN_SHARE_AND_EXCHANGE_WITH_LO}</p>
                      <p className="whyjoinblock"><span className="circlenumber">4</span>{hubslbl.SUPPORT_THE_UN_SUSTAINABLE_DEVELO}</p>
                      <p className="whyjoinblock"><span className="circlenumber">5</span>{hubslbl.DEVELOP_NEW_LOCAL_PARTNERSHIPS_AN}</p>
                    </div>
                  </div>
                  <div className="quickphotogallery"><img src="images/temppic01.jpg" alt="" />
                    <img src="images/temppic02.jpg" alt="" />
                    <img src="images/temppic03.jpg" alt="" />
                    <img src="images/temppic04.jpg" alt="" /></div>
                  <h3 className="helpquestion">{hubslbl.HAVE_A_QUESTION_SEND_AN_EMAIL_TO} <a href="#">shetradeshubs@intracen.org</a>
                  </h3>
                </div>
              </div>
              {/* why i am  */}
              {/* partners and founders  */}
              <div className="bgwhite">
                <div className="wrap">
                  <div className="container">
                    <h2>{hubslbl.MEET_THE_SHETRADES_HUB_HOSTS}</h2>
                  </div></div>
                <div className="partnerscontainer" style={{ marginTop: '-50px' }}>
                  {/* <Slider className="slider-new" {...sliderSettings}>
                      { partnerData.map(el => (
                            <div>
                            <Link to={`/publicpartner/${el.id}`}>
                              <Image src={el.image} style={{ width: '150px', height: '100px' }} /></Link>
                            </div>
                      ))} 
                      </Slider> */}
                  <Slider className="slider-new" {...sliderSettings}>
                      <Link to={`/partners`}><img src="images/partners_and_founders/DFIDlogo.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/KOICALogo.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/EUlogo.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/GIZ-Logo.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/GermanCooperation.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/UPSLogo.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/EIFLogo.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/MaryKay.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/ITFCLogo.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/OPECFundLogo.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/MFASweden.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/MFAFinland.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/MFANetherlandslogo.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/MFAIcelandlogo.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/NorwegianMFA.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/PeopleofJapan.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/Maersk.png" alt="Maersk" /></Link>
                  </Slider>
                </div>
                <div className="dotbg l2 dotb" /></div>
              {/* partners and founders  */}
            </div>

          )}
        </div>
      </CustomizedDocumentTitle>
    );
  }
}

PageHubs.propTypes = {
  showPreloader: PropTypes.bool,
};

export default connect(mapStateToProps)(PageHubs);
