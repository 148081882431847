import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import Preloader from '../components/Preloader';
import languages from '../helpers/languages';

var language = languages['en'];
var common = language.common[0];
var learnlbl = language.Learn[0];

const mapStateToProps = (state) => {
  return {
    lan: state.language.lan,
    user: state.auth.user,
  };
};

class PageLearn extends React.Component {
  state = {
    isModalOpened: false
  };

  componentDidMount() {
    
  }

  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    learnlbl = language.Learn[0];
    
    const { showPreloader } = this.props;
   
    const url =
    'https://learn.shetrades.com/local/itcacademy/setupuser.php?academyid=9&autoissuerid=2&wantsurl=/?redirect=0';
  
    const satUrl =
    'https://learn.shetrades.com/local/itcacademy/setupuser.php?academyid=9&autoissuerid=2&wantsurl=/my/index/index.php?id=1555';
  
  const attributes =  { href: url };
  const satAttributes= { href: satUrl };
    return (
      <CustomizedDocumentTitle title={common.MY_DASHBOARD}>
        <div className='transition-page'>
          {showPreloader ? (
            <Preloader size='medium' relative />
          ) : (
            <div>
              {/*header content */}
              <header>
                <div className="innerheader innerheader-learningcenter">
                  <h1>{learnlbl.THE_VIRTUAL_LEARNING_SPACE}</h1>
                </div>
              </header>
              {/*header content */}  
              {/*free block */}
              <div className="bgdark">
                <div className="wrap">
                  <div className="bgcircles">
                    <div className="container-2col">
                      <div>
                        <h2>{learnlbl.ITS_FREE_FOR_OUR_MEMBERS}</h2> 
                        <p>{learnlbl.ACCESS_LEARNING_MATERIAL_CAREFULLY_CURATED}</p></div>
                      <NavLink to="/sign-up" className="btn">{learnlbl.REGISTER_OR_LOGIN}</NavLink>
                    </div> 
                  </div>
                </div>
              </div>
              {/*free block */}
              {/*company self-assessment  */}
              <div className="bgwhite"> 
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon bigicon-learn2 bigiconhide" />
                    <div className="bigicontextblock">
                      <h2>
                        <a {...satAttributes} target='_blank' rel="noreferrer">{learnlbl.THE_COMPANY_SELF_ASSESSMENT}<span className="icon-red_arrow" />
                        </a></h2> 
                      <p>{learnlbl.DEVELOPED_BY_OUR_CAPACITY_BUILDING_EXPERTS}</p>
                    </div>
                  </div></div>
              </div>
              {/*company self-assessment */}
              {/*Virtual Learning Space  */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon bigicon-learn2 bigiconhide" />
                    <div className="bigicontextblock"><h2> 
                      <a {...attributes} target='_blank' rel="noreferrer">{learnlbl.ACCESS_FREE_ELEARNING_COURSES} <span className="icon-red_arrow" /></a></h2> 
                      <p>{learnlbl.OUR_ELEARNING_COURSES_ARE_DELIVERED_TOGET}</p></div>
                  </div>
                </div>
              </div>
                {/*free block */}
              {/*risk assessment  */}
              <div className="bgwhite"> 
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon bigicon-coach bigiconhide" />
                    <div className="bigicontextblock">
                      <h2>
                        <a href={learnlbl.RISK_LINK} target='_blank' rel="noreferrer">{learnlbl.RISK_ASSESSMENT_TOOLKIT}<span className="icon-red_arrow" />
                        </a></h2> 
                      <p>{learnlbl.RISK_ASSESSMENT_TOOLKIT_TEXT}</p>
                    </div>
                  </div></div>
              </div>
              {/*risk assessment */}
              {/*crisis toolkit  */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon bigicon-help bigiconhide" />
                    <div className="bigicontextblock"><h2> 
                      <a href={learnlbl.CRISIS_LINK} target='_blank' rel="noreferrer">{learnlbl.CRISIS_MANAGEMENT_TOOLKIT} <span className="icon-red_arrow" /></a></h2> 
                      <p>{learnlbl.CRISIS_MANAGEMENT_TOOLKIT_TEXT}</p></div>
                  </div>
                </div>
              </div>
              {/*crisis toolkit */}
              {/*webinars */}
              {/* remove issue #172
              <div className="bgwhite"> 
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon bigicon-learn2 bigiconhide" />
                    <div className="bigicontextblock"><h2>
                      <Link to="/publicwebinars"> {learnlbl.WATCH_OUR_WEBINARS} <span className="icon-red_arrow" /></Link></h2> 
                      <p>{learnlbl.LEARN_ABOUT_THE_LATEST_INNOVATIONS_IN_E_CO}</p>
                      <div className="container-ll-bottomlinks">
                        <Link to="/publicwebinars?tab=past">{learnlbl.CATALOGUE_OF_PAST_WEBINARS_SEE_ALL_THE_UPC} <span className="icon-red_arrow" /></Link>
                        <Link to="/publicwebinars?tab=upcoming">{learnlbl.SEE_ALL_THE_UPCOMING_WEBINARS} <span className="icon-red_arrow" /></Link>
                      </div></div>
                  </div>
                </div> </div>
              {/*webinars */}
              {/*coming next */}
              {/* remove issue #172
              <div className="bgdarkblue">
                <div className="comingnext"> 
                  <p>{learnlbl.COMING_NEXT}  |  <a href="#">« Master your finances  »</a> with our partner GroFin, on 15/08/2021 - 18:30 CEST</p></div>
              </div>
              {/*coming next */}
              {/*find coach */}
              {/* <div className="bggrey">
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon bigicon-learn2 bigiconhide" />
                    <div className="bigicontextblock"><h2>{learnlbl.GET_PERSONALIZED_SUPPORT_FIND_YOUR_COACH}:  
                      <Link to="/publiccoach">{learnlbl.FIND_YOUR_COACH}<span className="icon-red_arrow" /></Link></h2> 
                      <p>{learnlbl.WATCH_THIS_SPACE_TO_BE_ONE_OF_THE_FIRST_SH}</p>
                      <Link to="/publiccoach">{learnlbl.INTERESTED_IN_BECOMING_A_COACH_SEE_HOW_}<span className="icon-red_arrow" /></Link> </div>
                  </div>
                </div>
              </div> */}
              {/*find coach */}
              {/*help */}
              {/* remove issue #172
              <div className="bgwhite">
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon bigicon-help bigiconhide" />
                    <div className="bigicontextblock"><h2>{learnlbl.FIND_THE_SUPPORT_YOU_NEED_IN_THE_HELP_CENT} 
                      <Link to="/publichelpcenter"> {learnlbl.HELP_CENTRE}<span className="icon-red_arrow" /></Link></h2> 
                      <p>{learnlbl.FIND_USEFUL_TRADE_TOOLS_AND_RESOURCES_ACC}</p> </div>
                  </div>
                </div>
                <div className="dotbg l2" />
              </div>
              {/*help */}
          </div>
          )}
        </div>
      </CustomizedDocumentTitle>
    );
  }
}

PageLearn.propTypes = {
  showPreloader: PropTypes.bool,
};

export default connect(mapStateToProps)(PageLearn);
