import React from 'react';
import { connect } from 'react-redux';
import axios from '../requests/platformApi';
import { logout, setCurrentUser } from '../actions/auth';
import Preloader from '../components/Preloader';
// import Layout from './auth-modal/layout'

class LoginChecker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loginLoaded: false,
    };
  }

  componentDidMount() {
    axios
      .get('/checkSession')
      .then((res) => {
        this.setState({ loginLoaded: true });
        this.props.setCurrentUser(res.data.user);
      })
      .catch(() => {
        
      });
  }
  render() 
  {
    const { loginLoaded } = this.state;
    if (!loginLoaded) {
      return <Preloader />;
    }
    return this.props.children;
  }
}

const mapStateToProps = (state) => ({
  checkedOnServer: state.auth.checkedOnServer,
});

const mapDispatchToProps = {
  logout,
  setCurrentUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginChecker);
