import React from 'react';
import PropTypes from 'prop-types';
import lifecycle from 'react-pure-lifecycle';
import { connect } from 'react-redux';
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import { getAllMarketTools } from '../store/actions';
import languages from '../helpers/languages';

var language = languages['en'];
var common = language.common[0];

const mapStateToProps = (state) => ({
  marketTools: state.learn.marketTools,
  lan: state.language.lan
});

const mapDispatchToProps = {
  getAllMarketTools,
};

const methods = {
  componentDidMount(props) {
    props.getAllMarketTools();
  },
};

const MarketTool = (props) => {
  language = languages[props.lan];
  common = language.common[0];
  const { title, description, iconClass, backgroundColor, moreInfoUrl } = props;

  return (
    <section className='market-tool'>
      <div className='market-tool__icon' style={{ backgroundColor }}>
        <i className={iconClass} />
      </div>

      <div className='market-tool__info'>
        <div className='content'>
          <h3>{title}</h3>

          <p>{description}</p>
        </div>
      </div>

      <div className='market-tool__right'>
        <a className='market-tool__link' target='_blank' rel="noreferrer" href={moreInfoUrl}>
          <i className='icon-redirect' /> {common.SHOW_MORE}
        </a>
      </div>
    </section>
  );
};

MarketTool.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  lan: PropTypes.string,
};

const PageMarketTools = (props) => {
  language = languages[props.lan];
  common = language.common[0];
  return (
    <CustomizedDocumentTitle title={common.MARKET_TOOLS}>
      <div>
        {/*header content */}
        <header>
          <div className="innerheader innerheader-helpcenter">
            <h1>{common.MARKET_TOOLS}</h1>
          </div>
        </header>
        {/*header content */}
        <div className="bggrey">
          <div className="wrap">
            <div className="container-2col">
              <div><p>{common.MARKET_TOOLS_INFO}</p></div>
              <div />
            </div>
          </div>
          <div className="wrap">
            <div className="container-2even">
              {props.marketTools.map((el, i) => (
                <MarketTool key={i} lan={props.lan} {...el} />
              ))}
            </div>
          </div><div className="dotbg l2 dotb" />
        </div>
      </div>
    </CustomizedDocumentTitle>
  );
};

const marketToolCycle = lifecycle(methods)(PageMarketTools);
const List = connect(mapStateToProps, mapDispatchToProps)(marketToolCycle);
export default List;
