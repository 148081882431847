import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import {
  appendCurrentChat,
  readUpdate,
  reloadChat,
} from '../../../../../../store/actions';
import { MCK_FILE_URL, FILE_PREVIEW_URL } from '../../../../../../constants';
import formatNumber from '../../../../../../helpers/format-number';
import Preloader from '../../../../../../components/Preloader';
import CustomizedDocumentTitle from '../../../../../../components/CustomizedDocumentTitle';
import Message from './components/Message';
import NewMessage from './components/NewMessage';
import Header from '../Header';
import 'emoji-mart/css/emoji-mart.css';

const getFileUrl = (blobKey) => `${MCK_FILE_URL}${FILE_PREVIEW_URL}${blobKey}`;

const formatBytes = (number) =>
  formatNumber({ number, postfix: 'B', digitsAfterComma: 1 });

const mapStateToProps = (state) => ({
  applozicApi: state.messenger.applozicApi,
  applozicApiFetching: state.messenger.applozicApiFetching,
  canLoadMore: !state.messenger.currentChatFinished,
  chatTitle: state.messenger.currentChatTitle,
  messages: state.messenger.currentChatMessages,
  oldestLoadedMessageTime: state.messenger.oldestLoadedMessageTime,
  user: state.auth.user,
  t: state.language.t,
});

const mapDispatchToProps = {
  appendCurrentChat,
  readUpdate,
  reloadChat,
};

class Chat extends Component {
  static propTypes = {
    applozicApi: PropTypes.object,
    applozicApiFetching: PropTypes.bool,
    chatTitle: PropTypes.string,
    chatId: PropTypes.string.isRequired,
    isGroup: PropTypes.bool,
    messages: PropTypes.array,
    readUpdate: PropTypes.func,
    reloadChat: PropTypes.func,
    user: PropTypes.object,
    t: PropTypes.object,
  };

  static fetchMoreCount = 30;

  state = {
    scrollTop: 0,
    userDetails: [],
  };

  isFetching = false;

  componentDidMount() {
    if (this.props.applozicApi) {
      this.fetchMessagesArray();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.messages && !prevProps.messages) {
      setTimeout(() => this.scrollToBottom(), 100); // bad rendering fix
    } else if (
      this.props.messages &&
      prevProps.messages.length < this.props.messages.length
    ) {
      const { scrollTop } = this.state;
      if (scrollTop > 0.8) {
        this.scrollToBottom();
      }
    }
    if (!prevProps.applozicApi && this.props.applozicApi) {
      this.fetchMessagesArray();
    }
  }

  onScrollbarsUpdate({ top }) {
    if (top !== this.state.scrollTop) {
      this.setState({ scrollTop: top });
      if (top === 1) {
        this.readUpdate();
      } else if (top < 0.05) {
        this.fetchOlderMessages();
      }
    }
  }

  async fetchOlderMessages() {
    const { canLoadMore } = this.props;
    if (!this.isFetching && canLoadMore) {
      const { oldestLoadedMessageTime } = this.props;
      const height = this.scrollable.getScrollHeight();
      await this.fetchMessagesArray(oldestLoadedMessageTime);
      const newHeight = this.scrollable.getScrollHeight();
      this.scrollable.scrollTop(newHeight - height);
    }
  }

  fetchMessagesArray(endTime) {
    if (this.isFetching) return;
    const isInitialFetch = !endTime;
    const {
      appendCurrentChat,
      applozicApi,
      chatId,
      isGroup,
      reloadChat,
    } = this.props;
    const { ALApiService } = applozicApi;
    const requestData = {};
    this.isFetching = true;
    if (isGroup) {
      requestData.groupId = chatId;
    } else {
      requestData.userId = chatId;
    }
    if (endTime) {
      requestData.endTime = endTime;
      requestData.pageSize = Chat.fetchMoreCount;
    }
    if (isInitialFetch) {
      reloadChat({ chatId, isGroup });
    }
    return new Promise((resolve, reject) => {
      ALApiService.getMessages({
        data: requestData,
        success: ({ data: { message, userDetails } }) => {
          appendCurrentChat({ message, userDetails });
          this.isFetching = false;
          resolve();
          if (isInitialFetch) {
            this.setState({ userDetails });
          }
        },
        error: () => {
          this.isFetching = false;
          reject();
        },
      });
    });
  }

  scrollToBottom() {
    if (this.scrollable) {
      this.scrollable.scrollToBottom();
      this.readUpdate();
    }
  }

  readUpdate() {
    const { applozicApi, chatId, isGroup, readUpdate } = this.props;
    const { ALApiService } = applozicApi;
    ALApiService.conversationReadUpdate({
      data: isGroup
        ? `groupId=${chatId}`
        : `userId=${encodeURIComponent(chatId)}`,
    });
    readUpdate({ chatId, isGroup });
  }

  render() {
    const {
      applozicApi,
      applozicApiFetching,
      chatId,
      isGroup,
      messages,
      chatTitle,
      user,
      canLoadMore,
      t,
    } = this.props;
    const isApiReady = applozicApi && !applozicApiFetching;
    let lastResponseAuthor;
    let lastDayRendered;
    return (
      <CustomizedDocumentTitle title={chatTitle}>
        <Fragment>
          {chatTitle && <Header title={chatTitle} />}
          <div className='messenger-body list-animation-item'>
            <div className='chat'>
              <div className='chat-scrollable'>
                {!messages ? (
                  <div style={{ paddingTop: '20px' }}>
                    <Preloader relative size='small' />
                  </div>
                ) : !messages.length ? (
                  <p className='content' style={{ paddingTop: '20px' }}>
                    {t.messages[0].NO_MESSAGES}
                  </p>
                ) : (
                  <Scrollbars
                    autoHide
                    ref={(el) => (this.scrollable = el)}
                    onUpdate={this.onScrollbarsUpdate.bind(this)}
                  >
                    {messages.map((item) => {
                      const {
                        key,
                        message,
                        createdAtTime: time,
                        type,
                        status,
                        file,
                        fileMeta,
                        metadata,
                        contactIds,
                      } = item;
                      const author =
                        _.find(
                          this.state.userDetails,
                          ({ userId }) => contactIds === userId
                        ) || {};
                      const { displayName, imageLink } = author;
                      const { location: metaLocation } = metadata;
                      const my = type === 'outbox' || type === 5;
                      const read = my && status === 5;
                      const name = my ? '' : displayName;
                      const avatar = my ? user.avatar : imageLink;
                      let isFirstReply = false;
                      let newDay;
                      if (
                        !lastDayRendered ||
                        !moment(time).isSame(moment(lastDayRendered), 'days')
                      ) {
                        lastDayRendered = time;
                        newDay = time;
                      } else if (my) {
                        if (lastResponseAuthor !== 'me') {
                          isFirstReply = true;
                        }
                        lastResponseAuthor = 'me';
                      } else if (name) {
                        if (lastResponseAuthor !== name) {
                          isFirstReply = true;
                        }
                        lastResponseAuthor = name;
                      }

                      const fileProps = {};
                      if (metaLocation) {
                        fileProps.location = JSON.parse(metaLocation);
                      } else if (fileMeta) {
                        const {
                          name: fileName,
                          size,
                          key,
                          blobKey,
                          thumbnailUrl,
                        } = fileMeta;
                        if (thumbnailUrl) {
                          fileProps.photos = [
                            {
                              id: key,
                              thumb: thumbnailUrl,
                              full: getFileUrl(blobKey),
                              name: fileName,
                              size: formatBytes(size),
                            },
                          ];
                        } else {
                          fileProps.documents = [
                            {
                              id: key,
                              size: formatBytes(size),
                              name: fileName,
                              url: getFileUrl(blobKey),
                            },
                          ];
                        }
                      } else if (file) {
                        const {
                          name: fileName,
                          size,
                          key,
                          thumbnailUrl,
                          url,
                        } = file;
                        if (thumbnailUrl) {
                          fileProps.photos = [
                            {
                              id: key,
                              thumb: thumbnailUrl,
                              full: url,
                              name: fileName,
                              size: formatBytes(size),
                            },
                          ];
                        } else {
                          fileProps.documents = [
                            {
                              id: key,
                              size: formatBytes(size),
                              name: fileName,
                              url,
                            },
                          ];
                        }
                      }
                      return (
                        <Message
                          key={key}
                          text={message}
                          time={time}
                          newDay={newDay}
                          isFirstReply={isFirstReply}
                          received={read}
                          my={my}
                          failed={false}
                          name={name}
                          avatar={avatar}
                          {...fileProps}
                        />
                      );
                    })}
                  </Scrollbars>
                )}
              </div>

              {isApiReady ? (
                <NewMessage isGroup={isGroup} chatId={chatId} />
              ) : (
                <Preloader relative size='small' />
              )}
            </div>
          </div>
        </Fragment>
      </CustomizedDocumentTitle>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
