import React from 'react';
import TopFiltersDesktop from '../components/Desktop';
import TopFiltersMobile from '../components/Mobile';

const TopFilters = ({ isMobile, ...rest }) => {
  if (isMobile) {
    return <TopFiltersMobile {...rest} />;
  }

  return <TopFiltersDesktop {...rest} />;
};

export default TopFilters;
