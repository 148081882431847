import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import DownloadLink from '../../../components/DownloadLink';

const AdditionalFiles = ({ files, isPreview, t }) => {
  return (
    <Fragment>
      <h3>{t.create_tender[0].ADDITIONAL_FILES}:</h3>
      <div className='download-links'>
        {files.map((el, i) => (
          <DownloadLink preview={isPreview} key={i} {...el} />
        ))}
      </div>
    </Fragment>
  );
};

AdditionalFiles.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string.isRequired,
      size: PropTypes.string.isRequired,
      noBorder: PropTypes.bool,
    })
  ),
  isPreview: PropTypes.bool,
  t: PropTypes.object,
};

AdditionalFiles.defaultProps = {
  files: [],
};

const mapStateToProps = (state) => ({ t: state.language.t });

export default connect(mapStateToProps)(AdditionalFiles);
