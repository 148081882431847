import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import lifecycle from 'react-pure-lifecycle';
import { connect } from 'react-redux';
import Page from '../components/Page';
import CardOfMember from '../components/CardOfMember';
import MemberSuggestion from '../components/Suggestion';
import Member from '../components/Member';
import Preloader from '../components/Preloader';
import NoDataCard from '../components/Card/NoDataCard';
import TopMessage from '../components/TopMessage';
import {
  fetchNetwork,
  fetchMemberDetails,
  updateContact,
  addContact,
  knownUser,
  fetchRequest,
  fetchReviews,
  addReview,
  onMyNetworkMessageClose,
  onMemberMessageClose,
  fetchSearchResult
} from '../store/actions';
import languages from '../helpers/languages';
import {
  COUNTRY,NAME 
} from '../components/Search/TopFilters/types';

var language = languages['en'];
var myNetwork = language.my_network[0];
var common = language.common[0];

const mapStateToProps = (state) => ({
  query: state.search.query,
  cards: state.searchResult.search,
  totalItems: state.searchResult.totalItems,
  sortLabel: state.searchResult.sortLabel,
  showMessage: state.searchResult.messageShow,
  search: state.search,
 
  isMobile: state.responsive.isMobile,
  isFetching: state.searchResult.isFetching,

  defaultCards: state.network.network,
  defaultShowMessage: state.network.messageShow,
  defaultTotalItems: state.network.totalItems,
  defaultSortLabel: state.network.sortLabel,

  detail: state.members.memberDetails,
  detailStatus: state.members.messageShow,
  knownUserList: state.network.knownUser,
  requestUserList: state.network.request,
  reviews: state.members.reviews,
  totalReviews: state.members.totalReviews,
  contactRemoveFlag: state.network.contactRemoveFlag,
  userRole: state.auth.user.userRole,
  lan: state.language.lan,
});

const mapDispatchToProps = {
  fetchNetwork,
  fetchMemberDetails,
  updateContact,
  addContact,
  knownUser,
  fetchRequest,
  fetchReviews,
  addReview,
  onMyNetworkMessageClose,
  onMemberMessageClose,
  fetchSearchResult
};

const methods = {
  componentDidMount(props) {
    props.fetchNetwork(window.location.hash);
    //if query
    props.fetchSearchResult(window.location.hash, props.search)
    props.knownUser();
    props.fetchRequest('/#/companies');
    var queryParam = window.location.hash.split('/');
    if (queryParam[3] && queryParam[4]) {
      props.fetchMemberDetails(window.location.hash);
      props.fetchReviews(window.location.hash, 0, 1);
    }
  },
};

const getRenderCards = (props) => (cards,defaultCards) => (
  <div className='cards-list'>
    {cards === null ? (
      <Preloader size='medium' relative />
    ) : cards.length > 0 ? (
      cards.map((card, i) => (
        <CardOfMember
          key={card.id || i}
          detailUrl='my-network'
          pageType='my-network'
          detailFunction={props.fetchMemberDetails}
          updateContactFunction={props.updateContact}
          mini
          {...card}
        />
      ))
    ) : (
      <NoDataCard />
    )}
  </div>
);

const PageMyNetwork = (props) => {
  language = languages[props.lan];
  myNetwork = language.my_network[0];
  common = language.common[0];
  var menu = language.menu[0];
  var messaging = common.YOU_HAVE_SUCCESSFULLY_SEND_REQUEST;
  var toolbar = {
    tabs: {
      values: {
        individual: { text: common.INDIVIDUALS },
        company: { text: common.COMPANIES },
        all:{ text: common.ALL }
      },
      filterByKeys: (card) => [card.type],
    },
    sort: [
      { orderBy: 'date', order: 'ASC', label: common.OLDEST },
      { orderBy: 'date', order: 'DESC', label: common.RECENT },
    ],
  };
  const { cards, defaultCards, totalItems, detail, reviews } = props;
  return (
    <Page
      name={menu.MY_NETWORK}
      handleIds={true}
      canCreateNew={false}
      hasLeftPanel={true}
      topfilters={[COUNTRY]}
      toolbar={toolbar}
      isSearchPage={'searchResult'}
      fetchFunction={props.fetchSearchResult}
      search={props.search}
      totalItems={totalItems}
      sortLabel={props.sortLabel}
      cards={cards}
      renderAfterTitle={() => (
        <div className='wrap'>
          <div className='container'>
            <p className='gray'>{myNetwork.INFO}</p>
          </div>
        </div>
      )}
      renderCards={getRenderCards(props)}
      cardsPerPage={9}
      renderRight={() => (
        <Fragment>
          {props.contactRemoveFlag === false && (
            <TopMessage
              isShown={props.showMessage}
              onCloseClick={() => props.onMyNetworkMessageClose(false)}
              type='success'
              text={common.YOU_HAVE_SUCCESSFULLY_APPLIED}
            />
          )}

          {props.contactRemoveFlag === true && (
            <TopMessage
              isShown={props.contactRemoveFlag}
              onCloseClick={() => props.onMyNetworkMessageClose(false)}
              type='success'
              text={common.CONTACT_SUCCESSFULLY_REMOVED}
            />
          )}

          {props.canCreateNew === true && (
            <TopMessage
              isShown={props.showMessage}
              onCloseClick={() => props.onMessageClose(false)}
              type='success'
              text={messaging}
            />
          )}
        </Fragment>
      )}
      {...props}
      renderDetail={(match) => (
        <Member
          {...detail}
          pageType='networkDetails'
          memberMessageClose={props.onMemberMessageClose}
          topMessageStatus={props.detailStatus}
          updateContactFunction={props.updateContact}
          addContactFunction={props.addContact}
          reviews={reviews}
          totalReviews={props.totalReviews}
          fetchReviewsFunction={props.fetchReviews}
          addReviewFunction={props.addReview}
        />
      )}
    />
  );
};

PageMyNetwork.propTypes = {
  isMobile: PropTypes.bool,
};

PageMyNetwork.defaultProps = {
  cards: [],
  requestUserList: [],
  knownUserList: [],
};

const PageMyNetworkCycle = lifecycle(methods)(PageMyNetwork);
const List = connect(mapStateToProps, mapDispatchToProps)(PageMyNetworkCycle);

export default List;
