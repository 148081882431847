import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';
import classNames from 'classnames';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import CardForNews from '../../../../components/CardForNews';
import Dropdown from '../../../../components/Dropdown';
import NoDataCard from '../../../../components/Card/NoDataCard';
import Modal from '../../../../components/Modal';
import { fetchMonth } from '../../../../store/actions';

class Cards extends React.Component {
  state = {
    daySelected: null,
    isModalOpened: false,
  };

  setIsModalOpened(isModalOpened) {
    this.setState({ isModalOpened });
  }

  onDayClick({ day, events }) {
    this.setState({
      daySelected: { day, events },
      isModalOpened: true,
    });
  }

  render() {
    const {
      cards,
      filter,
      page,
      cardsPerPage,
      location,
      featureItems,
      totalItems,
      addBookmark,
      previousUrl,
      detailBookmark,
      isMobile,
      fetchMonth,
      t,
    } = this.props;
    const { daySelected, isModalOpened } = this.state;
    const queryObject = queryString.parse(location.search);
    const isCurrentTabNotDefault =
      'tab' in queryObject && queryObject.tab !== 'upcoming';
    const filteredCards = cards;
    const countFeature = featureItems;
    const now = moment.now();
    var small = [];
    var big = [];
    var i = 0;
    for (var key in cards) {
      if (!isMobile && i < countFeature && cards[key].featured === 0) {
        big.push(
          <CardForNews
            forBigNews
            key={i}
            {...cards[key]}
            listType='webinars'
            region={null}
          />
        );
      } else {
        small.push(
          <CardForNews
            key={i}
            {...cards[key]}
            listType='webinars'
            region={null}
          />
        );
      }
      i++;
    }
    return queryObject.view === 'calendar' ? (
      <div className='calendar calendar_big-for-events calendar_full-width list-animation-item'>
        <DayPickerRangeController
          noBorder
          numberOfMonths={1}
          enableOutsideDays
          navPrev={`${t.common[0].PREVIOUS} ${t.events[0].MONTH}`}
          navNext={`${t.common[0].NEXT} ${t.events[0].MONTH}`}
          daySize={isMobile ? 30 : 110}
          hideKeyboardShortcutsPanel
          transitionDuration={0}
          weekDayFormat={isMobile ? 'ddd' : 'dddd'}
          onPrevMonthClick={(moment) => fetchMonth(moment)}
          onNextMonthClick={(moment) => fetchMonth(moment)}
          renderDayContents={(day) => {
            const dayEvents = cards.filter(
              (el) =>
                day.isSameOrAfter(el.start, 'day') &&
                day.isSameOrBefore(el.end, 'day')
            );

            if (isMobile) {
              return (
                <div
                  className={classNames([
                    'calendar-maybeclickable',
                    'day',
                    {
                      'day_has-something': dayEvents.length,
                      'day_has-event': dayEvents.length,
                    },
                  ])}
                  onClick={this.onDayClick.bind(this, {
                    day,
                    events: dayEvents,
                  })}
                >
                  {day.format('D')}
                </div>
              );
            }
            if (dayEvents.length === 1) {
              return (
                <Link
                  to={'/webinars/' + dayEvents[0].id}
                  className='calendar-maybeclickable'
                >
                  <span className='calendar-day'>{day.format('D')}</span>
                  <span
                    className={`calendar-event${
                      day.isBefore(now, 'days') ? ' calendar-event_past' : ''
                    }`}
                  >
                    {dayEvents[0].title}
                  </span>
                </Link>
              );
            }
            if (dayEvents.length > 1) {
              return (
                <div className='calendar-maybeclickable'>
                  <span className='calendar-day'>{day.format('D')}</span>

                  <Dropdown
                    addClassName='dropdown_white'
                    top={`${dayEvents.length} ${t.menu[0].WEBINARS}`}
                    links={dayEvents.map((el) => ({
                      to: '/webinars/' + el.id,
                      title: el.title,
                    }))}
                  />
                </div>
              );
            }
            return (
              <div className='calendar-maybeclickable'>{day.format('D')}</div>
            );
          }}
        />

        {isMobile && (
          <Modal
            isOpen={isModalOpened}
            onRequestClose={this.setIsModalOpened.bind(this, false)}
            noBottom
          >
            <Fragment>
              <span
                className='back'
                onClick={this.setIsModalOpened.bind(this, false)}
              >
                {t.events[0].BACK_TO_CALENDAR}
              </span>
              {!daySelected ? (
                <p className='content'>{t.events[0].NO_DAY_SELECTED}</p>
              ) : (
                <Fragment>
                  <h3>{moment(daySelected.day).format('D MMM YYYY')}</h3>
                  {daySelected.events && daySelected.events.length ? (
                    <div className='odd-items'>
                      {daySelected.events.map((el, i) => (
                        <CardForNews
                          key={i}
                          {...el}
                          listType='webinars'
                          addBookmark={addBookmark}
                          previousUrl={previousUrl}
                          detailBookmark={detailBookmark}
                          region={null}
                        />
                      ))}
                    </div>
                  ) : (
                    <p className='content'>{t.events[0].NO_EVENTS_DAY}</p>
                  )}
                </Fragment>
              )}
            </Fragment>
          </Modal>
        )}
      </div>
    ) : isCurrentTabNotDefault ? (
      <Fragment>
        <p className='cards-superscript'>{t.webinars[0].ALL_WEBINARS}</p>

        <div className='odd-items'>
          {filteredCards.map((el, i) => (
            <CardForNews
              key={i}
              {...el}
              listType='webinars'
              addBookmark={addBookmark}
              previousUrl={previousUrl}
              detailBookmark={detailBookmark}
              region={null}
            />
          ))}
          {cards.length === 0 && <NoDataCard />}
        </div>
      </Fragment>
    ) : (
      <Fragment>
        <div
          className='featured-cards'
          style={big.length ? null : { display: 'none' }}
        >
          <p className='cards-superscript'>{t.events[0].FEATURE_EVENT}</p>
          {big}
        </div>
        <p className='cards-superscript'>{t.webinars[0].ALL_WEBINARS}</p>
        <div className='odd-items'>
          {small}
          {small.length === 0 && <NoDataCard />}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isMobile: state.responsive.isMobile,
  t: state.language.t,
});

const mapDispatchToProps = {
  fetchMonth,
};

const CardsRedux = connect(mapStateToProps, mapDispatchToProps)(Cards);
export default (
  cards,
  filter,
  page,
  cardsPerPage,
  location,
  featureItems,
  totalItems,
  addBookmark,
  previousUrl,
  detailBookmark
) => {
  const props = {
    cards,
    filter,
    page,
    cardsPerPage,
    location,
    featureItems,
    totalItems,
    addBookmark,
    previousUrl,
    detailBookmark,
  };
  return <CardsRedux {...props} />;
};
