import findIndex from 'lodash/findIndex';
import {
  ADD_FLASH_MESSAGE,
  REMOVE_FLASH_MESSAGE,
} from '../actions/flash-messages';

let id = 0;

export default function (state = [], action) {
  let index;
  switch (action.type) {
    case ADD_FLASH_MESSAGE:
      id++;
      return [
        ...state,
        {
          id,
          text: action.payload.message,
          type: action.payload.type,
        },
      ];
    case REMOVE_FLASH_MESSAGE:
      index = findIndex(
        state,
        (message) => message.id === action.payload.messageId
      );
      return [...state.slice(0, index), ...state.slice(index + 1)];
    default:
      return state;
  }
}
