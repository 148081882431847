import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tag from '../../../components/Tag';

export default class ListWithFilters extends Component {
  constructor() {
    super();

    this.state = {};
  }

  static propTypes = {
    tabs: PropTypes.object.isRequired,
    list: PropTypes.array.isRequired,
  };

  componentDidMount() {
    const { tabs } = this.props;

    this.setState({
      tab: Object.keys(tabs)[0],
    });
  }

  setActiveTab(tab) {
    this.setState({
      tab,
    });
  }

  render() {
    const { tabs, list } = this.props;

    const { tab } = this.state;

    return (
      <div className='list-with-filters'>
        <ul className='list-with-filters-toggles'>
          {Object.keys(tabs).map((key) => (
            <li
              key={key}
              onClick={() => this.setActiveTab(key)}
              className={classNames({
                active: tab === key,
              })}
            >
              {tabs[key]}
            </li>
          ))}
        </ul>

        <p className='tags'>
          {list
            .filter((el) => el.type === tab)
            .map((el) => (
              <Tag key={el.id} type='default' text={el.name} />
            ))}
        </p>
      </div>
    );
  }
}
