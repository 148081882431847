import {
  FETCH_DASHBOARD,
  DASHBOARD_FETCHED,
  DASHBOARD_REORDER,
  TOGGLE_DASHBOARD_SECTION,
} from '../store/actions';
import { moveOut, moveOver } from './../helpers/drag-and-drop';
import _ from 'lodash';
import dashboardSections from './dashboardSections';

const dashboardReducer = (
  state = { phone: {}, desktop: {}, hasFetched: false },
  action
) => {
  const { type, payload } = action;
  const { isPhone } = payload || {};
  const stateKey = isPhone ? 'phone' : 'desktop';
  switch (type) {
    case FETCH_DASHBOARD:
      return {
        ...state,
        hasFetched: false,
      };

    case DASHBOARD_FETCHED:
      const { sectionsOrder, lan } = payload;
      const dashboardSectionsTemp = dashboardSections[lan];
      const onlyExistingSections = Object.entries(sectionsOrder)
        .filter(([key]) => key in dashboardSectionsTemp)
        .reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value,
          }),
          {}
        );
      const sectionsInfo = _.merge(
        {},
        dashboardSectionsTemp,
        onlyExistingSections
      );
      const mobileSort = (a, b) => a.indexMobile - b.indexMobile;
      const desktopSort = (a, b) => a.index - b.index;
      return {
        hasFetched: true,
        phone: {
          visible: Object.values(sectionsInfo)
            .filter(({ isHiddenMobile }) => !isHiddenMobile)
            .sort(mobileSort),
          hidden: Object.values(sectionsInfo)
            .filter(({ isHiddenMobile }) => isHiddenMobile)
            .sort(mobileSort),
        },
        desktop: {
          visibleLeft: Object.values(sectionsInfo)
            .filter(({ isHidden, isRight }) => !isHidden && !isRight)
            .sort(desktopSort),
          visibleRight: Object.values(sectionsInfo)
            .filter(({ isHidden, isRight }) => !isHidden && isRight)
            .sort(desktopSort),
          hiddenLeft: Object.values(sectionsInfo)
            .filter(({ isHidden, isRight }) => isHidden && !isRight)
            .sort(desktopSort),
          hiddenRight: Object.values(sectionsInfo)
            .filter(({ isHidden, isRight }) => isHidden && isRight)
            .sort(desktopSort),
        },
      };

    case DASHBOARD_REORDER:
      const { source, destination } = payload;
      if (!destination) {
        return state;
      }
      const isSourceRight = source.droppableId.includes('Right');
      const isDestinationRight = destination.droppableId.includes('Right');
      if (isSourceRight !== isDestinationRight) {
        return state;
      }
      if (source.droppableId === destination.droppableId) {
        const sections = moveOver(
          state[stateKey][source.droppableId],
          source.index,
          destination.index
        );
        return {
          ...state,
          [stateKey]: {
            ...state[stateKey],
            [source.droppableId]: sections,
          },
        };
      } else {
        const result = moveOut(
          state[stateKey][source.droppableId],
          state[stateKey][destination.droppableId],
          source,
          destination
        );
        return {
          ...state,
          [stateKey]: {
            ...state[stateKey],
            ...result,
          },
        };
      }

    case TOGGLE_DASHBOARD_SECTION:
      const { sourceDroppableId, sourceIndex } = payload;
      let destinationDroppableId;
      switch (sourceDroppableId) {
        case 'visible':
          destinationDroppableId = 'hidden';
          break;
        case 'hidden':
          destinationDroppableId = 'visible';
          break;
        case 'visibleLeft':
          destinationDroppableId = 'hiddenLeft';
          break;
        case 'hiddenLeft':
          destinationDroppableId = 'visibleLeft';
          break;
        case 'visibleRight':
          destinationDroppableId = 'hiddenRight';
          break;
        case 'hiddenRight':
          destinationDroppableId = 'visibleRight';
          break;
        default:
        // intentional comment
      }
      const result = moveOut(
        state[stateKey][sourceDroppableId],
        state[stateKey][destinationDroppableId],
        {
          droppableId: sourceDroppableId,
          index: sourceIndex,
        },
        {
          droppableId: destinationDroppableId,
          index: 0,
        }
      );
      return {
        ...state,
        [stateKey]: {
          ...state[stateKey],
          ...result,
        },
      };

    default:
      return state;
  }
};

export default dashboardReducer;
