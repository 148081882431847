export default function (data, tab, language) {
  var validationMessageCommon = language.common[0];
  var errors = {};
  var isThereError = false;
  var fieldError = {};

  if (tab === 1 && data[tab] !== undefined) {
    var values = data[tab]['values'];
    if (
      !values.hasOwnProperty('title') ||
      values.title === '' ||
      values.title === undefined ||
      values['title'].trim() === ''
    ) {
      errors.title = [validationMessageCommon.PROVIDE_TITLE];
    }

    if (
      !values.hasOwnProperty('subjectType') ||
      values.subjectType === '' ||
      values.subjectType === undefined
    ) {
      errors.subjectType = [validationMessageCommon.CHOOSE_PRODUCT_SERVICE];
    }

    if (values.subjectType === 'product') {
      if (
        values.subjectType === 'product' &&
        (!values.hasOwnProperty('product') ||
          values.product === '' ||
          values.product === '{}' ||
          values.product === 'null' ||
          values.product.indexOf('"checked":true') <= 0)
      ) {
        errors.product = [validationMessageCommon.SELECT_PRODUCT];
      }
    } else if (values.subjectType === 'service') {
      if (
        values.subjectType === 'service' &&
        (!values.hasOwnProperty('service') ||
          values.service === '' ||
          values.service === '{}' ||
          values.service === 'null' ||
          values.service.indexOf('"checked":true') <= 0)
      ) {
        errors.service = [validationMessageCommon.SELECT_SERVICE];
      }
    }

    if (
      !values.hasOwnProperty('location') ||
      values.location === '' ||
      values.location === undefined
    ) {
      errors.location = [validationMessageCommon.SELECT_LOCATION];
    }

    if (
      !values.hasOwnProperty('city') ||
      values.city === '' ||
      values.city === undefined ||
      values['city'].trim() === ''
    ) {
      errors.city = [validationMessageCommon.ENTER_CITY];
    }

    if (/\d/.test(values['city'])) {
      errors.city = [validationMessageCommon.ENTER_CITY_NUMERIC];
    }
  }

  if (data[tab] !== undefined && tab === 2) {
    var values = data[tab]['values'];

    if (
      !values.hasOwnProperty('description') ||
      values.description === '' ||
      values.description === undefined ||
      values['description'].trim() === ''
    ) {
      errors.description = [validationMessageCommon.PROVIDE_DESCRIPTION];
    }

    if (!values.hasOwnProperty('allMembers')) {
      errors.allMembers = [validationMessageCommon.SELECT_OPTION];
    }
  }

  isThereError = isEmpty(errors);

  var returnData = {};

  returnData.errors = errors;
  returnData.hasError = isThereError;

  if (returnData.hasError) {
    returnData.message = validationMessageCommon.FILL_ALL_REQUIRED_FIELDS;
  } else {
    returnData.message = '';
  }

  return returnData;
}

function isEmpty(data) {
  for (var item in data) {
    return true;
  }

  return false;
}
