/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Link, withRouter } from 'react-router-dom';
import CustomizedDocumentTitle from '../../components/CustomizedDocumentTitle';
import CustomSelect from '../../components/Select/CustomSelect';
import languages from '../../helpers/languages';
import {
  addBookmark, doOfferErrors, fetchOffers, knownUser, searchCountries, searchProducts
} from '../../store/actions';
import $ from 'jquery';
import { Form } from 'informed';
import UserNameWithAvatar from '../../components/UserNameWithAvatar';
import Region from '../../components/Region';
import MemberSuggestion from '../../components/Suggestion';
import { reset, searchSubmit } from '../../reducers/search';
import {
  fetchSearchResult
} from '../../store/actions';


var language = languages['en'];
var common = language.common[0];

const mapStateToProps = (state) => {
  return {
    lan: state.language.lan,
    user: state.auth.user,
    search: state.search,
    cards: state.offers.offers,
    totalItems: state.offers.totalItems,
    sortLabel: state.offers.sortLabel,
    productOptions: state.defaultLoad.productOptions,
    countryOptions: state.defaultLoad.countryOptions,
    hasError: state.offers.formHasError,
    errorMessages: state.offers.errorMessages,
    formHasError: state.offers.formHasError,
    mainErrorMessage: state.offers.mainErrorMessage,
    knownUserList: state.network.knownUser,
  };
};

const mapDispatchToProps = {
  fetchOffers,
  addBookmark,
  searchProducts,
  searchCountries,
  doOfferErrors,
  knownUser,
  fetchSearchResult,
  onResetClick: reset,
  onSearchSubmit: searchSubmit,
};

class Page extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      cardsPerPage: 9,
      upperPageBound: 9,
      lowerPageBound: 0,
      isPrevBtnActive: 'disabled',
      isNextBtnActive: '',
      pageBound: 9,
      stateSelect1: '',
      stateSelect2: '',
      stateSelect3: '',
      stateSelect4: '',
      dataset: '',
      formApi: null,
      queryInput: '',
    };

    this.clear = this.clear.bind(this);
    this.onQueryInputChange = this.onQueryInputChange.bind(this);
    this.setFormApi = this.setFormApi.bind(this);
    this.submit = this.submit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.btnDecrementClick = this.btnDecrementClick.bind(this);
    this.btnIncrementClick = this.btnIncrementClick.bind(this);
    this.btnNextClick = this.btnNextClick.bind(this);
    this.btnPrevClick = this.btnPrevClick.bind(this);
    // this.componentDidMount = this.componentDidMount.bind(this);
    this.setPrevAndNextBtnClass = this.setPrevAndNextBtnClass.bind(this);



    this.data1 = {
      wrapperClass: 'custom-select',
      instanceID: 'comp-1',
      optionCol: '#636b6f',
      callback: this.updatestate1,
      options: ['Sort By Recommended', 'PRODUCT','COUNTRY','DATES']
    }
    this.data2 = {
      wrapperClass: 'c-s-box2',
      instanceID: 'comp-1',
      optionCol: '#636b6f',
      callback: this.updatestate2,
      options: ['PRODUCT','COUNTRY','DATES']
    }
    this.data3 = {
      wrapperClass: 'c-s-box3',
      instanceID: 'comp-1',
      optionCol: '#636b6f',
      callback: this.updatestate3,
      options: ['PRODUCT','COUNTRY','DATES']
    }
    this.data4 = {
      wrapperClass: 'c-s-box4',
      instanceID: 'comp-1',
      optionCol: '#636b6f',
      callback: this.updatestate4,
      options: ['PRODUCT','COUNTRY','DATES']
    }
    this.data5 = {
      wrapperClass: 'c-s-box5',
      instanceID: 'comp-1',
      optionCol: '#636b6f',
      callback: this.updatestate5,
      options: ['Add Dataset', 'Governance Performance Index', 'Human Development', 'Welfare', 'Education', 'Health', 'Economic Opportunity', 'Public Managment', 'Business Environment', 'Infrastructure', 'Rural Environment', 'Security & Rule of Law', 'Rule of Law', 'Transparency and Accountability', 'Persoanl Safety', 'State Security', 'Inclusion & Human Rights', 'Participation', 'Rights', 'Gender']
    }

  }


  updatestate1 = (val) => {
    this.setState(
      state => ({ stateSelect1: val })
    );
  }
  updatestate2 = (val) => {
    this.setState(
      state => ({ stateSelect2: val })
    );
  }
  updatestate3 = (val) => {
    this.setState(
      state => ({ stateSelect2: val })
    );
  }
  updatestate4 = (val) => {
    this.setState(
      state => ({ stateSelect2: val })
    );
  }
  updatestate5 = (val) => {
    this.setState(
      state => ({ dataset: val })
    );
  }

  handleClick(event) {
    let listid = Number(event.target.id);
    this.setState({
      currentPage: listid
    });
    $("ul li.active").removeClass('active');
    $('ul li#' + listid).addClass('active');
    this.setPrevAndNextBtnClass(listid);
  }

  setPrevAndNextBtnClass(listid) {
    let totalPage = Math.ceil(this.state.cards.length / this.state.cardsPerPage);
    this.setState({ isNextBtnActive: 'disabled' });
    this.setState({ isPrevBtnActive: 'disabled' });
    if (totalPage === listid && totalPage > 1) {
      this.setState({ isPrevBtnActive: '' });
    }
    else if (listid === 1 && totalPage > 1) {
      this.setState({ isNextBtnActive: '' });
    }
    else if (totalPage > 1) {
      this.setState({ isNextBtnActive: '' });
      this.setState({ isPrevBtnActive: '' });
    }
  }
  btnIncrementClick() {
    this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
    this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
    let listid = this.state.upperPageBound + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  }
  btnDecrementClick() {
    this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound });
    this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
    let listid = this.state.upperPageBound - this.state.pageBound;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  }
  btnPrevClick() {
    if ((this.state.currentPage - 1) % this.state.pageBound === 0) {
      this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound });
      this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
    }
    let listid = this.state.currentPage - 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  }
  btnNextClick() {
    if ((this.state.currentPage + 1) > this.state.upperPageBound) {
      this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
      this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
    }
    let listid = this.state.currentPage + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  }

  componentDidUpdate() {
    $("ul li.active").removeClass('active');
    $('ul li#' + this.state.currentPage).addClass('active');
    const { isMobile } = this.props;
    if (isMobile) return;
    const { formApi } = this.state;
  }
  componentDidMount() {
    //initial fetch
    var tab = '?order=DESC&orderBy=date';
    const { location } = this.props;
    const queryObject = queryString.parse(decodeURIComponent(location?.search));
    if (
      typeof queryObject.query === 'string' &&
      queryObject.query.length !== 0
    ) {
      this.setState({
        queryInput: queryObject.query,
      });
    }

    this.props.fetchOffers(window.location.hash + tab);
    this.props.searchProducts();
    this.props.searchCountries();
    this.props.knownUser();

  }

  clear() {
    const { onResetClick } = this.props;
    onResetClick();
    this.setState({
      queryInput: '',
    });
  }

  onQueryInputChange(e) {
    this.setState({
      queryInput: e.target.value,
    });
  }

  persist(values, searchUrl = null) {
    const { fetchSearchResult, onSearchSubmit } = this.props;
    const { formApi, queryInput } = this.state;
    formApi.setValues(values);
    onSearchSubmit(values);
    if (searchUrl) {
      fetchSearchResult(searchUrl, values, true);
    } else {
      fetchSearchResult(window.location.hash, values, true);
    }
  }

  setFormApi(formApi) {
    this.setState({
      formApi,
    });
  }

  submit() {
    const { history } = this.props;
    const { formApi, queryInput } = this.state;
    const trimmedQuery = encodeURIComponent(queryInput.trim());
    var pageUrl = window.location.hash.split('/');
    var currentTab = '';
    if (pageUrl[1]) {
      pageUrl = pageUrl[1];
      pageUrl = pageUrl.split('?');
      if (pageUrl[0] && pageUrl[0] === 'publicsearch') {
        var qParam = queryString.parse(decodeURIComponent(pageUrl[1]));
        if (qParam['tab']) {
          currentTab = 'tab=' + qParam['tab'];
        }
      }
    }
    const formState = formApi.getState();
    if (Object.keys(formState.touched) !== 0 || trimmedQuery.length !== 0) {
      const location = `/publicoffers?query=${trimmedQuery}&${currentTab}`;
      this.persist(formState.values, location);
      this.props.history.push(location);
    }
  }
  getInitialValues() {
  }
  render() {
    language = languages[this.props.lan];
    common = language.common[0];

    const { queryInput, currentPage, cardsPerPage, upperPageBound, lowerPageBound, isPrevBtnActive, isNextBtnActive } = this.state;

    const initialValues = this.getInitialValues();
    const { cards } = this.props;

    // Logic for displaying current cards
    const indexOfLastCard = currentPage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentcards = cards.slice(indexOfFirstCard, indexOfLastCard);

    const renderProductCards = currentcards.map((el, index) => {

      var listPage = '/offers';
      var href = `${listPage}/${el.id}`

      return <li key={index}>
        <span className="productimg">
          {el.offerimage === '' ? (
            <img src="images/no_image_available-product.png" alt={el.title} />
          ) : (
            <img src={el.offerimage} alt={el.title} />
          )}
        </span>
        <Link to={href}> {el.title}</Link>
        <br />
      </li>
    });

    const shuffle = arr => [...arr].sort(() => Math.random() - 0.5);

    const renderNewestProductCards = shuffle(currentcards).slice(0, 5).map((el, index) => {

      var listPage = '/offers';
      var href = `${listPage}/${el.id}`

      return <li key={index}>
        <Link to={href}>
          <span className="productstatus">
            {el.title} </span>
          <span className="productimg">
            {el.offerimage === '' ? (
              <img src="images/no_image_available-product.png" alt={el.title} />
            ) : (
              <img src={el.offerimage} alt={el.title} />
            )}
          </span>
        </Link>
        <br />
      </li>
    });

    const renderTradesCards = currentcards.map((el, index) => {
      return <div className="contentblock greyblock  rounded recommendedsellers">
        <div className="container">
          <span className="managecompanyimg">
            <i className="fas fa-check-circle" />
          </span>
          <div className="managecompanydesc">
            <Fragment>
              <div>
                {el.region && <Region {...el.region} />}

                {el.userNameWithAvatar && el.userNameWithAvatar.mini && (
                  <UserNameWithAvatar {...el.userNameWithAvatar} />
                )}
              </div>
              <div className='card__title'>
                {el.userNameWithAvatar && !el.userNameWithAvatar.mini && (
                  <UserNameWithAvatar {...el.userNameWithAvatar} />
                )}
              </div>
            </Fragment>
          </div>
        </div>

      </div>
    });

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(cards.length / cardsPerPage); i++) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map(number => {
      if (number === 1 && currentPage === 1) {
        return (
          <li key={number} className='active' id={number}><a href='#' id={number} onClick={this.handleClick}>{number}</a></li>
        )
      }
      else if ((number < upperPageBound + 1) && number > lowerPageBound) {
        return (
          <li key={number} id={number}><a href='#' id={number} onClick={this.handleClick}>{number}</a></li>
        )
      }
    });
    let pageIncrementBtn = null;
    if (pageNumbers.length > upperPageBound) {
      pageIncrementBtn = <li className=''><a href='#' onClick={this.btnIncrementClick}> &hellip; </a></li>
    }
    let pageDecrementBtn = null;
    if (lowerPageBound >= 1) {
      pageDecrementBtn = <li className=''><a href='#' onClick={this.btnDecrementClick}> &hellip; </a></li>
    }
    let renderPrevBtn = null;
    if (isPrevBtnActive === 'disabled') {
      renderPrevBtn = <li className={isPrevBtnActive}><span id="btnPrev"> Prev </span></li>
    }
    else {
      renderPrevBtn = <li className={isPrevBtnActive}><a href='#' id="btnPrev" onClick={this.btnPrevClick}> Prev </a></li>
    }
    let renderNextBtn = null;
    if (isNextBtnActive === 'disabled') {
      renderNextBtn = <li className={isNextBtnActive}><span id="btnNext"> Next </span></li>
    }
    else {
      renderNextBtn = <li className={isNextBtnActive}><a href='#' id="btnNext" onClick={this.btnNextClick}> Next </a></li>
    }


    return (
      <CustomizedDocumentTitle title={common.MY_DASHBOARD}>
        <div>
          {/*header content */}
          <header>
            <div className="innerheader innerheader-sellandbuy">
              <h1>Buy &amp; Sell</h1>
            </div>
          </header>
          {/*header content */}
          <div className="bggrey">
            <div className="wrap">
              <div className="container-2col">
                <p>A platform to buy from women-owned businesses.<br />
                  Are you a women-owned business? Start selling today!</p><Link to="#" className="btn">Register or login to sell &amp; buy</Link>
              </div>
            </div>
            <div style={{ backgroundColor: '#EFEFEF' }}>
              <div className="wrap">
                <h2>What are you interested in?</h2>
                <div className="container-2even twobigradiobuttons">
                  <span className="customradio">
                    <label htmlFor="m5"><input name="customradio" type="radio" defaultChecked id="m5" defaultValue /><span /><p>Buying products or services<br />
                      <b className="smalltext">See what women-owned businesses are selling</b></p></label>
                  </span>
                  <span className="customradio">
                    <label htmlFor="m6"><input name="customradio" type="radio" id="m6" defaultValue /><span /><p>Selling products or services<br />
                      <b className="smalltext"> See who is buying from women-owned business </b></p></label>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="bgwhite" style={{ paddingTop: 0 }}>
            <div className="wrap">
            <Form
                    onSubmit={this.submit}
                    initialValues={initialValues}
                    getApi={this.setFormApi}
                    className="searchform_full partnersearchv2"
                    render={({ formState: { touched, values } }) => (
                      <Fragment>
                        <button name="searchform_full" value="search" type="submit">
                          <i className="fas fa-search"></i>
                        </button>
                        <input name="searchform_full" 
                          type="text" id="searchform_full"
                          value={queryInput}
                          onChange={this.onQueryInputChange}
                        />

                      </Fragment>
                    )}
                  />
              {/* container left bar  */}
              <input type="checkbox" name="leftbarfilterv2" id="leftbarfilterv2" className="leftbarfilterv2" />
              <label htmlFor="leftbarfilterv2"><i className="fas fa-filter icoleft" /> Filter <i className="fal fa-chevron-down icoright" /><i className="fal fa-times" /></label>
              <div className="container">
                {/* <div>
                  <div className="selectinputsection">
                    <div className="custom-select"><i className="far fa-chevron-down" />
                      <CustomSelect data={this.data1} />
                    </div>
                    <div className="custom-select"><i className="far fa-chevron-down" />
                      <select>
                        <option value={0}>Show only products</option>
                        <option value={1}>Item 1</option>
                        <option value={2}>Item 2</option>
                        <option value={3}>Item 3</option>
                      </select>
                    </div>
                  </div>
                  <div className="slidersection">
                    <h4><i className="far fa-venus" /> Women-owned or led</h4>
                    <div className="slider">
                      <div className="slider-control" style={{ left: '30%' }}>
                        <div className="slider-control-slider" />
                        30%
                      </div>
                    </div>
                  </div>
                  <section>
                    <h4>Sector</h4>
                    <label htmlFor="BeautyWellness">
                      <span className="customcheckbox">
                        <input type="checkbox" name="BeautyWellness" id="BeautyWellness" /><i className="fal fa-check" />
                        <span className="sidebarlabeltextbox">
                          <span className="infoicon" />
                          <p className="sidebarlabeltext">Beauty &amp; Wellness</p>
                          <span className="infoicon" />
                        </span>
                      </span>
                    </label>
                    <label htmlFor="Accessories">
                      <span className="customcheckbox">
                        <input type="checkbox" name="Accessories" id="Accessories" /><i className="fal fa-check" />
                        <span className="sidebarlabeltextbox">
                          <span className="infoicon" />
                          <p className="sidebarlabeltext">Accessories</p>
                          <span className="infoicon" />
                        </span>
                      </span>
                    </label>
                    <label htmlFor="Accomodationandfood">
                      <span className="customcheckbox">
                        <input type="checkbox" name="Accomodationandfood" id="Accomodationandfood" /><i className="fal fa-check" />
                        <span className="sidebarlabeltextbox">
                          <span className="infoicon" />
                          <p className="sidebarlabeltext">Accomodation &amp; food</p>
                          <span className="infoicon" />
                        </span>
                      </span>
                    </label>
                  </section>
                  <section>
                    <h4>Country</h4>
                    <div className="searchsidebar">
                      <button name="searchform_sidebar" value="search" type="submit"><i className="fas fa-search" /></button>
                      <input name="searchform_sidebar" defaultValue required type="text" placeholder="Search…" id="searchform_sidebar" /></div>
                    <label htmlFor="Germany">
                      <span className="customcheckbox">
                        <input type="checkbox" name="Germany" id="Germany" /><i className="fal fa-check" />
                        <span className="sidebarlabeltextbox">
                          <span className="infoicon" />
                          <p className="sidebarlabeltext">Germany</p>
                          <span className="infoicon" />
                        </span>
                      </span>
                    </label>
                    <label htmlFor="SouthAfrica">
                      <span className="customcheckbox">
                        <input type="checkbox" name="SouthAfrica" id="SouthAfrica" /><i className="fal fa-check" />
                        <span className="sidebarlabeltextbox">
                          <span className="infoicon" />
                          <p className="sidebarlabeltext">South Africa</p>
                          <span className="infoicon" />
                        </span>
                      </span>
                    </label>
                    <label htmlFor="Algeria">
                      <span className="customcheckbox">
                        <input type="checkbox" name="Algeria" id="Algeria" /><i className="fal fa-check" />
                        <span className="sidebarlabeltextbox">
                          <span className="infoicon" />
                          <p className="sidebarlabeltext">Algeria</p>
                          <span className="infoicon" />
                        </span>
                      </span>
                    </label>
                    <Link to="#" className="loadmore">Load more</Link>
                  </section>
                  <section>
                    <h4>Show only…</h4>
                    <label htmlFor="Headedbywomen">
                      <span className="customcheckbox">
                        <input type="checkbox" name="Headedbywomen" id="Headedbywomen" /><i className="fal fa-check" />
                        <span className="sidebarlabeltextbox">
                          <span className="infoicon" />
                          <p className="sidebarlabeltext">Headed by women</p>
                          <span className="infoicon"><Link to="#"><i className="fas fa-question-circle" /></Link></span>
                        </span>
                      </span>
                    </label>
                    <label htmlFor="Verifiedsellers">
                      <span className="customcheckbox">
                        <input type="checkbox" name="Verifiedsellers" id="Verifiedsellers" /><i className="fal fa-check" />
                        <span className="sidebarlabeltextbox">
                          <span className="infoicon"><i className="fas fa-check-circle" /></span>
                          <p className="sidebarlabeltext">Verified sellers</p>
                          <span className="infoicon"><Link to="#"><i className="fas fa-question-circle" /></Link></span>
                        </span>
                      </span>
                    </label>
                    <label htmlFor="SupplierDiversity">
                      <span className="customcheckbox">
                        <input type="checkbox" name="SupplierDiversity" id="SupplierDiversity" /><i className="fal fa-check" />
                        <span className="sidebarlabeltextbox">
                          <span className="infoicon" />
                          <p className="sidebarlabeltext">Supplier Diversity Programme</p>
                          <span className="infoicon"><Link to="#"><i className="fas fa-question-circle" /></Link></span>
                        </span>
                      </span>
                    </label>
                    <Link to="#" className="loadmore">Load more</Link>
                  </section>
                  <section>
                    <h4>Certifications &amp; Standards</h4>
                    <div className="searchsidebar">
                      <button name="searchform_sidebar" value="search" type="submit"><i className="fas fa-search" /></button>
                      <input name="searchform_sidebar" defaultValue required type="text" placeholder="Search…" id="searchform_sidebar" /></div>
                    <label htmlFor="ISO345">
                      <span className="customcheckbox">
                        <input type="checkbox" name="ISO345" id="ISO345" /><i className="fal fa-check" />
                        <span className="sidebarlabeltextbox">
                          <span className="infoicon" />
                          <p className="sidebarlabeltext">ISO 345</p>
                          <span className="infoicon" />
                        </span>
                      </span>
                    </label>
                    <label htmlFor="FairTrade">
                      <span className="customcheckbox">
                        <input type="checkbox" name="FairTrade" id="FairTrade" /><i className="fal fa-check" />
                        <span className="sidebarlabeltextbox">
                          <span className="infoicon" />
                          <p className="sidebarlabeltext">FairTrade</p>
                          <span className="infoicon" />
                        </span>
                      </span>
                    </label>
                    <label htmlFor="RainforestAlliance">
                      <span className="customcheckbox">
                        <input type="checkbox" name="RainforestAlliance" id="RainforestAlliance" /><i className="fal fa-check" />
                        <span className="sidebarlabeltextbox">
                          <span className="infoicon" />
                          <p className="sidebarlabeltext">Rainforest Alliance</p>
                          <span className="infoicon" />
                        </span>
                      </span>
                    </label>
                    <Link to="#" className="loadmore">Load more</Link>
                  </section>
                  <section>
                    <h4>Sustainable &amp; inclusive practices</h4>
                    <div className="searchsidebar">
                      <button name="searchform_sidebar" value="search" type="submit"><i className="fas fa-search" /></button>
                      <input name="searchform_sidebar" defaultValue required type="text" placeholder="Search…" id="searchform_sidebar" /></div>
                    <label htmlFor="Benefitwomen">
                      <span className="customcheckbox">
                        <input type="checkbox" name="Benefitwomen" id="Benefitwomen" /><i className="fal fa-check" />
                        <span className="sidebarlabeltextbox">
                          <span className="infoicon" />
                          <p className="sidebarlabeltext">Benefit women and girls</p>
                          <span className="infoicon" />
                        </span>
                      </span>
                    </label>
                    <label htmlFor="womenseconimical">
                      <span className="customcheckbox">
                        <input type="checkbox" name="womenseconimical" id="womenseconimical" /><i className="fal fa-check" />
                        <span className="sidebarlabeltextbox">
                          <span className="infoicon" />
                          <p className="sidebarlabeltext">Women’s economical…</p>
                          <span className="infoicon" />
                        </span>
                      </span>
                    </label>
                    <label htmlFor="Solutions">
                      <span className="customcheckbox">
                        <input type="checkbox" name="Solutions" id="Solutions" /><i className="fal fa-check" />
                        <span className="sidebarlabeltextbox">
                          <span className="infoicon" />
                          <p className="sidebarlabeltext">Solutions to environm…</p>
                          <span className="infoicon" />
                        </span>
                      </span>
                    </label>
                    <Link to="#" className="loadmore">Load more</Link>
                  </section>
                </div> */}
                <div>
                  <h2>Recommended products for you</h2>
                  <ul className="productslist">
                    {renderProductCards}
                  </ul>
                  <ul className="pagination">
                    {renderPrevBtn}
                    {pageDecrementBtn}
                    {renderPageNumbers}
                    {pageIncrementBtn}
                    {renderNextBtn}
                  </ul>
                  <p></p>
                  <h2>Recommended sellers for you</h2>
                  <div className="container-2even">
                    {renderTradesCards}
                    <p className="pagination"><Link to="#" className="prevpage"><i className="fas fa-arrow-circle-left" /></Link> <Link to="#">1</Link> <Link to="#">2</Link> <Link to="#">3</Link> <Link to="#" className="active">4</Link> <Link to="#">5</Link> <Link to="#" className="nextpage"><i className="fas fa-arrow-circle-right" /></Link></p>
                  </div>
                </div>
              </div>
              {/* container left bar  */}
            </div>
          </div>
          {/* Meet the sellers on the platform  */}

          <Fragment>
            <div className="bggrey">
              <div className="wrap">
                <h2 style={{ marginBottom: 5 }}>Meet the sellers on the platform</h2>
                <p>Recommendations based on your preferred sector and country  </p>
                <MemberSuggestion
                  userNameWithAvatarProps={{ addToNetwork: true }}
                  cards={this.props.knownUserList}
                  pageType='knownUser'
                />
              </          div>
            </div>
          </Fragment>

          {/* Meet the sellers on the platform  */}
          {/* Newest products added  */}
          <div className="bgwhite">
            <div className="wrap">
              <h2 style={{ marginBottom: 5 }}>Newest products added</h2>
              <p>Recommendations based on your preferred sector and country</p>
              <ul className="productslist">
              {renderNewestProductCards}
              </ul>
            </div>
            <div className="dotbg l2 dotb" />
          </div>
          {/* Newest products added  */}
        </div>
      </CustomizedDocumentTitle>
    );
  }
}

Page.propTypes = {
  showPreloader: PropTypes.bool,
  fetchSearchResult: PropTypes.func,
  onQueryChange: PropTypes.func,
  onResetClick: PropTypes.func.isRequired,
  onSearchSubmit: PropTypes.func,
};

Page.defaultProps = {
  cards: [],
  errorMessages: {},
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Page));
