import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import Preloader from '../components/Preloader';
import hubsData from '../data/hubs.json';
import languages from '../helpers/languages';

var language = languages['en'];
var common = language.common[0];
var WhySheTrades = language.WhySheTrades[0];

const mapStateToProps = (state) => {
  return {
    lan: state.language.lan,
    user: state.auth.user,
  };
};

class AboutUs extends React.Component {
  componentDidMount() {

  }

  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    WhySheTrades = language.WhySheTrades[0];
    const { showPreloader } = this.props;
    const sliderSettings = {
      dots: true,
      infinite: true,
      speed: 300,
      centerMode: true,
      variableWidth: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1000,
      cssEase: "linear",
    };
    return (
      <CustomizedDocumentTitle title={common.MY_DASHBOARD}>
        <div className='transition-page'>
          {showPreloader ? (
            <Preloader size='medium' relative />
          ) : (
            <div>
              {/*header content */}
              <header>
                <div className="innerheader innerheader-abouttheinitiative">
                  <h1>{WhySheTrades.WHAT_IS_THE_SHETRADES_INITIATIVE}</h1>
                </div>
              </header>
              {/*header content */}
              {/*welcome text */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container-2col">
                    <div>{WhySheTrades.THE_SHETRADES_INITIATIVE_WAS_ESTABL}</div>
                    <div />
                  </div>
                </div>
              </div>
              {/*welcome text */}
              {/*marketplace */}
              <div className="bgwhite">
                <div className="whiteblockbgcircles"><div className="wrap">
                  <div className="container-2col">
                    <div><h2>{WhySheTrades.A_THRIVING_WOMEN_INCLUSIVE_BUSINESS}</h2>
                      <p>{WhySheTrades.EMPOWERING_WOMEN_TO_CONNECT_TO_TRAD}</p>
                      {/*<p><Link to="/discover">{aboutus.DISCOVER_OUR_ENTIRE_OFFERING_} <i className="fal fa-arrow-right" /></Link></p> */}
                    </div>
                    <div />
                  </div>
                </div>
                </div> </div>
              {/*marketplace */}
              {/*mission  */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container-2col">
                    <div><h2>{WhySheTrades.SHETRADES_MISSION}</h2>
                      <p>{WhySheTrades.WE_ARE_WORKING_TO_EMPOWER_WOMEN_OVE}</p>
                    </div>
                    <div />
                  </div>
                </div></div>
              {/*mission  */}
              {/*story   */}
              <div className="bgwhite">
                <div className="bgcirclesstory">
                  <div className="wrap">
                    <div className="container-2col">
                      <div><h2>{WhySheTrades.SHETRADES_STORY}</h2>
                        <p>{WhySheTrades.WOMENS_ECONOMIC_EMPOWERMENT_HAS_REM}. </p>
                        <p>{WhySheTrades.IN_2015_CONSULTATIONS_WITH_GLOBAL_T}</p>
                        {/*<p><Link to="/publicblog">{WhySheTrades.READ_WOMENS_STORIES_IN_OUR_BLOG_} <i className="fal fa-arrow-right" /></Link></p>*/}
                      </div>
                      <div className="storypictures">
                        <img src="images/storypic01.jpg" srcSet="images/storypic01.jpg 1x, images/storypic01@2x.jpg 2x, images/storypic01@3x.jpg 3x, images/storypic01@4x.jpg 4x"alt=""/><img src="images/storypic02.jpg" srcSet="images/storypic02.jpg 1x, images/storypic02@2x.jpg 2x, images/storypic02@3x.jpg 3x, images/storypic02@4x.jpg 4x" alt="" />
                        <img src="images/storypic03.jpg" srcSet="images/storypic03.jpg 1x, images/storypic03@2x.jpg 2x, images/storypic03@3x.jpg 3x, images/storypic03@4x.jpg 4x"alt=""/><img src="images/storypic04.jpg" srcSet="images/storypic04.jpg 1x, images/storypic04@2x.jpg 2x, images/storypic04@3x.jpg 3x, images/storypic04@4x.jpg 4x"alt=""/></div>
                    </div>
                  </div>
                </div>
              </div>
              {/*story   */}
              {/* our team  */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container">
                    <div>
                      <h2>{WhySheTrades.OUR_TEAM}</h2>
                      <p>{WhySheTrades.SHETRADES_IS_POWERED_BY_A_GLOBAL_CO}</p></div>
                  </div>
                </div>
              </div>
              {/* our team  */}
              {/* ST in the world  
              <div className="bgwhite">
                <div className="wrap">
                  <h2 style={{ marginBottom: 20 }}>{WhySheTrades.SHETRADES_IN_THE_WORLD}</h2>
                  <div className="container-2even">
                    <div><h3><i className="fas fa-home icoleft" />{WhySheTrades.Hubs}</h3>
                      <p>{WhySheTrades.SHETRADES_HUBS_ARE}</p>
                      <Link to="/publichubs">{WhySheTrades.READ_MORE} <i className="fal fa-arrow-right" /></Link>
                    </div>
                    <div><h3><i className="fas fa-map-marker icoleft" />{WhySheTrades.Projects}</h3>
                      <p>{WhySheTrades.SHETRADES_IS_PRESENT}.</p>
                      <Link to="/publicprojects">{WhySheTrades.READ_MORE}<i className="fal fa-arrow-right" /></Link>
                    </div>
                  </div>
                  <hr style={{ margin: '30px 0 20px 0' }} />
                  <div className="bgwhite">
                    <div className="wrap">
                    <div className="container-3col">
                        <div className="countrycountbox">
<span class="bred">SheTrades MENA Hub<br /></span>
<span class="bred">SheTrades Kenya Hub<br /></span>
<span class="bred">SheTrades Nigeria Hub<br /></span>
<span class="bred">SheTrades Argentina Hub<br /></span>
                        </div>
                        <div className="countrycountbox">
<span class="bred">SheTrades Rwanda Hub<br /></span>
<span class="bred">SheTrades Trinidad and Tobago Hub<br /></span>
<span class="bred">SheTrades Uruguay Hub<br /></span>
<span class="bred">SheTrades Vietnam Hub<br /></span>
                        </div>
                        <div className="countrycountbox">
<span class="bred">SheTrades South Africa Hub<br /></span>
<span class="bred">SheTrades Philippines Hub<br /></span>
<span class="bred">SheTrades Gambia Hub<br /></span>
<span class="bred">SheTrades Mauritius Hub<br /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ST in the world  */}
              {/* map area  */}
              <div className="bgdark">
                <div className="mapbox mapboxhubspage">
                  <div className="worldmap">
                    <div className="maphomeicon h1"><i className="fas fa-home-alt" /></div>
                    <div className="maphomeicon h2"><i className="fas fa-home-alt" /></div>
                    <div className="maphomeicon h3"><i className="fas fa-home-alt" /></div>
                    <div className="maphomeicon h4"><i className="fas fa-home-alt" /></div>
                    <div className="maphomeicon h5"><i className="fas fa-home-alt" /></div>
                    <div className="maphomeicon h6"><i className="fas fa-home-alt" /></div>
                    <div className="maphomeicon h7"><i className="fas fa-home-alt" /></div>
                    <div className="maphomeicon h8"><i className="fas fa-home-alt" /></div>
                    <div className="maphomeicon h9"><i className="fas fa-home-alt" /></div>
                    <div className="maphomeicon h10"><i className="fas fa-home-alt" /></div>
                    <div className="maphomeicon h11"><i className="fas fa-home-alt" /></div>
                    <div className="maphomeicon h12"><i className="fas fa-home-alt" /></div>
                  </div>
                </div>
              </div>
              {/* map area  */}
              {/* partners and founders  */}
              <div className="wrap" style={{ marginTop: '2%' }}>
                <div className="container-2col">
                  <div><h2>{WhySheTrades.OUR_FUNDERS}</h2>
                    <p>{WhySheTrades.WE_WORK_WITH_VARIOUS}</p></div>
                  <div><Link to="/partners" className="btn">{WhySheTrades.MEET_ALL}</Link></div>
                </div>
              </div>
              <div className="wrap">
              {/* <h2 style={{ margin: '20px 0 10px 0' }}>{WhySheTrades.OUR_FUNDERS}</h2><p>{WhySheTrades.OUR_FUNDERS_HELP_US}.</p> */ }
                <div className="founderscontainer">
                  <div className="partnerlogoimgs">
                    <span><img src="images/partners_and_founders/DFIDlogo.png" alt=""/></span>
                    <span><img src="images/partners_and_founders/KOICALogo.png" alt=""/></span>
                    <span><img src="images/partners_and_founders/EUlogo.png" alt=""/></span>
                    <span><img src="images/partners_and_founders/GIZ-Logo.png" alt=""/></span>
                    <span><img src="images/partners_and_founders/GermanCooperation.png" alt=""/></span>
                    <span><img src="images/partners_and_founders/UPSLogo.png" alt=""/></span>
                    <span><img src="images/partners_and_founders/EIFLogo.png" alt=""/></span>
                    <span><img src="images/partners_and_founders/MaryKay.png" alt=""/></span>
                    <span><img src="images/partners_and_founders/ITFCLogo.png" alt=""/></span>
                    <span><img src="images/partners_and_founders/OPECFundLogo.png" alt=""/></span>
                    <span><img src="images/partners_and_founders/MFASweden.png" alt=""/></span>
                    <span><img src="images/partners_and_founders/MFAFinland.png" alt=""/></span>
                    <span><img src="images/partners_and_founders/MFANetherlandslogo.png" alt=""/></span>
                    <span><img src="images/partners_and_founders/MFAIcelandlogo.png" alt=""/></span>
                    <span><img src="images/partners_and_founders/NorwegianMFA.png" alt=""/></span>
                    <span><img src="images/partners_and_founders/PeopleofJapan.png" alt=""/></span>
                    <span><img src="images/partners_and_founders/Maersk.png" alt=""/></span>
                  </div>
                </div>
              </div>
              {/* partners and founders  */}
            </div>

          )}
        </div>
      </CustomizedDocumentTitle>
    );
  }
}

AboutUs.propTypes = {
  showPreloader: PropTypes.bool,
};

export default connect(mapStateToProps)(AboutUs);
