import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Questionnaire from '../components/Questionnaire';
import Modal from '../components/Modal';
import languages from '../helpers/languages';

var language = languages['en'];
var label = language.report_abuse[0];
var common = language.common[0];
var menu = language.menu[0];
var dashboard = language.dashboard[0];
var main = label.MAIN_INFO;
var sub = label.SUB_INFO;

const mapStateToProps = (state) => ({
  isMobile: state.responsive.isMobile,
  lan: state.language.lan,
});

class ButtonAbuseReport extends Component {
  constructor() {
    super();

    this.state = {
      showModal: false,
    };
    this.submitAbuseReport = this.submitAbuseReport.bind(this);
  }

  toggleModal(showModal) {
    this.setState({
      showModal,
    });
  }

  submitAbuseReport(stepResults) {
    return new Promise((resolve, reject) => setTimeout(() => resolve(), 1000));
  }

  render() {
    language = languages[this.props.lan];
    label = language.report_abuse[0];
    common = language.common[0];
    menu = language.menu[0];
    dashboard = language.dashboard[0];
    main = label.MAIN_INFO;
    sub = label.SUB_INFO;
    const abuseQuestionnaire = [
      {
        id: '1',
        title: dashboard.ABUSE_REPORT,
        questions: [
          {
            id: '1',
            title: dashboard.TELL_US_WHAT_HAPPENED,
            type: 'radio-group',
            options: [
              {
                id: '1',
                name: dashboard.THIS_IS_INSULTING,
              },
              {
                id: '2',
                name: dashboard.MISLEADING_OR_FRAUDLENT,
              },
              {
                id: '3',
                name: dashboard.THIS_IS_SPAM,
              },
              {
                id: '4',
                name: dashboard.OTHER,
              },
            ],
          },
          {
            id: '2',
            title: common.PLEASE_DESCRIBE,
            type: 'text',
          },
        ],
      },
    ];
    const { isMobile, hideOnMobile } = this.props;
    if (isMobile && hideOnMobile) {
      return null;
    }

    const { showModal } = this.state;

    return (
      <Fragment>
        <a
          href='javascript: void null'
          className='button button_minimal'
          onClick={() => this.toggleModal(true)}
        >
          <span className='button__text'>{label.ABUSE_BUTTON}</span>
        </a>

        <Modal
          ariaHideApp={false}
          isOpen={showModal}
          onRequestClose={this.toggleModal.bind(this, false)}
          noBottom
        >
          <div>
            <Questionnaire
              steps={abuseQuestionnaire}
              afterTitleMain={main}
              afterMain={sub}
              submit={this.submitAbuseReport}
              close={this.toggleModal.bind(this, false)}
              //withConfirmStep={false}
              confirmText={common.SEND}
              formMessage='true'
              id={this.props.id}
              disableProgress
            />
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps)(ButtonAbuseReport);
