import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import formatNotificationTime from '../../../../../../helpers/format-notification-time';
import Region from '../../../../../../components/Region';
import UserNameWithAvatar from '../../../../../../components/UserNameWithAvatar';
import { connect } from 'react-redux';
import {
  userAppliedAction,
  fetchMessageTabData,
  saveVerfierResponse,
  saveVerificationResponse,
  saveContactResponse,
  handleCoachingActions,
} from '../../../../../../store/actions';

const mapStateToProps = (state) => ({
  userId: state.auth.user && state.auth.user.id,
  offset: state.notifications.offset,
  t: state.language.t,
});

const mapDispatchToProps = {
  userAppliedAction,
  fetchMessageTabData,
  saveVerfierResponse,
  saveVerificationResponse,
  saveContactResponse,
  handleCoachingActions,
};

class NotificationsFeedItem extends React.Component {
  componentDidMount() {
    //nothing here
  }

  handleUserAppliedAction(id, status, type) {
    //do action for tender offer
    if (type === 'tenders' || type === 'offers') {
      this.props.userAppliedAction(
        id,
        status,
        type,
        window.location.hash,
        this.props.offset
      );
    }
  }

  handleVerfierRequest(userId, status) {
    this.props.saveVerfierResponse(
      userId,
      status,
      window.location.hash,
      this.props.offset
    );
  }

  handleVerificationRequest(requestId, status, type) {
    this.props.saveVerificationResponse(
      requestId,
      status,
      type,
      window.location.hash,
      this.props.offset
    );
  }

  handleContactAction(requestId, status) {
    this.props.saveContactResponse(
      requestId,
      status,
      window.location.hash,
      this.props.offset
    );
  }

  handleMentorAction(requestId, status) {
    this.props.handleCoachingActions(
      requestId,
      status,
      window.location.hash,
      this.props.offset
    );
  }

  render() {
    const {
      id,
      itemId,
      name,
      action,
      relatedItem,
      time,
      tender,
      reply,
      highlighted,
      applierId,
      done,
      t,
    } = this.props;

    const actions = [];

    if (tender && done != true) {
      actions.push({
        title: t.common[0].REJECT,
        border: true,
        event: () =>
          this.handleUserAppliedAction(
            this.props.requestId,
            2,
            this.props.entityType
          ),
      });
      actions.push({
        title: t.common[0].APPROVE,
        event: () =>
          this.handleUserAppliedAction(
            this.props.requestId,
            1,
            this.props.entityType
          ),
      });
    } else {
      switch (itemId) {
        case 'comments':
          actions.push({
            title: t.common[0].REPLY_TO_COMMENT,
            to: '/#/' + this.props.entityType + '/' + this.props.entityId,
          });
          break;

        case 'tenders-and-offers':
          actions.push({
            title: t.common[0].DISCOVER_MORE,
            to: '/#/' + this.props.entityType + '/' + this.props.entityId,
          });
          break;

        case 'verification-requests':
          if (done === false) {
            actions.push({
              title: t.common[0].REJECT,
              border: true,
              event: () =>
                this.handleVerfierRequest(
                  this.props.userNamewithAvatar.dataId,
                  3
                ),
            });
            actions.push({
              title: t.common[0].ACCEPT,
              event: () =>
                this.handleVerfierRequest(
                  this.props.userNamewithAvatar.dataId,
                  2
                ),
            });
          } else {
            actions.push({
              title: t.common[0].GO_TO_VERIFICATION,
              to: '/#/verification',
            });
          }
          break;

        case 'request-verification':
          if (this.props.requestId > 0 && done === false) {
            actions.push({
              title: t.common[0].REJECT,
              border: true,
              event: () =>
                this.handleVerificationRequest(
                  this.props.requestId,
                  3,
                  this.props.requestType
                ),
            });
            actions.push({
              title: t.common[0].ACCEPT,
              event: () =>
                this.handleVerificationRequest(
                  this.props.requestId,
                  2,
                  this.props.requestType
                ),
            });
          }
          break;

        case 'member-request':
          if (this.props.requestId > 0 && done === false) {
            actions.push({
              title: t.common[0].REJECT,
              border: true,
              event: () => this.handleContactAction(this.props.requestId, 2),
            });
            actions.push({
              title: t.common[0].ACCEPT,
              event: () => this.handleContactAction(this.props.requestId, 1),
            });
          }

          if (done === true) {
            actions.push({
              title: t.common[0].GO_TO_MY_NETWORK,
              to: '/#/my-network',
            });
          }
          break;

        case 'be-mentored-requests':
          if (this.props.requestId > 0 && done === false) {
            actions.push({
              title: t.common[0].REJECT,
              border: true,
              event: () => this.handleMentorAction(this.props.requestId, 3),
            });
            actions.push({
              title: t.common[0].ACCEPT,
              event: () => this.handleMentorAction(this.props.requestId, 1),
            });
          }

          if (done === true) {
            actions.push({
              title: t.common[0].GO_TO_MENTORS,
              to: '/#/coaching',
            });
          }
          break;
        default:
         break;
      }
    }

    return (
      <div
        className={classNames(
          'list-animation-item',
          'notifications-feed-item',
          {
            'notifications-feed-item_highlighted': highlighted,
          }
        )}
        key={id}
      >
        <div className='flex flex_align-middle flex_md'>
          <div className='left'>
            <div className='flex flex_spaced flex_align-middle flex_md'>
              <UserNameWithAvatar
                {...this.props.userNamewithAvatar}
                mini={!!tender}
              />

              <p className='gray' style={{ textTransform: 'lowercase' }}>
                {action}
              </p>

              <div className='notifications-feed-item__related-item'>
                {tender ? (
                  <UserNameWithAvatar
                    {...tender.creatorNameWithAvatar}
                    mini={!!tender}
                  />
                ) : (
                  <b>
                    <a
                      href={
                        '/#/' +
                        this.props.entityType +
                        '/' +
                        this.props.entityId
                      }
                    >
                      {relatedItem}
                    </a>
                  </b>
                )}
              </div>
            </div>
          </div>

          <div className='right info'>
            <p className='gray'>{formatNotificationTime(time)}</p>
          </div>
        </div>

        {tender && (
          <div className='notifications-feed-item__tender'>
            <div className='flex flex_align-middle flex_md'>
              <div className='left'>
                <Link
                  to={'/' + this.props.entityType + '/' + tender.id}
                  className='h3'
                >
                  {tender.title}
                </Link>
              </div>

              {tender.hasOwnProperty('value') && (
                <div className='right'>
                  <span className='value'>
                    <span className='value__text'>{t.common[0].VALUE}:</span>
                    <span className='value__number'>{tender.value}</span>
                  </span>
                </div>
              )}
            </div>

            <div className='card__middle'>
              <div className='card__description'>{tender.description}</div>
            </div>
          </div>
        )}

        {reply && (
          <div className='notifications-feed-item__reply'>
            <p className='gray'>{reply}</p>
          </div>
        )}

        <div className='flex flex_md'>
          {tender && <Region {...tender.region} />}

          <div className='right'>
            <div className='buttons buttons_space-2x'>
              {actions.map(({ title, border, event, to }, i) => (
                <a
                  key={i}
                  href={to !== undefined ? to : 'javascript: void null'}
                  onClick={event}
                  className={`button button_150x28${
                    border ? ' ' : ''
                  }`}
                >
                  <span className='button__text'>{title}</span>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NotificationsFeedItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string,
  action: PropTypes.string.isRequired,
  relatedItem: PropTypes.string,
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tender: PropTypes.object,
  reply: PropTypes.string,
  highlighted: PropTypes.bool,
  t: PropTypes.object,
};

NotificationsFeedItem.defaultProps = {
  relatedItem: '',
  tender: null,
  reply: '',
  highlighted: '',
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsFeedItem);
