import {
  COMMITMENTS_LIST,
  COMMITMENTS_QUESTIONS,
  COMMITMENT_SUCCESS,
} from '../store/actions';

const initialState = {
  commitmentList: null, // show preloader if null, show UI if array
  commitmentQuestions: null, // show preloader if null, show UI if array
  saveSucess: false,
};

export default function commitments(state = initialState, action) {
  switch (action.type) {
    case COMMITMENTS_LIST:
      return {
        ...state,
        commitmentList: action.payload.data,
      };
    case COMMITMENTS_QUESTIONS:
      return {
        ...state,
        commitmentQuestions: action.payload.data,
        openedItem: action.payload.selectedItem,
      };
    case COMMITMENT_SUCCESS:
      return {
        ...state,
        saveSucess: true,
      };
    default:
      return state;
  }
}
