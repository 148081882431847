import React from 'react';
import classNames from 'classnames';

const Preloader = ({ size = 'small', relative }) => (
  <div
    className={classNames([
      'preloader',
      `preloader_size-${size}`,
      { preloader_relative: relative },
    ])}
  >
    <div className='dot'></div>
    <div className='dot'></div>
    <div className='dot'></div>
    <div className='dot'></div>
  </div>
);

export default Preloader;
