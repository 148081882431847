/* eslint no-restricted-globals:0 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import lifecycle from 'react-pure-lifecycle';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Page from '../components/Page';
import CardForVideo from '../components/CardForVideo';
import NoDataCard from '../components/Card/NoDataCard';
import Video from '../components/Video';
import {
  fetchVideos,
  fetchVideoDetails,
  fetchVideoComments,
  addVideoComments,
  addSubVideoComment,
} from '../store/actions';
import languages from '../helpers/languages';

var language = languages['en'];
var label = language.video[0];
var common = language.common[0];
var menu = language.menu[0];

const toolbar = (common) => ({
  tabs: {
    values: {},
    filterByKeys: (card) => [card.categoryName],
  },
  sort: [
    { orderBy: 'date', order: 'DESC', label: common.RECENT },
    { orderBy: 'date', order: 'ASC', label: common.OLDEST },
  ],
});

const mapStateToProps = (state) => {
  return {
    cards: state.videos.videos,
    totalItems: state.videos.totalItems,
    sortLabel: state.videos.sortLabel,
    videoDetails: state.videos.videoDetails,
    reviews: state.videos.videoComments,
    totalReviews: state.videos.commentCount,
    userRole: state.auth.user.userRole,
    lan: state.language.lan,
  };
};

const mapDispatchToProps = {
  fetchVideos,
  fetchVideoDetails,
  fetchVideoComments,
  addVideoComments,
  addSubVideoComment,
};

const methods = {
  componentDidMount(props) {
    props.fetchVideos(window.location.hash);
    var queryParam = window.location.hash.split('/');
    if (queryParam[1] && queryParam[2]) {
      props.fetchVideoDetails(window.location.hash);
      props.fetchVideoComments(window.location.hash);
    }
  },
};

const PageVideo = (props) => {
  language = languages[props.lan];
  label = language.video[0];
  common = language.common[0];
  menu = language.menu[0];
  var roleFlag = false;

  const { location, cards, totalItems, videoDetails, reviews } = props;
  const queryObject = queryString.parse(location.search);

  return (
    <Page
      name={queryObject.tab !== 'webinar' ? menu.VIDEO : menu.WEBINAR}
      singleName={menu.VIDEO}
      toolbar={toolbar(common)}
      cards={cards}
      fetchFunction={props.fetchVideos}
      totalItems={totalItems}
      sortLabel={props.sortLabel}
      detail={videoDetails}
      hasLeftPanel={false}
      canCreateNew={roleFlag}
      renderAfterTitle={() => (
        <div className='wrap'>
          <div className='container'>
            <p className='gray'>{label.INFO}</p>
          </div>
        </div>
      )}
      customBackUrl={
        videoDetails.categoryName !== 'Webinar'
          ? '/video?tab=workshop'
          : '/webinar?tab=webinar'
      }
      {...props}
      // eslint-disable-next-line no-shadow
      renderCards={(cards, filter, page, cardsPerPage) => {
        const isCurrentTabNotDefault =
          'tab' in queryObject && queryObject.tab !== 'webinar';
        const filteredCards = cards
          .filter(filter)
          .filter((el, i) => isCurrentTabNotDefault || i > 0)
          .slice(page * cardsPerPage, (page + 1) * cardsPerPage);

        return (
          <Fragment>
            <div className='odd-items'>
              {cards.map((el, i) => (
                <CardForVideo
                  key={i}
                  topInOneLine
                  forVideo
                  index={cards.length + i}
                  {...el}
                />
              ))}
              {cards.length === 0 && <NoDataCard />}
            </div>
          </Fragment>
        );
      }}
      cardsPerPage={9}
      renderAfterPagination={() => (
      <p></p>
        /*       <section
          className='promo-section'
          style={{ backgroundImage: 'url("../images/promo--webex.jpg")' }}
        >
          <div className='content'>
            <h2>{common.WEBEX_WEBINAR}</h2>

            <p>{common.WEBINAR_INFO}</p>
          </div>

          {queryObject.tab !== 'webinar' ? (
            <a
              target='_blank' rel="noreferrer"
              href='https://www.shetrades.com/en/learn/webinar'
              className='button button_border button_gray'
            >
              <i className='icon-arrow--small' />
              <span className='button__text'>{common.LEARN_MORE}</span>
            </a>
          ) : (
            ''
          )}

          <div className='app-store-google-play'>
            <a
              target='_blank' rel="noreferrer"
              href='https://itunes.apple.com/us/app/shetrades/id1209051557?mt=8'
            >
              <img src='../images/AS.png' alt='' />
            </a>

            <a target='_blank' rel="noreferrer" href='https://goo.gl/DGj9jw'>
              <img src='../images/GP.png' alt='' />
            </a>
          </div>
        </section> */
      )}
      renderDetail={(
        match // eslint-disable-line no-unused-vars
      ) => (
        <Video
          {...videoDetails}
          comments={reviews}
          totalReviews={props.totalReviews}
          fetchReviewsFunction={props.fetchVideoComments}
          addReviewFunction={props.addVideoComments}
          addSubComment={props.addSubVideoComment}
        />
      )}
    />
  );
};

PageVideo.propTypes = {
  location: PropTypes.object.isRequired,
};

PageVideo.defaultProps = {
  cards: [],
};

const PageVideoCycle = lifecycle(methods)(PageVideo);
const List = connect(mapStateToProps, mapDispatchToProps)(PageVideoCycle);

export default List;
