import React from 'react';
import { connect } from 'react-redux';

import { removeFlashMessage } from '../actions/flash-messages';

class Display extends React.Component {
  render() {
    return this.props.flashMessages.map((message) => {
      const iconClass = iconClasses[message.type];
      const color = colors[message.type];
      return (
        <div
          className='top-message'
          style={{ backgroundColor: color }}
          key={message.id}
        >
          <i className={iconClass} />

          <p className='top-message__text'>{message.text}</p>

          <button
            className='top-message__close'
            onClick={this.props.removeFlashMessage.bind(null, message.id)}
          >
            <i className='icon-close--big' />
          </button>
        </div>
      );
    });
  }
}

const mapStateToProps = (state) => ({ flashMessages: state.flashMessages });

const mapDispatchToProps = {
  removeFlashMessage,
};

const iconClasses = {
  success: 'icon-check--circle',
  warning: 'icon-message--warning',
  error: 'icon-message--error',
};

const colors = {
  success: '#4BBD77',
  warning: '#FFCA81',
  error: '#FF938B',
};

export default connect(mapStateToProps, mapDispatchToProps)(Display);
