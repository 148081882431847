/* eslint no-restricted-globals:0 */
import React, { Fragment } from 'react';
import { Form } from 'informed';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Input from '../components/settings/Input';
import Page from '../components/Page';
import Detail from '../components/Detail';
import ButtonBookmark from '../components/ButtonBookmark';
import FilesInput from '../components/settings/Input/components/FilesInput';
import MemberSuggestion from '../components/Suggestion';
import offerValidation from '../validations/validationOffers';
import {
  fetchOffers,
  fetchOfferDetails,
  applyOffer,
  addBookmark,
  searchProducts,
  searchCountries,
  doOfferErrors,
  setOfferPreview,
  createOffer,
  toggleApplySuccessMessage,
  toggleDetailApply,
  onCloseButtonClickOffer,
  setOffersErrorFlag,
  knownUser,
} from '../store/actions';
import KeyValueInput from '../components/settings/Input/components/KeyValueInput';
import {
  PRODUCT_ID,
  SERVICE_ID,
  COUNTRY,
} from '../components/Search/TopFilters/types';
import {
  ROUTE_FETCH_PRODUCTS,
  ROUTE_FETCH_SERVICES,
} from '../components/settings/DropdownTree/fetchDropdownTrees';
import highlightFirstError from '../helpers/highlight-first-error';
import languages from '../helpers/languages';
import PreventEmptyDetail from '../components/PreventEmptyDetail';


var language = languages['en'];
var label = language.offers[0];
var create = language.createOffer[0];
var common = language.common[0];
var menu = language.menu[0];
var buySelllbl = language.BuySell[0];

const toolbar = (common) => ({
  light: true,
  sort: [
    { orderBy: 'date', order: 'ASC', label: common.OLDEST },
    { orderBy: 'date', order: 'DESC', label: common.RECENT },
  ],
});

const mapStateToProps = (state) => {
  return {
    search: state.search,
    cards: state.offers.offers,
    totalItems: state.offers.totalItems,
    sortLabel: state.offers.sortLabel,
    detail: state.offers.offer,
    detailBookmark: state.offers.offerBookmark,
    detailApplied: state.offers.offerApplied,
    productOptions: state.defaultLoad.productOptions,
    countryOptions: state.defaultLoad.countryOptions,
    hasError: state.offers.formHasError,
    errorMessages: state.offers.errorMessages,
    formHasError: state.offers.formHasError,
    mainErrorMessage: state.offers.mainErrorMessage,
    detailPreview: state.offers.detailPreview,
    isApplySuccessShown: state.offers.isApplySuccessShown,
    isDetailApplyShown: state.offers.isDetailApplyShown,
    closeOffers: state.offers.closeOffer,
    knownUserList: state.network.knownUser,
    lan: state.language.lan,
  };
};

const mapDispatchToProps = {
  fetchOffers,
  fetchOfferDetails,
  applyOffer,
  addBookmark,
  searchProducts,
  searchCountries,
  doOfferErrors,
  setOfferPreview,
  createOffer,
  toggleApplySuccessMessage,
  toggleDetailApply,
  onCloseButtonClickOffer,
  setOffersErrorFlag,
  knownUser,
};

class PageOffers extends React.Component {
  // Attachments and photos can be reused with tenders by creating a higher order component.
  // For simplicity and freedom of next actions, I leave it like this.
  constructor() {
    super();
    this.state = {
      attachments: [],
      photos: [],
      type: false,
    };

    this.typeSelect = this.typeSelect.bind(this);
  }

  componentDidMount() {
    //initial fetch
    this.props.fetchOffers(window.location.hash, this.props.search);
    this.props.knownUser();
    //details
    var queryParam = this.props.location.pathname.split('/');
    if (queryParam[1] && queryParam[2] && !isNaN(queryParam[2]))
      this.props.fetchOfferDetails(queryParam[2]);
  }

  onAttachmentsChange(attachments) {
    this.setState({
      ...this.state,
      attachments,
    });
  }

  typeSelect(value) {
    this.setState({
      ...this.state,
      type: value,
    });
  }

  toggleTopMessage(props, closeClick) {
    this.props.onCloseButtonClickOffer(false);
  }

  onPhotosChange(photos) {
    this.setState({
      ...this.state,
      photos,
    });
  }

  beforeNextStep(currentStep, multiStepForm) {
    var stepSplit = window.location.hash.split('=');
    var step = stepSplit[1];

    if (step === 3) {
      return true;
    }

    var formStates = [];
    if (!multiStepForm.currentForm) return true;

    const { key, formApi } = multiStepForm.currentForm;
    formStates[key] = formApi.getState();
    var response = offerValidation(formStates, key, language);
    this.props.doOfferErrors('CheckError', response);
    if (response.hasError === false && key === 2) {
      var tab1 = multiStepForm.formStates[1]['values'];
      var tab2 = formStates[2]['values'];
      var attachments = this.state.hasOwnProperty('attachments')
        ? this.state.attachments
        : {};
      var photos = this.state.hasOwnProperty('photos') ? this.state.photos : {};
      this.props.setOfferPreview(
        tab1,
        tab2,
        attachments,
        photos,
        this.props.countryOptions,
        this.props.user
      );
    }

    if (response.hasError) {
      highlightFirstError(response.errors);
      return false;
    } else {
      if (currentStep === 2) {
        multiStepForm.setCurrentForm(null);
      }
      return true;
    }
  }

  async onFinish(multiStepForm) {
    await multiStepForm.onFinish();

    //request to save
    var data = multiStepForm.formStates;
    var attachments = this.state.hasOwnProperty('attachments')
      ? this.state.attachments
      : {};
    var photos = this.state.hasOwnProperty('photos') ? this.state.photos : {};

    this.props.createOffer(data, attachments, photos);
  }

  getCountryName(countries, $id) {
    for (var item in countries) {
      if (countries[item]['value'] === countries[item]['label']) {
        return countries[item]['label'];
      }
    }

    return '';
  }

  render() {
    language = languages[this.props.lan];
    label = language.offers[0];
    create = language.createOffer[0];
    common = language.common[0];
    menu = language.menu[0];
    buySelllbl = language.BuySell[0];

    const { totalItems, detail, location, match } = this.props;
    const { attachments, photos } = this.state;

    var roleFlag = false;

    return (
      <Page
        name={menu.OFFERS}
        singleName={label.OFFER}
        topfilters={[PRODUCT_ID, SERVICE_ID, COUNTRY]}
        toolbar={toolbar(common)}
        canCreateNew={roleFlag}
        fetchFunction={this.props.fetchOffers}
        search={this.props.search}
        totalItems={totalItems}
        pageName={'Offers'}
        headerClassName= {'innerheader-sellandbuy'}
        renderAfterTitle={() => (
          <div className="bggrey">
            <div className="wrap">
              <div className="container-2col">
                <p>{buySelllbl.A_PLATFORM_TO_BUY_FROM_WOMEN_OWNED_BUSINESS}
                  <br />
                  {buySelllbl.ARE_YOU_A_WOMEN_OWNED_BUSINESS_START_SELLIN}</p>
                <Link to="/sign-up" className="btn">{buySelllbl.REGISTER_OR_LOGIN_TO_SELL_BUY}</Link>
              </div>
            </div>
            <div style={{ backgroundColor: '#EFEFEF' }}>
              <div className="wrap">
                <h2>{buySelllbl.WHAT_ARE_YOU_INTERESTED_IN}</h2>
                <div className="container-2even twobigradiobuttons">
                  <span className="customradio">
                    <input name="customradio" type="radio" id="m5" value="buy" defaultChecked />
                    <span /><label htmlFor="m5">{buySelllbl.BUYING_PRODUCTS_OR_SERVICES}<br />
                      <b className="smalltext">{buySelllbl.SEE_WHAT_WOMEN_OWNED_BUSINESSES_ARE_SELLING}</b></label>
                  </span>
                  <span className="customradio">
                    <input name="customradio" type="radio" id="m6" value="sell" /><span />
                    <label htmlFor="m6">{buySelllbl.SELLING_PRODUCTS_OR_SERVICES}<br />
                      <b className="smalltext">{buySelllbl.SEE_WHO_IS_BUYING_FROM_WOMEN_OWNED_BUSINESS}</b>
                    </label>
                  </span>
                </div>
              </div>
            </div>
          </div>

        )}
        sortLabel={this.props.sortLabel}
        addBookmark={this.props.addBookmark}
        cardsPerPage={12}
        cards={
          this.props.cards.map((el) => ({
            ...el,
            showId: true,
            hasValueAndDates: true,
            renderAdditionalButtons(id, bookmarked) {
              return (
                <ButtonBookmark id={id} bookmarked={bookmarked} type={'offers'} />
              );
            },
          }))}
        location={location}
        match={match}
        renderRight={() => (
          <Fragment>
            <div className="bggrey">
              <div className="wrap">

                <h2 style={{ marginbottom: '5px' }}> {buySelllbl.MEET_THE_SELLERS_ON_THE_PLATFORM}</h2>
                <p>{buySelllbl.RECOMMENDATIONS_BASED_ON_YOUR_PREFERRED_SECTOR_AND_COUNTRY} </p>
                <MemberSuggestion
                  userNameWithAvatarProps={{ addToNetwork: true }}
                  cards={this.props.knownUserList}
                  pageType='knownUser'
                />
              </div>
            </div>
          </Fragment>
        )}
        renderDetail={(match) => {
          return (
            <PreventEmptyDetail detail={detail}>
              <Detail
                singleName={label.OFFER}
                {...detail}
                loggedInUser={this.props.user.id}
                search={this.props.search}
                previousUrl={window.location.hash}
                detailApplied={this.props.detailApplied}
                detailBookmarked={this.props.detailBookmark}
                applyFunction={this.props.applyOffer}
                addBookmark={this.props.addBookmark}
                isApplySuccessShown={this.props.isApplySuccessShown}
                onSuccessMessageClose={() =>
                  this.props.toggleApplySuccessMessage(false)
                }
                isDetailApplyShown={this.props.isDetailApplyShown}
                onDetailApplyToggle={this.props.toggleDetailApply}
                bookmarkedFlag={'notPreview'}
              />
            </PreventEmptyDetail>
          );
        }}
        createNewProps={{
          onFinish: this.onFinish.bind(this),
          beforeNext: this.beforeNextStep.bind(this),
          renderStep: (step, multiStepForm) => {
            switch (step) {
              case 1:
                return (
                  <Form
                    getApi={(formApi) =>
                      multiStepForm.setCurrentForm({ key: 1, formApi })
                    }
                    render={() => (
                      <Fragment>
                        <div className='inputs-group'>
                          <div className='inputs'>
                            <Input
                              id='subjectType'
                              label={create.FILL_MESSAGE}
                              required
                              type='tab-group'
                              hasError={
                                this.props.errorMessages.hasOwnProperty(
                                  'subjectType'
                                ) && this.props.errorMessages.subjectType
                                  ? true
                                  : false
                              }
                              errors={
                                this.props.errorMessages.hasOwnProperty(
                                  'subjectType'
                                ) && this.props.errorMessages.subjectType
                                  ? this.props.errorMessages.subjectType[0]
                                  : ''
                              }
                              onChange={this.typeSelect}
                              options={[
                                {
                                  value: 'product',
                                  label: common.PRODUCT,
                                },
                                {
                                  value: 'service',
                                  label: common.SERVICE,
                                },
                              ]}
                            />

                            {this.state.type === 'product' && (
                              <Input
                                id='product'
                                label={common.PRODUCTS}
                                placeholder={
                                  common.CATEGORY_NAME_NUMBER_PLACEHOLDER
                                }
                                type='dropdown-tree'
                                hasError={
                                  this.props.errorMessages.hasOwnProperty(
                                    'product'
                                  ) && this.props.errorMessages.product
                                    ? true
                                    : false
                                }
                                errors={
                                  this.props.errorMessages.hasOwnProperty(
                                    'product'
                                  ) && this.props.errorMessages.product
                                    ? this.props.errorMessages.product[0]
                                    : ''
                                }
                                required
                                dropdownTreeOptions={{
                                  route: ROUTE_FETCH_PRODUCTS,
                                  position: 'bottom',
                                }}
                              />
                            )}

                            {this.state.type === 'service' && (
                              <Input
                                id='service'
                                label={common.SERVICES}
                                placeholder={
                                  common.CATEGORY_NAME_NUMBER_PLACEHOLDER
                                }
                                type='dropdown-tree'
                                hasError={
                                  this.props.errorMessages.hasOwnProperty(
                                    'service'
                                  ) && this.props.errorMessages.service
                                    ? true
                                    : false
                                }
                                errors={
                                  this.props.errorMessages.hasOwnProperty(
                                    'service'
                                  ) && this.props.errorMessages.service
                                    ? this.props.errorMessages.service[0]
                                    : ''
                                }
                                required
                                dropdownTreeOptions={{
                                  route: ROUTE_FETCH_SERVICES,
                                  position: 'bottom',
                                }}
                              />
                            )}

                            <Input
                              id='title'
                              label={common.TITLE}
                              required
                              hasError={
                                this.props.errorMessages.hasOwnProperty(
                                  'title'
                                ) && this.props.errorMessages.title
                                  ? true
                                  : false
                              }
                              errors={
                                this.props.errorMessages.hasOwnProperty(
                                  'title'
                                ) && this.props.errorMessages.title
                                  ? this.props.errorMessages.title[0]
                                  : ''
                              }
                              placeholder={create.TITLE_PLACEHOLDER}
                            />

                            <Input
                              id='location'
                              label={common.CHOOSE_LOCATION}
                              required={true}
                              placeholder={common.LOCATION_PLACEHOLDER}
                              type='select'
                              options={this.props.countryOptions}
                              selectProps={{
                                searchable: true,
                              }}
                              hasError={
                                this.props.errorMessages.hasOwnProperty(
                                  'location'
                                ) && this.props.errorMessages.location
                                  ? true
                                  : false
                              }
                              errors={
                                this.props.errorMessages.hasOwnProperty(
                                  'location'
                                ) && this.props.errorMessages.location
                                  ? this.props.errorMessages.location[0]
                                  : ''
                              }
                            />

                            <Input
                              id='city'
                              label={common.CITY}
                              required
                              hasError={
                                this.props.errorMessages.hasOwnProperty(
                                  'city'
                                ) && this.props.errorMessages.city
                                  ? true
                                  : false
                              }
                              errors={
                                this.props.errorMessages.hasOwnProperty(
                                  'city'
                                ) && this.props.errorMessages.city
                                  ? this.props.errorMessages.city[0]
                                  : ''
                              }
                              placeholder={common.CITY_PLACEHOLDER}
                            />
                          </div>
                        </div>

                        <div className='inputs-group'>
                          <div className='inputs-group__title'>
                            <p className='gray'>{create.OFFER_FEATURES}</p>
                          </div>

                          <div className='inputs'>
                            <KeyValueInput
                              field='features'
                              keys={[
                                {
                                  value: 'dimensions',
                                  label: create.PRODUCT_DIMENSIONS,
                                },
                                {
                                  value: 'weight',
                                  label: create.WEIGHT,
                                },
                                {
                                  value: 'year',
                                  label: create.YEAR_OF_MANUFACTURE,
                                },
                              ]}
                            />
                          </div>
                        </div>
                      </Fragment>
                    )}
                  />
                );

              case 2:
                return (
                  <Form
                    getApi={(formApi) =>
                      multiStepForm.setCurrentForm({ key: 2, formApi })
                    }
                    render={({ formApi }) => (
                      <Fragment>
                        <div className='inputs'>
                          <Input
                            id='description'
                            label={common.DESCRIPTION}
                            required
                            type='textarea'
                            placeholder={create.DESCRIPTION_PLACEHOLDER}
                            hasError={
                              this.props.errorMessages.hasOwnProperty(
                                'description'
                              ) && this.props.errorMessages.description
                                ? true
                                : false
                            }
                            errors={
                              this.props.errorMessages.hasOwnProperty(
                                'description'
                              ) && this.props.errorMessages.description
                                ? this.props.errorMessages.description[0]
                                : ''
                            }
                            renderBottom={() => (
                              <FilesInput
                                field='attachments'
                                onChange={this.onAttachmentsChange.bind(this)}
                                value={attachments}
                                inputAttrs={{ multiple: true }}
                              />
                            )}
                          />

                          <Input
                            id='conditions'
                            label={common.CONDITIONS}
                            placeholder={common.CONDITIONS_PLACEHOLDER}
                            array
                            formApi={formApi}
                          />

                          <Input
                            id='photos'
                            label={common.PHOTOS}
                            type='images'
                            value={photos}
                            onChange={this.onPhotosChange.bind(this)}
                          />

                          <Input
                            id='allMembers'
                            label={create.ALL_MEMBER}
                            required
                            hasError={
                              this.props.errorMessages.hasOwnProperty(
                                'allMembers'
                              ) && this.props.errorMessages.allMembers
                                ? true
                                : false
                            }
                            errors={
                              this.props.errorMessages.hasOwnProperty(
                                'allMembers'
                              ) && this.props.errorMessages.allMembers
                                ? this.props.errorMessages.allMembers[0]
                                : ''
                            }
                            type='radio-group'
                            options={[
                              { value: 1, label: common.ALL_MEMBERS },
                              {
                                value: 0,
                                label: common.ONLY_VERIFIED_USERS,
                              },
                            ]}
                          />
                        </div>
                      </Fragment>
                    )}
                  />
                );

              case 3:
                return (
                  <div className='create-new-preview'>
                    {this.props.detailPreview.title !== undefined && (
                      <Detail isPreview {...this.props.detailPreview} />
                    )}
                  </div>
                );

              default:
                return null;
            }
          },
        }}
      />
    );
  }
}

PageOffers.defaultProps = {
  cards: [],
  errorMessages: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(PageOffers);
