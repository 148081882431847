import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ButtonBookmark from '../components/ButtonBookmark';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import Region from '../components/Region';
import Tag from '../components/Tag';
import formatDate from '../helpers/format-date';

const DATE_FORMAT = 'DD MMM. YYYY';

const CardForNews = (props) => {
  const {
    forBigNews,
    tags,
    region,
    date,
    start,
    start_end,
    end,
    title,
    id,
    bookmarked,
    description,
    topInOneLine,
    image,
    bigImage,
    parentLocation,
    location,
    queryParent,
    queryMode,
    listType,
    addBookmark,
    previousUrl,
    detailBookmark,
  } = props;
  const itemName = encodeURIComponent(id)
    .replace(/%20/g, '+')
    .replace(/%/g, '');

  const href = queryMode
    ? `${parentLocation || location.pathname}/{ item: itemName }}`
    : `${parentLocation || location.pathname}/${itemName}`;
  let viewDate;

  if (start && end) {
    viewDate = formatDate(start, end, listType);
  } else {
    viewDate = moment(date).format(DATE_FORMAT);
  }

  var isPast = moment().isAfter(props.end);

  let url;
  url = href;
  if (listType === 'events' ||  listType === 'publicevents' 
  || listType === 'news' ||  listType === 'news' 
  || listType === 'webinars' ||  listType === 'publicwebinars' ) 
  {
    url = `/${listType}/${id}`;
  }

  const hasBookmark = listType === 'events' && isPast === false;

  return (
    <section
    
      className={classNames([
        'card',
        'card_for-news',
        'list-animation-item',
        'card_for-big-news',
        {
          // 'card_for-big-news': forBigNews,
          // 'card_for-small-news': !forBigNews,
          'card_for-small-news-bookmark': hasBookmark,
          'card_top-in-one-line': topInOneLine,
        },
      ])}
    >
      <Link to={url} className='card__image'>
        <img src={forBigNews ? bigImage : image} alt={title} />
      </Link>

      <div className='card__top'>
        {tags.map((tag, i) => (
          <Tag {...tag} key={i} />
        ))}

        <span className='info'>
          <i className='icon-calendar' />
          <span className='info__text'>{viewDate}</span>
        </span>

        {region && props.region.name.length > 5 && <Region {...region} />}
      </div>

      {title && (
        <div className='card__title'>
          <Link to={url}>{title}</Link>
        </div>
      )}

      <div className='flex'>
        <div className='card__middle'>
          {description && (
            <div className='card__description'>{description}</div>
          )}
          {hasBookmark && (
            <ButtonBookmark
              id={id}
              bookmarked={props.bookmarked}
              type={listType}
            />
          )}
        </div>
      </div>
    </section>
  );
};

CardForNews.propTypes = {
  tags: PropTypes.array,
  forBigNews: PropTypes.bool,
  topInOneLine: PropTypes.bool,
  region: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  start_end: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  start: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  end: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  bigImage: PropTypes.string,
  parentLocation: PropTypes.object,
  location: PropTypes.object,
  queryMode: PropTypes.bool,
  queryParent: PropTypes.string,
  listType: PropTypes.string,
  addBookmark: PropTypes.func,
  previousUrl: PropTypes.string,
  detailBookmark: PropTypes.bool,
};

CardForNews.defaultProps = {
  tags: [],
  forBigNews: false,
  topInOneLine: false,
  region: null,
  date: '',
  start: '',
  end: '',
  title: '',
  id: '',
  start_end: '',
  description: '',
  image: '',
  bigImage: '',
  parentLocation: null,
  location: null,
  queryMode: false,
  queryParent: '',
  listType: '',
  addBookmark: null,
  previousUrl: '',
  detailBookmark: false,
};

export default withRouter(CardForNews);
