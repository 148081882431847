import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Region from '../../../components/Region';

const BottomText = (props) => {
  const { isLoading, region, type, employees, femaleEmployees, t } = props;

  const viewRegion = isLoading ? { name: <span>&nbsp;</span> } : region;
  return (
    <div className='member-bottom-text'>
      <p className='info'>
        {!isLoading ? (
          <Fragment>
            <i className='icon-user' />

            {type === 'individual' ? (
              <span className='info__text'>{t.common[0].INDIVIDUAL}</span>
            ) : (
              <span className='info__text'>
                {employees} {t.common[0].EMPLOYEES}{' '}
                {!!femaleEmployees && (
                  <span style={{ display: 'inline-block' }}>
                    {femaleEmployees > 0
                      ? '(' +
                        femaleEmployees +
                        `% ${t.header[0].OWNED_BY_WOMEN})`
                      : ''}
                  </span>
                )}
              </span>
            )}
          </Fragment>
        ) : (
          <span>&nbsp;</span>
        )}
      </p>

      <Region {...viewRegion} />
    </div>
  );
};

BottomText.propTypes = {
  femaleEmployees: PropTypes.number,
  isLoading: PropTypes.bool,
  region: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  type: PropTypes.string.isRequired,
  employees: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]).isRequired,
  t: PropTypes.object,
};

BottomText.defaultProps = {
  femaleEmployees: 0,
  isLoading: false,
};

const mapStateToProps = (state) => ({ t: state.language.t });

export default connect(mapStateToProps)(BottomText);
