import React from 'react';
import lifecycle from 'react-pure-lifecycle';
import { connect } from 'react-redux';

import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import Preloader from '../components/Preloader';
import { fetchStaticPages } from '../store/actions';
import languages from '../helpers/languages';

var language = languages['en'];
var common = language.common[0];
var menu = language.menu[0];

const mapStateToProps = (state) => {
  return { policy: state.staticPages.staticData, lan: state.language.lan };
};

const mapDispatchToProps = {
  fetchStaticPages,
};

const methods = {
  componentDidMount(props) {
    props.fetchStaticPages();
  },
};

const PageCookies = (props) => {
  language = languages[props.lan];
  common = language.common[0];
  menu = language.menu[0];

  const isLoading = props.policy === '' || props.policy === 'undefined';

  return (
    <CustomizedDocumentTitle title={menu.COOKIES}>
      <div className='transition-page'>
        {isLoading ? (
          <Preloader />
        ) : (
          <div className='detail detail_big detail_symmetric'>
            <div className='detail__whole'>
              <div className='content content_p-margins'>
                <h1 className='h2'>{props.policy.title}</h1>
                <div
                  className='content content_p-margins'
                  dangerouslySetInnerHTML={{ __html: props.policy.description }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </CustomizedDocumentTitle>
  );
};
const policylifeCycle = lifecycle(methods)(PageCookies);
const List = connect(mapStateToProps, mapDispatchToProps)(policylifeCycle);
export default List;
//export default PagePrivacyPolicy;
