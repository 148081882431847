import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Form } from 'informed';
import PropTypes from 'prop-types';
import ButtonBookmark from '../../components/ButtonBookmark';
import ButtonAbuseReport from '../../components/ButtonAbuseReport';
import Page from '../../components/Page';
import Forum from '../../components/Forum';
import Input from '../../components/settings/Input';
import MultiInputField from '../../components/settings/MultiInput/components/Field';
import forumValidation from '../../validations/validationForum';
import {
  forumErrors,
  fetchTagList,
  fetchForumTopics,
  fetchForumDetails,
  fetchAllAnswers,
  formTags,
  createForumTopic,
  addBookmark,
  setForumErrorFlag,
} from '../../store/actions';
import highlightFirstError from '../../helpers/highlight-first-error';
import BeforeToolbar from './components/BeforeToolbar';
import languages from '../../helpers/languages';
import PreventEmptyDetail from '../../components/PreventEmptyDetail';

var language = languages['en'];
var label = language.forum[0];
var common = language.common[0];
var menu = language.menu[0];
var createForum = language.create_forum[0];

const mapStateToProps = (state) => ({
  sortLabel: state.forum.sortLabel,
  totalItems: state.forum.totalListItems,
  cards: state.forum.forumTopics,
  detail: state.forum.forumDetails,
  answers: state.forum.forumAnswers,
  detailBookmark: state.forum.forumBookmark,
  totalAnswers: state.forum.totalAnswers,
  pageUrl: state.forum.pageUrl,
  pageTitle: state.forum.pageTitle,
  formTagOptions: state.forum.formTagOptions,
  hasError: state.forum.formHasError,
  errorMessages: state.forum.errorMessages,
  formHasError: state.forum.formHasError,
  mainErrorMessage: state.forum.mainErrorMessage,
  showedAnswers: state.forum.showedAnswers,
  isMobile: state.responsive.isMobile,
  userRole: state.auth.user.userRole,
  lan: state.language.lan,
});

const mapDispatchToProps = {
  fetchTagList,
  fetchForumTopics,
  fetchForumDetails,
  fetchAllAnswers,
  formTags,
  forumValidation,
  forumErrors,
  createForumTopic,
  addBookmark,
  setForumErrorFlag,
};

class PageForums extends React.Component {
  componentDidMount(props) {
    this.props.setForumErrorFlag(false);
    var queryParam = window.location.hash.split('/');
    if (queryParam[2]) {
      if (queryParam[2] === 'create') {
        this.props.formTags();
      } else {
        this.props.fetchForumDetails(queryParam[2]);
        this.props.fetchAllAnswers(queryParam[2], 0);
      }
    } else {
      this.props.fetchTagList();
      this.props.fetchForumTopics(window.location.hash);
    }
  }

  beforeNext(currentStep, multiStepForm) {
    var formStates = [];
    if (multiStepForm.currentForm) {
      const { key, formApi } = multiStepForm.currentForm;
      formStates[key] = formApi.getState();
      var response = forumValidation(formStates, key, language);
      if (response.hasError === true) {
        this.props.forumErrors('error', response);
      }
      if (response.hasError) {
        highlightFirstError(response.errors);
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

  async onFinish(multiStepForm) {
    await multiStepForm.onFinish();
    var data = multiStepForm.formStates;
    this.props.createForumTopic(data[1]['values']);
  }

  render() {
    var roleFlag = false;
    if (
      this.props.userRole === 'member' ||
      typeof this.props.userRole === 'undefined'
    ) {
      roleFlag = true;
    }
    language = languages[this.props.lan];
    label = language.forum[0];
    common = language.common[0];
    menu = language.menu[0];
    createForum = language.create_forum[0];
    const toolbar = {
      tabs: {
        values: {
          all: { text: label.ALL_TOPIC },
          'with-answer': { text: label.WITH_ANSWER },
          unanswered: { text: label.UNANSWERED },
        },
      },
      sort: [
        { orderBy: 'date', order: 'ASC', label: common.OLDEST },
        { orderBy: 'date', order: 'DESC', label: common.NEWEST },
      ],
    };
    const { location, cards, detail, answers, match } = this.props;
    return (
      <Page
        name={menu.FORUMS}
        hasLeftPanel={false}
        singleName={label.TOPIC}
        toolbar={toolbar}
        addBookmark={this.props.addBookmark}
        totalItems={this.props.totalItems}
        sortLabel={this.props.sortLabel}
        fetchFunction={this.props.fetchForumTopics}
        {...this.props}
        cardsPerPage={9}
        cards={cards.map((el) => {
          const tags = [];
          if (el.tags[0]) {
            tags.push(el.tags[0]);
          }
          if (el.tags.length > 1) {
            tags.push({ type: 'white', text: `+${el.tags.length - 1}` });
          }
          return {
            ...el,
            tags,
            renderAdditionalButtons: (id, bookmarked) => (
              <Fragment>
                <ButtonBookmark
                  id={id}
                  bookmarked={bookmarked}
                  type={'forums'}
                />
                <ButtonAbuseReport id={id} hideOnMobile />
              </Fragment>
            ),
          };
        })}
        renderAfterTitle={() => <BeforeToolbar location={location} />}
        renderDetail={() => {
          if (answers === null && detail === null) {
            return null;
          }
          return (
            <PreventEmptyDetail detail={detail}>
              <Forum
                {...detail}
                answers={answers}
                totalAnswers={this.props.totalAnswers}
                pageUrl={this.props.pageUrl}
                pageTitle={this.props.pageTitle}
                addBookmark={this.props.addBookmark}
                previousUrl={window.location.hash}
                detailBookmarked={this.props.detailBookmark}
                editFlag='forums'
              />
            </PreventEmptyDetail>
          );
        }}
        location={location}
        match={match}
        canCreateNew={roleFlag}
        createNewProps={{
          renderAfterTitle: () => {},
          onFinish: this.onFinish.bind(this),
          beforeNext: this.beforeNext.bind(this),
          steps: [{ title: label.MAIN_INFORMATION, nextButton: common.SUBMIT }],
          renderStep: (step, multiStepForm) => {
            switch (step) {
              case 1:
                return (
                  <Form
                    getApi={(formApi) =>
                      multiStepForm.setCurrentForm({ key: 1, formApi })
                    }
                    render={() => (
                      <Fragment>
                        <div className='inputs'>
                          <Input
                            id='name'
                            label={createForum.TOPIC_NAME}
                            hasError={
                              this.props.errorMessages.hasOwnProperty('name') &&
                              this.props.errorMessages.name
                                ? true
                                : false
                            }
                            errors={
                              this.props.errorMessages.hasOwnProperty('name') &&
                              this.props.errorMessages.name
                                ? this.props.errorMessages.name[0]
                                : ''
                            }
                            placeholder={createForum.TOPIC_PLACEHOLDER}
                            required
                          />

                          <Input
                            id='description'
                            label={createForum.TOPIC_DESCRIPTION}
                            placeholder={createForum.TOPIC_DESCRIPTION}
                            hasError={
                              this.props.errorMessages.hasOwnProperty(
                                'description'
                              ) && this.props.errorMessages.description
                                ? true
                                : false
                            }
                            errors={
                              this.props.errorMessages.hasOwnProperty(
                                'description'
                              ) && this.props.errorMessages.description
                                ? this.props.errorMessages.description[0]
                                : ''
                            }
                            type='textarea'
                            required
                          />

                          <Input
                            id='forumMembers'
                            label={createForum.PRIVATE_TOPIC}
                            type='tab-group'
                            hasError={
                              this.props.errorMessages.hasOwnProperty(
                                'forumMembers'
                              ) && this.props.errorMessages.forumMembers
                                ? true
                                : false
                            }
                            errors={
                              this.props.errorMessages.hasOwnProperty(
                                'forumMembers'
                              ) && this.props.errorMessages.forumMembers
                                ? this.props.errorMessages.forumMembers[0]
                                : ''
                            }
                            options={[
                              { value: 2, label: common.YES },
                              { value: 1, label: common.NO },
                            ]}
                            required
                          />

                          <MultiInputField
                            field='forums'
                            type='select'
                            id='forums'
                            label={createForum.SHOW_IN_FORUM}
                            placeholder={createForum.FORUM_LIST_PLACEHOLDER}
                            hasError={
                              this.props.errorMessages.hasOwnProperty(
                                'forums'
                              ) && this.props.errorMessages.forums
                                ? true
                                : false
                            }
                            errors={
                              this.props.errorMessages.hasOwnProperty(
                                'forums'
                              ) && this.props.errorMessages.forums
                                ? this.props.errorMessages.forums[0]
                                : ''
                            }
                            options={this.props.formTagOptions}
                            required
                          />
                        </div>
                      </Fragment>
                    )}
                  />
                );

              default:
                return null;
            }
          },
        }}
      />
    );
  }
}

PageForums.propTypes = {
  location: PropTypes.object.isRequired,
  isClearButtonDisabled: PropTypes.bool,
};

PageForums.defaultProps = {
  errorMessages: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(PageForums);
