/* eslint-disable no-unused-vars */
/*
 * This is a wrapper component that wraps <Select/> from 'react-select' in order
 * to bind the data interchange with <Form /> from 'informed'
 */

import React, { Component } from 'react'
import { asField } from 'informed'
import PropTypes from 'prop-types'
import Select, { Creatable, Async, AsyncCreatable } from 'react-select'


export class SelectInput extends Component {
  static propTypes = {
    async: PropTypes.bool,
    createable: PropTypes.bool,
    onChange: PropTypes.func,
    reactSelectProps: PropTypes.object,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.object,
    ]),
  }

  onSelectChange(option) {
    const { async, onChange } = this.props
    if (option == null) {
      onChange(null)
    } else if (async) {
      onChange(option)
    } else {
      onChange(option.value)
    }
  }

  render() {
    const { value, createable, async, reactSelectProps } = this.props
    let Component
    if (async && createable) {
      Component = AsyncCreatable
    } else if (async) {
      Component = Async
    } else if (createable) {
      Component = Creatable
    } else {
      Component = Select
    }
    return React.createElement(Component, {
      ...reactSelectProps,
      matchProp: 'label',
      onChange: this.onSelectChange.bind(this),
      value,
    })
  }
}

export const SelectInputField = asField(
  ({
    fieldApi: { setValue, setTouched },
    fieldState: { value },
    onChange,
    ...rest
  }) => {
    return (
      <SelectInput
        value={value}
        onChange={(v) => {
          setTouched(true)
          setValue(v)
          onChange(v)
        }}
        {...rest}
      />
    )
  }
)
