import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/fr';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { HashLink as Link } from 'react-router-hash-link';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import Preloader from '../components/Preloader';
import cardEvents from '../data/events.json';
import cardNews from '../data/news.json';
import cardPubs from '../data/publications.json';
import cardVideos from '../data/videos.json';
import cardStories from '../data/success-stories.json';
import languages from '../helpers/languages';
import {
  fetchEvents, fetchNews, fetchPublications, fetchStory,
  fetchVideos
} from '../store/actions';



var ReactRotatingText = require('react-rotating-text');

var language = languages['en'];
var common = language.common[0];
var HomePagelbl = language.Homepage[0];

const mapDispatchToProps = {
  fetchNews,
  fetchStory,
  fetchEvents,
  fetchVideos,
  fetchPublications
};

const mapStateToProps = (state) => {
  return {
    lan: state.language.lan,
    cardStoriesDb: state.successStory.stories,
    cardNewsDb: state.news.news,
    cardVideosDb: state.videos.videos,
    cardPubsDb: state.publications.publications,
    cardEventsDb: state.events.events,
  };
};

class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cardsLoaded: false,
    };
  }
  handleLoading(promise) {
    const animationPromise = new Promise((resolve) =>
      setTimeout(resolve, 1000)
    );
    this.setState({ cardsLoaded: false });
    return Promise.all([promise, animationPromise]).then(() => {
      if (!this.unmounted) {
        this.setState({ cardsLoaded: true });
      }
    });
  }

  componentWillUnmount() {
    this.unmounted = true;
  }


  componentDidMount() {
    this.handleLoading(this.props.fetchNews(window.location.hash));
    this.handleLoading(this.props.fetchStory(window.location.hash));
    this.handleLoading(this.props.fetchVideos(window.location.hash));
    this.handleLoading(this.props.fetchPublications(window.location.hash));
    this.handleLoading(this.props.fetchEvents());
  }

  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    HomePagelbl = language.Homepage[0];
    const { showPreloader,
      cardNewsDb,
      cardEventsDb,
      cardStoriesDb,
      cardVideosDb } = this.props;
    const { cardsLoaded } = this.state;

    const rotatingArray = [HomePagelbl.COMMUNITY, HomePagelbl.PLATFORM, HomePagelbl.NETWORK, HomePagelbl.LEARNING_SPACE, HomePagelbl.BUSINESS_ENVIRONMENT];

    const sliderSettings = {
      dots: true,
      infinite: true,
      speed: 300,
      centerMode: true,
      variableWidth: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1000,
      cssEase: "linear",
    };

    return (
      <CustomizedDocumentTitle title={common.MY_DASHBOARD}>
        <div className='transition-page'>
          {showPreloader ? (
            <Preloader size='medium' relative />
          ) : (
            <div>
              {/*header content */}
              <header>
                <div className="indexvideoheader">
                  <div className="headeroverlay">
                    <h1>A _ <span className="txt-rotate">
                      <ReactRotatingText items={rotatingArray} />
                      <br /></span>
                      {HomePagelbl.FOR_EVERY_WOMAN}
                    </h1>
                  </div>
                  <video playsInline autoPlay muted loop poster="images/mp4/Homewide_3.jpg">
                    <source src="images/mp4/Homewide_3.mp4" type="video/mp4" />
                    {HomePagelbl.YOUR_BROWSER_DOES_NOT_SUPPORT_THE_VIDEO_TAG}
                  </video>
                </div>
              </header>
              {/*header content */}
              {/*info panel */}
              <div id="header_info_panel">
                <div className="container-2even">
                  <div className="header_info_panel-left">
                    <h3>{HomePagelbl.FOR_WOMEN_IN_BUSINESS}</h3>
                    <p>{HomePagelbl.CONNECT_TO_OVER_40000_BUSINESSES_MA}</p>
                  </div>
                  <div className="header_info_panel-right">
                    <h3>{HomePagelbl.FOR_BUYERS_INVESTORS_AND_PARTNERS}</h3>
                    <p>{HomePagelbl.FIND_SUSTAINABLE_PRODUCTS_AND_SERVI}</p>
                  </div>
                </div>
              </div>
              {/*info panel */}

              {/*testimonials and button */}
              <div id="testimonials_bar">
                <div className="container-2col">
                  <ul id="testimonials">
                    <li><img src="images/1.jpg"
                      alt="" />
                      <p><span>{HomePagelbl.SINCE_PARTICIPATING_IN_THE_SHE_TRADES}<br /><strong>- Marie Laetitia Kayitsire</strong></span></p>
                    </li>
                    <li><img src="images/2.jpg"
                      alt="" />
                      <p><span>{HomePagelbl.THE_SHE_TRADES_UPS_IN_NIGERIA}<br /><strong>- Funmilola Omokunga</strong></span></p></li>
                    <li><img src="images/3.jpg" alt="" />
                      <p><span>{HomePagelbl.IN_ALL_SHE_TRADES_HAS_PUSHED_ME}<br /><strong>- Muthoni Waigwa</strong></span></p></li>
                    <li><img src="images/4.jpg" alt="" />
                      <p><span>{HomePagelbl.THANKS_TO_SHETRADES_I_WON_A_GRANT}<br /><strong>- Jaqueline Mukashyaka</strong></span></p></li>
                    <li><img src="images/5.jpg" alt="" />
                      <p><span>{HomePagelbl.SHE_TRADES_COMMONWEALTH_HELPED_MY_BUSINESS}<br /><strong>- Wanjiru Njenga </strong></span></p></li>
                    <li><img src="images/6.jpg" alt="" />
                      <p><span>{HomePagelbl.IN_MAYAN_TRADITION_WE_SAY_THAT}<br /><strong>- Anselma Chaleuan</strong></span></p></li>
                    <li><img src="images/7.jpg" alt="" />
                      <p><span>{HomePagelbl.THANKS_TO_SHETRADES_WE_HAVE_A_SOLID}<br /><strong>- Achini Perera </strong></span></p></li>
                    <li><img src="images/8.jpg" alt="" />
                      <p><span>{HomePagelbl.SHETRADES_THROUGH_DIGITALIZATION_HELPS}<br /><strong>- Ndeye Fatou Njie</strong></span></p></li>
                  </ul>
                  <Link to="/sign-up" className="btn">{HomePagelbl.JOIN_US_ITS_FREE}</Link>
                </div>
              </div>
              {/*testimonials and button */}

              {/*feature cards */}
              <div className="dotbg l2" />
              <div className="wrap">
                <div className="container-4col">
                  <div className="indexfeaturecard"><span className="bigicon bigicon-learn" />
                    <Link to="/publiclearn" className="biglinkar">{HomePagelbl.LEARN}<i className="fal fa-arrow-right" /></Link>
                    <p>{HomePagelbl.FIND_E_LEARNING_RESOURCES_WEBINARS}</p>
                    <Link to="/publiclearn" className="infolink">{HomePagelbl.MORE} <i className="fal fa-arrow-right" /></Link></div>
                  <div className="indexfeaturecard"><span className="bigicon bigicon-grow" />
                    <Link to="/project/9" className="biglinkar">{HomePagelbl.UNLOCK_FINANCE}<i className="fal fa-arrow-right" /></Link>
                    <p>{HomePagelbl.UNLOCK_FINANCE_DESC}</p>
                    <Link to="/project/9" className="infolink">{HomePagelbl.MORE} <i className="fal fa-arrow-right" /></Link></div>
                  <div className="indexfeaturecard"><span className="bigicon bigicon-network" />
                    <Link to="/publications" className="biglinkar">{HomePagelbl.RESOURCES}<i className="fal fa-arrow-right" /></Link>
                    <p>{HomePagelbl.RESOURCES_DESC}</p>
                    <Link to="/publications" className="infolink">{HomePagelbl.MORE} <i className="fal fa-arrow-right" /></Link></div>
                  <div className="indexfeaturecard"><span className="bigicon bigicon-sellandbuy" />
                    <Link to="/publicevents" className="biglinkar">{HomePagelbl.EVENTS}<i className="fal fa-arrow-right" /></Link>
                    <p>{HomePagelbl.EVENTS_DESC}</p>
                    <Link to="/publicevents" className="infolink">{HomePagelbl.MORE} <i className="fal fa-arrow-right" /></Link></div>
                </div>
              </div>
              <div className="dotbg" />
              {/*feature cards */}
              {/*global expo */}

              {!cardsLoaded ? (
                <div className='list-animation-item'>
                  <Preloader size='small' relative />
                </div>
              ) : !cardEventsDb.length ? (
                <Fragment>
                  <div id="global_meetup">
                    <div className="wrap">
                      <div className="container-2col">

                        <h2> <Link to={`/events/${cardEvents[0].id}`} >{cardEvents[0].title}
                        </Link>
                        </h2>
                        <p className="global_date">
                          {moment(cardEvents[0].start).format('MMM D, YYYY')} - {moment(cardEvents[0].end).format('MMM, D YYYY')} <b />
                          {cardEvents[0].region.name}<b /></p>
                      </div>
                    </div>
                  </div>
                  <div className="bgdarker">
                    <div className="wrap">
                      <div className="container-2col indexregfree">
                        <p>{cardEvents[0].description}</p>
                        <a href={cardEvents[0].applyUrl} rel="noreferrer" target='_blank' className="btn">{HomePagelbl.REGISTER_FOR_FREE}</a></div>
                    </div>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div id="global_meetup">
                    <div className="wrap">
                      <div className="container-2col">

                        <h2> <Link to={`/events/${cardEventsDb[0].id}`} >{cardEventsDb[0].title}
                        </Link>
                        </h2>
                        <p className="global_date">
                          {moment(cardEventsDb[0].start).format('MMM D, YYYY')} - {moment(cardEventsDb[0].end).format('MMM, D YYYY')} <b />
                          {cardEventsDb[0].region.name}<b /></p>
                      </div>
                    </div>
                  </div>
                  <div className="bgdarker">
                    <div className="wrap">
                      <div className="container-2col indexregfree">
                        <p>{cardEventsDb[0].description}</p>
                        <a href={cardEventsDb[0].applyUrl} rel="noreferrer" target='_blank' className="btn">{HomePagelbl.REGISTER_FOR_FREE}</a></div>
                    </div>
                  </div>
                </Fragment>
              )}

              <div id="btncalendar">
                <Link to="/events"><i className="fal fa-calendar" />
                  {HomePagelbl.CHECK_OUT_ALL_OUR_EVENTS} <i className="fal fa-arrow-right" />
                </Link>
              </div>
              {/*global expo */}
              {/*articles */}
              <div className="bgwhite"><div className="dotbg l2" style={{ marginTop: '-20px' }} />
                <div className="wrap">
                  <div className="container">
                    <h2>{HomePagelbl.LATEST_FROM_OUR_COMMUNITY}</h2>
                    <div className="container-3col">

                      {!cardsLoaded ? (
                        <div className='list-animation-item'>
                          <Preloader size='small' relative />
                        </div>
                      ) : !cardStoriesDb.length ? (
                        <Fragment>

                          <article>
                            <span class="articleimg">
                              <Link to={`/publicsuccessstories/${cardStories[0].id}`} >
                                <img height="208px" src={cardStories[0].image} alt="" /></Link></span>
                            <div class="articledescription">
                              <p>{cardStories[0].title}</p>
                              <Link to={`/publicsuccessstories/${cardStories[0].id}`} class="link">
                                {HomePagelbl.READ_MORE} <i class="fal fa-arrow-right"></i></Link>
                            </div>
                          </article>

                          {cardNews && cardNews.length &&
                            <article>

                              <span class="articleimg">
                                <Link to={`/publicnews/${cardNews[0].id}`} >
                                  <img height="208px" src={cardNews[0].image} alt="" /></Link></span>
                              <div class="articledescription">
                                <p>{cardNews[0].title}</p>
                                <Link to={`/publicnews/${cardNews[0].id}`} class="link">
                                  {HomePagelbl.READ_MORE} <i class="fal fa-arrow-right">
                                  </i></Link>
                              </div>

                            </article>
                          }

                          {cardVideos && cardVideos.length &&
                            <article>
                              <span class="articleimg">
                                <Link to={`/publicvideos`} >
                                  <img height="208px"src={cardVideos[0].image} alt="" /></Link></span>
                              <div class="articledescription">
                                <p>{cardVideos[0].title}</p>
                                <Link to={`/publicvideos`} class="link">
                                  {HomePagelbl.READ_MORE} <i class="fal fa-arrow-right"></i></Link>
                              </div>
                            </article>}
                        </Fragment>
                      ) : (
                        <Fragment>
                          <article>
                            <span class="articleimg">
                              <Link to={`/publicsuccessstories/${cardStoriesDb[0].id}`} >
                                <img height="208px" src={cardStoriesDb[0].image} alt="" /></Link></span>
                            <div class="articledescription">
                              <p>{cardStoriesDb[0].title}</p>
                              <Link to={`/publicsuccessstories/${cardStoriesDb[0].id}`} class="link">
                                {HomePagelbl.READ_MORE} <i class="fal fa-arrow-right"></i></Link>
                            </div>
                          </article>
                          {cardNewsDb && cardNewsDb.length &&
                            <article>
                              <span class="articleimg">
                                <Link to={`/publicnews/${cardNewsDb[0].id}`} >
                                  <img height="208px" src={cardNewsDb[0].image} alt="" /></Link></span>
                              <div class="articledescription">
                                <p>{cardNewsDb[0].title}</p>
                                <Link to={`/publicnews/${cardNewsDb[0].id}`} class="link">
                                  {HomePagelbl.READ_MORE} <i class="fal fa-arrow-right"></i></Link>
                              </div>
                            </article>
                          }
                          {cardVideosDb && cardVideosDb.length &&
                            <article>
                              <span class="articleimg">
                                <Link to={`/publicVideos`} >
                                  <img height="208px" src={cardVideosDb[0].image} alt="" /></Link></span>
                              <div class="articledescription">
                                <p>{cardVideosDb[0].title}</p>
                                <Link to={`/publicVideos`} class="link">
                                  {HomePagelbl.READ_MORE} <i class="fal fa-arrow-right"></i></Link>
                              </div>
                            </article>
                          }
                        </Fragment>
                      )}
                    </div>
                  </div></div></div>
              {/*articles */}
              {/*Let’s keep in touch! */}
              <div className="bgwhite">
                <div className="socialmedia">
                  <div className="socialmediainfotext">
                    <h3>{HomePagelbl.LETS_KEEP_IN_TOUCH}</h3>
                    <p>{HomePagelbl.CONNECT_WITH_US_ON_SOCIAL_MEDIA}</p>
                  </div>
                  <div className="socialmediaicons">
                    <Link to="/youtube"><i className="fab fa-youtube-square" /></Link>
                    <Link to="/facebook"><i className="fab fa-facebook-square" /></Link>
                    <Link to="/twitter"><i className="fab fa-twitter-square" /></Link>
                    <Link to="/linkedin"><i className="fab fa-linkedin" /></Link>
                  </div>
                </div>
              </div>
              {/*Let’s keep in touch! */}
              {/*Funding Opportunity */}
              <div id="findopportunity">
                <div className="wrap">
                  <div className="container-2col">
                    <h3>{HomePagelbl.FUNDING_OPPORTUNITY}</h3>
                    <div className="oppdate">
                      <Link to="/publicproject/9" className="btn">{HomePagelbl.SEE_IF_YOURE_ELIGIBLE}</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wrap">
                <div className="container-2col">

                </div>
              </div>
              {/*Funding Opportunity */}
              {/*Browse all the opportunities */}
              <div className="bgwhite">
                <div className="dotbg" style={{ maxHeight: '40px' }} />
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon bigicon-events bigiconhide" />
                    <div className="bigicontextblock"><a href="https://learn.shetrades.com/course/info.php?id=1555" className="biglink">{HomePagelbl.BROWSE_OUR_OPPORTUNITIES}<i className="fal fa-arrow-right" /></a>
                      <p>{HomePagelbl.FIND_OUT_ABOUT_PROJECTS_WITH_OPEN_A}</p></div>
                  </div>
                </div>
              </div>
              {/*Browse all the opportunities */}
              {/*map */}
              <div className="bgdark">
                <div className="mapcontainer">
                  <h3>{HomePagelbl.CONNECTING_3_MILLION_WOMEN_TO_MARKE}</h3>
                  <p className="mapintotext">
                    {HomePagelbl.TOGETHER_WE_ARE_WORKING_TO_CONNECT}.
                  </p>
                  <div id="mapinfo">
                    <div><h2>30</h2> <b>{HomePagelbl.COUNTRIES}</b></div>
                    <div><h2>12</h2> <b>hubs</b></div>
                  </div>
                  <div className="mapunder">
                    <div className="mapbl">{HomePagelbl.ALREADY_3MIO_WOMEN_ENTREPRENEURS_CO}</div>
                    <div className="mapbr"><strong /></div>
                  </div>
                  <Link to="/sign-up" className="btn">{HomePagelbl.JOIN_OUR_NETWORK_REGISTER_NOW}</Link>
                  <div className="mapbox mapboxindex">
                    <div className="worldmap">
                      <div className="maphomeicon h1"><i className="fas fa-home-alt" /></div>
                      <div className="maphomeicon h2"><i className="fas fa-home-alt" /></div>
                      <div className="maphomeicon h3"><i className="fas fa-home-alt" /></div>
                      <div className="maphomeicon h4"><i className="fas fa-home-alt" /></div>
                      <div className="maphomeicon h5"><i className="fas fa-home-alt" /></div>
                      <div className="maphomeicon h6"><i className="fas fa-home-alt" /></div>
                      <div className="maphomeicon h7"><i className="fas fa-home-alt" /></div>
                      <div className="maphomeicon h8"><i className="fas fa-home-alt" /></div>
                      <div className="maphomeicon h9"><i className="fas fa-home-alt" /></div>
                      <div className="maphomeicon h10"><i className="fas fa-home-alt" /></div>
                      <div className="maphomeicon h11"><i className="fas fa-home-alt" /></div>
                      <div className="maphomeicon h12"><i className="fas fa-home-alt" /></div>
                    </div>
                  </div>
                  {/*<Link to="/publichubs" className="btn addonbtn">{HomePagelbl.DISCOVER_OUR_HUBS}</Link>*/}
                </div></div>
              {/* ma*/}
              {/*connect */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container-2col">
                    <div>
                      <h2>{HomePagelbl.MAKING_AN_IMPACT_TOGETHER}</h2>
                      <p>{HomePagelbl.MEET_THE_ORGANISATIONS_WHO_ARE_MAKI}</p>
                    </div>
                    <Link to="/publicpartners" className="btn">{HomePagelbl.OUR_PARTNERS}</Link>
                  </div>
                </div>
                <div className="bgwhite">
                  <div className="partnerscontainer">
                    {/* <Slider className="slider-new" {...sliderSettings}>
                      {partnerData.map(el => (
                        <div>
                          <Link to={`/publicpartner/${el.id}`}>
                            <Image src={el.image} /></Link>
                        </div>
                      ))}
                    </Slider> */}
                    <Slider className="slider-new" {...sliderSettings}>
                      <Link to={`/partners`}><img src="images/partners_and_founders/DFIDlogo.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/KOICALogo.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/EUlogo.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/GIZ-Logo.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/GermanCooperation.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/UPSLogo.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/EIFLogo.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/MaryKay.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/ITFCLogo.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/OPECFundLogo.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/MFASweden.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/MFAFinland.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/MFANetherlandslogo.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/MFAIcelandlogo.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/NorwegianMFA.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/PeopleofJapan.png" alt="" /></Link>
                      <Link to={`/partners`}><img src="images/partners_and_founders/Maersk.png" alt="Maersk" /></Link>
                    </Slider>
                  </div>
                </div>
              </div>
              {/*connect */}
              {/*help center */}
              {/* Remove from issue #228
              <div className="bgwhite">
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon bigicon-help bigiconhide" />
                    <div className="bigicontextblock">
                      <Link to="/publichelpcenter" className="biglink">{HomePagelbl.RESOURCES_AND_HELP_CENTRE}
                        <i className="fal fa-arrow-right" /></Link>
                      <p>{HomePagelbl.FROM_THE_LATEST_PUBLICATIONS_AND_GU}</p>
                    </div>
                  </div></div>
                <div className="dotbg l2" />
              </div>
              {/*help center */}
            </div>
          )}
        </div>
      </CustomizedDocumentTitle>
    );
  }
}

HomePage.propTypes = {
  showPreloader: PropTypes.bool,
};

HomePage.defaultProps = {
  cardNewsDb: [],
  cardEventsDb: [],
  cardStoriesDb: [],
  cardPubsDb: []
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
