/* eslint no-restricted-globals:0 */
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addBookmark, addDashboardBookmark } from '../store/actions';
import CustomizedTooltip from '../components/CustomizedTooltip';
import languages from '../helpers/languages';

var language = languages['en'];
var common = language.common[0];

const mapStateToProps = (state) => ({
  lan: state.language.lan,
});

const mapDispatchToProps = {
  addBookmark,
  addDashboardBookmark,
};

class ButtonBookmark extends Component {
  static propTypes = {
    dashboard: PropTypes.bool,
    tab: PropTypes.string,
  };

  static defaultProps = {
    dashboard: false,
    tab: '',
    location: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      bookmarked: false,
    };
  }

  componentDidMount() {
    this.setState({
      bookmarked: this.props.bookmarked,
    });
  }

  toggle(id, type) {
    const { bookmarked } = this.state;
    if (this.props.dashboard) {
      this.props.addDashboardBookmark(type, id, this.props.tab);
    } else {
      this.props.addBookmark(type, id, null, window.location.hash);
    }
    this.setState({
      bookmarked: !bookmarked,
    });
  }

  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    const bookmarked = this.props.dashboard
      ? this.props.bookmarked
      : this.state.bookmarked;
    return (
      <CustomizedTooltip title={common.BOOKMARK}>
        <button
          className='button button_border dashboard-card__bookmark'
          onClick={() => this.toggle(this.props.id, this.props.type)}
        >
          <i
            className={
              bookmarked ? 'icon-bookmark--bookmarked' : 'icon-bookmark'
            }
          />
        </button>
      </CustomizedTooltip>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonBookmark);
