import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import UserNameWithAvatar from '../components/UserNameWithAvatar';

const Suggestion = (props) => {
  const {
    userNameWithAvatarProps,
    maxNumber = 6,
    cards,
    pageType,
    addContactFunction,
    updateContactFunction,
  } = props;

  return (
    <div className="meetsellers">
    <div>
       <ul className="meetsellerslist">
        {cards.slice(0, maxNumber).map((el) => (
          <li>
            <UserNameWithAvatar
              {...userNameWithAvatarProps}
              {...el}
              pageType={pageType}
            />
          </li>
        ))}
      </ul>
      </div>
    </div>
  );
};

Suggestion.propTypes = {
  userNameWithAvatarProps: PropTypes.object.isRequired,
  maxNumber: PropTypes.number,
  cards: PropTypes.array,
  pageType: PropTypes.string,
  addContactFunction: PropTypes.func,
  updateContactFunction: PropTypes.func,
};

Suggestion.defaultProps = {
  maxNumber: 3,
  cards: [],
  pageType: '',
  addContactFunction: null,
  updateContactFunction: null,
};

const mapStateToProps = (state) => ({ t: state.language.t });

export default connect(mapStateToProps)(Suggestion);
