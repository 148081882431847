import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Footer from '../../components/Footer';
import { InstallButton } from '../../components/Header/components/installButton';
import TopBar from '../../components/Header/components/TopBar';
import FormHandler from '../form-handler-signup';
import SignUpNewForm from '../forms/sign-up-new';
import GlobalError from '../global-error';
import { userSignupRequest } from '../../actions/auth';
import {
  email,
  password,
  passwordShouldMatch,
} from '../../validations/validations';
import languages from '../../helpers/languages';
import { fetchRegistrationInitialStates } from '../../store/actions';

class View extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submissionWasSuccessful: false,
    };
  }
  render() {
    this.props.fetchRegistrationInitialStates();
    const currLang = languages[this.props.lan];
    const regLang = currLang.registration[0];
    if (this.state.submissionWasSuccessful) {
      return (
        <div>
          <div id="top_bar">
            <TopBar></TopBar>
          </div>
          <div className='registration-success'>
            <div className='registration-success__vector'>
              <img src='/images/vector/letter.svg' alt='' />
            </div>
            <div>
              <div className="page__body">
                <h3>
                  {regLang.HAS_BEEN_CREATED}
                </h3><div
                >
                  <hr />
                </div>
                <div>
                  <p>{regLang.BEFORE_YOU_CAN_USE_YOUR_SHETRADES}</p>

                  <p>{regLang.YOU_SHOULD_RECEIVE_A_VERIFICATION_EMAIL}</p>
                  <p>{regLang.IF_YOU_DONT_RECEIVE_THE_VERIFICATION_EMAIL}</p>

                </div>
                <hr />
                <h3>{regLang.NEED_SUPPORT}</h3>
                <p>{regLang.NEED_MORE_INFO}</p>
              </div>
              {/*<div className="">
                <a href="https://shetrades.com.com/#/contactus/" target className="button  button-hollow">
                {regLang.VISIT_OUR_SUPPORT_PAGE}
                </a>
              </div>*/}
            </div>
            <p>{/*regLang.PLEASE_LOGIN_TO_CONTINUE*/}
              <Link to="/sign-in">{regLang.PLEASE_LOGIN_TO_CONTINUE}</Link></p>

          </div>
          <Footer>
            {/* <InstallButton></InstallButton> */}
          </Footer>
        </div>
      );
    }
    return (
      <FormHandler
        submitRequest={this.props.userSignupRequest}
        onSubmitSuccess={() =>
          this.setState({ submissionWasSuccessful: true })
        }
        initialValues={initialValues}
        mapFieldsToValidations={mapFieldsToValidations}
        language={currLang}
      // withCaptcha
      >
        {(formState, onChange, onSubmit, captchaRef, onCaptchaChange) => (
          <GlobalError error={formState.globalError}>
            <SignUpNewForm
              values={formState.values}
              errors={formState.errors}
              onChange={onChange}
              onSubmit={onSubmit}
              buttonDisabled={formState.buttonDisabled}
              captchaRef={captchaRef}
              onCaptchaChange={onCaptchaChange}
              globalError={formState.globalError}
            />
          </GlobalError>
        )}
      </FormHandler>
    );
  }
}

View.propTypes = {
  // onSubmitSuccess: PropTypes.func,
  userSignupRequest: PropTypes.func,
};

const mapStateToProps = (state) => ({
  lan: state.language.lan,
});

const mapDispatchToProps = {
  userSignupRequest,
  fetchRegistrationInitialStates,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);

const initialValues = {
  email: '',
  firstName: '',
  lastName: '',
  password: '',
  confirmPassword: '',
  gender: '',
  declaration: true,
  country: '',
  // 'g-recaptcha-response': '',
};

const mapFieldsToValidations = {
  email,
  password: password,
  confirmPassword: passwordShouldMatch('password'),
};
