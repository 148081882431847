import React from 'react';
import Modal from '../../../components/Modal';
import { Form } from 'informed';
import { connect } from 'react-redux';
import Input from '../../../components/settings/Input';
import FilesInput from '../../../components/settings/Input/components/FilesInput';

class DetailApply extends React.Component {
  static initialState = {
    formApi: null,
    files: [],
  };

  static getDerivedStateFromProps(props, state) {
    const { isShown } = props;
    const { files } = state;

    if (!isShown && files.length) {
      return DetailApply.initialState;
    }

    return state;
  }

  constructor() {
    super();
    this.state = DetailApply.initialState;
    this.onFilesChange = this.onFilesChange.bind(this);
  }

  setFormApi(formApi) {
    this.setState({
      ...this.state,
      formApi,
    });
  }

  onFilesChange(files) {
    this.setState({
      ...this.state,
      files,
    });
  }

  render() {
    const { files, formApi } = this.state;
    const { isShown, onApply, onClose, t } = this.props;

    return (
      <Modal
        isOpen={isShown}
        onRequestClose={onClose}
        buttons={{
          left: {
            text: t.common[0].CANCEL,
            className: 'gray',
            onClick: onClose,
          },
          right: {
            text: t.common[0].APPLY,
            className: 'brand',
            onClick: () => onApply({ ...formApi.getState().values, files }),
          },
        }}
      >
        <Form className='inputs' getApi={(formApi) => this.setFormApi(formApi)}>
          <Input
            id='message'
            label={t.common[0].YOUR_MESSAGE}
            type='textarea'
            placeholder={t.common[0].INTRODUCE_YOURSELF_COUNTERPARTY}
            vertical
            renderBottom={() => (
              <FilesInput
                field='attachments'
                onChange={this.onFilesChange}
                value={files}
                inputAttrs={{ multiple: true }}
                maxCount={3}
              />
            )}
          />
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({ t: state.language.t });

export default connect(mapStateToProps)(DetailApply);
