import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from '../../components/Modal';
import Accordion from '../../components/Accordion';
import Preloader from '../../components/Preloader';
import Questionnaire from '../../components/Questionnaire';
import CustomizedDocumentTitle from '../../components/CustomizedDocumentTitle';
import { fetchCommitmentList, fetchCommitmentForm } from '../../store/actions';
import AccordionItem from './components/AccordionItem';

import languages from '../../helpers/languages';

var language = languages['en'];
var label = language.commitments[0];
var menu = language.menu[0];
var common = language.common[0];

class PageCommitments extends Component {
  static propTypes = {
    commitments: PropTypes.array,
    commitmentQuestions: PropTypes.array,
    fetchCommitmentList: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      openedIndex: null,
      showModal: false,
    };
    this.submitQuestionnaire = this.submitQuestionnaire.bind(this);
  }

  componentDidMount() {
    const { fetchCommitmentList } = this.props;
    if (fetchCommitmentList) {
      fetchCommitmentList();
    }
  }

  toggleModal(showModal) {
    this.setState({
      showModal,
    });
  }

  submitQuestionnaire(stepResults) {
    // there should be something like
    // return axios.post('/create-commitment', stepResults)
    return new Promise((resolve, reject) => setTimeout(() => resolve(), 1000));
  }

  render() {
    const { showPreloader } = this.props;
    language = languages[this.props.lan];
    label = language.commitments[0];
    menu = language.menu[0];
    common = language.common[0];
    const {
      commitmentQuestions,
      commitments,
      fetchCommitmentForm,
    } = this.props;
    const { openedIndex, showModal } = this.state;
    const accordionItems = commitments
      ? commitments.map((el, i) => ({
          ...el,
          onButtonClick: () => {
            this.toggleModal(true);
            if (i === openedIndex) {
              fetchCommitmentForm(el.id);
            }
          },
          isButtonDisabled: el.status,
        }))
      : null;
    return (
      <CustomizedDocumentTitle title={menu.COMMITMENTS}>
        <div className='transition-page'>
        {showPreloader ? (
            <Preloader size='medium' relative />
          ) : (
          <div>
          {/*header content */}
          <header>
          <div className="innerheader innerheader-aboutouroffering">
          <h1>{label.COMMITMENT}</h1>
          </div>
          </header>
          {/*header content */}

          <div className='detail detail_big detail_symmetric'>
            <div className='detail__whole'>
              <div className='content content_p-margins'>
                <p>{label.COMMITMENT_INFO_ONE}</p>

                <p>{label.COMMITMENT_INFO_TWO}</p>

                <p>{label.COMMITMENT_INFO_THREE}</p>
              </div>

              {accordionItems ? (
                <Accordion
                  items={accordionItems}
                  ItemComponent={AccordionItem}
                  openedIndex={openedIndex}
                  onOpenedIndexChange={(openedIndex) =>
                    this.setState({ openedIndex })
                  }
                />
              ) : (
                <Preloader size='medium' relative />
              )}
            </div>
           </div>
          </div>
          )}
        </div>
      </CustomizedDocumentTitle>
    );
  }
}

const mapStateToProps = (state) => ({
  commitments: state.commitments.commitmentList,
  commitmentQuestions: state.commitments.commitmentQuestions,
  openedItem: state.commitments.openedItem,
  lan: state.language.lan,
});

const mapDispatchToProps = {
  fetchCommitmentForm,
  fetchCommitmentList,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageCommitments);
