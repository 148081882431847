import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import Preloader from '../components/Preloader';
import languages from '../helpers/languages';
import cardVLSCourses from '../data/vls-courses.json';
import cardWebinars from '../data/webinars.json';
import noAvatarImage from '../static/images/noavatar.svg';

var language = languages['en'];
var common = language.common[0];
var dashboard = language.dashboard[0];
var learnlbl = language.Learn[0];

const mapStateToProps = (state) => {
  return {
    lan: state.language.lan,
    user: state.auth.user,
  };
};

class PageLearn extends React.Component {
  state = {
    isModalOpened: false
  };

  componentDidMount() {

  }

  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    learnlbl = language.Learn[0];

    const { showPreloader } = this.props;
    const {
      isModalOpened,
    } = this.state;
    const url = 'https://learn.shetrades.com/?redirect=0';
      //'https://learn.shetrades.com/local/itcacademy/setupuser.php?academyid=9&autoissuerid=2&wantsurl=?redirect=0';

    const satUrl =
    'https://learn.shetrades.com/course/info.php?id=1555';

    const attributes = { href: url };
    const satAttributes = { href: satUrl };
    const shuffle = arr => [...arr].sort(() => Math.random() - 0.5);

    return (
      <CustomizedDocumentTitle title={common.MY_DASHBOARD}>
        <div className='transition-page'>
          {showPreloader ? (
            <Preloader size='medium' relative />
          ) : (
            <div>

              {/*header content */}
              <header>
                <div className="innerheader innerheader-learningcenter">
                  <h1>{learnlbl.THE_VIRTUAL_LEARNING_SPACE}</h1>
                </div>
              </header>
              {/*header content */}
              {/*learning activity */}
              <div className="blueblock">
                <div className="container-lr learnactivity-title">
                  <h2>
                  <img src={this.props.user.avatar ? this.props.user.avatar : noAvatarImage}  alt="" className="topbar_userpic"  />
                  {this.props.user.first_name}, {learnlbl.YOUR_LEARNING_ACTIVITY}</h2>
                </div>
              </div>
              <div className="bgdark">
                <div className="wrap" style={{ paddingTop: 10 }}>
                  <div className="container-2even">
                    {/* <div>
                      <h3 style={{ marginBottom: 20 }}>Latest progress in your activities</h3>
                       <p>Marketing for beginners</p>
                      <div className="progressbar">
                        <b style={{ width: '20%' }}>20%</b>
                        <a >Coming soon.... <i className="fal fa-arrow-right" /></a>
                      </div>
                      <a {...attributes} target='_blank' rel="noreferrer" className="bluelink">See them all <i className="fal fa-arrow-right" /></a>
                    </div>
                    <div>
                      <div className="congrats"><i className="fal fa-trophy-alt" /> <h3>Congratulations!<b>You have completed 21 courses so far.</b></h3></div>
                      <div className="blueblock rounded smallcontainer">
                        <div className="container-lr nowrap"><h3><i className="fal fa-icons" /> Your interests</h3>
                          <a {...attributes} target='_blank' rel="noreferrer" className="bluelink">Edit <i className="fal fa-cog iconright" /></a></div>
                        <p className="yourinterests-desc">Coming soon…</p>
                        <i>Our content suggestions are personalized for you.</i></div>
                    </div> */}
                  </div>
                </div>
              </div>
              {/*learning activity */}
              {/*company self-assessment  */}
              <div className="bgwhite">
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon bigicon-self_assessment bigiconhide" />
                    <div className="bigicontextblock">
                      <h2>
                        <a {...satAttributes} target='_blank' rel="noreferrer">{learnlbl.THE_COMPANY_SELF_ASSESSMENT}<span className="icon-red_arrow" />
                        </a></h2> 
                      <p>{learnlbl.DEVELOPED_BY_OUR_CAPACITY_BUILDING_EXPERTS}</p>
                    </div>
                  </div>
              </div>
              </div>
              {/*company self-assessment */}
              {/*Virtual Learning Space  */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon bigicon-learn bigiconhide" />
                    <div className="bigicontextblock"><h2> 
                      <a {...attributes} target='_blank' rel="noreferrer">{learnlbl.ACCESS_FREE_ELEARNING_COURSES} <span className="icon-red_arrow" /></a></h2> 
                      <p>{learnlbl.OUR_ELEARNING_COURSES_ARE_DELIVERED_TOGET}</p></div>
                  </div>
                </div>
                {/*Virtual Learning Space */}
                {/* suggested courses */}
               
                {/* <div className="wrap">
                  <h3 className="titlewithline">SUGGESTED_COURSES_FOR_YOU<span className="titleline" /></h3>
                  <p>BECAUSE_YOURE_INTERESTED_IN_MARKETING,_SUSTAINABILITY_AND_LEGAL
                    <Link to="/settings?tab=my-interest">EDIT_MY_PREFERENCES</Link></p>
                  
                  <div className="cardswrapper">

                    {shuffle(cardVLSCourses).slice(0, 3).map((course) => (
                      <div style={{backgroundImage: 'url(' + course.courseimageurl + ')'}} className="cardcontainer">
                        <div className="card-content">
                          <span className="card-tag"><i className="fas fa-book-open icoleft" />E_COURSE</span>
                          <p className="card-desc">
                            <a href={course.buttonurl} target="_blank">{course.coursetitle}</a></p>
                        </div>
                        <span className="card-bottom_info">
                          <span><i className="fas fa-stopwatch icoleft" />
                          </span> <span> English<i className="fas fa-globe-africa icoright" /></span>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>  */}
               </div>
              {/*suggested courses */}
              {/*webinars */}
              <div className="bgwhite">
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon bigicon-webinars bigiconhide" />
                    <div className="bigicontextblock"><h2>
                      <a {...attributes} target='_blank' rel="noreferrer"> 
                      {learnlbl.WATCH_OUR_WEBINARS} <i className="fal fa-arrow-right" /></a></h2>
                      <p>{learnlbl.LEARN_ABOUT_THE_LATEST_INNOVATIONS_IN_E_CO}</p>
                      <div className="container-ll-bottomlinks">
                        <Link to="/webinars?tab=past">{learnlbl.CATALOGUE_OF_PAST_WEBINARS} <i className="fal fa-arrow-right" /></Link>
                        <Link to="/webinars?tab=upcoming">{learnlbl.SEE_ALL_THE_UPCOMING_WEBINARS} <i className="fal fa-arrow-right" /></Link>
                      </div></div>
                  </div>
                </div>

                   {/*risk assessment  */}
              <div className="bggrey"> 
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon bigicon-help bigiconhide" />
                    <div className="bigicontextblock">
                      <h2>
                        <a href={learnlbl.RISK_LINK} target='_blank' rel="noreferrer">{learnlbl.RISK_ASSESSMENT_TOOLKIT}<span className="icon-red_arrow" />
                        </a></h2> 
                      <p>{learnlbl.RISK_ASSESSMENT_TOOLKIT_TEXT}</p>
                    </div>
                  </div></div>
              </div>
              {/*risk assessment */}
              {/*crisis toolkit  */}
              <div className="bgwhite">
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon bigicon-coach bigiconhide" />
                    <div className="bigicontextblock"><h2> 
                      <a href={learnlbl.CRISIS_LINK} target='_blank' rel="noreferrer">{learnlbl.CRISIS_MANAGEMENT_TOOLKIT} <span className="icon-red_arrow" /></a></h2> 
                      <p>{learnlbl.CRISIS_MANAGEMENT_TOOLKIT_TEXT}</p></div>
                  </div>
                </div>
              </div>
              {/*crisis toolkit */}
                {/*webinars */}
                {/*Suggested webinars */}
                {/* remove issue #172
                <div className="wrap">
                  <h3 className="titlewithline">SUGGESTED_WEBINARS_FOR_YOU<span className="titleline" /></h3>
                  <p>BECAUSE_YOURE_INTERESTED_IN_MARKETING,_SUSTAINABILITY_AND_LEGAL     
                  <Link to="/settings?tab=my-interest">EDIT_MY_PREFERENCES</Link></p>
                  {/*cards  */}
                  {/* <div className="cardswrapper">

                  {shuffle(cardWebinars).slice(0, 3).map((webinar, idx) => (

                           <div style={{backgroundImage: 'url(' + webinar.image + ')'}}className="cardcontainer">
                           <div className="card-content">
                             <span className="cardtopimage"><img src="images/partners_and_founders/smallbusdep-logo.jpg" srcSet="images/partners_and_founders/smallbusdep-logo.jpg 1x, images/smallbusdep-logo@2x.jpg 2x, images/partners_and_founders/smallbusdep-logo@3x.jpg 3x, images/partners_and_founders/smallbusdep-logo@4x.jpg 4x" alt =""/></span>
                             <span className="card-tag"><i className="fas fa-headset icoleft" />
                             Webinar</span>{webinar.title}
                             <p className="card-desc"></p>
                           </div>
                           <span className="card-bottom_info">
                             <span><i className="fas fa-stopwatch icoleft" /></span> <span> English<i className="fas fa-globe-africa icoright" /></span>
                           </span>
                         </div>
                        ))
                        }
                  </div>
                </div>  */} 
              </div>
              {/*Suggested webinars */}
              {/*coming next */}
              {/* remove issue #172
              <div className="narrowblueblock">
                <div className="comingnext">
                  <p>Coming next  |  <a {...attributes} target='_blank' rel="noreferrer">«&nbsp;Master your finances&nbsp; »</a> with our partner GroFin, on 15/08/2021 - 18:30 CEST</p>
                  <a {...attributes}  target='_blank' rel="noreferrer" className="bluelink">Read more <i className="fal fa-arrow-right" />
                  </a>
                </div>
              </div>
              {/*coming next */}
              {/*find coach */}
              {/* remove issue #172
              <div className="bggrey">
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon bigicon-coach bigiconhide" />
                    <div className="bigicontextblock"><h2>GET_PERSONALIZED_SUPPORT
                      <a href="#">FIND_YOUR_COACH <i className="fal fa-arrow-right" /></a></h2>
                      <p>WATCH_THIS_SPACE_TO_BE_ONE_OF_THE_FIRST_SHE_TRADES_MEMBERS_TO_ACCESS_OUR_NEW_COACHING_PROGRAMME</p>
                      <a href="#">INTERESTED_IN_BECOMING_A_COACH<i className="fal fa-arrow-right" /></a> </div>
                  </div>
                </div>
              </div>
              {/*find coach */}
              {/*help */}
              {/* remove issue #172
              <div className="bgwhite">
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon bigicon-help bigiconhide" />
                    <div className="bigicontextblock"><h2>FIND_THE_SUPPORT_YOU_NEED_IN_THE <a href="/publichelpcenter">HELP_CENTRE 
                    <i className="fal fa-arrow-right" /></a></h2>
                      <p>FIND_USEFUL_TRADE_TOOLS_AND_RESOURCES</p> </div>

                  </div>
                </div>
                <div className="dotbg l2 dotb" />
              </div> */}
              {/*help */}
            </div>

          )}
        </div>
      </CustomizedDocumentTitle>
    );
  }
}

PageLearn.propTypes = {
  showPreloader: PropTypes.bool,
};

export default connect(mapStateToProps)(PageLearn);
