import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import Preloader from '../components/Preloader';
import languages from '../helpers/languages';

var language = languages['en'];
var common = language.common[0];
var dashboard = language.dashboard[0];

const mapStateToProps = (state) => {
  return {
    lan: state.language.lan,
    user: state.auth.user,
  };
};

class Community extends React.Component {
  state = {
    isModalOpened: false
  };

  componentDidMount() {

  }

  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    const { showPreloader } = this.props;
    const {
      isModalOpened,
    } = this.state;
    const sliderSettings = {
      arrows: true,
      dots: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 1000,
      cssEase: "linear",
    };
    return (
      <CustomizedDocumentTitle title={common.MY_DASHBOARD}>
        <div className='transition-page'>
          {showPreloader ? (
            <Preloader size='medium' relative />
          ) : (
            <div>
              {/*header content */}
              <header>
                <div className="indexvideoheader">
                  <div className="headeroverlay">
                    <h1>A community <br /> to empower women entrepreneurs</h1></div>
                  <video playsInline autoPlay muted loop poster="videoheaderpreview2.jpg">
                    <source src="images/mp4/Community2_2.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </header>
              {/*header content */}
              {/*free block */}
              <div className="bgdark">
                <div className="wrap">
                  <div className="blueblockbgcircles">
                    <div className="container-2col">
                      <div>
                        <h2>Become one of us</h2>
                        <p>Join us and find here like-minded women, professionals, buyers, investors and more! </p></div>
                      <Link to="/sign-up" className="btn">Register or Login</Link>
                    </div>
                  </div>
                </div>
              </div>
              {/*free block */}
              {/*members */}
              <div className="narrowblueblock">
                <div className="memberthumbs"><img src="images/memberpic1.jpg" srcSet="images/memberpic1.jpg 1x, images/memberpic1@2x.jpg 2x, images/memberpic1@3x.jpg 3x, images/memberpic1@4x.jpg 4x" alt =""/><img src="images/memberpic2.jpg" srcSet="images/memberpic2.jpg 1x, images/memberpic2@2x.jpg 2x, images/memberpic2@3x.jpg 3x, images/memberpic2@4x.jpg 4x" alt =""/><img src="images/memberpic3.jpg" srcSet="images/memberpic3.jpg 1x, images/memberpic3@2x.jpg 2x, images/memberpic3@3x.jpg 3x, images/memberpic3@4x.jpg 4x" alt =""/>
                  <p>Charity, Priya and Thao and 340 other persons are also members</p></div>
              </div>
              {/*members */}
              {/*feature cards */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container-4col">
                    <div className="indexfeaturecard"><span className="bigicon bigicon-events" />
                      <h4><Link to="/publicevents">Event &amp; activities<i className="fal fa-arrow-right" /></Link></h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris at nunc.</p>
                    </div>
                    <div className="indexfeaturecard"><span className="bigicon bigicon-network" />
                      <h4><Link to="/publicforums">Groups<i className="fal fa-arrow-right" /></Link></h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris at nunc.</p>
                    </div>
                    <div className="indexfeaturecard"><span className="bigicon bigicon-hubs" />
                      <h4><Link to="/publichubs">Hubs<i className="fal fa-arrow-right" /></Link></h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris at nunc.</p>
                    </div>
                    <div className="indexfeaturecard"><span className="bigicon bigicon-projects" />
                      <h4><Link to="/publicprojects">Projects<i className="fal fa-arrow-right" /></Link></h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris at nunc.</p>
                    </div>
                  </div>
                </div>
              </div>
              {/*feature cards */}
              {/*Meet the community */}
              <div className="bgwhite">
                <div className="wrap">
                  <h2>Meet the community</h2>
                  <div className="container-4col meetcommunity">
                    <div>
                      <img src="images/meetmember01.jpg" srcSet="images/meetmember01.jpg 1x, images/meetmember01@2x.jpg 2x, images/meetmember01@3x.jpg 3x, images/meetmember01@4x.jpg 4x" alt =""className="roundpic" />
                      <p>Queen Honey, a story of resilience and creativity</p>
                      <Link to="/news">Read more <i className="fal fa-arrow-right" /></Link>
                    </div>
                    <div>
                      <img src="images/meetmember02.jpg" srcSet="images/meetmember02.jpg 1x, images/meetmember02@2x.jpg 2x, images/meetmember02@3x.jpg 3x, images/meetmember02@4x.jpg 4x" alt =""className="roundpic" />
                      <p>Priya and the trade women of Chittagong</p>
                      <Link to="/publicsuccess">Read more <i className="fal fa-arrow-right" /></Link>
                    </div>
                    <div>
                      <img src="images/meetmember03.jpg" srcSet="images/meetmember03.jpg 1x, images/meetmember03@2x.jpg 2x, images/meetmember03@3x.jpg 3x, images/meetmember03@4x.jpg 4x" alt =""className="roundpic" />
                      <p>The everyday of a female bus driver in Zambia</p>
                      <Link to="/publicvideos">Read more <i className="fal fa-arrow-right" /></Link>
                    </div>
                    <div>
                      <img src="images/meetmember04.jpg" srcSet="images/meetmember04.jpg 1x, images/meetmember04@2x.jpg 2x, images/meetmember04@3x.jpg 3x, images/meetmember01@4x.jpg 4x" alt =""className="roundpic" />
                      <p>Daiyu Zhang: Sourcing the finest teas </p>
                      <Link to="/publicpublication">Read more <i className="fal fa-arrow-right" /></Link>
                    </div>
                  </div>
                </div>
              </div>
              {/*Meet the community */}
              {/*Events & Activities */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container-2col">
                    <div>
                      <h2>Events &amp; Activities</h2>
                      <p>Meet women online or at local events.<br />
                        Some of the stuff you can find in our calendar</p>
                    </div>
                    <div><Link to="/publicevents" className="btn">go to calendar</Link></div>
                  </div>
                </div>
              </div>
              {/*Events & Activities */}
              {/*Events & Activities */}
              <div className="bgwhite">
                <div className="wrap">
                  <div className="container-2col">
                    <div>
                      <h2>Opportunities for our members</h2>
                      <p>Our opportunities board features all the great things you can access blabla in patnership with our partners</p>
                    </div>
                    <div><Link to="/publicopportunities" className="btn">Opportunities board</Link></div>
                  </div>
                </div>
                <div className="partnerscontainer">
                  {/* <Slider className="slider-new" {...sliderSettings}>
                      { partnerData.map(el => (
                            <div>
                            <Link to={`/publicpartner/${el.id}`}>
                              <Image src={el.image} style={{ width: '150px', height: '100px' }} /></Link>
                            </div>
                      ))} 
                      </Slider> */}
                  <Slider className="slider-new" {...sliderSettings}>
                    <Link to={`/publicpartner/1`}><img src="images/partners_and_founders/DFIDlogo.png" srcSet="images/partners_and_founders/DFIDlogo.png 1x, images/partners_and_founders/DFIDlogo@2x.png 2x, images/partners_and_founders/DFIDlogo@3x.png 3x, images/partners_and_founders/DFIDlogo@4x.png 4x" alt =""/></Link>
                    <Link to={`/publicpartner/1`}><img src="images/partners_and_founders/KOICALogo.png" srcSet="images/partners_and_founders/KOICALogo.png 1x, images/partners_and_founders/KOICALogo@2x.png 2x, images/partners_and_founders/KOICALogo@3x.png 3x, images/partners_and_founders/KOICALogo@4x.png 4x" alt =""/></Link>
                    <Link to={`/publicpartner/1`}><img src="images/partners_and_founders/EUlogo.png" srcSet="images/partners_and_founders/EUlogo.png 1x, images/partners_and_founders/EUlogo@2x.png 2x, images/partners_and_founders/EUlogo@3x.png 3x, images/partners_and_founders/EUlogo@4x.png 4x" alt =""/><b>Funded by<br />the European Union</b></Link>
                    <Link to={`/publicpartner/1`}><img src="images/partners_and_founders/GIZ-Logo.png" srcSet="images/partners_and_founders/GIZ-Logo.png 1x, images/partners_and_founders/GIZ-Logo@2x.png 2x, images/partners_and_founders/GIZ-Logo@3x.png 3x, images/partners_and_founders/GIZ-Logo@4x.png 4x" alt =""/></Link>
                    <Link to={`/publicpartner/1`}><img src="images/partners_and_founders/GermanCooperation.png" srcSet="images/partners_and_founders/GermanCooperation.png 1x, images/partners_and_founders/GermanCooperation@2x.png 2x, images/partners_and_founders/GermanCooperation@3x.png 3x, images/partners_and_founders/GermanCooperation@4x.png 4x" alt =""/></Link>
                    <Link to={`/publicpartner/1`}><img src="images/partners_and_founders/UPSLogo.png" srcSet="images/partners_and_founders/UPSLogo.png 1x, images/partners_and_founders/UPSLogo@2x.png 2x, images/partners_and_founders/UPSLogo@3x.png 3x, images/partners_and_founders/UPSLogo@4x.png 4x" alt =""/></Link>
                    <Link to={`/publicpartner/1`}><img src="images/partners_and_founders/EIFLogo.png" srcSet="images/partners_and_founders/EIFLogo.png 1x, images/partners_and_founders/EIFLogo@2x.png 2x, images/partners_and_founders/EIFLogo@3x.png 3x, images/partners_and_founders/EIFLogo@4x.png 4x" alt =""/></Link>
                    <Link to={`/publicpartner/1`}><img src="images/partners_and_founders/MaryKay.png" srcSet="images/partners_and_founders/MaryKay.png 1x, images/partners_and_founders/MaryKay@2x.png 2x, images/partners_and_founders/MaryKay@3x.png 3x, images/partners_and_founders/MaryKay@4x.png 4x" alt =""/></Link>
                    <Link to={`/publicpartner/1`}><img src="images/partners_and_founders/ITFCLogo.png" srcSet="images/partners_and_founders/ITFCLogo.png 1x, images/partners_and_founders/ITFCLogo@2x.png 2x, images/partners_and_founders/ITFCLogo@3x.png 3x, images/partners_and_founders/ITFCLogo@4x.png 4x" alt =""/></Link>
                    <Link to={`/publicpartner/1`}><img src="images/partners_and_founders/OPECFundLogo.png" srcSet="images/partners_and_founders/OPECFundLogo.png 1x, images/partners_and_founders/OPECFundLogo@2x.png 2x, images/partners_and_founders/OPECFundLogo@3x.png 3x, images/partners_and_founders/OPECFundLogo@4x.png 4x" alt =""/></Link>
                    <Link to={`/publicpartner/1`}><img src="images/partners_and_founders/MFASweden.png" srcSet="images/partners_and_founders/MFASweden.png 1x, images/partners_and_founders/MFASweden@2x.png 2x, images/partners_and_founders/MFASweden@3x.png 3x, images/partners_and_founders/MFASweden@4x.png 4x" alt =""/></Link>
                    <Link to={`/publicpartner/1`}><img src="images/partners_and_founders/MFAFinland.png" srcSet="images/partners_and_founders/MFAFinland.png 1x, images/partners_and_founders/MFAFinland@2x.png 2x, images/partners_and_founders/MFAFinland@3x.png 3x, images/partners_and_founders/MFAFinland@4x.png 4x" alt =""/></Link>
                    <Link to={`/publicpartner/1`}><img src="images/partners_and_founders/MFANetherlandslogo.png" srcSet="images/partners_and_founders/MFANetherlandslogo.png 1x, images/partners_and_founders/MFANetherlandslogo@2x.png 2x, images/partners_and_founders/MFANetherlandslogo@3x.png 3x, images/partners_and_founders/MFANetherlandslogo@4x.png 4x" alt =""/></Link>
                    <Link to={`/publicpartner/1`}><img src="images/partners_and_founders/MFAIcelandlogo.png" srcSet="images/partners_and_founders/MFAIcelandlogo.png 1x, images/partners_and_founders/MFAIcelandlogo@2x.png 2x, images/partners_and_founders/MFAIcelandlogo@3x.png 3x, images/partners_and_founders/MFAIcelandlogo@4x.png 4x" alt =""/></Link>
                    <Link to={`/publicpartner/1`}><img src="images/partners_and_founders/NorwegianMFA.png" srcSet="images/partners_and_founders/NorwegianMFA.png 1x, images/partners_and_founders/NorwegianMFA@2x.png 2x, images/partners_and_founders/NorwegianMFA@3x.png 3x, images/partners_and_founders/NorwegianMFA@4x.png 4x" alt =""/></Link>
                    <Link to={`/publicpartner/1`}><img src="images/partners_and_founders/PeopleofJapan.png" srcSet="images/partners_and_founders/PeopleofJapan.png 1x, images/partners_and_founders/PeopleofJapan@2x.png 2x, images/partners_and_founders/PeopleofJapan@3x.png 3x, images/partners_and_founders/PeopleofJapan@4x.png 4x" alt =""/></Link>
                  </Slider>
                </div>
              </div>
              {/*Events & Activities */}
              {/* social subscribe  */}
              <div className="wrap subscribe">
                <h2>The more, the merrier</h2>
                <div className="bgsubscribe">
                  <div>
                    <p>Invite friends ans colleagues to join SheTrades community. Let’s thrive together.</p>
                    <form action="/" method="post" className="subscribe_form">
                      <input name="subscribe_form" defaultValue required type="text" placeholder="Enter email…" id="subscribe_form" />
                      <button name="subscribe_form" value="search" type="submit">subscribe</button>
                    </form>
                  </div>
                  <div className="subscribesocial">
                    <p>Or share SheTrades.com on your favorite social media platform!</p>
                    <div className="socialmediaicons">
                      <Link to="/youtube"><i className="fab fa-youtube-square" /></Link>
                      <Link to="/facebook"><i className="fab fa-facebook-square" /></Link>
                      <Link to="/twitter"><i className="fab fa-twitter-square" /></Link>
                      <Link to="/linkedin"><i className="fab fa-linkedin" /></Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* social subscribe  */}
            </div>

          )}
        </div>
      </CustomizedDocumentTitle>
    );
  }
}

Community.propTypes = {
  showPreloader: PropTypes.bool,
};

export default connect(mapStateToProps)(Community);
