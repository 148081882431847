import { PUBLICATIONS, PUBLICATION_DETAILS } from '../store/actions';

const initialState = {};

export default function publications(state = initialState, action) {
  switch (action.type) {
    case PUBLICATIONS:
      return {
        ...state,
        publications: action.payload.data,
        totalItems: action.payload.count,
        sortLabel: action.payload.sortLabel,
      };
    case PUBLICATION_DETAILS:
      return {
        ...state,
        publicationDetails: action.payload.data,
      };
    default:
      return state;
  }
}
