import React from 'react';
import DocumentTitle from 'react-document-title';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const CustomizedDocumentTitle = ({ title, t, children }) => {
  if (!title) {
    return children;
  }
  return (
    <DocumentTitle title={`${title} - ${t.common[0].GO_TRADE}`}>
      {children}
    </DocumentTitle>
  );
};

CustomizedDocumentTitle.propTypes = {
  title: PropTypes.string,
  children: PropTypes.element,
  t: PropTypes.object,
};

const mapStateToProps = (state) => ({ t: state.language.t });

export default connect(mapStateToProps)(CustomizedDocumentTitle);
