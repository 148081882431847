import { combineReducers } from 'redux';
import dashboard from './../reducers/dashboard';
import search from './../reducers/search';
import registration from './../reducers/registration';
import members from './../reducers/members';
import verifiers from './../reducers/verifiers';
import network from './../reducers/network';
import tenders from './../reducers/tenders';
import offers from './../reducers/offers';
import forum from './../reducers/forum';
import learn from './../reducers/learn';
import business from './../reducers/business';
import defaultLoad from './../reducers/default';
import events from './../reducers/events';
import news from './../reducers/news';
import videos from './../reducers/videos';
import publications from './../reducers/publications';
import successStory from './../reducers/successStory';
import inviteFriend from './../reducers/InviteFriend';
import myfavorites from './../reducers/myfavorites';
import setting from './../reducers/setting';
import coaches from './../reducers/coaches';
import staticPages from './../reducers/staticPages';
import messenger from './../reducers/messenger';
import searchResult from './../reducers/searchResult';
import commitments from './../reducers/commitments';
import abuse from './../reducers/abuse';
import notifications from './../reducers/notifications';
import responsive from './../reducers/responsive';
import webinars from './../reducers/webinars';
import language from './../reducers/language';
import previewError from './../reducers/preview';
import socialMissingInfo from './../reducers/socialMissingInfo';
import invest from './../reducers/invest';
// import google from './../reducers/google'

import auth from './../reducers/auth';
import flashMessages from './../reducers/flash-messages';
import projectpages from './../reducers/projectpages';
import hubpages from './../reducers/hubpages';

export default combineReducers({
  dashboard,
  search,
  registration,
  members,
  events,
  verifiers,
  network,
  tenders,
  offers,
  business,
  defaultLoad,
  news,
  forum,
  learn,
  videos,
  publications,
  successStory,
  inviteFriend,
  myfavorites,
  setting,
  coaches,
  staticPages,
  searchResult,
  messenger,
  commitments,
  abuse,
  notifications,
  responsive,
  webinars,
  language,
  auth,
  flashMessages,
  previewError,
  socialMissingInfo,
  invest,
  projectpages,
  hubpages
});
