import React from 'react';
import PropTypes from 'prop-types';

const Region = ({ flag, name }) => {
  if (!name) {
    return null;
  }
  return (
    <span className='region'>
      <span className='region__flag'>
        {flag ? <img src={flag} alt='' /> : <i className='icon-tag' />}
      </span>
      <span className='region__name'>{name}</span>
    </span>
  );
};

Region.propTypes = {
  flag: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Region.defaultProps = {
  flag: '',
};

export default Region;
