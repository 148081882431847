import React from 'react';
import { connect } from 'react-redux';
import MessengerBody from './components/MessengerBody';
import MessengerTabs from './components/MessengerTabs';
import MobileMessenger from './components/Mobile';
import languages from '../../helpers/languages';

var language = languages['en'];
var messages = language.messages[0];

const mapStateToProps = (state) => ({
  isPhone: state.responsive.isPhone,
  userRole: state.auth.user.userRole,
  lan: state.language.lan,
});

const PageMessenger = ({ isPhone, lan }) => {
  language = languages[lan];
  messages = language.messages[0];
  if (isPhone) {
    return <MobileMessenger />;
  }

  return (
    <div className='transition-page'>
      <div className='innerheader innerheader-messages'>
        <h1>{messages.MY_MESSAGES}</h1>
      </div>
      <div className='row'>
        <div className='col-4'>
          <MessengerTabs />
        </div>

        <div className='col-8'>
          <MessengerBody />
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(PageMessenger);
