import axios from 'axios';
const CONTACT_LIST_URL = '/rest/ws/user/filter';

export const fetchChats = async (applozicApi) => {
  const [users, groups] = await Promise.all([
    fetchContacts(applozicApi),
    fetchGroups(applozicApi),
  ]);
  return [
    ...users.filter(
      ({ id }) =>
        !groups.reduce((acc, curr) => acc || String(curr.id) === id, false)
    ),
    ...groups.map((el) => ({
      ...el,
      unread: el.unreadCount,
      time: el.lastMessageAtTime || el.createdAtTime,
      isGroup: true,
    })),
  ].sort((a, b) => Math.sign(b.time - a.time));
};

export const fetchContacts = (applozicApi) => {
  const { ALApiService } = applozicApi;
  return new Promise((resolve, reject) => {
    ALApiService.getContactList({
      url: CONTACT_LIST_URL,
      success: ({ users }) => {
        axios.get('/contactList').then((res) => {
          var data = res.data.data;
          var contacts = data.contacts;
          var userContacts = contacts.map((a) => a.actualUserId);
          for (var obj in users) {
            var checkPresent = inArray(users[obj].userId, userContacts);
            if (checkPresent === false) {
              delete users[obj];
            }
          }

          resolve(
            users.map(
              ({
                userId,
                displayName,
                unreadCount,
                createdAtTime,
                lastMessageAtTime,
                connected,
              }) => ({
                id: userId,
                online: connected,
                name: displayName,
                time: lastMessageAtTime || createdAtTime,
                unread: unreadCount,
              })
            )
          );
        });
      },
      error: () => reject(),
    });
  });
};

function inArray(needle, haystack) {
  var length = haystack.length;
  for (var i = 0; i < length; i++) {
    if (haystack[i] === needle) return true;
  }
  return false;
}

export const fetchGroups = (applozicApi) => {
  const { ALApiService } = applozicApi;
  return new Promise((resolve, reject) => {
    ALApiService.loadGroups({
      success: ({ response }) => {
        var applozicGroups = response;
        axios.get('/contactList').then((res) => {
          var data = res.data.data;
          var contacts = data.groups;
          var userContacts = contacts.map((a) => a.id);
          for (var obj in applozicGroups) {
            var checkPresent = inArray(response[obj].id, userContacts);
            if (checkPresent === false) {
              delete applozicGroups[obj];
            }
          }
        });
        resolve(response);
      },
      error: () => reject(),
    });
  });
};

export function getChatGroups(applozicApi = null) {
  return new Promise((resolve, reject) => {
    axios
      .get('/userGroups')
      .then((response) => resolve(response.data.data))
      .catch(function (error) {
        reject();
      });
  });
}

export const fetchNotifications = () => {
  return new Promise((resolve) => setTimeout(() => resolve([]), 1000));
};

export const createGroup = (applozicApi, { name, members }) => {
  const { ALApiService } = applozicApi;
  return new Promise((resolve, reject) => {
    ALApiService.createGroup({
      data: {
        group: {
          groupName: name,
          users: members.map((userId) => ({ userId })),
          type: 2,
        },
      },
      success: (response) => resolve(response),
      error: (response) => reject(response),
    });
  });
};

export const removeGroupMember = (applozicApi, userId, groupId) => {
  const { ALApiService } = applozicApi;
  return new Promise((resolve, reject) => {
    ALApiService.removeGroupMember({
      data: {
        userId: userId,
        clientGroupId: groupId,
      },
      success: (response) => resolve(response),
      error: (response) => reject(response),
    });
  });
};

export function fetchBellNotification(applozicApi = null) {
  return new Promise((resolve, reject) => {
    axios
      .get('/fetchBellNotifications')
      .then((response) => resolve(response.data.data))
      .catch(function (error) {
        reject();
      });
  });
}

export const getUser = ({ applozicApi, userIdList }) =>
  new Promise((resolve, reject) => {
    const { ALApiService } = applozicApi;
    ALApiService.getUserDetail({
      data: { userIdList },
      success: (result) => resolve(result),
      error: (result) => reject(result),
    });
  });
