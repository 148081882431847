// import { TweenMax } from 'gsap';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Chart from '../../components/Chart';
import Comments from '../../components/Comments';
import GalleryFullscreen from '../../components/Gallery/components/Fullscreen';
import Tag from '../../components/Tag';
import Toolbar from '../../components/Toolbar';
import TopMessage from '../../components/TopMessage';
import { pageTransition } from '../../constants';
import languages from '../../helpers/languages';
import noAvatarImage from '../../static/images/noavatar.svg';
import MemberBottomText from './components/BottomText';
import MemberButtons from './components/Buttons/';
import DealLink from './components/DealLink';
import ListWithFilters from './components/ListWithFilters';
import MemberRating from './components/Rating';

//gsap.registerPlugin(ScrollToPlugin)

var language = languages['en'];
var common = language.common[0];
var detailMembers = language.detail_members[0];

// export const scrollTo = (y = 0) => {
//   TweenMax.to(window, 0.4, { scrollTo: { y, autoKill: false } });
// };

// export function getScrollXY() {
//   var scrOfX = 0,
//     scrOfY = 0;
//   if (typeof window.pageYOffset === 'number') {
//     //Netscape compliant
//     scrOfY = window.pageYOffset;
//     scrOfX = window.pageXOffset;
//   } else if (
//     document.body &&
//     (document.body.scrollLeft || document.body.scrollTop)
//   ) {
//     //DOM compliant
//     scrOfY = document.body.scrollTop;
//     scrOfX = document.body.scrollLeft;
//   } else if (
//     document.documentElement &&
//     (document.documentElement.scrollLeft || document.documentElement.scrollTop)
//   ) {
//     //IE6 standards compliant mode
//     scrOfY = document.documentElement.scrollTop;
//     scrOfX = document.documentElement.scrollLeft;
//   }
//   return [scrOfX, scrOfY];
// }

const mapStateToProps = (state) => ({
  lan: state.language.lan
});

const Member = (props) => {
  language = languages[props.lan];
  common = language.common[0];
  detailMembers = language.detail_members[0];
  const {
    name,
    description,
    avatar,
    region,
    rating,
    isVerified,
    isVerifier,
    isMentor,
    isInMyNetwork,
    type,
    verifiers,
    content,
    address,
    email,
    telephone,
    social = {},
    certifications,
    customers,
    exportCountries,
    offering,
    looking,
    tenders = [],
    offers = [],
    gallery = [],
    reviews,
    location,
    employees,
    femaleEmployees,
    reviewsCount,
    headedByAWoman,
    established,
    expericence,
    salesAnnualValue,
    exportAnnualValue,
    noAvatarImage,
    addContactFunction,
    pageType,
    id,
    updateContactFunction,
    verificationRequested,
    verifyInsteadOfAdd = null,
    requestedBy,
    totalReviews,
    fetchReviewsFunction,
    addReviewFunction,
    coaching = null,
    mentorRequested,
    ownedByAWoman,
    currentUser,
  } = props;

  const queryObject = queryString.parse(location.search);
  const tabIsDefault = !queryObject.tab || queryObject.tab === 'about';
  const hasDeals = tenders.length > 0 || offers.length > 0;
  const hasProductsAndServices = offering.length > 0 || looking.length > 0;

  const toolbar = {
    tabs: {
      values: {
        about: { text: detailMembers.ABOUT },
      },
    },
  };

  if (hasDeals) {
    toolbar.tabs.values.deals = { text: detailMembers.ACTIVE_DEALS };
  }

  if (gallery.length) {
    toolbar.tabs.values.gallery = { text: detailMembers.GALLERY };
  }

  if (reviews.length) {
    toolbar.tabs.values.reviews = {
      text: `${common.RECOMMENDATIONS} (${reviewsCount})`,
    };
  }

  return (
    <div className="bginnergrey">
      <div className="wrap">
        <div className="container-2col">
          <div>

            <div className="profileheader">
              <div>
                <picture>
                  <img
                    src={avatar ? avatar : noAvatarImage}
                    alt=''
                  />
                </picture>
              </div>
              <div className="profileheadercontainer">
                <div>
                  <h2>
                    {name}
                    <p className='member__tags'>
                      {isMentor && <Tag type='default' text='COACH' />}

                      {isVerifier && <Tag type='success' text='Verifier' />}

                      {isVerified && (
                        <i className='icon-verified'>
                          <span className='path1' />
                          <span className='path2' />
                        </i>
                      )}

                      {verifiers && (
                        <span className='gray' style={{ display: 'inline-block' }}>
                          {common.VERIFIED_BY} {verifiers}
                        </span>
                      )}
                    </p>
                  </h2>
                  <p className="buyerlocation">
                    <MemberBottomText
                      {...{
                        region,
                        type,
                        employees: employees,
                        femaleEmployees: ownedByAWoman,
                      }}
                    />
                    {address && (
                      <div className='member-contact'>
                        <p>
                          <b>{common.ADDRESS}:</b>
                        </p>
                        <address className='gray'>{address}</address>
                      </div>
                    )}

                    {telephone && (
                      <div className='member-contact'>
                        <p>
                          <b>{common.TELEPHONE}:</b>
                        </p>
                        <p className='gray'>
                          <a href={`tel:${telephone}`}>{telephone}</a>
                        </p>
                      </div>
                    )}
                  </p>
                </div>
                <div className="profilecontrols">
                  {Object.keys(social).map(
                    (key, i) =>
                      social[key] && (
                        <a key={key} href={social[key]} target='_blank' rel="noreferrer">
                          <i className={`icon-${key}`} />
                        </a>
                      )
                  )}
                  {/* <a href={`mailto:${email}`}>
                    <span className="atitle">Send a message to {name}</span>
                    <i className="fas fa-comment-alt-lines" />
                  </a> */}
                </div>
              </div>
            </div>

            <div className="rowcontainer">
              <div className="bslinktitle">
                <h3>About us</h3>{" "}
              </div>
              <div className="container-2even">
                <div>
                  <p className="buyerlocation">
                    <i className="fa-solid fa-flag-pennant" /> Established in  {established ? established : 'NA'}
                    <br />
                    <i className="fa-solid fa-users" /> {Number(employees)} employees
                  </p>
                </div>
                <p className="container-ll">
                  <i className="fa-solid fa-person-dress-simple" />
                  <span>Headed by a  {headedByAWoman ? 'Woman' : 'Man'}  {ownedByAWoman > 0
                    ? '(' + ownedByAWoman + '% Owned By Women)'
                    : ''} </span>
                </p>
              </div>
              <hr />
              <div>
                {description && (
                  <p>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                  </p>
                )}
                {content && (
                  <p>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                  </p>
                )}
              </div>
            </div>
            <div className="rowcontainer">

              <TransitionGroup className='transition-container'>
                <CSSTransition
                  key={queryObject.tab}
                  {...pageTransition}
                  onExit={() => {
                    const toolbar = document.querySelector('.member .toolbar');
                    const header = document.querySelector('.header');
                    const entering = document.querySelector('.page-enter');
                    const leaving = document.querySelector('.page-leave');
                    const y = Math.min(
                      toolbar.offsetTop - header.clientHeight - 15,
                      document.documentElement.scrollHeight -
                      Math.max(leaving.scrollHeight - entering.scrollHeight, 0) -
                      window.innerHeight
                    );
                    // scrollTo(y);
                  }}
                >
                  <div className='transition-page'>
                    {tabIsDefault && (
                      <Fragment>
                        <section className='detail'>
                          <div className='hr' />

                          <div className='detail__left'>
                            <div className='content'>
                              <p>
                                <b>{detailMembers.HEADED_BY_WOMEN}:</b>{' '}
                                {headedByAWoman ? 'Yes' : 'No'}
                              </p>
                              <p>
                                <b>{detailMembers.NUMBER_OF_EMPLOYEE}:</b> {employees}
                                {ownedByAWoman > 0
                                  ? '(' + ownedByAWoman + '% Owned By Women)'
                                  : ''}
                              </p>
                              <p>
                                <b>{detailMembers.FEMALE_EMPLOYEE}:</b>{' '}
                                {femaleEmployees > 0 ? femaleEmployees : 0}
                              </p>
                              <p>
                                <b>{detailMembers.ESTABLISHED}:</b>{' '}
                                {established ? established : 'NA'}
                              </p>
                              <p>
                                <b>{common.EXPORTING_EXPERIENCE}:</b>{' '}
                                {expericence > 0 ? expericence : 'NA'}
                              </p>
                            </div>
                          </div>

                          <div className='detail__right'>
                            {!!certifications.length && (
                              <Fragment>
                                <p>
                                  <b>{detailMembers.CERTIFICATION}: </b>
                                </p>
                                <p className='tags'>
                                  {certifications.map((el, i) => (
                                    <Tag key={i} type='default' text={el} />
                                  ))}
                                </p>
                              </Fragment>
                            )}
                          </div>

                          <div className='detail__whole'>
                            <div className='charts row'>
                              <div className='col-sm-6'>
                                <Chart
                                  title={detailMembers.ANNUAL_SALES_VALUE}
                                  number={Number(salesAnnualValue)}
                                  valuePrefix={'$'}
                                  valuePostfix='±'
                                />
                              </div>
                              <div className='col-sm-6'>
                                <Chart
                                  title={detailMembers.ANNUAL_EXPORT_VALUE}
                                  number={Number(exportAnnualValue)}
                                  valuePostfix='±'
                                  valuePrefix={'$'}
                                />
                              </div>
                              <div className='col-sm-6'>
                                <Chart
                                  title={detailMembers.FEMALE_EMPLOYEE}
                                  number={Number(femaleEmployees)}
                                />
                              </div>
                              <div className='col-sm-6'>
                                <Chart
                                  title={detailMembers.NUMBER_OF_EMPLOYEE}
                                  number={Number(employees)}
                                />
                              </div>
                            </div>
                          </div>

                          <div className='detail__whole'>
                            {!!customers.length && (
                              <p className='tags'>
                                <b>{detailMembers.OUR_PRIMARY_CUSTOMER}: </b>
                                {customers.map((el, i) => (
                                  <Tag key={i} type='default' text={el} />
                                ))}
                              </p>
                            )}
                          </div>

                          <div className='hr' />

                          <div className='detail__whole'>
                            {exportCountries && (
                              <p className='tags'>
                                <b>{detailMembers.EXPORT_WITH_COUNTRIES}: </b>{' '}
                                {exportCountries && (
                                  <Tag
                                    type='default'
                                    text={`${exportCountries.join(', ')} `}
                                  >
                                    <i className='icon-tooltip'>
                                      <span className='path1' />
                                      <span className='path2' />
                                    </i>
                                  </Tag>
                                )}
                              </p>
                            )}
                          </div>
                        </section>

                        {hasProductsAndServices && (
                          <section className='detail'>
                            <div className='detail__whole'>
                              <h3>{detailMembers.PRODUCT_SERVICE}</h3>

                              {offering.length > 0 && (
                                <div className='member-product-section'>
                                  <p>
                                    <b>{detailMembers.OFFERING}:</b>
                                  </p>

                                  <ListWithFilters
                                    list={offering}
                                    tabs={{
                                      product: common.PRODUCT,
                                      service: common.SERVICE,
                                    }}
                                  />
                                </div>
                              )}

                              {looking.length > 0 && (
                                <Fragment>
                                  <div className='hr' />

                                  <div className='member-product-section'>
                                    <p>
                                      <b>{common.WE_ARE_LOOKING_FOR}:</b>
                                    </p>

                                    <ListWithFilters
                                      list={looking}
                                      tabs={{
                                        product: common.PRODUCT,
                                        service: common.SERVICE,
                                      }}
                                    />
                                  </div>
                                </Fragment>
                              )}
                            </div>
                          </section>
                        )}
                        <TopMessage
                          isShown={props.topMessageStatus}
                          onCloseClick={() => props.memberMessageClose(false)}
                          type='success'
                          text={common.YOU_HAVE_SUCCESSFULLY_APPLIED}
                        />
                      </Fragment>
                    )}

                    {(tabIsDefault || queryObject.tab === 'deals') && hasDeals && (
                      <section className='detail'>
                        <div className='detail__whole'>
                          <div className='member-tab-title'>
                            <h3>{detailMembers.ACTIVE_DEALS}</h3>
                          </div>

                          <div className='row'>
                            {tenders.length > 0 && (
                              <div className='col-sm-6'>
                                <p className='gray'>{common.TENDERS}:</p>
                                <div className='download-links'>
                                  {tenders.map((el, i) => (
                                    <DealLink
                                      key={i}
                                      to={`/tenders/${el.id}`}
                                      {...el}
                                    />
                                  ))}
                                </div>
                              </div>
                            )}

                            {offers.length > 0 && (
                              <div className='col-sm-6'>
                                <p className='gray'>{common.OFFERS}:</p>
                                <div className='download-links'>
                                  {offers.map((el, i) => (
                                    <DealLink key={i} to={`/offers/${el.id}`} {...el} />
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </section>
                    )}

                    {(tabIsDefault || queryObject.tab === 'gallery') &&
                      gallery.length > 0 && (
                        <section className='detail'>
                          <div className='detail__whole'>
                            <div className='member-tab-title'>
                              <h3>{detailMembers.GALLERY}</h3>
                            </div>

                            <div className='member-gallery'>
                              <GalleryFullscreen
                                slides={gallery}
                                getSlickOverrides={({ isMobile, isPhone }) => ({
                                  slidesToShow: isPhone ? 1 : isMobile ? 2 : 4,
                                })}
                              />
                            </div>
                          </div>
                        </section>
                      )}

                    {(tabIsDefault || queryObject.tab === 'reviews') && (
                      <Comments
                        commentable
                        title={common.RECOMMENDATIONS}
                        singleTitle='recommendation'
                        comments={reviews}
                        subComments={false}
                        totalReviews={totalReviews}
                        fetchReviews={fetchReviewsFunction}
                        addReview={addReviewFunction}
                        classes='white-comments'
                      />
                    )}
                  </div>
                </CSSTransition>
              </TransitionGroup>


            </div>
            <p className="smalltext"></p>
          </div>
        </div>
      </div>
      <div className="dotbg l2 dotb" />
    </div>
  );
};

Member.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  avatar: PropTypes.string,
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reviews: PropTypes.array,
  isInMyNetwork: PropTypes.string,
  isVerified: PropTypes.bool,
  isVerifier: PropTypes.bool,
  isMentor: PropTypes.bool,
  region: PropTypes.object,
  type: PropTypes.string,
  location: PropTypes.object,
  verifiers: PropTypes.string,
  content: PropTypes.string,
  questions: PropTypes.object,
  address: PropTypes.string,
  email: PropTypes.string,
  telephone: PropTypes.string,
  social: PropTypes.object,
  certifications: PropTypes.array,
  charts: PropTypes.arrayOf(PropTypes.object),
  customers: PropTypes.arrayOf(PropTypes.string),
  exportCountries: PropTypes.array,
  offering: PropTypes.arrayOf(PropTypes.object),
  looking: PropTypes.arrayOf(PropTypes.object),
  tenders: PropTypes.array,
  offers: PropTypes.array,
  gallery: PropTypes.array,
  employees: PropTypes.number,
  femaleEmployees: PropTypes.number,
  reviewsCount: PropTypes.number,
  headedByAWoman: PropTypes.number,
  established: PropTypes.number,
  expericence: PropTypes.number,
  salesAnnualValue: PropTypes.string,
  exportAnnualValue: PropTypes.string,
  noAvatarImage: PropTypes.string,
  addContactFunction: PropTypes.func,
  pageType: PropTypes.string,
  id: PropTypes.number,
  updateContactFunction: PropTypes.func,
  topMessageStatus: PropTypes.bool,
  memberMessageClose: PropTypes.func,
  verifyInsteadOfAdd: PropTypes.string,
  verificationRequested: PropTypes.string,
  requestedBy: PropTypes.string,
  totalReviews: PropTypes.number,
  fetchReviewsFunction: PropTypes.func,
  addReviewFunction: PropTypes.func,
  coaching: PropTypes.string,
  mentorRequested: PropTypes.string,
  ownedByAWoman: PropTypes.number,
  currentUser: PropTypes.number,
};

Member.defaultProps = {
  name: '',
  description: '',
  avatar: '',
  rating: 0,
  reviews: [],
  isInMyNetwork: null,
  isVerified: false,
  isVerifier: false,
  isMentor: false,
  region: null,
  type: '',
  questions: {},
  tenders: [],
  offers: [],
  customers: [],
  gallery: [],
  exportCountries: [],
  charts: [],
  offering: [],
  looking: [],
  certifications: [],
  social: {},
  verifiers: '',
  content: '',
  address: '',
  email: '',
  telephone: '',
  employees: 0,
  femaleEmployees: 0,
  reviewsCount: 0,
  headedByAWoman: 0,
  established: 0,
  expericence: 0,
  salesAnnualValue: '',
  exportAnnualValue: '',
  noAvatarImage: noAvatarImage,
  addContactFunction: null,
  pageType: null,
  id: null,
  updateContactFunction: null,
  memberMessageClose: null,
  topMessageStatus: null,
  verificationRequested: null,
  verifyInsteadOfAdd: null,
  requestedBy: null,
  totalReviews: 0,
  fetchReviewsFunction: null,
  addReviewFunction: null,
  coaching: null,
  mentorRequested: null,
  ownedByAWoman: 0,
  currentUser: null,
};

export default withRouter(connect(mapStateToProps)(Member));
