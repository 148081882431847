import { Form } from 'informed';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Profile from '../../Profile/';
// import { NavLink, Link } from 'react-router-dom';
import { HashLink as Link, NavHashLink as NavLink } from 'react-router-hash-link';
import languages from '../../../helpers/languages';
import { reset, searchSubmit } from '../../../reducers/search';
import {
  fetchSearchResult
} from '../../../store/actions';
import LangSwitcher from '../../Profile/components/LangSwitcher';

var language = languages['en'];
var common = language.common[0];
var header = language.header[0];
var newmenu = language.NewMenu[0];

const mapStateToProps = (state) => {
  return {
    lan: state.language.lan,
    isMobile: state.responsive.isMobile,
    user: state.auth.user,
  };
};
class ProtectedTopBar extends React.Component {
  static propTypes = {
    fetchSearchResult: PropTypes.func,
    onQueryChange: PropTypes.func,
    onResetClick: PropTypes.func.isRequired,
    onSearchSubmit: PropTypes.func,
    toggleRef: PropTypes.func.isRequired,
    isMobile: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      formApi: null,
      queryInput: '',
    };

    this.clear = this.clear.bind(this);
    this.onQueryInputChange = this.onQueryInputChange.bind(this);
    this.setFormApi = this.setFormApi.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    const queryObject = queryString.parse(decodeURIComponent(location?.search));
    if (
      typeof queryObject.query === 'string' &&
      queryObject.query.length !== 0
    ) {
      this.setState({
        queryInput: queryObject.query,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { isMobile } = this.props;
    if (isMobile) return;
    const { formApi } = this.state;
  }

  clear() {
    const { onResetClick } = this.props;
    onResetClick();
    this.setState({
      queryInput: '',
    });
  }

  onQueryInputChange(e) {
    this.setState({
      queryInput: e.target.value,
    });
  }

  persist(values, searchUrl = null) {
    const { fetchSearchResult, onSearchSubmit } = this.props;
    const { formApi, queryInput } = this.state;
    formApi.setValues(values);
    onSearchSubmit(values);
    if (searchUrl) {
      fetchSearchResult(searchUrl, values, true);
    } else {
      fetchSearchResult(window.location.hash, values, true);
    }
  }

  setFormApi(formApi) {
    this.setState({
      formApi,
    });
  }

  submit() {
    const { history } = this.props;
    const { formApi, queryInput } = this.state;
    const trimmedQuery = encodeURIComponent(queryInput.trim());
    var pageUrl = window.location.hash.split('/');
    var currentTab = '';
    if (pageUrl[1]) {
      pageUrl = pageUrl[1];
      pageUrl = pageUrl.split('?');
      if (pageUrl[0] && pageUrl[0] === 'search') {
        var qParam = queryString.parse(decodeURIComponent(pageUrl[1]));
        if (qParam['tab']) {
          currentTab = 'tab=' + qParam['tab'];
        }
      }
    }
    const formState = formApi.getState();
    if (Object.keys(formState.touched) !== 0 || trimmedQuery.length !== 0) {
      const location = `/search?query=${trimmedQuery}&${currentTab}`;
      this.persist(formState.values, location);
      history.push(location);
    }
  }

  getInitialValues() {
  }

  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    header = language.header[0];
    newmenu = language.NewMenu[0];


    const {
      isMobile,
      toggleRef,
    } = this.props;
    const { queryInput } = this.state;
    const initialValues = this.getInitialValues();
    return (
      <div className="wrapper">
        {/*main nav */}
        <NavLink to="/" className="logo"><img src="images/svg/SheTrades-logo.svg" alt=" " /></NavLink>
        {<Profile></Profile>}
        <hr />
        {/*main nav */}
        <input id="mobilenavswitcher" name="mobilenavswitcher" type="checkbox" />
        <label htmlFor="mobilenavswitcher">
          <i className="far fa-bars" /><i className="fal fa-times" /></label>
        <ul id="top_menu">
          <li className="top_user_panel-buttons">
            <NavLink to='/sign-in' className="login">{newmenu.LOGIN}</NavLink>
            <NavLink to='/sign-up' className="register">{newmenu.REGISTER}</NavLink>
          </li>
          <li><a href="#">{newmenu.THE_INITIATIVE}<i className="fal fa-chevron-down" /></a>
            <ul>
              <li className="bgdark"><span className="dropdownlinks-block">
                <Link to="/aboutus">{newmenu.WHY_SHETRADES}</Link>
                <Link to="/partners">{newmenu.OUR_PARTNERS_AND_SPONSORS}</Link>
                { /* <Link to="/discover" className="red">{newmenu.DISCOVER_OUR_FULL_OFFERING}<i className="fal fa-arrow-right" /></Link> */}
              </span>
              </li>
            </ul>
          </li>
          {/* <li> <Link to="/profilesearch">{newmenu.SHETRADES_NETWORK} </Link></li> */}
          <li><Link to="/learn">{newmenu.LEARN}</Link></li>
          <li className="navcommunity">
            <a href="#">{newmenu.COMMUNITY}<i class="fal fa-chevron-down"></i></a>
            <ul>
              <li class="bgdark firstdropdownblock">
                <span class="dropdownlinks-list">

                  <Link to="/profilesearch">{newmenu.SHETRADES_NETWORK} </Link>
                  {/* <Link to="/projects">{newmenu.SHETRADES_PROJECTS}</Link> */}
                  <Link to="/hubs">{newmenu.SHETRADES_HUB} </Link>
                  <Link to="/partners">{newmenu.MEET_THE_PARTNERS}</Link>
                </span></li>
              {/* removed from issue #202 */}
              { /* <li className="bgdarkblue dropdownbg firstdropdownblock"><h4>
                <Link to="/community">{newmenu.HOW_CAN_I_BE_PART_OF_SHETRADES}<i className="fal fa-arrow-right" /></Link></h4>
                <p>{newmenu.DISCOVER_ALL_THE_WAYS_IN_WHICH}</p> </li>
              <li className="bgdarker seconddropdownblock"><h4>
                <Link to="/blog">{newmenu.MEET_THE_WOMEN_ON_OUR_}<i className="fal fa-arrow-right" /></Link></h4>
              <p>{newmenu.READ_AND_LISTEN_TO_OUR_MEMBERS}</p> </li> */ }
            </ul>
          </li>
          <li><Link to={this.props.lan === 'en' ? "/project/9" : this.props.lan === 'es' ? "/project/28" : "/project/39"}>{newmenu.FINANCE}</Link></li>
          <li><Link to="/project/5">{newmenu.POLICY_DATA} </Link></li>
          <li><a href="#">{newmenu.OPPORTUNITIES_BOARD}<i className="fal fa-chevron-down" /></a>
            <ul>
              <li className="bgdark"><span className="dropdownlinks-block">
                <Link to="/projects">{newmenu.SHETRADES_PROJECTS}</Link>
                <Link to="/events?tab=allevents">{newmenu.EVENTS_ACTIVITIES}</Link>
              </span>
              </li>
            </ul>
          </li>
          <li><Link to="/publictools">{newmenu.TOOLS}</Link></li>
          <li><a href="#">{newmenu.PUBLICATIONS_RESOURCES}<i className="fal fa-chevron-down" /></a>
            <ul>
              <li className="bgdark"><span className="dropdownlinks-block">
                <Link to="/success-stories">{common.SUCCESS_STORIES}</Link>
                <Link to="/video">{newmenu.VIDEOS}</Link>
                <Link to="/publicnews">{newmenu.SHETRADES_NEWS}</Link>
                <Link to="/publications">{newmenu.PUBLICATIONS}</Link>
                { /* <Link to="/helpcenter">{newmenu.RESOURCES}</Link> */}
              </span>
              </li>
            </ul>
          </li>
        </ul>
        <Form
          onSubmit={this.submit}
          initialValues={initialValues}
          getApi={this.setFormApi}
          className="searchform"
          render={({ formState: { touched, values } }) => (
            <Fragment>
              <input id="searchcheckbox" name="searchcheckbox" type="checkbox" />
              <label htmlFor="searchcheckbox"><i className="fal fa-search" /></label>
              <div className="searchformcontainer">
                <input
                  type='text'
                  placeholder={header.HEADER_SEARCH_PLACEHOLDER}
                  className='input-with-icon__input'
                  value={queryInput}
                  onChange={this.onQueryInputChange}
                />
                <button name="searchform" value="search" type="submit">{header.SEARCH}</button>
              </div>
            </Fragment>
          )}
        />

        <LangSwitcher></LangSwitcher>

      </div>
    )
  };
}

const mapDispatchToProps = {
  fetchSearchResult,
  onResetClick: reset,
  onSearchSubmit: searchSubmit,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProtectedTopBar));
