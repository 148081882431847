import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Image } from "react-bootstrap";
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import Preloader from '../components/Preloader';
import languages from '../helpers/languages';
import partnerData from '../data/partners.json';

var language = languages['en'];
var common = language.common[0];
var labels = language.Partner[0];


const mapStateToProps = (state) => {
  return {
    lan: state.language.lan,
    user: state.auth.user,
  };
};

class Partners extends React.Component {
  state = {
    isModalOpened: false
  };

  componentDidMount() {

  }

  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    labels = language.Partner[0];
    const { showPreloader } = this.props;
    const {
      isModalOpened,
    } = this.state;

    const sliderSettings = {
      arrows: true,
      dots: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 1000,
      cssEase: "linear",
    };

    return (
      <CustomizedDocumentTitle title={common.MY_DASHBOARD}>
      <div className='transition-page'>
        {showPreloader ? (
          <Preloader size='medium' relative />
        ) : (
          <div>
            {/*header content */}
            <header>
              <div class="innerheader innerheader-partners_landing">
                <h1>{labels.SHETRADES_PARTNERS}</h1>
              </div>
            </header>
            {/*header content */}
            {/*access */}
            <div className="bggrey">
              <div className="wrap">
                <div className="container">
                  <div>
                    {labels.OUR_PARTNERS_CONTRIBUTE_VALUABLE_}
                  <h3 className="helpquestion">
                 {labels.HAVE_A_QUESTION_SEND_AN_E_MAIL_TO} <a href="mailto:womenandtrade@intracen.org">womenandtrade@intracen.org</a></h3>
                  </div>                       
                  <div />
                </div>
              </div>
            </div>
  <div class="bgdark">
      <div class="wrap">
             <div class="blueblockbgcircles">
                  <div class="container-2col">
           
                      <div>
                          <h2>{labels.WANT_TO_BE_A_PARTNER}</h2> 
                          <p>{labels.WE_WELCOME_APPLICATIONS}</p>
                      </div>
                      <a href="https://www.shetrades.com/application/files/2516/0337/7762/FINAL_SheTrades_Partnerships_Handbook.pdf" class="btn alignbottom">{labels.LEARN_MORE}</a>
                  </div> 
              </div>
       </div>
  </div>     
            {/*access */}
            {/*Meet our partners */}
            {/* <div className="bgwhite">
              <div className="wrap">
                <div className="container-2col">
                  <div><h2>HOW_DO_WE_WORK_TOGETHER</h2>
                    <p>OUR_PARTNERS_ARE_LEADERS_ACROSS_SECTORS_AND_FIELDS_ESSENTIAL</p>
                    {/*<form action="/" method="post" class="form-filter">
            

                    <input name="s" value="" required="" type="text" placeholder="Filter? by sector? by service?" id="" class="input_filter">
                    <button  name="searchform" value="search" type="submit" class="input_btn">search</button>
                    </form>*/}
                  {/* </div>
                  <div />
                </div>
            </div>*/}
                {/* <p className="textbox">CLICK_ON_PARTNER_LOGOS_FROM_A_REEL_BELOW_OR_SELECT_A_SECTOR/p> */}
                    {partnerData.map((el, key) => (
             <div className={key % 2 ? 'bggrey' : 'bgwhite'}>
                  <div className="wrap">
                    <div class="listsection">
                           <section>
                              <div class="container-lr">
                                  <div><p>{el.opportunity_cta ? ( 
                                    <Link to={`/publicpartner/${el.id}`}>{el.title} <i class="fal fa-arrow-right"></i></Link>
                                    ) : (
                                    <a href={el.website} target="_blank">{el.title} <i class="fal fa-arrow-right"></i></a>
                                  )}
                                  <br /><p dangerouslySetInnerHTML={{__html: el.description}} /></p></div>
                                  <div><img src={el.image} alt={el.title} height="150" class="valigncenter" /></div>
                              </div>  
                          </section>
                    </div>
                    </div>
                    </div>
                    ))} 
          </div>
          
        )}
      </div>
    </CustomizedDocumentTitle>
  );
}
}

Partners.propTypes = {
showPreloader: PropTypes.bool,
};

export default connect(mapStateToProps)(Partners);
