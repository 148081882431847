import React, { Fragment } from 'react';
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';


// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => (
  <CustomizedDocumentTitle title={props.title}>
    <Fragment>
    <div id="formpage" className="bg">
        <div className="wrap">
          <div className="container-2col">
            {props.children}
       </div>
      </div>
    </div>
    </Fragment>
  </CustomizedDocumentTitle>
);
