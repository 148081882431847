import React from 'react';
import { Checkbox as CheckboxInGroup, CheckboxGroup } from 'react-checkbox-group';
import { asField } from 'informed';

const CheckboxGroupInput = ({ options, id, fieldApi: { setValue }, fieldState: { value } }) => (
  <CheckboxGroup className="options-group"
                 checkboxDepth={2}
                 value={value}
                 onChange={setValue}
  >
    {options.map(({ label: optionLabel, value: optionValue }, i) => {
      const optionId = `multi-option_${id}__${i}`;

      return (
        <div className="option option_checkbox" key={optionId}>
          <CheckboxInGroup value={optionValue} id={optionId}/>

          <label htmlFor={optionId}>
            <i className="icon-checkbox"/>

            {optionLabel}
          </label>
        </div>
      );
    })}
  </CheckboxGroup>
);

export default asField(CheckboxGroupInput);