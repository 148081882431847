import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Gallery from '../Gallery';
import Modal from '../../../components/Modal';

class GalleryFullscreen extends React.Component {
  static propTypes = {
    getSlickOverrides: PropTypes.func,
    isOpened: PropTypes.bool,
    modalOnly: PropTypes.bool,
    onToggle: PropTypes.func,
    slides: PropTypes.array,
  };

  static defaultProps = {
    getSlickOverrides: () => null,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      fullscreenSlider: null,
      isModalOpened: false,
      regularSlider: null,
    };
    this.connectSliders = this.connectSliders.bind(this);
  }

  async connectSliders() {
    const { modalOnly } = this.props;
    if (modalOnly) return;
    this.setState(
      {
        fullscreenSlider: this.fullscreenSliderElement,
        regularSlider: this.regularSliderElement,
      },
      () => {
        const { fullscreenSlider, currentIndex } = this.state;
        fullscreenSlider.slickGoTo(currentIndex);
      }
    );
  }

  setIsModalOpened(isModalOpened) {
    const { onToggle } = this.props;
    if (onToggle) {
      onToggle(isModalOpened);
    } else {
      this.setState({
        ...this.state,
        isModalOpened,
      });
    }
  }

  onSmallSlideClick(i) {
    this.setState({
      currentIndex: i,
      isModalOpened: true,
    });
  }

  render() {
    const { getSlickOverrides, isOpened, modalOnly, slides } = this.props;
    const { isModalOpened, fullscreenSlider, regularSlider } = this.state;
    const resultingIsOpened =
      typeof isOpened === 'boolean' ? isOpened : isModalOpened;
    const getRegularOverrides = ({ isMobile, isPhone }) => {
      const overrides = getSlickOverrides({ isMobile, isPhone });
      return Object.assign({}, overrides, {
        asNavFor: fullscreenSlider,
        ref: (el) => (this.regularSliderElement = el),
      });
    };
    const getFullscreenOverrides = ({ isMobile, isPhone }) => {
      const overrides = getSlickOverrides({ isMobile, isPhone });
      return Object.assign({}, overrides, {
        asNavFor: regularSlider,
        ref: (el) => (this.fullscreenSliderElement = el),
        slidesToShow: 1,
      });
    };

    return (
      <Fragment>
        {!modalOnly && (
          <Gallery
            slides={slides.map((slide, i) => ({
              ...slide,
              onClick: this.onSmallSlideClick.bind(this, i),
            }))}
            getSlickOverrides={getRegularOverrides}
          />
        )}

        <Modal
          isOpen={resultingIsOpened}
          onRequestClose={() => this.setIsModalOpened(false)}
          onAfterOpen={this.connectSliders}
          withCloseButton
          contentStyle={{
            width: 'auto',
            height: 'auto',
            background: 'none',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            position: 'absolute',
            pointerEvents: 'none',
          }}
        >
          <Gallery
            slides={slides}
            getSlickOverrides={getFullscreenOverrides}
            renderSlide={({ caption, onClick, thumb }) => (
              <img
                src={thumb}
                alt={caption}
                onClick={onClick}
                title={caption}
              />
            )}
          />
        </Modal>
      </Fragment>
    );
  }
}

export default GalleryFullscreen;
