import React from 'react';
import { asField } from 'informed';
import TimeInput from '../../';

const TimeInputField = ({
  fieldApi: { setValue, setTouched },
  fieldState: { value },
}) => (
  <TimeInput
    value={value}
    onChange={(v) => {
      setTouched(true);
      setValue(v);
    }}
  />
);

export default asField(TimeInputField);
