import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Preloader from '../../../../../components/Preloader';
import Node from '../Node';
import { fetchLevel } from '../../fetchDropdownTrees';

class Level extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    parentId: PropTypes.string,
    route: PropTypes.string,
    shouldFetch: PropTypes.bool,
    value: PropTypes.object,
    defaultOpened: PropTypes.arrayOf(PropTypes.string),
  };

  static defaultProps = {
    shouldFetch: true,
    value: null,
    defaultOpened: [],
  };

  static testIfNamesLoaded(value) {
    if (value === null) {
      return false;
    }
    const nodeValues = Object.values(value);
    if (!nodeValues.length) {
      return false;
    }
    return nodeValues.reduce((acc, curr) => acc && curr.name, false);
  }

  constructor() {
    super();
    this.state = {
      isFetching: false,
    };
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate({ value: oldValue }) {
    const { value } = this.props;
    if (oldValue && value === null) {
      this.fetch();
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  async fetch() {
    const { parentId, value, onChange, route, shouldFetch } = this.props;

    if (!shouldFetch || Level.testIfNamesLoaded(value)) return;

    this.setState({
      ...this.state,
      isFetching: true,
    });
    const nodes = await fetchLevel({ parentId, route });
    if (this.unmounted) return;
    this.setState(
      {
        ...this.state,
        isFetching: false,
      },
      () => {
        onChange(
          nodes.reduce(
            (acc, { id, name, hasChildren }) => ({
              ...acc,
              [id]:
                value && value[id]
                  ? {
                      ...value[id],
                      id,
                      name,
                      hasChildren,
                    }
                  : {
                      id,
                      name,
                      hasChildren,
                      checked: false,
                      childChecked: false,
                    },
            }),
            {}
          )
        );
      }
    );
  }

  onNodeChange(id, nodeValue) {
    const { value, onChange } = this.props;
    onChange({
      ...value,
      [id]: nodeValue,
    });
  }

  render() {
    const { value, route, shouldFetch, defaultOpened, t } = this.props;
    const { isFetching } = this.state;

    if (shouldFetch && (value === null || isFetching)) {
      return <Preloader relative size='small' />;
    }

    if (value) {
      const nodeEntries = Object.entries(value);
      if (nodeEntries.length) {
        return (
          <ul>
            {nodeEntries
              .sort(([a], [b]) => Math.sign(a - b))
              .map(([id, nodeValue]) => {
                if (defaultOpened.includes(id)) {
                  nodeValue.forceFetch = true;
                }
                return (
                  <Node
                    key={id}
                    value={nodeValue}
                    onChange={this.onNodeChange.bind(this, id)}
                    route={route}
                  />
                );
              })}
          </ul>
        );
      }
    }

    return <p className='content'>{t.common[0].NO_RESULT}</p>;
  }
}

const mapStateToProps = (state) => ({
  t: state.language.t,
});

export default connect(mapStateToProps)(Level);
