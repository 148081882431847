import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Input from '../../../../components/settings/Input';
import UploadPhoto from '../../../../components/settings/UploadPhoto';
import Tab from '../../../../components/settings/Tab';
import validator from 'validator';
import Preloader from '../../../../components/Preloader';
import {
  fetchSettingsMain,
  saveProfileMainData,
  searchCountries,
  fetchCompanies,
  saveProfileImage,
  updateLoaderValue,
} from '../../../../store/actions';
import languages from '../../../../helpers/languages';

var language = languages['en'];
var common = language.common[0];
var registration = language.registration[0];
var create_event = language.create_event[0];
var validation = language.registration_validation[0];
var currentYear = new Date().getFullYear();
var format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
var companyArray = [-1, 0, 9, 49, 99, 249, 499, 9999, 999999999999];

const stringValidator = (v) => {
  const isInvalid = v && !validator.isAlpha(v);
  return isInvalid ? 'Please enter a valid name' : null;
};

const urlValidator = (v) => {
  const isInvalid = v && !validator.isURL(v);
  return isInvalid ? 'Enter a valid URL' : null;
};

const mapStateToProps = (state) => {
  if (state.registration.hasOwnProperty('companies')) {
    var companies = [];
    var i = 0;
    for (var item in state.registration.companies) {
      companies[i] = {};
      companies[i]['label'] = state.registration.companies[item].label;
      companies[i]['value'] = '##' + state.registration.companies[item].value;
      i++;
    }
  } else {
    var companies = [];
  }

  return {
    user: state.auth.user,
    defaultData: state.setting.loadData,
    countryOptions: state.defaultLoad.countryOptions,
    companyNames: companies,
    updateSuccess: state.setting.loaderSuccess,
    lan: state.language.lan,
  };
};

const mapDispatchToProps = {
  fetchSettingsMain,
  saveProfileMainData,
  searchCountries,
  fetchCompanies,
  saveProfileImage,
  updateLoaderValue,
};

class MainInformation extends React.Component {
  constructor(props) {
    const currentYear = new Date().getFullYear();
    super(props);
    this.state = {
      companyTypes: [
        { value: '1', label: registration.COMPANY_TYPE_LABEL_ONE },
        { value: '2', label: registration.COMPANY_TYPE_LABEL_TWO },
        { value: '3', label: registration.COMPANY_TYPE_LABEL_THREE },
        { value: '4', label: registration.COMPANY_TYPE_LABEL_FOUR },
        { value: '5', label: registration.COMPANY_TYPE_LABEL_FIVE },
        { value: '6', label: registration.COMPANY_TYPE_LABEL_SIX },
        { value: '7', label: registration.COMPANY_TYPE_LABEL_SEVEN },
      ],

      profilePhoto: {
        photoFile: null,
        trigerProfile: false,
        editorState: {},
      },
      companyPhoto: {
        photoFile: null,
        editorState: {},
      },
      years: Array(100)
        .fill(null)
        .map((el, i) => ({ label: currentYear - i, value: currentYear - i })),
    };

    this.onProfilePhotoChange = this.onProfilePhotoChange.bind(this);
    this.onCompanyPhotoChange = this.onCompanyPhotoChange.bind(this);
  }

  componentDidMount() {
    this.props.fetchSettingsMain();
    this.props.searchCountries();
    this.props.fetchCompanies();
  }

  onProfilePhotoChange(profilePhoto) {
    console.log(profilePhoto);
    this.props.updateLoaderValue(false);
    this.props.saveProfileImage(
      'user',
      profilePhoto.editorState,
      profilePhoto.photoFile
    );
    this.setState({
      ...this.state,
      profilePhoto: {
        photoFile: null,
        trigerProfile: true,
        editorState: {},
      },
    });
  }

  onCompanyPhotoChange(companyPhoto) {
    this.props.saveProfileImage(
      'company',
      companyPhoto.editorState,
      companyPhoto.photoFile
    );
    this.setState({
      ...this.state,
      companyPhoto: {
        photoFile: null,
        editorState: {},
      },
    });
  }

  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    registration = language.registration[0];
    create_event = language.create_event[0];
    validation = language.registration_validation[0];
    const { companyTypes, years, profilePhoto, companyPhoto } = this.state;
    const userType =
      this.props.defaultData &&
      this.props.defaultData.hasOwnProperty('userType')
        ? this.props.defaultData.userType
        : 'individual';

    if (
      this.state.profilePhoto.trigerProfile === true &&
      this.props.updateSuccess !== true
    ) {
      return <Preloader relative size='medium' />;
    }

    return (
      <Tab
        title={common.ORGANISATION_INFORMATION}
        showPreloader={!this.props.defaultData}
        formProps={{
          initialValues: this.props.defaultData && {
            organizationName: this.props.defaultData.hasOwnProperty('companyId')
              ? '##' + this.props.defaultData.companyId
              : '',
            organizationType: this.props.defaultData.hasOwnProperty(
              'companyType'
            )
              ? this.props.defaultData.companyType
              : '',
            organizationSize: this.props.defaultData.hasOwnProperty(
              'companySize'
            )
              ? this.props.defaultData.companySize
              : '',
            established: this.props.defaultData.hasOwnProperty(
              'establishmentYear'
            )
              ? this.props.defaultData.establishmentYear
              : '',
            description:
              this.props.defaultData.hasOwnProperty('description') &&
              this.props.defaultData.description !== null
                ? this.props.defaultData.description
                : '',
            firstName: this.props.defaultData.hasOwnProperty('firstName')
              ? this.props.defaultData.firstName
              : '',
            lastName: this.props.defaultData.hasOwnProperty('lastName')
              ? this.props.defaultData.lastName
              : '',
            gender: this.props.defaultData.hasOwnProperty('gender')
              ? this.props.defaultData.gender
              : '',
            jobTitle: this.props.defaultData.hasOwnProperty('jobPosition')
              ? this.props.defaultData.jobPosition
              : '',
            country: this.props.defaultData.hasOwnProperty('countryId')
              ? this.props.defaultData.countryId
              : '',
            city: this.props.defaultData.hasOwnProperty('city')
              ? this.props.defaultData.city
              : 0,
            address: this.props.defaultData.hasOwnProperty('address')
              ? this.props.defaultData.address
              : 0,
            zip: this.props.defaultData.hasOwnProperty('zipCode')
              ? this.props.defaultData.zipCode
              : 0,
            phone: this.props.defaultData.hasOwnProperty('phone')
              ? this.props.defaultData.phone
              : 0,
            fax: this.props.defaultData.hasOwnProperty('fax')
              ? this.props.defaultData.fax
              : 0,
            website: this.props.defaultData.hasOwnProperty('website')
              ? this.props.defaultData.website
              : 0,
            facebook: this.props.defaultData.hasOwnProperty('facebook')
              ? this.props.defaultData.facebook
              : 0,
            twitter: this.props.defaultData.hasOwnProperty('twitter')
              ? this.props.defaultData.twitter
              : 0,
            linkedin: this.props.defaultData.hasOwnProperty('linkedin')
              ? this.props.defaultData.linkedin
              : 0,
          },
          onSubmit: (values) => {
            this.props.saveProfileMainData(values, this.state.profilePhoto);
          },
        }}
        renderContents={({ formApi, formState }) => (
          <Fragment>
            <div className='inputs-group'>
              <div className='inputs-group__title'>
                <p className='gray'>{common.SET_YOUR_PROFILE_PICTURE}</p>
              </div>
              <div className='inputs'>
                <UploadPhoto
                  imageExisting={this.props.defaultData.avatar}
                  value={profilePhoto}
                  onChange={this.onProfilePhotoChange}
                />
              </div>
            </div> 

            <div className='inputs-group'>
              <div className='inputs-group__title'>
                <p className='gray'>{common.MAIN_INFORMATION}</p>
              </div>

              <div className='inputs'>
                <Input
                  id='organizationName'
                  required={
                    this.props.defaultData.userType === 'company' ? true : false
                  }
                  label={registration.ORGANISATION_NAME}
                  disabled={true}
                  placeholder={common.ENTER_NAME_OF_ORGANIZATION_COMPANY_ETC}
                  type='select'
                  options={this.props.companyNames}
                  selectProps={{
                    searchable: true,
                    createable: true,
                  }}
                  informedProps={{
                    validate: (v) =>
                      this.props.defaultData.userType === 'company' && !v
                        ? common.CHOOSE_ORGANIZATION_NAME
                        : null,
                  }}
                  hasError={'organizationName' in formState.errors}
                  errors={formState.errors.organizationName}
                />

                <Input
                  id='organizationType'
                  required={
                    this.props.defaultData.userType === 'company' ? true : false
                  }
                  disabled={userType === 'company' ? false : true}
                  label={common.ORGANIZATION_TYPE}
                  placeholder={common.CHOOSE_TYPE}
                  type='select'
                  options={companyTypes}
                  selectProps={{
                    searchable: true,
                    createable: true,
                  }}
                  informedProps={{
                    validate: (v) =>
                      userType === 'company' && !v
                        ? common.SELECT_ORGANIZATION_TYPE
                        : null,
                  }}
                  hasError={'organizationType' in formState.errors}
                  errors={formState.errors.organizationType}
                />

                <Input
                  id='organizationSize'
                  required={
                    this.props.defaultData.userType === 'company' ? true : false
                  }
                  label={common.ORGANIZATION_SIZE}
                  disabled={userType === 'company' ? false : true}
                  placeholder={common.CHOOSE_SIZE}
                  type='select'
                  options={[
                    { value: '1', label: common.NO_EMPLOYEES },
                    { value: '2', label: registration.COMPANY_SIZE_LABEL_TWO },
                    {
                      value: '3',
                      label: registration.COMPANY_SIZE_LABEL_THREE,
                    },
                    { value: '4', label: registration.COMPANY_SIZE_LABEL_FOUR },
                    { value: '5', label: registration.COMPANY_SIZE_LABEL_FIVE },
                    { value: '6', label: registration.COMPANY_SIZE_LABEL_SIX },
                    {
                      value: '7',
                      label: registration.COMPANY_SIZE_LABEL_SEVEN,
                    },
                    {
                      value: '8',
                      label: registration.COMPANY_SIZE_LABEL_EIGHT,
                    },
                    { value: '9', label: registration.COMPANY_SIZE_LABEL_NINE },
                  ]}
                  informedProps={{
                    validate: (v) => {
                      if (
                        v &&
                        companyArray[v] <
                          this.props.defaultData.numberOfEmployees
                      ) {
                        return validation.INVALID_COMPANY_SIZE;
                      }
                      return null;
                    },
                  }}
                  hasError={'organizationSize' in formState.errors}
                  errors={formState.errors.organizationSize}
                />

                <Input
                  id='established'
                  required={
                    this.props.defaultData.userType === 'company' ? true : false
                  }
                  label={registration.ESTABLISHMENT_YEAR}
                  disabled={userType === 'company' ? false : true}
                  placeholder={common.ENTER_ORGANIZATION_ESTABLISHMENT_YEAR}
                  type='text'
                  selectOptions={{
                    searchable: true,
                  }}
                  informedProps={{
                    validate: (v) => {
                      if (
                        (v && v <= 0) ||
                        v > currentYear ||
                        format.test(v) ||
                        isNaN(v)
                      ) {
                        return validation.INVALID_YEAR_ERROR;
                      }
                      return null;
                    },
                  }}
                  hasError={'established' in formState.errors}
                  errors={formState.errors.established}
                />

                <Input
                  id='description'
                  required={
                    this.props.defaultData.userType === 'company' ? true : false
                  }
                  label={common.ORGANIZATION_DESCRIPTION}
                  placeholder={
                    common.WRITE_SHORT_TEXT_INTRODUCING_YOUR_ORGANIZATION
                  }
                  type='textarea'
                  disabled={userType === 'company' ? false : true}
                  formApi={formApi}
                  informedProps={{
                    validate: stringValidator,
                  }}
                  textareaProps={{
                    maxLength: 500,
                  }}
                  hasError={'description' in formState.errors}
                  errors={formState.errors.description}
                />

                <Input
                  id='firstName'
                  required
                  label={registration.FIRSTNAME}
                  placeholder={common.ENTER_YOUR_FIRST_NAME}
                  hasError={'firstName' in formState.errors}
                  errors={formState.errors.name}
                />

                <Input
                  id='lastName'
                  required
                  label={registration.LASTNAME}
                  placeholder={common.ENTER_YOUR_LAST_NAME}
                  hasError={'lastName' in formState.errors}
                  errors={formState.errors.name}
                />

                <Input
                  id='gender'
                  label='Gender'
                  required
                  type='tab-group'
                  hasError={'gender' in formState.errors}
                  errors={formState.errors.name}
                  options={[
                    { value: 'FEMALE', label: 'Female' },
                    { value: 'MALE', label: 'Male' },
                    { value: 'OTHER', label: 'Other' },
                  ]}
                />
                <Input
                  id='jobTitle'
                  required={
                    this.props.defaultData.userType === 'company' ? true : false
                  }
                  disabled={userType === 'company' ? false : true}
                  label={common.JOB_TITLE_REPRESENTATIVE}
                  placeholder={common.ENTER_YOUR_JOB_TITLE}
                  informedProps={{
                    validate: (v) =>
                      !v && userType === 'company'
                        ? common.PLEASE_CHOOSE_JOB_TITLE
                        : null,
                  }}
                  hasError={'jobTitle' in formState.errors}
                  errors={formState.errors.jobTitle}
                />
              </div>
            </div>

            <div className='inputs-group'>
              <div className='inputs-group__title'>
                <p className='gray'>{common.CONTACT_INFORMATION}</p>
              </div>

              <div className='inputs'>
                <Input
                  id='country'
                  required={
                    this.props.defaultData.userType === 'company' ? true : false
                  }
                  label={registration.COUNTRY}
                  placeholder={common.CHOOSE_COUNTRY}
                  type='select'
                  options={this.props.countryOptions}
                  selectProps={{
                    searchable: true,
                  }}
                  informedProps={{
                    validate: (v) => (!v ? common.PLEASE_CHOOSE_COUNTRY : null),
                  }}
                  hasError={'country' in formState.errors}
                  errors={formState.errors.country}
                />

                <Input
                  id='city'
                  required={
                    this.props.defaultData.userType === 'company' ? true : false
                  }
                  label={common.CITY}
                  placeholder={common.ENTER_CITY_TOWN_LOCALITY}
                  informedProps={{
                    validate: (v) => (!v ? common.ENTER_YOUR_CITY : null),
                  }}
                  hasError={'city' in formState.errors}
                  errors={formState.errors.city}
                />

                <Input
                  id='address'
                  required={
                    this.props.defaultData.userType === 'company' ? true : false
                  }
                  label={common.ADDRESS}
                  placeholder={common.ENTER_YOUR_ADDRESS}
                  informedProps={{
                    validate: (v) =>
                      !v ? common.PLEASE_ENTER_YOUR_ADDRESS : null,
                  }}
                  hasError={'address' in formState.errors}
                  errors={formState.errors.address}
                />

                <Input
                  id='zip'
                  label={registration.ZIP_CODE}
                  placeholder={common.ENTER_YOUR_ZIP_CODE}
                  informedProps={{
                    validate: (v) => {
                      if (!v) {
                        return common.ENTER_VALID_ZIP_CODE;
                      }
                      return null;
                    },
                  }}
                  hasError={'zip' in formState.errors}
                  errors={formState.errors.zip}
                />

                <Input
                  id='phone'
                  required={
                    this.props.defaultData.userType === 'company' ? true : false
                  }
                  label={common.TELEPHONE}
                  informedProps={{
                    validate: (v) => {
                      if (format.test(v)) {
                        return validation.INVALID_PHONE_NUMBER;
                      }
                      return null;
                    },
                  }}
                  placeholder={common.ENTER_YOUR_PHONE_NUMBER}
                  hasError={'phone' in formState.errors}
                  errors={formState.errors.phone}
                />

                <Input
                  id='fax'
                  label={registration.FAX}
                  placeholder={common.ENTER_YOUR_FAX_NUMBER}
                />
              </div>
            </div>

            <div className='inputs-group'>
              <div className='inputs-group__title'>
                <p className='gray'>{common.WEBSITE_SOCIAL_NETWORK}</p>
              </div>

              <div className='inputs'>
                <Input
                  id='website'
                  label={create_event.WEBSITE}
                  placeholder={registration.URL_PLACEHOLDER}
                  informedProps={{
                    validate: urlValidator,
                  }}
                  hasError={'website' in formState.errors}
                  errors={formState.errors.website}
                />

                <Input
                  id='facebook'
                  label={registration.FACEBOOK}
                  value='Lucille Arnold'
                  placeholder={registration.URL_PLACEHOLDER}
                  informedProps={{
                    validate: urlValidator,
                  }}
                  hasError={'facebook' in formState.errors}
                  errors={formState.errors.facebook}
                />

                <Input
                  id='twitter'
                  label={registration.TWITTER}
                  placeholder={registration.URL_PLACEHOLDER}
                  informedProps={{
                    validate: urlValidator,
                  }}
                  hasError={'twitter' in formState.errors}
                  errors={formState.errors.twitter}
                />

                <Input
                  id='linkedin'
                  label={registration.LINKDIN}
                  placeholder={registration.URL_PLACEHOLDER}
                  informedProps={{
                    validate: urlValidator,
                  }}
                  hasError={'linkedin' in formState.errors}
                  errors={formState.errors.linkedin}
                />
              </div>
            </div>

            {userType === 'company' && (
              <div className='inputs-group'>
                <div className='inputs-group__title'>
                  <p className='gray'>{common.SET_YOUR_COMPANY_PICTURE}</p>
                </div>

                <div className='inputs'>
                  <UploadPhoto
                    imageExisting={this.props.defaultData.companyAvatar}
                    value={companyPhoto}
                    onChange={this.onCompanyPhotoChange}
                  />
                </div>
              </div>
            )}
          </Fragment>
        )}
      />
    );
  }
}

const List = connect(mapStateToProps, mapDispatchToProps)(MainInformation);
export default List;
