import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class InputBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: props.value,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state) return true;
    return (
      nextProps.value !== this.props.value ||
      nextProps.error !== this.props.error
    );
  }

  componentDidUpdate(oldProps) {
    if (oldProps.value !== this.props.value) {
      this.setState({ isFocused: this.props.value });
    }
  }

  toggleFocus(isFocused) {
    this.setState({
      isFocused,
    });
  }

  render() {
    const onChange = (e) => {
      if (this.props.onChange) {
        this.props.onChange(e);
      }
    };
    return (
      <label
      >
        {this.props.label && (
          <span>
            {this.props.label}{' '}
            {this.props.inputAttrs && this.props.inputAttrs.required && (
              <sup>*</sup>
            )}
          </span>
        )}
        {this.props.children ? (
          this.props.children({
            value: this.props.value,
            toggleFocus: this.toggleFocus.bind(this),
            onChange,
          })
        ) : (
          <input
            value={this.props.value}
            onChange={onChange}
            onFocus={() => this.toggleFocus(true)}
            onBlur={() => this.toggleFocus(this.props.value !== '')}
            {...this.props.inputAttrs}
          />
        )}
        {this.props.error && (
          <div className='info itc-error itc-input-block__error'>
            {this.props.error}
          </div>
        )}
      </label>
    );
  }
}

InputBlock.propTypes = {
  error: PropTypes.string,
  inputAttrs: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

InputBlock.defaultProps = {
  inputAttrs: {},
};

export default InputBlock;
