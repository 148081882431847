import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';

import thunk from 'redux-thunk';
import rootReducer from './reducer';
import { createHashHistory } from 'history';

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['auth', 'search'],
};

const pReducer = persistReducer(persistConfig, rootReducer);

const reduxRouterMiddleware = routerMiddleware(createHashHistory());

export const store = createStore(
  pReducer,
  composeWithDevTools(applyMiddleware(thunk, reduxRouterMiddleware))
);

export const persistor = persistStore(store);
