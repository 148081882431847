import {
  MY_NETWORK,
  KNOWN_USER,
  USER_REQUEST,
  NETWORK_MESSAGE_HIDE,
  PURGE_MEMBERS,
  CONTACT_REMOVE_FLAG,
} from '../store/actions';

const initialState = {
  messageShow: false,
  network: null,
  contactRemoveFlag: false,
};

export default function network(state = initialState, action) {
  switch (action.type) {
    case MY_NETWORK:
      return {
        ...state,
        network: action.payload.data,
        totalItems: action.payload.count,
        sortLabel: action.payload.sortLabel,
        messageShow: action.payload.data.messageShow,
      };
    case KNOWN_USER:
      return {
        ...state,
        knownUser: action.payload.data,
      };
    case CONTACT_REMOVE_FLAG:
      return {
        ...state,
        contactRemoveFlag: action.payload,
      };
    case USER_REQUEST:
      return {
        ...state,
        request: action.payload.data,
        totalRequestItems: action.payload.count,
        sortRequestLabel: action.payload.sortLabel,
      };
    case NETWORK_MESSAGE_HIDE:
      return {
        ...state,
        messageShow: false,
        contactRemoveFlag: false,
      };
    case PURGE_MEMBERS:
      const { memberSection } = action.payload;
      if (memberSection === 'network') {
        return {
          ...initialState,
          knownUser: state.knownUser,
          request: state.request,
        };
      }
      return state;
    default:
      return state;
  }
}
