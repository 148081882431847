/* eslint-disable no-unused-expressions */
import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import queryString from 'query-string';

const Pagination = (props) => {
  const {
    page: currentPageIndex,
    cardsPerPage,
    location,
    fetchFunction,
    totalItems,
    pageName,
    search,
  } = props;
  const pagesLength = Math.ceil(totalItems / cardsPerPage); //Math.ceil(cards.filter(filter).length / cardsPerPage);
  if (pagesLength < 2) {
    return null;
  }

  const queryObject = queryString.parse(location.search);
  const toPrevObject = {
    ...queryObject,
    page: currentPageIndex - 1,
  };
  const toNextObject = {
    ...queryObject,
    page: currentPageIndex + 1,
  };
  const firstIndex = Math.max(currentPageIndex - 3, 0);
  const lastIndex = currentPageIndex + 4;
  const pages = Array(pagesLength)
    .fill(null)
    .map((el, i) => i)
    .slice(firstIndex, lastIndex);

  const to = (queryObject) =>
    `${location.pathname}?${queryString.stringify(queryObject)}`;

  return (
    <ul className='pagination'>
      {currentPageIndex > 0 && (
        <li className='prev'>
          <NavLink
            to={to(toPrevObject)}
            onClick={(e) => {
              fetchFunction
                ? pageName === 'search' ||
                  pageName === 'tenders' ||
                  pageName === 'offers'
                  ? fetchFunction(to(toPrevObject), search)
                  : fetchFunction(to(toPrevObject))
                : '';
            }}
          >
            <i className='icon-arrow--long' />
          </NavLink>
        </li>
      )}

      {pages.map((page) => {
        const toQueryObject = {
          ...queryObject,
          page,
        };

        // if (el.ellipsis) {
        //   return '&hellip;';
        // }

        return (
          <li key={page}>
            <NavLink
              isActive={() => page === currentPageIndex}
              to={to(toQueryObject)}
              onClick={(e) => {
                fetchFunction
                  ? pageName === 'search' ||
                    pageName === 'tenders' ||
                    pageName === 'offers'
                    ? fetchFunction(to(toQueryObject), search)
                    : fetchFunction(to(toQueryObject))
                  : '';
              }}
            >
              {page + 1}
            </NavLink>
          </li>
        );
      })}

      {currentPageIndex < pagesLength - 1 && (
        <li className='next'>
          <NavLink
            to={to(toNextObject)}
            onClick={(e) => {
              fetchFunction
                ? pageName === 'search'
                  ? fetchFunction(to(toNextObject), search)
                  : fetchFunction(to(toNextObject))
                : '';
            }}
          >
            <i className='icon-arrow--long' />
          </NavLink>
        </li>
      )}
    </ul>
  );
};

export default withRouter(Pagination);
