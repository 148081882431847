import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { fetchEvents, fetchMonth } from '../store/actions';

const mapStateToProps = (state) => {
  return {
    cards: state.events.events,
    t: state.language.t,
  };
};

const mapDispatchToProps = {
  fetchEvents,
  fetchMonth,
};

function formatInterval(start, end) {
  if (moment(start).isSame(end, 'day')) {
    return moment(start).format('MMM D, YYYY');
  } else if (moment(start).isSame(end, 'month')) {
    return `${moment(start).format('MMM D')} - ${moment(end).format(
      'D, YYYY'
    )}`;
  } else if (moment(start).isSame(end, 'year')) {
    return `${moment(start).format('MMM D')} - ${moment(end).format(
      'MMM D, YYYY'
    )}`;
  }
  return `${moment(start).format('MMM D, YYYY')} - ${moment(end).format(
    'MMM, D YYYY'
  )}`;
}

class Calendar extends React.Component {
  constructor() {
    super();

    this.state = {
      selectedMonth: moment(),
    };
  }

  componentDidMount(props) {
    this.props.fetchEvents('/#/events?view=calendar');
  }

  setSelectedMonth(selectedMonth) {
    this.props.fetchMonth(selectedMonth);
    this.setState({
      selectedMonth,
    });
  }

  static propTypes = {
    maxEvents: PropTypes.number,
  };

  static defaultProps = {
    maxEvents: 3,
  };

  render() {
    const { maxEvents, cards, t } = this.props;
    const { selectedMonth } = this.state;

    // eslint-disable-next-line no-unused-vars
    const filteredEvents = cards
      .filter(
        ({ start, end, type }) =>
          moment(start).isSameOrBefore(selectedMonth, 'month') &&
          moment(end).isSameOrAfter(selectedMonth, 'month')
      )
      // eslint-disable-next-line no-nested-ternary
      .sort((a, b) => (a.start < b.start ? -1 : a.start > b.start ? 1 : 0))
      .slice(0, maxEvents);

    return (
      <div className='widget__white'>
        <div className='calendar calendar_small-for-dashboard calendar_full-width calendar_with-long-arrows'>
          <DayPickerRangeController
            noBorder
            numberOfMonths={1}
            enableOutsideDays
            navPrev={<i className='icon-arrow--long' />}
            navNext={<i className='icon-arrow--long' />}
            daySize={37}
            hideKeyboardShortcutsPanel
            transitionDuration={0}
            weekDayFormat='ddd'
            renderMonth={(month) => (
              <div className='calendar-widget-top'>
                <div className='calendar-widget-year'>
                  {month.format('YYYY')}
                </div>
                <div className='calendar-widget-month'>
                  {month.format('MMMM')}
                </div>
              </div>
            )}
            renderDayContents={(day) => {
              const selectedEvents = cards.filter((el) =>
                day.isSame(el.start, 'day')
              );

              if (selectedEvents.length) {
                const [selectedEvent] = selectedEvents;
                const { type, id } = selectedEvent;
                return (
                  <div className={`day day_has-something day_has-${type}`}>
                    <Link to={`/${type}s/${id}`}>{day.format('D')}</Link>
                  </div>
                );
              }

              return <div className='day'>{day.format('D')}</div>;
            }}
            // eslint-disable-next-line no-shadow
            onPrevMonthClick={(moment) => this.setSelectedMonth(moment)}
            // eslint-disable-next-line no-shadow
            onNextMonthClick={(moment) => this.setSelectedMonth(moment)}
          />
        </div>

        {filteredEvents.length > 0 && (
          <Fragment>
            <div className='calendar-widget-events'>
              {filteredEvents.map((el, i) => {
                const { start, end, title, type, id } = el;
                const to = `/${type}s/${id}`;
                return (
                  <div
                    key={i}
                    className={`calendar-widget-event calendar-widget-event_${type}`}
                  >
                    <div className='info'>{formatInterval(start, end)}</div>

                    <Link to={to} className='calendar-widget-event__title'>
                      {title}
                    </Link>
                  </div>
                );
              })}
            </div>

            {filteredEvents.length >= maxEvents && (
              <Link
                to='/events?view=calendar'
                className='button button_flat button_white'
              >
                <span className='button__text'>{t.common[0].VIEW_MORE}</span>
              </Link>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

Calendar.defaultProps = {
  cards: [],
};

const List = connect(mapStateToProps, mapDispatchToProps)(Calendar);
export default List;
