import queryString from 'query-string';

import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/es';

import languages from '../helpers/languages';

let lan;

const [hashPath, search] = window.location.hash.split('?');
const path = hashPath.substr(1);
const queryObject = queryString.parse(search || '');

if ('lan' in queryObject) {
  lan = queryObject.lan;
}

if (lan === undefined) {
  lan = localStorage.getItem('shetrades-language') || 'en';
  const res = queryString.stringify({ ...queryObject, lan });
  window.history.replaceState(null, '', `/#${path}?${res}`);
}

moment.locale(lan);

const initialState = {
  lan,
  t: languages[lan],
};

export default (state = initialState) => state;
