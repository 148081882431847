import React, { Fragment } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Form } from 'informed';
import queryString from 'query-string';
import { Image } from "react-bootstrap";
import { connect } from 'react-redux';
import { Link, NavLink, Redirect } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import CustomizedDocumentTitle from '../../../../components/CustomizedDocumentTitle';
import Modal from '../../../../components/Modal';
import Preloader from '../../../../components/Preloader';
import Region from '../../../../components/Region';
import Tag from '../../../../components/Tag';
import eventData from '../../../../data/events.json';
import hubsData from '../../../../data/hubs.json';
import cardNews from '../../../../data/news.json';
import cardOffers from '../../../../data/offers.json';
import partnerData from '../../../../data/partners.json';
import cardPublication from '../../../../data/publications.json';
import cardSuccessStories from '../../../../data/success-stories.json';
import tenderData from '../../../../data/tenders.json';
import cardVideos from '../../../../data/videos.json';
import cardVLSCourses from '../../../../data/vls-courses.json';
import cardWebinars from '../../../../data/webinars.json';
import languages from '../../../../helpers/languages';
import noAvatarImage from '../../../../static/images/noavatar.svg';
import { fetchOffers, fetchProgressProgess } from '../../../../store/actions';




var language = languages['en'];
var common = language.common[0];
var labels = language.dashboard[0];
var registrationSuccess = language.registration_successful_popup[0];
var labelWelcome = language.registration_welcome_popup[0];
var header = language.header[0];
const DATE_FORMAT = 'DD MMM YYYY';

const mapStateToProps = (state) => {
  var progressCount = 0;
  if (state.setting.hasOwnProperty('setProfileProgress')) {
    progressCount = 30;

    for (var item in state.setting.setProfileProgress) {
      if (state.setting.setProfileProgress[item].done === true) {
        progressCount =
          +progressCount + +state.setting.setProfileProgress[item].points;
      }
    }
  } else {
    var progressCount = 30;
  }
  if (progressCount) {
    var progressCount = progressCount * 0.01;
  }

  return {
    todo: state.setting.setProfileProgress,
    progress: progressCount,
    totalOfferItems: state.offers.totalItems,
    lan: state.language.lan,
    user: state.auth.user
  };
};

const mapDispatchToProps = {
  fetchProgressProgess,
  fetchOffers,
};

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpened: false,
      formApi: null,
      queryInput: '',
    };

    this.clear = this.clear.bind(this);
    this.onQueryInputChange = this.onQueryInputChange.bind(this);
    this.setFormApi = this.setFormApi.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {

    const {
      location,
      fetchRequest,
      fetchDashboard
    } = this.props;
    const queryObject = queryString.parse(decodeURIComponent(location?.search));
    if (
      typeof queryObject.query === 'string' &&
      queryObject.query.length !== 0
    ) {
      this.setState({
        queryInput: queryObject.query,
      });
    }

    //TODO:smellycode
    if (!localStorage.getItem('preview')) {
      this.setState({
        isModalOpened: true,
      });
    }

    fetchRequest();
    fetchDashboard();
    this.props.fetchProgressProgess();
    const tab = '?order=DESC&orderBy=date';
    this.props.fetchOffers((window.location.hash + tab));
  }

  onClose = (e) => {
    localStorage.setItem('preview', 'false');
    e.preventDefault();
    this.setState({
      isModalOpened: false,
    });
  };

  onAllow() {
    localStorage.setItem('preview', 'false');
    this.setState({
      isModalOpened: false,
    });
  }

  onCompleteRegistration = (e) => {
    localStorage.setItem('preview', 'false');
    e.preventDefault();
    this.setState({
      isModalOpened: false,
    });
    if (this.props.user.type_User === 2) {
      this.props.history.push('/completeregistration/business');
    } else if (this.props.user.type_User === 3) {
      this.props.history.push('/completeregistration/partner');
    }
    else {
      this.props.history.push('/completeregistration/team');
    }
  };

  clear() {
    const { onResetClick } = this.props;
    onResetClick();
    this.setState({
      queryInput: '',
    });
  }

  onQueryInputChange(e) {
    this.setState({
      queryInput: e.target.value,
    });
  }

  persist(values, searchUrl = null) {
    const { fetchSearchResult, onSearchSubmit } = this.props;
    const { formApi, queryInput } = this.state;
    formApi.setValues(values);
    onSearchSubmit(values);
    if (searchUrl) {
      fetchSearchResult(searchUrl, values, true);
    } else {
      fetchSearchResult(window.location.hash, values, true);
    }
  }

  setFormApi(formApi) {
    this.setState({
      formApi,
    });
  }

  submit() {
    const { history } = this.props;
    const { formApi, queryInput } = this.state;
    const trimmedQuery = encodeURIComponent(queryInput.trim());
    var pageUrl = window.location.hash.split('/');
    var currentTab = '';
    if (pageUrl[1]) {
      pageUrl = pageUrl[1];
      pageUrl = pageUrl.split('?');
      if (pageUrl[0] && pageUrl[0] === 'publicsearch') {
        var qParam = queryString.parse(decodeURIComponent(pageUrl[1]));
        if (qParam['tab']) {
          currentTab = 'tab=' + qParam['tab'];
        }
      }
    }
    const formState = formApi.getState();
    if (Object.keys(formState.touched) !== 0 || trimmedQuery.length !== 0) {
      const location = `/search?query=${trimmedQuery}&${currentTab}`;
      this.persist(formState.values, location);
      this.props.history.push(location);
    }
  }

  getInitialValues() {
  }


  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    labels = language.dashboard[0];
    registrationSuccess = language.registration_successful_popup[0];
    header = language.header[0];
    const { progress } = this.props;
    const { showPreloader } = this.props;
    const {
      isModalOpened,
    } = this.state;

    const { queryInput } = this.state;
    const initialValues = this.getInitialValues();


    const hubInfo = hubsData.find(hb => hb.id === "1");
    const sliderSettings = {
      arrows: true,
      dots: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 1000,
      cssEase: "linear",
    };
    const shuffle = arr => [...arr].sort(() => Math.random() - 0.5);

    return (
      <CustomizedDocumentTitle title={common.MY_DASHBOARD}>
        <div className='transition-page'>
          {showPreloader ? (
            <Preloader size='medium' relative />
          ) : (
            <div>
              {/*MAIN CONTENT  */}
              {/*welcome, user  */}
              <div className="wrap">
                <div className="container">
                  <h1>{labels.WELCOME}, {this.props.user.first_name}</h1>
                  {/*<p>This is your personal space: use it to access your tools and customize it to your liking.</p> */}
                  <Form
                    onSubmit={this.submit}
                    initialValues={initialValues}
                    getApi={this.setFormApi}
                    className="searchform"
                    render={({ formState: { touched, values } }) => (
                      <Fragment>
                        <input id="searchcheckbox" name="searchcheckbox" type="checkbox" />
                        <label htmlFor="searchcheckbox"><i className="fal fa-search" /></label>
                        <div className="searchformcontainer">
                          <input
                            name="searchform_full"
                            type="text"
                            placeholder="Search for a member, an event, an offer…"
                            id="searchform_full"
                            value={queryInput}
                            onChange={this.onQueryInputChange}
                          />
                          <button name="searchform_full" value="search" type="submit"><i className="fas fa-search" /></button>
                        </div>
                      </Fragment>
                    )}
                  />
                </div>
              </div>
              {/*welcome, user  */}
              {/*4 even blocks  */}
              <div className="wrap">
                <div className="container-2even">
                  {/*shetrades network  */}
                  <div className="darkerblock contentblock rounded" style={{ padding: '20px' }}>
                    {/* <div className="networkpics">
                      <img src="images/communitypic1.jpg" srcSet="images/communitypic1.jpg 1x, images/communitypic1@2x.jpg 2x, images/communitypic1@3x.jpg 3x, images/communitypic1@4x.jpg 4x" alt="" />
                      <img src="images/communitypic2.jpg" srcSet="images/communitypic2.jpg 1x, images/communitypic2@2x.jpg 2x, images/communitypic2@3x.jpg 3x, images/communitypic2@4x.jpg 4x" alt="" />
                       <img src="images/communitypic3.jpg" srcSet="images/communitypic3.jpg 1x, images/communitypic3@2x.jpg 2x, images/communitypic3@3x.jpg 3x, images/communitypic3@4x.jpg 4x" alt="" />
                    </div> */}
                    <h3 className="insidedarkbgtitle">SheTrades network</h3>
                    <div className="container-2col">
                      <p>Connect and join discussion groups 45’000+ like-minded members, potential biz partners, suppliers, etc.</p>
                      <Link to="/profilesearch" className="btn btn-small alignbottom">Browse now</Link></div>
                  </div>
                  {/*shetrades network  */}
                  {/*your profile block  */}
                  <div className="contentblock whiteblock  rounded" style={{ padding: '20px' }}>
                    <div className="userbar">
                      <img src={this.props.user ? this.props.user.avatar : noAvatarImage} alt="" />
                      <p>{labels.YOUR_PROFILE} {Math.round(100 * progress)}% {labels.complete}</p>
                    </div><div className="container-2col">
                      <p>{labels.REPLY_TO_A_FEW_QUESTIONS}</p>
                      {this.props.user.userRole === 'member' ?
                        <NavLink to="completeregistration/business" className="btn btn-small alignbottom">{labels.EDIT_PROFILE}</NavLink>
                        :

                        <NavLink to="/settings" className="btn btn-small alignbottom">{labels.EDIT_PROFILE}</NavLink>
                      }
                    </div>
                  </div>

                  <div className="contentblock whiteblock  rounded" style={{ padding: '20px' }}>
                    {/* Remove from issue #168
                    <div className="networkpics">
                      <img src="images/1.jpg" alt="" /><img src="images/2.jpg" alt="" /><img src="images/3.jpg" alt="" /><img src="images/4.jpg" alt="" />
                    </div>
                    */ }
                    <h3 className="insidedarkbgtitle">{labels.UNLOCK_FINANCE}</h3>
                    <div className="container-2col">
                      <p>{labels.FIND_OUT_HOW_YOU}</p>
                      <Link to="/project/9" className="btn btn-small">{labels.LEARN_MORE}</Link>
                    </div>
                  </div>
                  {/*your profile block  */}

                    {/* SheTrades hubs */}
             
                  <div className="contentblock whiteblock blockshadow">
                    <div className="moveblockicon" />
                    <h2>SheTrades hubs</h2>
                    <ul className="hubslist">
                      {shuffle(hubsData).slice(0, 4).map((hub, idx) => (
                        <li><Link to={`/publichub/${hub.id}`}>
                          <img src="images/mapex.png" alt="" />
                          <span>{hub.title}</span></Link></li>
                      ))
                      }
                    </ul>
                  </div>
              {/* SheTrades hubs */}
                </div>
              </div>
              {/* This block is not finished see issue #171
              <div className="wrap">
                <div className="container-2even">
                  {/*you are member of  /}
                  <div className="narrowcontentblock whiteblock  rounded">
                    <div className="container">
                      <span className="hubimg"><img src="images/SAhub.jpg" srcSet="images/SAhub.jpg 1x, images/SAhub@2x.jpg 2x, images/SAhub@3x.jpg 3x, images/SAhub@4x.jpg 4x" alt =""/></span>
                      <div className="bigicontextblock"><h4>You are a not member of any hub, please join</h4>
                        <p>See what’s happening &amp;  join discussion groups</p>
                      </div>
                    </div>
                  </div>
                  {/*you are member of  /}
                  {/*you are member of  /}
                  <div className="narrowcontentblock whiteblock  rounded">
                    <div className="container">
                      <span className="hubimg"><img src="images/49705006271_420c4d27f4_c.jpg" srcSet="images/49705006271_420c4d27f4_c.jpg 1x, images/49705006271_420c4d27f4_c@2x.jpg 2x, images/49705006271_420c4d27f4_c@3x.jpg 3x, images/49705006271_420c4d27f4_c@4x.jpg 4x" alt =""/></span>
                      <div className="bigicontextblock"><h4>You are a not a member of any project</h4>
                        <p>See what’s happening &amp;  join discussion groups</p>
                      </div>
                    </div>
                  </div>
                  {/*you are member of  /}

                </div>
              </div>
              {/*first time user block */}
              {/* {this.props.user.userRole === 'member' && (
                <div className="bgdark" style={{ margin: '20px 0' }}>
                  <div className="wrap">
                    <div className="blueblockbgcircles">
                      <div className="container-2col">
                        <div>
                          <h2>{labels.DO_YOU_OWN}</h2>
                          <p>{labels.CREATE_OR_JOIN_AN_EXISTING_COMPANY} </p></div>
                        <NavLink to="/registration/seller" className="btn">{labels.CREATE_OR_JOIN_A_COMPANY_PAGE}</NavLink>
                      </div>
                    </div>
                  </div>
                </div>)} */}
              {/*free block */}

              {/*Manage your company pages }
              <div className="wrap">
                <div className="contentblock whiteblock  rounded">
                  <h2>Manage your company pages</h2>
                  <div className="container-3col">
                    <div>
                      <div><Link to="/registation/buyer" className="addcompanybtn"><i className="fal fa-plus-circle" />Add a Company page</Link></div>
                    </div>
                  </div>
                </div>
              </div>
              {/*Manage your company pages */}


              <div class="wrap">
                <div class="container-2even">

                  {/*buy and sell  */}
                  <div className="contentblock whiteblock blockshadow earlyresponse">
                    <div className="moveblockicon" />
                    <div className="buyandsellbg">
                      <h2>{labels.NEWS_PORTAL}</h2>
                      <p className="textdesc">{labels.KEEP_UP_WITH_THE_LATEST}</p>
                      { /*<p className="count"><span className="cart-arrow-down" /> {this.props.totalOfferItems} selling offers</p> */}
                      <NavLink to="/publicnews">{labels.ACCESS_OUR_LATEST_STORIES}<span className="icon-red_arrow" /></NavLink>
                    </div>
                  </div>
                  {/*buy and sell  */}
                  {/* Learn  */}
                  <div className="contentblock whiteblock blockshadow earlyresponse">
                    <div className="moveblockicon" />
                    <div className="learnbg">
                      <h2>{labels.LEARN}</h2>
                      <p className="textdesc">{labels.ENROLL_IN_OUR_COURSES}.</p>
                      {/*<p className="count"><span className="bookopen" />179 {labels.LEARNING_MODULES}</p>
                          <p className="count"><span className="signal-stream" /> 66 {labels.WEBINARS}</p> */}
                      <NavLink to="/learn">{labels.SEE_OUR_OFFERING_AND_ENROLL}<span className="icon-red_arrow" /></NavLink>
                    </div>
                  </div>
                  {/* Learn  */}
                </div></div>
              {/*4 even blocks  */}
              {/* Opportunities  */}
              {/* This block is not finished see issue #171
              <div className="wrap">
                <div className="container">
                  <div className="contentblock whiteblock blockshadow">
                    <div className="moveblockicon" />
                    <div className="linktitle">
                      <div><h2>Opportunities</h2>
                        <p>Brought to you by SheTrades Hubs, ITC and our partners</p>
                      </div>
                      <NavLink to="/opportunities" className="btn btn-small" style={{ marginTop: 'auto' }}>All the opportunities<span className="icon-white_arrow" /></NavLink>
                    </div>
                    <ul className="opportunities_list">
                      {tenderData.slice(0, 3).map((tender, idx) => (
                        <li>
                          <Link to={`/tenders/${tender.id}`} >
                            {tender.title}<span className="icon-red_arrow" /></Link>
                          <p>Apply before {moment(tender.dates.closing).format(DATE_FORMAT)}</p>
                          <Link to={`/tenders/${tender.id}`} className="hubtag">
                            <img src="images/handshake.png" alt="" />
                            {tender.tags.map((tag, i) => (
                              <Tag {...tag} key={i} />
                            ))}
                          </Link>
                        </li>
                      ))
                      }
                    </ul>
                  </div>
                </div>
              </div>
              {/* Opportunities  */}
              {/*2x1  */}
              {/* Upcoming events  */}
              {/* This block is not finished see issue #171
              <div className="wrap">
                <div className="container-2even">
                  <div className="contentblock whiteblock blockshadow"><h2>Upcoming events</h2>
                    <div className="moveblockicon" />
                    <ul className="upcomingevents">
                      {eventData.slice(0, 3).map((event, idx) => (
                        <li><p>
                          <b>{moment(event.start).format(DATE_FORMAT)} -{moment(event.end).format(DATE_FORMAT)} {event.title}</b>
                        </p>
                          <span className="hubtag">
                            <img src="images/pin.png" alt="" />
                            {<Region {...event.region} />}</span>
                          <Link to={`/events/${event.id}`} className="hubtag hub_red">
                            read more &amp; register
                            <img src="images/signin.png" alt="" className="imgr" />
                          </Link>
                        </li>
                      ))
                      }

                    </ul>
                    <NavLink to="/events?tab=allevents" className="btn">Check out all the events</NavLink>
                  </div>
                  {/* Upcoming events  /}
                  {/* map hub  */}
              {/* This block is not finished see issue #171
                  <div className="contentblock whiteblock blockshadow mapbg">
                    <div className="moveblockicon" />
                    <h2>{hubInfo.title}</h2>
                    <p>Hubs are where local things happen. You’ve been automatically enrolled in Zambia.</p>
                    <p className="count">{hubInfo.totalMembers} members<br /> {hubInfo.totalForums} discussion group</p>
                    <p className="goal">Goal: to bring {hubInfo.goal} zambian women to trade by 2021</p>
                    <NavLink to={`/hubs/${hubInfo.id}`} className="btn">Check out your hub page</NavLink>
                  </div>
                </div></div>
              {/* map hub  */}
              {/* social subscribe  */}
              {/* This block is not finished see issue #171
              <div className="wrap">
                <h2>The more, the merrier</h2>
                <div className="bgsubscribe">
                  <div>
                    <p>Invite friends ans colleagues to join SheTrades community. Let’s thrive together.</p>
                    <form action="/" method="post" className="subscribe_form">
                      <input name="subscribe_form" defaultValue required type="text" placeholder="Enter email…" id="subscribe_form" />
                      <button name="subscribe_form" value="search" type="submit">subscribe</button>
                    </form>
                  </div>
                  <div className="subscribesocial">
                    <p>Or share SheTrades.com on your favorite social media platform!</p>
                    <div className="socialmediaicons">
                      <NavLink to="/facebook"><span className="icon-facebook" /></NavLink>
                      <NavLink to="/twitter"><span className="icon-twitter" /></NavLink>
                      <NavLink to="/youtube"><span className="icon-youtube" /></NavLink>
                    </div>
                  </div>
                </div>
              </div>
              {/* social subscribe  */}
              {/*articles */}
              {/* This block is not finished see issue #171
              <div className="wrap">
                <div className="container">
                  <h2 style={{ marginTop: '20px', marginBottom: 0 }}>Be inspired</h2>
                  <p>Inspiring things, success stories, trade news for women, open calls for opportunities and highlights, etc.</p>
                  <div className="container-3col">
                    {cardSuccessStories.slice(0, 3).map((success_story, idx) => (
                      <article>
                        <h5 className="articletitle">{success_story.title}</h5><br />
                        <span className="articleimg">
                          <Link to={`/success-stories/${success_story.id}`}>
                            <img src={success_story.image} alt="" />
                          </Link></span>
                        <div className="articledescription">
                          <p>{success_story.description}</p>
                          <Link to={`/success-stories/${success_story.id}`} className="link">
                            Read more <span className="icon-red_arrow" />
                          </Link></div>
                      </article>
                    ))}
                  </div>
                  <span className="buttonsarea">
                    <NavLink to="/success-stories" className="btn" style={{ flex: 1 }}>All the stories &amp; news on the blog</NavLink>
                    <NavLink to="/video" className="btn  hidebtn">All the videos</NavLink></span>
                </div></div>
              {/*articles */}
              {/*buy and sell full */}
              {/* This block is not finished see issue #171
              <div className="wrap">
                <div className="container">
                  <div className="contentblock whiteblock blockshadow">
                    <div className="moveblockicon" />
                    <div className="linktitle linktitlegrey admar">
                      <h2>Buy &amp; Sell</h2>
                      <NavLink to="/offers">Go to Buy &amp; Sell <span className="icon-red_arrow" /></NavLink>
                    </div>
                    <div className="container-3col">
                      {cardOffers.slice(0, 3).map((offer, idx) => (
                        <div className="buysellcard">
                          <h3><img src="images/cart-arrow-down.png" alt="" />Latest relevant offers</h3>
                          <p>{offer.title}</p>
                          <span className="hubtag">
                            {//* {offer.tags.map((tag, i) => (
                          <Tag {...tag} key={i} />
                        ))} /}
                            {<Region {...offer.region} />}
                          </span>
                          <a href="#" className="hubtag hub_red">read more</a>
                          <hr />
                        </div>
                      ))}
                    </div>
                  </div></div>
              </div>
              {/*buy and sell full */}
              {/* learn full */}
              {/* This block is not finished see issue #171
              <div className="wrap">
                <div className="container">
                  <div className="contentblock whiteblock blockshadow">
                    <div className="moveblockicon" />
                    <div className="linktitle">
                      <h2>Learn</h2>
                      <NavLink to="/learn">See our offering and enroll<span className="icon-red_arrow" /></NavLink>
                    </div>
                    <div className="container-2even">
                      <div>
                        <h3>Popular courses</h3>
                        {shuffle(cardVLSCourses).slice(0, 4).map((course, idx) => (
                          <span>
                            <p>{course.coursetitle}</p>
                            <img src={course.courseimageurl} />
                            <div className="progressbar">
                              <b style={{ width: '0%' }}>?</b>
                              <a href={course.buttonurl} target="_blank">Enrol in VLS<span className="icon-white_arrow" /></a>
                            </div>
                          </span>
                        ))}
                      </div>
                      <div>
                        <h3>Courses you may like</h3>
                        {shuffle(cardVLSCourses).slice(4, 9).map((course, idx) => (
                          <span>
                            <p>{course.coursetitle}</p>
                            <img src={course.courseimageurl} />
                            <div className="progressbar">
                              <b style={{ width: '0%' }}>?</b>
                              <a href={course.buttonurl} target="_blank">Enrol in VLS<span className="icon-white_arrow" /></a>
                            </div>
                          </span>
                        ))}
                      </div></div>
                  </div>
                </div>
              </div>
              {/*learn full */}
              {/* SheTrades hubs */}
              {/* This block is not finished see issue #171
              <div className="wrap">
                <div className="container">
                  <div className="contentblock whiteblock blockshadow">
                    <div className="moveblockicon" />
                    <h2>SheTrades hubs</h2>
                    <ul className="hubslist">
                      {shuffle(hubsData).slice(0, 4).map((hub, idx) => (
                        <li><Link to={`/publichub/${hub.id}`}>
                          <img src="images/mapex.png" alt="" />
                          <span>{hub.title}</span></Link></li>
                      ))
                      }

                    </ul>
                  </div></div>
              </div>
              {/* SheTrades hubs */}
              {/* hub control bar */}
              {/* This block is not finished see issue #171
              <div className="wrap">
                <div className="dark_title_bar">
                  <h3><img src="images/grip-lines-vertical.png" alt="" />{hubInfo.title}</h3>
                  <span className="title_bar-controllinks">
                    <a href="#"><img src="images/question-circle-light.png" alt="" /></a>
                    <a href="#"><img src="images/settings.png" alt="" /></a>
                    <a href="#"><img src="images/plus-square-light.png" alt="" /></a>
                  </span>
                </div></div>
              {/* hub control bar */}
              {/* This block is not finished see issue #171
              <div className="wrap">
                {/* events with tabs /}
                <div className="container-2even">
                  <div className="contentblock whiteblock">
                    <div className="dark_title_bar">
                      <h3><img src="images/grip-lines-vertical.png" alt="" />Events</h3>
                    </div>
                    <input className="radiotab" id="first-tab" name="group" type="radio" defaultChecked />
                    <input className="radiotab" id="second-tab" name="group" type="radio" />
                    <div className="tabswrapper">
                      <label className="tab" id="first-tab-title" htmlFor="first-tab">Upcoming</label>
                      <label className="tab" id="second-tab-title" htmlFor="second-tab">Attending</label>
                    </div>
                    <div className="tabscontainer">
                      <div className="tabcontainer" id="first-tabcontainer">
                        <ul className="upcomingevents">
                          {shuffle(eventData).slice(0, 3).map((event, idx) => (
                            <li><p>{moment(event.start).format(DATE_FORMAT)} -{moment(event.end).format(DATE_FORMAT)} {event.title}</p>
                              <span className="hubtag">
                                <img src={event.image} alt="" />
                                {<Region {...event.region} />}</span>
                              <Link to={`/events/${event.id}`} className="hubtag hub_red">
                                read more &amp; register
                                <img src="images/signin.png" alt="" className="imgr" />                    </Link>
                            </li>
                          ))}
                        </ul>
                        <NavLink to="/events?tab=allevents" className="btn">Check out all the events</NavLink>
                      </div>
                      <div className="tabcontainer" id="second-tabcontainer">
                        <h3>Webinar: how to leverage something</h3>
                        <p>There is currently no hub close to you, but we are expanding! Meanwhile, check out all our local hubs.</p>
                      </div>
                    </div></div>
                  {/* events with tabs /}
                  {/* learn with tabs /}
                  <div className="contentblock whiteblock">
                    <div className="dark_title_bar">
                      <h3><img src="images/grip-lines-vertical.png" alt="" />Learn</h3>
                    </div>
                    <input className="radiotab" id="third-tab" name="group2" type="radio" defaultChecked />
                    <input className="radiotab" id="fourth-tab" name="group2" type="radio" />
                    <div className="tabswrapper">
                      <label className="tab" id="third-tab-title" htmlFor="third-tab">Ongoing</label>
                      <label className="tab" id="fourth-tab-title" htmlFor="fourth-tab">Completed</label>
                    </div>
                    <div className="tabscontainer">
                      <div className="tabcontainer" id="third-tabcontainer">

                        {shuffle(cardWebinars).slice(0, 3).map((webinar, idx) => (
                          <div>
                            <p>{webinar.title}</p>
                            <div className="progressbar">
                              <b style={{ width: '0%' }}>0%</b>
                              <Link to={`/webinars/${webinar.id}`}>Read more<span className="icon-white_arrow" /></Link>
                            </div>
                          </div>
                        ))
                        }

                        <Link to="/webinars" className="btn">All the learning offering</Link>
                      </div>
                      <div className="tabcontainer" id="fourth-tabcontainer">
                        <h3>Webinar: how to leverage something</h3>
                        <p>There is currently no hub close to you, but we are expanding! Meanwhile, check out all our local hubs.</p>
                      </div>
                    </div>
                  </div>
                  {/* learn with tabs /}
                </div></div>*/}
            </div>
          )}

          <Modal
            isOpen={this.props.user.userRole == 'member' && isModalOpened}
            onRequestClose={() => this.setState({ isModalOpened: false })}
            smallOnMobile
            buttons={{
              right: {
                text: registrationSuccess.NOLATER,
                className: 'btn btn-small alignbottom',
                onClick: this.onAllow.bind(this),
              },
              left: {
                text: registrationSuccess.CONTINUE,
                className: 'btn btn-small alignbottom',
                onClick: this.onCompleteRegistration.bind(this),
              },
            }}
            children={
              <Fragment>
                <div className='content content_p-margins'>
                  <div className="notification_popup">
                    <div className="frame1">
                      <h2 className="text-red">{labelWelcome.WELCOME}<br /><span className="text-smaller">We are happy to have you here!</span></h2>
                      <p>{labelWelcome.COME_AND_SEE_WHAT}</p>
                      <p />
                    </div>
                  </div>
                </div>
              </Fragment>
            }
          />

        </div>
      </CustomizedDocumentTitle >


    );
  }
}


Dashboard.propTypes = {
  fetchRequest: PropTypes.func.isRequired,
  fetchDashboard: PropTypes.func.isRequired,
  closeRegistrationMessage: PropTypes.func,
  registrationMessage: PropTypes.string,
  showRegistrationMessage: PropTypes.bool,
  showPreloader: PropTypes.bool,
  buttonDisabled: PropTypes.bool.isRequired,
  userSignupMissingRequest: PropTypes.func,
  progress: PropTypes.number,
};

Dashboard.defaultProps = {
  progress: 0,
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
