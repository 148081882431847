import React from "react";
import { connect } from 'react-redux';
import { Redirect, Route} from 'react-router-dom';

const ProtectedRoute = (props) => {

  const page = props.location.pathname + props.location.search;
  // if (props.fromUsers && props.isLoggedIn) {
  //   console.log(page);
  //   return <Redirect to="/dashboard" />;
  // }
  if (!props.fromUsers && !props.isLoggedIn) {
    console.log(page);
    return <Redirect to={`/sign-in?wantsurls=${page}`}  />;
  }
  return <Route {...props} />;
};
const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
});

export default connect(mapStateToProps)(ProtectedRoute);
