import React, { Fragment } from 'react';
import { Form } from 'informed';
import queryString from 'query-string';
import lifecycle from 'react-pure-lifecycle';
import { connect } from 'react-redux';
import NoDataCard from '../components/Card/NoDataCard';
import CardOfMember from '../components/CardOfMember';
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import Member from '../components/Member';
import Modal from '../components/Modal';
import Page from '../components/Page';
//import { getScrollXY, scrollTo } from '../components/Page/Page';
import Preloader from '../components/Preloader';
import { COUNTRY, NAME } from '../components/Search/TopFilters/types';
import TopMessage from '../components/TopMessage';
import languages from '../helpers/languages';
import {
  addReview, fetchMemberDetails,
  // becomeVerifier,
  fetchReviews, fetchVerifiers, fetchVerifierStatus, getVerified, onVerifierMessageClose,
  onVerifierSuccessMessageClose
} from '../store/actions';
import { withRouter } from 'react-router-dom';

var language = languages['en'];
var label = language.verifiers[0];
var common = language.common[0];
var menu = language.menu[0];

const mapStateToProps = (state) => ({
  cards: state.verifiers.verifiers,
  showMessage: state.verifiers.messageShow,
  totalItems: state.verifiers.totalItems,
  sortLabel: state.verifiers.sortLabel,
  detail: state.members.memberDetails,
  verifierStatus: state.verifiers.verifierStatus,
  reviews: state.members.reviews,
  totalReviews: state.members.totalReviews,
  search: state.search,
  verifierSuccessMessage: state.verifiers.verifierSuccessMessage,
  checkStatus:2,
  lan: state.language.lan,
});

const mapDispatchToProps = {
  fetchVerifiers,
  fetchMemberDetails,
  getVerified,
  fetchVerifierStatus,
  // becomeVerifier,
  fetchReviews,
  addReview,
  onVerifierMessageClose,
  onVerifierSuccessMessageClose,
};

const methods = {
  componentDidMount(props) {
    const queryObject = queryString.parse(decodeURIComponent(props.location?.search));
    if (
      typeof queryObject.query === 'string' &&
      queryObject.query.length !== 0
    ) {
      this.setState({
        queryInput: queryObject.query,
      });
    }
    props.fetchVerifiers(window.location.hash,props.search);
    props.fetchVerifierStatus();
    var queryParam = window.location.hash.split('/');
    if (queryParam[3] && queryParam[4]) {
      props.fetchMemberDetails(window.location.hash);
      props.fetchReviews(window.location.hash, 0, 1);
    }
  },
};

const getRenderCards = (props) => (cards) => (
  <div className='cards-list'>
    {cards === null ? (
      <Preloader size='medium' relative />
    ) : cards.length > 0 ? (
      cards.map((card, i) => (
        <CardOfMember
          key={card.id || i}
          verifyInsteadOfAdd='1'
          detailUrl='verification'
          addContactFunction={props.getVerified}
          pageType='verification'
          {...card}
        />
      ))
    ) : (
      <NoDataCard />
    )}
  </div>
);

class BecomeAVerifier extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpened: false,
      formApi: null,
      queryInput: '',
    };

    this.clear = this.clear.bind(this);
    this.onQueryInputChange = this.onQueryInputChange.bind(this);
    this.setFormApi = this.setFormApi.bind(this);
    this.submit = this.submit.bind(this);
  }

  setIsModalOpened(isModalOpened) {
    this.setState({
      ...this.state,
      isModalOpened,
    });
  }

  onConfirm() {
    const { onConfirm } = this.props;
    onConfirm();
    this.setIsModalOpened(false);
  }
  clear() {
    const { onResetClick } = this.props;
    onResetClick();
    this.setState({
      queryInput: '',
    });
  }

  onQueryInputChange(e) {
    this.setState({
      queryInput: e.target.value,
    });
  }

  persist(values, searchUrl = null) {
    const { fetchSearchResult, onSearchSubmit } = this.props;
    const { formApi, queryInput } = this.state;
    formApi.setValues(values);
    onSearchSubmit(values);
    if (searchUrl) {
      fetchSearchResult(searchUrl, values, true);
    } else {
      fetchSearchResult(window.location.hash, values, true);
    }
  }

  setFormApi(formApi) {
    this.setState({
      formApi,
    });
  }

  submit() {
    const { history } = this.props;
    const { formApi, queryInput } = this.state;
    const trimmedQuery = encodeURIComponent(queryInput.trim());
    var pageUrl = window.location.hash.split('/');
    var currentTab = '';
    if (pageUrl[1]) {
      pageUrl = pageUrl[1];
      pageUrl = pageUrl.split('?');
      if (pageUrl[0] && pageUrl[0] === 'publicsearch') {
        var qParam = queryString.parse(decodeURIComponent(pageUrl[1]));
        if (qParam['tab']) {
          currentTab = 'tab=' + qParam['tab'];
        }
      }
    }
    const formState = formApi.getState();
    if (Object.keys(formState.touched) !== 0 || trimmedQuery.length !== 0) {
      const location = `/search?query=${trimmedQuery}&${currentTab}`;
      this.persist(formState.values, location);
      this.props.history.push(location);
    }
  }

  getInitialValues() {
  }


  render() {
    language = languages[this.props.lan];
    label = language.verifiers[0];
    common = language.common[0];
    menu = language.menu[0];
    const toolbar = {
      tabs: {
        values: {
          all: { text: label.ALL_VERIFIERS }
        },
        filterByKeys: (card) => [card.type],
      },
      sort: [
        { orderBy: 'date', order: 'DESC', label: common.RECENT },
         { orderBy: 'date', order: 'ASC', label: common.OLDEST },
      ],
    };
    const { verifierStatus } = this.props;
    const isTouched = verifierStatus === '1' || verifierStatus === '2';

    let buttonText;
    switch (verifierStatus) {
      case '1':
        buttonText = label.PENDING;
        break;
      case '2':
        buttonText = label.VERIFIER;
        break;
      default:
        buttonText = label.BECOME_VERIFIER;
    }

    const buttonStyle = {};
    if (isTouched) {
      buttonStyle.pointerEvents = 'none';
    }

    const { isModalOpened } = this.state;

    return (
      <Fragment>
        <a
          href='javascript: void null'
          onClick={() => this.setIsModalOpened(true)}
          className={
            isTouched ? 'button button_gray button_hover-brand' : 'button'
          }
          style={buttonStyle}
        >
          <span className='button__text'>{buttonText}</span>
        </a>

        <Modal
          isOpen={isModalOpened}
          smallOnMobile
          onRequestClose={() => this.setIsModalOpened(false)}
          buttons={{
            left: {
              text: common.CANCEL,
              className: 'gray',
              onClick: () => this.setIsModalOpened(false),
            },
            right: {
              text: common.CONFIRM,
              className: 'brand',
              onClick: () => this.onConfirm(),
            },
          }}
        >
          <section className='content content_p-margins modal-content__title'>
            <h4>{label.CHANGE_PROFILE}</h4>
            <p>{label.CHANGE_PROFILE_NOTE}</p>
          </section>
        </Modal>
      </Fragment>
    );
  }
}

const PageAllPartners = (props) => {
  language = languages[props.lan];
  label = language.verifiers[0];
  common = language.common[0];
  menu = language.menu[0];
  var roleFlag = false;
  const toolbar = {
    tabs: {
      values: {
        all: { text: label.ALL_VERIFIERS },
        my: { text: label.MY_VERIFIERS },
        me: { text: label.VERIFIED_BY_ME },
      },
      filterByKeys: (card) => [card.type],
    },
    sort: [
      { orderBy: 'date', order: 'DESC', label: common.RECENT },
      { orderBy: 'date', order: 'ASC', label: common.OLDEST },
    ],
  };
  const {
    cards,
    totalItems,
    detail,
    reviews,
    verifierStatus,
    checkStatus,
  } = props;
  let bottom;
  // const onExploreMoreClick = () => {
  //   const top = bottom.getBoundingClientRect().top;
  //   const [, scrollY] = getScrollXY();
  //   scrollTo(top + scrollY - 120);
  // };

  Object.keys(toolbar.tabs.values).forEach(function (key) {
    if (key === 'me' && checkStatus !== 2) {
      delete toolbar.tabs.values[key];
    }
  });

  const { queryInput } = this.state;
  const initialValues = this.getInitialValues();

  return (
    <CustomizedDocumentTitle title={common.MY_DASHBOARD}>
      <div className='transition-page'>
      {/*header content */}
      <header>
        <div className="innerheader innerheader-partners_landing">
          <h1>SHE_TRADES_PARTNERS</h1>
        </div>
      </header>
      {/*header content */}  
      <div className="bggrey">
        <div className="wrap">
          <div className="container-2col">
            <p>OUR_PARTNERS_CONTRIBUTE_VALUABLE_OFFERS</p><a href="#" className="btn">REGISTER_OR_LOGIN_TO_BENEFIT</a>
          </div>
        </div>
      </div>
      <div className="bgwhite" style={{paddingTop: 0}}>
        <div className="wrap">
        <Form
                    onSubmit={this.submit}
                    initialValues={initialValues}
                    getApi={this.setFormApi}
                    className="searchform"
                    render={({ formState: { touched, values } }) => (
                      <Fragment>
                        <input id="searchcheckbox" name="searchcheckbox" type="checkbox" />
                        <label htmlFor="searchcheckbox"><i className="fal fa-search" /></label>
                        <div className="searchformcontainer">
                          <input
                            name="searchform_full"
                            type="text"
                            placeholder="Search for a member, an event, an offer…"
                            id="searchform_full"
                            value={queryInput}
                            onChange={this.onQueryInputChange}
                          />
                          <button name="searchform_full" value="search" type="submit"><i className="fas fa-search" /></button>
                        </div>
                      </Fragment>
                    )}
                  />
          {/* container left bar  */}    
          <input type="checkbox" name="leftbarfilter" id="leftbarfilter" className="leftbarfilter" />
          <label htmlFor="leftbarfilter"><i className="fas fa-filter icoleft" /> FILTER <i className="fal fa-chevron-down icoright" /></label>
          <div className="container-leftsidebar">
            <div>
              <section>
                <h4>PARTNERSHIP_LEVEL</h4>
                <label htmlFor="Transformer">
                  <span className="customcheckbox">
                    <input type="checkbox" name="Transformer" id="Transformer" /><i className="fal fa-check" />
                    <span className="sidebarlabeltextbox">
                      <span className="infoicon" />
                      <p className="sidebarlabeltext">TRANSFORMER</p>
                      <span className="infoicon"> <a href="#"><i className="fas fa-question-circle" /></a></span>
                    </span>
                  </span>
                </label>
                <label htmlFor="Contributor">
                  <span className="customcheckbox">
                    <input type="checkbox" name="Contributor" id="Contributor" /><i className="fal fa-check" />
                    <span className="sidebarlabeltextbox">
                      <span className="infoicon" />
                      <p className="sidebarlabeltext">CONTRIBUTOR</p>
                      <span className="infoicon"> <a href="#"><i className="fas fa-question-circle" /></a></span>
                    </span>
                  </span>
                </label>
                <label htmlFor="Advocate">
                  <span className="customcheckbox">
                    <input type="checkbox" name="Advocate" id="Advocate" /><i className="fal fa-check" />
                    <span className="sidebarlabeltextbox">
                      <span className="infoicon" />
                      <p className="sidebarlabeltext">ADVOCATE</p>
                      <span className="infoicon"><a href="#"><i className="fas fa-question-circle" /></a></span>
                    </span>
                  </span>
                </label>
                <label htmlFor="Verifier">
                  <span className="customcheckbox">
                    <input type="checkbox" name="Verifier" id="Verifier" /><i className="fal fa-check" />
                    <span className="sidebarlabeltextbox">
                      <span className="infoicon"><i className="fas fa-clipboard-check" /></span>
                      <p className="sidebarlabeltext">VERIFIER</p>
                      <span className="infoicon"><a href="#"><i className="fas fa-question-circle" /></a></span>
                    </span>
                  </span>
                </label>
              </section>
              <section>
                <h4>Sector</h4>
                <label htmlFor="BeautyWellness">
                  <span className="customcheckbox">
                    <input type="checkbox" name="BeautyWellness" id="BeautyWellness" /><i className="fal fa-check" />
                    <span className="sidebarlabeltextbox">
                      <span className="infoicon" />
                      <p className="sidebarlabeltext">Beauty &amp; Wellness (34)</p>
                      <span className="infoicon" />
                    </span>
                  </span>
                </label>
                <label htmlFor="Accessories">
                  <span className="customcheckbox">
                    <input type="checkbox" name="Accessories" id="Accessories" /><i className="fal fa-check" />
                    <span className="sidebarlabeltextbox">
                      <span className="infoicon" />
                      <p className="sidebarlabeltext">Accessories (2)</p>
                      <span className="infoicon" />
                    </span>
                  </span>
                </label>
                <label htmlFor="Accomodationfood">
                  <span className="customcheckbox">
                    <input type="checkbox" name="Accomodationfood" id="Accomodationfood" /><i className="fal fa-check" />
                    <span className="sidebarlabeltextbox">
                      <span className="infoicon" />
                      <p className="sidebarlabeltext">Accomodation &amp; food (1)</p>
                      <span className="infoicon" />
                    </span>
                  </span>
                </label>
                <label htmlFor="Agriculture">
                  <span className="customcheckbox">
                    <input type="checkbox" name="Agriculture" id="Agriculture" /><i className="fal fa-check" />
                    <span className="sidebarlabeltextbox">
                      <span className="infoicon" />
                      <p className="sidebarlabeltext">Agriculture, forests,… (35)</p>
                      <span className="infoicon" />
                    </span>
                  </span>
                </label>
                <a href="#" className="loadmore">Load more</a>
              </section>
              <section>
                <h4>SERVICES_OFFERED</h4>
                <label htmlFor="Logistics">
                  <span className="customcheckbox">
                    <input type="checkbox" name="Logistics" id="Logistics" /><i className="fal fa-check" />
                    <span className="sidebarlabeltextbox">
                      <span className="infoicon" />
                      <p className="sidebarlabeltext">Logistics (45)</p>
                      <span className="infoicon" />
                    </span>
                  </span>
                </label>
                <label htmlFor="Financialsupport">
                  <span className="customcheckbox">
                    <input type="checkbox" name="Financialsupport" id="Financialsupport" /><i className="fal fa-check" />
                    <span className="sidebarlabeltextbox">
                      <span className="infoicon" />
                      <p className="sidebarlabeltext">Financial support (2)</p>
                      <span className="infoicon" />
                    </span>
                  </span>
                </label>
                <label htmlFor="Coachingtraining">
                  <span className="customcheckbox">
                    <input type="checkbox" name="Coachingtraining" id="Coachingtraining" /><i className="fal fa-check" />
                    <span className="sidebarlabeltextbox">
                      <span className="infoicon" />
                      <p className="sidebarlabeltext">Coaching &amp; training (10)</p>
                      <span className="infoicon" />
                    </span>
                  </span>
                </label>
                <label htmlFor="Marketanalysis">
                  <span className="customcheckbox">
                    <input type="checkbox" name="Marketanalysis" id="Marketanalysis" /><i className="fal fa-check" />
                    <span className="sidebarlabeltextbox">
                      <span className="infoicon" />
                      <p className="sidebarlabeltext">Market analysis (2)</p>
                      <span className="infoicon" />
                    </span>
                  </span>
                </label>
                <a href="#" className="loadmore">LOAD_MORE</a>
              </section>
              <section>
                <h4>Country</h4>
                <label htmlFor="Namibia">
                  <span className="customcheckbox">
                    <input type="checkbox" name="Namibia" id="Namibia" /><i className="fal fa-check" />
                    <span className="sidebarlabeltextbox">
                      <span className="infoicon" />
                      <p className="sidebarlabeltext">Namibia (1)</p>
                      <span className="infoicon" />
                    </span>
                  </span>
                </label>
                <label htmlFor="Algeria">
                  <span className="customcheckbox">
                    <input type="checkbox" name="Algeria" id="Algeria" /><i className="fal fa-check" />
                    <span className="sidebarlabeltextbox">
                      <span className="infoicon" />
                      <p className="sidebarlabeltext">Algeria (3)</p>
                      <span className="infoicon" />
                    </span>
                  </span>
                </label>
                <label htmlFor="Andorra">
                  <span className="customcheckbox">
                    <input type="checkbox" name="Andorra" id="Andorra" /><i className="fal fa-check" />
                    <span className="sidebarlabeltextbox">
                      <span className="infoicon" />
                      <p className="sidebarlabeltext">Andorra (2)</p>
                      <span className="infoicon" />
                    </span>
                  </span>
                </label>
                <label htmlFor="Angola">
                  <span className="customcheckbox">
                    <input type="checkbox" name="Angola" id="Angola" /><i className="fal fa-check" />
                    <span className="sidebarlabeltextbox">
                      <span className="infoicon" />
                      <p className="sidebarlabeltext">Angola (1)</p>
                      <span className="infoicon" />
                    </span>
                  </span>
                </label>
                <a href="#" className="loadmore">Load more</a>
              </section>
            </div>
            <div>
              <hr />
              <div className="listsection">
               <Page
                name={menu.VERIFIES}
                handleIds={true}
                topfilters={[NAME, COUNTRY]}
                canCreateNew={roleFlag}
                renderAfterTitle={() => (
                  <div className='after-title flex'>
                    <div className='left'>
                      <p className='gray'>{label.INFO}</p>
                    </div>

                    <div className='right'>
                      {/* <div className='buttons buttons_flex'>
                        <a
                          href='javascript: void null'
                          className='button '
                          onClick={onExploreMoreClick}
                        >
                          <span className='button__text'>{label.EXPLORE_MORE}</span>
                        </a>

                        <BecomeAVerifier
                          verifierStatus={verifierStatus}
                          onConfirm={props.becomeVerifier}
                          lan={props.lan}
                        />
                      </div> */}
                    </div>
                  </div>
                )}
                toolbar={toolbar}
                cards={cards}
                fetchFunction={props.fetchVerifiers}
                totalItems={totalItems}
                sortLabel={props.sortLabel}
                search={props.search}
                renderCards={getRenderCards(props)}
                cardsPerPage={6}
                renderAfterPagination={() => (
                  <Fragment>
                    <section className='verification-text row'>
                      <div className='col-md-6'>
                        <div className='content content_p-margins'>
                          <h3>{label.WHO_CAN_BE_VERIFIER}</h3>
                        </div>
                      </div>

                      <div className='col-md-6'>
                        <div className='content content_p-margins'>
                          <p>{label.WHO_CAN_BE_VERIFIER_INFO_ONE}</p>

                          <p>{label.WHO_CAN_BE_VERIFIER_INFO_TWO}</p>
                        </div>
                        <div className='buttons'>
                          <BecomeAVerifier
                            verifierStatus={verifierStatus}
                            onConfirm={props.becomeVerifier}
                            lan={props.lan}
                          />
                        </div> 
                      </div>
                    </section>

                    <TopMessage
                      isShown={props.showMessage}
                      onCloseClick={() => props.onVerifierMessageClose(false)}
                      type='success'
                      text={label.TOP_MESSAGE}
                    />

                    <TopMessage
                      isShown={props.verifierSuccessMessage}
                      onCloseClick={() => props.onVerifierSuccessMessageClose()}
                      type='success'
                      text={language.registration[0].SUCCESS}
                    />
                  </Fragment>
                )}
                {...props}
                renderDetail={() => (
                  <Member
                    {...detail}
                    verifyInsteadOfAdd='1'
                    addContactFunction={props.getVerified}
                    pageType='verificationDetails'
                    reviews={reviews}
                    totalReviews={props.totalReviews}
                    fetchReviewsFunction={props.fetchReviews}
                    addReviewFunction={props.addReview}
                  />
                )}
              />
            </div>
            {/* container left bar  */}  
          </div> 
        </div>
        <div className="dotbg l2 dotb" />
          </div>
          </div>
        </div>
      </CustomizedDocumentTitle>
    );
}


PageAllPartners.defaultProps = {
  cards: [],
};

const PageAllPartnersCycle = lifecycle(methods)(PageAllPartners);
const List = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageAllPartnersCycle);

export default withRouter(List);
