import {
  FETCHED_BELL_NOTIFICATIONS,
  SET_NOTIFICATION_TAB_DATA,
  APPEND_NOTIFICATIONS,
  PROFILE_BELL_NOTIFICATION_COUNT,
} from '../store/actions';

const initialState = {
  bellNotificationItems: [],
  notificationBellCount: 0,
  notificationCount: 0,
  notificationItems: null, // show preloader if null, hide preloader if array or empty array
  offset: 0,
  totalCount: 0,
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHED_BELL_NOTIFICATIONS:
      return {
        ...state,
        bellNotificationItems: action.payload.data,
        notificationCount: action.payload.total,
      };

    case SET_NOTIFICATION_TAB_DATA:
      return {
        ...state,
        notificationItems: action.payload.data,
      };

    case APPEND_NOTIFICATIONS:
      const { items, offset, totalCount } = action.payload;
      const { notificationItems } = state;
      return {
        ...state,
        notificationItems:
          notificationItems !== null ? [...notificationItems, ...items] : items,
        offset,
        totalCount,
      };

    case PROFILE_BELL_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationBellCount: action.payload,
      };

    default:
      return state;
  }
};

export default notificationsReducer;
