import React, { Component } from 'react';
import InputRange from 'react-input-range';
import PropTypes from 'prop-types';

export default class PriceSlider extends Component {
  static propTypes = {
    results: PropTypes.array,
    range: PropTypes.object,
    onRangeChange: PropTypes.func,
  };

  static getDiagramData(results) {
    const OPTIMAL_COLUMN_COUNT = 12;
    const columnCount = Math.min(
      Math.ceil(results.length / 5),
      OPTIMAL_COLUMN_COUNT
    );
    const valuesMax = results.reduce(
      (acc, { value }) => (acc > value ? acc : value),
      0
    );
    const valuesMin = results.reduce(
      (acc, { value }) => (acc < value ? acc : value),
      valuesMax
    );
    const step =
      0.01 * Math.floor((100 * (valuesMax - valuesMin)) / columnCount);

    let min = valuesMin;
    let additionalColumnsBelowValuesCount = 0;
    while (
      additionalColumnsBelowValuesCount <
        Math.round(0.5 * (OPTIMAL_COLUMN_COUNT - columnCount)) &&
      min - step > 0
    ) {
      min -= step;
      additionalColumnsBelowValuesCount++;
    }
    const additionalColumnsAboveValuesCount =
      OPTIMAL_COLUMN_COUNT - additionalColumnsBelowValuesCount - columnCount;
    const max = valuesMax + additionalColumnsAboveValuesCount * step;

    const columns = [];
    for (let i = 0; i < OPTIMAL_COLUMN_COUNT; i++) {
      const colMin = min + i * step;
      const colMax =
        min + (i + 1) * step - (i + 1 === OPTIMAL_COLUMN_COUNT ? 0 : 1e-2);
      const count = results.filter(
        ({ value }) => value <= colMax && value >= colMin
      ).length;
      columns.push({
        colMin,
        colMax,
        count,
        height: `${(100 * count) / results.length}%`,
      });
    }

    return { max, min, columns };
  }

  render() {
    const { results, range, onRangeChange, onRangeChangeEnd } = this.props;
    const { max, min, columns } = PriceSlider.getDiagramData(results);
    const rangeToShow = {
      min: range ? Math.max(range.min, min) : min,
      max: range ? Math.min(range.max, max) : max,
    };

    return (
      <div className='price-slider'>
        <div className='h3'>
          $ {Math.round(rangeToShow.min)} - {Math.round(rangeToShow.max)} $
        </div>

        <div className='price-slider-diagram'>
          {columns.map(({ colMax, colMin, count, height }, i) => (
            <div
              key={i}
              style={{ height }}
              title={`$${colMin} - $${colMax}: ${count}`}
              className='price-slider-column'
            />
          ))}
        </div>

        <InputRange
          minValue={min}
          maxValue={max}
          onChange={onRangeChange}
          onChangeComplete={onRangeChangeEnd}
          value={rangeToShow}
        />
      </div>
    );
  }
}
