import React from 'react';
import { connect } from 'react-redux';

const PageUnderMaintenance = (props) => (
  <section className='detail detail_big'>
    <div style={{ textAlign: 'center' }}>
      <h1 classname="h2">Page Under Maintenance</h1>
      <div classname="detail__description">
        <img style={{ display: 'inline' }} src="images/error_under_maintenance.png" alt="Error under maintenance" />
      </div>
    </div>
  </section>
);

const mapStateToProps = (state) => ({
  t: state.language.t,
});

export default connect(mapStateToProps)(PageUnderMaintenance);
