import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { NavLink, withRouter } from 'react-router-dom';
import Dropdown from '../../../components/Dropdown';

const mapStateToProps = (state) => ({
  isMobile: state.responsive.isMobile,
});

const TabsList = ({ isMobile, items, activeTabName }) => {
  if (!items || items.length === 0) {
    return null;
  }

  if (isMobile) {
    return (
      <div className='sort-by'>
        <Dropdown
          exact
          closeOnClick
          addClassName='dropdown_white'
          top={activeTabName}
          links={items}
        />

        <i className='icon-dropdown' />
      </div>
    );
  }

  return (
    <ul className='toolbar__tabs'>
      {items.map(({ to, isActive, active, onClick, title }, i) => {
        if (to) {
          return (
            <li key={i}>
              <NavLink to={to} isActive={isActive} onClick={onClick}>
                {title}
              </NavLink>
            </li>
          );
        }
        return (
          <li key={i}>
            <a
              href='javascript:void null'
              onClick={onClick}
              className={classNames({ active })}
            >
              {title}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default withRouter(connect(mapStateToProps)(TabsList));
