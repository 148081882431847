import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import GalleryFullscreen from '../../../../../../../../../../components/Gallery/components/Fullscreen';

class TypePhotos extends React.Component {
  constructor() {
    super();
    this.state = {
      galleryOpened: false,
    };
    this.toggleGallery = this.toggleGallery.bind(this);
    this.openGallery = this.toggleGallery.bind(this, true);
  }

  toggleGallery(galleryOpened) {
    this.setState({
      ...this.state,
      galleryOpened,
    });
  }

  render() {
    const { photos, t } = this.props;
    const { galleryOpened } = this.state;

    let photosClass = 'chat-photos';
    let photoClass = 'chat-photo';
    let firstPhotoDescription;

    if (photos.length <= 2) {
      photoClass += ' chat-photo_2x';
    }

    if (photos.length === 1) {
      const firstPhoto = photos[0];
      photosClass += ' chat-photos_detailed';
      firstPhotoDescription = (
        <div className='chat-first-photo-description'>
          <span className='chat-source-link' onClick={this.openGallery}>
            {firstPhoto.name}
          </span>

          <div className='info'>{firstPhoto.size}</div>

          <a href={firstPhoto.full} target='_blank' rel="noreferrer">
            {t.common[0].DOWNLOAD}
          </a>
        </div>
      );
    }

    return (
      <div className={photosClass}>
        {photos.map(({ id, thumb, full, size, name }) => (
          <a href={full} target='_blank' rel="noreferrer" className={photoClass} key={id}>
            <img src={thumb} alt='' />

            <i className='icon-download' />
          </a>
        ))}

        {firstPhotoDescription}

        <GalleryFullscreen
          modalOnly
          slides={photos.map(({ full }) => ({ thumb: full }))}
          isOpened={galleryOpened}
          onToggle={this.toggleGallery}
        />
      </div>
    );
  }
}
TypePhotos.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.object).isRequired,
  t: PropTypes.object,
};

const mapStateToProps = (state) => ({ t: state.language.t });

export default connect(mapStateToProps)(TypePhotos);
