import React from 'react';
import PropTypes from 'prop-types';
import DownloadLink from '../../../../../../../../../../components/DownloadLink';

const TypeDocuments = ({ documents }) => (
  <div className='download-links'>
    {documents.map((el) => (
      <DownloadLink key={el.id} noBorder {...el} />
    ))}
  </div>
);

TypeDocuments.propTypes = {
  documents: PropTypes.array.isRequired,
};

export default TypeDocuments;
