import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Slick from 'react-slick';

const slickDefaultConfig = {
  lazyLoad: true,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  speed: 600,
};

const mapStateToProps = (state) => ({
  isMobile: state.responsive.isMobile,
  isPhone: state.responsive.isPhone,
});

const GallerySlide = ({ thumb, caption, onClick }) => (
  <figure className='gallery-thumb' onClick={onClick}>
    <div className='gallery-thumb__hover-area'>
      <div className='gallery-thumb__image'>
        <img src={thumb} alt={caption} draggable='false' title={caption} />
      </div>

      {caption && <figcaption className='gray'>{caption}</figcaption>}
    </div>
  </figure>
);

const defaultSlickOverrides = ({ isMobile, isPhone }) =>
  isMobile
    ? {
        slidesToShow: isPhone ? 1 : 2,
      }
    : null;

const Gallery = ({
  slides,
  getSlickOverrides = defaultSlickOverrides,
  renderSlide = GallerySlide,
  isMobile,
  isPhone,
}) => {
  const configOverrides = getSlickOverrides({ isMobile, isPhone });
  const finalSlickConfig = Object.assign(
    {},
    slickDefaultConfig,
    configOverrides
  );
  return (
    <Slick {...finalSlickConfig}>
      {slides.map(({ id, ...rest }) => (
        <div className='slick-slide' key={id}>
          {renderSlide(rest)}
        </div>
      ))}
    </Slick>
  );
};

Gallery.propTypes = {
  slides: PropTypes.array,
  getSlickOverrides: PropTypes.func,
  renderSlide: PropTypes.func,
};

export default connect(mapStateToProps)(Gallery);
