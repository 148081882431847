import {
  APPLOZIC_SOCKET_EVENT,
  APPEND_CURRENT_CHAT,
  APPEND_USER_OR_GROUP,
  READ_UPDATE,
  RELOAD_CHAT,
  RESET_CHATS,
  SET_APPLOZIC_API,
  SET_CHATS,
  SET_TOTAL_UNREAD_COUNT,
  SET_CONTACTS_OPTIONS,
  SET_GROUPS,
  SET_GROUPS_USER_LIST,
  TOGGLE_APPLOZIC_API_FETCHING,
  TYPING_STATUS_CHANGE,
  UPDATE_MESSENGER_CACHE,
  SET_CONTACTS_OPTIONS_FOR_SEARCH,
} from '../store/actions';
import _ from 'lodash';
import { distinguishGroupId } from '../constants';

const initialState = {
  applozicApi: null,
  applozicApiFetching: false,
  cache: {},
  chats: { message: [], groupFeeds: [], userDetails: [], chatList: null },
  contactOptions: [],
  groupsList: [],
  conversation: [],
  totalUnreadCount: 0,
  typingStatus: {},
  groupUsers: [],
  contactDropOptions: null,
  currentChatFinished: false,
  currentChatId: null,
  currentChatIsGroup: false,
  currentChatMessages: null,
  currentChatTitle: null,
  currentChatMembers: null,
  oldestLoadedMessageTime: null,
};

export const getUserOrGroup = ({
  groupFeeds,
  userDetails,
  isGroup,
  chatId,
}) => {
  if (isGroup) {
    return _.find(groupFeeds, ({ clientGroupId: id }) => id === chatId);
  }
  return _.find(userDetails, ({ userId }) => userId === chatId);
};

const isMessageFromInitialFeed = (message) => 'key' in message;

const buildChatList = ({ message, userDetails, groupFeeds }) => {
  const chats = {};
  message
    .sort((a, b) => Math.sign(b.createdAtTime - a.createdAtTime))
    .forEach((el) => {
      const {
        clientGroupId,
        contactIds,
        read,
        message,
        fileMeta,
        createdAtTime,
        type,
      } = el;
      const isGroup = !!clientGroupId;
      const id = clientGroupId || contactIds;
      const isFromInitialFeed = isMessageFromInitialFeed(el);
      const unreadCountAdd =
        type === 5 ? 0 : isFromInitialFeed ? 0 : read ? 0 : 1;
      const userOrGroup = getUserOrGroup({
        userDetails,
        groupFeeds,
        chatId: String(id),
        isGroup,
      });
      const text = fileMeta
        ? `<i class="icon-file"></i> ${fileMeta.name}`
        : message;
      if (chats[id]) {
        chats[id].unread += unreadCountAdd;
      } else if (isGroup) {
        chats[id] = {
          id,
          name: userOrGroup ? userOrGroup.name : '', // TODO: query API for new group
          groupAvatars: userOrGroup
            ? userOrGroup.membersId.map((id) => {
                const user = getUserOrGroup({ userDetails, chatId: id });
                return user && user.imageLink;
              })
            : null,
          time: createdAtTime,
          unread: unreadCountAdd + (userOrGroup ? userOrGroup.unreadCount : 0),
          text,
        };
      } else {
        chats[id] = {
          id,
          online: userOrGroup && userOrGroup.connected,
          name: userOrGroup ? userOrGroup.userName : '', // TODO: query API for new user
          avatar: userOrGroup ? userOrGroup.imageLink : '', // TODO: query API for new user
          time: createdAtTime,
          unread: unreadCountAdd + (userOrGroup ? userOrGroup.unreadCount : 0),
          text,
        };
      }
    });
  return Object.values(chats).sort((a, b) => Math.sign(b.time - a.time));
};

const getTotalUnreadCount = (chatList) =>
  chatList.reduce((prev, curr) => prev + curr.unread, 0);

const messengerReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_APPLOZIC_API:
      return {
        ...state,
        applozicApi: payload,
      };

    case UPDATE_MESSENGER_CACHE:
      const { key, value, isPending } = payload;
      return {
        ...state,
        cache: {
          ...state.cache,
          [key]: Object.assign({}, state.cache[key], { value, isPending }),
        },
      };

    case APPLOZIC_SOCKET_EVENT:
      const { type: applozicEventType, response } = payload;

      switch (applozicEventType) {
        case 'messageSent':
        case 'messageReceived': {
          const { message: messageObj } = response;
          const { from, to, message, timeStamp } = messageObj;
          const isGroup = distinguishGroupId(to);
          const chatId = isGroup ? to : from || to;
          const newMessage = {
            [isGroup ? 'clientGroupId' : 'contactIds']: chatId,
            createdAtTime: timeStamp,
            message,
            type: applozicEventType === 'messageSent' ? 5 : 4,
          };
          const newMessageList = [newMessage, ...state.chats.message];
          const isMessageFromThisChat = isGroup
            ? String(to) === state.currentChatId
            : (applozicEventType === 'messageSent' &&
                to === state.currentChatId) ||
              (applozicEventType === 'messageReceived' &&
                from === state.currentChatId);
          const chatList = buildChatList({
            ...state.chats,
            message: newMessageList,
          });
          return {
            ...state,
            totalUnreadCount: getTotalUnreadCount(chatList),
            currentChatMessages: isMessageFromThisChat
              ? [...state.currentChatMessages, response.message]
              : state.currentChatMessages,
            chats: {
              ...state.chats,
              message: newMessageList,
              chatList,
            },
          };
        }

        case 'conversationRead':
          // case 'conversationReadFromOtherSource':
          const { message } = response;
          const [userId] = message.split(',');

          if (
            (!state.currentChatIsGroup && state.currentChatId !== userId) ||
            (state.currentChatIsGroup &&
              !_.find(
                state.currentChatMembers,
                ({ userId: lUserId }) => lUserId === userId
              ))
          ) {
            return state;
          }

          const unreadMessagesExist = state.currentChatMessages.some(
            ({ read }) => !read
          );
          if (unreadMessagesExist) {
            return {
              ...state,
              currentChatMessages: state.currentChatMessages.map((el) => ({
                ...el,
                status: 5,
              })), // TODO: status 5 means outcoming message read. possible error for incoming messages.
            };
          }

          return state;

        case 'userConnect':
          const newUsers = state.chats.userDetails.map((el) => {
            if (el.userId === response) {
              return { ...el, connected: true };
            }
            return el;
          });
          return {
            ...state,
            chats: {
              ...state.chats,
              userDetails: newUsers,
              chatList: buildChatList({
                ...state.chats,
                userDetails: newUsers,
              }),
            },
          };

        default:
          return state;
      }

    case TOGGLE_APPLOZIC_API_FETCHING:
      return {
        ...state,
        applozicApiFetching: payload,
      };

    case APPEND_CURRENT_CHAT:
      const oldMessages = state.currentChatMessages || [];
      const { message: message3, userDetails: userDetails3 } = payload;
      const currentChatMessages = [...message3, ...oldMessages].sort(
        (a, b) => a.createdAtTime - b.createdAtTime
      );
      return {
        ...state,
        currentChatFinished: !message3.length,
        currentChatMessages,
        currentChatMembers: userDetails3,
        oldestLoadedMessageTime: currentChatMessages[0]
          ? currentChatMessages[0].createdAtTime
          : null,
      };

    case RELOAD_CHAT:
      const { chatId, isGroup } = payload;
      const userOrGroup = getUserOrGroup({ ...state.chats, chatId, isGroup });
      return {
        ...state,
        currentChatFinished: false,
        currentChatTitle: !userOrGroup
          ? ''
          : isGroup
          ? userOrGroup.name
          : userOrGroup.userName,
        currentChatId: chatId,
        currentChatIsGroup: isGroup,
        currentChatMessages: null,
      };

    case SET_CONTACTS_OPTIONS:
      return {
        ...state,
        contactOptions: payload.data,
      };

    case SET_GROUPS:
      return {
        ...state,
        groupsList: payload.data,
      };

    case SET_GROUPS_USER_LIST:
      return {
        ...state,
        groupUsers: payload.data,
      };

    case SET_TOTAL_UNREAD_COUNT:
      return {
        ...state,
        totalUnreadCount: payload,
      };

    case SET_CHATS:
      const { message, userDetails, groupFeeds } = payload;
      const userOrGroup2 = getUserOrGroup({
        userDetails,
        groupFeeds,
        chatId: state.currentChatId,
        isGroup: state.currentChatIsGroup,
      });
      return {
        ...state,
        currentChatTitle: !userOrGroup2
          ? ''
          : state.currentChatIsGroup
          ? userOrGroup2.name
          : userOrGroup2.userName,
        chats: {
          message,
          userDetails,
          groupFeeds,
          chatList: buildChatList({ message, userDetails, groupFeeds }),
        },
      };

    case SET_CONTACTS_OPTIONS_FOR_SEARCH:
      return {
        ...state,
        contactDropOptions: action.payload.data,
      };

    case APPEND_USER_OR_GROUP:
      const { user, group } = payload;
      const newUserDetails = user
        ? [...state.chats.userDetails, user]
        : state.chats.userDetails;
      const newGroupFeeds = group
        ? [...state.chats.groupFeeds, group]
        : state.chats.groupFeeds;
      return {
        ...state,
        chats: {
          ...state.chats,
          userDetails: newUserDetails,
          groupFeeds: newGroupFeeds,
          chatList: buildChatList({
            message: state.chats.message,
            userDetails: newUserDetails,
            groupFeeds: newGroupFeeds,
          }),
        },
      };

    case RESET_CHATS:
      return initialState;

    case READ_UPDATE:
      const currentChat = _.find(
        state.chats.chatList,
        ({ id }) => String(id) === action.payload.chatId
      );
      if (!currentChat) {
        return state;
      }
      const message4 = state.chats.message.map((el) => {
        const { clientGroupId, contactIds } = el;
        if (
          ((action.payload.isGroup &&
            String(clientGroupId) === action.payload.chatId) ||
            contactIds === action.payload.chatId) &&
          !el.read
        ) {
          return { ...el, read: true };
        }
        return el;
      });
      const userDetails4 = state.chats.userDetails.map((el) => {
        if (
          action.payload.isGroup ||
          String(el.userId) !== String(action.payload.chatId)
        ) {
          return el;
        }
        return {
          ...el,
          unreadCount: 0,
        };
      });
      const groupFeeds4 = state.chats.groupFeeds.map((el) => {
        if (
          !action.payload.isGroup ||
          String(el.clientGroupId) !== String(action.payload.chatId)
        ) {
          return el;
        }
        return {
          ...el,
          unreadCount: 0,
        };
      });
      const chatList2 = buildChatList({
        message: message4,
        userDetails: userDetails4,
        groupFeeds: groupFeeds4,
      });

      return {
        ...state,
        totalUnreadCount: getTotalUnreadCount(chatList2),
        chats: {
          message: message4,
          userDetails: userDetails4,
          groupFeeds: groupFeeds4,
          chatList: chatList2,
        },
      };

    default:
      return state;
  }
};

export default messengerReducer;
