import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import Preloader from '../components/Preloader';
import languages from '../helpers/languages';

var language = languages['en'];
var common = language.common[0];
var dashboard = language.dashboard[0];
var discover =language.Offerings[0];

const mapStateToProps = (state) => {
  return {
    lan: state.language.lan,
    user: state.auth.user,
    userRole: state.auth.user.userRole,
  };
};

class Discover extends React.Component {
  state = {
    isModalOpened: false
  };

  componentDidMount() {
    
  }

  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    const { showPreloader } = this.props;
    const {
      isModalOpened,
    } = this.state;
    
  const url =
  'https://learn.shetrades.com/local/itcacademy/setupuser.php?academyid=9&autoissuerid=2&wantsurl=/my/index.php#showdashcat';

  const satUrl =
  'https://learn.shetrades.com/mod/itcselfassesment/view.php?id=23029';

const attributes =  { href: url };
const satAttributes= { href: satUrl };
    return (
      <CustomizedDocumentTitle title={common.MY_DASHBOARD}>
      <div className='transition-page'>
        {showPreloader ? (
          <Preloader size='medium' relative />
        ) : (
           <div>
              {/*header content */}
              <header>
                <div className="innerheader innerheader-aboutouroffering">
                  <h1>OUR_GATEWAY_TO_BUSINESS_SHE_TRADESCOM</h1>
                </div>
              </header>
              {/*header content */}  
              {/*welcome text */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container-2col">
                    <div>SHE_TRADESCOM_IS_OUR_DIGITAL_ONE_STOP_SHOP_FOR_WOMEN_ENTREPRENEURs</div>
                    <div />
                  </div>
                </div>
              </div>
              {/*welcome text */}
              {/*access */}
              <div className="bgwhite">
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon bigicon-sellandbuy bigiconhide" />
                    <div className="bigicontextblock"><h2>Access the Global Market with 
                      <Link to="/buysell">SELL_BUY <i className="fal fa-arrow-right" /></Link></h2> 
                      <p>GET_STARTED_BY_CREATING_YOUR_ONLINE_BUSINESS_PROFILE_ON_SHE_TRADESCOM</p></div>
                  </div>
                </div>
              </div>
              {/*access */}
              {/*build */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon bigicon-network bigiconhide" />
                    <div className="bigicontextblock"><h2>GROW_YOUR_NETWORK</h2> 
                      <p>ONCE_YOUVE_REGISTERED_YOUR_COMPANY_PROFILE</p>
                      <Link to="/community">MORE_ABOUT_OUR_COMMUNITY <i className="fal fa-arrow-right" /></Link>
                    </div>
                  </div>
                </div>
              </div>
              {/*build */}
              {/*free block */}
              <div className="bgdark">
                <div className="wrap">
                  <div className="blueblockbgcircles">
                    <div className="container-2col">
                      <div>
                        <h2>IT_ALL_FREE_FOR_OUR_MEMBERS</h2> 
                        <p>ACCESS_ALL_THE_TOOLS_AND_RESOURCES_AVAILABLE_TO_YOU_FOR_FREE</p></div>
                      <Link to="/sign-up" className="btn">REGISTER_OR_LOGIN</Link>
                    </div> 
                  </div>
                </div>
              </div>
              {/*free block */}
              {/*improve */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon bigicon-learn bigiconhide" />
                    <div className="bigicontextblock"><h2>ENROL_ON_ONE_OF_OUR_MANY <a {...attributes} target='_blank' rel="noreferrer" >E_LEARNING_COURSEs <i className="fal fa-arrow-right" /></a></h2> 
                      <p>LEARN_NEW_SKILLS_AND_BROADEN_YOUR_KNOWLEDGE</p>

                    </div>
                  </div>
                </div>
              </div>
              {/*improve */}
              {/*grow */}
              <div className="bgwhite">
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon  bigicon-grow bigiconhide" />
                    <div className="bigicontextblock"><h2>EVENTS_AND_OPPORTUNITIES_TO_GROW_YOUR_BUSINESs</h2> 
                      <p>ON_SHE_TRADESCOM_YOU_CAN_FIND_EVENTS_AND_OPPORTUNITIES</p>
                      <div className="container-ll-bottomlinks">
                        <Link to="/events?tab=upcoming">SEE_ALL_THE_OPEN_OPPORTUNITIES <i className="fal fa-arrow-right" /></Link>
                        <Link to="/">SEE_ALL_THE_UPCOMING_EVENTS <i className="fal fa-arrow-right" /></Link>
                      </div></div>
                  </div>
                </div>
              </div>
              {/*grow */}
              {/*Peruse */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon  bigicon-policy_maker bigiconhide" />
                    <div className="bigicontextblock"><h2>FOR_THE_LATEST_DATA_AND_POLICY_UPDATEs 
                      <Link to="/outlook">POLICY <i className="fal fa-arrow-right" /></Link></h2> 
                      <p>THE_FIRST_TOOL_OF_ITS_KIND,_SHE_TRADES_OUTLOOK_EXISTS</p> 
                    </div>
                  </div>
                </div></div>
              {/*Peruse */}
              {/*inspiration */}
              <div className="bgwhite">
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon  bigicon-stories bigiconhide" />
                    <div className="bigicontextblock"><h2>HEAR_FROM_REAL_WOMEN_IN_OUR_COMMUNITY 
                      <Link to="/blog">BLOG <i className="fal fa-arrow-right" /></Link></h2> 
                      <p>STAY_UP_TO_DATE_WITH_SHE_TRADES_ACTIVITIES_PROJECTS_AND_HUBS</p> 
                    </div>
                  </div>
                </div></div>
              {/*inspiration */}
              {/*help */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container">
                    <span className="bigicon  bigicon-help bigiconhide" />
                    <div className="bigicontextblock"><h2>FIND_ALL_THE_SUPPORT_YOU_NEED_IN_OUR 
                      <Link to="/helpcenter">HELP_CENTER <i className="fal fa-arrow-right" /></Link></h2> 
                      <p>OUR_HELP_CENTRE_IS_WELL_EQUIPPED_WITH_USEFUL_TOOLS_AND_RESOURCES</p> 
                    </div>
                  </div>
                </div>
              </div>
              {/*help */}
            </div>
        )}
      </div>
    </CustomizedDocumentTitle>
    );
  }
}

Discover.propTypes = {
  showPreloader: PropTypes.bool,
};

export default connect(mapStateToProps)(Discover);
