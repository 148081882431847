import { BUSINESS, COACH_MESSAGE_HIDE, PURGE_MEMBERS } from '../store/actions';

const initialState = {
  business: null,
  messageShow: false,
};

export default function business(state = initialState, action) {
  switch (action.type) {
    case BUSINESS:
      return {
        ...state,
        business: action.payload.data,
        totalItems: action.payload.count,
        sortLabel: action.payload.sortLabel,
        messageShow: action.payload.data.messageShow,
      };
    case COACH_MESSAGE_HIDE:
      return {
        ...state,
        messageShow: false,
      };

    case PURGE_MEMBERS:
      const { memberSection } = action.payload;
      if (memberSection === 'business') {
        return initialState;
      }
      return state;
    default:
      return state;
  }
}
