import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import moment from 'moment';
import VideoPlayButton from '../../components/VideoPlayButton';

const DATE_FORMAT = 'MMM DD, YYYY';

const CardForNews = (props) => {
  const {
    image,
    title = <span>&nbsp;</span>,
    date = <span>&nbsp;</span>,
    isLoading,
    parentLocation,
    id,
    isVideo,
  } = props;

  const to = !isVideo
    ? `${parentLocation}/${id}`
    : `${parentLocation}/${title}/${id}`;

  return (
    <section
      className={classNames('dashboard-card-for-news', 'list-animation-item', {
        loading: isLoading,
      })}
    >
      <Link to={to} className='dashboard-card-for-news__image'>
        {!isLoading && isVideo ? ( // eslint-disable-line no-nested-ternary
          <VideoPlayButton height={100} href={image} index={id} />
        ) : !isLoading ? (
          <img src={image} alt='' />
        ) : (
          ''
        )}
      </Link>

      <div className='dashboard-card-for-news__right'>
        <p className='info'>
          {
            // eslint-disable-next-line no-nested-ternary
            isLoading ? date : date ? moment(date).format(DATE_FORMAT) : ''
          }
        </p>

        <Link to={to} className='dashboard-card-for-news__title'>
          <h4>{title}</h4>
        </Link>
      </div>
    </section>
  );
};

CardForNews.propTypes = {
  image: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  date: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
  ]),
  isLoading: PropTypes.bool,
  parentLocation: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isVideo: PropTypes.bool,
};

CardForNews.defaultProps = {
  image: '',
  title: <span>&nbsp;</span>,
  date: <span>&nbsp;</span>,
  isLoading: false,
  parentLocation: '',
  id: '',
  isVideo: false,
};

export default CardForNews;
