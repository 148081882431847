import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Tag from '../../../components/Tag';

const mapStateToProps = (state) => ({
  isMobile: state.responsive.isMobile,
  t: state.language.t,
});

const DealLink = (props) => {
  const { to, status, title, value, isMoblie, t } = props;

  const name = `${title}`;

  return (
    <Link to={to} className='download-link' title={name}>
      {status && (
        <span className='download-link__status'>
          <Tag
            type={status === 1 ? 'success' : 'error'}
            text={status === 1 ? t.common[0].IN_PROCESS : t.common[0].CLOSED}
          />
        </span>
      )}

      <span className='download-link__name'>{name}</span>

      {!isMoblie && (
        <span className='download-link__download'>{t.common[0].SHOW_MORE}</span>
      )}
    </Link>
  );
};

DealLink.propTypes = {
  to: PropTypes.string.isRequired,
  status: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  t: PropTypes.object,
};

DealLink.defaultProps = {
  status: null,
};

export default connect(mapStateToProps)(DealLink);
