import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Editor from './components/Editor';
import TakePhoto from './components/TakePhoto';
import noAvatarImage from '../../../static/images/noavatar.svg';
import languages from '../../../helpers/languages';
import TopMessage from '../../../components/TopMessage';

var language = languages['en'];
var common = language.common[0];

const mapStateToProps = (state) => ({
  imageUploadError: state.setting.profileImageError,
  isMobile: state.responsive.isMobile,
  lan: state.language.lan,
});

class UploadPhoto extends Component {
  static propTypes = {
    imageExisting: PropTypes.string,
    imageUploadError: PropTypes.bool,
    maxFileSize: PropTypes.number,
    maxHeight: PropTypes.number,
    maxWidth: PropTypes.number,
    minWidth: PropTypes.number,
    minHeight: PropTypes.number,
    onChange: PropTypes.func,
    value: PropTypes.object,
    isMobile: PropTypes.bool,
  };

  static defaultProps = {
    maxFileSize: 2e6,
    maxWidth: 1e8,
    maxHeight: 1e8,
    minWidth: 200,
    minHeight: 200,
    value: {
      photoFile: null,
      editorState: {},
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      isEditorOpened: false,
      isTakePhotoOpened: false,
      photoFile: null,
      photoFileUrl: '',
      showAlert: false,
    };

    this.onEditorTakePhotoClick = this.onEditorTakePhotoClick.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.onTakePhotoChange = this.onTakePhotoChange.bind(this);
  }

  setIsTakePhotoOpened(isTakePhotoOpened) {
    this.setState({
      ...this.state,
      isTakePhotoOpened,
    });
  }

  setIsEditorOpened(isEditorOpened) {
    this.setState({
      isEditorOpened,
    });
  }

  async onTakePhotoChange({ blob, url }) {
    if (await this.validateImage({ blob, url })) {
      this.setState({
        hasError: false,
        isTakePhotoOpened: false,
        isEditorOpened: true,
        photoFile: blob,
        photoFileUrl: url,
      });
    } else {
      this.setState({
        hasError: true,
        showAlert: true,
      });
    }
  }

  async onFileChange(e) {
    const fileList = e.target.files;

    if (!fileList.length) return;

    const [firstFile] = fileList;
    const url = URL.createObjectURL(firstFile);
    e.target.value = null;

  
    await this.validateImage({ blob: firstFile, url })
    .then(() => { 
      this.setState({
        hasError: false,
        photoFile: firstFile,
        photoFileUrl: url,
        isEditorOpened: true,
        showAlert:false
      });
    }).catch((e) => {
      console.log(e);
      this.setState({
        hasError: true,
        showAlert: true,
      })
    });
  }

  onEditorTakePhotoClick() {
    this.setState({
      isEditorOpened: false,
      isTakePhotoOpened: true,
    });
  }

  onEditorChange(editorState) {
    const { onChange } = this.props;
    const { photoFile } = this.state;
    onChange({ photoFile, editorState });
    this.setIsEditorOpened(false);
  }

  validateImage({ blob, url }) {
    const {
      maxFileSize,
      maxHeight,
      maxWidth,
      minHeight,
      minWidth,
    } = this.props;
    const image = new Image();
    return new Promise((resolve) => {
      image.onload = () => {
        const { naturalWidth, naturalHeight } = image;
        if (
          blob.size > maxFileSize ||
          naturalWidth > maxWidth ||
          naturalHeight > maxHeight ||
          naturalWidth < minWidth ||
          naturalHeight < minHeight
        ) {
          resolve(false);
        } else {
          resolve(true);
        }
      };
      image.src = url;
    });
  }

  closeAlert() {
    this.setState({ showAlert: false });
  }

  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    const { imageExisting, imageUploadError, isMobile } = this.props;
    const {
      isEditorOpened,
      isTakePhotoOpened,
      hasError,
      photoFileUrl,
    } = this.state;

    return (
      <div className='upload-photo'>
        <div className='upload-photo__image'>
          <img src={imageExisting || noAvatarImage} alt='' />
        </div>

        <div className='upload-photo__right'>
          <p>{common.UPLOAD_YOUR_PHOTO}</p>

          <p className='gray'>
            {common.IMAGE_INFO_1}
            <br /> {common.IMAGE_INFO_2}
          </p>

          {hasError && (
            <p className='loss'>
              {common.CHOSEN_IMAGE_DOESNT_MEET_REQUIREMENTS}
            </p>
          )}

          {imageUploadError && (
            <p className='loss'>{common.IMAGE_UPLOAD_ERROR}</p>
          )}

          <div className='buttons'>
            <label className='button'>
              <i className='icon-upload' />

              <span className='button__text'>{common.UPLOAD_PHOTO}</span>

              <input
                type='file'
                className='visually-hidden'
                accept='image/png'
                onChange={this.onFileChange}
              />
            </label>

            {!isMobile && (
              <button
                className='button '
                onClick={() => this.setIsTakePhotoOpened(true)}
                type='button'
              >
                <i className='icon-camera' />
                <span className='button__text'>{common.TAKE_A_PHOTO}</span>
              </button>
            )}
          </div>
        </div>

        <Editor
          isOpen={isEditorOpened}
          imageUrl={photoFileUrl}
          onClose={() => this.setIsEditorOpened(false)}
          onFileChange={this.onFileChange}
          onTakePhotoClick={this.onEditorTakePhotoClick}
          onChange={this.onEditorChange}
        />

        <TakePhoto
          isOpen={isTakePhotoOpened}
          onClose={() => this.setIsTakePhotoOpened(false)}
          onChange={this.onTakePhotoChange}
        />
        <TopMessage
            isShown={this.state.showAlert || imageUploadError}
            closeable={true}
            onCloseClick={this.closeAlert.bind(this)}
            type='error'
            text={common.IMAGE_UPLOAD_ERROR}
          />
      </div>
    );
  }
}

export default connect(mapStateToProps)(UploadPhoto);
