import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import moment from 'moment';
import { Editor } from 'react-draft-wysiwyg';

import Comments from '../components/Comments';
import { createEditorStateFromHTML } from '../components/settings/Input/components/EditorInput/EditorInput.js';

const DATE_FORMAT = 'DD MMM YYYY';

const playerConfig = {
  height: '100%',
  playing: true,
  width: '100%',
};

// TODO: there may be a bug in console - it is Chrome fault.
// https://github.com/CookPete/react-player/issues/288

const Video = (props) => {
  const {
    date,
    title,
    description,
    video,
    comments = [],
    totalReviews,
    fetchReviewsFunction,
    addReviewFunction,
    addSubComment,
    t,
  } = props;

  const editorState = createEditorStateFromHTML(description);

  return (
    <Fragment>
      <section className='detail detail_big detail_symmetric'>
        <div className='detail__whole'>
          <div className='detail__top'>
            {date && (
              <span className='card__date'>
                {moment(date).format(DATE_FORMAT)}
              </span>
            )}
          </div>

          <h1 className='h2'>{title}</h1>

          {description && (
            <Editor
              wrapperClassName='content content_p-margins'
              readOnly={true}
              toolbar={{ options: [] }}
              toolbarStyle={{ display: 'none' }}
              editorState={editorState}
            />
          )}

          {video && (
            <div className='player-wrapper'>
              <ReactPlayer
                url={video}
                className='react-player'
                {...playerConfig}
              />
            </div>
          )}
        </div>
      </section>

      <Comments
        commentable
        comments={comments}
        totalReviews={totalReviews}
        fetchReviews={fetchReviewsFunction}
        addReview={addReviewFunction}
        addSubComment={addSubComment}
        title={t.common[0].COMMENTS}
        singleTitle={t.common[0].COMMENT}
        classes='detail_big detail_symmetric white-comments'
      />
    </Fragment>
  );
};

Video.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  description: PropTypes.string,
  video: PropTypes.string,
  comments: PropTypes.array,
  totalReviews: PropTypes.number,
  fetchReviewsFunction: PropTypes.func,
  addReviewFunction: PropTypes.func,
  addSubComment: PropTypes.func,
  t: PropTypes.object,
};

Video.defaultProps = {
  description: '',
  video: '',
  comments: [],
  totalReviews: 0,
  fetchReviewsFunction: null,
  addReviewFunction: null,
  addSubComment: null,
};

const mapStateToProps = (state) => ({
  t: state.language.t,
});

export default connect(mapStateToProps)(Video);
