import { PRODUCT_SEARCH, COUNTRY_SEARCH } from '../store/actions';

const initialState = {};

export default function defaultLoad(state = initialState, action) {
  switch (action.type) {
    case PRODUCT_SEARCH:
      return {
        ...state,
        productOptions: action.payload.data,
      };

    case COUNTRY_SEARCH:
      return {
        ...state,
        countryOptions: action.payload,
      };

    default:
      return state;
  }
}
