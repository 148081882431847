import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import InputBlock from '../../components/InputBlock';

import languages from '../../helpers/languages';

function Form(props) {
  const currLang = languages[props.lan];
  const regLang = currLang.registration[0];
  const fields = [
    {
      name: 'email',
      placeholder: regLang.EMAIL,
      required: true,
      type: 'email',
    },
    {
      name: 'password',
      placeholder: regLang.PASSWORD,
      required: true,
      type: 'password',
    },
  ];
  const fieldsJsx = fields.map((field) => (
    <div key={field.name}>
       <label for={field.name}>{field.placeholder}</label>
      <InputBlock
        error={props.errors[field.name]}
        inputAttrs={field}
        // label={field.placeholder}
        onChange={props.onChange}
        value={props.values[field.name]}
      />
    </div>
  ));

  return (
            <form onSubmit={props.onSubmit} className=''>
            {fieldsJsx}
            
            <p class="forgotpasswordlink">
             <Link to='/forgot-password'>
                  {currLang.common[0].FORGOT_YOUR_PASSWORD}
              </Link>
              </p>
              <button
                disabled={props.buttonDisabled}
                type='submit'
                name="login" value="login"
                className='btn'
              >
                {currLang.sign_in[0].SIGN_IN}
              </button>
            </form>
      );
}

Form.propTypes = {
  buttonDisabled: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  lan: state.language.lan,
});

export default connect(mapStateToProps)(Form);
