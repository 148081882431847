import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Textarea from 'react-autosize-textarea';
import Comment from '../components/Comment';
import Stars from '../components/Stars';
import languages from '../helpers/languages';

var language = languages['en'];
var common = language.common[0];
var menu = language.menu[0];

const NUMBER_TO_SHOW = 3;
const NUMBER_TO_SHOW_ADDITIONALLY = 3;

const mapStateToProps = (state) => ({
  isMobile: state.responsive.isMobile,
  lan: state.language.lan,
});

class Comments extends React.Component {
  static propTypes = {
    comments: PropTypes.array,
    commentable: PropTypes.bool,
    title: PropTypes.string.isRequired,
    singleTitle: PropTypes.string.isRequired,
    classes: PropTypes.string,
    hasStars: PropTypes.bool,
    subComments: PropTypes.bool,
    totalReviews: PropTypes.number,
    fetchReviews: PropTypes.func,
    addReview: PropTypes.func,
    addAnswer: PropTypes.func,
    addSubComment: PropTypes.func,
    showedAnswers: PropTypes.number,
  };

  static defaultProps = {
    comments: [],
    commentable: false,
    classes: '',
    hasStars: false,
    subComments: true,
    totalReviews: 0,
    addReview: null,
    addSubComment: null,
    showedAnswers: 0,
    fetchReviews: 0,
  };

  constructor() {
    super();

    this.state = {
      newCommentText: '',
      rating: undefined,
      show: NUMBER_TO_SHOW,
    };
  }

  showMore() {
    const { show } = this.state;
    /* if(this.props.fetchReviews){
        this.props.totalReviews(window.location.hash, show);  
    }*/
    this.props.fetchReviews(window.location.hash, show);
    this.setState({
      show: show + NUMBER_TO_SHOW_ADDITIONALLY,
      rating: 0,
    });
  }

  onRatingChange(rating) {
    this.setState({
      ...this.state,
      rating,
    });
  }

  onNewCommentChange(e) {
    this.setState({
      ...this.state,
      newCommentText: e.target.value,
    });
  }

  onCommentSubmit(e) {
    e.preventDefault();
    //this.props.fetchReviews(window.location.hash, 3);
    this.props.addReview(window.location.hash, this.state);
    this.setState({
      newCommentText: '',
      rating: undefined,
      show: NUMBER_TO_SHOW,
    });
    /*if(this.props.addAnswer){
      this.props.addAnswer(window.location.hash, this.state);  
        this.setState({
          newCommentText: '',
          show: NUMBER_TO_SHOW,
        });
    }*/
  }

  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    menu = language.menu[0];
    const {
      comments,
      commentable,
      title,
      singleTitle,
      classes,
      hasStars,
      subComments,
      totalReviews,
      addSubComment,
    } = this.props;

    const { show, rating, newCommentText } = this.state;

    return (
      <section className={classNames('detail', classes)}>
        <div className='detail__whole'>
          <div className='member-tab-title'>
            <h3>{title}</h3>
          </div>

          {commentable && (
            <form
              action=''
              onSubmit={this.onCommentSubmit.bind(this)}
              className='add-comment'
            >
              <label htmlFor='add-comment'>
                <i className='icon-comment' />
              </label>
              <Textarea
                name='add-comment'
                id='add-comment'
                placeholder={`${common.COMMENTS_PLACEHOLDER} ${singleTitle}`}
                rows={1}
                maxRows={20}
                value={newCommentText}
                onChange={this.onNewCommentChange.bind(this)}
                required
              />
              <div className='submit-comment'>
                {hasStars && (
                  <div className='your-rating'>
                    <span className='info'>{common.RATING}:</span>{' '}
                    <Stars
                      editable
                      rating={rating}
                      onChange={this.onRatingChange.bind(this)}
                    />
                  </div>
                )}
                <button type='submit' className='button'>
                  {common.SUBMIT}
                </button>
              </div>
            </form>
          )}

          <div className='comments'>
            {this.props.hasOwnProperty('comments') &&
              comments.map((el) => (
                <Comment
                  key={el.id}
                  showedAnswers={show}
                  addSubComment={addSubComment}
                  commentable={commentable}
                  subComments={subComments}
                  {...el}
                />
              ))}

            {show < totalReviews && (
              <div className='show-more-comments'>
                <button
                  className='button '
                  onClick={(e) => this.showMore()}
                >
                  <i className='icon-arrow--small' /> Show more{' '}
                  {title.toLowerCase()}
                </button>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}
export default connect(mapStateToProps)(Comments);
