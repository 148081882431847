import 'moment/locale/es';
import 'moment/locale/fr';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import Preloader from '../components/Preloader';
import languages from '../helpers/languages';
import {
  fetchDetailProjectPages
} from '../store/actions';


var language = languages['en'];
var common = language.common[0];
var label = language.Projects[0];


const mapStateToProps = (state) => {
  return {
    lan: state.language.lan,
    user: state.auth.user,
    projectInfo: state.projectpages.detail
  };
};

const mapDispatchToProps = {
  fetchDetailProjectPages,
};

class Project extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.props.match.params.id)
    this.state = {
      cardsLoaded: false,
      isModalOpened: false
    };
  }
  handleLoading(promise) {
    const animationPromise = new Promise((resolve) =>
      setTimeout(resolve, 1000)
    );
    this.setState({ cardsLoaded: false });
    return Promise.all([promise, animationPromise]).then(() => {
      if (!this.unmounted) {
        this.setState({ cardsLoaded: true });
      }
    });
  }

  componentDidMount() {

    this.handleLoading(this.props.fetchDetailProjectPages(this.props.match.params.id));
  }
  componentWillUnmount() {
    this.unmounted = true;
  }



  render() {
    var roleFlag = true;
    language = languages[this.props.lan];
    common = language.common[0];
    label = language.Projects[0];
    const {
      projectInfo,showPreloader
    } = this.props;
    const { cardsLoaded,fullyLoaded } = this.state;
        return (
      <CustomizedDocumentTitle title={common.MY_DASHBOARD}>
        <div className='transition-page'>
        { (projectInfo === undefined || projectInfo === null)? (
            <Preloader size='medium' relative />
          ) : (
            <div>
              {/*header content */}
              <header>
                {projectInfo.banner ? (
                  <img src={projectInfo.banner} width="100%" alt="" />
                ) : (
                  <div className="innerheader innerheader-projecttemplate">
                  </div>
                )}
              </header>
              {/*header content */}
              {/*members */}
              <div className="narrowdarkblueblock">
                <div className="wrap">
                  <h3 className="textwhite nomargin">{projectInfo.title}</h3>
                </div>
              </div>
              {/*members */}
              {/*dark block under inner header */}
              <div className="bgwhite">
                <div className="wrap">
                  <div className="container"><div dangerouslySetInnerHTML={{ __html: projectInfo.description }} /></div>

                </div>
              </div>
                 {/*free block */}
                 <div className="bgdark">
                <div className="wrap">
                  <div className="blueblockbgcircles">
                    <div className="container-2col">
                      <div>
                        <p>
                          <div dangerouslySetInnerHTML={{ __html: projectInfo.applicationtext }} /></p></div>
                      {roleFlag &&
                        <a href={projectInfo.website} className="btn">{label.APPLY_ACCESS_YOUR_ACCOUNT_LOGGED}</a>
                      }
                      {!roleFlag &&
                        <Link to="/registration" className="btn">{label.COMPLETE_YOUR_PROFILE_FIRST}</Link>
                      }
                    </div>
                  </div>
                </div>
              </div>
              {/*free block */}
              {/*dark block under inner header */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container">
                    <div>
                      <div dangerouslySetInnerHTML={{ __html: projectInfo.longDescription }} />
                      {/* <p><i className="fas fa-stopwatch icoleft" />
                        <b>Duration of the project:</b>  {moment(projectInfo.end).diff(projectInfo.end, 'months', true)} months ({moment(projectInfo.start).format('MMM D, YYYY')}-{moment(projectInfo.end).format('MMM D, YYYY')})
                      </p> */}
                    </div>
                    <div />
                  </div>
                </div></div>
              {/*why join */}
              <div className="bgwhite">
                <div className="wrap">
                  <div className="container">
                    <div dangerouslySetInnerHTML={{ __html: projectInfo.pageContent }} />
                    
                    <hr />
                    <div dangerouslySetInnerHTML={{ __html: projectInfo.eligibilitytext }} />
                   
                  </div>
                </div>
              </div> 
              {/*why join */}
              {/*free block */}
              <div className="bgdark">
                <div className="wrap">
                  <div className="blueblockbgcircles">
                    <div className="container-2col">
                      <div>
                        <p>
                          <div dangerouslySetInnerHTML={{ __html: projectInfo.applicationtext }} /></p></div>
                      {roleFlag &&
                        <a href={projectInfo.website} className="btn">{label.APPLY_ACCESS_YOUR_ACCOUNT_LOGGED}</a>
                      }
                      {!roleFlag &&
                        <Link to="/registration" className="btn">{label.COMPLETE_YOUR_PROFILE_FIRST}</Link>
                      }
                    </div>
                  </div>
                </div>
              </div>
              {/*free block */}
              {/*Hub resources */}
              {/* <div className="bgwhite">
                <div className="wrap">
                  <div className="container">
                    <p><Link to="/publiccontactus" className="btn">Get in touch with us</Link></p>
                  </div>
                </div>
              </div> */}
              {/*Hub resources */}
              {/*Upcoming events */}
              {/*Upcoming events */}
              {/*Hub resources */}
              <div className="bgwhite">
                <div className="wrap">
                  <div className="container">
                      <div dangerouslySetInnerHTML={{ __html: projectInfo.resources }} />
                  </div>
                </div>
              </div>
              <h3 className="helpquestion"> {label.HAVE_A_QUESTION_SEND_AN_E_MAIL_TO}&nbsp;<a href="mailto:{projectInfo.email}"> {projectInfo.email}</a></h3>
              {/*Hub resources */}
              {/*articles */}
              {/* <div className="bggrey">
                <div className="wrap">
                  <div className="container">
                    <div className="container-2col">
                      <Link to="/publicsuccessstories">
                        <div dangerouslySetInnerHTML={{ __html: projectInfo.successstories }} />
                        {label.READ_MORE_IN_OUR_BLOG} <i className="fal fa-arrow-right" /></Link></div>
                    <div className="container-3col">
                    </div>
                  </div>
                </div>
              </div> */}
              {/*articles */}
              {/*Our donors */}
              {/*Our donors */}
              {/*active projects */}
              {/*active projects */}
            </div>
          )}
        </div>
      </CustomizedDocumentTitle>
    );
  }
}

Project.propTypes = {
  showPreloader: PropTypes.bool,
};

Project.defaultProps = {
  projectsInfoDbDb: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(Project);

