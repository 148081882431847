import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StaticGoogleMap } from '../../../../../../../../../../components/GoogleMap';
import GoogleMapCoordinates from '../../../../../../../../../../components/GoogleMapCoordinates';

const TypeLocation = ({ location }) => (
  <Fragment>
    <div className='chat-message__google-map'>
      <StaticGoogleMap center={location} size={{ width: 350, height: 90 }} />
    </div>

    <GoogleMapCoordinates
      top={location.name}
      lat={location.lat}
      lng={location.lng}
    />
  </Fragment>
);

TypeLocation.propTypes = PropTypes.object.isRequired;

export default TypeLocation;
