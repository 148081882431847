import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import Modal from '../../../../components/Modal';
import Hamburger from '../../../../components/Header/components/Hamburger';
import TopFiltersDesktop from './Desktop';

class TopFiltersMobile extends React.Component {
  state = {
    areFiltersOpened: false,
  };

  toggleFilters(areFiltersOpened) {
    this.setState({
      ...this.state,
      areFiltersOpened,
    });
  }

  render() {
    const { t } = this.props;
    const { areFiltersOpened } = this.state;

    return (
      <Fragment>
        <button
          className='input-with-icon input-with-icon_mobile-filters'
          onClick={this.toggleFilters.bind(this, true)}
        >
          <span className='input-with-icon__input'>
            {t.common[0].SHOW_FILTERS}
          </span>

          <i className='icon-filters' />
        </button>

        <Modal isOpen={areFiltersOpened} noBottom>
          <div className='mobile-filters-modal'>
            <h4 className='mobile-filters-modal__title'>
              {t.common[0].SEARCH_FILTERS}
            </h4>

            <div className='mobile-filters-modal__ham'>
              <Hamburger
                isOpened={areFiltersOpened}
                onClick={this.toggleFilters.bind(this, false)}
              />
            </div>

            <TopFiltersDesktop
              isMobile
              onShowResultsClick={this.toggleFilters.bind(this, false)}
              {...this.props}
            />
          </div>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  t: state.language.t,
});

export default connect(mapStateToProps)(TopFiltersMobile);
