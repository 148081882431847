import React from 'react';
import PropTypes from 'prop-types';

const shorten = (textOrNull, maxLength) => {
  let text = textOrNull || '';
  if (text.length > maxLength) {
    return text.slice(0, maxLength - 1).trim() + '\u2026';
  }
  return text;
};

const Tag = ({ type, text, children, maxLength }) => (
  <span className={`tag tag_${type}`} title={text}>
    {maxLength ? shorten(text, maxLength) : text}

    {children}
  </span>
);

Tag.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string.isRequired,
  children: PropTypes.object,
};

Tag.defaultProps = {
  type: 'default',
  children: null,
};

export default Tag;
