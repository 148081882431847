import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'informed';
import queryString from 'query-string';
import { Link, NavLink, withRouter } from 'react-router-dom';
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import Preloader from '../components/Preloader';
import languages from '../helpers/languages';

var language = languages['en'];
var common = language.common[0];
var lblHelp = language.Help[0];

const mapStateToProps = (state) => {
  return {
    lan: state.language.lan,
    user: state.auth.user,
  };
};

class HelpCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpened: false,
      formApi: null,
      queryInput: '',
    };

    this.clear = this.clear.bind(this);
    this.onQueryInputChange = this.onQueryInputChange.bind(this);
    this.setFormApi = this.setFormApi.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {

    const {
      location
    } = this.props;

    const queryObject = queryString.parse(decodeURIComponent(location?.search));
    if (
      typeof queryObject.query === 'string' &&
      queryObject.query.length !== 0
    ) {
      this.setState({
        queryInput: queryObject.query,
      });
    }


  }

  clear() {
    const { onResetClick } = this.props;
    onResetClick();
    this.setState({
      queryInput: '',
    });
  }

  onQueryInputChange(e) {
    this.setState({
      queryInput: e.target.value,
    });
  }

  persist(values, searchUrl = null) {
    const { fetchSearchResult, onSearchSubmit } = this.props;
    const { formApi, queryInput } = this.state;
    formApi.setValues(values);
    onSearchSubmit(values);
    if (searchUrl) {
      fetchSearchResult(searchUrl, values, true);
    } else {
      fetchSearchResult(window.location.hash, values, true);
    }
  }

  setFormApi(formApi) {
    this.setState({
      formApi,
    });
  }

  submit() {
    const { history } = this.props;
    const { formApi, queryInput } = this.state;
    const trimmedQuery = encodeURIComponent(queryInput.trim());
    var pageUrl = window.location.hash.split('/');
    var currentTab = '';
    if (pageUrl[1]) {
      pageUrl = pageUrl[1];
      pageUrl = pageUrl.split('?');
      if (pageUrl[0] && pageUrl[0] === 'publicsearch') {
        var qParam = queryString.parse(decodeURIComponent(pageUrl[1]));
        if (qParam['tab']) {
          currentTab = 'tab=' + qParam['tab'];
        }
      }
    }
    const formState = formApi.getState();
    if (Object.keys(formState.touched) !== 0 || trimmedQuery.length !== 0) {
      const location = `/search?query=${trimmedQuery}&${currentTab}`;
      this.persist(formState.values, location);
      this.props.history.push(location);
    }
  }

  getInitialValues() {
  }

  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    lblHelp = language.Help[0];
    const { showPreloader } = this.props;
    const {
      isModalOpened,
    } = this.state;

    const { queryInput } = this.state;
    const initialValues = this.getInitialValues();
    return (
      <CustomizedDocumentTitle title={common.MY_DASHBOARD}>
        <div className='transition-page'>
          {showPreloader ? (
            <Preloader size='medium' relative />
          ) : (
            <div>
              {/*header content */}
              <header>
                <div className="innerheader innerheader-helpcenter">
                  <h1>{lblHelp.RESOURCES_AND_HELP_CENTER}</h1>
                </div>
              </header>
              {/*header content */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container">
                    <p>{lblHelp.A_FEW_WORDS_EXPLAIN_WHAT_YOU_CAN}</p>
                    <Form
                    onSubmit={this.submit}
                    initialValues={initialValues}
                    getApi={this.setFormApi}
                    className="searchform"
                    render={({ formState: { touched, values } }) => (
                      <Fragment>
                        <input id="searchcheckbox" name="searchcheckbox" type="checkbox" />
                        <label htmlFor="searchcheckbox"><i className="fal fa-search" /></label>
                        <div className="searchformcontainer">
                          <input
                            name="searchform_full"
                            type="text"
                            placeholder="Search for a member, an event, an offer…"
                            id="searchform_full"
                            value={queryInput}
                            onChange={this.onQueryInputChange}
                          />
                          <button name="searchform_full" value="search" type="submit"><i className="fas fa-search" /></button>
                        </div>
                      </Fragment>
                    )}
                  />
                  </div>
                </div>
                <div className="wrap">
                  <div className="container-2even">
                    <div className="contentblock whiteblock  rounded">
                      <h2><NavLink to="/publicpublications">
                        {lblHelp.PUBLICATIONS}<i className="fal fa-arrow-right" /></NavLink></h2>
                      <p> {lblHelp.SMALL_BUSINESSES_INCLUDING_THOSE}</p>
                    </div>
                    <div className="contentblock whiteblock  rounded">
                      <h2><NavLink to="/covid">
                        <i className="fas fa-virus" /> {lblHelp.COVID19_PORTAL} <i className="fal fa-arrow-right" /></NavLink></h2>
                      <p>{lblHelp.SMALL_BUSINESSES_INCLUDING_THOSE}</p>
                    </div>
                    <div className="contentblock whiteblock  rounded">
                      <h2>{lblHelp.FREQUENTLY_ASKED_QUESTIONS}</h2>
                      <p><NavLink to="/f-a-q">- {lblHelp.HOW_DO_I_BECOME_A_COACH}?</NavLink><br />
                        <NavLink to="/f-a-q">- {lblHelp.I_AM_A_FREELANCER_IS_SHETRADES_R}?</NavLink><br />
                        <NavLink to="/f-a-q">- {lblHelp.I_SELL_SERVICES_CAN_I_USE_THE_MA}?</NavLink><br />
                        <NavLink to="/f-a-q">- {lblHelp.HOW_DO_I_GET_IN_TOUCH_WITH_THE_TE}?</NavLink></p>
                      <p><NavLink to="/f-a-q" className="btn">{lblHelp.SEE_ALL_THE_FAQ}</NavLink></p>
                    </div>
                    <div className="contentblock whiteblock  rounded">
                      <h2><NavLink to="/news">{lblHelp.MEDIA_PORTAL} <i className="fal fa-arrow-right" /></NavLink></h2>
                      <p>{lblHelp.SMALL_BUSINESSES_INCLUDING_THOSE}</p>
                    </div>
                  </div>
                </div>
              </div>
              {/*free block */}
              <div className="bgdark">
                <div className="wrap">
                  <div className="blueblockbgcircles">
                    <div className="container-2col">
                      <div>
                        <h2>{lblHelp.UNDERSTAND_YOUR_MARKET}</h2>
                        <p>{lblHelp.THE_INTERNATIONAL_TRADE_CENTRE_HAS_DEVELOPED}</p></div>
                      <NavLink to="/publictools" className="btn">DISCOVER_ITCS_ONLINE_TOOLS</NavLink>
                    </div>
                  </div>
                </div>
              </div>
              {/*free block */}
              {/*Upcoming events */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container">
                    <h2>Let’s get in touch</h2>
                    <p style={{ flexBasis: '100%' }}>{lblHelp.SMALL_BUSINESSES_INCLUDING_THOSE}</p>
                    <p><NavLink to="/publiccontactus" className="btn">{lblHelp.CONTACT_US}</NavLink></p>
                  </div>
                </div>
                <div className="dotbg l2 dotb" />
              </div>
              {/*Upcoming events */}
            </div>
          )}
        </div>
      </CustomizedDocumentTitle>
    );
  }
}

HelpCenter.propTypes = {
  showPreloader: PropTypes.bool,
};

export default  withRouter(connect(mapStateToProps)(HelpCenter));
