import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import axios from '../../../../../../requests/platformApi';

import Tag from '../../../../../../components/Tag';
import noAvatarImage from '../../../../../../static/images/noavatar.svg';
import {
  removeGroupMember,
  getChatGroups,
} from '../../../../../../pages/Messenger/components/MessengerTabs/fetchApplozic';
import {
  reloadMessenger,
  fetchMessageTabData,
} from '../../../../../../store/actions';

const mapStateToProps = (state) => ({
  applozicApi: state.messenger.applozicApi,
  t: state.language.t,
});

const mapDispatchToProps = {
  reloadMessenger,
  fetchMessageTabData,
};

class Contact extends React.Component {
  async removeMember(userId, groupId) {
    const { applozicApi, reloadMessenger } = this.props;

    if (userId > 0 && groupId > 0) {
      const applozicResponse = await removeGroupMember(
        applozicApi,
        userId,
        groupId
      ).catch((e) => {
        throw e;
      });

      // here update data on laravel server
      if (applozicResponse.status === 'success') {
        let formData = new FormData();
        formData.append('userId', userId);
        formData.append('groupId', groupId);
        await axios.post('/removeGroupMember', formData).catch((e) => {
          throw e;
        });
      }

      //reload members and groups
      this.props.fetchMessageTabData(window.location.hash);
    }
  }

  render() {
    const {
      id,
      avatar,
      name,
      companyName,
      countryName,
      groupName,
      online,
      verified,
      actualUserId,
      canWrite,
      t,
    } = this.props;

    const isDefaultGroup = groupName === 'ALL_CONTACTS';

    const actualGroupName = isDefaultGroup
      ? t.messages[0].ALL_CONTACTS
      : groupName;

    var to = '/#/messenger?item=' + actualUserId + '&tab=chats';
    return (
      <div className='messenger-contact' key={id}>
        <div className='flex flex_align-middle flex_md'>
          <div className={classNames('messenger-contact__image', { online })}>
            <img src={avatar} alt='' />
          </div>

          <div className='messenger-contact__name'>
            <div className='h4'>
              <a href='javascript: void null'>{name} </a>

              {verified && (
                <i className='icon-verified'>
                  <span className='path1' />
                  <span className='path2' />
                </i>
              )}
            </div>

            <div className='gray'>
              <a href='javascript: void null'>{companyName}</a>
            </div>
          </div>

          <div className='messenger-contact__country'>
            <div className='gray'>{countryName}</div>
          </div>

          <div className='messenger-contact__group'>
            <div>
              <Tag text={actualGroupName} />
            </div>
          </div>

          <div className='right'>
            <div className='messenger-contact__actions'>
              {canWrite === true && (
                <a href={to}>
                  <i className='icon-edit' />
                </a>
              )}
              {!isDefaultGroup && this.props.canRemove === true && (
                <a
                  href='javascript: void null'
                  onClick={() =>
                    this.removeMember(
                      this.props.actualUserId,
                      this.props.groupId
                    )
                  }
                >
                  <i className='icon-trash' />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Contact.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  avatar: PropTypes.string,
  name: PropTypes.string.isRequired,
  companyName: PropTypes.string,
  countryName: PropTypes.string,
  groupName: PropTypes.string,
  online: PropTypes.bool,
  verified: PropTypes.bool,
};

Contact.defaultProps = {
  avatar: noAvatarImage,
  online: false,
  verified: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
