import React from 'react';
import PropTypes from 'prop-types';
import formatNumber from '../../../helpers/format-number';

const UnreadCount = ({ unreadCount }) => {
  if (unreadCount === 0) {
    return null;
  }
  const formattedNumber = formatNumber({ number: unreadCount });
  let unreadCountStyles;
  switch (formattedNumber.length) {
    case 3:
      unreadCountStyles = { fontSize: '9px' };
      break;
    case 4:
      unreadCountStyles = { fontSize: '7px' };
      break;
    default:
      unreadCountStyles = null;
  }
  return (
    <span className='header-button__unread' style={unreadCountStyles}>
      {formattedNumber}
    </span>
  );
};

UnreadCount.propTypes = {
  unreadCount: PropTypes.number,
};

UnreadCount.defaultProps = {
  unreadCount: 0,
};

export default UnreadCount;
