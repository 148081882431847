import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'informed';
import { connect } from 'react-redux';
import _ from 'lodash';
import isEmail from 'validator/lib/isEmail';
import { Scrollbars } from 'react-custom-scrollbars';
import Progress from '../../components/Progress';
import Input from '../../components/settings/Input';
import Preloader from '../../components/Preloader';
import TopMessage from '../../components/TopMessage';
import QuestionnaireLayout from './components/QuestionnaireLayout';

import {
  submitCommitment,
  submitAbuseReport,
  closeAbuseOnSuccess,
} from '../../store/actions';
import languages from '../../helpers/languages';

var language = languages['en'];
var reportAbuse = language.report_abuse[0];
var common = language.common[0];
var commitments = language.commitments[0];

const mapStateToProps = (state) => ({
  report: state.abuse.status,
  lan: state.language.lan,
});

const mapDispatchToProps = {
  submitCommitment,
  submitAbuseReport,
  closeAbuseOnSuccess,
};

class Questionnaire extends React.Component {
  static propTypes = {
    close: PropTypes.func,
    confirmText: PropTypes.string,
    disableProgress: PropTypes.bool,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        type: PropTypes.string,
        options: PropTypes.array,
        conditions: PropTypes.arrayOf(
          PropTypes.shape({
            questionId: PropTypes.string.isRequired,
            answerIds: PropTypes.array,
          })
        ),
        isRequired: PropTypes.bool,
      })
    ),
    submit: PropTypes.func,
    withConfirmStep: PropTypes.bool,
    formMessage: PropTypes.string,
    common: PropTypes.string,
    report: PropTypes.bool,
    id: PropTypes.number,
    selectedItem: PropTypes.number,
    afterTitleMain: PropTypes.string,
    afterMain: PropTypes.string,
    hasScrollbars: PropTypes.bool,
  };

  static defaultProps = {
    confirmText: 'Submit',
    disableProgress: false,
    withConfirmStep: true,
    formMessage: '',
    common: '',
    report: false,
    id: null,
    selectedItem: 0,
    afterTitleMain: '',
    afterMain: '',
    hasScrollbars: false,
  };

  static checkCondition({ formState, questions, condition }) {
    const { questionId, answerIds = [] } = condition;
    const question = _.find(
      questions,
      ({ id }) => String(id) === String(questionId)
    );
    if (!question) {
      throw new Error(`Question with id "${questionId}" cannot be rendered: dependant question with
                        id ${questionId} was not found`);
    }
    const answer = formState.values[questionId];
    const isOptionCorrect = (optionId) =>
      answerIds.indexOf(String(optionId)) !== -1;

    if (Array.isArray(answer)) {
      if (answer.every((optionId) => !isOptionCorrect(optionId))) {
        return false;
      }
    } else if (!isOptionCorrect(answer)) {
      return false;
    }
    return (
      !question.conditions ||
      question.conditions.every((parentCondition) => {
        return Questionnaire.checkCondition({
          formState,
          questions,
          condition: parentCondition,
        });
      })
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      formApi: null,
      showPreloader: false,
      showTopMessage: false,
      step: 0,
      stepsResults: {},
      files: {},
    };

    this.onFilesChange = this.onFilesChange.bind(this);
    this.setFormApi = this.setFormApi.bind(this);
    this.submit = this.submit.bind(this);
  }

  setFormApi(formApi) {
    this.setState({
      ...this.state,
      formApi,
    });
  }

  topSuccessMessage(props, closeClick) {
    this.props.closeAbuseOnSuccess(false);
  }

  togglePreloader(showPreloader) {
    this.setState({
      ...this.state,
      showPreloader,
    });
  }

  toggleTopMessage(showTopMessage) {
    this.setState({
      ...this.state,
      showTopMessage,
    });
  }

  async onFilesChange(id, imagesOnly, files) {
    this.setState({
      ...this.state,
      files: {
        ...this.state.files,
        [id]: files,
      },
    });
  }

  async saveCurrentStep(newStepToGo) {
    const { steps, withConfirmStep } = this.props;
    const { formApi, step } = this.state;
    if (newStepToGo !== null && withConfirmStep && step === steps.length) {
      return this.setState({
        ...this.state,
        step: newStepToGo,
      });
    }
    const { values } = formApi.getState();
    if (newStepToGo !== null) {
      await this.setState({
        ...this.state,
        step: newStepToGo,
        stepsResults: {
          ...this.state.stepsResults,
          [step]: values,
        },
      });
    } else {
      await this.setState({
        ...this.state,
        stepsResults: {
          ...this.state.stepsResults,
          [step]: values,
        },
      });
    }
  }

  async goToStep(step) {
    const { formApi, step: currentStep } = this.state;
    if (currentStep < step) {
      await formApi.submitForm();
      const { errors } = formApi.getState();
      if (Object.keys(errors).length) return;
    }
    await this.saveCurrentStep(step);
    formApi.setValues(this.state.stepsResults[step]);
  }

  async submit() {
    const {
      close,
      submit,
      formMessage,
      selectedItem,
      submitCommitment,
      steps,
    } = this.props;
    const { stepsResults, files } = this.state;

    if (selectedItem) {
      submitCommitment(selectedItem, stepsResults, files, this.props.steps);
    } else {
      this.props.submitAbuseReport(
        this.state.stepsResults,
        this.props.id,
        this.props.common,
        this.props.steps
      );
    }
    await this.saveCurrentStep();
    if (submit) {
      this.togglePreloader(true);
      if (formMessage !== '') {
        this.setState({
          ...this.state,
          showTopMessage: true,
        });
      }
      await submit({ stepsResults, files }).catch((e) => {
        this.setState({
          ...this.state,
          showPreloader: false,
          showTopMessage: true,
        });
        throw e;
      });
      this.togglePreloader(false);
    }
    if (close) {
      close();
    }
  }

  render() {
    language = languages[this.props.lan];
    reportAbuse = language.report_abuse[0];
    common = language.common[0];
    commitments = language.commitments[0];
    const {
      steps,
      close,
      withConfirmStep,
      formMessage,
      report,
      confirmText,
      disableProgress,
      afterMain,
      afterTitleMain,
      hasScrollbars,
    } = this.props;

    if (!steps) {
      return (
        <QuestionnaireLayout onCloseClick={close}>
          <Preloader relative size='small' />
        </QuestionnaireLayout>
      );
    }

    if (steps.length === 0) {
      return (
        <QuestionnaireLayout onCloseClick={close}>
          <p className='content'>{common.THERE_NO_QUESTIONS_IN_CHALLENGE}</p>
        </QuestionnaireLayout>
      );
    }

    const { files, step, showPreloader, showTopMessage, type } = this.state;
    const isConfirmationStep =
      step === (withConfirmStep ? steps.length : steps.length - 1);
    let stepData;
    if (withConfirmStep && isConfirmationStep) {
      stepData = {
        title: reportAbuse.THANKS_FOR_THE_FEEDBACK,
      };
    } else {
      stepData = steps[step?step:0];
    }

    const { title, descriptionItems, questions } = stepData;

    const progress = step / steps.length;

    const scrollable = (formState) => (
      <div className='questionnaire__form inputs inputs_vertical'>
        {afterMain !== '' && afterTitleMain !== '' && (
          <section className='content content_p-margins modal-content__title'>
            <h4>{afterTitleMain}</h4>
            <p>{afterMain}</p>
          </section>
        )}
        {questions
          .filter(({ conditions = [] }) => {
            return conditions.every((condition) =>
              Questionnaire.checkCondition({ formState, questions, condition })
            );
          })
          .map(
            ({
              id,
              title,
              type: dbQuestionType,
              options = [],
              conditions = [],
              isRequired,
            }) => {
              const isFileInvalid =
                formState.submits > 0 &&
                isRequired &&
                (!files[id] || !files[id].length);
              let type = dbQuestionType;
              if (dbQuestionType === 'file') {
                type = 'files';
              }
              return (
                <Input
                  id={id}
                  key={id}
                  label={title}
                  type={type}
                  required={isRequired}
                  options={options.map(
                    ({ id: optionId, name: optionTitle }) => ({
                      label: optionTitle,
                      value: optionId,
                    })
                  )}
                  informedProps={{
                    validate: (v) => {
                      switch (type) {
                        case 'checkbox-group':
                          return v && v.length
                            ? null
                            : reportAbuse.FIELD_CAN_NOT_BE_EMPTY;
                        case 'email':
                          return v && isEmail(v)
                            ? null
                            : reportAbuse.VALUE_SHOULD_BE_EMAIL;
                        default:
                          return v ? null : reportAbuse.FIELD_CAN_NOT_BE_EMPTY;
                      }
                    },
                  }}
                  hasError={formState.errors && !!formState.errors[id]}
                  errors={formState.errors && formState.errors[id]}
                  {...(type === 'images' || type === 'files'
                    ? {
                        value: files[id],
                        onChange: this.onFilesChange.bind(
                          this,
                          id,
                          type === 'images'
                        ),
                        hasError: isFileInvalid,
                        errors: isFileInvalid
                          ? reportAbuse.FIELD_CAN_NOT_BE_EMPTY
                          : null,
                      }
                    : null)}
                  {...(type === 'textarea'
                    ? {
                        textareaProps: { rows: 3 },
                      }
                    : null)}
                />
              );
            }
          )}
      </div>
    );

    const left = (
      <Fragment>
        {!disableProgress && (
          <div className='questionnaire-top__progress flex'>
            <div className='left'>
              <p className='gray'>
                {common.STEP} {step + 1} / {steps.length + 1}
              </p>
            </div>

            <div className='right'>
              <p className='gray'>{Math.round(100 * progress)}%</p>
            </div>
          </div>
        )}

        {!disableProgress && <Progress progress={progress} />}

        <h4 className='text-justify'>{title}</h4>

        {descriptionItems && (
          <div className='content'>
            <ul>
              {descriptionItems.map((el, i) => (
                <li key={i}>{el}</li>
              ))}
            </ul>
          </div>
        )}
      </Fragment>
    );

    return (
      <QuestionnaireLayout left={left} onCloseClick={close}>
        <Fragment>
          {showPreloader ? (
            <Preloader size='medium' relative />
          ) : (
            <Fragment>
              <Form
                getApi={this.setFormApi}
                render={({ formState }) =>
                  questions ? (
                    hasScrollbars ? (
                      <Scrollbars
                        className='questionnaire__scrollbars'
                        autoHide
                        style={{
                          minHeight: '250px',
                          height: 'calc(100vh - 350px)',
                        }}
                      >
                        {scrollable(formState)}
                      </Scrollbars>
                    ) : (
                      <div style={{ minHeight: 'calc(100vh - 350px)' }}>
                        {scrollable(formState)}
                      </div>
                    )
                  ) : null
                }
              />

              {withConfirmStep && isConfirmationStep && formMessage === '' && (
                <div className='questionnaire__form content'>
                  <p>{commitments.PLEASE_PRESS_SEND_CONFIRM_COMMITMENT}</p>
                </div>
              )}

              {withConfirmStep && isConfirmationStep && formMessage !== '' && (
                <div className='questionnaire__form content'>
                  <p>{reportAbuse.PLEASE_PRESS_SEND_CONFIRM_ABUSE}</p>
                </div>
              )}

              <div className='questionnaire-bottom flex flex_align-middle'>
                <div className='left'>
                  {step > 0 && (
                    <button
                      className='button button_minimal'
                      onClick={() => this.goToStep(step - 1)}
                    >
                      <span className='button__text'>{common.PREVIOUS}</span>
                    </button>
                  )}
                </div>

                <div className='right'>
                  {isConfirmationStep ? (
                    <button className='button' onClick={this.submit}>
                      <i className='icon-arrow--small' />
                      <span className='button__text'>{confirmText}</span>
                    </button>
                  ) : (
                    <button
                      className='button'
                      onClick={() => this.goToStep(step + 1)}
                    >
                      <i className='icon-arrow--small' />
                      <span className='button__text'>{common.NEXT}</span>
                    </button>
                  )}
                </div>
              </div>
            </Fragment>
          )}

          {showTopMessage && formMessage === '' && (
            <TopMessage
              type='error'
              text='Failed to submit the commitment'
              isShown={showTopMessage}
              onCloseClick={this.toggleTopMessage.bind(this, false)}
            />
          )}

          {showTopMessage && formMessage !== '' && (
            <TopMessage
              isShown={report}
              onCloseClick={this.toggleTopMessage.bind(this, false)}
              type='success'
              text={'You have successfully report the Abusement '}
              closeable={false}
            />
          )}
        </Fragment>
      </QuestionnaireLayout>
    );
  }
}

const List = connect(mapStateToProps, mapDispatchToProps)(Questionnaire);
export default List;
