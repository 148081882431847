import 'moment/locale/es';
import 'moment/locale/fr';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import Preloader from '../components/Preloader';
import languages from '../helpers/languages';
import {
  fetchDetailProjectPages
} from '../store/actions';


var language = languages['en'];
var common = language.common[0];
var label = language.Projects[0];


const mapStateToProps = (state) => {
  return {
    lan: state.language.lan,
    projectInfo: state.projectpages.detail
  };
};

const mapDispatchToProps = {
  fetchDetailProjectPages,
};

class Project extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardsLoaded: false,
      isModalOpened: false
    };
  }
  handleLoading(promise) {
    const animationPromise = new Promise((resolve) =>
      setTimeout(resolve, 1000)
    );
    this.setState({ cardsLoaded: false });
    return Promise.all([promise, animationPromise]).then(() => {
      if (!this.unmounted) {
        this.setState({ cardsLoaded: true });
      }
    });
  }

  componentDidMount() {

    this.handleLoading(this.props.fetchDetailProjectPages(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    label = language.Projects[0];
    const {
      projectInfo
    } = this.props;

    return (
      <CustomizedDocumentTitle title={common.MY_DASHBOARD}>
        <div className='transition-page'>
          {(projectInfo === undefined || projectInfo === null) ? (
            <Preloader size='medium' relative />
          ) : (
            <div>
              {/*header content */}
              <header>
                {projectInfo.banner ? (
                  <img src={projectInfo.banner} width="100%" alt="" />
                ) : (
                  <div className="innerheader innerheader-projecttemplate">
                  </div>
                )}
              </header>
              {/*header content */}
              {/*members */}
              <div className="narrowdarkblueblock">
                <div className="wrap">
                  <h3 className="textwhite nomargin">{projectInfo.title}</h3>
                </div>
              </div>
              {/*members */}
              {/*dark block under inner header */}
              <div className="bgwhite">
                <div className="wrap">
                  <div className="container"><div dangerouslySetInnerHTML={{ __html: projectInfo.description }} /></div>
                </div>
              </div>
              <div className="bgdark">
                <div className="wrap">
                  <div className="blueblockbgcircles">
                    <div className="container-2col">
                      <div>
                        <h2>{label.JOIN_THIS_PROJECT}</h2>
                        <p>
                          <div dangerouslySetInnerHTML={{ __html: projectInfo.applicationtext }} />
                        </p></div>
                      {(projectInfo.id === 17 || projectInfo.id === 53) ? (
                        <a href={projectInfo.website} className="btn">
                          {label.APPLY_ACCESS_YOUR_ACCOUNT_LOGGED}</a>
                      ) :
                        (
                          <Link target={"_blank"} to={`/sign-in?wantsurls=/project/${projectInfo.id}`} className="btn">
                            {label.APPLY_ACCESS_YOUR_ACCOUNT}</Link>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              {/*dark block under inner header */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container">
                    <div>
                      <div dangerouslySetInnerHTML={{ __html: projectInfo.longDescription }} />
                      {/* <p><i className="fas fa-stopwatch icoleft" />
                        <b>Duration of the project:</b>  {moment(projectInfo.end).diff(projectInfo.end, 'months', true)} months ({moment(projectInfo.start).format('MMM D, YYYY')}-{moment(projectInfo.end).format('MMM D, YYYY')})
                      </p> */}
                    </div>
                    <div />
                  </div>
                </div></div>
              {/*why join */}
              <div className="bgwhite">
                <div className="wrap">
                  <div className="container">
                    <div dangerouslySetInnerHTML={{ __html: projectInfo.pageContent }} />
                    {/* <p className="container-ll">
                      {projectInfo.countries !== '' && <span>
                        <i className="fas fa-map-marker icoleft" />
                        {label.BASED_IN}
                        {projectInfo.countries}</span>}
                      {projectInfo.sectors !== '' &&
                        <span><i className="fas fa-chart-pie icoleft" />
                          In {projectInfo.sectors}</span>
                      }</p> */}
                    {/* <hr /> */}
                    <div dangerouslySetInnerHTML={{ __html: projectInfo.applicationtext }} />
                    <div dangerouslySetInnerHTML={{ __html: projectInfo.eligibilitytext }} />
                    {/* <div className="quickphotogallery">
                      <img src={projectInfo.image} alt =""/>
                    </div> */}

                  </div>
                </div>
              </div>
              {/*why join */}
              {/*free block */}
              <div className="bgdark">
                <div className="wrap">
                  <div className="blueblockbgcircles">
                    <div className="container-2col">
                      <div>
                        <h2>{label.JOIN_THIS_PROJECT}</h2>
                        <p>
                          <div dangerouslySetInnerHTML={{ __html: projectInfo.applicationtext }} />
                        </p></div>
                        {(projectInfo.id === 17 || projectInfo.id === 53) ? (
                        <a href={projectInfo.website} className="btn">
                          {label.APPLY_ACCESS_YOUR_ACCOUNT_LOGGED}</a>
                      ) :
                        (
                          <Link target={"_blank"} to={`/sign-in?wantsurls=/project/${projectInfo.id}`} className="btn">
                            {label.APPLY_ACCESS_YOUR_ACCOUNT}</Link>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              {/*free block */}
              {/*Hub resources */}
              {/* <div className="bgwhite">
                <div className="wrap">
                  <div className="container">
                    <p><Link to="/publiccontactus" className="btn">Get in touch with us</Link></p>
                  </div>
                </div>
              </div> */}
              {/*Hub resources */}
              {/*Upcoming events */}
              {/* <div className="bggrey">
                <div className="wrap">
                  <div className="container">
                    <div className="container-lr"><h2>{label.UPCOMING_EVENTS}</h2>
                      <div dangerouslySetInnerHTML={{ __html: projectInfo.upcomingevents }} />
                      <Link to="/publicevents">
                        {label.SEE_ALL_THE_EVENTS_IN_SHE_TRADES_CALENDAR} <i className="fal fa-arrow-right" /></Link>
                    </div>
                    <div className="cardswrapper">
                    </div>
                  </div>
                </div>
              </div> */}
              {/*Upcoming events */}
              {/*Hub resources */}
              <div className="bgwhite">
                <div className="wrap">
                  <div className="container">
                    <div dangerouslySetInnerHTML={{ __html: projectInfo.resources }} />


                    {/*cards  */}
                  </div>
                </div>
              </div>
              <h3 className="helpquestion">{label.HAVE_A_QUESTION_SEND_AN_E_MAIL_TO}&nbsp;<a href="mailto:{projectInfo.email}">{projectInfo.email}</a></h3>
              {/*Hub resources */}
              {/*articles */}
              {/* <div className="bggrey">
                <div className="wrap">
                  <div className="container">
                    <div className="container-2col">
                      <Link to="/publicsuccessstories">
                        <div dangerouslySetInnerHTML={{ __html: projectInfo.successstories }} />
                        {label.READ_MORE_IN_OUR_BLOG} <i className="fal fa-arrow-right" /></Link></div>
                    <div className="container-3col">
                    </div>
                  </div>
                </div>
              </div> */}
              {/*articles */}
              {/*Our donors */}
              {/* <div className="bgwhite">
                <div className="wrap">
                  <div className="container">
                    <h2>Our donors</h2>
                    <div className="partnerlogos">
                      <img src="images/partners_and_founders/Grofinlogo.png" srcSet="images/partners_and_founders/Grofinlogo.png 1x, images/partners_and_founders/Grofinlogo@2x.png 2x, images/partners_and_founders/Grofinlogo@3x.png 3x, images/partners_and_founders/Grofinlogo@4x.png 4x" alt =""/>
                      <img src="images/partners_and_founders/WEPlogohigh-res.png" srcSet="images/partners_and_founders/WEPlogohigh-res.png 1x, images/partners_and_founders/WEPlogohigh-res@2x.png 2x, images/partners_and_founders/WEPlogohigh-res@3x.png 3x,
                                            images/partners_and_founders/WEPlogohigh-res@4x.png 4x" alt =""/>
                      <img src="images/partners_and_founders/UPS_Shield.png" srcSet="images/partners_and_founders/UPS_Shield.png 1x, images/partners_and_founders/UPS_Shield@2x.png 2x, images/partners_and_founders/UPS_Shield@3x.png 3x, images/partners_and_founders/UPS_Shield@4x.png 4x" alt =""/>
                      <img src="images/partners_and_founders/NEPClogo.png" srcSet="images/partners_and_founders/NEPClogo.png 1x, images/partners_and_founders/NEPClogo@2x.png 2x, images/partners_and_founders/NEPClogo@3x.png 3x, images/partners_and_founders/NEPClogo@4x.png 4x" alt =""/>
                    </div>
                  </div>
                </div>
              </div> */}
              {/*Our donors */}
              {/*active projects */}
              {/* <div className="bggrey">
                <div className="wrap">
                  <div className="container">
                    <h2>{label.CHECK_OUT_OR_OTHER_ACTIVE_PROJECTS}</h2>
                  </div>
                </div>
                <div className="commonwealthblock negativemargintop">
                  {shuffle(projectsData).slice(0, 4).map(project => (
                    <section>
                      <span className="commonwealthblockmainpic">
                        <img height="250px" src={project.image} alt="" /></span>
                      <Link to={`/publicproject/${project.id}`}><span className="title">{project.title}</span></Link>
                      <p><div className="project-description" dangerouslySetInnerHTML={{ __html: project.description }} /></p>
                      <p>
                        <Link to={`/publicproject/${project.id}`}>
                          {label.READ_MORE}
                          <i className="fal fa-arrow-right" />
                        </Link>
                      </p></section>
                  ))}
                </div>
                <div className="dotbg l2 dotb" />
              </div> */}
              {/*active projects */}
            </div>
          )}
        </div>
      </CustomizedDocumentTitle>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Project);
