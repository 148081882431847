import React, { Component } from 'react';
import { SingleDatePicker } from 'react-dates';
import { asField } from 'informed';
import moment from 'moment';

class DateInput extends Component {
  constructor() {
    super();
    this.state = {
      isFocused: false,
    };
  }

  onFocusChange({ focused }) {
    this.setState({
      ...this.state,
      isFocused: focused,
    });
  }

  onDateChange(value) {
    const {
      fieldApi: { setValue, setTouched },
    } = this.props;
    setTouched(true);
    setValue(value);
  }

  render() {
    const {
      fieldState: { value },
    } = this.props;
    const { isFocused } = this.state;
    const date = value ? moment(value) : null;

    return (
      <div className='calendar calendar_small-for-dashboard calendar_with-long-arrows calendar_input'>
        <SingleDatePicker
          date={date}
          onDateChange={this.onDateChange.bind(this)}
          focused={isFocused}
          onFocusChange={this.onFocusChange.bind(this)}
          noBorder
          numberOfMonths={1}
          enableOutsideDays
          navPrev={<i className='icon-arrow--long' />}
          navNext={<i className='icon-arrow--long' />}
          daySize={37}
          hideKeyboardShortcutsPanel
          transitionDuration={0}
          weekDayFormat='ddd'
          renderMonth={(month) => (
            <div className='calendar-widget-top'>
              <div className='calendar-widget-year'>{month.format('YYYY')}</div>
              <div className='calendar-widget-month'>
                {month.format('MMMM')}
              </div>
            </div>
          )}
          displayFormat='DD MMMM YYYY'
        />

        <i className='icon-calendar' />
      </div>
    );
  }
}

export default asField(DateInput);
