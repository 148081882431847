import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import languages from '../../../../helpers/languages';

var language = languages['en'];

const mapStateToProps = (state) => ({
  isMobile: state.responsive.isMobile,
  lan: state.language.lan,
});

const AccordionItem = (props) => {
  language = languages[props.lan];
  const {
    isOpened,
    image,
    title,
    subtitle,
    bottomRef,
    description,
    onToggleClick,
  } = props;

  return (
    <div
      className={classNames([
        'accordion-item accordion-item_commitment',
        { 'accordion-item_opened': isOpened },
      ])}
    >
      <div className='accordion-item__top'>
        <div className='accordion-item__image'>
          <img src={image} alt='' />
        </div>

        <h3>
          {title}. <span className='gray'>{subtitle}</span>
        </h3>

        <div className='accordion-item__icon' onClick={onToggleClick}>
          {isOpened ? '—' : <i className='icon-accordion--plus' />}
        </div>
      </div>

      <div className='accordion-item__bottom' ref={bottomRef}>
        <div
          className='content content_p-margins'
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  );
};

AccordionItem.propTypes = {
  isOpened: PropTypes.bool,
  itemId: PropTypes.number,
  onToggleClick: PropTypes.func,
  onOpenItem: PropTypes.func,
  bottomRef: PropTypes.func,
  description: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

AccordionItem.defaultProps = {
  isOpened: false,
  bottomRef: () => {},
};

//export default AccordionItem;
export default connect(mapStateToProps)(AccordionItem);
