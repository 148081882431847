import { VIDEOS, VIDEO_DETAILS, VIDEO_COMMENTS } from '../store/actions';

const initialState = { videoDetails: [], videoComments: [] };

export default function videos(state = initialState, action) {
  switch (action.type) {
    case VIDEOS:
      return {
        ...state,
        videos: action.payload.data,
        totalItems: action.payload.count,
        sortLabel: action.payload.sortLabel,
      };
    case VIDEO_DETAILS:
      return {
        ...state,
        videoDetails: action.payload.data,
      };
    case VIDEO_COMMENTS:
      var reviewsTemp = action.payload.data;
      if (
        state.videoId === action.videoId &&
        state.videoComments &&
        action.newReviewFlag !== 1
      ) {
        reviewsTemp = state.videoComments.concat(reviewsTemp);
      }
      return {
        ...state,
        videoComments: reviewsTemp,
        commentCount: action.payload.count,
        videoId: action.videoId,
      };
    default:
      return state;
  }
}
