import moment from 'moment';

export default (date) => {
  const m = moment(date);
  const n = moment();

  if (m.isSame(n, 'days')) {
    return m.format('h:mm A');
  } else if (m.isSame(n.subtract(1, 'days'), 'days')) {
    return 'Yesterday';
  }
  return m.format('D MMM YYYY');
};
