import React from 'react';
import { connect } from 'react-redux';

const ClearAll = ({ disabled, onClick, t }) => (
  <span
    {...(disabled && { style: { pointerEvents: 'none', opacity: 0.66 } })}
    onClick={onClick}
  >
    <i className='icon-close' />
    {t.common[0].CLEAR_ALL_FILTERS}
  </span>
);

const mapStateToProps = (state) => ({ t: state.language.t });

export default connect(mapStateToProps)(ClearAll);
