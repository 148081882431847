import { PROJECTPAGES, DETAIL_PROJECTPAGES } from '../store/actions';

const initialState = {};

export default function projectpages(state = initialState, action) {
  switch (action.type) {
    case PROJECTPAGES:
      return {
        ...state,
        projectpages: action.payload.data,
        totalItems: action.payload.count,
        sortLabel: action.payload.sortLabel,
      };
    case DETAIL_PROJECTPAGES:
      return {
        ...state,
        detail: action.payload.data,
      };
    default:
      return state;
  }
}
