import axios from '../requests/platformApi';
/**
 * @param {object} selectedOption - object with keys label and value. This is one of options from 'Event Type' list of options.
 * @return {bool} whether or not selected option is Webinar.
 */

export const webinarTypeIsChosen = (selectedOption) => {
  if (typeof selectedOption !== 'object' || selectedOption === null) {
    return false;
  }

  return selectedOption['value'] === Number(process.env.EVENT_TYPE_WEBINAR_ID);
};

/**
 * Fetch options list from API for drop-down selects
 * @param {string} url - API endpoint url
 * @return {function} to be used as react-select loadOptions prop
 */

export const fetchOptions = (url, options = {}) => {
  const { labelKey = 'label' } = options;
  return async () => {
    const response = await axios.get(url);
    const options = response.data.data.map((option) => ({
      label: option[labelKey],
      value: option['id'],
    }));
    return { options };
  };
};
