import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const VideoPlayButton = (props) => {
  const { index, height, href, rBig, rSmall } = props;

  return (
    <Fragment>
      <svg width='100%' height={height}>
        <defs>
          <clipPath id={`clip-path-${index}`}>
            <circle cx='50%' cy='50%' r={rSmall} />
          </clipPath>
        </defs>

        <filter id='blur'>
          <feGaussianBlur stdDeviation='5' />

          <feComposite in2='SourceGraphic' operator='atop' />
        </filter>

        <image
          xlinkHref={href}
          width='100%'
          height='100%'
          preserveAspectRatio='xMidYMid slice'
        />

        <image
          xlinkHref={href}
          width='100%'
          height='100%'
          preserveAspectRatio='xMidYMid slice'
          filter='url(#blur)'
          clipPath={`url(#clip-path-${index})`}
        />

        <circle
          className='play-button-bg'
          cx='50%'
          cy='50%'
          r={rBig}
          fill='rgba(0,0,0,.2)'
        />
      </svg>

      <div className='play-button'>
        <i className='icon-play' />
      </div>
    </Fragment>
  );
};

VideoPlayButton.propTypes = {
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  href: PropTypes.string.isRequired,
  rBig: PropTypes.number,
  rSmall: PropTypes.number,
};

VideoPlayButton.defaultProps = {
  rBig: 35,
  rSmall: 25,
};

export default VideoPlayButton;
