import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Redirect, withRouter } from 'react-router-dom';
import axios from '../../requests/platformApi';

import FormHandler from '../form-handler';
import Form from '../forms/set-new';
import GlobalError from '../global-error';
import Layout from '../layout';
import { addFlashMessage } from '../../actions/flash-messages';
import { password, passwordShouldMatch } from '../../validations/validations';
import languages from '../../helpers/languages';

function View(props) {
  const regLang = languages[props.lan].registration[0];
  if (!params.resetToken) {
    return <Redirect to='/sign-in' />;
  }
  const onSubmitSuccess = () => {
    props.history.push('/sign-in');
    props.addFlashMessage(regLang.PWD_UPD_SUCCESS, 'success');
  };
  return (
    <Layout title={regLang.PASSWORD_RESET}>
      <FormHandler
        initialValues={initialValues}
        submitRequest={submitRequest}
        onSubmitSuccess={onSubmitSuccess}
        mapFieldsToValidations={mapFieldsToValidations}
      >
        {(formState, onChange, onSubmit) => (
          <GlobalError
            defaultText={regLang.PLS_ENTER_NEW_PASSWORD}
            error={formState.globalError}
          >
            <Form
              values={formState.values}
              errors={formState.errors}
              onChange={onChange}
              onSubmit={onSubmit}
              buttonDisabled={formState.buttonDisabled}
            />
          </GlobalError>
        )}
      </FormHandler>
    </Layout>
  );
}

View.propTypes = {
  addFlashMessage: PropTypes.func.isRequired,
  lan: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  lan: state.language.lan,
});

const mapDispatchToProps = { addFlashMessage };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View));

const submitRequest = (data) =>
  axios.post('/changePassword', data).then(
    (response) => response.data,
    ({ response }) => response.data
  );

const arr = window.location.hash.split('?');
const params = queryString.parse(arr[1] || '');
const initialValues = {
  password: '',
  confirmPassword: '',
  token: params.resetToken,
};

const mapFieldsToValidations = {
  password: password,
  confirmPassword: passwordShouldMatch('password'),
};
