import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import lifecycle from 'react-pure-lifecycle';

import noAvatarImage from '../../static/images/noavatar.svg';
import cogImage from '../../static/images/svg/cog.svg';
import gearImage from '../../static/images/cog-solid.svg';
import ListOfNotifications from '../../components/ListOfNotifications';
import HeaderDropdown from './components/HeaderDropdown';
import MobileProfile from './components/Mobile';
import Progress from '../../components/Progress';
import { fetchBellNotification } from '../../pages/Messenger/components/MessengerTabs/fetchApplozic';
import {
  fetchProgressProgess,
  notificationCount,
  reloadMessenger,
} from '../../store/actions';
import languages from '../../helpers/languages';

import { logout } from '../../actions/auth';

var language = languages['en'];
var header = language.header[0];

const mapStateToProps = (state) => {
  var progressCount = 0;
  if (state.setting.hasOwnProperty('setProfileProgress')) {
    progressCount = 30;
    for (var item in state.setting.setProfileProgress) {
      if (state.setting.setProfileProgress[item].done === true) {
        progressCount =
          +progressCount + +state.setting.setProfileProgress[item].points;
      }
    }
  } else {
    progressCount = 30;
  }
  if (progressCount) {
    progressCount = progressCount * 0.01;
  }
  return {
    user: state.auth.user,
    loggedIn: state.auth.isLoggedIn,
    frontEndBellCount: state.notifications.notificationBellCount,
    totalUnreadCount: state.messenger.totalUnreadCount,
    bellNotificationItem: state.notifications.bellNotificationItems,
    progress: progressCount,
    chats: state.messenger.chats.chatList,
    isMobile: state.responsive.isMobile,
    lan: state.language.lan,
  };
};

const mapDispatchToProps = {
  fetchProgressProgess,
  logout,
  notificationCount,
  reloadMessenger,
};

const methods = {
  componentDidMount(props) {
    props.fetchProgressProgess();
    if (props.user) {
      props.notificationCount(props.user.id);
    }
  },
};

const Profile = (props) => {
  const { chats, frontEndBellCount, totalUnreadCount, reloadMessenger } = props;

  let userId, avatar;
  // TODO: rewrite with connect({ t: state.language.t })
  language = languages[props.lan];
  header = language.header[0];

  if (props.loggedIn) {
    userId = props.user.id;
  }
  // if (props.isMobile) {
  //   return <MobileProfile {...props} />;
  // }

  const onBellToggle = (isOpened) => {
    if (isOpened) {
      reloadMessenger({
        key: 'bell_notification',
        fetchItems: fetchBellNotification,
      });
    }
  };
  return (

    <div id="top_user_panel">
      <div id="top_user_panel-left">
        <NavLink exact to='/dashboard' className="topbar_userpic">
          <span className="top_user_panel-notification" />
          <img src={props.user.avatar ? props.user.avatar : noAvatarImage} alt =""/>
          <span className="username">{props.user.first_name ? props.user.first_name : 'Login'}</span>
        </NavLink>
      </div>
      <label htmlFor="top_user_panelswitcher" id="top_user_panelsw" />
      <input id="top_user_panelswitcher" name="top_user_panelsw" type="checkbox" />
      <div id="top_user_panel-right">
        <div className="top_user_panel-buttons">

          <div className='header-buttons'>
            {/* empty button to remove when messaging will be back */}
            <button class="header-button"></button>
            <button class="header-button" aria-describedby="Your Dashboard">
              <NavLink exact to='/dashboard' title="Dashboard">
                <i className="fas fa-tachometer-alt-fast" />
              </NavLink>
            </button>
            <HeaderDropdown
              icon='fas fa-bell'
              unreadCount={frontEndBellCount}
              type='notification'
              userUid={props.userId}
              tooltip={header.NOTIFICATIONS}
              onToggle={onBellToggle}
              renderContents={() => (
                <ListOfNotifications
                  fetchItems={fetchBellNotification}
                  tab='notifications'
                  limit={5}
                  fullyClickable
                  stateKey='bell_notification'
                  button={{
                    title: header.SEE_ALL_ACTIVITY,
                    to: '/messenger?tab=notifications',
                  }}
                />
              )}
            />

            {/*             <HeaderDropdown
              icon='fas fa-envelope'
              unreadCount={totalUnreadCount}
              tooltip={header.MESSAGES}
              renderContents={() => (
                <ListOfNotifications
                  items={chats}
                  tab='chats'
                  fullyClickable
                  limit={5}
                  button={{
                    title: header.GO_TO_MY_MESSAGE,
                    to: '/messenger',
                  }}
                />
              )}
            /> */ }

            <HeaderDropdown
              icon='fas fa-cog'
              tooltip={header.MY_ACCOUNT}
              renderContents={() => (
                <div className='header-profile'>
                  <div className='header-profile__item header-profile__item--inset'>
                    <div className='header-profile__name'>
                      {props.user.first_name + ' ' + props.user.last_name}
                    </div>

                    <p className='header-profile__email' title={props.user.email}>
                      {props.user.email}
                    </p>
                  </div>

                  <div className='header-profile__item header-profile__item--inset'>
                    <div className='header-profile__strength'>
                      <span>{header.PROFILE_STRENGTH}</span>
                    </div>

                    <Progress small progress={props.progress} />
                  </div>

                  <div className='header-profile__item'>
                    <ul className="header-profile__item header-profile__links">
                      <li className='header-profile__name'>
                        <NavLink exact to='/settings'>
                          <span className='header-profile__name'>{header.MY_PROFILE}</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink exact to='/my-network'>
                          <span className='header-profile__name'>{header.MY_CONTACTS}</span>
                        </NavLink> </li>

                      {props.user.userRole === 'super_admin' && (
                        <li>
                          <NavLink exact to='/adminpanel'>
                            <span className='header-profile__name'>Admin Panel</span>
                          </NavLink> </li>
                      )}

                      <li>

                        <a
                          href='javascript: void null'
                          onClick={() => props.logout(props.login, props.userId)}
                        >
                          <span className='header-profile__name'>{header.LOGOUT}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            />
          </div>


        </div>
      </div>
    </div>
  );
};

Profile.propTypes = {
  avatar: PropTypes.string,
  closeAside: PropTypes.func,
  chats: PropTypes.array,
  frontEndBellCount: PropTypes.number,
  totalUnreadCount: PropTypes.number,
  isMobile: PropTypes.bool,
  logout: PropTypes.func,
  lan: PropTypes.string.isRequired,
};

Profile.defaultProps = {
  avatar: noAvatarImage,
  lan: 'en',
};
const profileLifeCycle = lifecycle(methods)(Profile);
const List = connect(mapStateToProps, mapDispatchToProps)(profileLifeCycle);
export default withRouter(List);
