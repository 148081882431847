import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'informed';
import { connect } from 'react-redux';

import Preloader from '../../components/Preloader';
import highlightFirstError from '../../helpers/highlight-first-error';

class Tab extends React.Component {
  static propTypes = {
    formProps: PropTypes.object,
    renderContents: PropTypes.func.isRequired,
    showPreloader: PropTypes.bool,
    title: PropTypes.string.isRequired,
    t: PropTypes.object,
  };

  static defaultProps = {
    formProps: {},
    showPreloader: true,
  };

  render() {
    const { formProps, renderContents, showPreloader, title, t } = this.props;
    return (
      <section className='detail sliderClass'>
        <div className='detail__whole'>
          <div className='member-tab-title'>
            <h3>{title}</h3>
          </div>

          {showPreloader ? (
            <Preloader size='medium' relative />
          ) : (
            <Form
              {...formProps}
              onSubmitFailure={highlightFirstError}
              render={({ formState }) => (
                <Fragment>
                  {renderContents({ formState })}

                  <div className='hr' />

                  <div className='flex'>
                    <div className='right'>
                      <div className='buttons'>
                        <button className='button' type='submit'>
                          <i className='icon-arrow--small' />

                          <span className='button__text'>
                            {t.common[0].SAVE}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
            />
          )}
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  t: state.language.t,
});

export default connect(mapStateToProps)(Tab);
