import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const MemberRating = (props) => {
  const { rating, reviews, isLoading, to, t } = props;

  return (
    <p className='member-rating'>
      <b>
        {isLoading || !props.starFlag ? (
          <span>&nbsp;</span>
        ) : (
          <Fragment>
            <i className='icon-star' /> {rating.toFixed(1)} / 5
          </Fragment>
        )}
      </b>

      {(isLoading || !!reviews) && (
        <Link to={to + '?tab=reviews'} className='info'>
          {isLoading ? (
            <span>&nbsp;</span>
          ) : (
            <Fragment>
              {reviews} {t.common[0].RECOMMENDATIONS}
            </Fragment>
          )}
        </Link>
      )}
    </p>
  );
};

MemberRating.propTypes = {
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reviews: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoading: PropTypes.bool,
  starFlag: PropTypes.bool,
  to: PropTypes.string,
  t: PropTypes.object,
};

MemberRating.defaultProps = {
  isLoading: false,
  rating: 0,
  starFlag: false,
  to: '',
};

const mapStateToProps = (state) => ({ t: state.language.t });

export default connect(mapStateToProps)(MemberRating);
