import React from 'react';
import PropTypes from 'prop-types';

const SearchFilter = ({children }) => (
    <div className=''s="selectinputsection">
    <div className="custom-select"><i className="far fa-chevron-down"></i>
    {children}
  </div>
  </div>
);

SearchFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
};

export default SearchFilter;
