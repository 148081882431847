import React from 'react';
import lifecycle from 'react-pure-lifecycle';
import { connect } from 'react-redux';

import { getInvestContent } from '../store/actions';
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import Preloader from '../components/Preloader';
import languages from '../helpers/languages';

var language = languages['en'];
var common = language.common[0];
var menu = language.menu[0];


const mapStateToProps = (state) => ({
 investContent: state.invest.investContent,
  lan: state.language.lan,
  userRole: state.auth.user.userRole,
});

const mapDispatchToProps = {
  getInvestContent,
};

const methods = {
  componentDidMount(props) {
    props.getInvestContent();
  },
};

const PageInvest = (props) => {
  var roleFlag = true;
  if (props.userRole === 'member' || typeof props.userRole === 'undefined') {
    roleFlag = false;
  }
  language = languages[props.lan];
  common = language.common[0];
  menu = language.menu[0];

  const isLoading =
    props.investContent === '' || props.investContent === 'undefined';

  const url = '';

  const attributes = roleFlag ? { href: url } : {};

  const rawHTML = `<div className="content content_p-margins"><h2><strong>Apply for the SheTrades Invest</strong></h2>
<p><strong>SheTrades Invest</strong><br><p>SheTrades Invest is dedicated and focused on unlocking finance for women in developing and least developed markets. The project facilitates the connection of capital providers with women seeking funding and SMEs suppoprting women, while promoting the mainstream of gender-lens investment and building capacity of women entrepreneurs to become investment and due diligence ready.
</p>
<p class="text-left">The project is currently receiving applications from:</p>
<p>Women-led Businesses & SMEs Benefitting women</p>
<p>Capital providers</p>
</div>`;
  return (
    <CustomizedDocumentTitle title={menu.INVEST}>
      <div className='transition-page'>
        {isLoading ? (
          <Preloader />
        ) : (
          <section className='card card_for-news card_for-full-news'>
            <div className='card__image'>
              <img src='../images/image--invest.jpg' alt='SheTrades Invest' />
            </div>
            <div className='card__top' />
            <div
              className='content content_p-margins'
              dangerouslySetInnerHTML={{ __html: rawHTML }}
            />
            
            <a {...attributes} target='_blank' rel="noreferrer" className='button'>
              <i className='icon-redirect' />
              {/* <span className='button__text'>{common.LETS_GO}</span> */}
              <span className='button__text'>APPLY_TO_SHE_TRADES_INVEST</span>
            </a>
          </section>
        )}
      </div>
    </CustomizedDocumentTitle>
  );
};

const InvestCycle = lifecycle(methods)(PageInvest);
const List = connect(mapStateToProps, mapDispatchToProps)(InvestCycle);
export default List;