import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps } from 'recompose';
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import { GOOGLE_APIS_KEY } from '../constants';
import markerIcon from '../static/images/marker.svg';

class CustomGoogleMap extends React.Component {
  componentDidUpdate(prevProps) {
    const { forceMove: prevForceMove = {} } = prevProps;
    const { forceMove = {} } = this.props;
    if (
      forceMove.lat !== prevForceMove.lat ||
      forceMove.lng !== prevForceMove.lng
    ) {
      this.map.panTo({ lat: forceMove.lat, lng: forceMove.lng });
    }
  }

  render() {
    const {
      lat,
      lng,
      zoom,
      showMarker,
      markerShiftY,
      onClick = () => {},
    } = this.props;

    return (
      <GoogleMap
        defaultZoom={zoom}
        defaultCenter={{ lat: lat + markerShiftY, lng }}
        onClick={onClick}
        ref={(el) => (this.map = el)}
      >
        {showMarker && <Marker position={{ lat, lng }} icon={markerIcon} />}
      </GoogleMap>
    );
  }
}

CustomGoogleMap.propTypes = {
  lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  zoom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  markerShiftY: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showMarker: PropTypes.bool,
  forceMove: PropTypes.object,
};

CustomGoogleMap.defaultProps = {
  lat: 53.4084,
  lng: -2.9916,
  zoom: 11,
  showMarker: false,
  markerShiftY: -1e-2,
};

export const StaticGoogleMap = (props) => {
  const { center, zoom, size } = props;
  const { lat, lng } = center;
  const { width, height } = size;
  const mapConfig = {
    center: typeof center === 'string' ? center : `${lat},${lng}`,
    zoom,
    size: `${width}x${height}`,
    maptype: 'roadmap',
    key: GOOGLE_APIS_KEY,
  };

  const mapConfigParamString = Object.keys(mapConfig)
    .map((key) => `${key}=${mapConfig[key]}`)
    .join('&');

  const src = `https://maps.googleapis.com/maps/api/staticmap?${mapConfigParamString}`;

  return (
    <div className='static-google-map'>
      <img src={src} alt='' className='static-google-map__map' />

      <img src={markerIcon} alt='' className='static-google-map__marker' />
    </div>
  );
};

StaticGoogleMap.propTypes = {
  center: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  size: PropTypes.object.isRequired,
  zoom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

StaticGoogleMap.defaultProps = {
  zoom: 11,
};

export default compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${GOOGLE_APIS_KEY}`,
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div className='google-map' />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  withScriptjs,
  withGoogleMap
)(CustomGoogleMap);
