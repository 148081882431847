/* eslint-disable no-unused-expressions */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Region from '../../components/Region';
import Tag from '../../components/Tag';
import UserNameWithAvatar from '../../components/UserNameWithAvatar';
import AdditionalFiles from '../../components/Detail/components/AdditionalFiles';
import DetailApply from './components/DetailApply';
import TopMessage from '../../components/TopMessage';
import Modal from '../../components/Modal';
import { editItem, closeThisItem } from '../../store/actions';
import languages from '../../helpers/languages';

var language = languages['en'];
var createTender = language.create_tender[0];
var common = language.common[0];
var offers = language.offers[0];

const mapStateToProps = (state) => ({
  isMobile: state.responsive.isMobile,
  lan: state.language.lan,
});

const mapDispatchToProps = {
  editItem,
  closeThisItem,
};

class Detail extends Component {
  constructor() {
    super();
    this.state = {
      isModalOpened: false,
    };
  }

  setIsModalOpened(isModalOpened) {
    this.setState({
      ...this.state,
      isModalOpened,
    });
  }

  static propTypes = {
    isPreview: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    tags: PropTypes.array,
    region: PropTypes.object,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    features: PropTypes.object,
    dates: PropTypes.object,
    files: PropTypes.array,
    userNameWithAvatar: PropTypes.object,
    applied: PropTypes.number,
    value: PropTypes.string,
    specsOnRight: PropTypes.array,
    tagsOnRight: PropTypes.array,
    singleName: PropTypes.string,
    content: PropTypes.string.isRequired,
    alreadyApplied: PropTypes.bool,
    detailApplied: PropTypes.bool,
    detailBookmarked: PropTypes.bool,
    applyFunction: PropTypes.func,
    addBookmark: PropTypes.func,
    isApplySuccessShown: PropTypes.bool,
    onSuccessMessageClose: PropTypes.func,
    editFlag: PropTypes.string,
    pageChosen: PropTypes.string,
    bookmarkedFlag: PropTypes.string,
  };

  static defaultProps = {
    tags: [],
    files: [],
    tagsOnRight: [],
    specsOnRight: [],
    id: '',
    applied: 0,
    singleName: '',
    dates: null,
    region: null,
    features: null,
    userNameWithAvatar: null,
    isPreview: false,
    applyFunction: null,
    status: null,
    alreadyApplied: false,
    editFlag: '',
    pageChosen: '',
    bookmarkedFlag: '',
  };

  componentDidMount() {
    const { isPreview, isMobile } = this.props;

    if (isPreview || isMobile) {
      return;
    }
  }

  editItem() {
    this.props.editItem(window.location.hash);
  }
  closeItem() {
    this.setIsModalOpened(false);
    this.props.closeThisItem(window.location.hash, this.props.search);
  }
  render() {
    language = languages[this.props.lan];
    createTender = language.create_tender[0];
    common = language.common[0];
    offers = language.offers[0];
    const {
      id,
      tags,
      region,
      title,
      description,
      features,
      dates,
      files,
      userNameWithAvatar,
      applied,
      value,
      specsOnRight,
      tagsOnRight,
      singleName,
      content,
      creatorId,
      isPreview,
      applyFunction,
      alreadyApplied,
      addBookmark,
      bookmarked,
      detailBookmarked,
      detailApplied,
      isAuthor,
      previousUrl,
      search,
      isApplySuccessShown,
      onSuccessMessageClose,
      isDetailApplyShown,
      onDetailApplyToggle,
      status,
      editFlag,
      pageChosen,
      isMobile,
      bookmarkedFlag,
    } = this.props;

    const lcSingleName = singleName.toLowerCase();

    const isSingleColumn = isMobile || isPreview;

    if (id) {
      tags.push({ type: 'default', text: `Id: ${id}` });
    }

    const rightTopHtml = (
      <div className='apply-tender__show-on-scroll'>
        {tagsOnRight && (
          <div className='apply-tender__tags'>
            {tagsOnRight &&
              tagsOnRight.map((tag, i) => <Tag {...tag} key={i} />)}
          </div>
        )}

        {title && <div className='h3'>{title}</div>}
      </div>
    );
    const bookStyles = {
      borderColor: '#39424C',
      color: 'white',
      background: '#39424C',
    };
    const rightBottomHtml = (
      <div className='apply-tender__bottom'>
        {!!applied && (
          <div className='apply-tender__applied'>
            <span className='info'>
              <i className='icon-user' />
              <span className='info__text'>
                {applied} {common.SHETRADES_MEMBERS_HAVE_APPLIED}
              </span>
            </span>
          </div>
        )}

        {status === '1' && (
          <div>
            {isAuthor !== true && bookmarkedFlag === 'notPreview' && (
              <div>
                {detailApplied ? (
                  <a
                    href='javascript: void null'
                    style={bookStyles}
                    className='button button_arrow'
                    disabled='true'
                  >
                    <i className='icon-arrow--small' />

                    <span className='button__text'>
                      {singleName} {common.ALREADY_APPLIED}
                    </span>
                  </a>
                ) : (
                  <a
                    href='javascript: void null'
                    onClick={() => onDetailApplyToggle(true)}
                    className='button button_arrow'
                  >
                    <i className='icon-arrow--small' />
                    {singleName === common.TENDER && (
                      <span className='button__text'>
                        {common.APPLY_FOR_THE} {lcSingleName}{' '}
                      </span>
                    )}

                    {singleName === offers.OFFER && (
                      <span className='button__text'>
                        {common.MAKE_AN_ORDER}
                      </span>
                    )}
                  </a>
                )}

                {bookmarkedFlag === 'notPreview' && (
                  <a
                    href='javascript: void null'
                    className='button '
                    style={detailBookmarked === true ? bookStyles : {}}
                    onClick={(e) => {
                      addBookmark
                        ? addBookmark(null, null, search, previousUrl)
                        : '';
                    }}
                  >
                    <i className='icon-bookmark' />
                    {detailBookmarked === false && (
                      <span className='button__text'>
                        {common.FOLLOWUP_THE} {lcSingleName}
                      </span>
                    )}
                    {detailBookmarked === true && (
                      <span className='button__text'>
                        {common.REMOVE_FROM_FOLLOWUP}
                      </span>
                    )}
                  </a>
                )}
              </div>
            )}
          </div>
        )}

        {status === '4' && (
          <div>
            {common.THIS} {lcSingleName} {common.WAS_CLOSED_BY_CREATOR}.
          </div>
        )}
        {status === '3' && (
          <div>
            {common.THIS} {lcSingleName} {common.WAS_CONFIRMED_BY_CREATOR}.
          </div>
        )}

        {isAuthor === true && (
          <div>
            <a
              href='javascript: void null'
              style={bookStyles}
              className='button button_arrow'
              disabled='true'
            >
              {common.YOU_ARE_THE_AUTHOR} {lcSingleName}
            </a>
          </div>
        )}
        {isAuthor === true && editFlag === 'myItems' && (
          <div>
            <a
              href='javascript:void null'
              className='button button_minimal'
              onClick={(e) => this.editItem()}
            >
              {common.EDIT_ITEM}
            </a>
          </div>
        )}

        {isAuthor === true &&
          pageChosen === '' &&
          status !== 4 &&
          bookmarkedFlag === 'notPreview' && (
            <div>
              <a
                href='javascript:void null'
                className='button button_minimal'
                onClick={(e) => this.setIsModalOpened(true)}
              >
                {common.CLOSE} {lcSingleName}
              </a>
            </div>
          )}
      </div>
    );

    const { isModalOpened } = this.state;

    const rightJsx = (
      <div
        className='apply-tender'
        ref={(el) => {
          this.apply = el;
        }}
      >
        {!isSingleColumn && rightTopHtml}

        {singleName !== common.TENDER && singleName !== offers.OFFER && (
          <div className='value'>
            <span className='value__text'>{common.VALUE}:</span>

            <span className='value__number'>{value}</span>
          </div>
        )}

        {specsOnRight && (
          <div className='dates'>
            {specsOnRight.map((spec, i) => (
              <p key={i}>
                <span className='color-light'>{spec.label}: </span>

                <b>{spec.value}</b>
              </p>
            ))}
          </div>
        )}

        {rightBottomHtml}
      </div>
    );

    return (
      <section className='detail detail_big'>
        <div
          className={isPreview ? 'detail__whole' : 'detail__left'}
          ref={(el) => {
            this.left = el;
          }}
        >
          <div className='detail__top'>
            {tags && tags.map((tag, i) => <Tag {...tag} key={i} />)}
            {region && <Region {...region} />}
          </div>

          <h1
            className='h2'
            ref={(el) => {
              this.title = el;
            }}
          >
            {title}
          </h1>

          {description && (
            <div
              className='detail__description'
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}

          {isSingleColumn && rightJsx}

          <div
            className='content content_p-margins'
            dangerouslySetInnerHTML={{ __html: content }}
          />

          <div className='content'>
            {features.title && <h3>{features.title}:</h3>}

            {features.list && (
              <ul>
                {features.list.map((el, i) => (
                  <li key={i}>{el}</li>
                ))}
              </ul>
            )}

            {dates && <h3>{createTender.IMPORTANT_DATES}:</h3>}

            {dates && (
              <div className='dates'>
                <p>
                  <span className='color-light'>{common.OPENING_DATE}: </span>
                  <b>{dates.opening}</b>
                </p>

                <p>
                  <span className='color-light'>{common.CLOSING_DATE}: </span>
                  <b>{dates.closing}</b>
                </p>
              </div>
            )}

            {files && <AdditionalFiles isPreview={isPreview} files={files} />}

            {userNameWithAvatar && <h3>{createTender.OFFERED_BY}:</h3>}

            {userNameWithAvatar && (
              <UserNameWithAvatar
                regionName={region && region.name}
                {...this.props.userNameWithAvatar}
              />
            )}
          </div>
        </div>

        {!isSingleColumn && (
          <div className='detail__right right'>{rightJsx}</div>
        )}

        {bookmarkedFlag === 'notPreview' && status !== '4' && (
          <DetailApply
            isShown={isDetailApplyShown}
            onClose={() => onDetailApplyToggle(false)}
            onApply={(data) => applyFunction({ ...data, id })}
          />
        )}

        <TopMessage
          isShown={isApplySuccessShown}
          onCloseClick={onSuccessMessageClose}
          type='success'
          text={`${common.TOP_MESSAGE_DETAIL} ${singleName} "${title}"`}
        />
        <Fragment>
          <Modal
            isOpen={isModalOpened}
            onRequestClose={() => this.setIsModalOpened(false)}
            smallOnMobile
            buttons={{
              left: {
                text: common.CANCEL,
                className: 'gray',
                onClick: () => this.setIsModalOpened(false),
              },
              right: {
                text: common.CONFIRM,
                className: 'brand',
                onClick: () => this.closeItem(this, false),
              },
            }}
          >
            <section className='content content_p-margins modal-content__title'>
              <h4>{common.CONFIRM_ACTION}</h4>
            </section>
          </Modal>
        </Fragment>
      </section>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
