import React, { Fragment } from 'react';
import { Form } from 'informed';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import axios from '../../requests/platformApi';

import 'react-dates/lib/css/_datepicker.css';
import Page from '../../components/Page';
import CardForFullNews from '../../components/CardForFullNews';
import Input from '../../components/settings/Input';
import Dropzone from 'react-dropzone';
import Cards from './components/Cards';
import Details from './components/Details';
import {
  WEBINAR_SECTOR,COUNTRY,NAME,DATES
} from '../../components/Search/TopFilters/types';
import eventValidation from '../../validations/validationEvents';
import {
  searchCountries,
  fetchWebinars,
  doeventErrors,
  createEvent,
  addBookmark,
  fetchWebinarMonth,
  setEventPreview,
  setEventsErrorFlag,
  detailWebinars,
} from '../../store/actions';
import highlightFirstError from '../../helpers/highlight-first-error';
import languages from '../../helpers/languages';

import { fetchOptions } from '../../services/shared';
import MultiInputField from '../../components/settings/MultiInput/components/Field';
import PreventEmptyDetail from '../../components/PreventEmptyDetail';

var language = languages['en'];
var webinarsLocale = language.webinars[0];
var label = language.events[0];
var common = language.common[0];
var menu = language.menu[0];
var create = language.create_event[0];

const mapStateToProps = (state) => {
  return {
    cards: state.webinars.webinars,
    totalItems: state.webinars.totalItems,
    hasError: state.events.formHasError,
    errorMessages: state.events.errorMessages,
    formHasError: state.events.formHasError,
    mainErrorMessage: state.events.mainErrorMessage,
    countryOptions: state.defaultLoad.countryOptions,
    featureItems: state.webinars.featureItems,
    detailPreview: state.events.detailPreview,
    detailBookmark: state.events.eventBookmark,
    verifierStatus: state.auth.user.verifier_status,
    search: state.search,
    lan: state.language.lan,
    userRole: state.auth.user.userRole,
    detail: state.webinars.detail_webinars,
  };
};

const mapDispatchToProps = {
  fetchWebinars,
  searchCountries,
  doeventErrors,
  eventValidation,
  createEvent,
  fetchWebinarMonth,
  setEventPreview,
  addBookmark,
  setEventsErrorFlag,
  detailWebinars,
};

class PageWebinars extends React.Component {
  constructor() {
    super();
    this.state = {
      coverImage: [],
      locations: [
        { label: 'Abu-Dabi', value: 'abu-dabi' },
        { label: 'Bagdad', value: 'badgad' },
      ],
      attachments: [],
      photos: [],
      coverImage: [],
      sponsor: [],
      sectorOptions: null,
    };
  }

  componentDidMount(props) {
    this.props.fetchWebinars(window.location.hash);
    this.props.searchCountries();
    this.props.setEventsErrorFlag(false);
    fetchOptions('/events/sector', { labelKey: 'sector' })().then(
      ({ options }) => {
        this.setState({ sectorOptions: options });
      }
    );
    var queryParam = this.props.location.pathname.split('/');
    if (queryParam[1] && queryParam[2] && !isNaN(queryParam[2]))
      this.props.detailWebinars(window.location.hash);
  }

  beforeNextStep(currentStep, multiStepForm) {
    var stepSplit = window.location.hash.split('=');
    var step = stepSplit[1];

    if (step === 3) {
      return true;
    }

    var formStates = [];
    if (!multiStepForm.currentForm) return true;
    const titleImage = this.state.hasOwnProperty('coverImage')
      ? this.state.coverImage
      : {};
    const sponsors = this.state.hasOwnProperty('sponsor')
      ? this.state.sponsor
      : {};
    const galleryImage = this.state.hasOwnProperty('photos')
      ? this.state.photos
      : {};
    const attachments = this.state.hasOwnProperty('attachments')
      ? this.state.attachments
      : {};
    const { key, formApi } = multiStepForm.currentForm;
    formStates[key] = formApi.getState();
    var response = eventValidation(
      formStates,
      key,
      titleImage,
      galleryImage,
      sponsors,
      language
    );
    this.props.doeventErrors('CheckError', response);
    if (response.hasError === false && key === 2) {
      var tab1 = multiStepForm.formStates[1]['values'];
      var tab2 = formStates[2]['values'];
      this.props.setEventPreview(
        tab1,
        tab2,
        titleImage,
        galleryImage,
        sponsors,
        this.props.countryOptions,
        attachments,
        this.state.sectorOptions
      );
    }

    if (response.hasError) {
      highlightFirstError(response.errors);
      return false;
    } else {
      if (currentStep === 2) {
        multiStepForm.setCurrentForm(null);
      }
      return true;
    }
  }

  async onFinish(multiStepForm) {
    await multiStepForm.onFinish();

    // request to save
    var data = multiStepForm.formStates;
    const titleImage = this.state.hasOwnProperty('coverImage')
      ? this.state.coverImage
      : {};
    const sponsors = this.state.hasOwnProperty('sponsor')
      ? this.state.sponsor
      : {};
    const galleryImage = this.state.hasOwnProperty('photos')
      ? this.state.photos
      : {};
    const attachments = this.state.hasOwnProperty('attachments')
      ? this.state.attachments
      : {};
    this.props.createEvent(
      data,
      titleImage,
      galleryImage,
      sponsors,
      attachments
    );
  }

  onCoverImageChange(coverImage) {
    this.setState({
      ...this.state,
      coverImage,
    });
  }

  onPhotosChange(photos) {
    this.setState({
      ...this.state,
      photos,
    });
  }

  onSponsorChange(sponsor) {
    this.setState({
      ...this.state,
      sponsor,
    });
  }

  async fetchEventTypes() {
    const response = await axios.get('/events/type');
    const eventTypes = response.data.data.map(({ type, id }) => ({
      label: type,
      value: id,
    }));
    this.setState({
      eventTypes,
    });
    return { options: eventTypes };
  }

  render() {
    language = languages[this.props.lan];
    webinarsLocale = language.webinars[0];
    label = language.events[0];
    common = language.common[0];
    menu = language.menu[0];
    create = language.create_event[0];

    var roleFlag = false;
    if (
      this.props.userRole === 'member' ||
      typeof this.props.userRole === 'undefined'
    ) {
      roleFlag = false;
    }

    const toolbar = {
      tabs: {
        values: {
          upcoming: {
            text: webinarsLocale.UPCOMING_WEBINARS,
            filter: (card) => moment(card.end).isAfter(moment.now()),
          },
          past: {
            text: webinarsLocale.PAST_WEBINARS,
            filter: (card) => moment(card.end).isSameOrBefore(moment.now()),
          },
        },
      },
      withToggleView: true,
    };
    const {
      location,
      fetchWebinarMonth,
      cards,
      totalItems,
      detail,
      featureItems,
    } = this.props;
    const { coverImage, photos, sponsor } = this.state;
    const [image = {}] = coverImage;
    const { preview } = image;
    return (
      <Fragment>
        <Page
          name={menu.WEBINARS}
          singleName={common.WEBINAR}
          search={this.props.search}
          topfilters={[NAME, COUNTRY,WEBINAR_SECTOR]}
          toolbar={toolbar}
          renderAfterTitle={() => (
            <div className='after-title flex'>
              <div className='left'>
                <p className='gray'>{}</p>
              </div>
            </div>
          )}
          canCreateNew={roleFlag}
          createNewProps={{
            onFinish: this.onFinish.bind(this),
            beforeNext: this.beforeNextStep.bind(this),
            renderStep: (step, multiStepForm) => {
              switch (step) {
                case 1:
                  return (
                    <Form
                      getApi={(formApi) =>
                        multiStepForm.setCurrentForm({ key: 1, formApi })
                      }
                      render={({ formApi }) => (
                        <Fragment>
                          <div className='inputs'>
                            <Input
                              id='title'
                              label={common.TITLE}
                              required
                              hasError={
                                this.props.errorMessages.hasOwnProperty(
                                  'title'
                                ) && this.props.errorMessages.title
                                  ? true
                                  : false
                              }
                              errors={
                                this.props.errorMessages.hasOwnProperty(
                                  'title'
                                ) && this.props.errorMessages.title
                                  ? this.props.errorMessages.title[0]
                                  : ''
                              }
                              placeholder={common.TITLE_PLACEHOLDER}
                            />

                            <Input
                              id='type'
                              label={create.EVENT_TYPE}
                              required
                              hasError={
                                this.props.errorMessages.hasOwnProperty(
                                  'type'
                                ) && this.props.errorMessages.type
                                  ? true
                                  : false
                              }
                              errors={
                                this.props.errorMessages.hasOwnProperty(
                                  'type'
                                ) && this.props.errorMessages.type
                                  ? this.props.errorMessages.type[0]
                                  : ''
                              }
                              placeholder={create.TYPE_PLACEHOLDER}
                              type='select'
                              selectProps={{
                                async: true,
                                loadOptions: fetchOptions('/events/type', {
                                  labelKey: 'type',
                                }),
                              }}
                            />

                            <Input
                              id='organizer'
                              label={create.ORGANISER}
                              required
                              hasError={
                                this.props.errorMessages.hasOwnProperty(
                                  'organizer'
                                ) && this.props.errorMessages.organizer
                                  ? true
                                  : false
                              }
                              errors={
                                this.props.errorMessages.hasOwnProperty(
                                  'organizer'
                                ) && this.props.errorMessages.organizer
                                  ? this.props.errorMessages.organizer[0]
                                  : ''
                              }
                              placeholder={create.ORGANISER_PLACEHOLDER}
                            />

                            <Input
                              id='startTime'
                              label={create.START_DATE}
                              required
                              hasError={
                                this.props.errorMessages.hasOwnProperty(
                                  'startTime'
                                ) && this.props.errorMessages.startTime
                                  ? true
                                  : false
                              }
                              errors={
                                this.props.errorMessages.hasOwnProperty(
                                  'startTime'
                                ) && this.props.errorMessages.startTime
                                  ? this.props.errorMessages.startTime[0]
                                  : ''
                              }
                              type='time'
                            />

                            <Input
                              id='endTime'
                              label={create.END_DATE}
                              required
                              hasError={
                                this.props.errorMessages.hasOwnProperty(
                                  'endTime'
                                ) && this.props.errorMessages.endTime
                                  ? true
                                  : false
                              }
                              errors={
                                this.props.errorMessages.hasOwnProperty(
                                  'endTime'
                                ) && this.props.errorMessages.endTime
                                  ? this.props.errorMessages.endTime[0]
                                  : ''
                              }
                              type='time'
                            />

                            {this.state.sectorOptions ? (
                              <MultiInputField
                                id='sector'
                                field='sector'
                                label={create.SECTOR}
                                required
                                hasError={
                                  this.props.errorMessages.hasOwnProperty(
                                    'sector'
                                  ) && this.props.errorMessages.sector
                                    ? true
                                    : false
                                }
                                errors={
                                  this.props.errorMessages.hasOwnProperty(
                                    'sector'
                                  ) && this.props.errorMessages.sector
                                    ? this.props.errorMessages.sector[0]
                                    : ''
                                }
                                placeholder={create.SECTOR_PLACEHOLDER}
                                options={this.state.sectorOptions}
                              />
                            ) : null}

                            <Input
                              id='website'
                              label={create.WEBSITE}
                              required
                              hasError={
                                this.props.errorMessages.hasOwnProperty(
                                  'website'
                                ) && this.props.errorMessages.website
                                  ? true
                                  : false
                              }
                              errors={
                                this.props.errorMessages.hasOwnProperty(
                                  'website'
                                ) && this.props.errorMessages.website
                                  ? this.props.errorMessages.website[0]
                                  : ''
                              }
                              placeholder={create.WEBSITE_PLACEHOLDER}
                            />

                            <Input
                              id='applyUrl'
                              label={create.APPLY_URL}
                              hasError={
                                this.props.errorMessages.hasOwnProperty(
                                  'applyUrl'
                                ) && this.props.errorMessages.applyUrl
                                  ? true
                                  : false
                              }
                              required
                              errors={
                                this.props.errorMessages.hasOwnProperty(
                                  'applyUrl'
                                ) && this.props.errorMessages.applyUrl
                                  ? this.props.errorMessages.applyUrl[0]
                                  : ''
                              }
                              placeholder={create.APPLY_URL_PLACEHOLDER}
                            />

                            <Input
                              id='maplocation'
                              label={create.LOCATION}
                              required={true}
                              placeholder={create.MAP_PLACEHOLDER}
                              type='location'
                              hasError={
                                this.props.errorMessages.hasOwnProperty(
                                  'maplocation'
                                ) && this.props.errorMessages.maplocation
                                  ? true
                                  : false
                              }
                              errors={
                                this.props.errorMessages.hasOwnProperty(
                                  'maplocation'
                                ) && this.props.errorMessages.maplocation
                                  ? this.props.errorMessages.maplocation[0]
                                  : ''
                              }
                              searchable
                              formApi={formApi}
                            />
                          </div>
                        </Fragment>
                      )}
                    />
                  );

                case 2:
                  return (
                    <Form
                      getApi={(formApi) =>
                        multiStepForm.setCurrentForm({ key: 2, formApi })
                      }
                      render={({ formApi }) => (
                        <Fragment>
                          <div data-input-id='coverImage'>
                            <Dropzone
                              id='coverImage'
                              value={coverImage}
                              accept='image/jpeg, image/png'
                              onDrop={this.onCoverImageChange.bind(this)}
                              className='upload-file'
                              activeClassName='dropzone_active'
                              rejectClassName='dropzone_rejected'
                              multiple={false}
                              style={
                                preview
                                  ? {
                                      backgroundImage: `url(${preview})`,
                                      backgroundSize: 'cover',
                                    }
                                  : null
                              }
                            >
                              <button className='button' type='button'>
                                <i className='icon-upload' />

                                <span className='button__text'>
                                  {create.UPLOAD_COVER}
                                </span>
                              </button>

                              <div className='info'>
                                {create.COVER_IMAGE_INFO}
                              </div>
                            </Dropzone>
                          </div>

                          <div className='inputs'>
                            <Input
                              id='teaser'
                              label={create.SHORT_DESCRIPTION}
                              required
                              type='textarea'
                              placeholder={create.SHORT_DESCRIPTION}
                              hasError={
                                this.props.errorMessages.hasOwnProperty(
                                  'teaser'
                                ) && this.props.errorMessages.teaser
                                  ? true
                                  : false
                              }
                              errors={
                                this.props.errorMessages.hasOwnProperty(
                                  'teaser'
                                ) && this.props.errorMessages.teaser
                                  ? this.props.errorMessages.teaser[0]
                                  : ''
                              }
                              textareaProps={{ maxLength: 500 }}
                            />
                            <Input
                              id='editor'
                              label={create.DESCRIPTION}
                              required
                              type='editor'
                              placeholder={create.DESCRIPTION}
                              hasError={
                                this.props.errorMessages.hasOwnProperty(
                                  'description'
                                ) && this.props.errorMessages.description
                                  ? true
                                  : false
                              }
                              errors={
                                this.props.errorMessages.hasOwnProperty(
                                  'description'
                                ) && this.props.errorMessages.description
                                  ? this.props.errorMessages.description[0]
                                  : ''
                              }
                            />

                            <Input
                              id='photos'
                              label={create.GALLERY_IMAGES}
                              type='images'
                              value={photos}
                              required
                              hasError={
                                this.props.errorMessages.hasOwnProperty(
                                  'photos'
                                ) && this.props.errorMessages.photos
                                  ? true
                                  : false
                              }
                              errors={
                                this.props.errorMessages.hasOwnProperty(
                                  'photos'
                                ) && this.props.errorMessages.photos
                                  ? this.props.errorMessages.photos[0]
                                  : ''
                              }
                              onChange={this.onPhotosChange.bind(this)}
                            />

                            <Input
                              id='sponsor'
                              label={create.SPONSOR_IMAGE}
                              type='images'
                              value={sponsor}
                              required
                              hasError={
                                this.props.errorMessages.hasOwnProperty(
                                  'sponsor'
                                ) && this.props.errorMessages.sponsor
                                  ? true
                                  : false
                              }
                              errors={
                                this.props.errorMessages.hasOwnProperty(
                                  'sponsor'
                                ) && this.props.errorMessages.sponsor
                                  ? this.props.errorMessages.sponsor[0]
                                  : ''
                              }
                              onChange={this.onSponsorChange.bind(this)}
                            />
                          </div>
                        </Fragment>
                      )}
                    />
                  );

                case 3:
                  return (
                    <div className='create-new-preview'>
                      {this.props.detailPreview.title !== undefined && (
                        <CardForFullNews
                          isEvent
                          noSharing
                          {...this.props.detailPreview}
                          bookmarkFlag={'bookmarkFlag'}
                          detailsType={'events'}
                        />
                      )}
                    </div>
                  );
                default:
                  return null;
              }
            },
          }}
          cards={cards}
          fetchFunction={this.props.fetchWebinars}
          totalItems={totalItems}
          featureItems={featureItems}
          {...this.props}
          renderCards={(cards, filter, page, cardsPerPage) =>
            Cards(
              cards,
              filter,
              page,
              cardsPerPage,
              location,
              featureItems,
              totalItems,
              fetchWebinarMonth
            )
          }
          cardsPerPage={9}
          renderDetail={() => (
            <PreventEmptyDetail detail={detail}>
              <Details detail={detail} addBookmark={this.props.addBookmark} />
            </PreventEmptyDetail>
          )}
        />


      </Fragment>
    );
  }
}

PageWebinars.propTypes = {
  location: PropTypes.object.isRequired,
};

PageWebinars.defaultProps = {
  cards: [],
  detail: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(PageWebinars);
