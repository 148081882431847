import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Stars extends React.Component {
  static propTypes = {
    rating: PropTypes.number,
    onChange: PropTypes.func,
    editable: PropTypes.bool,
  };

  constructor() {
    super();
    this.state = {
      intermediateValue: null,
    };
  }

  onMouseLeave() {
    const { editable } = this.props;

    if (editable) {
      this.setState({
        ...this.state,
        intermediateValue: null,
      });
    }
  }

  onStarMouseEnter(i) {
    const { editable } = this.props;

    if (editable) {
      this.setState({
        ...this.state,
        intermediateValue: i + 1,
      });
    }
  }

  onStarClick(i) {
    const { editable, onChange } = this.props;
    if (editable && onChange) {
      onChange(i + 1);
    }
  }

  render() {
    const { rating } = this.props;
    const { intermediateValue } = this.state;
    const valueToShow = intermediateValue || rating;

    return (
      <p className='stars' onMouseLeave={this.onMouseLeave.bind(this)}>
        {Array(5)
          .fill()
          .map((el, i) => (
            <span
              key={i}
              className={classNames('icon-star', { active: i < valueToShow })}
              onMouseEnter={this.onStarMouseEnter.bind(this, i)}
              onClick={this.onStarClick.bind(this, i)}
            />
          ))}
      </p>
    );
  }
}

export default Stars;
