import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

class ButtonsList extends React.Component {
  constructor(props) {
    super(props);
    this.items = [];
    this.state = {
      hasOverflow: false,
      expanded: false,
      maxHeight: undefined,
      insertIndex: undefined,
    };
    this.restrictLines = this.restrictLines.bind(this);
  }

  componentDidMount() {
    this.restrictLines();
    window.addEventListener('resize', this.restrictLines);
  }

  componentDidUpdate(props) {
    if (props.items.length !== this.props.items.length) {
      this.restrictLines();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.restrictLines);
  }

  restrictLines() {
    if (!this.items[0]) return;
    const { maxLines } = this.props;
    let numberOfLines = 1;
    let currentOffsetTop = this.items[0].offsetTop;
    let hasOverflow = false;
    let maxHeight;
    let insertIndex;
    this.items.forEach((el, i) => {
      if (el.offsetTop > currentOffsetTop) {
        numberOfLines += 1;
        currentOffsetTop = el.offsetTop;
      }
      if (!hasOverflow && numberOfLines > maxLines) {
        insertIndex = i - 1;
        hasOverflow = true;
        maxHeight = currentOffsetTop;
      }
    });
    this.setState({
      insertIndex,
      hasOverflow,
      maxHeight,
    });
  }

  render() {
    const { items, t } = this.props;
    const { expanded, hasOverflow, insertIndex, maxHeight } = this.state;
    const wrapperStyle = expanded
      ? null
      : { maxHeight: maxHeight ? `${maxHeight}px` : 'none' };
    const moreArrowStyle = { transform: `rotate(${expanded ? -90 : 90}deg)` };
    const toggleElement = (
      <button
        className='buttons-list__show-more info'
        onClick={() => this.setState({ expanded: !expanded })}
      >
        {expanded
          ? t.forum[0].SHOW_LESS
          : t.forum[0].SHOW_MORE.replace('$1', items.length - insertIndex)}{' '}
        <i className='icon-arrow--small' style={moreArrowStyle} />
      </button>
    );
    return (
      <ul className='buttons-list' style={wrapperStyle}>
        {items.map(({ title, to, onClick, isActive }, i) => {
          const visible = !hasOverflow || expanded || insertIndex > i;
          const linkStyle = {
            visibility: visible ? '' : 'hidden',
            opacity: visible ? '' : 0,
            transition: visible
              ? `all .25s, opacity .25s ${Math.max(i - insertIndex, 0) * 0.02}s`
              : 'all .25s, opacity 0s',
          };
          return (
            <li key={to} ref={(el) => (this.items[i] = el)}>
              <NavLink
                to={to}
                onClick={onClick}
                title={title}
                isActive={isActive}
                style={linkStyle}
              >
                {title}
              </NavLink>
              {hasOverflow && !expanded && insertIndex === i && (
                <span className='toggle-positioner'>{toggleElement}</span>
              )}
            </li>
          );
        })}
        {hasOverflow && expanded && <li>{toggleElement}</li>}
      </ul>
    );
  }
}

ButtonsList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      isActive: PropTypes.func,
    })
  ).isRequired,
  maxLines: PropTypes.number,
};

ButtonsList.defaultProps = {
  maxLines: 2,
};

const mapStateToProps = (state) => ({ t: state.language.t });

export default connect(mapStateToProps)(ButtonsList);
