import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

const ModalButton = ({ text, ...props }) => <button {...props}>{text}</button>;

ModalButton.propTypes = {
  text: PropTypes.string.isRequired,
};

export const ModalBottom = ({ left, right }) => (
  <div className='modal-bottom'>
    <div className='flex'>
      <div className='left'>{left && <ModalButton {...left} />}</div>

      <div className='right'>{right && <ModalButton {...right} />}</div>
    </div>
  </div>
);

const Modal = (props) => {
  const {
    children,
    buttons,
    contentStyle,
    noBottom,
    withArrow,
    isMobile,
    smallOnMobile,
    withCloseButton,
    ...rest
  } = props;
  const { onRequestClose } = props;
  const { left, right } = buttons;
  let mobileContentOverrides;
  if (isMobile && smallOnMobile) {
    mobileContentOverrides = {
      width: 'auto',
    };
  } else if (isMobile) {
    mobileContentOverrides = {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      width: 'auto',
      boxShadow: 'none',
      background: '#F7F9FB',
    };
  }
  const finalContentStyle = Object.assign(
    {},
    {
      width: 720,
      borderRadius: 0,
      padding: 0,
      border: 'none',
      background: 'white',
      boxShadow: '0 10px 40px rgba(0,0,0,.05)',
      top: 140,
      left: 'auto',
      right: 'auto',
      bottom: 'auto',
      position: 'absolute',
    },
    contentStyle,
    mobileContentOverrides
  );

  return (
    <ReactModal
      closeTimeoutMS={350}
      ariaHideApp={false}
      style={{
        content: finalContentStyle,
        overlay: {
          backgroundColor: 'rgba(0,0,0,.1)',
          zIndex: 3,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}
      {...rest}
    >
      <div
        className={classNames('modal-content', {
          'modal-content_with-arrow': withArrow,
        })}
      >
        {children}
      </div>

      {!noBottom && <ModalBottom left={left} right={right} />}

      {withCloseButton && (
        <button
          className='modal-content__close'
          type='button'
          aria-label='Close'
          onClick={onRequestClose}
        >
          <i className='icon-close' />
        </button>
      )}
    </ReactModal>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state.responsive.isMobile,
});

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  buttons: PropTypes.object,
  contentStyle: PropTypes.object,
  noBottom: PropTypes.bool,
  withArrow: PropTypes.bool,
  isMobile: PropTypes.bool,
  smallOnMobile: PropTypes.bool,
};

Modal.defaultProps = {
  buttons: {},
  contentStyle: {},
  noBottom: false,
  withArrow: false,
};

export default connect(mapStateToProps)(Modal);
