/*
 Helper function to validate the piece of registration.js state.
 The crucial point is that it doesn't return function (dispatch) => dispatch({}).
 Instead, it returns the validation result which we can use to dispatch actions after.
 */
export default function (
  data, language
) {
  if (error == undefined) {
    var error = {
      response: {
        data: {
          errors: {},
        },
      },
    };
  }

  var isThereError = false;
  var validation = language.registration_validation[0];

  if (data[1] !== undefined) {
    var email,
      password,
      firstName,
      lastName,
      userType,
      jobPosition,
      gender,
      iagree,
      confirmPassword;

    email = password = firstName = lastName = userType = jobPosition = gender = iagree = confirmPassword = true;
  }

  for (var item in error.response.data.errors) {
    if (error.response.data.errors.item === true) {
      isThereError = true;
    }
  }
  var redirectUrl = '';
  if (isThereError === true) {
    redirectUrl = '/sign-up';
  }

  var data = {};
  data.errors = error.response.data.errors;
  data.redirectUrl = redirectUrl;

  if (isEmpty(error.response.data.errors)) {
    isThereError = true;
  }

  if (error.response.data.errors.hasOwnProperty('iagree')) {
    data.message = validation.TERMS_CONDTION_ERROR;
  } else {
    data.message = validation.COMMNON_ERROR;
  }

  data.hasError = isThereError;
  return data;
}

function isEmpty(data) {
  for (var item in data) {
    return true;
  }

  return false;
}
