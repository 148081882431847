import 'moment/locale/es';
import 'moment/locale/fr';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import Preloader from '../components/Preloader';
import {
  fetchDetailHubPages
} from '../store/actions';
import languages from '../helpers/languages';


var language = languages['en'];
var common = language.common[0];
var label = language.Hubs[0];

const mapStateToProps = (state) => {
  return {
    lan: state.language.lan,
    hubInfo: state.hubpages.detail
  };
};

const mapDispatchToProps = {
  fetchDetailHubPages,
};

class Hub extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardsLoaded: false,
      fullyLoaded: true,
      isModalOpened: false
    };
  }
  handleLoading(promise) {
    const animationPromise = new Promise((resolve) =>
      setTimeout(resolve, 1000)
    );
    this.setState({ cardsLoaded: false });
    return Promise.all([promise, animationPromise]).then(() => {
      if (!this.unmounted) {
        this.setState({ cardsLoaded: true });
      }
    });
  }

  componentDidMount() {

    this.handleLoading(this.props.fetchDetailHubPages(this.props.match.params.id));
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    label = language.Hubs[0];
    const {
      hubInfo
    } = this.props;
    
    return (
      <CustomizedDocumentTitle title={common.MY_DASHBOARD}>
        <div className='transition-page'>
          {(hubInfo === undefined || hubInfo === null) ? (
            <Preloader size='medium' relative />
          ) : (
            <div>
              {/*header content */}
              <header>
                <div className="innerheader innerheader-hubs_landing">
                  <h1>{hubInfo.title}</h1>
                </div>
              </header>
              {/*header content */}
              {/*welcome text */}
              {/*<div className="bggrey">
                <div className="wrap">
                  <div className="container-2col">
                    <div>{label.UNDER_THE_SHETRADES_PLATFORM_SEL} </div>
                    <div className="hubscounting"><b>12</b>{label.NATIONAL_HUBS}<br />{label.AND_EXPANDING}</div>
                  </div>
                </div>
              </div>
              {/*welcome text */}
              {/* map area  */}
              {/*<div className="bgdark">
                <div className="innermapcontainer">
                  <div className="worldmap">
                    <div className="h1"><span className="ico-housered" /></div>
                    <div className="h2"><span className="ico-housered" /></div>
                    <div className="h3"><span className="ico-housered" /></div>
                    <div className="h4"><span className="ico-housered" /></div>
                    <div className="h5"><span className="ico-housered" /></div>
                    <div className="h6"><span className="ico-housered" /></div>
                    <div className="h7"><span className="ico-housered" /></div>
                    {/*box */}
                    {/*<div className="hovermapbox">
                      <div className="hovermapbox-hubcontainer">
                        <div><img src="images/temphubpic.jpg" alt="" className="roundpic" /> </div>
                        <div><h3>{hubInfo.title}</h3>
                          <div className="memberpics">
                            <img src="images/1.jpg" alt="" />
                            <img src="images/2.jpg" alt="" />
                            <img src="images/3.jpg" alt="" />
                          </div>
                          <p>{label.MEET_THE_SHETRADES_HUB_HOSTS}.</p>
                          <div className="links-lr">
                            <NavLink to="/hubs" className="btn">{label.READ_MORE}</NavLink>
                            <NavLink to="/forums" className="btn">{label.SEE_DISCUSSION_GROUPS}</NavLink></div>
                        </div>
                      </div>
                    </div>
                    {/*box */}
                  {/*</div>
                </div>
              </div>
              {/* map area  */}
              {/* ST in the world  */}
              <div className="bgwhite">
                <div className="wrap">
                  <div className="container-1col">
                    <div dangerouslySetInnerHTML={{ __html: hubInfo.description }} />
                  </div>
                </div>
              </div>
              {/* ST in the world  */}
              {/*free block */}
              <div className="bgdark">
                <div className="wrap">
                  <div className="bgcircles">
                    <div className="container-2col">
                      <div>
                        <h2>{label.HOW_DO_I_JOIN_MY_LOCAL_HUB}</h2>
                        <p>{label.REGISTERED_WOMEN_AND_INSTITUTIONS} 
                        <a href="mailto:womenandtrade@intracen.org"> {label.CONTACT_US}.</a></p></div>
                      <NavLink to="/sign-up" className="btn">{label.REGISTER_OR_LOGIN}</NavLink>
                    </div>
                  </div>
                </div>
              </div>
              {/*free block */}
              {/* why i am  */}
              {/*<div className="bggrey">
                <div className="wrap">
                  <h2>{label.WHY_JOIN_A_LOCAL_SHETRADES_HUB}</h2>
                  <div className="container-2even">
                    <div><h3>{label.AS_A_BUSINESS}</h3>
                      <p className="whyjoinblock"><span className="circlenumber">1</span>{label.ACCESS_THE_ENTIRE_SHETRADES_OFFER}<a href="#">
                        {label.SHETRADES_OFFERING}</a></p>
                      <p className="whyjoinblock"><span className="circlenumber">2</span>{label.CONNECT_TO_POTENTIAL_BUYERS_INVE}</p>
                      <p className="whyjoinblock"><span className="circlenumber">3</span>{label.RECEIVE_ONSITE_LOCAL_SUPPORT_TO}</p>
                      <p className="whyjoinblock"><span className="circlenumber">4</span>{label.GAIN_ACCESS_TO_ONSITE_WORKSHOPS}</p>
                      <p className="whyjoinblock"><span className="circlenumber">5</span>{label.JOIN_DISCUSSION_GROUPS_NETWORK_AN}</p>
                      <p className="whyjoinblock"><span className="circlenumber">6</span>{label.GET_NOTIFIED_OF_NATIONALLOCAL_OPP}</p>
                      <p className="whyjoinblock"><span className="circlenumber">7</span>{label.B2B_MEETINGS}</p>
                    </div>
                    <div><h3>{label.AS_A_CORPORATION_BUYER_OR_INVEST}</h3>
                      <p className="whyjoinblock"><span className="circlenumber">1</span>{label.CONNECT_WITH_LOCAL_OVER_WOMEN_OWN}</p>
                      <p className="whyjoinblock"><span className="circlenumber">2</span>{label.IDENTIFY_UNIQUE_AND_INNOVATIVE_LO}</p>
                      <p className="whyjoinblock"><span className="circlenumber">3</span>{label.LEARN_SHARE_AND_EXCHANGE_WITH_LO}</p>
                      <p className="whyjoinblock"><span className="circlenumber">4</span>{label.SUPPORT_THE_UN_SUSTAINABLE_DEVELO}</p>
                      <p className="whyjoinblock"><span className="circlenumber">5</span>{label.DEVELOP_NEW_LOCAL_PARTNERSHIPS_AN}</p>
                    </div>
                  </div>
                  <div className="quickphotogallery"><img src="images/temppic01.jpg" alt="" />
                    <img src="images/temppic02.jpg" alt="" />
                    <img src="images/temppic03.jpg" alt="" />
                    <img src="images/temppic04.jpg" alt="" /></div>
                  <h3 className="helpquestion">{label.HAVE_A_QUESTION_SEND_AN_EMAIL_TO} <a href="#">womenandtrade@intracen.org</a></h3>
                </div>
              </div>
              {/* why i am  */}
              {/* partners and founders  */}
              {/*<div className="bgwhite">
                <div className="wrap">
                  <div className="container">
                    <h2>{label.MEET_THE_SHETRADES_HUB_HOSTS}</h2>
                    <div className="partnerscontainer">
                      <div className="partnerlogos">
                        <img src="images/partners_and_founders/Grofinlogo.png" alt="" />
                        <img src="images/partners_and_founders/WEPlogohigh-res.png" alt="" />
                        <img src="images/partners_and_founders/UPS_Shield.png" alt="" />
                        <img src="images/partners_and_founders/NEPClogo.png" alt="" />
                        <img src="images/partners_and_founders/Grofinlogo.png" alt="" />
                        <img src="images/partners_and_founders/WEPlogohigh-res.png" alt="" />
                        <img src="images/partners_and_founders/UPS_Shield.png" alt="" />
                        <img src="images/partners_and_founders/NEPClogo.png" alt="" />
                      </div></div>
                  </div>
                </div>*/} <div className="dotbg l2" />
                {/* </div>
              partners and founders  */}
            </div>
          )}
        </div>
      </CustomizedDocumentTitle>
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Hub);

