import {
  FORUM_TAGS,
  FORUM_TOPIC,
  FORUM_DETAIL,
  FORUM_ANSWERS,
  FORM_TAG_OPTIONS,
  FORUM_ALL_ANSWERS,
  HANDLE_FORUM_ERROR,
  CREATE_FORUM_SUCCESS,
  ADD_FORUM_BOOKMARK,
} from '../store/actions';

const initialState = {
  forumTags: [],
  forumTopics: [],
  forumDetails: [],
  forumAnswers: [],
  formTagOptions: [],
  errorMessages: [],
  hasError: false,
  forumBookmark: false,
};

export default function forum(state = initialState, action) {
  switch (action.type) {
    case FORUM_TAGS:
      let forumTags;
      try {
        forumTags = Object.values(action.payload.data.values);
      } catch (e) {
        forumTags = [];
      }
      return {
        ...state,
        forumTags,
      };
    case FORUM_TOPIC:
      return {
        ...state,
        forumTopics: action.payload.data,
        totalListItems: action.payload.count,
        sortLabel: action.payload.sortLabel,
      };
    case FORUM_DETAIL:
      return {
        ...state,
        forumDetails: action.payload.data,
        pageUrl: action.payload.url,
        pageTitle: action.payload.data['title'],
      };
    case ADD_FORUM_BOOKMARK:
      return {
        ...state,
        forumBookmark: !state.forumBookmark,
      };
    case HANDLE_FORUM_ERROR:
      return {
        ...state,
        formHasError: action.payload.hasError,
        errorMessages: action.payload.errors,
        mainErrorMessage: action.payload.message,
      };
    case CREATE_FORUM_SUCCESS:
      return {
        ...state,
        saveSucess: true,
      };
    case FORUM_ANSWERS:
      var tempAnswer = action.payload.data;
      if (state.forumAnswers) {
        tempAnswer = state.forumAnswers.concat(tempAnswer.answers);
      }
      return {
        ...state,
        forumAnswers: tempAnswer,
        totalAnswers: action.payload.count,
        showedAnswers: action.payload.page,
      };
    case FORM_TAG_OPTIONS:
      return {
        ...state,
        formTagOptions: action.payload.data,
      };
    case FORUM_ALL_ANSWERS:
      var forumAnswersList = action.payload.data;
      return {
        ...state,
        forumAnswers: action.payload.data.answers,
        totalAnswers: action.payload.count,
      };
    default:
      return state;
  }
}
