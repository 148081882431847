import classnames from 'classnames';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Footer from '../components/Footer';
import { InstallButton } from '../components/Header/components/installButton';
import ProtectedTopBar from '../components/Header/components/ProtectedTopBar';
import TopBar from '../components/Header/components/TopBar';
import PageUnderMaintenance from '../components/PageUnderMaintenance ';
import ProtectedApp from '../components/ProtectedApp';
import ProtectedRoute from '../components/ProtectedRoute';
import { pageTransition } from '../constants';
import Cookies from '../pages/Cookies';
import Faq from '../pages/Faq';
import AboutUs from './AboutUs';
import AllPartners from './AllPartners';
import Blog from './Blog';
import Commitments from './Commitments/Commitments';
import Community from './Community';
import Contactus from './Contactus';
import Discover from './Discover';
import HelpCenter from './HelpCenter';
import HomePage from './HomePage';
import Hub from './Hub';
import Hubs from './Hubs';
import Learn from './Learn';
import News from './News';
import Offers from './Offers';
import Partner from './Partner';
import Partners from './Partners';
import Project from './Project';
import Projects from './Projects';
import Publications from './Publications';
import PublicEvents from './Events/Events';
import Search from './Search';
import SuccessStory from './SuccessStory';
import Tools from './Tools';
import Video from './Video';
import Webinars from './Webinars';
import BuySell from './BuyPage/Page';
import Members  from './Members';
import SearchProfiles  from './SearchProfiles';

const mapStateToProps = (state) => ({
  isMobile: state.responsive.isMobile,
  isLandscape: state.responsive.isLandscape,
  auth: state.auth
});

class PublicApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpened: false,
    };
  }

  toggleMenu(isMenuOpened) {
    const { isMobile } = this.props;
    if (isMobile) {
      this.setState({
        isMenuOpened,
      });
    }
  }

  render() {
    const { props } = this;
    const { isMobile, isLandscape, auth } = props;

    return (
      <div id='app-root-element' className={classnames({ mobile: isMobile })}>
        {/*top bar */}
        <div id="top_bar">
          {auth.isLoggedIn && <ProtectedTopBar />}
          {!auth.isLoggedIn && <TopBar></TopBar>}
        </div>
        {/*top bar */}
        <Switch>
          <Route
            render={() => (
              <Fragment>
                <div>

                  <TransitionGroup className='transition-container'>
                    <CSSTransition
                      key={props.location.pathname}
                      {...pageTransition}
                      onEnter={() =>
                        setTimeout(() => {
                          window.scrollTo(0, 0);
                        }, 400)
                      }
                    >
                      <Switch location={props.location}>

                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/'
                          component={HomePage}
                        />
                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/home'
                          component={HomePage}
                        />
                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publicaboutus'
                          component={AboutUs}
                        />
                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publiccommittments'
                          component={Commitments}
                        />
                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publicdiscover'
                          component={Discover}
                        />
                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publichubs'
                          component={Hubs}
                        />
                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publichub/:id'
                          component={Hub}
                        />
                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publiclearn'
                          component={Learn}
                        />
                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publicoffers'
                          component={PageUnderMaintenance}
                        />
                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/offers/:id'
                          component={Offers}
                        />

                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publicpartners'
                          component={Partners}
                        />
                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publicprojects'
                          component={Projects}
                        />
                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publictools'
                          component={Tools}
                        />

                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publichelpcenter'
                          component={HelpCenter}
                        />
                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publicsearch'
                          component={Search}
                        />

                        <Route
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publicmynetwork'
                          component={Members}
                        />
                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publicforums'
                          component={PageUnderMaintenance}
                        />
                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publicevents'
                          component={PublicEvents}
                        />
                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publiccommunity'
                          component={Community}
                        />


                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publicallpartners'
                          component={AllPartners}
                        />
                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publicpartner/:id'
                          component={Partner}
                        />

                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publichub'
                          component={Hub}
                        />

                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publicproject/:id'
                          component={Project}
                        />
                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publicpartner'
                          component={Partner}
                        />

                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publicwebinars'
                          component={Webinars}
                        />

                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publicblog'
                          component={Blog}
                        />

                        <Route
                          exact
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/buysell'
                          component={BuySell}
                        />

                        <Route
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/policy'
                          component={() => {
                            window.location.href = 'https://www.shetrades.com/policy';
                            return null;
                          }}
                        />
                        <Route
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/terms'
                          component={() => {
                            window.location.href = 'https://www.shetrades.com/terms';
                            return null;
                          }}
                        />
                        <Route
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publicoutlook'
                          component={() => {
                            window.location.href = 'https://www.shetrades.com/outlook/home';
                            return null;
                          }}
                        />

                        <Route
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/youtube'
                          component={() => {
                            window.location.href = 'https://www.youtube.com/c/Exportimpactforgood';
                            return null;
                          }}
                        />
                        <Route
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/facebook'
                          component={() => {
                            window.location.href = 'https://www.facebook.com/ITCSheTrades';
                            return null;
                          }}
                        />
                        <Route
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/twitter'
                          component={() => {
                            window.location.href = 'https://twitter.com/ITCnews';
                            return null;
                          }}
                        />
                        <Route
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/linkedin'
                          component={() => {
                            window.location.href = 'https://www.linkedin.com/company/international-trade-centre/';
                            return null;
                          }}
                        />
                        <Route
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/itcwebsite'
                          component={() => {
                            window.location.href = 'https://www.intracen.org';
                            return null;
                          }}
                        />

                        <Route basename={process.env.REACT_APP_BASE_ROUTE} path='/publicnews' component={News} />

                        <Route basename={process.env.REACT_APP_BASE_ROUTE} path='/publicvideos' component={Video} />

                        <Route basename={process.env.REACT_APP_BASE_ROUTE} path='/publicopportunities'
                          component={PageUnderMaintenance}
                        />

                        <Route
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publicpublications'
                          component={Publications}
                        />
                        <Route
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publicsuccessstories'
                          component={SuccessStory}
                        />

                        <Route
                          basename={process.env.REACT_APP_BASE_ROUTE} path='/publiccontactus'
                          component={Contactus}
                        />
                         <Route
                        exact
                        basename={process.env.REACT_APP_BASE_ROUTE} path='/profilesearch'
                        component={SearchProfiles}
                      />
                        <Route basename={process.env.REACT_APP_BASE_ROUTE} path='/publicwebinars' component={Webinars} />
                        <Route exact basename={process.env.REACT_APP_BASE_ROUTE} path='/publiccookies' component={Cookies} />
                        <Route exact basename={process.env.REACT_APP_BASE_ROUTE} path='/publicf-a-q' component={Faq} />

                        <ProtectedRoute component={ProtectedApp} />
                      </Switch>
                    </CSSTransition>
                  </TransitionGroup>
                </div>
              </Fragment>
            )}
          />
        </Switch>

        {/* {!isMobile && <CookiesPopup/>} */}

        {isLandscape && isMobile && (
          <div className='no-landscape'>
            <img
              src={require('../static/images/iphone-landscape-portrait.svg')}
              alt=''
            />
            <p className='content'>{props.t.common[0].ROTATE_DEVICE}</p>
          </div>
        )}
        <Footer>
          {<InstallButton></InstallButton>}
        </Footer>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(PublicApp));
