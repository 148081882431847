import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import TypeYoutubeVideo from './components/TypeYoutubeVideo';
import TypeLocation from './components/TypeLocation';
import TypeDocuments from './components/TypeDocuments';
import TypePhotos from './components/TypePhotos';
import emojifyText from '../../../../../../../../pages/Messenger/components/MessengerBody/components/Chat/components/NewMessage/emojifyText';
import noAvatarImage from '../../../../../../../../static/images/noavatar.svg';

const Message = (props) => {
  const {
    text,
    time,
    my,
    received,
    name,
    avatar,
    newDay,
    isFirstReply,
    documents,
    youtubeVideoId,
    photos,
    location,
    failed,
  } = props;

  return (
    <Fragment>
      {newDay && (
        <div className='chat-new-day'>
          <span className='gray'>{moment(newDay).format('D MMM YYYY')}</span>
        </div>
      )}

      <div
        className={classNames('chat-message', {
          'chat-message_my': my,
          'chat-message_first-reply': isFirstReply,
        })}
        title={failed ? 'Message not sent' : null}
      >
        {(newDay || isFirstReply) && avatar && (
          <div className='chat-message__image'>
            <img src={avatar} alt='' />
          </div>
        )}

        <div
          className='chat-message__body'
          style={failed ? { opacity: 0.5 } : null}
        >
          {location && <TypeLocation location={location} />}

          {(newDay || isFirstReply) && name && (
            <div className='chat-message__name'>{name}</div>
          )}

          {text && (
            <p dangerouslySetInnerHTML={{ __html: emojifyText(text) }} />
          )}

          {!!documents.length && <TypeDocuments documents={documents} />}

          {youtubeVideoId && <TypeYoutubeVideo videoId={youtubeVideoId} />}

          {!!photos.length && <TypePhotos photos={photos} />}
        </div>

        <div className='chat-message__time'>
          <p className='gray'>
            {received && <i className='icon-done' />}
            {failed && '! '}

            {moment(time).format('h:mmA')}
          </p>
        </div>
      </div>
    </Fragment>
  );
};

Message.propTypes = {
  text: PropTypes.string,
  time: PropTypes.number,
  my: PropTypes.bool,
  received: PropTypes.bool,
  name: PropTypes.string,
  avatar: PropTypes.string,
  newDay: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isFirstReply: PropTypes.bool,
  documents: PropTypes.array,
  youtubeVideoId: PropTypes.string,
  photos: PropTypes.array,
  location: PropTypes.object,
  failed: PropTypes.bool,
};

Message.defaultProps = {
  text: '',
  name: '',
  location: null,
  my: false,
  isFirstReply: false,
  avatar: noAvatarImage,
  received: false,
  newDay: '',
  documents: [],
  youtubeVideoId: '',
  photos: [],
};

export default Message;
