import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Page from '../components/Page';
import Detail from '../components/Detail';
import Forum from '../components/Forum';
import DefaultCards from '../components/Page/components/Cards';
import EventCards from '../pages/Events/components/Cards';
import EventDetails from '../pages/Events/components/Details';
import ButtonBookmark from '../components/ButtonBookmark';
import ButtonAbuseReport from '../components/ButtonAbuseReport';
import ForumsBeforeToolbar from '../pages/Forums/components/BeforeToolbar';
import queryString from 'query-string';
import { fetchMyFavorites } from '../store/actions';

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    userRole: state.auth.user.userRole,
    search: state.search,
    myitems: state.myfavorites,
    t: state.language.t,
  };
};

const mapDispatchToProps = {
  fetchMyFavorites,
};

class PageMyFavorites extends React.Component {
  componentDidMount() {
    this.props.fetchMyFavorites(window.location.hash, this.props.search);
  }

  render() {
    var roleFlag = false;
    if (
      this.props.userRole === 'member' ||
      typeof this.props.userRole === 'undefined'
    ) {
      roleFlag = false;
    }
    const { location, t } = this.props;
    const queryObject = queryString.parse(location.search);
    const toolbar = {
      tabs: {
        values: {
          tenders: {
            text: t.common[0].TENDERS,
            singleName: t.common[0].TENDER,
            renderBeforeToolbar: null,
            additionalButtons: (id, bookmarked) => (
              <ButtonBookmark
                id={id}
                bookmarked={bookmarked}
                type={'tenders'}
              />
            ),
            renderCards: DefaultCards,
            topFilters: true,
            sort: 'sortDefault',
            sortLabel: this.props.myitems.sortLabel,
            cards: this.props.myitems.listData,
            detail: Detail,
          },
          offers: {
            text: t.common[0].OFFERS,
            singleName: t.common[0].OFFER,
            renderBeforeToolbar: null,
            additionalButtons: (id, bookmarked) => (
              <ButtonBookmark id={id} bookmarked={bookmarked} type={'offers'} />
            ),
            renderCards: DefaultCards,
            topFilters: true,
            sort: 'sortDefault',
            sortLabel: this.props.myitems.sortLabel,
            cards: this.props.myitems.listData,
            detail: Detail,
          },
          forums: {
            text: t.menu[0].FORUMS,
            singleName: t.common[0].FORUM,
            renderBeforeToolbar() {
              return (
                <ForumsBeforeToolbar type='favorites' location={location} />
              );
            },
            additionalButtons: (id, bookmarked) => (
              <Fragment>
                <ButtonBookmark
                  id={id}
                  bookmarked={bookmarked}
                  type={'forums'}
                />
                <ButtonAbuseReport id={id} hideOnMobile />
              </Fragment>
            ),
            renderCards: DefaultCards,
            topFilters: false,
            sort: 'sortTopics',
            sortLabel: this.props.myitems.sortLabel,
            cards: this.props.myitems.listData,
            detail: Forum,
          },
          events: {
            text: t.common[0].EVENTS,
            singleName: t.common[0].EVENT,
            renderBeforeToolbar: null,
            additionalButtons() {
              return false;
            },
            renderCards: EventCards,
            cards: this.props.myitems.listData,
            detail: EventDetails,
          },
        },
      },
      sortDefault: [
        { orderBy: 'date', order: 'ASC', label: t.common[0].OLDEST },
        { orderBy: 'date', order: 'DESC', label: t.common[0].NEWEST },
      ],
      sortTopics: [
        { orderBy: 'date', order: 'ASC', label: t.common[0].OLDEST },
        { orderBy: 'date', order: 'DESC', label: t.common[0].NEWEST },
      ],
    };

    const defaultTab = 'tenders';
    const { tab } = queryObject;
    const tabIsDefault =
      !('tab' in queryObject) || queryObject.tab === defaultTab;
    const { values } = toolbar.tabs;

    const group = location.pathname.split('/').pop();
    const {
      cards,
      sort,
      sortLabel,
      renderBeforeToolbar,
      topFilters,
      additionalButtons,
      renderCards,
    } = tabIsDefault ? values[defaultTab] : values[tab];
    const { singleName, detail } = values[group] || { singleName: undefined };
    return (
      <Page
        name={t.header[0].MY_FAVORITES}
        fetchFunction={this.props.fetchMyFavorites}
        totalItems={this.props.myitems.totalItems}
        sortLabel={sortLabel}
        search={this.props.search}
        cardsPerPage={9}
        canCreateNew={roleFlag}
        singleName={singleName}
        queryBack={location.hash}
        topfilters={topFilters}
        renderBeforeToolbar={renderBeforeToolbar}
        toolbar={{
          ...toolbar,
          sort: toolbar[sort],
          withToggleView: tab === 'events',
        }}
        cards={cards.map((el) => ({
          ...el,
          queryParent: tab || defaultTab,
          queryMode: true,
          showId: true,
          renderAdditionalButtons: additionalButtons,
        }))}
        {...this.props}
        // eslint-disable-next-line no-shadow
        renderCards={(cards, filter, page, cardsPerPage) =>
          renderCards(cards, filter, page, cardsPerPage, location)
        }
        renderDetail={(match) => {
          switch (group) {
            case 'forums':
              return <Forum {...detail} />;
            case 'events':
              return EventDetails(match);
            default:
              return <Detail singleName={singleName} {...detail} />;
          }
        }}
      />
    );
  }
}

PageMyFavorites.propTypes = {
  location: PropTypes.object.isRequired,
  t: PropTypes.object,
};

const List = connect(mapStateToProps, mapDispatchToProps)(PageMyFavorites);
export default List;
