/* eslint no-restricted-globals:0 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import lifecycle from 'react-pure-lifecycle';
import { connect } from 'react-redux';
import queryString from 'query-string';

import Page from '../components/Page';
import CardForNews from '../components/CardForNews';
import CardForFullNews from '../components/CardForFullNews';
import NoDataCard from '../components/Card/NoDataCard';
import PreventEmptyDetail from '../components/PreventEmptyDetail';
import languages from '../helpers/languages';
import { fetchNews, fetchDetailNews } from '../store/actions';
import {
  COUNTRY,
  NAME
} from '../components/Search/TopFilters/types';

var language = languages['en'];
var label = language.news[0];
var common = language.common[0];
var menu = language.menu[0];
var news = language.news[0];

const mapStateToProps = (state) => ({
  cards: state.news.news,
  totalItems: state.news.totalItems,
  detail: state.news.detail,
  relatednews: state.news.relatednews,
  sortLabel: state.news.sortLabel,
  isMobile: state.responsive.isMobile,
  lan: state.language.lan,
});

const mapDispatchToProps = {
  fetchNews,
  fetchDetailNews,
};

const methods = {
  componentDidMount(props) {
    props.fetchNews(window.location.hash);
    var queryParam = window.location.hash.split('/');
    if (queryParam[1] && queryParam[2])
      props.fetchDetailNews(window.location.hash);
  },
};

const PageNews = (props) => {
  language = languages[props.lan];
  label = language.news[0];
  common = language.common[0];
  menu = language.menu[0];
  news = language.news[0];

  const { location } = props;
  const toolbar = {
    tabs: {
      values: {
        all: { text: label.ALL_NEWS },
        'press-release': {
          text: label.PRESS_RELEASE,
          filter: (card) =>
            card.tags.map((tag) => tag.text).includes('Press Release'),
        },
        speech: {
          text: label.SPEECH,
          filter: (card) => card.tags.map((tag) => tag.text).includes('Speech'),
        },
      },
    },
    sort: [
      { orderBy: 'date', order: 'DESC', label: common.RECENT },
      { orderBy: 'date', order: 'ASC', label: common.OLDEST },
    ],
  };
  const { cards, totalItems, detail, isMobile } = props;
  return (
    <Page
      name={menu.NEWS}
      singleName={label.POST}
      // topfilters={[NAME, COUNTRY]}
      toolbar={toolbar}
      cards={cards}
      fetchFunction={props.fetchNews}
      detail={detail}
      hasLeftPanel={false}
      totalItems={totalItems}
      sortLabel={props.sortLabel}
      canCreateNew={false}
      renderAfterTitle={() => (
        <div className='wrap'>
          <div className='container'>
            <p className='gray'>{label.INFO}</p>
          </div>
        </div>
      )}
      {...props}
      renderCards={(cards, filter, page, cardsPerPage) => {
        const queryObject = queryString.parse(location.search);
        const isCurrentTabNotDefault =
          'tab' in queryObject && queryObject.tab !== 'all';
        if (page <= 0 && !isMobile) {
          var filteredCards = cards.filter(
            (el, i) => isCurrentTabNotDefault || i > 0
          );
        } else {
          var filteredCards = cards;
        }

        return (
          <Fragment>
            {!isCurrentTabNotDefault && page <= 0 && cards[0] && !isMobile && (
              <div className='featured-cards'>
                <CardForNews forBigNews {...cards[0]} listType='news' />
              </div>
            )}

            <div className='odd-items'>
              {filteredCards.map((el, i) => (
                <CardForNews key={i} topInOneLine {...el} listType='news' />
              ))}
              {cards.length === 0 && <NoDataCard />}
            </div>
          </Fragment>
        );
      }}
      cardsPerPage={9}
      renderDetail={(match) => (
        <PreventEmptyDetail detail={detail}>
          <Fragment>
            <CardForFullNews {...detail} />

            {props.relatednews != undefined && props.relatednews.length > 0 && (
              <section className='upcoming'>
                <h2>{label.RELATED_NEWS}</h2>
                <div className='odd-items'>
                  {props.relatednews != undefined &&
                    props.relatednews.map((el, i) => (
                      <CardForNews
                        key={i}
                        topInOneLine
                        {...el}
                        listType='news'
                      />
                    ))}
                </div>
              </section>
            )}
          </Fragment>
        </PreventEmptyDetail>
      )}
    />
  );
};

PageNews.propTypes = {
  location: PropTypes.object.isRequired,
};

PageNews.defaultProps = {
  cards: [],
};

const pageNewsCycle = lifecycle(methods)(PageNews);
const List = connect(mapStateToProps, mapDispatchToProps)(pageNewsCycle);

export default List;
//export default PageNews;
