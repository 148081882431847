export const ADD_FLASH_MESSAGE = 'ADD_FLASH_MESSAGE';
export const REMOVE_FLASH_MESSAGE = 'REMOVE_FLASH_MESSAGE';

export const MESSAGE_STYLE_SUCCESS = 'MESSAGE_STYLE_SUCCESS';
export const MESSAGE_STYLE_ERROR = 'MESSAGE_STYLE_ERROR';

export const addFlashMessage = (message, type = '') => ({
  type: ADD_FLASH_MESSAGE,
  payload: {
    message,
    type,
  },
});

export const removeFlashMessage = (messageId) => ({
  type: REMOVE_FLASH_MESSAGE,
  payload: {
    messageId,
  },
});
