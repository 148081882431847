import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import NoDataCard from '../../../../../../components/Card/NoDataCard';
import Preloader from '../../../../../../components/Preloader';
import { loadMoreNotifications } from '../../../../../../store/actions';
import NotificationsFeedItem from '../NotificationsFeedItem';

const mapStateToProps = (state) => {
  return {
    items: state.notifications.notificationItems,
    offset: state.notifications.offset,
    totalCount: state.notifications.totalCount,
    t: state.language.t,
  };
};

const mapDispatchToProps = {
  loadMoreNotifications,
};

class NotificationsFeed extends React.Component {
  static propTypes = {
    items: PropTypes.array,
    limit: PropTypes.number,
    loadMoreNotifications: PropTypes.func,
    location: PropTypes.object,
    offset: PropTypes.number,
    totalCount: PropTypes.number,
    t: PropTypes.object,
  };

  static defaultProps = {
    limit: 2,
    offset: 0,
  };

  componentDidMount() {
    const { items, limit, loadMoreNotifications, offset } = this.props;
    if (items === null) {
      loadMoreNotifications({ limit, offset });
    }
  }

  render() {
    const {
      items,
      loadMoreNotifications,
      limit,
      location,
      offset,
      totalCount,
      t,
    } = this.props;
    const queryObject = queryString.parse(location.search);
    const hasMore = offset < totalCount;

    if (items === null) {
      return <Preloader relative size='medium' />;
    }

    if (items.length === 0) {
      return (
        <div className='cards-list'>
          <NoDataCard />
        </div>
      );
    }

    return (
      <div className='notifications-feed'>
        {items
          .filter(({ time, itemId }) => !!time && !!itemId)
          .map((el) => (
            <NotificationsFeedItem
              key={el.id}
              highlighted={queryObject.id === el.id}
              {...el}
            />
          ))}

        {hasMore && (
          <button
            className='button button_gray button_flat'
            onClick={loadMoreNotifications.bind(null, { limit, offset })}
          >
            <span className='button__text'>{t.common[0].SHOW_MORE}</span>
          </button>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NotificationsFeed)
);
