import axios from '../requests/platformApi';
import {
  FETCH_DASHBOARD,
  DASHBOARD_FETCHED,
  DASHBOARD_REORDER,
  TOGGLE_DASHBOARD_SECTION,
  handleApiErrors,
} from '../store/actions';
import _ from 'lodash';

export function fetchDashboard() {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_DASHBOARD,
    });
    const lan = getState().language.lan;
    return axios
      .get('/dashboard/section')
      .then((response) => {
        dispatch(dashboardFetched(response.data.data, lan));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export const dashboardFetched = (data, lan) => {
  const isValid = typeof data === 'object' && !Array.isArray(data);
  const sectionsOrder = isValid ? data : {};
  return {
    type: DASHBOARD_FETCHED,
    payload: { sectionsOrder, lan },
  };
};

export const reorderDashboard = ({ source, destination }, isPhone) => ({
  type: DASHBOARD_REORDER,
  payload: {
    source,
    destination,
    isPhone,
  },
});

export function saveDashboard({ phone, desktop }) {
  return (dispatch) => {
    const sections = {};
    phone.visible.forEach((el, i) => {
      sections[el.identifier] = { isHiddenMobile: false, indexMobile: i };
    });
    phone.hidden.forEach((el, i) => {
      sections[el.identifier] = { isHiddenMobile: true, indexMobile: i };
    });
    desktop.visibleLeft.forEach((el, i) => {
      sections[el.identifier].index = i;
      sections[el.identifier].isHidden = false;
    });
    desktop.visibleRight.forEach((el, i) => {
      sections[el.identifier].index = i;
      sections[el.identifier].isHidden = false;
    });
    desktop.hiddenLeft.forEach((el, i) => {
      sections[el.identifier].index = i;
      sections[el.identifier].isHidden = true;
    });
    desktop.hiddenRight.forEach((el, i) => {
      sections[el.identifier].index = i;
      sections[el.identifier].isHidden = true;
    });
    return axios.post('/dashboard/section', sections).catch(function (error) {
      dispatch(handleApiErrors(dispatch, error));
    });
  };
}

export const toggleSection = ({
  section,
  sourceDroppableId,
  sourceIndex,
  isPhone,
}) => ({
  type: TOGGLE_DASHBOARD_SECTION,
  payload: { section, sourceDroppableId, sourceIndex, isPhone },
});
