import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dropdown from '../../../../../../../../components/Dropdown';
import { LocationInput } from '../../../../../../../../components/settings/Input/components/LocationInput/LocationInput';
import Modal from '../../../../../../../../components/Modal';
import DraggableFilePreview from '../../../../../../../../components/settings/Input/components/FilesInput/components/DraggableFilePreview';
import TextareaWithEmojis from '../TextareaWithEmojis';
import { unemojify } from './emojifyText';
import formatNumber from '../../../../../../../../helpers/format-number';

const mapStateToProps = (state) => ({
  applozicApi: state.messenger.applozicApi,
  t: state.language.t,
});

class NewMessage extends Component {
  static propTypes = {
    applozicApi: PropTypes.object.isRequired,
    chatId: PropTypes.string.isRequired,
    isGroup: PropTypes.bool,
    t: PropTypes.object,
  };

  dropdownLinks = ({ t }) => [
    {
      icon: 'camera',
      title: t.common[0].PHOTO_VIDEO,
      onClick: this.openFilePicker.bind(this, true),
    },
    {
      icon: 'file',
      title: t.common[0].DOCUMENT,
      onClick: this.openFilePicker.bind(this, false),
    },
    {
      icon: 'tag',
      title: t.common[0].MAP_LOCATION,
      onClick: () => this.toggleLocationPicker(true),
    },
  ];

  constructor(props) {
    super(props);

    this.state = {
      currentFile: null,
      files: [],
      showLocationPicker: false,
      text: '',
      textEmojis: '',
    };

    this.nextFile = this.nextFile.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.sendTextMessage = this.sendTextMessage.bind(this);
    this.skipAllFiles = this.skipAllFiles.bind(this);
  }

  toggleLocationPicker(showLocationPicker) {
    this.setState({
      showLocationPicker,
    });
  }

  onTextChange(textEmojis, callback) {
    this.setState(
      {
        text: unemojify(textEmojis),
        textEmojis,
      },
      callback
    );
  }

  sendTextMessage() {
    const { applozicApi } = this.props;
    const { ALApiService } = applozicApi;
    const { text } = this.state;
    const sanitizedText = text.trim().replace(/(\n|<br\s?\/?>\s?)+/g, '<br />');
    if (sanitizedText === '') return;
    ALApiService.sendMessage({
      data: {
        message: this.configureMessage({
          message: sanitizedText,
        }),
      },
    });
    this.setState({
      text: '',
      textEmojis: '',
    });
  }

  sendLocation(location) {
    const { applozicApi } = this.props;
    const { ALApiService } = applozicApi;
    ALApiService.sendMessage({
      data: {
        message: this.configureMessage({
          metadata: {
            location: JSON.stringify(location),
          },
        }),
      },
    });
  }

  configureMessage(overrides = {}) {
    const { chatId, isGroup } = this.props;
    const messageObj = {
      type: 5,
      message: '',
      to: isGroup ? undefined : chatId,
      groupId: isGroup ? chatId : undefined,
      metadata: {},
      key: 'mpfj2',
      source: 1,
    };
    return Object.assign({}, messageObj, overrides);
  }

  openFilePicker(imagesOnly) {
    this.filePicker.setAttribute(
      'accept',
      imagesOnly
        ? 'image/*, video/*'
        : 'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf'
    );
    this.filePicker.click();
  }

  decideOnFile(send) {
    const { applozicApi } = this.props;
    const { ALApiService } = applozicApi;
    const { files, currentFile, text } = this.state;
    if (send) {
      ALApiService.sendAttachment({
        data: {
          file: currentFile,
          messagePxy: this.configureMessage({
            message: text,
          }),
        },
      });
    }
    this.nextFile(files);
  }

  skipAllFiles() {
    this.setState({
      currentFile: null,
      files: [],
    });
  }

  nextFile(files, rewindLength = false) {
    const [firstFile, ...rest] = files;
    if (rewindLength) {
      this.setState({
        currentFile: firstFile,
        files: rest,
        filesQueueLength: rewindLength
          ? files.length
          : this.state.filesQueueLength,
      });
    } else {
      this.setState({
        currentFile: firstFile,
        files: rest,
        text: '',
        textEmojis: '',
      });
    }
  }

  render() {
    const { chatId, t } = this.props;
    const {
      location,
      showLocationPicker,
      currentFile,
      files,
      filesQueueLength,
      textEmojis,
    } = this.state;

    return (
      <div className='new-message'>
        <div className='new-message__clip-button'>
          <Dropdown
            addClassName='dropdown_white'
            links={this.dropdownLinks({ t })}
          >
            <i className='icon-paper-clip' />
          </Dropdown>
        </div>

        <TextareaWithEmojis
          maxLength={1000}
          maxRows={6}
          onChange={this.onTextChange}
          onSubmit={this.sendTextMessage}
          chatId={chatId}
          value={textEmojis}
        />

        <LocationInput
          isOpen={showLocationPicker}
          value={location}
          onClose={this.toggleLocationPicker.bind(this, false)}
          onChange={this.sendLocation.bind(this)}
        />

        <input
          type='file'
          className='visually-hidden'
          ref={(el) => (this.filePicker = el)}
          onChange={(e) => this.nextFile(e.target.files, true)}
          multiple
        />

        {currentFile && (
          <Modal
            isOpen={true}
            smallOnMobile
            onRequestClose={this.skipAllFiles}
            contentStyle={{ overflow: 'visible' }}
            buttons={{
              left: {
                text: t.common[0].SKIP,
                className: 'gray',
                onClick: this.decideOnFile.bind(this, false),
              },
              right: {
                text: t.common[0].SEND_MESSAGE,
                className: 'brand',
                onClick: this.decideOnFile.bind(this, true),
              },
            }}
          >
            <h3 className='modal-content__title'>
              {t.common[0].SENDING_FILE} {filesQueueLength - files.length}/
              {filesQueueLength}
            </h3>
            <DraggableFilePreview
              name={`${currentFile.name} (${formatNumber({
                number: currentFile.size,
                postfix: 'B',
                digitsAfterComma: 1,
              })})`}
              preview={currentFile.preview}
              withIcon={false}
            />
            <div style={{ marginTop: '15px' }}>
              <TextareaWithEmojis
                maxLength={200}
                maxRows={3}
                onChange={this.onTextChange}
                onSubmit={this.decideOnFile.bind(this, true)}
                pickerBottom={-240}
                chatId={chatId}
                value={textEmojis}
              />
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps)(NewMessage);
