import { COACHES, COACH_STATUS, COACH_MESSAGE_HIDE } from '../store/actions';

const initialState = { messageShow: false };

export default function Coaches(state = initialState, action) {
  switch (action.type) {
    case COACHES:
      return {
        ...state,
        COACHES: action.payload.data,
        totalItems: action.payload.count,
        sortLabel: action.payload.sortLabel,
      };
    case COACH_STATUS:
      return {
        ...state,
        messageShow: action.payload.data.messageShow,
        coachesStatus: action.payload.data.coachesStatus,
      };

    case COACH_MESSAGE_HIDE:
      return {
        ...state,
        messageShow: false,
      };

    default:
      return state;
  }
}
