/*
 Adds stagger animation of list items opening and closing to element with dropdownRef inside the wrapped component
 by clicking on toggleRef in the wrapped component or outside of it

 Reference to exposing refs to parent components https://reactjs.org/docs/refs-and-the-dom.html#exposing-dom-refs-to-parent-components
 */

import React from 'react';
import PropTypes from 'prop-types';
import gsap from "gsap";
import { TweenMax, Power2, Power4 } from 'gsap';
import _ from 'lodash';
import { ScrollToPlugin } from 'gsap/all'

//gsap.registerPlugin(ScrollToPlugin)

const withDropdownStagger = (WrappedComponent) =>
  class extends React.Component {
    static propTypes = {
      closeOnClick: PropTypes.bool,
      defaultOpened: PropTypes.bool,
      ignoreOutsideClick: PropTypes.bool,
    };

    static defaultProps = {
      closeOnClick: false,
      defaultOpened: false,
      ignoreOutsideClick: false,
    };

    constructor(props) {
      super(props);
      this.toggleElement = null;
      this.dropdownElement = null;
      this.state = {
        opened: false,
      };
      this.handleResize = _.debounce(this.handleResize.bind(this), 200);
    }

    componentDidMount() {
      document.addEventListener('click', this.handleOutsideClick, true);
      document.addEventListener('keyup', this.handleEscButtonPressed);
      window.addEventListener('resize', this.handleResize);

      if (this.dropdownElement) {
        this.dropdownElement.style.marginBottom = `-${this.dropdownElement.clientHeight}px`;
      }

      this.toggle(true, this.props.defaultOpened);
    }

    componentWillUnmount() {
      document.removeEventListener('click', this.handleOutsideClick, true);
      document.removeEventListener('keyup', this.handleEscButtonPressed, true);
    }

    toggle(immediate, forceOpened) {
      const { opened } = this.state;
      const newOpened =
        typeof forceOpened === 'boolean' ? forceOpened : !opened;

      if (!this.dropdownElement) {
        return;
      }

      this.dropdownElement.style.overflow = 'hidden';
      this.dropdownElement.style.pointerEvents = 'none';

      if (newOpened) {
        this.dropdownElement.scrollTop = 0;
      }

      if (immediate) {
        TweenMax.set(this.dropdownElement, {
          autoAlpha: +!newOpened,
          autoRound: false,
          ease: Power2.easeOut,
          marginBottom: +!newOpened * -this.dropdownElement.clientHeight,
        });
      } else {
        TweenMax.fromTo(
          this.dropdownElement,
          0.25,
          {
            marginBottom: +newOpened * -this.dropdownElement.clientHeight,
          },
          {
            autoAlpha: +!newOpened,
            autoRound: false,
            ease: Power2.easeOut,
            marginBottom: +!newOpened * -this.dropdownElement.clientHeight,
          }
        );
      }

      if (immediate) {
        TweenMax.set(this.dropdownElement, {
          autoAlpha: +newOpened,
          ease: Power2.easeOut,
          rotationX: +!newOpened * -91,
        });
      } else {
        TweenMax.fromTo(
          this.dropdownElement,
          opened ? 0.2 : 0.4,
          {
            autoAlpha: +!newOpened,
            rotationX: +newOpened * -91,
          },
          {
            autoAlpha: +newOpened,
            clearProps: !newOpened ? '' : 'transform',
            ease: Power2.easeOut,
            rotationX: +!newOpened * -91,
          }
        );
      }

      if (immediate) {
        TweenMax.set(
          Array.prototype.slice.call(this.dropdownElement.children, 0, 8),
          {
            autoAlpha: +newOpened,
            ease: Power4.easeOut,
            onComplete: () => {
              this.dropdownElement.style.overflow = '';
              this.dropdownElement.style.pointerEvents = '';
            },
            y: +opened * 25,
          }
        );
      } else {
        TweenMax.staggerFromTo(
          Array.prototype.slice.call(this.dropdownElement.children, 0, 8),
          !newOpened ? 0.2 : 0.4,
          {
            autoAlpha: +!newOpened,
            y: +newOpened * 25,
          },
          {
            autoAlpha: +newOpened,
            clearProps: !newOpened ? '' : 'transform',
            ease: Power4.easeOut,
            onComplete: () => {
              this.dropdownElement.style.overflow = '';
              this.dropdownElement.style.pointerEvents = '';
            },
            y: +!newOpened * 25,
          },
          0.05
        );
      }

      this.setState({
        opened: newOpened,
      });
    }

    handleOutsideClick = (e) => {
      const { closeOnClick } = this.props;
      if (
        (this.toggleElement &&
          this.toggleElement.contains(e.target) &&
          (this.props.ignoreOutsideClick || !this.state.opened)) ||
        (!this.props.ignoreOutsideClick &&
          this.dropdownElement &&
          (!this.dropdownElement.contains(e.target) || closeOnClick) &&
          this.state.opened)
      ) {
        this.toggle();
      }
    };

    handleEscButtonPressed = (e) => {
      if (
        e.keyCode === 27 &&
        this.state.opened &&
        !this.props.ignoreOutsideClick
      ) {
        this.toggle();
      }
    };

    handleResize() {
      this.toggle(true);
      this.toggle(true);
    }

    render() {
      return (
        <WrappedComponent
          toggleRef={(el) => {
            this.toggleElement = el;
          }}
          dropdownRef={(el) => {
            this.dropdownElement = el;
          }}
          {...this.props}
          {...this.state}
        />
      );
    }
  };

export default withDropdownStagger;
