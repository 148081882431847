import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import CustomizedTooltip from '../../../components/CustomizedTooltip';
import withDropdown from '../../../hoc/withDropdown';
import UnreadCount from './UnreadCount';

const HeaderDropdown = (props) => {
  const {
    icon,
    image,
    isBig,
    toggleRef,
    dropdownRef,
    renderContents,
    unreadCount,
    dropdownOpened,
    tooltip,
    text,
    isLan,
  } = props;
  return (
    
    <div
    className={classNames([
      'header-dropdown',
      {
        'header-dropdown_big': isBig,
        'header-dropdown_opened': dropdownOpened,
      },
    ])}
  >
    <CustomizedTooltip title={tooltip}>
      <button
        className='header-button'
        aria-describedby={tooltip}
        ref={toggleRef}
      >
          {icon && <i className={`${icon}`} />}
          {image && <img src={image} alt='' />}
        {text && <span className='f-1011'>{text}</span>}
        <UnreadCount unreadCount={unreadCount} />
      </button>
    </CustomizedTooltip>

    <div
      className={classNames([
        'pl-dropdown',
        'pl-dropdown--user',
        { 'pl-dropdown--user_big': !isBig && !isLan },
        { 'pl-dropdown--lan': isLan },
      ])}
      ref={dropdownRef}
    >
      <div className='triangle' />

      <div className='header-dropdown-activity'>{renderContents()}</div>
    </div>
  </div>
  );
};

HeaderDropdown.propTypes = {
  dropdownOpened: PropTypes.bool,
  dropdownRef: PropTypes.func,
  icon: PropTypes.string,
  image: PropTypes.string,
  isBig: PropTypes.bool,
  renderContents: PropTypes.func,
  toggleRef: PropTypes.func,
  unreadCount: PropTypes.number,
  isLan: PropTypes.bool,
};

HeaderDropdown.defaultProps = {
  unreadCount: 0,
  isLan: false,
};

const HeaderWithDropdown = withDropdown(HeaderDropdown);

export default (props) => <HeaderWithDropdown closeOnClick={true} {...props} />;
