import React from 'react';
import PropTypes from 'prop-types';
import lifecycle from 'react-pure-lifecycle';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Page from '../components/Page';
import CardForFullNews from '../components/CardForFullNews';
import CardForNews from '../components/CardForNews';
import NoDataCard from '../components/Card/NoDataCard';
import cards from '../data/publications.json';
import { fetchPublications, fetchPublicationDetails } from '../store/actions';
import languages from '../helpers/languages';
import PreventEmptyDetail from '../components/PreventEmptyDetail';
import {
  COUNTRY,
  NAME
} from '../components/Search/TopFilters/types';

var language = languages['en'];
var label = language.publication[0];
var common = language.common[0];
var menu = language.menu[0];

const toolbar = (common) => ({
  tabs: {
    values: {},
    filterByKeys: (card) => [card.categoryName],
  },
  sort: [
    { orderBy: 'date', order: 'DESC', label: common.RECENT },
    { orderBy: 'date', order: 'ASC', label: common.OLDEST },
  ],
});

const mapStateToProps = (state) => {
  return {
    cards: state.publications.publications,
    totalItems: state.publications.totalItems,
    sortLabel: state.publications.sortLabel,
    publicationDetails: state.publications.publicationDetails,
    userRole: state.auth.user.userRole,
    lan: state.language.lan,
  };
};

const mapDispatchToProps = {
  fetchPublications,
  fetchPublicationDetails,
};

const methods = {
  componentDidMount(props) {
    props.fetchPublications(window.location.hash);
    var queryParam = window.location.hash.split('/');
    if (queryParam[1] && queryParam[2])
      props.fetchPublicationDetails(window.location.hash);
  },
};

const PagePublications = (props) => {
  language = languages[props.lan];
  label = language.publication[0];
  common = language.common[0];
  menu = language.menu[0];
  const { cards, totalItems, publicationDetails } = props;
  var roleFlag=false;
  return (
    <Page
      name={common.PUBLICATIONS}
      singleName={label.PUBLICATION}
      toolbar={toolbar(common)}
      // topfilters={[NAME, COUNTRY]}
      cards={cards}
      fetchFunction={props.fetchPublications}
      totalItems={totalItems}
      hasLeftPanel={false}
      renderAfterTitle={() => (
        <div className='wrap'>
          <div className='container'>
            <p className='gray'>{label.INFO}</p>
          </div>
        </div>
      )}
      sortLabel={props.sortLabel}
      canCreateNew={roleFlag}
      {...props}
      // eslint-disable-next-line no-shadow
      renderCards={(cards, filter, page, cardsPerPage) => (
        <div className='odd-items'>
          {cards.map((el, i) => (
            <CardForNews key={i} {...el} />
          ))}
          {cards.length === 0 && <NoDataCard />}
        </div>
      )}
      renderDetail={(
        match // eslint-disable-line no-unused-vars
      ) => (
        <PreventEmptyDetail detail={publicationDetails}>
          <CardForFullNews {...publicationDetails} />
        </PreventEmptyDetail>
      )}
    />
  );
};

PagePublications.defaultProps = {
  cards: [],
};

const PagePublicationsCycle = lifecycle(methods)(PagePublications);
const List = connect(
  mapStateToProps,
  mapDispatchToProps
)(PagePublicationsCycle);

export default List;
