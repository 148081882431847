import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import lifecycle from 'react-pure-lifecycle';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Page from '../components/Page';
import CardForFullNews from '../components/CardForFullNews';
import CardForNews from '../components/CardForNews';
import NoDataCard from '../components/Card/NoDataCard';
import { fetchStory, fetchDetailsStory } from '../store/actions';
import languages from '../helpers/languages';
import PreventEmptyDetail from '../components/PreventEmptyDetail';

var language = languages['en'];
var label = language.publication[0];
var common = language.common[0];
var menu = language.menu[0];

const toolbar = (common) => ({
  tabs: {
    values: {},
    filterByKeys: (card) => [card.categoryName],
  },
  sort: [
    { orderBy: 'date', order: 'DESC', label: common.RECENT },
    { orderBy: 'date', order: 'ASC', label: common.OLDEST },
  ],
});

const mapStateToProps = (state) => {
  return {
    cards: state.successStory.stories,
    totalItems: state.successStory.totalItems,
    sortLabel: state.successStory.sortLabel,
    storyDetails: state.successStory.storyDetails,
    userRole: state.auth.user.userRole,
    lan: state.language.lan,
  };
};

const mapDispatchToProps = {
  fetchStory,
  fetchDetailsStory,
};

const methods = {
  componentDidMount(props) {
    props.fetchStory(window.location.hash);
    var queryParam = window.location.hash.split('/');
    if (queryParam[1] && queryParam[2])
      props.fetchDetailsStory(window.location.hash);
  },
};

const PageSuccessStories = (props) => {
  language = languages[props.lan];
  label = language.publication[0];
  common = language.common[0];
  menu = language.menu[0];
  const { cards, totalItems, storyDetails } = props;

  var roleFlag = false;
  if (props.userRole === 'member' || typeof props.userRole === 'undefined') {
    roleFlag = false;
  }

  return (
    <Page
      name={common.SUCCESS_STORIES}
      singleName={label.SUCCESS_STORIES}
      toolbar={toolbar(common)}
      cards={cards}
      fetchFunction={props.fetchStory}
      totalItems={totalItems}
      renderAfterTitle={() => (
        <div className='after-title flex'>
          <div className='left'>
            <p className='gray'></p>
          </div>
        </div>
      )}
      sortLabel={props.sortLabel}
      hasLeftPanel={false}
      canCreateNew={roleFlag}
      {...props}
      // eslint-disable-next-line no-shadow
      renderCards={(cards, filter, page, cardsPerPage) => (
        <div className='odd-items'>
          {cards.map((el, i) => (
            <CardForNews key={i} {...el} />
          ))}
          {cards.length === 0 && <NoDataCard />}
        </div>
      )}
      cardsPerPage={9}
      renderDetail={(match) => (
        <PreventEmptyDetail detail={storyDetails}>
          <Fragment>
            <CardForFullNews {...storyDetails} />
          </Fragment>
        </PreventEmptyDetail>
      )}
    />
  );
};

PageSuccessStories.defaultProps = {
  cards: [],
};

const PageSuccessStoriesCycle = lifecycle(methods)(PageSuccessStories);
const List = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageSuccessStoriesCycle);

export default List;
