import { connect } from 'react-redux';
import {
  updateContact,
  fetchRequest,
  closeRegistrationMessage
} from '../../../../store/actions';
import { fetchDashboard} from '../../../../actions/dashboard';
import Dashboard from '../layout/Dashboard';

const mapStateToProps = (state) => {
  const { isPhone } = state.responsive;
  return {
    showPreloader: !state.dashboard.hasFetched,
    user: state.auth.user,
    showRegistrationMessage: state.registration.registrationSuccess,
    registrationMessage: state.registration.registrationMessage,
    request: state.network.request,
  };
};

const mapDispatchToProps = {
  fetchDashboard,
  fetchRequest,
  updateContact,
  closeRegistrationMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
