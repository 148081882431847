import React from 'react';
import { asField } from 'informed';
import InputRange from 'react-input-range';

export default asField(
  ({ fieldState: { value }, fieldApi: { setValue, setTouched }, ...rest }) => (
    <InputRange
      value={value}
      onChange={(v) => {
        setValue(v);
        setTouched(true);
      }}
      {...rest}
    />
  )
);
