import React from 'react';

const SupportedBy = ({ logos = [] }) => {
  const rowCount = Math.ceil(logos.length / 3);
  const rows = Array(rowCount)
    .fill(null)
    .map((el, i) => [logos[3 * i], logos[3 * i + 1], logos[3 * i + 2]]);

  return (
    <table style={{ width: '100%' }}>
      <tbody>
        {rows.map((row, i) => (
          <tr key={i}>
            {row
              .filter((el) => !!el)
              .map((el, j) => (
                <td key={j} style={{ width: `${100 / row.length}%` }}>
                  <img
                    key={j}
                    src={el}
                    alt=''
                    style={{
                      marginLeft: 'auto',
                      maxWidth: '100%',
                      marginRight: 'auto',
                      maxHeight: '125px',
                    }}
                  />
                </td>
              ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SupportedBy;
