import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import VideoPlayButton from '../components/VideoPlayButton';

const CardForVideo = (props) => {
  const {
    forBigNews,
    title,
    image = '',
    bigImage = '',
    index,
    duration,
    id,
  } = props;

  const href = `/video/${encodeURIComponent(title)
    .replace(/%20/g, '+')
    .replace(/%/g, '')}/${id}`;

  return (
    <section
      className={classNames(
        'card',
        'card_for-news',
        'card_for-video',
        'list-animation-item',
        {
          ' card_for-big-news': forBigNews,
          ' card_for-small-news': !forBigNews,
        }
      )}
    >
      <Link to={href} className='card__image'>
        <VideoPlayButton
          height={forBigNews ? 465 : 250}
          href={forBigNews ? bigImage : image}
          index={index}
        />
      </Link>

      {title && (
        <div className='card__title'>
          <Link to={href}>{title}</Link>
        </div>
      )}
    </section>
  );
};

CardForVideo.propTypes = {
  forBigNews: PropTypes.bool,
  title: PropTypes.string,
  image: PropTypes.string,
  bigImage: PropTypes.string,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.number,
};

CardForVideo.defaultProps = {
  forBigNews: false,
  title: '',
  image: '',
  bigImage: '',
  id: null,
};

export default CardForVideo;
