import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function GlobalError(props) {
  console.log(props);
  return (
    <div>
      <div
        className={classnames([
          'itc-main-error',
          'text-center',
          { 'itc-error': props.error },
        ])}
      >
        {props.error || props.defaultText}
      </div>
      <div>{props.children}</div>
    </div>
  );
}

GlobalError.propTypes = {
  error: PropTypes.string,
};

export default GlobalError;
