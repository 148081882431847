import React from 'react';
import Card from '../../../components/Card';
import NoDataCard from '../../../components/Card/NoDataCard';

const Cards = (cards) => (
  <div className='cards-list'>
    {cards.length > 0 &&
      cards.map((card, i) => <Card key={card.id || i} {...card} />)}
    {cards.length === 0 && <NoDataCard />}
  </div>
);

export default Cards;
