import {
  COUNTRIES,
  SET_CITIES,
  SET_USER_TYPE_VALIDATION_TRUE,
  SET_USER_TYPE_VALIDATION_FALSE,
  REGISTER_SAVE_ERRORS,
  FILL_ALL_FORMS,
  REGISTRATION_SUCCESS,
  REGISTER_SAVE_ERRORS_FRONTEND,
  DECLARATION_ERROR,
  CLOSE_VALIDATION_ERROR,
  CLOSE_REGISTRATION_MESSAGE,
  SET_COMPANIES,
  SET_EXISTING_COMPANY,
  SET_NEW_COMPANY,
  VERIFIER_REGISTER_SUCCESS,
} from '../store/actions';

const initialState = {
  companySize: [],
  noOfManagementTeam: [],
  noOfManagementTeamWomen: [],
  numberOfEmployees: [],
  noOfEmployeesWomen: [],
  exportingYearExperience: [],
  companyValidation: false,
  formHasError: false,
  errorMessages: {},
  registrationSuccess: false,
  registrationMessage: '',
  existingCompanyId: false,
  existingCompanyDetails: [],
};

export default function registration(state = initialState, action) {
  switch (action.type) {
    case COUNTRIES:
      return {
        ...state,
        countries: action.payload.data,
      };
    case SET_COMPANIES:
      return {
        ...state,
        companies: action.payload.data,
      };
    case SET_CITIES:
      return {
        ...state,
        cities: action.payload.data,
      };
    case SET_USER_TYPE_VALIDATION_TRUE:
      return {
        ...state,
        companyValidation: true,
        existingCompanyId: false,
        existingCompanyDetails: [],
      };
    case SET_USER_TYPE_VALIDATION_FALSE:
      return {
        ...state,
        companyValidation: false,
        existingCompanyId: false,
        existingCompanyDetails: [],
      };
    case REGISTER_SAVE_ERRORS:
      return {
        ...state,
        formHasError: true,
        errorMessages: action.payload,
      };
    case FILL_ALL_FORMS:
      return {
        ...state,
        formHasError: true,
        mainErrorMessage: action.payload,
      };
    case REGISTRATION_SUCCESS:
      return {
        ...state,
        registrationSuccess: true,
        registrationMessage: action.payload,
      };
    case REGISTER_SAVE_ERRORS_FRONTEND:
      return {
        ...state,
        formHasError: action.payload.hasError,
        errorMessages: action.payload.errors,
        redirectUrl: action.payload.redirectUrl,
        mainErrorMessage: action.payload.message,
      };
    case DECLARATION_ERROR:
      return {
        ...state,
        formHasError: true,
        mainErrorMessage: action.payload,
      };
    case CLOSE_VALIDATION_ERROR:
      return {
        ...state,
        formHasError: false,
      };
    case CLOSE_REGISTRATION_MESSAGE:
      return {
        ...state,
        registrationSuccess: false,
        registrationMessage: '',
      };
    case SET_EXISTING_COMPANY:
      return {
        ...state,
        existingCompanyId: action.payload.data.companyId,
        existingCompanyDetails: action.payload.data,
      };
    case SET_NEW_COMPANY:
      return {
        ...state,
        existingCompanyId: false,
        existingCompanyDetails: [],
      };
    case VERIFIER_REGISTER_SUCCESS:
      return {
        ...state,
        userRoleCheck: action.payload.data.userRole,
      };
    default:
      return state;
  }
}
