import {
  OFFERS,
  OFFERDETAILS,
  OFFERAPPLIED,
  ADD_OFFER_BOOKMARK,
  HANDLE_OFFER_ERROR,
  SET_OFFER_PREVIEW,
  ADD_OFFER_SUCCESS,
  TOGGLE_APPLY_SUCCESS,
  TOGGLE_DETAIL_APPLY,
  CLOSE_SUCCESS_OFFER,
} from '../store/actions';

const initialState = {
  errorMessages: [],
  hasError: false,
  detailPreview: '',
  offerApplied: false,
  isApplySuccessShown: false,
  isDetailApplyShown: false,
};

export default function offers(state = initialState, action) {
  switch (action.type) {
    case OFFERS:
      return {
        ...state,
        offers: action.payload.data,
        totalItems: action.payload.count,
        sortLabel: action.payload.sortLabel,
      };
    case OFFERDETAILS:
      return {
        ...state,
        offer: action.payload.data,
        offerApplied: action.payload.data.alreadyApplied,
        offerBookmark: action.payload.data.bookmarked,
      };
    case OFFERAPPLIED:
      return {
        ...state,
        offerApplied: action.payload.status,
        isApplySuccessShown: true,
        isDetailApplyShown: false,
      };
    case ADD_OFFER_BOOKMARK:
      return {
        ...state,
        offerBookmark: !state.offerBookmark,
      };
    case HANDLE_OFFER_ERROR:
      return {
        ...state,
        formHasError: action.payload.hasError,
        errorMessages: action.payload.errors,
        mainErrorMessage: action.payload.message,
      };
    case SET_OFFER_PREVIEW:
      return {
        ...state,
        detailPreview: action.payload,
      };
    case ADD_OFFER_SUCCESS:
      return {
        ...state,
        saveSucess: true,
      };
    case TOGGLE_APPLY_SUCCESS:
      return {
        ...state,
        isApplySuccessShown: action.payload,
      };
    case TOGGLE_DETAIL_APPLY:
      return {
        ...state,
        isDetailApplyShown: action.payload,
      };
    case CLOSE_SUCCESS_OFFER:
      return {
        ...state,
        closeOffer: action.payload,
      };
    default:
      return state;
  }
}
