import {
  push
} from 'react-router-redux';
import queryString from 'query-string';
import moment from 'moment';
import socketIOClient from 'socket.io-client';
import axios from './../requests/platformApi';
import {
  distinguishGroupId
} from './../constants';
import {
  getUserOrGroup
} from './../reducers/messenger';
import {
  treeToQuery
} from './../reducers/search';
import {
  webinarTypeIsChosen
} from './../services/shared';
import {
  logout,
  setCurrentUser
} from './../actions/auth';


export const COUNTRIES_CHANGE = 'COUNTRIES_CHANGE';
export const DATE_CHANGE = 'DATE_CHANGE';
export const EVENT_TYPE_CHANGE = 'EVENT_TYPE_CHANGE';
export const EVENT_SECTOR_CHANGE = 'EVENT_SECTOR_CHANGE';
export const FOCUS_CHANGE = 'FOCUS_CHANGE';
export const MULTISELECT_ADD = 'MULTISELECT_ADD';
export const MULTISELECT_REMOVE = 'MULTISELECT_REMOVE';
export const RANGE_CHANGE = 'RANGE_CHANGE';
export const RANGE_CHANGE_COMPLETE = 'RANGE_CHANGE_COMPLETE';
export const RESET = 'RESET';
export const SELECT_CHANGE = 'SELECT_CHANGE';
export const PRICE_RANGE_CHANGE = 'PRICE_RANGE_CHANGE';
export const PRODUCT_ID_CHANGE = 'PRODUCT_ID_CHANGE';
export const NAME_CHANGE = 'NAME_CHANGE';
export const REFRESH_RESULTS = 'REFRESH_RESULTS';
export const SEARCH_SUBMIT = 'SEARCH_SUBMIT';

export const FETCH_DASHBOARD = 'FETCH_DASHBOARD';
export const DASHBOARD_FETCHED = 'DASHBOARD_FETCHED';
export const TOGGLE_DASHBOARD_SECTION = 'TOGGLE_DASHBOARD_SECTION';
export const DASHBOARD_REORDER = 'DASHBOARD_REORDER';

export const FORUM_TAGS = 'FORUM_TAGS';
export const FORUM_TOPIC = 'FORUM_TOPIC';
export const FORUM_DETAIL = 'FORUM_DETAIL';
export const FORUM_ANSWERS = 'FORUM_ANSWERS';
export const FORM_TAG_OPTIONS = 'FORM_TAG_OPTIONS';
export const FORUM_ALL_ANSWERS = 'FORUM_ALL_ANSWERS';
export const CREATE_FORUM_SUCCESS = 'CREATE_FORUM_SUCCESS';
export const MARKET_TOOLS = 'MARKET_TOOLS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const HANDLE_FORUM_ERROR = 'HANDLE_FORUM_ERROR';
export const COUNTRIES = 'COUNTRIES';
export const SET_CITIES = 'SET_CITIES';
export const SET_USER_TYPE_VALIDATION_TRUE = 'SET_USER_TYPE_VALIDATION_TRUE';
export const SET_USER_TYPE_VALIDATION_FALSE = 'SET_USER_TYPE_VALIDATION_FALSE';
export const SAVE_INFO_TAB_STATE = 'SAVE_INFO_TAB_STATE';
export const SAVE_COMPANIES_TAB_STATE = 'SAVE_COMPANIES_TAB_STATE';
export const SAVE_PRODUCTS_TAB_STATE = 'SAVE_PRODUCTS_TAB_STATE';
export const MEMBERS = 'MEMBERS';
export const ADD_EVENT_BOOKMARK = 'ADD_EVENT_BOOKMARK';
export const MEMBERDETAILS = 'MEMBERDETAILS';
export const REVIEWS = 'REVIEWS';
export const VERIFIERS = 'VERIFIERS';
export const ADD_FORUM_BOOKMARK = 'ADD_FORUM_BOOKMARK';

export const EVENTS = 'EVENTS';
export const DETAIL_EVENTS = 'DETAIL_EVENTS';
export const SET_EVENT_PREVIEW = 'SET_EVENT_PREVIEW';
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS';

export const NEWS = 'NEWS';
export const DETAIL_NEWS = 'DETAIL_NEWS';

export const VERIFIER_STATUS = 'VERIFIER_STATUS';

export const REGISTER = 'REGISTER';
export const FILL_ALL_FORMS = 'FILL_ALL_FORMS';
export const REGISTER_SAVE_ERRORS = 'REGISTER_SAVE_ERRORS';
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS';
export const REGISTER_SAVE_ERRORS_FRONTEND = 'REGISTER_SAVE_ERRORS_FRONTEND';
export const DECLARATION_ERROR = 'DECLARATION_ERROR';
export const MY_NETWORK = 'MY_NETWORK';
export const KNOWN_USER = 'KNOWN_USER';
export const USER_REQUEST = 'USER_REQUEST';
export const TENDERS = 'TENDERS';
export const TENDERDETAILS = 'TENDERDETAILS';
export const TENDERAPPLIED = 'TENDERAPPLIED';
export const OFFERS = 'OFFERS';
export const OFFERDETAILS = 'OFFERDETAILS';
export const OFFERAPPLIED = 'OFFERAPPLIED';
export const ADD_OFFER_BOOKMARK = 'ADD_OFFER_BOOKMARK';
export const ADD_TENDER_BOOKMARK = 'ADD_TENDER_BOOKMARK';
export const CLOSE_VALIDATION_ERROR = 'CLOSE_VALIDATION_ERROR';
export const CLOSE_REGISTRATION_MESSAGE = 'CLOSE_REGISTRATION_MESSAGE';
export const BUSINESS = 'BUSINESS';
export const PRODUCT_SEARCH = 'PRODUCT_SEARCH';
export const COUNTRY_SEARCH = 'COUNTRY_SEARCH';
export const HANDLE_OFFER_ERROR = 'HANDLE_OFFER_ERROR';
export const HANDLE_EVENT_ERROR = 'HANDLE_EVENT_ERROR';
export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_EXISTING_COMPANY = 'SET_EXISTING_COMPANY';
export const SET_NEW_COMPANY = 'SET_NEW_COMPANY';

export const SET_OFFER_PREVIEW = 'SET_OFFER_PREVIEW';
export const ADD_OFFER_SUCCESS = 'ADD_OFFER_SUCCESS';
export const HANDLE_TENDER_ERROR = 'HANDLE_TENDER_ERROR';
export const SET_TENDER_PREVIEW = 'SET_TENDER_PREVIEW';
export const ADD_TENDER_SUCCESS = 'ADD_TENDER_SUCCESS';
export const SET_COUNTRIES_FILTER_OPTIONS = 'SET_COUNTRIES_FILTER_OPTIONS';
export const SET_EVENTS_SECTOR_FILTER_OPTIONS =
  'SET_EVENTS_SECTOR_FILTER_OPTIONS';
export const SET_EVENTS_TYPE_FILTER_OPTIONS = 'SET_EVENTS_TYPE_FILTER_OPTIONS';
export const LOGOUT = 'LOGOUT';
export const TOGGLE_APPLY_SUCCESS = 'TOGGLE_APPLY_SUCCESS';
export const TOGGLE_DETAIL_APPLY = 'TOGGLE_DETAIL_APPLY';
export const VIDEOS = 'VIDEOS';
export const VIDEO_DETAILS = 'VIDEO_DETAILS';
export const VIDEO_COMMENTS = 'VIDEO_COMMENTS';
export const PUBLICATIONS = 'PUBLICATIONS';
export const PUBLICATION_DETAILS = 'PUBLICATION_DETAILS';
export const SUCCESSTORY = 'SUCCESSTORY';
export const SUCCESSTORY_DETAILS = 'SUCCESSTORY_DETAILS';
export const PURGE_MEMBERS = 'PURGE_MEMBERS';

export const E_LEARN_CONTENT = 'E_LEARN_CONTENT';
export const SAT_CONTENT = 'SAT_CONTENT';

export const E_INVEST_CONTENT = 'E_INVEST_CONTENT';

export const DO_NOTHING = 'DO_NOTHING';
export const SET_MY_ITEMS = 'SET_MY_ITEMS';
export const SET_MY_FAVORITES = 'SET_MY_FAVORITES';
//settings
export const SET_GENERAL_TAB = 'SET_GENERAL_TAB';
export const SET_DETAILS_TAB = 'SET_DETAILS_TAB';
export const SET_VERIFIER_TAB = 'SET_VERIFIER_TAB';
export const SET_SERVICES_TAB = 'SET_SERVICES_TAB';
export const RESET_TABS = 'RESET_TABS';
export const SET_SAVE_ERRORS_FRONTEND = 'SET_SAVE_ERRORS_FRONTEND';

export const COACHES = 'COACHES';
export const COACH_STATUS = 'COACH_STATUS';
export const SEARCH_RESULT_FETCHED = 'SEARCH_RESULT_FETCHED';
export const FETCH_SEARCH_RESULT = 'FETCH_SEARCH_RESULT';
export const ATTEMPT_RESET_PASSWORD = 'ATTEMPT_RESET_PASSWORD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CLOSE_RESET_MESSAGE = 'CLOSE_RESET_MESSAGE';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const RESET_ON_MOUNT = 'RESET_ON_MOUNT';

export const FAQS = 'FAQS';
export const STATIC_PAGES = 'STATIC_PAGES';

export const COMMITMENTS_LIST = 'COMMITMENTS_LIST';
export const COMMITMENTS_QUESTIONS = 'COMMITMENTS_QUESTIONS';
export const COMMITMENT_SUCCESS = 'COMMITMENT_SUCCESS';
export const SET_COMMITMENTS = 'SET_COMMITMENTS';
export const PROFILE_PROGRESS = 'PROFILE_PROGRESS';
export const SET_CERTIFICATES = 'SET_CERTIFICATES';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_LOADER = 'UPDATE_LOADER';
export const PROFILE_IMAGE_ERROR = 'PROFILE_IMAGE_ERROR';
export const CLOSE_UPDATE_SUCCESS = 'CLOSE_UPDATE_SUCCESS';
export const ABUSESUCCESS = 'ABUSESUCCESS';
// messenger
export const UPDATE_MESSENGER_CACHE = 'UPDATE_MESSENGER_CACHE';
export const APPLOZIC_SOCKET_EVENT = 'APPLOZIC_SOCKET_EVENT';
export const SET_APPLOZIC_API = 'SET_APPLOZIC_API';
export const TOGGLE_APPLOZIC_API_FETCHING = 'TOGGLE_APPLOZIC_API_FETCHING';
export const APPEND_CURRENT_CHAT = 'APPEND_CURRENT_CHAT';
export const RELOAD_CHAT = 'RELOAD_CHAT';
export const SET_TOTAL_UNREAD_COUNT = 'SET_TOTAL_UNREAD_COUNT';
export const TYPING_STATUS_CHANGE = 'TYPING_STATUS_CHANGE';
export const SET_CHATS = 'SET_CHATS';
export const SET_CONTACTS_OPTIONS = 'SET_CONTACTS_OPTIONS';
export const SET_GROUPS = 'SET_GROUPS';
export const SET_GROUPS_USER_LIST = 'SET_GROUPS_USER_LISTSET_GROUPS_USER_LIST';
export const SET_CONTACTS_OPTIONS_FOR_SEARCH =
  'SET_CONTACTS_OPTIONS_FOR_SEARCH';
export const APPEND_USER_OR_GROUP = 'APPEND_USER_OR_GROUP';
export const RESET_CHATS = 'RESET_CHATS';
export const READ_UPDATE = 'READ_UPDATE';

export const PROFILE_BELL_NOTIFICATION_COUNT =
  'PROFILE_BELL_NOTIFICATION_COUNT';
export const FETCHED_BELL_NOTIFICATIONS = 'FETCHED_BELL_NOTIFICATIONS';
export const SET_NOTIFICATION_TAB_DATA = 'SET_NOTIFICATION_TAB_DATA';

export const COACH_MESSAGE_HIDE = 'COACH_MESSAGE_HIDE';
export const VERIFIER_MESSAGE_HIDE = 'VERIFIER_MESSAGE_HIDE';
export const MEMBER_MESSAGE_HIDE = 'MEMBER_MESSAGE_HIDE';
export const NETWORK_MESSAGE_HIDE = 'NETWORK_MESSAGE_HIDE';
export const BUSINESS_MESSAGE_HIDE = 'BUSINESS_MESSAGE_HIDE';

export const APPEND_NOTIFICATIONS = 'APPEND_NOTIFICATIONS';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const CLOSE_SUCCESS = 'CLOSE_SUCCESS';
export const CLOSE_SUCCESS_OFFER = 'CLOSE_SUCCESS_OFFER';
export const VERIFIER_REGISTER_SUCCESS = 'VERIFIER_REGISTER_SUCCESS';
export const CONTACT_REMOVE_FLAG = 'CONTACT_REMOVE_FLAG';

export const WEBINARS = 'WEBINARS';
export const DETAIL_WEBINARS = 'DETAIL_WEBINARS';
export const WEBINAR_SECTOR_CHANGE = 'WEBINAR_SECTOR_CHANGE';
export const SET_WEBINARS_SECTOR_FILTER_OPTIONS =
  'SET_WEBINARS_SECTOR_FILTER_OPTIONS';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';
export const CLOSE_RESET_ERROR_MESSAGE = 'CLOSE_RESET_ERROR_MESSAGE';
export const VERIFIER_SUCCESS_MESSAGE = 'VERIFIER_SUCCESS_MESSAGE';
export const CLOSE_VERIFIER_SUCCESS_MESSAGE = 'CLOSE_VERIFIER_SUCCESS_MESSAGE';

export const PREVIEW_ACCESS_ERROR = 'PREVIEW_ACCESS_ERROR';
export const SOCIAL_MISSING_INFO = 'SOCIAL_MISSING_INFO';
export const SET_SESSION = 'SET_SESSION';
// export const SET_GOOGLE_TRANSLATE = 'SET_GOOGLE_TRANSLATE';

export const PROJECTPAGES = 'PROJECTPAGES ';
export const DETAIL_PROJECTPAGES = 'DETAIL_PROJECTPAGES';
export const HUBPAGES = 'HUBPAGES ';
export const DETAIL_HUBPAGES = 'DETAIL_HUBPAGES';

const config = {
  headers: {
    Accept: 'application/json',
    Authorization: 'Basic c4aa7808d0d92c6bdcfce0d0105bb34c',
    'Content-Type': 'application/json',
  },
};

function languageHeader(lan) {
  const options = {
    headers: {
      'X-localization': lan
    },
  };
  return options;
}


export function fetchRegistrationInitialStates() {
  console.log("fetching countries");
  return (dispatch) => {
    axios.get('/get/countries').then((res) => {
      const countries = res.data;
      dispatch(setCountries(countries));
    });
  };
}

function setCountries(links) {
  return {
    type: COUNTRIES,
    payload: links,
  };
}

export function fetchCompanies() {
  return (dispatch) => {
    axios.get('/companies').then((res) => {
      const companies = res.data;
      dispatch(setCompanies(companies));
    });
  };
}

function setCompanies(countries) {
  return {
    type: SET_COMPANIES,
    payload: countries,
  };
}

export function fetchCities(countryId) {
  return (dispatch) => {
    axios
      .get('/get/cities', {
        params: {
          countryId: countryId,
        },
      })
      .then((res) => {
        const cities = res.data;
        dispatch(setCities(cities));
      });
  };
}

export function companyChange({
  value,
  isExisting
}) {
  if (value && isExisting) {
    return (dispatch) => {
      axios
        .get(`/company/details?companyId=${value}`)
        .then((res) => {
          const data = res.data;
          dispatch(setExistingCompanyDetails(data));
        })
        .catch(() => {
          console.log('Section error');
        });
    };
  } else {
    return clearExistingCompanyValues();
  }
}

function setExistingCompanyDetails(data) {
  return {
    type: SET_EXISTING_COMPANY,
    payload: data,
  };
}

export function clearExistingCompanyValues() {
  return {
    type: SET_NEW_COMPANY,
    payload: 'new',
  };
}

function setCities(cities) {
  return {
    type: SET_CITIES,
    payload: cities,
  };
}

export function setRegistrationsValidationsUserType(type) {
  var companyValidation = true;
  if (type == 1) {
    companyValidation = false;
  }

  return (dispatch) => {
    dispatch(setValidationsRulesAction(companyValidation));
  };
}

function setValidationsRulesAction(companyValidation) {
  if (companyValidation) {
    return {
      type: SET_USER_TYPE_VALIDATION_TRUE,
    };
  } else {
    return {
      type: SET_USER_TYPE_VALIDATION_FALSE,
    };
  }
}

export function saveInfoTabDetails(values) {
  var data = {
    userType: values['user-type'],
    companyName: values['company-name'],
    jobPosition: values['job-position'],
    userId: values['userId'],
  };
  return (dispatch) => {
    dispatch(saveInfoTabState(data));
  };
}

function saveInfoTabState(data) {
  return {
    type: SAVE_INFO_TAB_STATE,
    payload: data,
  };
}

export function saveCompaniesTabDetails(values) {
  var data = {
    companyType: values.hasOwnProperty('companyType') ? values.companyType : 1,
    zip: values.zip,
    companySize: values.hasOwnProperty('companySize') ? values.companySize : 0,
    // country: values.country,
    city: values.cities,
    address: values.address,
    phone: values.phone,
    fax: values.fax,
    websiteUrl: values.website,
    facebook: values.facebook,
    managedByWomen: values.hasOwnProperty('managedByWomen') ?
      values.managedByWomen : true,
    noOfManagementTeam: values.hasOwnProperty('noOfManagementTeam') ?
      values.noOfManagementTeam : 0,
    noOfWomenManagementTeam: values.hasOwnProperty('noOfWomenManagementTeam') ?
      values.noOfWomenManagementTeam : 0,
    numberOfEmployees: values.hasOwnProperty('numberOfEmployees') ?
      values.numberOfEmployees : 0,
    numberOfEmployeesWomen: values.hasOwnProperty('numberOfEmployeesWomen') ?
      values.numberOfEmployeesWomen : 0,
  };
  return (dispatch) => {
    dispatch(saveCompaniesTabState(data));
  };
}

function saveCompaniesTabState(data) {
  return {
    type: SAVE_COMPANIES_TAB_STATE,
    payload: data,
  };
}

export function saveProductsTabDetails(values) {
  var data = {
    primaryCustomers: values.primaryCustomers,
    annualValueOfSales: values.annualValueOfSales,
    annualValueOfExports: values.annualValueOfExports,
    hasExportExperience: values.hasExportExperience,
    yearsOfExportingExperience: values.yearsOfExportingExperience,
    countriesExport: values.exportToCountries,
    countriesImport: values.exportFromCountries,
    selling: values['selling'],
    lookingFor: values['looking-for'],
  };

  return (dispatch) => {
    dispatch(saveProductsTabState(data));
  };
}

function saveProductsTabState(data) {
  return {
    type: SAVE_PRODUCTS_TAB_STATE,
    payload: data,
  };
}

export function attemptRegistration(formvalues, as, existingCompanyId, countryId) {
  for (var key in formvalues) {
    var values = formvalues[key].values;
    if (key == 1) {
      if (values['typeUser'] === 2) {
        var tab1 = {
          typeUser: values['typeUser'],
          companyName: values['companyName'].value,
          jobPosition: values['jobPosition'],
          userId: values['userId'],
          existingCompanyId: existingCompanyId != false ? existingCompanyId : 0,
        };
      } else {
        tab1 = {
          companyName: values['companyName'] ? values['companyName'].value : '',
          jobPosition: values['jobPosition'] ? values['jobPosition'] : '',
          typeUser: values['typeUser'],
          userId: values['userId'],
          existingCompanyId: existingCompanyId != false ? existingCompanyId : 0,
        };
      }
    } else if (key == 2) {
      var tab2 = {
        companyType: values.hasOwnProperty('companyType') ?
          values.companyType : '',
        zipCode: values.zip,
        companySize: values.hasOwnProperty('companySize') ?
          values.companySize : '',
        country: countryId,
        city: values.city,
        address: values.address,
        phone: values.phone,
        fax: values.fax,
        website: values.website,
        linkedin: values.linkedin,
        twitter: values.twitter,
        facebook: values.facebook,
        managedByWomen: values.hasOwnProperty('managedByWomen') ?
          values.managedByWomen : true,
        yearOfEstablishment: values.yearOfEstablishment,
        noOfManagementTeam: values.hasOwnProperty('noOfManagementTeam') ?
          values.noOfManagementTeam : '',
        noOfWomenManagementTeam: values.hasOwnProperty(
            'noOfWomenManagementTeam'
          ) ?
          values.noOfWomenManagementTeam : '',
        noOfEmployees: values.hasOwnProperty('numberOfEmployees') ?
          values.numberOfEmployees : '',
        noOfWomenEmployees: values.hasOwnProperty('numberOfEmployeesWomen') ?
          values.numberOfEmployeesWomen : '',
      };
    } else if (key == 3) {
      var tab3 = {
        primaryCustomers: values.primaryCustomers && values.primaryCustomers.length > 0 ?
          values.primaryCustomers.join('+') : '',
        annualValueOfSales: values.annualValueOfSales,
        annualExportValue: values.annualValueOfExports,
        experienceInExporting: values.hasExportExperience,
        yearOfExporting: values.yearsOfExportingExperience,
        exportFromCountries: values.hasOwnProperty('exportToCountries') ?
          JSON.stringify(values.exportFromCountries) : null,
        exportToCountries: values.hasOwnProperty('exportFromCountries') ?
          JSON.stringify(values.exportToCountries) : null,
        sellingProducts: values.hasOwnProperty('sellingProducts') ?
          JSON.stringify(values['sellingProducts']) : null,
        lookingProducts: values.hasOwnProperty('lookingForProducts') ?
          JSON.stringify(values['lookingForProducts']) : null,
        offeredServices: values.hasOwnProperty('offeringServices') ?
          JSON.stringify(values['offeringServices']) : null,
        lookingServices: values.hasOwnProperty('lookingForServices') ?
          JSON.stringify(values['lookingForServices']) : null,
      };
    }
  }
  if (!tab1 || !tab2 || !tab3) {
    return (dispatch) => {
      dispatch(doRegistrationErrors('BEFORE', 'Fill all three tabs'));
      dispatch(push('/registration/' + as + '?step=1'));
    };
  }

  //save registration
  return (dispatch, getState) => {
    const lan = getState().language.lan;
    const data = Object.assign(tab1, tab2, tab3, {
      userRole: as
    });

    if (as == 'Verifier') {
      data.typeUser = 2;
    }

    axios
      .post('/completeRegistration', data, languageHeader(lan))
      .then((res) => {
        if (as === 'Verifier') {
          dispatch(becomeVerifier());
        }
        dispatch(doRegistration(res.data));
        //dispatch checksession
        dispatch(checkSession());
      })
      .catch(function (error) {
        dispatch(doRegistrationErrors('SAVE', error));
        if (as == 'Verifier') {
          dispatch(push('/registration/verifier?step=1'));
        }
        if (as == 'Buyer') {
          dispatch(push('/registration/buyer?step=1'));
        } else {
          dispatch(push('/registration/seller?step=1'));
        }
      });
  };
}

function setVerifierData(data) {
  return {
    type: VERIFIER_REGISTER_SUCCESS,
    payload: data,
  };
}

export function doRegistrationErrors(type, data) {
  if (type == 'BEFORE') {
    return {
      type: FILL_ALL_FORMS,
      payload: data,
    };
  } else if (type == 'SAVE') {
    return {
      type: REGISTER_SAVE_ERRORS,
      payload: data,
    };
  } else {
    return {
      type: REGISTER_SAVE_ERRORS_FRONTEND,
      payload: data,
    };
  }
}

export function doSettingErrors(data) {
  return {
    type: SET_SAVE_ERRORS_FRONTEND,
    payload: data,
  };

}

function doRegistration(data) {
  return {
    type: REGISTRATION_SUCCESS,
    payload: data,
  };
}

function isEmpty(data) {
  for (var item in data) {
    return true;
  }

  return false;
}

export function fetchMembers(param, messageFlag = null) {
  var url = '/members';
  var actualUrl = window.location.hash;
  actualUrl = actualUrl.split('/')[1];
  actualUrl = actualUrl.split('?')[0];
  if (actualUrl == 'companies') url = url + '?' + 'tab=company';
  else url = url + '?' + 'tab=individual';
  var result = false;
  if (messageFlag != undefined || messageFlag != null) {
     result = true;
  }

  if (param) {
    var queryParam = queryString.parse(decodeURIComponent(param.split('?')[1]));
    var sortLabel = 'Recent';
    if (queryParam['order'] == 'ASC') sortLabel = 'Oldest';

    if (param.split('?')[1]) url = url + '&' + param.split('?')[1];
  }
  return (dispatch) => {
    dispatch(purgeMembers({
      memberSection: 'members'
    }));
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        var messageStatus = result;
        data.data.messageShow = messageStatus;
        data.sortLabel = sortLabel;
        dispatch(setMembers(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function fetchBestMatchingMembers(param, messageFlag = null) {
  var url = '/members';
  var result = false;
  if (messageFlag != undefined || messageFlag != null) {
     result = true;
  }

  if (param) {
    var queryParam = queryString.parse(decodeURIComponent(param.split('?')[1]));
    var sortLabel = 'Recent';
    if (queryParam['order'] == 'ASC') sortLabel = 'Oldest';

    if (param.split('?')[1]) url = url + '&' + param.split('?')[1];
  }
  return (dispatch) => {
    dispatch(purgeMembers({
      memberSection: 'members'
    }));
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        var messageStatus = result;
        data.data.messageShow = messageStatus;
        data.sortLabel = sortLabel;
        dispatch(setMembers(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}


function setMembers(data) {
  return {
    type: MEMBERS,
    payload: data,
  };
}

export function closeValidationError() {
  return {
    type: CLOSE_VALIDATION_ERROR,
  };
}

export function closeRegistrationMessage() {
  return {
    type: CLOSE_REGISTRATION_MESSAGE,
  };
}

export function fetchMemberDetails(param, messageFlag = null) {
  var url = '/member';
  var result = false;
  if (messageFlag != undefined || messageFlag != null) {
     result = true;
  }

  if (param) {
    var queryParam = param.split('/');
    if (queryParam[3] && queryParam[4])
       url = url + '/' + queryParam[3] + '/' + queryParam[4];
  }

  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        var messageStatus = true;
        data.data.messageShow = result;
        dispatch(setMemberDetails(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setMemberDetails(data) {
  return {
    type: MEMBERDETAILS,
    payload: data,
  };
}

export function addContact(param, tab) {
  var url = '/contact';
  var queryParam = param.split(',');
  var data = {
    id: queryParam[1],
    type: queryParam[0],
  };

  return (dispatch) => {
    axios
      .post(url, data)
      .then((res) => {
        if (queryParam[2] == 'members') {
          dispatch(fetchMembers(window.location.hash, [false]));
        } else if (queryParam[2] == 'companies') {
          dispatch(fetchMembers(window.location.hash, [false]));
        } else if (queryParam[2] == 'search') {
          dispatch(
            fetchSearchResult(window.location.hash, null, false, [false])
          );
        } else if (
          queryParam[2] == 'memberDetails' ||
          queryParam[2] == 'networkDetails' ||
          queryParam[2] == 'businessDetails'
        ) {
          dispatch(fetchMemberDetails(window.location.hash, [false]));
        } else if (queryParam[2] == 'knownUser') {
          dispatch(knownUser());
          dispatch(fetchNetwork(window.location.hash, [false]));
        } else if (queryParam[2] == 'business-circle') {
          var tabSel = window.location.hash;
          if (tab == 'companies') tabSel = tabSel + '?tab=company';
          else if (tab == 'individuals') tabSel = tabSel + '?tab=individual';
          dispatch(fetchBusinessCircle(tabSel, [false]));
        }
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function updateContact(param, tab) {
  var url = '/contact';
  var queryParam = param.split(',');
  var data = {
    id: queryParam[1],
    type: queryParam[0],
    status: queryParam[4],
    requestedBy: queryParam[3],
  };
  return (dispatch) => {
    axios
      .put(url, data)
      .then((res) => {
        dispatch(fetchRecentMessages());
        if (queryParam[2] == 'members') {
          dispatch(fetchMembers(window.location.hash, [false]));
        } else if (queryParam[2] == 'search') {
          dispatch(
            fetchSearchResult(window.location.hash, null, false, [false])
          );
        } else if (
          queryParam[2] == 'memberDetails' ||
          queryParam[2] == 'networkDetails' ||
          queryParam[2] == 'requestContactsDetail' ||
          queryParam[2] == 'businessDetails'
        ) {
          dispatch(fetchMemberDetails(window.location.hash, [false]));
        } else if (queryParam[2] == 'my-network') {
          dispatch(knownUser());
          dispatch(fetchNetwork(window.location.hash, [false]));
          dispatch(fetchRequest('/#/my-network'));

          if (queryParam[4] == 3) {
            dispatch(setContactRemoveFlag());
          }
        } else if (queryParam[2] == 'networkRequest') {
          dispatch(knownUser());
          dispatch(fetchRequest('/#/my-network'));
          dispatch(fetchNetwork(window.location.hash));
        } else if (queryParam[2] == 'request_contacts') {
          dispatch(fetchRequest(window.location.hash));
        } else if (queryParam[2] == 'business-circle') {
          var tabSel = window.location.hash;
          if (tab == 'companies') tabSel = tabSel + '?tab=company';
          else if (tab == 'individuals') tabSel = tabSel + '?tab=individual';
          dispatch(fetchBusinessCircle(tabSel, [false]));
        }
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setContactRemoveFlag() {
  return {
    type: CONTACT_REMOVE_FLAG,
    payload: true,
  };
}

export function filterData(url, search) {
  let decodeFilter = decodeFilters(search);
  if (url.indexOf('?') !== -1) {
    decodeFilter = (decodeFilter.charAt(0) === "&") ? decodeFilter.substring(1) : decodeFilter;
    url = url + decodeFilter;
  } else {
    decodeFilter = (decodeFilter.charAt(0) === "&") ? decodeFilter.substring(1) : decodeFilter;
    url = url + '?' + decodeFilter
  }
  return '/#/'+url;

}

export function fetchVerifiers(param, search, messageFlag = null) {
  var url = '/verifiers';

  var result = false;
  if (messageFlag !== undefined || messageFlag != null) {
    result = true;
  }

  if (param) {
    var queryParam = queryString.parse(decodeURIComponent(param.split('?')[1]));
    var sortLabel = 'Recent';
    if (queryParam['order'] == 'ASC') sortLabel = 'Oldest';

    if (param.split('?')[1]) url = url + '?' + param.split('?')[1] + '&';
  }
  if (search !== undefined && search != null) {
    let decodeFilter = decodeFilters(search);
    if (url.indexOf('?') !== -1) {
      decodeFilter = (decodeFilter.charAt(0) === "&") ? decodeFilter.substring(1) : decodeFilter;
      url = url + decodeFilter;
    } else {
      decodeFilter = (decodeFilter.charAt(0) === "&") ? decodeFilter.substring(1) : decodeFilter;
      url = url + '?' + decodeFilter;
    }
  }
  return (dispatch) => {
    dispatch(purgeMembers({
      memberSection: 'verifiers'
    }));
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        data.sortLabel = sortLabel;
        dispatch(setVerifiers(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setVerifiers(data) {
  return {
    type: VERIFIERS,
    payload: data,
  };
}

export function getVerified(param) {
  var url = '/verified';
  var queryParam = param.split(',');
  var data = {
    id: queryParam[1],
    type: queryParam[0],
  };
  return (dispatch) => {
    axios
      .post(url, data)
      .then((res) => {
        if (queryParam[2] == 'verification') {
          dispatch(fetchVerifiers(window.location.hash, null, [false]));
          dispatch(setverificationMessage());
        } else if (queryParam[2] == 'verificationDetails') {
          dispatch(fetchMemberDetails(window.location.hash));
          dispatch(setverificationMessage());
        }
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setverificationMessage() {
  return {
    type: VERIFIER_SUCCESS_MESSAGE,
  };
}

export function onVerifierSuccessMessageClose() {
  return {
    type: CLOSE_VERIFIER_SUCCESS_MESSAGE,
  };
}

export function fetchEvents(param, search) {
  var url = '/get/events';
  if (param) {
    var queryParam = queryString.parse(param.split('?')[1]);
    // for some reason API gives no events for calendar
    url = url + '?' + queryString.stringify({
      ...queryParam,
      view: 'grid'
    });
  } else {
    url = '/get/events?tab=upcoming';
  }
  if (search !== undefined && search != null) {
    let decodeFilter = decodeFilters(search);
    if (url.indexOf('?') !== -1) {
      decodeFilter = (decodeFilter.charAt(0) === "&") ? decodeFilter.substring(1) : decodeFilter;
      url = url + decodeFilter;
    } else {
      decodeFilter = (decodeFilter.charAt(0) === "&") ? decodeFilter.substring(1) : decodeFilter;
      url = url + '?' + decodeFilter;
    }
  }
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        dispatch(setEvents(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setEvents(data) {
  return {
    type: EVENTS,
    payload: data,
  };
}

export function detailEvent(param) {
  var url = '/get/events';
  if (param) {
    var queryParam = queryString.parse(decodeURIComponent(param.split('/')[2]));
    if (param.split('/')[2]) url = '/event' + '/' + param.split('/')[2];
  }
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        data.url = window.window.location.href;
        dispatch(setdetailEvent(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setdetailEvent(data) {
  return {
    type: DETAIL_EVENTS,
    payload: data,
  };
}

export function doeventErrors(type, data) {
  return {
    type: HANDLE_EVENT_ERROR,
    payload: data,
  };
}

export function fetchVerifierStatus(messageStatus = false) {
  var url = '/verifier';
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        data.data.messageShow = messageStatus;
        dispatch(setVerifierStatus(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setVerifierStatus(data) {
  return {
    type: VERIFIER_STATUS,
    payload: data,
  };
}

export function becomeVerifier() {
  var url = '/verifier';
  return (dispatch) => {
    axios
      .post(url)
      .then((res) => {
        var data = res.data;
        dispatch(fetchVerifierStatus(true));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function fetchNetwork(param, messageFlag = null) {
  var url = '/network';
  var result = false;

  if (messageFlag != undefined || messageFlag != null) {
    var result = true;
  }

  if (param) {
    var queryParam = queryString.parse(decodeURIComponent(param.split('?')[1]));
    var sortLabel = 'Recent';
    if (queryParam['order'] == 'ASC') sortLabel = 'Oldest';

    if (param.split('?')[1]) var url = url + '?' + param.split('?')[1];
  }
  return (dispatch) => {
    dispatch(purgeMembers({
      memberSection: 'network'
    }));
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        var messageStatus = result;
        data.data.messageShow = messageStatus;
        data.sortLabel = sortLabel;
        dispatch(setNetwork(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setNetwork(data) {
  return {
    type: MY_NETWORK,
    payload: data,
  };
}

export function knownUser() {
  var url = '/knownUser';
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        dispatch(setKnownUser(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setKnownUser(data) {
  return {
    type: KNOWN_USER,
    payload: data,
  };
}

export function fetchRequest(param) {
  var url = '/network';
  url = url + '?requester=1';
  if (param) {
    var queryParam = queryString.parse(decodeURIComponent(param.split('?')[1]));
    var sortLabel = 'Recent';
    if (queryParam['order'] == 'ASC') sortLabel = 'Oldest';

    if (param.split('?')[1]) var url = url + '&' + param.split('?')[1];
  }

  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        data.sortLabel = sortLabel;
        dispatch(setRequest(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setRequest(data) {
  return {
    type: USER_REQUEST,
    payload: data,
  };
}
export function fetchTenders(param, search) {
  var url = '/tenders?';
  if (param) {
    var queryParam = queryString.parse(decodeURIComponent(param.split('?')[1]));
    var sortLabel = 'Recent';
    if (queryParam['orderBy'] === 'date') {
      if (queryParam['order'] === 'ASC') sortLabel = 'Oldest';
      else sortLabel = 'Recent';
    } else if (queryParam['order'] === 'DESC') {
      sortLabel = 'Recent';
    }

    if (param.split('?')[1]) url = url + param.split('?')[1] + '&';
  }

  if (search !== undefined) {
    let decodeFilter = decodeFilters(search);
    if (url.indexOf('?') !== -1) {
      decodeFilter = (decodeFilter.charAt(0) === "&") ? decodeFilter.substring(1) : decodeFilter;
      url = url + decodeFilter;
    } else {
      decodeFilter = (decodeFilter.charAt(0) === "&") ? decodeFilter.substring(1) : decodeFilter;
      url = url + '?' + decodeFilter;
    }
  }

  return (dispatch) => {
    return axios
      .get(url)
      .then((res) => {
        var data = res.data;
        data.sortLabel = sortLabel;
        dispatch(setTenders(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function fetchOffers(param, search) {
  var url = '/offers?';
  if (param) {
    var queryParam = queryString.parse(decodeURIComponent(param.split('?')[1]));
    var sortLabel = 'Recent';
    if (queryParam['orderBy'] === 'date') {
      if (queryParam['order'] === 'ASC') sortLabel = 'Oldest';
      else sortLabel = 'Recent';
    }

    if (param.split('?')[1]) url = url + param.split('?')[1] + '&';
  }

  if (search !== undefined) {
    let decodeFilter = decodeFilters(search);
    if (url.indexOf('?') !== -1) {
      decodeFilter = (decodeFilter.charAt(0) === "&") ? decodeFilter.substring(1) : decodeFilter;
      url = url + decodeFilter;
    } else {
      decodeFilter = (decodeFilter.charAt(0) === "&") ? decodeFilter.substring(1) : decodeFilter;
      url = url + '?' + decodeFilter;
    }
  }

  return (dispatch) => {
    return axios
      .get(url)
      .then((res) => {
        var data = res.data;
        data.sortLabel = sortLabel;
        dispatch(setOffers(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setOffers(data) {
  return {
    type: OFFERS,
    payload: data,
  };
}

export function fetchOfferDetails(param) {
  var url = '/offer/'+param;
  // if (param) {
  //   var queryParam = param.split('/');
  //   if (queryParam[1] && queryParam[2]) 
  //   url = url + '/' + queryParam[2];
  // }

  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        dispatch(setOfferDetails(data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setOfferDetails(data) {
  return {
    type: OFFERDETAILS,
    payload: data,
  };
}

export function fetchTenderDetails(param) {
  var url = '/tender';
  if (param) {
    var queryParam = param.split('/');
    if (queryParam[1] && queryParam[2]) url = url + '/' + queryParam[2];
  }
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        dispatch(setTenderDetails(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setTenderDetails(data) {
  return {
    type: TENDERDETAILS,
    payload: data,
  };
}

function setTenders(data) {
  return {
    type: TENDERS,
    payload: data,
  };
}

function decodeFilters(search) {
  var returnObj = {};
  returnObj.opening = {};
  returnObj.closing = {};

  var query = '';
  if (search.hasOwnProperty('datepicker')) {
    if (search.datepicker.opening.start_date != null) {
      returnObj.opening.start = search.datepicker.opening.start_date.format(
        'YYYY-MM-DD'
      );
      query = query + 'openingStartDate=' + returnObj.opening.start + '&';
    } else {
      returnObj.opening.start = null;
      query = query + 'openingStartDate=null&';
    }

    if (search.hasOwnProperty('priceRange') && search.priceRange != null) {
      query = query + 'priceMin=' + search.priceRange.min + '&';
      query = query + 'priceMax=' + search.priceRange.max + '&';
    }

    if (search.datepicker.opening.end_date != null) {
      returnObj.opening.end = search.datepicker.opening.end_date.format(
        'YYYY-MM-DD'
      );
      query = query + 'openingEndDate=' + returnObj.opening.end + '&';
    } else {
      returnObj.opening.end = null;
      query = query + 'openingEndDate=null&';
    }

    if (search.datepicker.closing.start_date != null) {
      returnObj.closing.start = search.datepicker.closing.start_date.format(
        'YYYY-MM-DD'
      );
      query = query + 'closingStartDate=' + returnObj.closing.start + '&';
    } else {
      query = query + 'closingStartDate=null&';
    }

    if (search.datepicker.closing.end_date != null) {
      returnObj.closing.end = search.datepicker.closing.end_date.format(
        'YYYY-MM-DD'
      );
      query = query + 'closingEndDate=' + returnObj.closing.end + '&';
    } else {
      query = query + 'closingEndDate=null&';
    }
  }

  if (search.hasOwnProperty("name")) {
    if (search.name != null) {
      returnObj.name = search.name;
      query = query + 'name=' + returnObj.name + '&';
    } else {
      query = query + 'name=null&';
    }
  }

  if (search.hasOwnProperty('selectedCountriesIds')) {
    returnObj.countries = search.selectedCountriesIds;
    for (var i = 0; i < returnObj.countries.length; i++) {
      query = query + 'countryId[' + i + ']=' + returnObj.countries[i] + '&';
    }
  }

  if (
    search.hasOwnProperty('selectedEventsSectorIds') &&
    search.selectedEventsSectorIds.length != null
  ) {
    query += search.selectedEventsSectorIds
      .map((id, i) => `&eventsSector[${i}]=${id}`)
      .join('');
  }

  if (
    search.hasOwnProperty('selectedEventsTypeIds') &&
    search.selectedEventsTypeIds.length != null
  ) {
    query += search.selectedEventsTypeIds
      .map((id, i) => `&eventsType[${i}]=${id}`)
      .join('');
  }

  if (
    search.hasOwnProperty('selectedWebinarsSectorIds') &&
    search.selectedWebinarsSectorIds.length != null
  ) {
    query += search.selectedWebinarsSectorIds
      .map((id, i) => `&webinarsSector[${i}]=${id}`)
      .join('');
  }

  if (search.hasOwnProperty('serviceId') && search.serviceId != null) {
    query += treeToQuery(search.serviceId, 'serviceId');
  }

  if (search.hasOwnProperty('countryId') && search.countryId != null) {
    query += treeToQuery(search.countryId, 'countryId');
  }

  if (search.hasOwnProperty('producId')) {
    const productAllId = JSON.parse(search.productId);
    var selectProductId = [];
    var run = 0;
    for (var grandKey in productAllId) {
      //check gp exist
      if (!productAllId.hasOwnProperty(grandKey)) {
        continue;
      }

      //check parent selected checked ADDD
      if (productAllId[grandKey]['checked'] == true) {
        selectProductId[run] = grandKey;
        run++;
        continue;
      }

      //parent code
      if (productAllId[grandKey].hasOwnProperty('children')) {
        for (var parentKey in productAllId[grandKey]['children']) {
          if (!productAllId.hasOwnProperty(grandKey)) {
            continue;
          }

          //check parent selected checked ADDD
          if (productAllId[grandKey]['children'][parentKey]['checked'] == true) {
            selectProductId[run] = parentKey;
            run++;
            continue;
          }

          if (
            productAllId[grandKey]['children'][parentKey].hasOwnProperty(
              'children'
            )
          ) {
            for (var childKey in productAllId[grandKey]['children'][parentKey][
                'children'
              ]) {
              //child code
              if (
                productAllId[grandKey]['children'][parentKey]['children'][
                  childKey
                ]['checked'] == true
              ) {
                selectProductId[run] = childKey;
                run++;
                continue;
              }
            }
          }
        }
      }
    }
    if (selectProductId.length > 0) {
      query += selectProductId.map((id, i) => `&productId[${i}]=${id}`).join('');
    }
  }

  return query;
}


export function applyTender({
  message,
  files
}) {
  var url = '/tender/apply';
  var params = window.location.hash;

  var queryParam = window.location.hash.split('/');

  var id = queryParam[2];

  let formData = new FormData();
  const description = message != undefined ? message : '';
  formData.append('id', id);
  formData.append('message', description);

  for (var i = 0; i < files.length; i++) {
    formData.append('document[]', files[i], files[i].name);
  }

  return (dispatch) => {
    axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        dispatch(dispatchApplyTender('true', res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
        dispatch(dispatchApplyTender('false', {}));
      });
  };
}

function dispatchApplyTender(status, data) {
  return (dispatch) => {
    dispatch({
      type: TENDERAPPLIED,
      payload: data,
    });
    setTimeout(() => {
      dispatch({
        type: TOGGLE_APPLY_SUCCESS,
        payload: false,
      });
    }, 3000);
  };
}

export function applyOffer({
  id,
  message,
  files
}) {
  var url = '/offer/apply';
  var params = window.location.hash;

  var queryParam = window.location.hash.split('/');

  var id = queryParam[2];
  url = url;

  const description = message != undefined ? message : '';
  let formData = new FormData();
  formData.append('id', id);
  formData.append('message', description);

  for (var i = 0; i < files.length; i++) {
    formData.append('document[]', files[i], files[i].name);
  }

  return (dispatch) => {
    axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        dispatch(dispatchApplyOffer('true', res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
        dispatch(dispatchApplyOffer('false', {}));
      });
  };
}

function dispatchApplyOffer(status, data) {
  return (dispatch) => {
    dispatch({
      type: OFFERAPPLIED,
      payload: data,
    });
    setTimeout(() => {
      dispatch({
        type: TOGGLE_APPLY_SUCCESS,
        payload: false,
      });
    }, 3000);
  };
}

export function addBookmark(
  type = null,
  id = null,
  search = null,
  previousUrl = null
) {
  var params = window.location.hash;
  var queryParam = window.location.hash.split('/');
  var path = queryParam[1].split('?');
  if (type == null) {
    var type = queryParam[1];
  }
  if (id == null) {
    var id = queryParam[2];
  }

  var url = '/bookmark/' + type + '/' + id;
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        dispatch(dispatchAddBookmark(type, res.data));
        //  if(search != null && previousUrl!=null){
        if (type == 'offers') {
          dispatch(fetchOffers('#/offers', search));
          dispatch(fetchMyFavorites(previousUrl, search));
        } else if (type == 'tenders') {
          dispatch(fetchTenders('#/tenders', search));
          dispatch(fetchMyFavorites(previousUrl, search));
        } else if (type == 'forums') {
          dispatch(fetchForumTopics(previousUrl));
          dispatch(fetchForumDetails(id));
          dispatch(fetchMyFavorites(previousUrl, search));
        } else {
          dispatch(fetchEvents(window.location.hash));
          dispatch(detailEvent(window.location.hash));
          dispatch(fetchMyFavorites(previousUrl, search));
        }
        //  }
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function addDashboardBookmark(type = null, id = null, tab = null) {
  var url = '/bookmark/' + type + '/' + id;
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        dispatch(dispatchAddBookmark(type, res.data));
        var param = '?order=DESC&orderBy=date';
        if (tab == 'following')
          param = '?order=DESC&orderBy=date&section=following';
        else if (tab == 'closed')
          param = '?order=DESC&orderBy=closedDate&tab=closed';
        if (type == 'offers') {
          dispatch(fetchOffers('#/offers' + param));
        } else if (type == 'tenders') {
          dispatch(fetchTenders('#/tenders' + param));
        } else {
          dispatch(fetchForumTopics('#/forums'));
        }
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function dispatchAddBookmark(type, data) {
  if (type == 'offers') {
    return {
      type: ADD_OFFER_BOOKMARK,
      payload: data,
    };
  } else if (type == 'tenders') {
    return {
      type: ADD_TENDER_BOOKMARK,
      payload: data,
    };
  } else if (type == 'forums') {
    return {
      type: ADD_FORUM_BOOKMARK,
      payload: data,
    };
  } else {
    return {
      type: ADD_EVENT_BOOKMARK,
      payload: data,
    };
  }
}

export function fetchBusinessCircle(param, messageFlag = null) {
  var url = '/business';
  var result = false;

  if (messageFlag != undefined || messageFlag != null) {
    var result = true;
  }

  if (param) {
    var queryParam = queryString.parse(decodeURIComponent(param.split('?')[1]));
    var sortLabel = 'Recent';

    if (queryParam['order'] == 'ASC') sortLabel = 'Oldest';

    if (param.split('?')[1]) var url = url + '?' + param.split('?')[1];
  }
  return (dispatch) => {
    dispatch(purgeMembers({
      memberSection: 'business'
    }));
    return axios
      .get(url)
      .then((res) => {
        var data = res.data;
        data.sortLabel = sortLabel;
        var messageStatus = result;
        data.data.messageShow = messageStatus;
        dispatch(setBusinessCircle(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setBusinessCircle(data) {
  return {
    type: BUSINESS,
    payload: data,
  };
}

export function searchProducts(key = null) {
  if (key != null) {
    var url = '/search/products?searchKey=' + key;
  } else {
    var url = '/search/products?searchKey=';
  }
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        dispatch(updateProducts(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function updateProducts(data) {
  return {
    type: PRODUCT_SEARCH,
    payload: data,
  };
}

export function searchCountries(key = null) {
  if (key != null) {
    var url = '/get/countries';
  } else {
    var url = '/get/countries';
  }

  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        var countries2 = [];
        var countries = res.data.data;
        //countries dropdown
        if (res.data.hasOwnProperty('data')) {
          var i = 0;
          countries2[i] = {};
          countries2[i]['label'] = 'Select a Country';
          countries2[i]['value'] = '';
          i = 1;
          for (var item in countries) {
            countries2[i] = {};
            countries2[i]['label'] = countries[item].country_name;
            countries2[i]['value'] = countries[item].id;
            i++;
          }
        }
        dispatch(updateCountriesOption(countries2));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function updateCountriesOption(data) {
  return {
    type: COUNTRY_SEARCH,
    payload: data,
  };
}

export function doOfferErrors(type, data) {
  return {
    type: HANDLE_OFFER_ERROR,
    payload: data,
  };
}

export function setEventPreview(
  tab1,
  tab2,
  titleImage,
  galleryImage,
  photos,
  countries,
  attachments,
  sectors
) {
  var countryName = getCountryName(countries, tab1['location']);
  if (tab1['feature'] == 0) {
    var text = 'Feature Event';
  } else {
    var text = 'Not Featured';
  }

  var details = {};

  details['tags'] = [];
  details['tags'].type = 'default';
  details['tags'].text = text;

  details['region'] = {};

  details['region'].name = tab1.city + ', ' + countryName;

  var startdate = tab1['startTime'].date;
  var starttime = tab1['startTime'].time;

  var enddate = tab1['endTime'].date;
  var endtime = tab1['endTime'].time;

  if (Date.parse(startdate) == Date.parse(enddate)) {
    //moment(endtime, moment.ISO_8601).format('ddd, MMM Do HH:MMa');
    var startDateMoment = moment(starttime).format('llll');
    var endDateMoment = moment(endtime).format('llll');
    var startDateSec = Date.parse(startDateMoment);
    var endDateSec = Date.parse(endDateMoment);
  } else {
    var endDateSec = Date.parse(enddate);
    var startDateSec = Date.parse(startdate);
  }

  if (tab1.city == undefined) {
    var location = '';
  } else {
    var location = tab1.city + ' ' + countryName;
  }

  details['title'] = tab1['title'];
  details['lat'] = tab1['maplocation'].lat;
  details['lng'] = tab1['maplocation'].lng;
  details['start'] = startDateSec;
  details['end'] = endDateSec;
  details['description'] = tab2['teaser'];
  details['image'] = galleryImage[0]['preview'];
  details['bigImage'] = titleImage[0]['preview'];
  details['editorHtml'] = tab2.editor;
  details['galleryImages'] = galleryImage.map(({
    preview
  }) => ({
    id: preview,
    thumb: preview,
  }));
  details['supportedBy'] = photos.map(({
    preview
  }) => preview);

  details['files'] = [];
  for (var i = 0; i < attachments.length; i++) {
    details['files'][i] = {};
    details['files'][i]['name'] = attachments[i]['name'];
    details['files'][i]['size'] = String(attachments[i]['size']);
  }

  var sector = sectors
    .filter((option) => tab1['sector'].includes(option.value))
    .map((option) => option.label)
    .join(', ');

  if (webinarTypeIsChosen(tab1['type'])) {
    details['details'] = [{
        name: 'Date',
        value: moment(details['start']).format('DD MMM. YYYY') +
          ' - ' +
          moment(details['end']).format('DD MMM. YYYY'),
      },
      {
        name: 'Type',
        value: tab1['type'].label
      },
      {
        name: 'Sector',
        value: sector
      },
      {
        name: 'Website',
        value: {
          link: tab1['website']
        }
      },
    ];
  } else {
    details['details'] = [{
        name: 'Date',
        value: moment(details['start']).format('DD MMM. YYYY') +
          ' - ' +
          moment(details['end']).format('DD MMM. YYYY'),
      },
      {
        name: 'Type',
        value: tab1['type'].label
      },
      {
        name: 'Sector',
        value: sector
      },
      {
        name: 'Website',
        value: {
          link: tab1['website']
        }
      },
      {
        name: 'Where',
        value: location
      },
    ];
  }

  return {
    type: SET_EVENT_PREVIEW,
    payload: details,
  };
}

export function createEvent(
  data,
  titleImage,
  galleryImage,
  sponsors,
  attachments
) {
  var tab1 = data[1]['values'];
  var tab2 = data[2]['values'];

  var imageTitle = {};

  var latitude = tab1['maplocation'].lat;
  var longitude = tab1['maplocation'].lng;
  var startdate = tab1['startTime'].date;
  var enddate = tab1['endTime'].date;
  var starttime = tab1['startTime'].time;
  var endtime = tab1['endTime'].time;

  var localStartDate = new Date(startdate);
  var localEndDate = new Date(enddate);

  var localStart = new Date(starttime);
  var localEnd = new Date(endtime);

  if (!tab1['website'].match(/^[a-zA-Z]+:\/\//)) {
    tab1['website'] = 'http://' + tab1['website'];
  }

  if (!tab1['applyUrl'].match(/^[a-zA-Z]+:\/\//)) {
    tab1['applyUrl'] = 'http://' + tab1['applyUrl'];
  }

  let formData = new FormData();
  formData.append('title', tab1['title']);

  formData.append('latitude', latitude);
  formData.append('longitude', longitude);
  formData.append('type', tab1['type']['value']);
  formData.append('organizer', tab1['organizer']);
  formData.append('countryId', tab1['location']);
  formData.append('city', tab1['city']);
  formData.append('startDate', localStartDate);
  formData.append('endDate', localEndDate);
  formData.append('startTime', localStart);
  formData.append('endTime', localEnd);
  formData.append('sector', tab1['sector']);
  formData.append('website', tab1['website']);
  formData.append('applyUrl', tab1['applyUrl']);
  formData.append('teaser', tab1['teaser']);
  formData.append('description', tab2.editor);

  formData.append(
    'teaser',
    tab2['teaser'] != undefined ? tab2['teaser'] : null
  );
  //formData.append('description',  (tab2['description'] != undefined)?JSON.stringify(tab2['description']):null);

  for (var i = 0; i < titleImage.length; i++) {
    imageTitle[i] = titleImage[i];
    formData.append('imageTitle[]', titleImage[i], titleImage[i].name);
  }

  for (var i = 0; i < galleryImage.length; i++) {
    formData.append('photos[]', galleryImage[i], galleryImage[i].name);
  }

  for (var i = 0; i < sponsors.length; i++) {
    formData.append('sponsor[]', sponsors[i], sponsors[i].name);
  }

  for (var i = 0; i < attachments.length; i++) {
    formData.append('document[]', attachments[i], attachments[i].name);
  }

  //save offers
  return (dispatch) => {
    axios
      .post('/event', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        dispatch(eventCreateSuccess(res.data));
      })
      .catch(function (error) {
        var returnData = {};
        returnData.errors = error.response.data.errors;
        returnData.hasError = true;
        returnData.message = error.response.data.message;
        dispatch(doeventErrors('SAVE', returnData));
        dispatch(push('/events/create?step=1'));
      });
  };
}

function eventCreateSuccess(data) {
  return {
    type: ADD_EVENT_SUCCESS,
    payload: data,
  };
}

export function setOfferPreview(
  tab1,
  tab2,
  attachments,
  photos,
  countries,
  user
) {
  var countryName = getCountryName(countries, tab1['location']);
  var details = {};

  details['tags'] = [];
  details['tags'].type = 'default';
  details['tags'].text = '00000';

  details['region'] = {};
  details['region'].name = tab1.city + ', ' + countryName;

  details['title'] = tab1['title'];

  details['userNameWithAvatar'] = {};
  if(user)
  {
  details['userNameWithAvatar'].name = user.displayName;
  details['userNameWithAvatar'].location = user.location;
  }

  details['features'] = {};
  details['features']['title'] = 'Details';
  details['features']['list'] = tab2['conditions'];
  details['singleName'] = 'Offer';

  var i = 0;
  details['specsOnRight'] = [];

  if (tab1.hasOwnProperty('features')) {
    var fieldKeys = tab1['features']['fields'];
    for (let key in fieldKeys) {
      details['specsOnRight'][i] = {};
      details['specsOnRight'][i]['label'] = fieldKeys[key]['label'];
      details['specsOnRight'][i]['value'] = tab1[key];
      i++;
    }
  }

  details['specsOnRight'][i] = {};
  details['specsOnRight'][i]['label'] = 'Seller';
  if(user)
  {
  details['specsOnRight'][i]['value'] = user.displayName;
  }

  //images preview
  var images = '';
  for (var i = 0; i < photos.length; i++) 
  {
    images = images + ' ' + '<img src="' + photos[i]['preview'] + '" alt=""/>';
  }
  details['content'] = images + tab2['description'];

  //files
  details['files'] = [];
  for (var i = 0; i < attachments.length; i++) {
    details['files'][i] = {};
    details['files'][i]['name'] = attachments[i]['name'];
    details['files'][i]['size'] = String(attachments[i]['size']);
  }

  return {
    type: SET_OFFER_PREVIEW,
    payload: details,
  };
}

function getCountryName(countries, id) {
  for (var item in countries) {
    if (countries[item]['value'] == id) {
      return countries[item]['label'];
    }
  }

  return '';
}

export function fetchReviews(param, page, newReviewFlag) {
  var url = '/reviews';
  if (param) {
    var queryParam = param.split('/');
    if (queryParam[3] && queryParam[4])
      var url = url + '/' + queryParam[3] + '/' + queryParam[4];
  }
  if (page) {
    url = url + '?page=' + page / 3;
  }
  if (!newReviewFlag) newReviewFlag = 0;
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        dispatch(
          setReviews(res.data, queryParam[3], queryParam[4], newReviewFlag)
        );
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setReviews(data, userType, id, newReviewFlag) {
  return {
    type: REVIEWS,
    payload: data,
    userType: userType,
    id: id,
    newReviewFlag: newReviewFlag,
  };
}

export function addReview(param, formParam) {
  var url = '/review';
  var queryParam = param.split('/');
  var rating = 0;
  if (formParam.rating) rating = formParam.rating;
  var userId = queryParam[4].split('?');
  var data = {
    id: userId[0],
    type: queryParam[3],
    comment: formParam.newCommentText,
    rating: rating,
  };
  return (dispatch) => {
    axios
      .post(url, data)
      .then((res) => {
        dispatch(fetchReviews(window.location.hash, 0, 1));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function createOffer(data, attachments, photos) {
  var tab1 = data[1]['values'];
  var tab2 = data[2]['values'];
  var details = [];

  if (tab1.hasOwnProperty('features')) {
    //features
    var fieldKeys = tab1['features']['fields'];
    var i = 0;
    for (let key in fieldKeys) {
      details[i] = {};
      details[i]['label'] = fieldKeys[key]['label'];
      details[i]['value'] = tab1[key];
      i++;
    }
  }
  var documents = {};
  var photo = {};

  let formData = new FormData();
  formData.append('title', tab1['title']);

  formData.append('countryId', tab1['location']);
  formData.append('city', tab1['city']);
  formData.append('subjectType', tab1['subjectType']);
  formData.append('product', tab1['product']);
  formData.append('service', tab1['service']);
  formData.append(
    'description',
    tab2['description'] != undefined ? tab2['description'] : null
  );
  formData.append(
    'condition',
    tab2['conditions'] != undefined ? JSON.stringify(tab2['conditions']) : null
  );
  formData.append(
    'allMembers',
    tab2['allMembers'] != undefined ? tab2['allMembers'] : null
  );

  for (var i = 0; i < attachments.length; i++) {
    documents[i] = attachments[i];
    formData.append('document[]', attachments[i], attachments[i].name);
  }

  for (var i = 0; i < photos.length; i++) {
    formData.append('photo[]', photos[i], photos[i].name);
  }

  for (var i = 0; i < details.length; i++) {
    formData.append('feature[' + i + '][label]', details[i]['label']);
    formData.append('feature[' + i + '][value]', details[i]['value']);
  }

  //save offers
  return (dispatch) => {
    axios
      .post('/offer', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        dispatch(offerCreateSuccess(res.data));
      })
      .catch(function (error) {
        var returnData = {};
        returnData.errors = error.response.data.errors;
        returnData.hasError = true;
        returnData.message = error.response.data.message;
        dispatch(doOfferErrors('SAVE', returnData));
        dispatch(handleApiErrors(dispatch, error));
        dispatch(push('/offers/create?step=1'));
      });
  };
}

function offerCreateSuccess(data) {
  return {
    type: ADD_OFFER_SUCCESS,
    payload: data,
  };
}

export function doTenderErrors(type, data) {
  return {
    type: HANDLE_TENDER_ERROR,
    payload: data,
  };
}

export function setTenderPreview(
  tab1,
  tab2,
  attachments,
  photos,
  countries,
  user
) {
  var countryName = getCountryName(countries, tab1['location']);
  var details = {};

  details['tags'] = [];
  details['tags'].type = 'default';
  details['tags'].text = '00000';

  details['region'] = {};
  details['region'].name = tab1.city + ', ' + countryName;

  details['title'] = tab1['title'];

  details['dates'] = {};
  details['dates']['opening'] = tab1['openingDate'];
  details['dates']['closing'] = tab1['closingDate'];

  details['userNameWithAvatar'] = {};
  details['userNameWithAvatar'].name = user.displayName;
  details['userNameWithAvatar'].location = user.location;

  details['features'] = {};
  details['features']['title'] = 'Conditions';
  details['features']['list'] = tab2['conditions'];

  details['value'] = 'NA';
  details['applied'] = 0;

  details['specsOnRight'] = [];

  details['specsOnRight'][1] = {};
  details['specsOnRight'][1]['label'] = 'Manufacturer country';
  details['specsOnRight'][1]['value'] = user.location;

  details['specsOnRight'][2] = {};
  details['specsOnRight'][2]['label'] = 'Seller';
  details['specsOnRight'][2]['value'] = user.displayName;

  //images preview
  var images = '';
  for (var i = 0; i < photos.length; i++) {
    images = images + ' ' + '<img src="' + photos[i]['preview'] + '" alt=""/>';
  }
  details['content'] = images + tab2['description'];

  //files
  details['files'] = [];
  for (var i = 0; i < attachments.length; i++) {
    details['files'][i] = {};
    details['files'][i]['name'] = attachments[i]['name'];
    details['files'][i]['size'] = String(attachments[i]['size']);
  }

  return {
    type: SET_TENDER_PREVIEW,
    payload: details,
  };
}

export function createTender(data, attachments, photos) {
  var tab1 = data[1]['values'];
  var tab2 = data[2]['values'];

  var documents = {};
  var photo = {};

  let formData = new FormData();
  formData.append('subjectType', tab1['subjectType']);
  formData.append('title', tab1['title']);
  formData.append('price', tab1['price']);
  formData.append('countryId', tab1['location']);
  formData.append('city', tab1['city']);
  formData.append('product', tab1['product']);
  formData.append('service', tab1['service']);
  formData.append('openingDate', tab1['openingDate']);
  formData.append('closingDate', tab1['closingDate']);

  formData.append(
    'description',
    tab2['description'] != undefined ? tab2['description'] : null
  );
  formData.append(
    'condition',
    tab2['conditions'] != undefined ? JSON.stringify(tab2['conditions']) : null
  );
  formData.append(
    'allMembers',
    tab2['allMembers'] != undefined ? tab2['allMembers'] : null
  );

  for (var i = 0; i < attachments.length; i++) {
    formData.append('document[]', attachments[i], attachments[i].name);
  }

  for (var i = 0; i < photos.length; i++) {
    formData.append('photo[]', photos[i], photos[i].name);
  }

  //save offers
  return (dispatch) => {
    axios
      .post('/tender', formData)
      .then((res) => {
        dispatch(tenderCreateSuccess(res.data));
      })
      .catch(function (error) {
        var returnData = {};
        returnData.errors = error.response.data.errors;
        returnData.hasError = true;
        returnData.message = error.response.data.message;

        dispatch(handleApiErrors(dispatch, error));
        dispatch(doTenderErrors('SAVE', returnData));
        dispatch(push('/tenders/create?step=1'));
      });
  };
}

function tenderCreateSuccess(data) {
  return {
    type: ADD_TENDER_SUCCESS,
    payload: data,
  };
}

export function notificationCount(userId) {
  return (dispatch) => {
    
     //const SOCKET_IO_HOST='https://shetrades.com:3000';

    //var socket = socketIOClient(SOCKET_IO_HOST);

    // socket.on(
    //   'bell-notification-' + userId + ':App\\Events\\BellNotification',
    //   function (message) {
    //     if (userId == message.data.user_id) {
    //       if (parseInt(message.data.count) > 0) {
    //         dispatch(notificationHandle(message.data.count));
    //       } else {
    //         dispatch(notificationHandle(0));
    //       }
    //     } else {
    //       dispatch(notificationHandle(0));
    //     }
    //   }
    // );
  };
}

function notificationHandle(bellCount) {
  return {
    type: PROFILE_BELL_NOTIFICATION_COUNT,
    payload: bellCount,
  };
}

export function fetchTopFilterEventsType() {
  return (dispatch) => {
    axios
      .get('/get/events/type', {
        headers: config
      })
      .then((res) => {
        dispatch(setEventsTypeFilterOptions(res.data.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setEventsTypeFilterOptions(data) {
  return {
    type: SET_EVENTS_TYPE_FILTER_OPTIONS,
    payload: data,
  };
}

export function fetchTopFilterEventsSector() {
  return (dispatch) => {
    axios
      .get('/get/events/sector', {
        headers: config
      })
      .then((res) => {
        dispatch(setEventsSectorFilterOptions(res.data.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setEventsSectorFilterOptions(data) {
  return {
    type: SET_EVENTS_SECTOR_FILTER_OPTIONS,
    payload: data,
  };
}

export function fetchTopFilterCountries() {
  return (dispatch) => {
    axios
      .get('/countries/options', {
        headers: config
      })
      .then((response) => {
        dispatch(setCountriesFilterOptions(response.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setCountriesFilterOptions(data) {
  return {
    type: SET_COUNTRIES_FILTER_OPTIONS,
    payload: data,
  };
}

export function fetchNews(param) {
  var url = '/news';
  if (param) {
    var queryParam = queryString.parse(decodeURIComponent(param.split('?')[1]));
    var sortLabel = 'Recent';
    if (queryParam['order'] == 'ASC') sortLabel = 'Oldest';
    if (param.split('?')[1]) var url = url + '?' + param.split('?')[1];
  }

  return (dispatch, getState) => {
    const lan = getState().language.lan;
    url = url +'&lan='+lan;
    const options = languageHeader(lan);
    return axios
      .get(url, options)
      .then((res) => {
        var data = res.data;
        data.sortLabel = sortLabel;

        dispatch(setNews(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setNews(data) {
  return {
    type: NEWS,
    payload: data,
  };
}

export function fetchDetailNews(param) {
  var url = '/news';
  if (param) {
    var queryParam = queryString.parse(decodeURIComponent(param.split('/')[2]));
    if (param.split('/')[2]) var url = url + '/' + param.split('/')[2];
  }
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        dispatch(setDetailNews(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setDetailNews(data) {
  return {
    type: DETAIL_NEWS,
    payload: data,
  };
}

export function fetchProjectPages() {
  var url = '/projectpages';
  return (dispatch, getState) => {
    const lan = getState().language.lan;
    const options = languageHeader(lan);
    return axios
      .get(url, options)
      .then((res) => {
        var data = res.data;
        data.sortLabel = '';
        dispatch(setProjectPages(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setProjectPages(data) {
  return {
    type: PROJECTPAGES,
    payload: data,
  };
}

export function fetchDetailProjectPages(param) {
  
  /*
  var url = '/projectpages';
  if (param) {
    url = url + '/' + param;
  }
  console.log(url);
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        dispatch(setDetailProjectPages(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
  */
 
  var url = '/get/countries';

  console.log(url);
  

  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        dispatch(setDetailProjectPages(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
 

}

function setDetailProjectPages(data) {
  return {
    type: DETAIL_PROJECTPAGES,
    payload: data,
  };
}

export function fetchDetailHubPages(param) {
  var url = '/hubpages';
  if (param) {
    url = url + '/' + param;
  }
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        dispatch(setDetailHubPages(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setDetailHubPages(data) {
  return {
    type: DETAIL_HUBPAGES,
    payload: data,
  };
}

export function toggleApplySuccessMessage(isOpened) {
  return {
    type: TOGGLE_APPLY_SUCCESS,
    payload: isOpened,
  };
}

export function toggleDetailApply(isOpened) {
  return {
    type: TOGGLE_DETAIL_APPLY,
    payload: isOpened,
  };
}

function previewMode(error) {
  return {
    type: PREVIEW_ACCESS_ERROR,
    payload: error,
  }
}

function doNothing(error) {
  //TODO- consider logging errors like this
  return {
    type: DO_NOTHING,
    payload: 'no action',
  }
}

export function handleApiErrors(dispatch, error) {
  if (error) {
    if (error.hasOwnProperty('response') && error.response.status === 403) {
      dispatch(previewMode(error));
    } else if (
      error.hasOwnProperty('response') &&
      error.response.status === 401
    ) {
      dispatch(logout);
    } else {
      dispatch(doNothing(error));
    }
  } else {
    dispatch(doNothing(error));
  }
}

export function fetchTagList() {
  return (dispatch) => {
    axios
      .get('/forum/tags')
      .then((res) => {
        const tags = res.data;
        dispatch(setTagList(res.data));
      })
      .catch(function (error) {
        console.log('tags error');
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setTagList(tags) {
  return {
    type: FORUM_TAGS,
    payload: tags,
  };
}

export function fetchForumTopics(param) {
  var url = '/forums?';
  var tab = 'all';
  var forum = '';
  var page = 0;
  var sortLabel = 'NEWEST';
  if (param) {
    var queryParam = queryString.parse(decodeURIComponent(param.split('?')[1]));
    if (queryParam['order'] == 'ASC') {
      sortLabel = 'OLDEST';
    }
    if (queryParam['tab']) {
      tab = queryParam['tab'];
    }
    if (queryParam['forum']) {
      forum = queryParam['forum'];
    }
    if (queryParam['page']) {
      page = queryParam['page'];
    }

    if (param.split('?')[1]) {
      url = url + param.split('?')[1];
    }
    return (dispatch) => {
      return axios
        .get(url)
        .then((res) => {
          const data = res.data;
          data.sortLabel = sortLabel;
          dispatch(setForumTopics(data));
        })
        .catch(function (error) {
          console.log(error.response.data.message);
          dispatch(handleApiErrors(dispatch, error));
        });
    };
  }
}

function setForumTopics(topics) {
  return {
    type: FORUM_TOPIC,
    payload: topics,
  };
}

export function fetchForumDetails(id) {
  var url = '/forum/details/' + id;
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        const data = res.data;
        data.url = window.window.location.href;
        dispatch(setForumDetails(data));
      })
      .catch(function (error) {
        console.log('details error');
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setForumDetails(details) {
  return {
    type: FORUM_DETAIL,
    payload: details,
  };
}

export function fetchAnswers(id, page) {
  var url = '/forum/answers/' + id;

  if (page) {
    page = page;
    var queryParam = id.split('/');
    url = '/forum/answers/' + queryParam[2] + '?page=' + page / 3;
  }
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        const data = res.data;

        data.page = page;
        dispatch(setAnswers(data));
      })
      .catch(function (error) {
        console.log('fetch ans error');
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setAnswers(answers, page) {
  return {
    type: FORUM_ANSWERS,
    payload: answers,
    page: page,
  };
}

export function addAnswer(param, formParam, page) {
  var url = '/forum/addAnswer';
  var queryParam = param.split('/');
  var answerData = {
    id: queryParam[2],
    answer: formParam.newCommentText,
  };
  return (dispatch) => {
    axios
      .post(url, answerData)
      .then((res) => {
        dispatch(fetchAllAnswers(queryParam[2]), 0);
      })
      .catch(function (error) {
        console.log('add comment error');
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function formTags() {
  return (dispatch) => {
    axios
      .get('/forum/tagsOptions')
      .then((res) => {
        const tags = res.data;
        dispatch(setFormTagLists(res.data));
      })
      .catch(function (error) {
        console.log('tags error');
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setFormTagLists(tagOptions) {
  return {
    type: FORM_TAG_OPTIONS,
    payload: tagOptions,
  };
}

export function forumErrors(type, data) {
  return {
    type: HANDLE_FORUM_ERROR,
    payload: data,
  };
}

export function createForumTopic(data) {
  let tags = [];
  let tagsSelected;
  let formData = new FormData();
  formData.append('title', data['name']);
  formData.append('description', data['description']);
  formData.append('isPublic', data['forumMembers']);

  var tagOptions = data['forums'];
  for (var i = 0; i < tagOptions.length; i++) {
    tags.push(tagOptions[i].split(','));
  }
  tagsSelected = tags.join(',');
  formData.append('tag_id', tags);
  return (dispatch) => {
    axios
      .post('/forum/addTopic', formData)
      .then((res) => {
        dispatch(createForumSuccess(res.data));
      })
      .catch(function (error) {
        var returnData = {};
        returnData.errors = error.response.data.errors;
        returnData.hasError = true;
        returnData.message = error.response.data.message;
        dispatch(forumErrors('SAVE', returnData));
        dispatch(push('/forums/create'));
      });
  };
}

function createForumSuccess(result) {
  return {
    type: CREATE_FORUM_SUCCESS,
    payload: result,
  };
}

export function fetchVideos(param) {
  var url = '/get/videos';
  if (param) {
    var queryParam = queryString.parse(decodeURIComponent(param.split('?')[1]));
    var sortLabel = 'Recent';
    if (queryParam['order'] == 'ASC') sortLabel = 'Oldest';
    if (param.split('?')[1]) var url = url + '?' + param.split('?')[1];
  }
  return (dispatch, getState) => {
    const lan = getState().language.lan;
    const options = languageHeader(lan);
    console.log(url);
    console.log(options);
    return axios
      .get(url, options)
      .then((res) => {
        var data = res.data;
        data.sortLabel = sortLabel;
        dispatch(setVideos(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setVideos(data) {
  return {
    type: VIDEOS,
    payload: data,
  };
}

export function fetchVideoDetails(param) {
  var url = '/video';
  if (param) {
    var queryParam = param.split('/');
    if (queryParam[3]) var url = url + '/' + queryParam[3];
  }
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        dispatch(setVideoDetails(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setVideoDetails(data) {
  return {
    type: VIDEO_DETAILS,
    payload: data,
  };
}

export function addSubComment(param, id, comment, page) {
  var queryParam = param.split('/');
  var commentData = {
    topicId: queryParam[2],
    answerId: id,
    comment: comment,
  };
  return (dispatch) => {
    axios
      .post('/forum/addComment', commentData)
      .then((res) => {
        dispatch(fetchAllAnswers(queryParam[2], page));
      })
      .catch(function (error) {
        console.log('add comment error');
      });
  };
}
export function fetchAllAnswers(id, page) {
  var url = '/forum/answers/' + id;
  if (page) {
    page = page / 3;
    url = '/forum/answers/' + id + '?page=' + page + '&all=1';
  }
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        const data = res.data;
        dispatch(setAllAnswers(data));
      })
      .catch(function (error) {
        console.log('fetch all error');
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setAllAnswers(answers) {
  return {
    type: FORUM_ALL_ANSWERS,
    payload: answers,
  };
}

export function fetchPublications(param) {
  var url = '/publications';
  if (param) {
    var queryParam = queryString.parse(decodeURIComponent(param.split('?')[1]));
    var sortLabel = 'Recent';
    if (queryParam['order'] == 'ASC') sortLabel = 'Oldest';
    if (param.split('?')[1]) var url = url + '?' + param.split('?')[1];
  }
  return (dispatch, getState) => {
    const lan = getState().language.lan;
    const options = languageHeader(lan);
    return axios
      .get(url, options)
      .then((res) => {
        var data = res.data;
        data.sortLabel = sortLabel;
        dispatch(setPublications(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setPublications(data) {
  return {
    type: PUBLICATIONS,
    payload: data,
  };
}

export function fetchPublicationDetails(param) {
  var url = '/publication';
  if (param) {
    var queryParam = param.split('/');
    if (queryParam[2]) var url = url + '/' + queryParam[2];
  }
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        dispatch(setPublicationDetails(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setPublicationDetails(data) {
  return {
    type: PUBLICATION_DETAILS,
    payload: data,
  };
}

export function fetchStory(param) {
  var url = '/successStories';
  if (param) {
    var queryParam = queryString.parse(decodeURIComponent(param.split('?')[1]));
    var sortLabel = 'Recent';
    if (queryParam['order'] == 'ASC') sortLabel = 'Oldest';
    if (param.split('?')[1]) var url = url + '?' + param.split('?')[1];
  }
  return (dispatch, getState) => {
    const lan = getState().language.lan;
    const options = languageHeader(lan);
    axios
      .get(url, options)
      .then((res) => {
        var data = res.data;
        data.sortLabel = sortLabel;
        dispatch(setStory(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setStory(data) {
  return {
    type: SUCCESSTORY,
    payload: data,
  };
}

export function fetchDetailsStory(param) {
  var url = '/successStory';
  if (param) {
    var queryParam = param.split('/');
    if (queryParam[2]) var url = url + '/' + queryParam[2];
  }
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        dispatch(setStoryDetails(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setStoryDetails(data) {
  return {
    type: SUCCESSTORY_DETAILS,
    payload: data,
  };
}

export function getAllMarketTools() {
  return (dispatch, getState) => {
    const lan = getState().language.lan;
    const options = languageHeader(lan);
    axios
      .get('/marketTools', options,
      {
        headers: config
      })
      .then((res) => {
        const data = res.data;
        dispatch(setMarketTools(data));
      })
      .catch(function (error) {
        console.log('Market-Tools error');
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setMarketTools(marketTools) {
  return {
    type: MARKET_TOOLS,
    payload: marketTools,
  };
}
export function fetchVideoComments(param, page, newReviewFlag, limit) {
  var url = '/video/comment';
  if (param) {
    var queryParam = param.split('/');
    if (queryParam[3]) var url = url + '/' + queryParam[3];
  }
  if (page) {
    url = url + '?page=' + page / 3;
    if (limit) {
      url = url + '&limit=' + limit;
    }
  } else if (limit) {
    url = url + '?limit=' + limit;
  }
  if (!newReviewFlag) newReviewFlag = 0;
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        dispatch(setVideoComments(res.data, queryParam[3], newReviewFlag));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setVideoComments(data, id, newReviewFlag) {
  return {
    type: VIDEO_COMMENTS,
    payload: data,
    videoId: id,
    newReviewFlag: newReviewFlag,
  };
}

export function addVideoComments(param, formParam, parentId) {
  var url = '/video/comment';
  var queryParam = param.split('/');
  if (!parentId) {
    parentId = 0;
  }
  var data = {
    videoId: queryParam[3],
    comment: formParam.newCommentText,
    parentId: parentId,
  };
  return (dispatch) => {
    axios
      .post(url, data)
      .then((res) => {
        var data = res.data;
        dispatch(fetchVideoComments(window.location.hash, 0, 1));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function addSubVideoComment(param, parentId, comment, page) {
  var url = '/video/comment';
  var queryParam = param.split('/');
  var commentData = {
    videoId: queryParam[3],
    comment: comment,
    parentId: parentId,
  };

  return (dispatch) => {
    axios
      .post(url, commentData)
      .then((res) => {
        dispatch(fetchVideoComments(window.location.hash, 0, 1, page));
      })
      .catch(function (error) {
        console.log('add comment error');
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function fetchMyItems(param, search) {
  var url = '/my-items?';
  if (param) {
    var queryParam = queryString.parse(decodeURIComponent(param.split('?')[1]));
    var sortLabel = 'Newest';
    if (queryParam['tab'] == 'forums') {
      sortLabel = 'Oldest';
    }
    if (queryParam['orderBy'] == 'date') {
      if (queryParam['order'] == 'ASC') sortLabel = 'Oldest';
      else sortLabel = 'Newest';
    } else if (queryParam['order'] == 'DESC') {
      sortLabel = 'Value High to Low';
    }
    var zeroParam = param.split('?')[0];
    if (param.split('?')[1] && param.indexOf('#') !== -1) {
      url = url + param.split('?')[1] + '&';
    } else if (
      param.indexOf('#') == -1 &&
      zeroParam.indexOf('my-items') == -1
    ) {
      url = url + param;
    } else if (zeroParam.indexOf('my-items') !== -1) {
      url = url + param.split('?')[1] + '&';
    }
  }

  if (search !== undefined) {
    let decodeFilter = decodeFilters(search);
    if (url.indexOf('?') !== -1) {
      url = url + '&' + decodeFilter;
    } else {
      decodeFilter = (decodeFilter.charAt(0) === "&") ? decodeFilter.substring(1) : decodeFilter;
      url = url + '?' + decodeFilter;
    }
  }
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        data.sortLabel = sortLabel;
        dispatch(setMyItem(data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setMyItem(data) {
  return {
    type: SET_MY_ITEMS,
    payload: data,
  };
}

export function fetchMyFavorites(param, search) {
  var url = '/my-favorites?';
  if (param) {
    var queryParam = queryString.parse(decodeURIComponent(param.split('?')[1]));
    var sortLabel = 'Newest';
    if (queryParam['tab'] == 'forums') {
      sortLabel = 'Oldest';
    }

    if (queryParam['orderBy'] == 'date') {
      if (queryParam['order'] == 'ASC') sortLabel = 'Oldest';
      else sortLabel = 'Newest';
    } else if (queryParam['order'] == 'DESC') {
      sortLabel = 'Value High to Low';
    }
    var zeroParam = param.split('?')[0];
    if (param.split('?')[1] && param.indexOf('#') !== -1) {
      url = url + param.split('?')[1] + '&';
    } else if (
      param.indexOf('#') === -1 &&
      zeroParam.indexOf('my-favorites') == -1
    ) {
      url = url + param;
    } else if (zeroParam.indexOf('my-favorites') !== -1) {
      url = url + param.split('?')[1] + '&';
    }
  }

  if (search !== undefined) {
    let decodeFilter = decodeFilters(search);
    if (url.indexOf('?') !== -1) {
      url = url + '&' + decodeFilter;
    } else {
      decodeFilter = (decodeFilter.charAt(0) === "&") ? decodeFilter.substring(1) : decodeFilter;
      url = url + '?' + decodeFilter;
    }
  }
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        data.sortLabel = sortLabel;
        dispatch(setMyFavorites(data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setMyFavorites(data) {
  return {
    type: SET_MY_FAVORITES,
    payload: data,
  };
}

export function fetchSettingsGeneral() {
  var url = '/setting/general';
  return (dispatch) => {
    dispatch(resetTabs());
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        dispatch(setSettingTab('general', data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setSettingTab(tab, data) {
  switch (tab) {
    case 'general':
      return {
        type: SET_GENERAL_TAB,
          payload: data,
      };

    case 'details':
      return {
        type: SET_DETAILS_TAB,
          payload: data,
      };

    case 'verifier':
      return {
        type: SET_VERIFIER_TAB,
          payload: data,
      };
    case 'services':
      return {
        type: SET_SERVICES_TAB,
          payload: data,
      };
    case 'commitment':
      return {
        type: SET_COMMITMENTS,
          payload: data,
      };
    case 'certificates':
      return {
        type: SET_CERTIFICATES,
          payload: data,
      };

    default:
      return {
        type: DO_NOTHING,
          payload: 'No action',
      };
  }
}

export function resetTabs() {
  return {
    type: RESET_TABS,
  };
}

export function saveGeneralData(values) {
  var url = '/setting/general';
  var newsLetter = 0;
  if (values.subscribeToNewsletter === true) {
    newsLetter = 1;
  }
  return (dispatch) => {
    let formData = new FormData();

    formData.append('phone', values.currentPhoneNumber);
    formData.append('additionalPhone', values.additionalPhoneNumber);
    formData.append('email', values.currentEmail);
    formData.append('additionalEmail', values.additionalEmail);
    if (values.currentPassword != undefined) {
      formData.append('oldPassword', values.currentPassword);
      formData.append('newPassword', values.newPassword);
      formData.append('confirmPassword', values.newPasswordConfirm);
    }
    formData.append('newsLetterSubcription', newsLetter);

    axios
      .post(url, formData)
      .then(function (response) {
        dispatch(setUpdateSuccess(true));
        return {
          type: DO_NOTHING,
          payload: 'string',
        };
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function fetchSettingsDetails() {
  var url = '/setting/detail';
  return (dispatch) => {
    dispatch(resetTabs());
    return axios
      .get(url)
      .then((res) => {
        var data = res.data;
        dispatch(setSettingTab('details', data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function saveDetailsData(values, gallery) {
  var primaryCustomers = '',
    customers = '';
  if (values.primaryCustomers.length > 0) {
    for (var i = 0; i < values.primaryCustomers.length; i++) {
      primaryCustomers += values.primaryCustomers[i] + '+';
    }
    customers = primaryCustomers.substring(0, primaryCustomers.length - 1);
  }
  return (dispatch) => {
    let formData = new FormData();

    formData.append('annualExportValue', values.annualValueOfExports);
    formData.append('annualValueOfSales', values.annualValueOfSales);
    formData.append('yearOfExporting', values.yearsOfExportingExperience);
    formData.append('experienceInExporting', values.hasExportExperience);
    formData.append('managedByWomen', values.managedByWomen);
    formData.append('noOfManagementTeam', values.noOfManagementTeam);
    formData.append('noOfWomenManagementTeam', values.noOfWomenManagementTeam);
    formData.append('noOfEmployees', values.numberOfEmployees);
    formData.append('noOfWomenEmployees', values.numberOfEmployeesWomen);
    formData.append('primaryCustomers', customers);

    for (var i = 0; i < gallery.length; i++) {
      if (gallery[i] instanceof File) {
        formData.append('document[]', gallery[i], gallery[i].name);
        formData.append('newGallery[]', gallery[i], gallery[i].name);
      } else {
        formData.append('gallery[]', gallery[i].id);
      }
    }

    axios
      .post('/setting/detail', formData)
      .then(function (response) {
        dispatch(fetchSettingsDetails());
        dispatch(setUpdateSuccess(true));
        dispatch(fetchProgressProgess());
        return {
          type: DO_NOTHING,
          payload: 'string',
        };
      })
      .catch(function (error) {
        var returnData = {};
        returnData.errors = error.response.data;
        returnData.hasError = true;
        returnData.message = error.response.data.message;
        dispatch(doSettingErrors(returnData));
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function fetchSettingsMain() {
  var url = '/setting/main';
  return (dispatch) => {
    dispatch(resetTabs());
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        dispatch(setSettingTab('details', data));
        dispatch(fetchProgressProgess());
        dispatch(updateUsersAvatar(data.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function updateUsersAvatar(data) {
  return {
    type: 'UPDATE_USER_PROFILE',
    payload: data,
  };
}

export function saveProfileMainData(values, gallery) {
  return (dispatch) => {
    let formData = new FormData();
    formData.append('address', values.address);
    formData.append('fax', values.fax);
    formData.append('zip', values.zip);
    formData.append('country', values.country);
    formData.append('city', values.city);
    formData.append('phone', values.phone);
    formData.append('firstName', values.firstName);
    formData.append('lastName', values.lastName);
    formData.append('gender', values.gender);
    formData.append('description', values.description);
    formData.append('organizationName', values.organizationName);
    formData.append('organizationType', values.organizationType);
    formData.append('organizationSize', values.organizationSize);
    formData.append('website', values.website);
    formData.append('facebook', values.facebook);
    formData.append('twitter', values.twitter);
    formData.append('linkedin', values.linkedin);
    formData.append('jobTitle', values.jobTitle);
    formData.append('established', values.established);

    for (var i = 0; i < gallery.length; i++) {
      formData.append('gallery[]', gallery[i], gallery[i].name);
    }

    axios
      .post('/setting/main', formData)
      .then(function (response) {
        if (response.status === true || response.status === 200) {
          dispatch(fetchSettingsMain());
          dispatch(setUpdateSuccess(true));
          dispatch(fetchProgressProgess());
          return {
            type: DO_NOTHING,
            payload: 'string',
          };
        } else {
          console.log(response);
          alert(response.message);
        }
      })
      .catch(function (error) {
        var returnData = {};
        returnData.errors = error.response.data;
        returnData.hasError = true;
        returnData.message = error.response.data.message;
        dispatch(doSettingErrors(returnData));
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setUpdateSuccess(data) {
  return {
    type: UPDATE_PROFILE,
    payload: data,
  };
}

export function fetchMonth(param) {
  var url = '/get/events';
  var month = moment(param).format('MM');
  var year = moment(param).format('YYYY');
  if (param) {
    var url = url + '?month=' + month + '&view=calendar' + '&year=' + year;
  }

  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        var data = res.data;

        dispatch(setEvents(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function fetchCoaches(param) {
  var url = '/Coaches';
  if (param) {
    var queryParam = queryString.parse(decodeURIComponent(param.split('?')[1]));
    var sortLabel = 'Recent';
    if (queryParam['order'] == 'ASC') sortLabel = 'Oldest';

    if (param.split('?')[1]) var url = url + '?' + param.split('?')[1];
  }
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        data.sortLabel = sortLabel;
        data.status = false;
        dispatch(setCoaches(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setCoaches(data) {
  return {
    type: COACHES,
    payload: data,
  };
}

export function fetchProgressProgess() {
  const url = '/setting/profileProgress';
  return (dispatch, getState) => {
    const lan = getState().language.lan;
    const options = languageHeader(lan);
    axios
      .get(url, options)
      .then((res) => {
        var data = res.data;
        dispatch(setProfileProgress(data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}
export function beCoached(param) {
  var url = '/coached';
  var queryParam = param.split(',');
  var data = {
    id: queryParam[1],
    type: queryParam[0],
  };
  return (dispatch) => {
    axios
      .post(url, data)
      .then((res) => {
        if (queryParam[2] == 'coaching') {
          dispatch(fetchCoaches(window.location.hash));
        } else if (queryParam[2] == 'coachingDetails') {
          dispatch(fetchMemberDetails(window.location.hash));
        }
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function fetchCoachStatus(messageStatus = false) {
  var url = '/coach';
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        data.data.messageShow = messageStatus;
        dispatch(setCoachStatus(data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setCoachStatus(data) {
  return {
    type: COACH_STATUS,
    payload: data,
  };
}

export function becomeCoach() {
  var url = '/COACH';
  return (dispatch) => {
    axios
      .post(url)
      .then((res) => {
        var data = res.data;
        dispatch(fetchCoachStatus(true));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function editForumItem(param) {
  var queryObject = param.split('/');
  var url = '/forums/edit?id=' + queryObject[2];
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        const data = res.data;
      })
      .catch(function (error) {
        console.log('edit item error' + error);
      });
  };
}

export function editItem(param) {
  var queryObject = param.split('/');
  var url = queryObject[1] + '/edit?id=' + queryObject[2];
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        const data = res.data;
      })
      .catch(function (error) {
        console.log('edit error' + error);
      });
  };
}

export function editEventItem(param) {
  var queryObject = param.split('/');
  var url = '/events/edit?id=' + queryObject[2];
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        const data = res.data;
      })
      .catch(function (error) {
        console.log('edit error' + error);
      });
  };
}

/*export function uploadProfileImage(profileParams,imageUrl) {
  console.log('uploadPre',profileParams,imageUrl);
} */

export function fetchVerifiersList() {
  var url = '/setting/verifier';
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        dispatch(setSettingTab('verifier', data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function fetchMyCommitments() {
  var url = '/setting/myCommitments';
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        dispatch(setSettingTab('commitment', data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setProfileProgress(data) {
  return {
    type: PROFILE_PROGRESS,
    payload: data,
  };
}
export function fetchSettingsServices() {
  var url = '/setting/services';
  return (dispatch) => {
    dispatch(resetTabs());
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        dispatch(setSettingTab('services', data));
      })
      .catch(function (error) {

      });
  };
}

export function getElearningContent() {
  //get the current active language else english as default
  return (dispatch, getState) => {
    const lan = getState().language.lan;
    const url = `/eLearning?url=e-learning&lan=${lan}`;
    const options = languageHeader(lan);
    axios
      .get(url, options)
      .then((res) => {
        const data = res.data;
        dispatch(setElearnContent(data));
      })
      .catch(function (error) {
        console.log('Market-Tools error' + error);
      });
  };
}

function setElearnContent(eLearnContent) {
  return {
    type: E_LEARN_CONTENT,
    payload: eLearnContent,
  };
}

export function getInvestContent() {
  //get the current active language else english as default
  return (dispatch, getState) => {
    const lan = getState().language.lan;
    const url = `/invest?url=invest&lan=${lan}`;
    const options = languageHeader(lan);
    axios
      .get(url, options)
      .then((res) => {
        const data = res.data;
        dispatch(setInvestContent(data));
      })
      .catch(function (error) {
        console.log('Market-Tools error' + error);
      });
  };
}

function setInvestContent(investContent) {
  return {
    type: E_INVEST_CONTENT,
    payload: investContent,
  };
}

export function getSATContent() {
  //get the current active language else english as default
  return (dispatch, getState) => {
    const lan = getState().language.lan;
    const url = `/sat?url=sat&lan=${lan}`;
    const options = languageHeader(lan);
    axios
      .get(url, options)
      .then((res) => {
        const data = res.data;
        dispatch(setSATContent(data));
      })
      .catch(function (error) {
        console.log('sat error' + error);
      });
  };
}

function setSATContent(satContent) {
  return {
    type: SAT_CONTENT,
    payload: satContent,
  };
}

export function fetchFaq() {
  return (dispatch, getState) => {
    const lan = getState().language.lan;
    const options = languageHeader(lan);
    axios
      .get('/faq', options)
      .then((res) => {
        const faqs = res.data;
        dispatch(setFaqs(faqs));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setFaqs(faqs) {
  return {
    type: FAQS,
    payload: faqs,
  };
}

export function fetchStaticPages() {
  var locations = window.location.hash;
  var queried = locations.split('/');
  var token = queried[1];
  token = token.split('?');
  var url = '/staticpages?url_slug=' + token[0];
  return (dispatch, getState) => {
    const lan = getState().language.lan;
    const options = languageHeader(lan);
    axios
      .get(url, options)
      .then((res) => {
        const data = res.data;
        dispatch(setStaticPages(data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setStaticPages(content) {
  return {
    type: STATIC_PAGES,
    payload: content,
  };
}

export function fetchSearchResult(
  param,
  search = null,
  searchFlag = false,
  messageFlag = null
) {
  var url = '/search?';
  var result = false;
  if (messageFlag != undefined || messageFlag != null) {
    var result = true;
  }
  if (param) {
    var queryParam = queryString.parse(decodeURIComponent(param.split('?')[1]));
    var sortLabel = 'Recent';
    if (queryParam['order'] == 'ASC') sortLabel = 'Oldest';

    if (param.split('?')[1]) var url = url + param.split('?')[1];
  }
  if (search) {
    url = formatSearchData(url, search, searchFlag);
  }
  return (dispatch) => {
    
      axios
        .get(url)
        .then((res) => {
          console.log("search results returned"+res);
          var data = res.data;
          data.sortLabel = sortLabel;
          var messageStatus = result;
          data.data.messageShow = messageStatus;
          dispatch(setSearchResult(res.data));
          //dispatch(push(url));
        })
        .catch(function (error) {
          // var data = [];
          // data.sortLabel = sortLabel;
          // dispatch(setSearchResult(data));
          console.log("search result error"+error);
          dispatch(handleApiErrors(dispatch, error));
        });
    };
  //}
}

function setSearchResult(data) {
  return {
    type: SEARCH_RESULT_FETCHED,
    payload: data,
  };
}

function formatSearchData(url, data, searchFlag) {
  // var lastChar = url.charAt(url.length - 1);
  // if (lastChar !== "&" || lastChar !== "?") {
  //   url = url + '&';
  // }
  if (data.countries) {
    // url += treeToQuery(data.countries, 'countryId');
    url = url + 'countryId=' + data.countries;
  } else if (data.countryId && !searchFlag) {
    //url += treeToQuery(data.countryId, 'countryId');
    url = url + 'countryId=' + data.countries;
  }

  if (data.experience) {
    var experience = '';
    switch (data.experience) {
      case 2:
        experience = '-1';
        break;
      case 3:
        experience = '1-2';
        break;
      case 4:
        experience = '2-5';
        break;
      case 5:
        experience = '5-';
        break;
      default:
        experience = '-1';
    }
    if (experience !== '') {
      url = url + '&experience=' + experience;
    }
  }
  if (data.womenPercentage) {
    url = url + '&womenPercentage=' + data.womenPercentage;
  }

  if (data.productsBuy || data.productsOffer) {
    url += treeToQuery(data.productsBuy, 'productIdBuy');
    url += treeToQuery(data.productsOffer, 'productId');
  } else if (data.productId && !searchFlag) {
    url += treeToQuery(data.productId, 'productId');
  }

  if (data.servicesBuy || data.servicesOffer) {
    url += treeToQuery(data.servicesBuy, 'serviceIdBuy');
    url += treeToQuery(data.servicesOffer, 'serviceId');
  } else if (data.serviceId && !searchFlag) {
    url += treeToQuery(data.serviceId, 'serviceId');
  }
  return url;
}

export function fetchCommitmentList() {
  var url = '/commitment';
  return (dispatch, getState) => {
    const lan = getState().language.lan;
    const options = languageHeader(lan);
    axios
      .get(url, options)
      .then((res) => {
        const data = res.data;
        dispatch(setCommitments(res.data));
      })
      .catch(function (error) {
        console.log('commitment error' + error);
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setCommitments(commitments) {
  return {
    type: COMMITMENTS_LIST,
    payload: commitments,
  };
}

export function fetchCommitmentForm(id) {
  return (dispatch) => {
    var idSelected = id;
    var url = '/commitment/fetchSteps/' + idSelected;
    // dispatch(setQuestions(null));
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        data.selectedItem = idSelected;
        dispatch(setQuestions(data));
      })
      .catch(function (error) {
        console.log('commitment form error');
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setQuestions(questions) {
  return {
    type: COMMITMENTS_QUESTIONS,
    payload: questions,
  };
}

export function submitCommitment(commitmentId, data, files, allQuestions) {
  var url = '/commitment/submit/' + commitmentId;
  var totalSteps = Object.keys(data).length;
  var checkBoxValuesOne = '';
  var formData = new FormData();
  var isArray = false;
  var stepOneArray;

  if (totalSteps > 0) {
    var stepCount = 0;
    for (var step = 0; step < totalSteps; step++) {
      stepCount = Object.keys(data[step]).length;
      stepOneArray = [];
      Object.keys(data[step]).forEach(function eachKey(key) {
        isArray = Array.isArray(data[step][key]);
        if (isArray == true) {
          checkBoxValuesOne = '';
          for (var i = 0; i < data[step][key].length; i++) {
            if (data[step][key][i] != 'undefined') {
              checkBoxValuesOne = checkBoxValuesOne + data[step][key][i] + ',';
            }
          }
          stepOneArray.push({
            question: key,
            answer: checkBoxValuesOne
          });
        } else {
          stepOneArray.push({
            question: key,
            answer: data[step][key]
          });
        }
      });

      for (var i = 0; i < stepOneArray.length; i++) {
        if (
          stepOneArray[i].answer != '' &&
          stepOneArray[i].answer != 'undefined'
        ) {
          formData.append(
            'question[' + stepOneArray[i].question + ']',
            stepOneArray[i].answer
          );
        }
      }
    }

    var fileArray = [];
    if (Object.keys(files).length > 0) {
      Object.keys(files).forEach(function eachKey(key) {
        var ObjectLength = Object.keys(files[key]).length;
        var fileCount = 0;
        while (ObjectLength > fileCount) {
          if (
            files[key][fileCount] != '' &&
            files[key][fileCount] != 'undefined'
          ) {
            fileArray.push({
              question: key,
              answer: files[key][fileCount]
            });
            fileCount++;
          }
        }
        fileCount = 0;
      });
    }
    for (var f = 0; f < fileArray.length; f++) {
      if (fileArray[f].answer != '' && fileArray[f].answer != 'undefined') {
        formData.append(
          'question[' + fileArray[f].question + '][]',
          fileArray[f].answer
        );
      }
    }

    return (dispatch) => {
      axios
        .post(url, formData)
        .then((res) => {
          const data = res.data;
          dispatch(fetchCommitmentList());
          dispatch(commitmentSuccess(res.data));
        })
        .catch(function (error) {
          var returnData = {};
          returnData.errors = error.response.data;
          returnData.hasError = true;
          returnData.message = error.response.data.message;
          dispatch(forumErrors('SAVE', returnData));
          dispatch(push('/commitments'));
        });
    };
  }
}

function commitmentSuccess(result) {
  return {
    type: COMMITMENT_SUCCESS,
    payload: result,
  };
}

export function submitAbuseReport(data, id = null, common = null, questions) {
  var locations = window.location.hash;
  var queried = locations.split('/');
  var abuseType = data[0][1];
  var description = data[0][2];
  var entityType = 'common abuse';
  var entityId = 0;
  var variable = queried[1].split('?');
  var type = questions[0].questions[0].options[abuseType - 1].name;

  if (common != 'true') {
    if (queried[1] == 'forums' || variable[0] == 'forums') {
      var entityType = queried[1];
      if (variable[0] == 'forums') {
        var entityType = variable[0];
      }

      var entityId = queried[2];
      if (queried[2] == null) {
        var entityId = id;
      }
    }
  }

  let formData = new FormData();
  formData.append('url', window.location);
  formData.append('abuseType', type);
  formData.append('description', description);
  formData.append('entityType', entityType);
  formData.append('entityId', entityId);

  return (dispatch) => {
    axios
      .post('/abuseReport', formData)
      .then((res) => {
        const report = res.data.value;
        dispatch(setAbuses(report));
      })
      .catch(function (error) {
        console.log('Report abuse management form error');
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setAbuses(report) {
  return {
    type: ABUSESUCCESS,
    payload: report,
  };
}

export function fetchCertifications() {
  var url = '/setting/certificates';
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        dispatch(setSettingTab('certificates', data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function saveInterestData(data) {
  var formData = new FormData();

  if (data.countries) {
    formData.append('myInterestsCountries', data.countries);
  } else {
    formData.append('myInterestsCountries', '');
  }
  if (data.sectors) {
    formData.append('myInterestsSectors', data.products_looking_for);
  } else {
    formData.append('myInterestsSectors', '');
  }
  if (data.typeOfEvents) {
    formData.append('myInterestsTypeOfEvents', data.products_selling);
  } else {
    formData.append('myInterestsTypeOfEvents', '');
  }
  if (data.inspirations) {
    formData.append('myInterestsInspirations', data.services_offering);
  } else {
    formData.append('myInterestsInspirations', '');
  }
  //email
  //platform notification

  return (dispatch) => {
    axios
      .post('/setting/my-interest', formData)
      .then(function (response) {
        console.log(response);
        dispatch(fetchProgressProgess());
        dispatch(setUpdateSuccess(true));
      })
      .catch(function (error) {
        var returnData = {};
        returnData.errors = error.response.data;
        returnData.hasError = true;
        returnData.message = error.response.data.message;
        dispatch(doSettingErrors(returnData));
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function saveProfileServicesData(data) {
  var formData = new FormData();
  if (data.certifications) {
    formData.append('certificates', data.certifications);
  }

  if (data.countries_export) {
    formData.append('export', data.countries_export);
  } else {
    formData.append('export', '');
  }
  if (data.countries_import) {
    formData.append('import', data.countries_import);
  } else {
    formData.append('import', '');
  }
  if (data.products_looking_for) {
    formData.append('products_looking_for', data.products_looking_for);
  } else {
    formData.append('products_looking_for', '');
  }
  if (data.products_selling) {
    formData.append('products_selling', data.products_selling);
  } else {
    formData.append('products_selling', '');
  }
  if (data.services_offering) {
    formData.append('services_offering', data.services_offering);
  } else {
    formData.append('services_offering', '');
  }
  if (data.services_looking_for) {
    formData.append('services_looking_for', data.services_looking_for);
  } else {
    formData.append('services_looking_for', '');
  }

  return (dispatch) => {
    axios
      .post('/setting/updateServices', formData)
      .then(function (response) {
        console.log(response);
        dispatch(fetchProgressProgess());
        dispatch(setUpdateSuccess(true));
      })
      .catch(function (error) {
        var returnData = {};
        returnData.errors = error.response.data;
        returnData.hasError = true;
        returnData.message = error.response.data.message;
        dispatch(doSettingErrors(returnData));
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function onSuccessCloseClick(closeClick) {
  return (dispatch) => {
    dispatch(setUpdateSuccess(closeClick));
  };
}

export function onErrorCloseClick(closeClick) {
  var returnData = {};
  returnData.errors = null;
  returnData.hasError = closeClick;
  returnData.message = null;
  return (dispatch) => {
    dispatch(doSettingErrors(returnData));
  };
}

// messenger
export function updateMessengerCache({
  key,
  value,
  isPending
}) {
  return {
    type: UPDATE_MESSENGER_CACHE,
    payload: {
      key,
      value,
      isPending
    },
  };
}

export function reloadMessenger({
  key,
  fetchItems
}) {
  return async (dispatch) => {
    dispatch(updateMessengerCache({
      key,
      isPending: true
    }));
    const items = await fetchItems();
    dispatch(updateMessengerCache({
      key,
      value: items,
      isPending: false
    }));
  };
}

export function emitApplozicSocketEvent({
  type,
  response
}) {
  return (dispatch, getState) => {
    dispatch({
      type: APPLOZIC_SOCKET_EVENT,
      payload: {
        type,
        response
      },
    });
    if (type === 'messageReceived') {
      const {
        message: {
          to,
          from
        },
      } = response;
      const {
        messenger: {
          chats,
          applozicApi
        },
      } = getState();
      const {
        userDetails,
        groupFeeds
      } = chats;
      const {
        ALApiService
      } = applozicApi;
      const isGroup = distinguishGroupId(to);
      const chatId = String(isGroup ? to : from);
      const userOrGroup = getUserOrGroup({
        userDetails,
        groupFeeds,
        isGroup,
        chatId,
      });
      if (!userOrGroup && isGroup) {
        ALApiService.getGroupInfo({
          data: {
            groupId: chatId
          },
          success({
            response
          }) {
            dispatch(appendUserOrGroup({
              group: response
            }));
          },
        });
      } else if (!userOrGroup) {
        ALApiService.getUserDetail({
          data: {
            userIdList: [chatId]
          },
          success({
            response: [user]
          }) {
            if (user) {
              dispatch(appendUserOrGroup({
                user
              }));
            }
          },
        });
      }
    }
  };
}

export function setApplozicApi(applozicApi) {
  return {
    type: SET_APPLOZIC_API,
    payload: applozicApi,
  };
}

export function toggleApplozicApiFetching(applozicApiFetching) {
  return {
    type: TOGGLE_APPLOZIC_API_FETCHING,
    payload: applozicApiFetching,
  };
}

export function appendCurrentChat({
  message,
  userDetails
}) {
  return {
    type: APPEND_CURRENT_CHAT,
    payload: {
      message,
      userDetails
    },
  };
}

export function reloadChat({
  chatId,
  isGroup
}) {
  return {
    type: RELOAD_CHAT,
    payload: {
      chatId,
      isGroup
    },
  };
}

export function setTotalUnreadCount(totalUnreadCount) {
  return {
    type: SET_TOTAL_UNREAD_COUNT,
    payload: totalUnreadCount,
  };
}

export function fetchRecentMessages() {
  return async (dispatch, getState) => {
    const {
      messenger: {
        applozicApi
      },
    } = getState();
    const {
      ALApiService
    } = applozicApi;
    const serverContactsPromise = axios.get('/allContactsOptions');
    const serverGroupsPromise = axios.get('/contactList');
    const applozicContactsPromise = new Promise((resolve, reject) => {
      ALApiService.getMessages({
        data: {
          startIndex: 0,
          mainPageSize: 100
        },
        success: (response) => resolve(response),
        error: (response) => reject(response),
      });
    });
    const [
      serverContactsResponse,
      serverGroupsResponse,
      applozicContactsResponse,
    ] = await Promise.all([
      serverContactsPromise,
      serverGroupsPromise,
      applozicContactsPromise,
    ]);
    const {
      data: {
        message,
        groupFeeds,
        userDetails
      },
    } = applozicContactsResponse;
    const {
      data: {
        data: {
          groups
        },
      },
    } = serverGroupsResponse;
    const {
      data: contactsData
    } = serverContactsResponse;
    const {
      data: contactsList
    } = contactsData;
    const groupIds = groups.map(({
      id
    }) => String(id));
    const userIds = contactsList.map(({
      value
    }) => String(value));
    const messagesFromExistentChats = message.filter(
      ({
        clientGroupId,
        contactIds
      }) => {
        if (clientGroupId) {
          return groupIds.indexOf(clientGroupId) !== -1;
        }
        return userIds.indexOf(contactIds) !== -1;
      }
    );
    dispatch(setContactOptionsForSearch(contactsData));
    dispatch({
      type: SET_CHATS,
      payload: {
        message: messagesFromExistentChats,
        groupFeeds,
        userDetails
      },
    });
  };
}

export function getContactsOptions() {
  //get contacts
  return (dispatch) => {
    axios
      .get('/allContactsOptions')
      .then((res) => {
        var data = res.data;
        dispatch(setContactOptions(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setContactOptions(data) {
  return {
    type: SET_CONTACTS_OPTIONS,
    payload: data,
  };
}

function setGroups(data) {
  return {
    type: SET_GROUPS,
    payload: data,
  };
}

export function fetchMessageTabData(param, offset = 0) {
  var queryParam = queryString.parse(decodeURIComponent(param.split('?')[1]));

  if (queryParam.tab == 'groups') {
    var url = '/groupsUserList?' + param.split('?')[1];
    return (dispatch) => {
      axios
        .get(url)
        .then((res) => {
          var data = res.data;

          dispatch(setTabData('groups', data));
        })
        .catch(function (error) {
          dispatch(handleApiErrors(dispatch, error));
        });
    };
  } else if (queryParam.tab == 'notifications') {
    var url = '/fetchNotication?' + param.split('?')[1];

    if (offset > 0) {
      url = url + '&limit=' + offset + '&offset=0';
    }
    return (dispatch, getState) => {
      const lan = getState().language.lan;
      const options = languageHeader(lan);
      axios
        .get(url, options)
        .then((res) => {
          var data = res.data;

          dispatch(setTabData('notifications', data));
        })
        .catch(function (error) {
          dispatch(handleApiErrors(dispatch, error));
        });
    };
  } else {
    return {
      type: DO_NOTHING,
      payload: 'tht',
    };
  }

  return {
    type: DO_NOTHING,
    payload: 'tht',
  };
}

function setTabData(tab, data) {
  if (tab == 'groups') {
    return {
      type: SET_GROUPS_USER_LIST,
      payload: data,
    };
  } else if (tab == 'notifications') {
    return {
      type: SET_NOTIFICATION_TAB_DATA,
      payload: data,
    };
  } else {
    return {
      type: DO_NOTHING,
      payload: 'tht',
    };
  }
}

export const appendNotifications = ({
  items,
  offset = 0,
  totalCount
}) => ({
  type: APPEND_NOTIFICATIONS,
  payload: {
    items,
    offset,
    totalCount
  },
});

export const loadMoreNotifications = ({
  limit,
  offset = 0
}) => {
  return (dispatch) => {
    axios
      .get('/fetchNotication', {
        params: {
          offset,
          limit
        }
      })
      .then((response) => {
        const {
          data: {
            data,
            total
          },
        } = response;
        dispatch(
          appendNotifications({
            items: data,
            offset: offset + limit,
            totalCount: total,
          })
        );
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
};

export function fetchBellNotifications(userId, page) {
  var url = '/fetchBellNotifications/' + userId + '/' + page;

  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        const data = res.data;
        dispatch(setFetchedBellNotifications(data));
      })
      .catch(function (error) {
        console.log('notification error');
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setFetchedBellNotifications(notifications) {
  return {
    type: FETCHED_BELL_NOTIFICATIONS,
    payload: notifications,
  };
}

export function userAppliedAction(requestId, status, type, param, offset) {
  let formData = new FormData();
  formData.append('requestId', requestId);
  formData.append('status', status);

  if (type == 'tenders') {
    var url = '/tender/action';
  } else {
    var url = '/offer/action';
  }

  return (dispatch) => {
    axios
      .post(url, formData)
      .then((res) => {
        const report = res.data.value;
        dispatch(fetchMessageTabData(param, offset));
      })
      .catch(function (error) {
        console.log('some error on action save');
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function saveVerfierResponse(userId, status, param, offset) {
  let formData = new FormData();
  formData.append('id', userId);
  formData.append('status', status);

  var url = '/handleVerifierRequest';

  return (dispatch) => {
    axios
      .post(url, formData)
      .then((res) => {
        const report = res.data.value;
        dispatch(fetchMessageTabData(param, offset));
      })
      .catch(function (error) {
        console.log('some error on action save');
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function saveVerificationResponse(
  requestId,
  status,
  type,
  param,
  offset
) {
  let formData = new FormData();
  formData.append('id', requestId);
  formData.append('status', status);
  formData.append('type', type);
  var url = '/approveVerification';

  return (dispatch) => {
    axios
      .post(url, formData)
      .then((res) => {
        const report = res.data.value;
        dispatch(fetchMessageTabData(param, offset));
      })
      .catch(function (error) {
        console.log('some error on action save');
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function saveContactResponse(requestId, status, param, offset) {
  let formData = new FormData();
  formData.append('id', requestId);
  formData.append('status', status);
  var url = '/updateContactRequest';

  return (dispatch) => {
    axios
      .post(url, formData)
      .then((res) => {
        const report = res.data.value;
        dispatch(fetchMessageTabData(param, offset));
        dispatch(fetchRecentMessages());
      })
      .catch(function (error) {
        console.log('some error on action save');
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function handleCoachingActions(requestId, status, param, offset) {
  let formData = new FormData();
  formData.append('id', requestId);
  formData.append('status', status);
  var url = '/updateCOACHRequest';

  return (dispatch) => {
    axios
      .post(url, formData)
      .then((res) => {
        const report = res.data.value;
        dispatch(fetchMessageTabData(param, offset));
      })
      .catch(function (error) {
        console.log('some error on action save');
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function profileImageError() {
  return {
    type: PROFILE_IMAGE_ERROR,
  };
}

export function saveProfileImage(type, imageMarking, image) {
  let formData = new FormData();

  formData.append('imageMarking', JSON.stringify(imageMarking));
  formData.append('image', image, image.name);
  formData.append('type', type);

  var url = '/setting/updateProfileImage';

  console.log(formData);

  return (dispatch) => {

    axios
      .post(url, formData, {
        headers: {
          'accept': 'application/json',
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
        }
      })
      .then((res) => {
        dispatch(fetchSettingsMain());
        dispatch(setUpdateSuccess(true));
        dispatch(setLoaderSuccess(true));
      })
      .catch(function (error) {
        dispatch(profileImageError());
        dispatch(setUpdateSuccess(false));
        dispatch(setLoaderSuccess(false));
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function onMessageClose(action) {
  var data = {
    status: false
  };
  return {
    type: COACH_MESSAGE_HIDE,
    payload: data,
  };
}

export function onVerifierMessageClose(action) {
  var data = {
    status: false
  };
  return {
    type: VERIFIER_MESSAGE_HIDE,
    payload: data,
  };
}

export function onMemberMessageClose(action) {
  var data = {
    status: false
  };
  return {
    type: MEMBER_MESSAGE_HIDE,
    payload: data,
  };
}
export function onMyNetworkMessageClose(action) {
  var data = {
    status: false
  };
  return {
    type: NETWORK_MESSAGE_HIDE,
    payload: data,
  };
}

function setContactOptionsForSearch(data) {
  return {
    type: SET_CONTACTS_OPTIONS_FOR_SEARCH,
    payload: data,
  };
}

export function onBuisnessMessageClose(action) {
  var data = {
    status: false
  };
  return {
    type: BUSINESS_MESSAGE_HIDE,
    payload: data,
  };
}

export function appendUserOrGroup({
  user,
  group
}) {
  return {
    type: APPEND_USER_OR_GROUP,
    payload: {
      user,
      group
    },
  };
}

export function resetChats() {
  return {
    type: RESET_CHATS,
  };
}
export function readUpdate({
  chatId,
  isGroup
}) {
  return {
    type: READ_UPDATE,
    payload: {
      chatId,
      isGroup
    },
  };
}
export function closeThisItem(param, search) {
  var queryParam = param.split('/');
  if (queryParam[1] && queryParam[2]) {
    var url = queryParam[1] + '/delete';
    var data = {
      id: queryParam[2],
    };
  }
  return (dispatch) => {
    axios
      .post(url, data)
      .then((res) => {
        if (queryParam[1] == 'tenders') {
          dispatch(fetchTenders(param, search));
          dispatch(push('/tenders?tab=closed'));
          dispatch(setSuccessFlagTender(true));
        } else {
          dispatch(fetchOffers(param, search));
          dispatch(push('/offers'));
        }
      })
      .catch(function (error) {
        console.log('error in closing');
        dispatch(setSuccessFlagOffer(true));
      });
  };
}

function setSuccessFlagTender(flag) {
  return {
    type: CLOSE_SUCCESS,
    payload: flag,
  };
}

function setSuccessFlagOffer(flag) {
  return {
    type: CLOSE_SUCCESS_OFFER,
    payload: flag,
  };
}

export function closeAbuseOnSuccess(closeClick) {
  return (dispatch) => {
    dispatch(setAbuses(closeClick));
  };
}

export function onCloseButtonClick(closeClick) {
  return (dispatch) => {
    dispatch(setSuccessFlagTender(closeClick));
  };
}

export function onCloseButtonClickOffer(closeClick) {
  return (dispatch) => {
    dispatch(setSuccessFlagOffer(closeClick));
  };
}

export function purgeMembers({
  memberSection
}) {
  return {
    type: PURGE_MEMBERS,
    payload: {
      memberSection
    },
  };
}

export function setOffersErrorFlag(flag) {
  var returnData = {};
  returnData.errors = {};
  returnData.hasError = flag;
  returnData.message = '';

  return (dispatch) => {
    dispatch(doOfferErrors('SAVE', returnData));
  };
}

export function setTendersErrorFlag(flag) {
  var returnData = {};
  returnData.errors = {};
  returnData.hasError = flag;
  returnData.message = '';

  return (dispatch) => {
    dispatch(doTenderErrors('SAVE', returnData));
  };
}

export function setForumErrorFlag(flag) {
  var returnData = {};
  returnData.errors = {};
  returnData.hasError = flag;
  returnData.message = '';

  return (dispatch) => {
    dispatch(forumErrors('SAVE', returnData));
  };
}

export function setEventsErrorFlag(flag) {
  var returnData = {};
  returnData.errors = {};
  returnData.hasError = flag;
  returnData.message = '';

  return (dispatch) => {
    dispatch(doeventErrors('SAVE', returnData));
  };
}

export function fetchWebinars(param, search) {
  var url = '/webinars';
  if (param) {
    var queryParam = queryString.parse(param.split('?')[1]);

    // for some reason API gives no events for calendar
    url = url + '?' + queryString.stringify({
      ...queryParam,
      view: 'grid'
    });
  }
  if (search !== undefined && search != null) {
    let decodeFilter = decodeFilters(search);
    if (url.indexOf('?') !== -1) {
      decodeFilter = (decodeFilter.charAt(0) === "&") ? decodeFilter.substring(1) : decodeFilter;
      url = url + decodeFilter;
    } else {
      decodeFilter = (decodeFilter.charAt(0) === "&") ? decodeFilter.substring(1) : decodeFilter;
      url = url + '?' + decodeFilter;
    }
  }
  return (dispatch, getState) => {
    const lan = getState().language.lan;
    const options = languageHeader(lan);
    axios
      .get(url, options)
      .then((res) => {
        dispatch(setWebinars(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setWebinars(data) {
  return {
    type: WEBINARS,
    payload: data,
  };
}

export function detailWebinars(param) {
  var url = '/webinars';
  if (param) {
    var queryParam = queryString.parse(decodeURIComponent(param.split('/')[2]));
    if (param.split('/')[2]) var url = '/webinar' + '/' + param.split('/')[2];
  }
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        var data = res.data;
        data.url = window.window.location.href;
        dispatch(setdetailWebinar(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setdetailWebinar(data) {
  return {
    type: DETAIL_WEBINARS,
    payload: data,
  };
}

export function fetchWebinarMonth(param) {
  var url = '/webinars';
  var month = moment(param).format('MM');
  var year = moment(param).format('YYYY');
  if (param) {
    var url = url + '?month=' + month + '&view=calendar' + '&year=' + year;
  }

  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        var data = res.data;

        dispatch(setWebinars(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

export function fetchTopFilterWebinarsSector() {
  return (dispatch) => {
    axios
      .get('/webinars/sector', {
        headers: config
      })
      .then((res) => {
        dispatch(setWebinarsSectorFilterOptions(res.data));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}

function setWebinarsSectorFilterOptions(data) {
  return {
    type: SET_WEBINARS_SECTOR_FILTER_OPTIONS,
    payload: data,
  };
}

export function updateLoaderValue(data) {
  return (dispatch) => {
    dispatch(setLoaderSuccess(data));
  };
}

function setLoaderSuccess(data) {
  return {
    type: UPDATE_LOADER,
    payload: data,
  };
}

export function checkSession() {
  var url = '/checkSession';
  return (dispatch) => {
    axios
      .get(url)
      .then((res) => {
        dispatch(setCurrentUser(res.data.user));
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  }
}

function setSession(status, data) {
  return {
    type: SET_SESSION,
    payload: data,
  };
}

export function SaveMissingUserInfo(gender, userId) {
  var url = 'api/v2/updateGenderCountry';
  var data = {
    userId: userId,
    gender: gender,
  };
  return (dispatch) => {
    axios
      .post(url, data, {
        headers: config,
      })
      .then((response) => {
        return {
          type: SOCIAL_MISSING_INFO,
          payload: response,
        };
      })
      .catch(function (error) {
        dispatch(handleApiErrors(dispatch, error));
      });
  };
}