import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import languages from '../../helpers/languages';
import SignUpFormStep1 from './sign-up-step1';
import SignUpFormStep2 from './sign-up-step2';
import TopMessage from '../../components/TopMessage';

var language = languages['en'];
var labels = language.NewRegister[0];

class SignUpNewForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentStep: 1,
      showAlert: true,
    };
  }

  componentDidMount() {

  }

  closeAlert() {
    this.setState({ showAlert: false });
  }

  _next = () => {
    let currentStep = this.state.currentStep
    if(!this.props.globalError)
    {
      currentStep = currentStep >= 1 ? 2 : currentStep + 1;
  }
  this.setState({
    currentStep: currentStep
  })
}

  _prev = () => {
    let currentStep = this.state.currentStep
    currentStep = currentStep <= 1 ? 1 : currentStep - 1
    this.setState({
      currentStep: currentStep
    })
  }

  /*
  * the functions for our button
  */
  previousButton() {
    let currentStep = this.state.currentStep;
    if (currentStep !== 1) {
      return (
        <button
          className="button button_continue"
          type="button" onClick={this._prev}>
          {labels.BACK}
        </button>
      )
    }
    return null;
  }

  nextButton() {
    let currentStep = this.state.currentStep;
    if (currentStep < 2) {
      return (
        <button
          className="button button_continue"
          type="button" onClick={this._next}>
          {labels.CONTINUE}
        </button>
      )
    }
    return null;
  }

  render() {
    labels = languages[this.props.lan].NewRegister[0];
    
    return (
      <React.Fragment>
        <div className="wrap">
          {this.props.globalError && (
            <TopMessage
              isShown={this.state.showAlert || this.props.globalError || this.props.errors}
              type={'error'}
              text= 'Your registration failed. Please use strong password & make sure you this email is not already registered'
              onCloseClick={this.closeAlert.bind(this)}
            />
          )}
          <div className="formtoptext">
            <h3>{labels.REGISTER_FOR_FREE} </h3>
            <p>{labels.JOIN_OUR_COMMUNITY_OF_OVER}</p>
          </div>
          <div className="container-2col">

            <form onSubmit={this.props.onSubmit}>
              {/* render the form steps and pass required props in*/}
              <Step1
                currentStep={this.state.currentStep}
                values={this.props.values}
                errors={this.props.errors}
                onChange={this.props.onChange}
                onSubmit={this.props.onSubmit}
                buttonDisabled={this.props.buttonDisabled}
              />
              <br></br>
              <Step2
                currentStep={this.state.currentStep}
                values={this.props.values}
                errors={this.props.errors}
                onChange={this.props.onChange}
                onSubmit={this.props.onSubmit}
                buttonDisabled={this.props.buttonDisabled}
                globalError={this.props.globalError}
              />

              {this.nextButton()}
              {this.previousButton()}
            </form>
            <div className="sidebg formpictures">
              <img src="images/temppic04.jpg" alt="" />
              <img src="images/storypic-02.jpg" alt="" />
              <img src="images/temppic01.jpg" alt="" />
            </div>
          </div>
          <p>{labels.ARE_YOU_ALREADY_A_MEMBER}?  
            <Link to="/sign-in">{labels.LOG_IN}</Link></p>
        </div>
      </React.Fragment >
    );
  }
}

function Step1(props) {
  if (props.currentStep !== 1) {
    return null
  }
  return (
    <div className="formpage-content">
      <h5 className="titlewithlinecentered"><span>{labels.LOG_IN_WITH_SOCIAL_NETWORK}</span></h5>
      <div className="loginlogos">
        <a href="https://shetrades.com/sociallogin/facebook"><i className="fab fa-facebook" /><p>Facebook</p></a>
        <a href="https://shetrades.com/sociallogin/google"><span className="ico-google" /><p>Google</p></a>
        <a href="https://shetrades.com/sociallogin/linkedin"><i className="fab fa-linkedin" /><p>Linkedin</p></a>
        <a href="https://shetrades.com/sociallogin/twitter"><i className="fab fa-twitter" /><p>Twitter</p></a>
        <a href="https://shetrades.com/sociallogin/itcs"><span className="ico-itclogo" /><p>ITC SSO</p></a>
      </div>
      <p />
      <h5 className="titlewithlinecentered">
        <span>{labels.OR_REGISTER_NEW_ACCOUNT}</span></h5>
      <p />
      <SignUpFormStep1
        values={props.values}
        errors={props.errors}
        onChange={props.onChange}
        buttonDisabled={props.buttonDisabled}
      />
    </div>
  );
}

function Step2(props) {
  if (props.currentStep !== 2) {
    return null
  }
  return (
    <div className="formpage-content">
      <div className="formtoptext">
        <h3>{labels.YOURE_JUST_A_FEW_QUESTIONS_AWAY}</h3>
        <p>{labels.THESE_LAST_QUESTIONS_WILL_HELP_US}</p>
        <hr />
      </div>
      <SignUpFormStep2
        values={props.values}
        errors={props.errors}
        onChange={props.onChange}
        buttonDisabled={props.buttonDisabled}
      />
      {(props.globalError) &&
        <div className="itc-main-error itc-error">
          {props.globalError}

        </div>
      }
    </div>

  );
}

const mapStateToProps = (state) => {
  return {
    lan: state.language.lan,
  };
};

export default connect(mapStateToProps)(SignUpNewForm);
