import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ConfirmButton from './components/ConfirmButton';
import languages from '../../../../helpers/languages';

var language = languages['en'];
var common = language.common[0];
var bussinessCircle = language.bussiness_circle[0];

const mapStateToProps = (state) => {
  return { userLogged: state.auth.user, lan: state.language.lan };
};

class Buttons extends Component {
  static propTypes = {
    active: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    verifyInsteadOfAdd: PropTypes.string,
    addcontact: PropTypes.func,
    param: PropTypes.string,
    updateContact: PropTypes.func,
    verificationRequested: PropTypes.string,
    requestedBy: PropTypes.string,
    dashboard: PropTypes.string,
    coaching: PropTypes.string,
    mentorRequested: PropTypes.string,
    status: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currentUser: PropTypes.number,
    isInMyNetwork: PropTypes.string,
    userType: PropTypes.string,
    userCompany: PropTypes.number,
    isVerifier: PropTypes.bool,
  };

  static defaultProps = {
    verifyInsteadOfAdd: null,
    addcontact: null,
    param: null,
    updateContact: null,
    verificationRequested: null,
    requestedBy: null,
    dashboard: null,
    coaching: null,
    mentorRequested: null,
    status: true,
    currentUser: null,
    isInMyNetwork: null,
    userType: null,
    userCompany: null,
    isVerifier: null,
  };

  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    bussinessCircle = language.bussiness_circle[0];
    const {
      active,
      verifyInsteadOfAdd,
      addcontact,
      param,
      updateContact,
      verificationRequested,
      requestedBy,
      dashboard,
      coaching,
      mentorRequested,
      status,
      id,
      currentUser,
      isInMyNetwork,
      userType,
      userLogged,
      mini,
      isVerifier,
    } = this.props;

    return (
      <div className='member-buttons'>
        {/* {isInMyNetwork === '1' && param.split(',')[0] !== 'company' && (
          <Link
            to={`/messenger?tab=chats&item=${param.split(',')[1]}`}
            className={classnames([
              'button ',
              { 'm-button-icon': mini },
            ])}
          >
            <i className='icon-comment' />

            <span className='button__text'>{common.SEND_MESSAGE}</span>
          </Link>
        )} */}

        {active &&
          !verifyInsteadOfAdd &&
          !requestedBy &&
          !coaching &&
          currentUser !== id &&
          (param.split(',')[0] !== 'company' ||
            userLogged.company_id !== id) && (
            <ConfirmButton
              type='member'
              label={active === '1' ? common.REMOVE_CONTACT : common.PENDING}
              buttonStatus={verificationRequested}
              onConfirm={updateContact}
              param={param + ',3'}
              dashboard={dashboard}
            />
          )}

        {active &&
          !verifyInsteadOfAdd &&
          requestedBy &&
          !coaching &&
          currentUser !== id &&
          (param.split(',')[0] !== 'company' ||
            userLogged.company_id !== id) && (
            <ConfirmButton
              type='member'
              label={
                active === '1' ? common.REMOVE_CONTACT : common.CONFIRM_REQUEST
              }
              buttonStatus={verificationRequested}
              onConfirm={updateContact}
              param={active === '1' ? param + ',3' : param + ',1'}
              dashboard={dashboard}
            />
          )}

        {!active &&
          !verifyInsteadOfAdd &&
          !coaching &&
          currentUser !== id &&
          (param.split(',')[0] !== 'company' ||
            userLogged.company_id !== id) && (
            <ConfirmButton
              type='member'
              label={bussinessCircle.ADD_TO_CONTACTS}
              buttonStatus={verificationRequested}
              onConfirm={addcontact}
              param={param}
              dashboard={dashboard}
            />
          )}

        {active &&
          isVerifier &&
          verifyInsteadOfAdd &&
          !coaching &&
          currentUser !== id &&
          (param.split(',')[0] !== 'company' ||
            userLogged.company_id !== id) && (
            <ConfirmButton
              type='verifier'
              buttonStatus={verificationRequested}
              onConfirm={addcontact}
              param={param}
            />
          )}

        {!active &&
          isVerifier &&
          verifyInsteadOfAdd &&
          !coaching &&
          currentUser !== id &&
          (param.split(',')[0] !== 'company' ||
            userLogged.company_id !== id) && (
            <ConfirmButton
              type='verifier'
              buttonStatus={verificationRequested}
              onConfirm={addcontact}
              param={param}
            />
          )}

        {active &&
          !verifyInsteadOfAdd &&
          coaching &&
          status &&
          currentUser !== id &&
          (param.split(',')[0] !== 'company' ||
            userLogged.company_id !== id) && (
            <ConfirmButton
              type='coach'
              buttonStatus={mentorRequested}
              onConfirm={addcontact}
              param={param}
            />
          )}

        {!active &&
          !verifyInsteadOfAdd &&
          coaching &&
          status &&
          currentUser !== id &&
          (param.split(',')[0] !== 'company' ||
            userLogged.company_id !== id) && (
            <ConfirmButton
              type='coach'
              buttonStatus={mentorRequested}
              onConfirm={addcontact}
              param={param}
            />
          )}
      </div>
    );
  }
}

Buttons.defaultProps = {};

const List = connect(mapStateToProps)(Buttons);
export default List;
