import React, { Fragment } from 'react';
import lifecycle from 'react-pure-lifecycle';
import { connect } from 'react-redux';
import Page from '../components/Page';
import CardOfMember from '../components/CardOfMember';
import Member from '../components/Member';
import Preloader from '../components/Preloader';
import NoDataCard from '../components/Card/NoDataCard';
import TopMessage from '../components/TopMessage';
import {
  fetchMembers,
  fetchMemberDetails,
  addContact,
  updateContact,
  fetchReviews,
  addReview,
  onMemberMessageClose,
} from '../store/actions';
import { COUNTRY, NAME } from '../components/Search/TopFilters/types';
import languages from '../helpers/languages';

var language = languages['en'];
var common = language.common[0];
var bussinessCircle = language.bussiness_circle[0];

const toolbar = (common) => ({
  tabs: {
    values: {},
    filterByKeys: (card) => [card.type],
  },
  sort: [
    { orderBy: 'date', order: 'ASC', label: common.OLDEST },
    { orderBy: 'date', order: 'DESC', label: common.RECENT },
  ],
});

const mapStateToProps = (state) => ({
  cards: state.members.members,
  showMessage: state.members.messageShow,
  totalItems: state.members.totalItems,
  sortLabel: state.members.sortLabel,
  detail: state.members.memberDetails,
  reviews: state.members.reviews,
  totalReviews: state.members.totalReviews,
  user: state.auth.user,
  userRole: state.auth.user.userRole,
  lan: state.language.lan,
});

const mapDispatchToProps = {
  fetchMembers,
  fetchMemberDetails,
  fetchReviews,
  addContact,
  updateContact,
  addReview,
  onMemberMessageClose,
};

const methods = {
  componentDidMount(props) {
    props.fetchMembers(window.location.hash);
    var queryParam = window.location.hash.split('/');
    if (queryParam[3] && queryParam[4]) {
      props.fetchMemberDetails(window.location.hash);
      props.fetchReviews(window.location.hash, 0, 1);
    }
  },
};

export const renderMembers = (cards, props) => (
  <div className='cards-list'>
    {cards === null ? (
      <Preloader relative size='medium' />
    ) : cards.length > 0 ? (
      cards.map((card, i) => (
        <CardOfMember
          key={card.id || i}
          detailUrl='companies'
          {...card}
          {...props}
        />
      ))
    ) : (
      <NoDataCard />
    )}
  </div>
);

const PageMembers = (props) => {
  language = languages[props.lan];
  common = language.common[0];
  bussinessCircle = language.bussiness_circle[0];
  var messaging = common.YOU_HAVE_SUCCESSFULLY_SEND_REQUEST;
  const { cards, totalItems, detail, reviews } = props;
  return (
    <Fragment>
      <Page
        name={common.COMPANIES}
        singleName='Companies'
        topfilters={[NAME, COUNTRY]}
        toolbar={toolbar(common)}
        renderAfterTitle={() => (
          <div className='after-title flex'>
            <div className='left'>
              <p className='gray'>{bussinessCircle.MEMBERS_INFO}</p>
            </div>
          </div>
        )}
        cards={cards}
        fetchFunction={props.fetchMembers}
        detailFunction={props.fetchMemberDetails}
        totalItems={totalItems}
        sortLabel={props.sortLabel}
        renderCards={(cards) =>
          renderMembers(cards, {
            detailFunction: props.fetchMemberDetails,
            addContactFunction: props.addContact,
            updateContactFunction: props.updateContact,
            pageType: 'companies',
          })
        }
        cardsPerPage={6}
        canCreateNew={false}
        {...props}
        renderDetail={(match) => (
          <Member
            {...detail}
            addContactFunction={props.addContact}
            updateContactFunction={props.updateContact}
            pageType='memberDetails'
            reviews={reviews}
            totalReviews={props.totalReviews}
            fetchReviewsFunction={props.fetchReviews}
            addReviewFunction={props.addReview}
            currentUser={props.user.id}
          />
        )}
      />

      <TopMessage
        isShown={props.showMessage}
        onCloseClick={() => props.onMemberMessageClose(false)}
        type='success'
        text={messaging}
      />
    </Fragment>
  );
};

PageMembers.defaultProps = {
  cards: [],
};

const pageMembersCycle = lifecycle(methods)(PageMembers);
const List = connect(mapStateToProps, mapDispatchToProps)(pageMembersCycle);

export default List;
