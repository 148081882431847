import React, { Fragment } from 'react';
import 'react-dates/lib/css/_datepicker.css';
import { connect } from 'react-redux';

import CardForNews from '../../../../components/CardForNews';
import CardForFullNews from '../../../../components/CardForFullNews';
import NoDataCard from '../../../../components/Card/NoDataCard';

const mapStateToProps = (state) => {
  return {
    cards: state.webinars.upcoming_webinars,
    pageUrl: state.webinars.pageUrl,
    detailBookmark: state.events.eventBookmark,
    loggedInUser: state.auth.user && state.auth.user.id,
    t: state.language.t,
  };
};

const Details = (props) => {
  const previousUrl = window.location.hash;
  const { detail, cards, pageUrl, pageTitle, applyUrl, image, t } = props;
  var row = [];
  var i = 0;
  for (var key in props.cards) {
    row.push(
      <div key={i} className='col-md-4'>
        <CardForNews key={i} {...props.cards[key]} listType='webinars' />
      </div>
    );
    i++;
  }

  return (
    <Fragment>
      <CardForFullNews
        isEvent
        {...detail}
        pageUrl={pageUrl}
        addBookmark={props.addBookmark}
        detailBookmarked={props.detailBookmark}
        previousUrl={props.previousUrl}
        loggedInUser={props.loggedInUser}
        region={null}
      />

      <section className='upcoming'>
        <h2>{t.webinars[0].UPCOMING_WEBINARS}</h2>

        <div className='row'>{row}</div>
        {row.length === 0 && <NoDataCard />}
      </section>
    </Fragment>
  );
};

Details.defaultProps = {
  cards: [],
  addBookmark: null,
};

export default connect(mapStateToProps)(Details);
