import React, { Component } from 'react';
import PropTypes from 'prop-types';
import gsap from "gsap";
import { TweenLite} from 'gsap';
import { ScrollToPlugin } from 'gsap/all'

//gsap.registerPlugin(ScrollToPlugin)

class Accordion extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    ItemComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    sharedProps: PropTypes.object,
    className: PropTypes.string,
    openedIndex: PropTypes.number,
    onOpenedIndexChange: PropTypes.func,
  };

  static defaultProps = {
    sharedProps: {},
    className: 'accordion',
  };

  constructor() {
    super();
    this.state = {
      innerOpenedIndex: null,
    };
    this.items = [];
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  componentDidUpdate({ openedIndex: oldOpenedIndex }) {
    const { openedIndex } = this.props;
    if (
      openedIndex !== null &&
      oldOpenedIndex !== null &&
      openedIndex !== oldOpenedIndex
    ) {
      this.openItem(openedIndex);
    }
  }

  openItem(newInnerOpenedIndex) {
    const { onOpenedIndexChange } = this.props;
    const { innerOpenedIndex } = this.state;
    const newInnerOpenedItem = this.items[newInnerOpenedIndex];
    if (innerOpenedIndex !== null) {
      TweenLite.to(this.items[innerOpenedIndex], 0.2, { height: 0 });
    }
    if (newInnerOpenedItem) {
      TweenLite.to(newInnerOpenedItem, 0.2, {
        height: newInnerOpenedItem.scrollHeight,
      });
    }
    if (onOpenedIndexChange) {
      onOpenedIndexChange(newInnerOpenedIndex);
    }
    this.setState({
      innerOpenedIndex: newInnerOpenedIndex,
    });
  }

  handleResize() {
    const { openedIndex } = this.props;
    const { innerOpenedIndex } = this.state;
    const finalOpenedIndex = openedIndex || innerOpenedIndex;
    const newInnerOpenedItem = this.items[finalOpenedIndex];
    if (innerOpenedIndex !== null) {
      TweenLite.set(this.items[innerOpenedIndex], { height: 0 });
    }
    if (newInnerOpenedItem) {
      TweenLite.set(newInnerOpenedItem, {
        height: newInnerOpenedItem.scrollHeight,
      });
    }
  }

  render() {
    const {
      items,
      ItemComponent,
      sharedProps,
      className,
      openedIndex,
    } = this.props;
    const { innerOpenedIndex } = this.state;
    const finalOpenedIndex = openedIndex || innerOpenedIndex;

    return (
      <div className={className}>
        {items.map((el, i) => {
          const { id } = el;
          return (
            <ItemComponent
              key={id}
              isOpened={finalOpenedIndex === i}
              itemId={finalOpenedIndex === i ? i + 1 : 0}
              bottomRef={(el) => (this.items[i] = el)}
              onToggleClick={() =>
                this.openItem(finalOpenedIndex === i ? null : i)
              }
              {...sharedProps}
              {...el}
            />
          );
        })}
      </div>
    );
  }
}

export default Accordion;
