import React, { useState, useEffect } from "react"
import { Button } from "react-bootstrap"
import languages from '../../../helpers/languages';


export const InstallButton = () => {
  var language = languages['en'];
  var header = language.header[0];

  let [showInstallBtn, setShowInstallBtn] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined" && typeof navigator !== "undefined") {
      const isIOS =
        navigator.userAgent.includes("iPhone") ||
        navigator.userAgent.includes("iPad") ||
        (navigator.userAgent.includes("Macintosh") &&
          typeof navigator.maxTouchPoints === "number" &&
          navigator.maxTouchPoints > 2)

      const isSupportingBrowser = window.hasOwnProperty(
        "BeforeInstallPromptEvent"
      )

      const isStandalone = window.matchMedia("(display-mode: standalone)")
        .matches

      if (isStandalone) {
        setShowInstallBtn(false)
      } else {
        setShowInstallBtn(
          isIOS ||
            (isSupportingBrowser &&
              (localStorage.getItem("sheTradesInstalled") === "" ||
                localStorage.getItem("sheTradesInstalled") === "false"))
        )
      }

      // This will only be called if the browser is eligible and PWA has NOT been installed yet
      window.addEventListener("beforeinstallprompt", () => {
        localStorage.setItem("sheTradesInstalled", "false")
        setShowInstallBtn(true)
      })

      window.addEventListener("appinstalled", () => {
        localStorage.setItem("sheTradesInstalled", "true")
      })
    }
  }, [])

  let installButton

  if (showInstallBtn) {
    installButton = (
      <div className="header-button">
      <Button 
        id="installButton"
        variant="primary"
        size="sm"
        onClick={() => {
          if (typeof document !== "undefined") {
            document.querySelector("pwa-install").openPrompt()
          }
        }}
      >
        Install
      </Button>
      </div>
    )
  }

  return (
    <>
      {installButton}
      <pwa-install
          id="installId"
          manifestpath="/manifest.webmanifest"
          usecustom="true" 
          installbuttontext = "Install"
          explainer = "This website can be installed on the home screen of your phone, laptop or computer. You can also delete it when you are no longer using it. Important: We do not use data from your phone and do not store private data."
          featuresheader = "Key Features"
          descriptionheader = "SheTrades Platform"
          iosinstallinfotext = "To install, tap the share button and then 'Add to Home Screen'"
          ></pwa-install>
    </>
  )
}
