import React, { Fragment } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { listTransition, distinguishGroupId } from '../../../../constants';
import CustomizedDocumentTitle from '../../../../components/CustomizedDocumentTitle';
import Header from './components/Header';
import Chat from './components/Chat';
import MessengerContact from './components/Contact';
import NotificationsFeed from './components/NotificationsFeed';
import lifecycle from 'react-pure-lifecycle';
import {
  fetchMessageTabData,
  loadMoreNotifications,
} from '../../../../store/actions';
import languages from '../../../../helpers/languages';

var language = languages['en'];
var messages = language.messages[0];

let localizedTabs = null;

const setLocalizedTabs = (messages) => {
  localizedTabs = {
    groups: [
      {
        id: '1',
        name: messages.ALL_CONTACTS,
        text: messages.CONTACT_LIST_WITHOUT_CATEGORIES,
      },
    ],
    tabs: [
      {
        id: 'chats',
        icon: 'messenger--chats',
      },
      {
        id: 'groups',
        icon: 'messenger--contacts',
      },
      {
        id: 'notifications',
        icon: 'messenger--activity',
      },
    ],
    notificationFilters: [
      {
        id: 'all',
        icon: 'info',
        name: messages.ALL_NOTIFICATIONS,
        text: messages.NOTIFICATIONS,
      },
      {
        id: 'tenders-and-offers',
        icon: 'gavel',
        name: messages.TENDERS_AND_OFFERS,
        text: messages.NOTIFICATION_CATEGORY,
      },
      {
        id: 'verification-requests',
        icon: 'check--circle',
        name: messages.VERIFICATION_REQUESTS,
        text: messages.NOTIFICATION_CATEGORY,
      },
      {
        id: 'comments',
        icon: 'comment--notification',
        name: messages.NEW_COMMENTS,
        text: messages.NOTIFICATION_CATEGORY,
      },
    ],
  };
};

const mapStateToProps = (state) => {
  return {
    groupUsers: state.messenger.groupUsers,
    lan: state.language.lan,
  };
};

const mapDispatchToProps = {
  fetchMessageTabData,
  loadMoreNotifications,
};

const methods = {
  componentDidMount(props) {
    const { fetchMessageTabData, location } = props;
    const queryObject = queryString.parse(location.search);
    if (queryObject.tab !== 'notifications') {
      fetchMessageTabData(location.search);
    }
  },
};

const getTabName = (itemsArray, idToSearch) => {
  let result = itemsArray[0].name;
  itemsArray.forEach(({ id, name }) => {
    if (id === idToSearch) {
      result = name;
    }
  });
  return result;
};

const MessengerBody = (props) => {
  language = languages[props.lan];
  messages = language.messages[0];
  if (localizedTabs === null) {
    setLocalizedTabs(messages);
  }
  const { tabs, groups, notificationFilters } = localizedTabs;
  const { location, groupUsers } = props;
  const queryObject = queryString.parse(location.search);
  const { item } = queryObject;
  const tab = queryObject.tab || tabs[0].id;
  const groupName = getTabName(groups, queryObject.item);
  const notificationsTabName = getTabName(
    notificationFilters,
    queryObject.item
  );
  let title;
  switch (tab) {
    case 'groups':
      title = groupName;
      break;
    case 'notifications':
      title = notificationsTabName;
      break;
  }

  return (
    <CustomizedDocumentTitle title={title}>
      <TransitionGroup className='transition-container'>
        <CSSTransition key={location.search} {...listTransition}>
          <div className='transition-page'>
            {tab === 'chats' && item ? (
              <Chat chatId={item} isGroup={distinguishGroupId(item)} />
            ) : (
              tab === 'chats' && (
                <p className='list-animation-item content'>
                  {messages.PLEASE_SELECT_CHAT_START_MESSAGE}
                </p>
              )
            )}

            {tab === 'groups' && (
              <Fragment>
                <Header title={groupName} />

                <div className='messenger-body list-animation-item'>
                  <div className='messenger-contacts'>
                    {groupUsers.map((el) => (
                      <MessengerContact key={el.id} {...el} />
                    ))}
                  </div>
                </div>
              </Fragment>
            )}

            {tab === 'notifications' && (
              <Fragment>
                <Header title={notificationsTabName} />
                <NotificationsFeed limit={10} />
              </Fragment>
            )}
          </div>
        </CSSTransition>
      </TransitionGroup>
    </CustomizedDocumentTitle>
  );
};

MessengerBody.propTypes = {
  fetchMessageTabData: PropTypes.func,
  location: PropTypes.object.isRequired,
};

const MessengerBodyCycle = lifecycle(methods)(MessengerBody);
const List = connect(mapStateToProps, mapDispatchToProps)(MessengerBodyCycle);

export default withRouter(List);
