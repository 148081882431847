import {
  FETCH_SEARCH_RESULT,
  SEARCH_RESULT_FETCHED,
  MEMBER_MESSAGE_HIDE,
} from '../store/actions';

const initialState = {
  messageShow: false,
  isFetching: false,
};

export default function searchResult(state = initialState, action) {
  switch (action.type) {
    case FETCH_SEARCH_RESULT:
      return {
        ...state,
        isFetching: true,
      };
    case SEARCH_RESULT_FETCHED:
      return {
        ...state,
        search: action.payload.data,
        messageShow: action.payload.data.messageShow,
        totalItems: action.payload.count,
        sortLabel: action.payload.sortLabel,
        isFetching: false,
      };
    case MEMBER_MESSAGE_HIDE:
      return {
        ...state,
        messageShow: false,
      };
    default:
      return state;
  }
}
