import React, { useState } from "react";
import lifecycle from 'react-pure-lifecycle';
import { connect } from 'react-redux';
import { RWebShare } from "react-web-share";
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import languages from '../helpers/languages';

var language = languages['en'];
var common = language.common[0];

const mapStateToProps = (state) => ({
  lan: state.language.lan,
});

const mapDispatchToProps = {
};

const methods = {
  componentDidMount(props) {
  },
};

const PageInviteFriend = (props) => {
  language = languages[props.lan];
  common = language.common[0];
 
  return (
    <CustomizedDocumentTitle title={common.INVITE_FRIEND}>
      <div className='transition-page'>
        <div className='innerheader innerheader-helpcenter'>
          <h1 className='h2'>{common.INVITE_FRIEND}</h1>
        </div>

        <div>
          
        <RWebShare
          data={{
          text: "I think you would really enjoy being a part of the SheTrades",
          url: "https://shetrades.com/#/sign-up",
          title: "SheTrades",
          }}
          onClick={() => console.log("shared successfully!")}
        >
        <button>INVITE_THROUGH_SOCIAL_MEDIA</button>
        </RWebShare>
         <h2>OR_EMAIL_THEM</h2>
    </div>
      </div>
    </CustomizedDocumentTitle>
  );
};

const inviteFriendCycle = lifecycle(methods)(PageInviteFriend);
const List = connect(mapStateToProps, mapDispatchToProps)(inviteFriendCycle);

export default List;
