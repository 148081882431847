import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Input from '../../../../components/settings/Input';
import MultiInputField from '../../../../components/settings/MultiInput/components/Field';
import Tab from '../../../../components/settings/Tab';
import {
  fetchSettingsDetails,
  saveDetailsData,
  closeValidationError,
} from '../../../../store/actions';
import languages from '../../../../helpers/languages';

var language = languages['en'];
var registration = language.registration[0];
var detail_members = language.detail_members[0];
var common = language.common[0];
var create_offer = language.create_offer[0];
var validation = language.registration_validation[0];

const mapStateToProps = (state) => {
  if (state.registration.hasOwnProperty('noOfManagementTeamWomen')) {
    var i = 0,
      j = 0;
    for (i = 0, j = 0; i <= 100; i += 10, j++) {
      state.registration.noOfManagementTeamWomen[j] = {
        label: i + '%',
        value: i,
      };
    }
  }
  if (state.registration.hasOwnProperty('exportingYearExperience')) {
    var i = 0;
    state.registration.exportingYearExperience[i] = {
      label: common.SELECT,
      value: '',
    };

    for (i = 1; i <= 10; i++) {
      state.registration.exportingYearExperience[i] = {
        label: i,
        value: i,
      };
    }

    state.registration.exportingYearExperience[i] = {
      label: common.MORE_THAN_10,
      value: 11,
    };
  } else {
    state.registration.exportingYearExperience = [];
  }
  var companyArray = [0, 9, 49, 99, 249, 499, 999, 9999, 999999999999];
  let companySize = 0;
  if (
    state.setting.hasOwnProperty('loadData') &&
    state.setting.loadData !== null &&
    state.setting.loadData !== ''
  ) {
    companySize = companyArray[state.setting.loadData.companySize - 1];
  }
  return {
    userRole: state.auth.user.userRole,
    defaultData: state.setting.loadData,
    numberOfManagementWomenTotal: state.registration.noOfManagementTeamWomen,
    yearOfExportingList: state.registration.exportingYearExperience,
    companyTotalSize: companySize,
    lan: state.language.lan,
  };
};

const mapDispatchToProps = {
  fetchSettingsDetails,
  saveDetailsData,
  closeValidationError,
};

class DetailsInformation extends React.Component {
  static primaryCustomersOptions = [
    {
      value: 'Multinational Corporation (MNC). Tier 1 or 2 supplier to an MNC',
      label: registration.MNC_DETAILS,
    },
    {
      value: 'Small and Medium Enterprises',
      label: registration.SMALL_MEDIUM_ENTERPRISES,
    },
    {
      value: 'Local state or national government agencies',
      label: registration.LOCAL_STATE_NATIONAL_GOVT_AGENCIES,
    },
    { value: 'NGOs', label: registration.NGOS },
    {
      value: 'International Institutions',
      label: registration.INTERNATIONAL_INSTITUTIONS,
    },
    {
      value: 'Wholesalers/ retailers',
      label: registration.WHOLESALE_RETAILERS,
    },
    { value: 'importer/ agent', label: registration.IMPORTER_AGENT },
    { value: 'Other', label: registration.OTHER_PLEASE_SPECIFY },
  ];

  constructor() {
    super();
    this.state = {
      galleryImages: [],
    };
    this.onGalleryImagesChange = this.onGalleryImagesChange.bind(this);
  }

  componentDidMount() {
    this.props.fetchSettingsDetails().then(() => {
      if (this.props.defaultData) {
        const { gallery } = this.props.defaultData;
        if (gallery) {
          const galleryImages = gallery.map(({ thumb, id, caption }) => ({
            preview: thumb,
            name: thumb,
            id,
          }));
          this.setState({
            galleryImages,
          });
        }
      }
    });
  }

  onGalleryImagesChange(galleryImages) {
    this.setState({
      galleryImages,
    });
  }

  render() {
    language = languages[this.props.lan];
    registration = language.registration[0];
    detail_members = language.detail_members[0];
    common = language.common[0];
    create_offer = language.create_offer[0];
    validation = language.registration_validation[0];
    const { galleryImages } = this.state;
    const userType =
      this.props.defaultData &&
      this.props.defaultData.hasOwnProperty('userType')
        ? this.props.defaultData.userType
        : 'individual';
    let fixPrimaryCustomers;
    if (this.props.defaultData) {
      const { primaryCustomers } = this.props.defaultData;
      switch (typeof primaryCustomers) {
        case 'string':
          fixPrimaryCustomers = primaryCustomers.split(',');
          break;
        case 'object':
          fixPrimaryCustomers = primaryCustomers;
          break;
      }
    }
    return (
      <Tab
        title={registration.ORGANISATION_DETAIL}
        showPreloader={!this.props.defaultData}
        formProps={{
          initialValues: this.props.defaultData && {
            managedByWomen: this.props.defaultData.hasOwnProperty(
              'managedByWomen'
            )
              ? this.props.defaultData.managedByWomen
              : 0,
            noOfManagementTeam: this.props.defaultData.hasOwnProperty(
              'noOfManagementTeam'
            )
              ? this.props.defaultData.noOfManagementTeam
              : '',
            noOfWomenManagementTeam: this.props.defaultData.hasOwnProperty(
              'noOfWomenManagementTeam'
            )
              ? this.props.defaultData.noOfWomenManagementTeam
              : '',
            numberOfEmployees: this.props.defaultData.hasOwnProperty(
              'numberOfEmployees'
            )
              ? this.props.defaultData.numberOfEmployees
              : '',
            numberOfEmployeesWomen: this.props.defaultData.hasOwnProperty(
              'numberOfWomenEmployees'
            )
              ? this.props.defaultData.numberOfWomenEmployees
              : '',
            annualValueOfSales: this.props.defaultData.hasOwnProperty(
              'annualValueOfSales'
            )
              ? this.props.defaultData.annualValueOfSales
              : '',
            annualValueOfExports: this.props.defaultData.hasOwnProperty(
              'annualExportValue'
            )
              ? this.props.defaultData.annualExportValue
              : '',
            hasExportExperience: this.props.defaultData.hasOwnProperty(
              'experienceInExporting'
            )
              ? this.props.defaultData.experienceInExporting
              : 0,
            yearsOfExportingExperience: this.props.defaultData.hasOwnProperty(
              'yearOfExporting'
            )
              ? this.props.defaultData.yearOfExporting
              : '',
            primaryCustomers: fixPrimaryCustomers,
          },
          onSubmit: (values) => {
            this.props.saveDetailsData(values, this.state.galleryImages);
          },
        }}
        renderContents={({ formState }) => (
          <Fragment>
            <div className='inputs-group'>
              <div className='inputs-group__title'>
                <p className='gray'>{common.MANAGEMENT_EMPLOYEES}</p>
              </div>

              <Input
                id='managedByWomen'
                label={common.IS_YOUR_COMPANY_MANAGED_BY_WOMEN}
                disabled={userType === 'company' ? false : true}
                required={
                  this.props.defaultData.userType === 'company' ? true : false
                }
                type='tab-group'
                options={[
                  { value: 1, label: 'Yes' },
                  { value: 0, label: 'No' },
                ]}
              />

              <div className='row'>
                <div className='col-sm-6'>
                  <div className='inputs'>
                    <Input
                      id='noOfManagementTeam'
                      label={common.ORGANIZATION_SHARE_OWNED_BY_WOMEN}
                      disabled={userType === 'company' ? false : true}
                      required={
                        this.props.defaultData.userType === 'company'
                          ? true
                          : false
                      }
                      informedProps={{
                        validate: (v) => {
                          //TODO: Is this required?
                          if (
                            (userType === 'company' &&
                              this.props.userRole === 'seller' &&
                              isNaN(v)) &&
                            v < 30
                          ) {
                            return validation.SELLER_PERCENTAGE_ERROR;
                          }
                          if (
                            userType === 'company' &&
                            this.props.userRole === 'seller' &&
                            v > 100
                          ) {
                            return 'VALUE CAN NOT BE GREATER THAN 100';
                          }
                          if (
                            (userType === 'company' &&
                              v &&
                              this.props.userRole !== 'seller' &&
                              isNaN(v)) &&
                            v < 0
                          ) {
                            return validation.INVALID_VALUE;
                          }
                          if (
                            userType === 'company' &&
                            this.props.userRole !== 'seller' &&
                            v > 100
                          ) {
                            return 'VALUE CAN NOT BE GREATER THAN 100';
                          }
                          return null;
                        },
                      }}
                      hasError={'noOfManagementTeam' in formState.errors}
                      errors={formState.errors.noOfManagementTeam}
                      placeholder='1'
                    />

                    <Input
                      id='noOfWomenManagementTeam'
                      label={common.NUMBER_OF_WOMEN_IN_MANAGEMENT_TEAM}
                      disabled={userType === 'company' ? false : true}
                      required={
                        this.props.defaultData.userType === 'company'
                          ? true
                          : false
                      }
                      type='select'
                      options={this.props.numberOfManagementWomenTotal}
                      selectProps={{
                        arrowRenderer: null,
                        clearable: false,
                      }}
                    />
                  </div>
                </div>

                <div className='col-sm-6'>
                  <div className='inputs'>
                    <Input
                      id='numberOfEmployees'
                      label={detail_members.NUMBER_OF_EMPLOYEE}
                      disabled={userType === 'company' ? false : true}
                      required={
                        this.props.defaultData.userType === 'company'
                          ? true
                          : false
                      }
                      informedProps={{
                        validate: (v) => {
                          if ((v && isNaN(v)) || v < 0) {
                            return validation.INVALID_VALUE;
                          }
                          if (v && this.props.companyTotalSize < v) {
                            return validation.COMPANY_GREATER_SIZE_ERROR;
                          }
                          return null;
                        },
                      }}
                      placeholder='1'
                      hasError={'numberOfEmployees' in formState.errors}
                      errors={formState.errors.numberOfEmployees}
                    />

                    <Input
                      id='numberOfEmployeesWomen'
                      label={common.NUMBER_OF_WOMEN_EMPLOYEE}
                      disabled={userType === 'company' ? false : true}
                      required={
                        this.props.defaultData.userType === 'company'
                          ? true
                          : false
                      }
                      placeholder='1'
                      informedProps={{
                        validate: (v) => {
                          if ((v && isNaN(v)) || v < 0) {
                            return validation.INVALID_VALUE;
                          }
                          if (v && formState.values.numberOfEmployees < v) {
                            return validation.EMPLOYEE_COUNT_ERROR;
                          }
                          if (
                            v &&
                            this.props.companyTotalSize <
                              formState.values.numberOfEmployees
                          ) {
                            return validation.COMPANY_GREATER_SIZE_ERROR;
                          }
                          return null;
                        },
                      }}
                      hasError={'numberOfEmployeesWomen' in formState.errors}
                      errors={formState.errors.numberOfEmployeesWomen}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='inputs-group'>
              <div className='inputs-group__title'>
                <p className='gray'>{registration.ANNUAL_SALES_VALUE}</p>
              </div>

              <div className='row'>
                <div className='col-sm-6'>
                  <div className='inputs'>
                    <Input
                      id='annualValueOfSales'
                      label={registration.ANNUAL_SALES_VALUE}
                      required
                      placeholder='100'
                      informedProps={{
                        validate: (v) => {
                          if ((v && isNaN(v)) || v < 0) {
                            return validation.INVALID_VALUE;
                          }
                          return null;
                        },
                      }}
                      hasError={'annualValueOfSales' in formState.errors}
                      errors={formState.errors.annualValueOfSales}
                    />
                  </div>
                </div>

                <div className='col-sm-6'>
                  <div className='inputs'>
                    <Input
                      id='annualValueOfExports'
                      label={registration.ANNUAL_EXPORT_VALUE}
                      required
                      placeholder='100'
                      informedProps={{
                        validate: (v) => {
                          if ((v && isNaN(v)) || v < 0) {
                            return validation.INVALID_VALUE;
                          }
                          return null;
                        },
                      }}
                      hasError={'annualValueOfExports' in formState.errors}
                      errors={formState.errors.annualValueOfExports}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='inputs-group'>
              <div className='inputs-group__title'>
                <p className='gray'>{common.EXPORT}</p>
              </div>

              <div className='row'>
                <div className='col-12'>
                  <div className='inputs'>
                    <Input
                      id='hasExportExperience'
                      label={registration.HAVE_EXPORTING_EXPERIENCE}
                      required
                      type='tab-group'
                      options={[
                        { value: 1, label: 'Yes' },
                        { value: 0, label: 'No' },
                      ]}
                    />
                  </div>
                </div>
                {formState.values.hasExportExperience === 1 && (
                  <div className='col-12'>
                    <div className='inputs'>
                      <Input
                        id='yearsOfExportingExperience'
                        label={registration.YEARS_EXPORTING_EXPERIENCE}
                        required
                        type='select'
                        options={this.props.yearOfExportingList}
                        placeholder='1'
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className='inputs'>
                <MultiInputField
                  field='primaryCustomers'
                  id='primaryCustomers'
                  label={detail_members.OUR_PRIMARY_CUSTOMER}
                  placeholder={registration.PRIMARY_CUSTOMER_PLACEHOLDER}
                  options={DetailsInformation.primaryCustomersOptions}
                />
              </div>
            </div>

            <div className='inputs-group'>
              <div className='inputs-group__title'>
                <p className='gray'>{detail_members.GALLERY}</p>
              </div>
              <div className='inputs'>
                <Input
                  id='galleryImages'
                  label={create_offer.GALLERY_IMAGES}
                  type='images'
                  value={galleryImages}
                  onChange={this.onGalleryImagesChange.bind(this)}
                  fileInputProps={{
                    sortable: false,
                  }}
                />
              </div>
            </div>
          </Fragment>
        )}
      />
    );
  }
}

const List = connect(mapStateToProps, mapDispatchToProps)(DetailsInformation);
export default List;
