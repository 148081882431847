import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import withDropdownStagger from '../hoc/withDropdownStagger';

const Dropdown = (props) => {
  const {
    top,
    links,
    toggleRef,
    dropdownRef,
    opened,
    active,
    location,
    exact,
    addClassName,
    children,
  } = props;
  return (
    <div className={classNames(['dropdown', addClassName, { opened, active }])}>
      <div className='dropdown-top' ref={toggleRef}>
        <a href='javascript: void null'>
          {top}
          {children}
        </a>
      </div>

      <ul className='dropdown-links' ref={dropdownRef}>
        {links.map(({ to, title, icon, onClick = () => {} }, i) => (
          <li key={i}>
            {to ? (
              <NavLink
                to={to}
                onClick={onClick}
                isActive={() => {
                  if (exact) {
                    return to === location.pathname + location.search;
                  }

                  return location.pathname.includes(to.split('?')[0]);
                }}
              >
                {icon && <i className={`icon-${icon}`} />}

                {title}
              </NavLink>
            ) : (
              <a href='javascript: void null' onClick={onClick}>
                {icon && <i className={`icon-${icon}`} />}

                {title}
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

Dropdown.propTypes = {
  top: PropTypes.string,
  toggleRef: PropTypes.func.isRequired,
  dropdownRef: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  links: PropTypes.array,
  active: PropTypes.bool,
  exact: PropTypes.bool,
  addClassName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Dropdown.defaultProps = {
  links: [],
  exact: false,
  active: false,
  top: '',
  addClassName: '',
  children: '',
};

export default withRouter(withDropdownStagger(Dropdown));
