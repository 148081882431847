import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { SelectInput } from '../../../../../../components/settings/SelectInput';
import { appendUserOrGroup } from '../../../../../../store/actions';
import languages from '../../../../../../helpers/languages';

var language = languages['en'];
var messages = language.messages[0];

const mapStateToProps = (state) => ({
  applozicApi: state.messenger.applozicApi,
  contacts: state.messenger.contactDropOptions,
  loadedUserDetails: state.messenger.chats.userDetails,
  lan: state.language.lan,
});

const mapDispatchToProps = {
  appendUserOrGroup,
};

class MessengerSearch extends Component {
  static propTypes = {
    appendUserOrGroup: PropTypes.func,
    contacts: PropTypes.array,
    history: PropTypes.object,
    loadedUserDetails: PropTypes.array,
  };

  state = {
    searchContactId: '',
  };

  findUser(id) {
    const { loadedUserDetails } = this.props;
    return _.find(loadedUserDetails, ({ userId }) => userId === String(id));
  }

  onSearchChange(searchContactId) {
    const { appendUserOrGroup, contacts, history } = this.props;
    this.setState({
      searchContactId,
    });

    if (!searchContactId) return;

    const result = this.findUser(searchContactId);
    if (!result) {
      const option = _.find(contacts, ({ value }) => searchContactId === value);
      const { label } = option;
      appendUserOrGroup({
        user: {
          userId: String(searchContactId),
          userName: label,
          displayName: label,
        },
      });
    }
    // TODO: use 'query-string'
    history.push(`/messenger?tab=chats&item=${searchContactId}`);
  }

  render() {
    language = languages[this.props.lan];
    messages = language.messages[0];
    const { contacts } = this.props;
    const { searchContactId } = this.state;
    return (
      <div className='messenger-search'>
        <div className='input-with-icon'>
          <SelectInput
            value={searchContactId}
            onChange={this.onSearchChange.bind(this)}
            reactSelectProps={{
              className: 'input-with-icon__input',
              options: contacts,
              placeholder: messages.FIND_MEMBERS_CONTACTS,
              searchable: true,
            }}
          />

          <i className='icon-search' />
        </div>

        {/*<button className="button">*/}
        {/*<span className="button__text">*/}
        {/*<i className="icon-edit"/>*/}
        {/*</span>*/}
        {/*</button>*/}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MessengerSearch));
