/* eslint-disable no-unused-expressions */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Tag from '../components/Tag';
import MemberButtons from '../components/Member/components/Buttons';
import MemberRating from '../components/Member/components/Rating';
import MemberBottomText from '../components/Member/components/BottomText';
import NoAvatarImg from '../static/images/noavatar.svg'


const CardOfMember = (props) => {
  const {
    name,
    description,
    avatar,
    rating,
    reviews,
    isVerified,
    isVerifier,
    isMentor,
    isInMyNetwork,
    region,
    employees,
    type,
    verificationRequested,
    verifyInsteadOfAdd = null,
    isLoading,
    noAvatarImage,
    dataId,
    detailFunction,
    addContactFunction,
    pageType,
    updateContactFunction,
    detailUrl,
    requestedBy,
    dashboard,
    coaching = null,
    mentorRequested,
    mini,
    email,
    t,
  } = props;

  const viewName = isLoading ? '' : name;
  const viewType = isLoading ? '' : type;
  const viewRegion = isLoading ? { name: <span>&nbsp;</span> } : region;
  const viewEmployees = isLoading ? <span>&nbsp;</span> : employees;

  const to = `/${detailUrl}/${encodeURIComponent(name)
    .replace(/%20/g, '+')
    .replace(/%/g, '')}/${type}/${dataId}`;

  const buttons = (
    <MemberButtons
      {...{
        active: verifyInsteadOfAdd
          ? verificationRequested
          : coaching
            ? mentorRequested
            : isInMyNetwork,
        verifyInsteadOfAdd,
        verificationRequested: verificationRequested,
        addcontact: addContactFunction,
        updateContact: updateContactFunction,
        param: type + ',' + dataId + ',' + pageType + ',' + requestedBy,
        dashboard: dashboard,
        requestedBy: requestedBy,
        coaching,
        mentorRequested: mentorRequested,
        status: isMentor,
        id: dataId,
        isInMyNetwork: isInMyNetwork,
        mini,
        isVerifier: isVerifier,
      }}
    />
  );

  return (
  
    <div class="contentblock greyblock rounded recommendedsellers cardblock">
         <div class="container">
             <span class="managecompanyimg">
                {isVerified && (<i class="fas fa-check-circle"></i>)}
                {/*<i class="far fa-venus"></i>*/}
                <img src={avatar ? avatar : noAvatarImage} alt={viewName} />
                
            </span>
             <div class="managecompanydesc">
                 <h3><Link
                    to={to}
                    onClick={(e) => {
                      detailFunction ? detailFunction(to) : '';

                    }}                   
                  >{viewName}
                  </Link></h3>
                 <h4>{viewType}</h4>
                 <p class="bbll">
                    <span><i class="fas fa-map-marker"></i>&nbsp;{region.name}</span>
                    {isVerifier && (<span><i class="fas check-circle"></i>&nbsp;{t.common[0].VERIFIER}</span>)}
                    {isMentor && (<span><i class="fas check-circle"></i>&nbsp;{t.common[0].COACH}</span>)}
                </p>
             </div>  
             <div className="profilecontrols">
             {/* <a href={`mailto:${email}`}><span className="atitle">Send an chat</span>
                <i className="fas fa-comment-alt-lines" />
              </a> */}
             </div>

         </div>
    </div>

  );
};

CardOfMember.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  avatar: PropTypes.string,
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reviews: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isInMyNetwork: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isVerified: PropTypes.bool,
  isVerifier: PropTypes.bool,
  isMentor: PropTypes.bool,
  region: PropTypes.object,
  employees: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  verificationRequested: PropTypes.string,
  verifyInsteadOfAdd: PropTypes.string,
  isLoading: PropTypes.bool,
  noAvatarImage: PropTypes.string,
  dataId: PropTypes.number,
  detailFunction: PropTypes.func,
  addContactFunction: PropTypes.func,
  pageType: PropTypes.string,
  updateContactFunction: PropTypes.func,
  detailUrl: PropTypes.string,
  requestedBy: PropTypes.string,
  dashboard: PropTypes.string,
  coaching: PropTypes.string,
  mentorRequested: PropTypes.string,
  mini: PropTypes.bool,
  t: PropTypes.object,
};

CardOfMember.defaultProps = {
  name: '',
  description: '',
  avatar: '',
  rating: 0,
  reviews: 0,
  isInMyNetwork: null,
  isVerified: false,
  isVerifier: false,
  isMentor: false,
  region: null,
  employees: 0,
  type: '',
  verificationRequested: null,
  verifyInsteadOfAdd: null,
  isLoading: false,
  noAvatarImage: NoAvatarImg,
  dataId: 0,
  detailFunction: null,
  addContactFunction: null,
  pageType: null,
  updateContactFunction: null,
  detailUrl: 'members',
  requestedBy: null,
  dashboard: null,
  coaching: null,
  mentorRequested: null,
};

const mapStateToProps = (state) => ({ t: state.language.t });

export default connect(mapStateToProps)(CardOfMember);
