import React from 'react';
import PropTypes from 'prop-types';

const QuestionnaireLayout = ({ left, children, onCloseClick }) => {
  return (
    <div className='questionnaire'>
      <div className='questionnaire-top'>
        <div className='flex'>
          <div className='left grow'>{left}</div>
          <div className='right'>
            <div className='questionnaire-top__close'>
              <a href='javascript: void null' onClick={onCloseClick}>
                <i className='icon-close--big' />
              </a>
            </div>
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

QuestionnaireLayout.propTypes = {
  left: PropTypes.element,
  children: PropTypes.element,
  onCloseClick: PropTypes.func,
};

export default QuestionnaireLayout;
