import { ABUSESUCCESS } from '../store/actions';

const initialState = {
  status: false,
};

export default function abuse(state = initialState, action) {
  switch (action.type) {
    case ABUSESUCCESS:
      return {
        ...state,
        status: action.payload,
      };
    default:
      return state;
  }
}
