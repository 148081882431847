import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

class ArrayInput extends React.Component {
  static propTypes = {
    inputComponent: PropTypes.func.isRequired,
    maxCount: PropTypes.number,
  };

  static defaultProps = {
    maxCount: 20,
  };

  constructor() {
    super();
    this.state = {
      uniqueId: 0,
      array: [],
    };
  }

  async componentDidMount() {
    const { length = 1 } = this.props;
    for (let i = 0; i < length; i++) {
      await this.addField();
    }
  }

  async addField() {
    const { uniqueId, array } = this.state;
    const { maxCount } = this.props;

    if (array.length >= maxCount) return;

    await this.setState({
      ...this.state,
      array: [...array, { key: this.state.uniqueId }],
      uniqueId: uniqueId + 1,
    });
  }

  onRemoveClick(key) {
    const index = _.findIndex(this.state.array, { key });
    this.setState({
      ...this.state,
      array: [
        ...this.state.array.slice(0, index),
        ...this.state.array.slice(index + 1),
      ],
    });
  }

  render() {
    const {
      inputComponent: InputComponent,
      field,
      maxCount,
      t,
      ...otherProps
    } = this.props;
    return (
      <Fragment>
        {this.state.array.map(({ key }, i) => (
          <div className='array-input-item' key={key}>
            <div className='input'>
              <InputComponent {...otherProps} field={`${field}[${key}]`} />
            </div>

            {i > 0 && (
              <i
                className='icon-close'
                onClick={this.onRemoveClick.bind(this, key)}
              ></i>
            )}
          </div>
        ))}

        <button
          type='button'
          className='button button_border button_gray'
          onClick={this.addField.bind(this)}
        >
          <i className='icon-plus'></i>
          <span className='button__text'>{t.common[0].ADD}</span>
        </button>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  t: state.language.t,
});

export default connect(mapStateToProps)(ArrayInput);
