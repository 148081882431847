import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';

const CustomizedTooltip = ({ title, children }) => (
  <Tooltip
    placement='bottomRight'
    overlay={<Fragment>{title}</Fragment>}
    overlayClassName='shetrades-tooltip'
    trigger={['focus', 'hover']}
    transitionName='fadescale'
  >
    {children}
  </Tooltip>
);

CustomizedTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element,
};

export default CustomizedTooltip;
