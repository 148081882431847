/* eslint-disable no-unused-expressions */
import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import UserNameWithAvatar from '../components/UserNameWithAvatar';
import Share from '../components/Share';
import Comments from '../components/Comments';
import Questionnaire from '../components/Questionnaire';
import Modal from '../components/Modal';
import { connect } from 'react-redux';
import {
  addAnswer,
  fetchAnswers,
  addSubComment,
  editForumItem,
} from '../store/actions';
import languages from '../helpers/languages';

const DATE_FORMAT = 'DD MMM YYYY';

var language = languages['en'];
var label = language.report_abuse[0];
var common = language.common[0];
var menu = language.menu[0];
var dashboard = language.dashboard[0];
var forum = language.forum[0];
var main = label.MAIN_INFO;
var sub = label.SUB_INFO;

const mapStateToProps = (state) => ({
  isMobile: state.responsive.isMobile,
  showedAnswers: state.forum.showedAnswers,
  loggedInUser: state.auth.user && state.auth.user.id,
  lan: state.language.lan,
});

const mapDispatchToProps = {
  addAnswer,
  fetchAnswers,
  addSubComment,
  editForumItem,
};
class Forum extends Component {
  static propTypes = {
    userNameWithAvatar: PropTypes.object,
    date: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    categories: PropTypes.array,
    answers: PropTypes.array,
    totalAnswers: PropTypes.number,
    fetchAnswers: PropTypes.func,
    pageUrl: PropTypes.string,
    pageTitle: PropTypes.string,
    addBookmark: PropTypes.func,
    detailBookmarked: PropTypes.bool,
    editFlag: PropTypes.string,
    isMobile: PropTypes.bool,
  };

  static defaultProps = {
    answers: [],
    totalAnswers: null,
    fetchAllAnswers: null,
    addAnswer: null,
    pageTitle: null,
    addSubComment: null,
    addBookmark: null,
    detailBookmarked: false,
    editFlag: '',
  };

  constructor() {
    super();

    this.state = {
      showModal: false,
    };
    this.submitAbuseReport = this.submitAbuseReport.bind(this);
  }

  toggleModal(showModal) {
    this.setState({
      showModal,
    });
  }

  editItem() {
    this.props.editForumItem(window.location.hash);
  }

  submitAbuseReport(stepResults) {
    // there should be something like
    // return axios.post('/report-abuse', stepResults)
    return new Promise((resolve, reject) => setTimeout(() => resolve(), 1000));
  }

  render() {
    language = languages[this.props.lan];
    label = language.report_abuse[0];
    common = language.common[0];
    menu = language.menu[0];
    dashboard = language.dashboard[0];
    forum = language.forum[0];
    main = label.MAIN_INFO;
    sub = label.SUB_INFO;
    const abuseQuestionnaire = [
      {
        id: '1',
        title: dashboard.ABUSE_REPORT,
        questions: [
          {
            id: '1',
            title: dashboard.TELL_US_WHAT_HAPPENED,
            type: 'radio-group',
            options: [
              {
                id: '1',
                name: dashboard.THIS_IS_INSULTING,
              },
              {
                id: '2',
                name: dashboard.MISLEADING_OR_FRAUDLENT,
              },
              {
                id: '3',
                name: dashboard.THIS_IS_SPAM,
              },
              {
                id: '4',
                name: dashboard.OTHER,
              },
            ],
          },
          {
            id: '2',
            title: common.PLEASE_DESCRIBE,
            type: 'text',
          },
        ],
      },
    ];
    const {
      userNameWithAvatar,
      date,
      title,
      bookmarked,
      postedUser,
      description,
      categories,
      answers,
      totalAnswers,
      fetchAnswers,
      previousUrl,
      pageUrl,
      pageTitle,
      addAnswer,
      addSubComment,
      addBookmark,
      detailBookmarked,
      editFlag,
      isMobile,
    } = this.props;

    const bookStyles = {
      borderColor: '#39424C',
      color: 'white',
      background: '#39424C',
    };

    const { showModal } = this.state;
    return (
      <Fragment>
        <section className='detail detail_big detail_symmetric'>
          <div className='detail__left'>
            <div className='detail__top'>
              {userNameWithAvatar && (
                <UserNameWithAvatar {...userNameWithAvatar} />
              )}

              {date && (
                <span className='card__date'>
                  {moment(date).format(DATE_FORMAT)}
                </span>
              )}
            </div>

            <h1 className='h2'>{title}</h1>

            {description && (
              <div className='detail__description'>{description}</div>
            )}
          </div>

          <div className='detail__right right'>
            <div className='detail__share'>
              <Share pageUrl={pageUrl} pageTitle={pageTitle} />
            </div>
          </div>

          <div className='detail__bottom'>
            <div className='flex'>
              <div className='left'>
                {categories && (
                  <ul className='buttons-list'>
                    {categories.map((el) => (
                      <li key={el.value}>
                        <Link to={`/forums?forum=${el.value}`}>{el.label}</Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <div className='right'>
                <div className='buttons'>
                  <button
                    className='button button_border dashboard-card__bookmark'
                    style={bookmarked === true ? bookStyles : {}}
                    onClick={(e) => {
                      addBookmark
                        ? addBookmark(null, null, true, previousUrl)
                        : '';
                    }}
                  >
                    <i className='icon-bookmark' />
                  </button>

                  <button
                    className='button button_minimal'
                    onClick={() => this.toggleModal(true)}
                  >
                    {label.ABUSE_BUTTON}
                  </button>
                </div>

                {editFlag === 'my-items' &&
                  this.props.loggedInUser === postedUser && (
                    <a
                      href='javascript:void null'
                      className='button button_minimal'
                      onClick={(e) => this.editItem()}
                    >
                      {common.EDIT_ITEM}
                    </a>
                  )}
              </div>
            </div>
          </div>
        </section>

        <Comments
          commentable
          comments={answers}
          title={forum.ANSWERS}
          singleTitle='answer'
          addReview={addAnswer}
          totalReviews={totalAnswers}
          showedAnswers={this.props.showedAnswers}
          addSubComment={
            this.props.hasOwnProperty('addSubComment') &&
            addSubComment != undefined &&
            addSubComment
          }
          fetchReviews={fetchAnswers}
          classes=' detail_big detail_symmetric'
        />

        <Modal
          isOpen={showModal}
          onRequestClose={this.toggleModal.bind(this, false)}
          noBottom
        >
          <Questionnaire
            steps={abuseQuestionnaire}
            afterTitleMain={main}
            afterMain={sub}
            submit={this.submitAbuseReport}
            close={this.toggleModal.bind(this, false)}
            //withConfirmStep={false}
            confirmText={common.SEND}
            formMessage='true'
            disableProgress
          />
        </Modal>
      </Fragment>
    );
  }
}

const List = connect(mapStateToProps, mapDispatchToProps)(Forum);
export default List;
