import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { REACT_ROOT_ID } from '../constants';

export default class TopMessage extends Component {
  static propTypes = {
    closeable: PropTypes.bool,
    isShown: PropTypes.bool,
    onCloseClick: PropTypes.func,
    type: PropTypes.string.isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  };

  static defaultProps = {
    closeable: true,
    hidden: true,
  };

  render() {
    const { type, text, isShown, onCloseClick, closeable } = this.props;
    const root = document.getElementById(REACT_ROOT_ID);
    const iconClasses = {
      success: 'icon-check--circle',
      warning: 'icon-message--warning',
      error: 'icon-message--error',
    };

    const colors = {
      success: '#4BBD77',
      warning: '#FFCA81',
      error: '#FF938B',
    };

    const iconClass = iconClasses[type];
    const color = colors[type];

    return ReactDOM.createPortal(
      <div
        className={classNames('top-message', {
          'top-message_hidden': !isShown,
        })}
        style={{ backgroundColor: color }}
      >
        <i className={iconClass} />

        <p className='top-message__text'>{text}</p>

        {closeable && (
          <button className='top-message__close' onClick={onCloseClick}>
            <i className='icon-close--big' />
          </button>
        )}
      </div>,
      root
    );
  }
}
