import { mergeDateTime } from './../components/settings/Input/Input';
import { webinarTypeIsChosen } from './../services/shared';

export default function (
  data,
  tab,
  titleImage,
  galleryImage,
  sponsors,
  language
) {
  var validationMessageCommon = language.common[0];
  var validationMessage = language.create_event_validation[0];
  var errors = {};
  var isThereError = false;
  var fieldError = {};

  if (tab === 1 && data[tab] !== undefined) {
    var values = data[tab]['values'];
    var letters = /^[a-zA-Z\s]*$/;
    var lettersWithCharactor = /^[a-zA-Z\s._^%$#&!~@,-]*$/;

    var regexp = new RegExp(
      '^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?'
    );
    var max = 255;

    if (
      !values.hasOwnProperty('title') ||
      values.title === '' ||
      values.title == undefined ||
      values.title.trim() == ''
    ) {
      errors.title = [validationMessageCommon.PROVIDE_TITLE];
    }

    if (values.title !== undefined && values.title !== '') {
      if (values.title.length > max) {
        errors.title = [validationMessage.TITLE_LENGTH_EXCEEDING];
      }
    }

    if (values.type === null) {
      errors.type = [validationMessage.PROVIDE_TYPE_EVENT];
    }

    if (values.title !== undefined && values.title !== '') {
      if (values.title.length > max) {
        errors.title = [validationMessage.TITLE_LENGTH_EXCEEDING];
      }
    }

    if (values.type === null) {
      errors.type = [validationMessage.PROVIDE_TYPE_EVENT];
    }

    if (
      !values.hasOwnProperty('organizer') ||
      values.organizer === '' ||
      values.organizer === undefined ||
      values.organizer.trim() === ''
    ) {
      errors.organizer = [validationMessage.PROVIDE_ORGANIZER_EVENT];
    }

    if (
      (values.organizer !== undefined && values.organizer !== '') ||
      (typeof values.organizer === 'string' && values.organizer.trim() === '')
    ) {
      if (!values.organizer.match(letters)) {
        errors.organizer = [validationMessage.PROVIDE_ORGANIZER_EVENTS];
      }

      if (values.organizer.length > max) {
        errors.type = [validationMessage.EVENT_ORGANIZER_LENGTH_EXCEEDING];
      }
    }

    if (!webinarTypeIsChosen(values['type'])) {
      if (
        !values.hasOwnProperty('location') ||
        values.location === '' ||
        values.location === undefined
      ) {
        errors.location = [validationMessageCommon.SELECT_COUNTRY];
      }

      if (
        !values.hasOwnProperty('city') ||
        values.city === '' ||
        values.city === undefined ||
        values.city.trim() === ''
      ) {
        errors.city = [validationMessageCommon.ENTER_CITY];
      }

      if (values.city !== undefined && values.city !== '') {
        if (!values.city.match(letters)) {
          errors.city = [validationMessage.PROVIDE_VALID_DATA_CITY_EVENTS];
        }

        if (values.city.length > max) {
          errors.city = [validationMessage.EVENT_CITY_LENGTH_EXCEEDING];
        }
      }
    }

    if (
      !values.hasOwnProperty('startTime') ||
      values.startTime.time === '' ||
      values.startTime.time == undefined
    ) {
      errors.startTime = [validationMessageCommon.SELECT_TIME];
    }

    if (
      !values.hasOwnProperty('startTime') ||
      values.startTime.time === '' ||
      values.startTime.time === undefined
    ) {
      errors.startTime = [validationMessageCommon.SELECT_TIME];
    }

    if (
      !values.hasOwnProperty('startTime') ||
      values.startTime.date === '' ||
      values.startTime.date === undefined
    ) {
      errors.startTime = [validationMessageCommon.SELECT_DATE];
    }

    if (
      !values.hasOwnProperty('startTime') ||
      !values.hasOwnProperty('endTime') ||
      values.startTime.date === undefined ||
      values.startTime.date === undefined ||
      values.endTime.date === undefined ||
      values.endTime.date === undefined
    ) {
      errors.startTime = [validationMessageCommon.SELECT_TIME];
    }

    if (
      !values.hasOwnProperty('endTime') ||
      values.endTime.time === '' ||
      values.endTime.time === undefined
    ) {
      errors.endTime = [validationMessageCommon.SELECT_TIME];
    }

    if (
      !values.hasOwnProperty('endTime') ||
      values.endTime.date === '' ||
      values.endTime.date === undefined
    ) {
      errors.endTime = [validationMessageCommon.SELECT_DATE];
    }

    if (
      values.hasOwnProperty('endTime') &&
      values.endTime.date !== '' &&
      values.endTime.date !== undefined
    ) {
      if (values.endTime.time === '' || values.endTime.time === undefined) {
        errors.endTime = [validationMessageCommon.SELECT_TIME];
      }
    }

    if (!errors.startTime && !errors.endTime) {
      const { startTime, endTime } = values;
      const startTimeMoment = mergeDateTime(startTime);
      const endTimeMoment = mergeDateTime(endTime);

      if (startTimeMoment.isSameOrAfter(endTimeMoment)) {
        errors.endTime = [validationMessage.CHOOSE_CURRECT_TIME_INTERVAL];
      }
    }

    if (
      values.hasOwnProperty('startTime') &&
      values.startTime != undefined &&
      values.hasOwnProperty('endTime') &&
      values.endTime != undefined
    ) {
      var localStartDate = new Date(values.startTime.date);
      var localEndDate = new Date(values.endTime.date);
      var localiseStartDate = localStartDate.toLocaleString();
      var localiseEndDate = localEndDate.toLocaleString();

      var localStartTime = new Date(values.startTime.time);
      var localEndTime = new Date(values.endTime.time);
      var localiseStartTime = localStartTime.toLocaleString();
      var localiseEndTime = localEndTime.toLocaleString();

      var splitStartDate = localiseStartDate.split(',');
      var splitEndDate = localiseEndDate.split(',');

      var splitStarTime = localiseStartTime.split(',');
      var splitEndTime = localiseEndTime.split(',');

      if (splitStartDate[0] == splitEndDate[0]) {
        if (splitStarTime[1] >= splitEndTime[1]) {
          errors.endTime = [
            validationMessage.END_TIME_SHOULDBE_GREATERTHAN_START_TIME,
          ];
        }
      }
    }

    if (!values.sector || values.sector.length === 0) {
      errors.sector = [validationMessage.ENTER_SECTOR];
    }

    if (
      !values.hasOwnProperty('website') ||
      values.website === '' ||
      values.website == undefined ||
      values.website.trim() == ''
    ) {
      errors.website = [validationMessage.ENTER_WEBSITE];
    }

    if (
      !values.hasOwnProperty('website') ||
      values.website === '' ||
      values.website === undefined ||
      values.website.trim() === ''
    ) {
      errors.website = [validationMessage.ENTER_WEBSITE];
    }

    if (
      !values.hasOwnProperty('applyUrl') ||
      values.applyUrl === '' ||
      values.applyUrl === undefined
    ) {
      errors.applyUrl = [validationMessage.ENTER_URL];
    }

    if (values.website !== undefined && values.website !== '') {
      if (!values.website.match(regexp)) {
        errors.website = [validationMessage.PROVIDE_PROPER_URL];
      }
    }

    if (values.applyUrl !== undefined && values.applyUrl !== '') {
      if (!values.applyUrl.match(regexp)) {
        errors.applyUrl = [validationMessage.PROVIDE_PROPER_URL];
      }
    }
  }

  if (data[tab] !== undefined && tab === 2) {
    var values = data[tab]['values'];

    if (titleImage.length === 0) {
      errors.coverImage = [validationMessage.PROVIDE_TITLE_IMAGE];
    }

    if (galleryImage.length === 0) {
      errors.photos = [validationMessage.PROVIDE_GALLERY_IMAGES];
    }

    if (sponsors.length === 0) {
      errors.sponsor = [validationMessage.PROVIDE_SPONSORS_IMAGES];
    }

    if (
      !values.hasOwnProperty('editor') ||
      values.editor === '' ||
      values.editor === undefined
    ) {
      errors.description = [validationMessageCommon.PROVIDE_DESCRIPTION];
    }

    if (
      !values.hasOwnProperty('teaser') ||
      values.teaser === '' ||
      values.teaser === undefined
    ) {
      errors.teaser = [validationMessageCommon.PROVIDE_SHORT_DESCRIPTION];
    }
  }

  isThereError = isEmpty(errors);

  var returnData = {};

  returnData.errors = errors;
  returnData.hasError = isThereError;

  if (returnData.hasError) {
    returnData.message = validationMessageCommon.FILL_ALL_REQUIRED_FIELDS;
  } else {
    returnData.message = '';
  }

  return returnData;
}

function isEmpty(data) {
  for (var item in data) {
    return true;
  }

  return false;
}
