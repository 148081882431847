import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from '../../../../../components/Modal';

class TakePhoto extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    t: PropTypes.object,
  };

  static initialState = {
    isCapturing: false,
    hasError: false,
    hasUserInteracted: false,
    previewPhoto: null,
    previewPhotoUrl: '',
  };

  constructor() {
    super();
    this.state = TakePhoto.initialState;
    this.onAfterOpen = this.onAfterOpen.bind(this);
    this.capture = this.capture.bind(this);
    this.close = this.close.bind(this);
    this.retake = this.retake.bind(this);
    this.use = this.use.bind(this);
  }

  async mountVideo() {
    if (navigator.mediaDevices.getUserMedia) {
      this.video.srcObject = await navigator.mediaDevices
        .getUserMedia({ video: true })
        .catch((e) => {
          this.setState({
            ...this.state,
            hasError: true,
          });
          throw e;
        });

      await this.setState({
        ...this.state,
        hasUserInteracted: true,
      });
    }
  }

  unmountVideo() {
    const { srcObject } = this.video;
    if (srcObject) {
      srcObject.getTracks().forEach((el) => el.stop());
    }
    this.video.srcObject = null;
  }

  async onAfterOpen() {
    const { previewPhotoUrl } = this.state;
    if (!previewPhotoUrl) {
      this.mountVideo();
    }
  }

  close() {
    const { onClose } = this.props;
    const { previewPhoto } = this.state;
    if (!previewPhoto) {
      this.unmountVideo();
    }
    onClose();
  }

  async capture() {
    await this.setState({
      ...this.state,
      isCapturing: true,
    });

    const context = this.canvas.getContext('2d');
    this.canvas.width = this.video.videoWidth;
    this.canvas.height = this.video.videoHeight;
    context.drawImage(
      this.video,
      0,
      0,
      this.video.videoWidth,
      this.video.videoHeight
    );

    this.unmountVideo();
    this.canvas.toBlob((blob) => {
      this.setState({
        ...this.state,
        isCapturing: false,
        previewPhoto: blob,
        previewPhotoUrl: URL.createObjectURL(blob),
      });
    });
  }

  async retake() {
    await this.setState(TakePhoto.initialState);
    this.mountVideo();
  }

  use() {
    const { onChange } = this.props;
    const { previewPhoto, previewPhotoUrl } = this.state;
    onChange({ blob: previewPhoto, url: previewPhotoUrl });
    this.setState(TakePhoto.initialState);
  }

  render() {
    const { isOpen, t } = this.props;
    const {
      hasError,
      hasUserInteracted,
      isCapturing,
      previewPhotoUrl,
    } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        onAfterOpen={this.onAfterOpen}
        onRequestClose={this.close}
        buttons={{
          left: {
            text: t.common[0].CANCEL,
            className: 'gray',
            onClick: this.close,
          },
          right: {
            text: previewPhotoUrl
              ? t.common[0].USE_PICTURE
              : t.common[0].TAKE_PICTURE,
            className: 'brand',
            onClick: previewPhotoUrl ? this.use : this.capture,
            style: isCapturing ? { pointerEvents: 'none', opacity: 0.5 } : {},
          },
        }}
      >
        <div className='take-photo'>
          <div className='take-photo__image-container'>
            {previewPhotoUrl ? (
              <img src={previewPhotoUrl} alt='' className='take-photo__image' />
            ) : (
              <Fragment>
                <video
                  autoPlay={true}
                  ref={(el) => (this.video = el)}
                  className='take-photo__image'
                />
                <canvas
                  className='visually-hidden'
                  ref={(el) => (this.canvas = el)}
                />
              </Fragment>
            )}

            {hasError ? (
              <div className='take-photo__message'>
                <p className='take-photo__message info'>
                  {t.common[0].AN_ERROR_OCCURED_ACCESSING_CAMERA}
                </p>
              </div>
            ) : !hasUserInteracted ? (
              <div className='take-photo__message'>
                <p className='info'>{t.common[0].PLEASE_ALLOW_ACCESS_CAMERA}</p>
              </div>
            ) : null}
          </div>

          {previewPhotoUrl && (
            <div className='take-photo__button-container'>
              <button
                className='button button_border button_gray'
                onClick={this.retake}
              >
                <span className='button__text'>{t.common[0].RE_TAKE}</span>
              </button>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  t: state.language.t,
});

export default connect(mapStateToProps)(TakePhoto);
