import { TweenMax } from 'gsap';
import { Form } from 'informed';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, Route, Switch } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { logout, setCurrentUser } from '../../actions/auth';
import CreateNew from '../../components/CreateNew';
import CustomizedDocumentTitle from '../../components/CustomizedDocumentTitle';
import Pagination from '../../components/Pagination';
import TopFilters from '../../components/Search/TopFilters';
import Toolbar from '../../components/Toolbar';
import { listTransition } from '../../constants';
import { reset, searchSubmit } from '../../reducers/search';
import {
  fetchSearchResult
} from '../../store/actions';
import Cards from './components/Cards';
import classNames from 'classnames';
import MainFilterDropDown from '../PublicPage/components/AsideSearchMain'


// gsap.registerPlugin(ScrollToPlugin)
const FILTER_BYPASS = () => true;

// export const scrollTo = (y = 0) => {
//   TweenMax.to(window, 0.4, { scrollTo: { y, autoKill: false } });
// };

// export function getScrollXY() {
//   var scrOfX = 0,
//     scrOfY = 0;
//   if (typeof window.pageYOffset === 'number') {
//     //Netscape compliant
//     scrOfY = window.pageYOffset;
//     scrOfX = window.pageXOffset;
//   } else if (
//     document.body &&
//     (document.body.scrollLeft || document.body.scrollTop)
//   ) {
//     //DOM compliant
//     scrOfY = document.body.scrollTop;
//     scrOfX = document.body.scrollLeft;
//   } else if (
//     document.documentElement &&
//     (document.documentElement.scrollLeft || document.documentElement.scrollTop)
//   ) {
//     //IE6 standards compliant mode
//     scrOfY = document.documentElement.scrollTop;
//     scrOfX = document.documentElement.scrollLeft;
//   }
//   return [scrOfX, scrOfY];
// }

class Page extends React.Component {
  // eslint-disable-line react/no-deprecated
  constructor(props) {
    super(props);
    this.state = {
      formApi: null,
      queryInput: '',
      filter: FILTER_BYPASS,
      page: 0,
    };

    this.clear = this.clear.bind(this);
    this.onQueryInputChange = this.onQueryInputChange.bind(this);
    this.setFormApi = this.setFormApi.bind(this);
    this.submit = this.submit.bind(this);
  }

  clear() {
    const { onResetClick } = this.props;
    onResetClick();
    this.setState({
      queryInput: '',
    });
  }

  onQueryInputChange(e) {
    this.setState({
      queryInput: e.target.value,
    });
  }

  persist(values, searchUrl = null) {
    const { fetchSearchResult, onSearchSubmit } = this.props;
    const { formApi, queryInput } = this.state;
    formApi.setValues(values);
    onSearchSubmit(values);
    if (searchUrl) {
      fetchSearchResult(searchUrl, values, true);
    } else {
      fetchSearchResult(window.location.hash, values, true);
    }
  }

  setFormApi(formApi) {
    this.setState({
      formApi,
    });
  }

  submit() {
    const { history } = this.props;
    console.log(history);
    const { formApi, queryInput } = this.state;
    const trimmedQuery = encodeURIComponent(queryInput.trim());
    var pageUrl = window.location.hash.split('/');
    console.log(window.location.hash.split('#')[0]);
    var currentTab = '';
    if (pageUrl[1]) {
      pageUrl = pageUrl[1];
      pageUrl = pageUrl.split('?');
      if (pageUrl[0] && pageUrl[0] === 'search') {
        var qParam = queryString.parse(decodeURIComponent(pageUrl[1]));
        if (qParam['tab']) {
          currentTab = 'tab=' + qParam['tab'];
        }
      }
    }
    const formState = formApi.getState();
    if (Object.keys(formState.touched) !== 0 || trimmedQuery.length !== 0) {
      const location = `${window.location.hash.split('#')[0]}?query=${trimmedQuery}&${currentTab}`;
      this.persist(formState.values, location);
      this.props.history.push(location);
    }
  }

  getInitialValues() {
  }

  _syncStateWithProps(nextProps) {
    const { location, toolbar } = nextProps;
    const queryObject = queryString.parse(location.search);
    let newFilter;

    if (queryObject.tab && toolbar.tabs.filterByKeys) {
      newFilter = (card) =>
        queryObject.tab === 'all' ||
        toolbar.tabs.filterByKeys(card).includes(queryObject.tab);
    } else if (queryObject.tab) {
      newFilter = toolbar.tabs.values[queryObject.tab].filter || FILTER_BYPASS;
    } else {
      newFilter = FILTER_BYPASS;
    }

    this.setState({
      filter: newFilter,
      page: Number(queryObject.page) || 0,
    });
  }

  componentDidMount() {
    const { location } = this.props;
    this._syncStateWithProps(this.props);
    const queryObject = queryString.parse(decodeURIComponent(location?.search));
    if (
      typeof queryObject.query === 'string' &&
      queryObject.query.length !== 0
    ) {
      this.setState({
        queryInput: queryObject.query,
      });
    }

  }

  componentDidUpdate(prevProps) {
    const { isMobile } = this.props;
    if (isMobile) return;
    const { formApi } = this.state;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this._syncStateWithProps(nextProps);
  }

  static propTypes = {
    toolbar: PropTypes.object,
    topfilters: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    cards: PropTypes.array,
    name: PropTypes.string.isRequired,
    singleName: PropTypes.string,
    canCreateNew: PropTypes.bool,
    createNewProps: PropTypes.object,
    cardsPerPage: PropTypes.number,
    renderAfterTitle: PropTypes.func,
    renderCards: PropTypes.func,
    renderDetail: PropTypes.func,
    renderRight: PropTypes.func,
    renderAfterPagination: PropTypes.func,
    renderBeforeToolbar: PropTypes.func,
    handleIds: PropTypes.bool,
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    queryBack: PropTypes.string,
    fetchFunction: PropTypes.func,
    totalItems: PropTypes.number,
    sortLabel: PropTypes.string,
    detailFunction: PropTypes.func,
    search: PropTypes.object,
    pageName: PropTypes.string,
    headerClassName: PropTypes.string,
    hasLeftPanel: PropTypes.bool,
    customBackUrl: PropTypes.string,
    newItemShouldBeCheckedByAdmin: PropTypes.bool,
    createSuccessText: PropTypes.string,
    fetchSearchResult: PropTypes.func,
    onQueryChange: PropTypes.func,
    onResetClick: PropTypes.func.isRequired,
    onSearchSubmit: PropTypes.func,
    toggleRef: PropTypes.func.isRequired,
    isMobile: PropTypes.bool,
  };

  static defaultProps = {
    cards: [],
    singleName: '',
    canCreateNew: false,
    creanteNewProps: null,
    cardsPerPage: 6,
    renderAfterTitle: null,
    renderCards: (cards, filter, page, cardsPerPage, detailFunction) =>
      Cards(cards, filter, page, cardsPerPage, detailFunction),
    renderDetail: null,
    renderRight: null,
    renderAfterPagination: null,
    renderBeforeToolbar: null,
    handleIds: true,
    topFilters: false,
    toolbar: null,
    queryBack: '',
    fetchFunction: null,
    totalItems: 0,
    sortLabel: '',
    detailFunction: null,
    search: null,
    pageName: '',
    headerClassName: 'innerheader-sellandbuy',
    hasLeftPanel: true,
    customBackUrl: null,
    newItemShouldBeCheckedByAdmin: true,
    createSuccessText: '',
  };

  render() {
    const {
      toolbar,
      topfilters,
      cards,
      name,
      singleName,
      canCreateNew,
      createNewProps,
      cardsPerPage,
      renderAfterTitle,
      renderCards,
      renderDetail,
      renderRight,
      renderAfterPagination,
      renderBeforeToolbar,
      handleIds = true,
      match,
      location,
      queryBack,
      fetchFunction,
      totalItems,
      sortLabel,
      detailFunction,
      search,
      pageName,
      headerClassName,
      hasLeftPanel,
      customBackUrl,
      isMobile,
      toggleRef,
      t,
    } = this.props;

    const { queryInput } = this.state;
    const initialValues = this.getInitialValues();
    const { filter, page } = this.state;
    const queryObject = queryString.parse(location.search);
    const lcSingleName = singleName.toLowerCase();
    const createNewLink = `${match.path}/create`;
    const createNewTitle = `${t.common[0].CREATE_NEW}  ${lcSingleName}`;
    const congratulationsLink = `${match.path}/congratulations`;
    const topfiltersProps = {};
    let createSuccessText = this.props.createSuccessText;
    if (this.props.newItemShouldBeCheckedByAdmin) {
      createSuccessText = `${t.common[0].SUCCESS_FIRST} ${lcSingleName}. ${t.common[0].SUCCESS_SECOUND}`;
    }

    if (typeof topfilters === 'object') {
      topfiltersProps.filters = topfilters;
    }
    return (
      <Switch>
        {canCreateNew === true && (
          <Route
            path={createNewLink}
            render={() => (
              <CustomizedDocumentTitle title={createNewTitle}>
                <div className='transition-page'>
                  <CreateNew
                    {...createNewProps}
                    finishLink={congratulationsLink}
                    pageTitle={createNewTitle}
                  />
                  <Link
                    className='back'
                    to={queryBack ? `${match.path}?${queryBack}` : match.path}
                  >
                    {t.common[0].BACK_TO_LIST}
                  </Link>
                </div>
              </CustomizedDocumentTitle>
            )}
          />
        )}

        {canCreateNew == true && (
          <Route
            path={congratulationsLink}
            render={() => (
              <CustomizedDocumentTitle title={createNewTitle}>
                <div className='transition-page'>
                  <div className='congratulations detail'>
                    <div className='detail__whole'>
                      <div className='content'>
                        <h3>{t.common[0].CONGRATULATIONS}</h3>
                        <p>{createSuccessText}</p>
                      </div>
                      <div className='buttons'>
                        <Link to={createNewLink} className='button'>
                          <span className='button__text'>
                            + {t.common[0].ADD_NEW} {lcSingleName}
                          </span>
                        </Link>
                        <Link to='/' className='button '>
                          <span className='button__text'>
                            {t.common[0].BACK_TO_HOME_PAGE}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </CustomizedDocumentTitle>
            )}
          />
        )}

        {handleIds && (
          <Route
            path={`${match.path}/:id`}
            render={({ match: singleMatch }) => (
              <CustomizedDocumentTitle title={name}>
                <div className='transition-page transition-page_detail'>
                  <Link
                    className='back'
                    to={
                      customBackUrl
                        ? customBackUrl
                        : queryBack
                          ? `${match.path}?${queryBack}`
                          : match.path
                    }
                  >
                    {t.common[0].BACK_LISTING} {name}
                  </Link>

                  {renderDetail && renderDetail(singleMatch)}
                </div>
              </CustomizedDocumentTitle>
            )}
          />
        )}
        <Route exact path={match.path}>
          <CustomizedDocumentTitle title={name}>
            <div>

              {/*header content */}
              <header>
                <div className={`innerheader ${headerClassName}`} >
                  <h1>{name}</h1>
                </div>
              </header>
              <div> {renderAfterTitle && renderAfterTitle()}</div>
              <div>{renderBeforeToolbar && renderBeforeToolbar()}
              </div>

              {/*header content */}
              <div className="bgwhite" style={{ paddingTop: 0 }}>
                <div className='wrap'>
                  <Form
                    onSubmit={this.submit}
                    initialValues={initialValues}
                    getApi={this.setFormApi}
                    className="searchform_full partnersearchv2"
                    render={({ formState: { touched, values } }) => (
                      <Fragment>
                        <button name="searchform_full" value="search" type="submit">
                          <i className="fas fa-search"></i>
                        </button>
                        <input name="searchform_full" 
                          type="text" id="searchform_full"
                          value={queryInput}
                          onChange={this.onQueryInputChange}
                        />

                      </Fragment>
                    )}
                  />
                  <input type="checkbox" name="leftbarfilter" id="leftbarfilter" className="leftbarfilter" />
                  <label htmlFor="leftbarfilter"><i className="fas fa-filter icoleft" /> Filter <i className="fal fa-chevron-down icoright" /></label>
                  <div className={classNames([
                    {
                      'container-leftsidebar': hasLeftPanel,
                      '': !hasLeftPanel,
                    },
                  ])}
                  >
                    <div>
                      {topfilters && <MainFilterDropDown isMobile={isMobile} closeOnToggleClick={false} />}
                    </div>
                    <div>
                      <h2 className="nomargin">{pageName}
                        <div className='right'>
                          {canCreateNew && (
                            <Link
                              to={createNewLink}
                              className='button '
                            >
                              <i className='icon-plus' />

                              <span className='button__text'>{createNewTitle}</span>
                            </Link>
                          )}
                        </div>
                      </h2>
                      <hr />
                      <div className="listsection">

                        {toolbar && (
                          <Toolbar
                            {...toolbar}
                            search={search}
                            fetchFunction={fetchFunction}
                            sortLabel={sortLabel}
                          />
                        )}
                        <TransitionGroup className='transition-container'>
                          <CSSTransition
                            key={location.search}
                            {...listTransition}
                          >
                            <div className='transition-page'>
                              {renderCards(
                                cards,
                                filter,
                                page,
                                cardsPerPage,
                                detailFunction
                              )}
                            </div>
                          </CSSTransition>
                        </TransitionGroup>

                        {queryObject.view !== 'calendar' && (
                          <Pagination
                            {...{
                              page,
                              cardsPerPage,
                              fetchFunction,
                              totalItems,
                              pageName,
                              search,
                            }}
                          />
                        )}

                        {renderAfterPagination && renderAfterPagination()}
                      </div>
                    </div>

                  </div>
                </div>
                {renderRight && <div className='col-md-12'>{renderRight()}</div>}
                <div className="dotbg l2 dotb" />
              </div>
            </div>
          </CustomizedDocumentTitle>
        </Route>
      </Switch>
    );
  }
}

const mapDispatchToProps = {
  logout,
  setCurrentUser,
  fetchSearchResult,
  onResetClick: reset,
  onSearchSubmit: searchSubmit,
};

const mapStateToProps = (state) => ({
  isMobile: state.responsive.isMobile,
  t: state.language.t,
});

export default connect(mapStateToProps, mapDispatchToProps)(Page);
