/* eslint-disable no-unused-expressions */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import Dropdown from '../components/Dropdown';

// first item is always the default active item
const Sort = (props) => {
  const { items, location, fetchFunction,search } = props;
  const queryObject = queryString.parse(location.search);
  let topLabel = items[0] && items[0].label;
  items.forEach((el) => {
    if(el.order===queryObject.order){
      topLabel=el.label;
    }
  });

  return (
    <div className='sort-by'>
      <span className='info'>{props.t.common[0].SORT_BY}:</span>

      <Dropdown
        exact
        closeOnClick
        addClassName='dropdown_white'
        top={topLabel}
        links={items.map((el) => {
          const toObject = {
            ...queryObject,
            order: el.order,
            orderBy: el.orderBy,
          };
          return {
            to: `${location.pathname}?${queryString.stringify(toObject)}`,
            title: el.label,
            fetchFunction: fetchFunction,
            onClick: (e) => {
              fetchFunction
                ? fetchFunction(
                    `${location.pathname}?${queryString.stringify(toObject)}`,
                    search
                  )
                : '';
            },
          };
        })}
      />

      <i className='icon-dropdown' />
    </div>
  );
};

const mapStateToProps = (state) => ({ t: state.language.t });

export default withRouter(connect(mapStateToProps)(Sort));
