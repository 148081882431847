import { FAQS, STATIC_PAGES } from '../store/actions';

const initialState = {
  faqs: [],
  staticData: [],
};

export default function staticPages(state = initialState, action) {
  switch (action.type) {
    case FAQS:
      return {
        ...state,
        faqs: action.payload.data,
      };
    case STATIC_PAGES:
      return {
        ...state,
        staticData: action.payload.data,
      };
    default:
      return state;
  }
}
