import './sass/main.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import objectFitImages from 'object-fit-images';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { Provider } from 'react-redux';
import 'react-dates/initialize';
import Modal from 'react-modal';

import { REACT_ROOT_ID } from './constants';
import { persistor, store } from './store/createStore';
import App from './components/App';
import ApplozicProvider from './components/ApplozicProvider';
import Preloader from './components/Preloader';

import './bootstrap';

import reportWebVitals from './reportWebVitals';

import TagManager from "react-gtm-module";
import ReactGA from 'react-ga';


const main = document.createElement('div');
main.id = REACT_ROOT_ID;
document.body.insertBefore(main, document.body.firstChild);

let token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Preloader />} persistor={persistor}>
      <ApplozicProvider>
        <App />
      </ApplozicProvider>
    </PersistGate>
  </Provider>,
  main
);

Modal.setAppElement(main);

objectFitImages();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
