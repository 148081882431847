export default function ({ number, postfix = '', digitsAfterComma = 0 }) {
  const pretty = (number, power = 1) =>
    (number * power).toFixed(digitsAfterComma);
  if (number < 1e3) {
    return `${pretty(number, 1)}${postfix}`;
  } else if (number < 1e6) {
    return `${pretty(number, 1e-3)}K${postfix}`;
  } else if (number < 1e9) {
    return `${pretty(number, 1e-6)}M${postfix}`;
  } else {
    return `${pretty(number, 1e-9)}G${postfix}`;
  }
}
