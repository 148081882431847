import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import classNames from 'classnames';
import Preloader from '../components/Preloader';
import formatNotificationTime from '../helpers/format-notification-time';
import noAvatarImage from '../static/images/noavatar.svg';
import { connect } from 'react-redux';
import { reloadMessenger, fetchMessageTabData } from '../store/actions';
import emojifyText from '../pages/Messenger/components/MessengerBody/components/Chat/components/NewMessage/emojifyText';
import formatNumber from '../helpers/format-number';

const GroupCount = ({ number }) => {
  const formattedNumber = `+${formatNumber({ number })}`;
  let styles;
  switch (formattedNumber.length) {
    case 4:
      styles = { fontSize: '9px' };
      break;
    case 5:
      styles = { fontSize: '7px' };
      break;
    default:
      styles = null;
  }
  return (
    <div className='group-plus' style={styles}>
      {formattedNumber}
    </div>
  );
};

const NotificationAvatar = ({
  icon,
  online,
  avatar,
  groupAvatars,
  limit = 3,
  isBig = false,
}) => (
  <div
    className={classNames([
      'notification__image',
      { online, notification__image_group: groupAvatars },
    ])}
  >
    {icon ? (
      <i className={`icon-${icon}`} />
    ) : !groupAvatars ? (
      <img src={avatar} alt='' />
    ) : isBig ? (
      <Fragment>
        {groupAvatars.slice(0, limit).map((src, i) => (
          <img src={src || noAvatarImage} key={i} alt='' />
        ))}
        {groupAvatars.length > limit && (
          <GroupCount number={groupAvatars.length - limit} />
        )}
      </Fragment>
    ) : (
      <GroupCount number={groupAvatars.length} />
    )}
  </div>
);

const mapStateToProps = (state) => ({
  applozicApi: state.messenger.applozicApi,
  applozicApiFetching: state.messenger.applozicApiFetching,
  stateCache: state.messenger.cache,
  userId: state.auth.user && state.auth.user.id,
  offset: state.notifications.offset,
  t: state.language.t,
});

const mapDispatchToProps = {
  reloadMessenger,
  fetchMessageTabData,
};

class ListOfNotifications extends React.Component {
  static propTypes = {
    applozicApi: PropTypes.object,
    applozicApiFetching: PropTypes.bool,
    fetchItems: PropTypes.func,
    items: PropTypes.array,
    stateKey: PropTypes.string,
    stateCache: PropTypes.object,
    reloadMessenger: PropTypes.func,
    tab: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    button: PropTypes.object,
    limit: PropTypes.number,
    noCircles: PropTypes.bool,
    defaultAvatar: PropTypes.string,
    itemClassName: PropTypes.string,
    fullyClickable: PropTypes.bool,
    t: PropTypes.object,
  };

  static defaultProps = {
    fetchItems: () => [],
    button: null,
    noCircles: false,
    defaultAvatar: noAvatarImage,
    itemClassName: 'notification',
    fullyClickable: false,
  };

  componentDidMount() {
    if (this.props.applozicApi && !this.props.items) {
      this.fetch();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.applozicApi && this.props.applozicApi && !this.props.items) {
      this.fetch();
    }
  }

  fetch() {
    const { applozicApi } = this.props;
    const cache = this.getCache();
    const { value, isPending } = cache;

    if (!value && !isPending) {
      const { stateKey, fetchItems, reloadMessenger } = this.props;
      reloadMessenger({
        key: stateKey,
        fetchItems: () => fetchItems(applozicApi),
      });
    }
  }

  getCache() {
    const { stateCache, stateKey } = this.props;
    return stateCache[stateKey] || {};
  }

  render() {
    const {
      limit,
      noCircles,
      button,
      defaultAvatar,
      itemClassName,
      items,
      fullyClickable,
      tab,
      location,
      applozicApiFetching,
      fetchMessageTabData,
      fetchItems,
      t,
    } = this.props;

    let itemsToRender;
    const cache = this.getCache();
    const { value, isPending } = cache;
    if (items) {
      if (limit) {
        itemsToRender = items.slice(0, limit);
      } else {
        itemsToRender = items;
      }
    } else if (value) {
      if (limit) {
        itemsToRender = value.slice(0, limit);
      } else {
        itemsToRender = value;
      }
    }

    if (this.props.stateKey === 'notifications') {
      itemsToRender = [
        {
          id: 'all',
          icon: 'info',
          name: t.messages[0].ALL_NOTIFICATIONS,
          text: t.messages[0].NOTIFICATIONS,
        },
        {
          id: 'tenders-and-offers',
          icon: 'gavel',
          name: t.messages[0].TENDERS_AND_OFFERS,
          text: t.messages[0].NOTIFICATION_CATEGORY,
        },
        {
          id: 'verification-requests',
          icon: 'check--circle',
          name: t.messages[0].VERIFICATION_REQUESTS,
          text: t.messages[0].NOTIFICATION_CATEGORY,
        },
        {
          id: 'comments',
          icon: 'comment--notification',
          name: t.messages[0].NEW_COMMENTS,
          text: t.messages[0].NOTIFICATION_CATEGORY,
        },
      ];
    }

    const queryObject = queryString.parse(location.search);

    return (
      <Fragment>
        {items === null ||
          (fetchItems && isPending) ||
          (!fetchItems && applozicApiFetching) ? (
          <div className='list-animation-item'>
            <Preloader size='small' relative />
          </div>
        ) : itemsToRender && itemsToRender.length ? (
          <div className='notifications'>
            {itemsToRender.map((el, i) => {
              // id is unique for set of notifications
              // itemId can be similar and lead to one and the same tab (e. g. two notifications can have
              // different id but be displayed both under /messenger?tab=notifications&item=tenders-and-offers

              const {
                icon,
                avatar = defaultAvatar,
                groupAvatars,
                online,
                text,
                action,
                relatedItem,
                time,
                unread,
                id,
                itemId,
              } = el;

              // fix 'All contacts' translation
              let name = el.name;
              if (id === 'all') {
                name = t.messages[0].ALL_CONTACTS;
              }

              const toObject = {
                ...queryObject,
                tab,
                item: itemId || id,
                id: itemId ? id : undefined,
              };

              const to = `/messenger?${queryString.stringify(toObject)}`;
              if (el.hasOwnProperty('userNamewithAvatar')) {
                var linkTo = `/members/${encodeURIComponent(name)
                  .replace(/%20/g, '+')
                  .replace(/%/g, '')}/${el.userNamewithAvatar.type}/${el.userNamewithAvatar.dataId
                  }`;
              } else {
                var linkTo = window.location.hash;
              }

              if (fullyClickable) {
                return (
                  <NavLink
                    to={to}
                    onClick={() => fetchMessageTabData(to, this.props.offset)}
                    className={`list-animation-item ${itemClassName}`}
                    key={id || i}
                    isActive={() => queryObject.item === (itemId || id)}
                  >
                    <NotificationAvatar
                      online={online}
                      avatar={avatar}
                      groupAvatars={groupAvatars}
                      icon={icon}
                      isBig={itemClassName.includes('big')}
                    />

                    <div className='notification__right'>
                      <p className='notification__top'>
                        <span className='notification__title' title={name}>
                          {name}
                        </span>

                        <span className='notification__secondary'>
                          {!unread && time && (
                            <span className='info'>
                              {formatNotificationTime(time)}
                            </span>
                          )}

                          {!!unread && noCircles && (
                            <span title={`${unread} unread messages`}>{unread}</span>
                          )}

                          {!!unread && !noCircles && (
                            <span
                              className='pl-circle pl-circle--dark pl-circle--sm pl-circle--centered'
                              title={`${unread} unread messages`}
                            >
                              {unread}
                            </span>
                          )}
                        </span>
                      </p>

                      <div className='notification__bottom'>
                        <span
                          className='gray'
                          dangerouslySetInnerHTML={{
                            __html: emojifyText(text || action, 16),
                          }}
                        />

                        <span className='brand'>{relatedItem}</span>
                      </div>
                    </div>
                  </NavLink>
                );
              }

              return (
                <div
                  className={`list-animation-item ${itemClassName}`}
                  key={id || i}
                >
                  <NotificationAvatar
                    online={online}
                    avatar={avatar}
                    groupAvatars={groupAvatars}
                    icon={icon}
                  />

                  <div className='notification__right'>
                    <p className='notification__top'>
                      <Link
                        to={linkTo}
                        className='notification__title'
                        title={name}
                      >
                        <b>{name}</b>
                      </Link>

                      <span className='notification__secondary'>
                        <Link to={to}>
                          {!unread && time && (
                            <span className='info'>
                              {formatNotificationTime(time)}
                            </span>
                          )}

                          {!!unread && noCircles && (
                            <b
                              title={`${unread} ${t.messages[0].UNREAD_MESSAGES}`}
                            >
                              {unread}
                            </b>
                          )}

                          {!!unread && !noCircles && (
                            <span
                              className='pl-circle pl-circle--dark pl-circle--sm pl-circle--centered'
                              title={`${unread} ${t.messages[0].UNREAD_MESSAGES}`}
                            >
                              {unread}
                            </span>
                          )}
                        </Link>
                      </span>
                    </p>

                    <div className='notification__bottom'>
                      <Link to={to}>
                        <span
                          className='gray'
                          dangerouslySetInnerHTML={{
                            __html: emojifyText(text || action, 16),
                          }}
                        />

                        <span className='brand'>{relatedItem}</span>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <p className='list-animation-item content'>
            {t.header[0].BELL_NOTIFICATION_EMPTY}
          </p>
        )}

        {button && (
          <div className='list-animation-item notifications-button'>
            {button.to ? (
              <NavLink
                to={button.to}
                className='button button_flat button_gray'
                isActive={(match, destination) =>
                  destination.pathname + destination.search === button.to
                }
                onClick={button.onClick}
              >
                <span className='button__text'>{button.title}</span>
              </NavLink>
            ) : (
              <button
                className='button'
                onClick={button.onClick}
              >
                <span className='button__text'>{button.title}</span>
              </button>
            )}
          </div>
        )}
      </Fragment>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListOfNotifications)
);
