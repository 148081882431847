import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';

import UserNameWithAvatar from '../../components/UserNameWithAvatar';
import ButtonBookmark from '../../components/ButtonBookmark';
import Region from '../../components/Region';

const DATE_FORMAT = 'MMM DD, YYYY';

const Card = (props) => {
  const {
    userNameWithAvatar,
    value,
    title,
    isLoading,
    dates,
    parentLocation,
    id,
    region,
    cardType,
    bookmarked,
    tab,
    t,
  } = props;

  const viewRegion = isLoading ? { name: <span>&nbsp;</span> } : region;
  const viewUserNameWithAvatar = isLoading
    ? { name: <span>&nbsp;</span> }
    : userNameWithAvatar;
  // eslint-disable-next-line no-nested-ternary
  const viewDates = isLoading ? (
    <span>&nbsp;</span>
  ) : typeof dates === 'object' ? (
    `${moment(dates.opening).format(DATE_FORMAT)} - ${moment(
      dates.closing
    ).format(DATE_FORMAT)}`
  ) : (
    ''
  );

  const to = `${parentLocation}/${id}`;

  return (
    <section
      className={classNames('dashboard-card', 'list-animation-item', {
        loading: isLoading,
      })}
    >
      <div className='dashboard-card__top'>
        <div className='flex'>
          <div className='left'>
            <UserNameWithAvatar {...viewUserNameWithAvatar} />
          </div>

          <div className='right'>
            <p className='h3 dashboard-card__value'>{value}</p>
          </div>
        </div>
      </div>

      <Link to={to} className='dashboard-card__title'>
        <h3>{title}</h3>
      </Link>

      {(isLoading || typeof dates === 'object') && (
        <p className='dashboard-card__dates-overflow'>
          <span className='gray'>{t.common[0].DATES}: </span>

          <span className='dashboard-card__dates'>{viewDates}</span>
        </p>
      )}

      <div className='dashboard-card__bottom flex'>
        {viewRegion && (
          <div className='left'>
            <Region {...viewRegion} />
          </div>
        )}

        <div className='right'>
          <ButtonBookmark
            id={id}
            bookmarked={bookmarked}
            type={cardType}
            dashboard={true}
            tab={tab}
          />
        </div>
      </div>
    </section>
  );
};

Card.propTypes = {
  userNameWithAvatar: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  isLoading: PropTypes.bool,
  dates: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  parentLocation: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  region: PropTypes.object,
  cardType: PropTypes.string,
  bookmarked: PropTypes.bool,
  tab: PropTypes.string,
  t: PropTypes.object,
};

Card.defaultProps = {
  userNameWithAvatar: null,
  id: '',
  parentLocation: '',
  value: <span>&nbsp;</span>,
  title: <span>&nbsp;</span>,
  cardType: '',
  bookmarked: false,
  tab: '',
};

const mapStateToProps = (state) => ({ t: state.language.t });

export default connect(mapStateToProps)(Card);
