/* eslint-disable no-unused-expressions */
import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { Form } from 'informed';
import { DateRangePicker } from 'react-dates';
import { START_DATE } from 'react-dates/src/constants';
import classNames from 'classnames';
import DropdownTree from '../../../../components/settings/DropdownTree';
import TopFilter from './TopFilter';
import SelectTopFilter from './SelectTopFilter';
import PriceSlider from './PriceSlider';
import ClearAll from './ClearAll';
import queryString from 'query-string';
import {
  ROUTE_FETCH_PRODUCTS,
  ROUTE_FETCH_SERVICES,
  ROUTE_FETCH_COUNTRIES,
} from '../../../../components/settings/DropdownTree/fetchDropdownTrees';
import {
  PRODUCT_ID,
  SERVICE_ID,
  NAME,
  COUNTRY,
  DATES,
  VALUE,
  EVENT_SECTOR,
  EVENT_TYPE,
  WEBINAR_SECTOR,
} from '../../../../components/Search/TopFilters/types';
import 'react-dates/lib/css/_datepicker.css';
import Input from '../../../../components/settings/Input';
import Select from '../../HeaderDropdown/components/Select';

import { filterData } from '../../../../store/actions';

class TopFiltersDesktop extends React.Component {
  static propTypes = {
    countriesOptions: PropTypes.array.isRequired,
    onCountriesChange: PropTypes.func.isRequired,
    datepicker: PropTypes.object.isRequired,
    filters: PropTypes.array,
    isClearButtonDisabled: PropTypes.bool,
    name: PropTypes.string,
    onNameChange: PropTypes.func,
    onClear: PropTypes.func.isRequired,
    onDatepickerDateChange: PropTypes.func.isRequired,
    onDatepickerFocusChange: PropTypes.func.isRequired,
    eventsTypeOptions: PropTypes.array.isRequired,
    eventsSectorOptions: PropTypes.array.isRequired,
    onEventTypeChange: PropTypes.func.isRequired,
    onEventSectorChange: PropTypes.func.isRequired,
    webinarsSectorOptions: PropTypes.array.isRequired,
    onWebinarSectorChange: PropTypes.func.isRequired,
    t: PropTypes.object.isRequired,
  };

  static defaultProps = {
    filters: [PRODUCT_ID, SERVICE_ID, COUNTRY],
    isClearButtonDisabled: false,
  };

  constructor() {
    super();
    this.state = {
      name: '',
      formApi: null,
      queryInput: '',
    };
    this.onQueryInputChange = this.onQueryInputChange.bind(this);
    this.setFormApi = this.setFormApi.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    this.props.fetchTopFilterCountries();
  }

  componentDidUpdate(prevProps) {
    const { isMobile } = this.props;
    if (isMobile) return;
  }


  onQueryInputChange(e) {
    console.log(e.target.value);
        this.setState({
      queryInput: e.target.value,
    });
    this.submit();
  }

  persist() {
    const { onSubmit } = this.props;
    const { values } = this.state;
    if (onSubmit) {
      onSubmit(values);
    }
  }

  setFormApi(formApi) {
    this.setState({
      formApi,
    });
  }

  submit() {
    const { history } = this.props;
    console.log(history);
    const { formApi, queryInput } = this.state;
    const trimmedQuery = encodeURIComponent(queryInput.trim());
    var pageUrl = window.location.hash.split('/');
    console.log(window.location.hash.split('#')[0]);
    var currentTab = '';
    if (pageUrl[1]) {
      pageUrl = pageUrl[1];
      pageUrl = pageUrl.split('?');
      if (pageUrl[0] && pageUrl[0] === 'search') {
        var qParam = queryString.parse(decodeURIComponent(pageUrl[1]));
        if (qParam['tab']) {
          currentTab = 'tab=' + qParam['tab'];
        }
      }
    }
    const formState = formApi.getState();
    if (Object.keys(formState.touched) !== 0 || trimmedQuery.length !== 0) {
      const location = `${window.location.hash.split('#')[0]}?query=${trimmedQuery}&${filterData(currentTab, formState.values)} &${currentTab}`;
      this.persist(formState.values, location);
      this.props.history.push(location);
    }
  }

  setTypedName(name) {
    this.setState({
      ...this.state,
      name,
    });
  }

  render() {
    const {
      onClear,
      isClearButtonDisabled,
      filters,
      onTreeChange,
      isMobile,
      t,
    } = this.props;
    return (
      <div
        className={classNames([
          'container-leftsidebar',
          { 'top-filters_mobile': isMobile },
        ])}
      >
        <div className='container-leftsidebar'>
          <Form
            action=''
            onSubmit={(e) => {
              e.preventDefault;
              this.submit();
            }}
            getApi={this.setFormApi}
            render={({ formState: { touched, values } }) => (
              <Fragment>
                <section>
                  {filters.map((filter) => {
                    // eslint-disable-next-line default-case
                    switch (filter) {
                      case PRODUCT_ID:
                        const { productIdCount } = this.props;
                        return (
                          <TopFilter
                            key={filter}
                            buttonText={`${t.common[0].ID_NAME_PRODUCT}${productIdCount ? ` — ${productIdCount}` : ''
                              }`}
                            modified={!!productIdCount}
                            onCancel={() => onTreeChange({ productId: null })}
                            noBottom={isMobile}
                          >
                             <section>
                             <h4>Product Type</h4>
                            <DropdownTree
                              route={ROUTE_FETCH_PRODUCTS}
                              value={this.props.productId}
                              onSubmit={(productId) => onTreeChange({ productId })}
                              dropdownType={isMobile ? 'none' : 'dropdown'}
                            />
                            </section>

                          </TopFilter>
                        );

                      case SERVICE_ID:
                        const { serviceIdCount } = this.props;
                        return (
                          <TopFilter
                            key={filter}
                            buttonText={`${t.common[0].ID_NAME_SERVICE}${serviceIdCount ? ` — ${serviceIdCount}` : ''
                              }`}
                            modified={!!serviceIdCount}
                            onCancel={() => onTreeChange({ serviceId: null })}
                            noBottom={isMobile}
                          >
                             <section>
                             <h4>Service Type</h4>
                            <DropdownTree
                              route={ROUTE_FETCH_SERVICES}
                              value={this.props.serviceId}
                              onSubmit={(serviceId) => onTreeChange({ serviceId })}
                              dropdownType={isMobile ? 'none' : 'dropdown'}
                            />
                            </section>
                          </TopFilter>
                        );

                      case NAME: {
                        const { name, onNameChange } = this.props;
                        const { name: userTypingName } = this.state;
                        const onNameModalApply = () => {
                          onNameChange(userTypingName);
                          this.setTypedName(userTypingName);
                        };
                        let buttonText = t.messages[0].NAME;
                        if (name) {
                          buttonText += ` — '${name}'`;
                        }

                        return (
                          <TopFilter
                            key={filter}
                            buttonText={buttonText}
                            modified={!!name}
                            onApply={onNameModalApply}
                            onCancel={() => this.setTypedName(name)}
                            onAfterOpen={() => this.name.focus()}
                            contentStyle={{ width: 350 }}
                          >
                          </TopFilter>
                        );
                      }

                      case COUNTRY:
                        const {
                          countriesOptions,
                          selectedCountries,
                          onCountriesChange,
                          countryIdCount,
                        } = this.props;

                        return (
                          <TopFilter
                            key={filter}
                            buttonText={`${t.common[0].COUNTRY}${countryIdCount ? ` — ${countryIdCount}` : ''
                              }`}
                            modified={!!countryIdCount}
                            onCancel={() => onTreeChange({ selectedCountries: null })}
                            noBottom={isMobile}
                          >
                            <section>
                             <h4>Countries</h4>
                              <Input
                                value={selectedCountries}
                                onChange={onCountriesChange}
                                options={countriesOptions}
                                id='countryId'
                                type='dropdown-tree'
                                dropdownTreeOptions={{
                                  route: ROUTE_FETCH_COUNTRIES,
                                  position: 'bottom',
                                  defaultOpenedToplevel: ['ASIA'],
                                }}
                              />
                              </section>
                          </TopFilter>
                        );

                      case WEBINAR_SECTOR:
                        const {
                          selectedWebinarsSector,
                          onWebinarSectorChange,
                        } = this.props;
                        return (
                          <SelectTopFilter
                            value={selectedWebinarsSector}
                            onChange={onWebinarSectorChange}
                            optionUrl='/events/sector'
                            labelKey='sector'
                            id='selectedWebinarsSectorId'
                            label={t.common[0].SECTOR}
                            buttonLabel={t.common[0].SECTOR}
                            placeholder={t.common[0].SECTOR_PLACEHOLDER}
                          />
                        );

                      case EVENT_SECTOR:
                        const {
                          selectedEventsSector,
                          onEventSectorChange,
                        } = this.props;
                        return (
                          <SelectTopFilter
                            value={selectedEventsSector}
                            onChange={onEventSectorChange}
                            optionUrl='/events/sector'
                            labelKey='sector'
                            id='selectedEventsSectorId'
                            label={t.common[0].SECTOR}
                            buttonLabel={t.common[0].SECTOR}
                            placeholder={t.common[0].SECTOR_PLACEHOLDER}
                          />
                        );

                      case EVENT_TYPE:
                        const {
                          selectedEventsType,
                          onEventTypeChange,
                        } = this.props;
                        return (
                          <SelectTopFilter
                            value={selectedEventsType}
                            onChange={onEventTypeChange}
                            optionUrl='/events/type'
                            labelKey='type'
                            id='selectedEventsTypeIds'
                            label={t.common[0].TYPE}
                            buttonLabel={t.common[0].TYPE}
                            placeholder={t.common[0].TYPE_PLACEHOLDER}
                          />
                        );

                      case DATES:
                        const {
                          datepicker,
                          onDatepickerDateChange,
                          onDatepickerFocusChange,
                        } = this.props;
                        let isDatepickerModified = false;
                        Object.values(datepicker).forEach(
                          ({ start_date, end_date }) => {
                            if (start_date || end_date) {
                              isDatepickerModified = true;
                            }
                          }
                        );

                        const dates = (
                          <div className='top-filter-datepicker'>
                            <ul className='top-filter-tabs'>
                              {Object.keys(datepicker).map((key) => {
                                const {
                                  start_date,
                                  end_date,
                                  focused_input,
                                } = datepicker[key];

                                return (
                                  <li key={key}>
                                    <span>{key} dates — </span>

                                    <span className='calendar calendar_range-picker calendar_with-long-arrows'>
                                      <DateRangePicker
                                        startDate={start_date}
                                        startDateId='closeStartDate'
                                        endDate={end_date}
                                        endDateId='closeEndDate'
                                        focusedInput={
                                          isMobile || key === 'opening'
                                            ? focused_input || START_DATE
                                            : focused_input
                                        }
                                        onDatesChange={(e) =>
                                          onDatepickerDateChange(e, key)
                                        }
                                        onFocusChange={(e) =>
                                          onDatepickerFocusChange(e, key)
                                        }
                                        numberOfMonths={isMobile ? 1 : 2}
                                        daySize={30}
                                        hideKeyboardShortcutsPanel={true}
                                        navPrev={<i className='icon-arrow--long' />}
                                        navNext={<i className='icon-arrow--long' />}
                                        weekDayFormat='dd'
                                        noBorder
                                        customArrowIcon=' - '
                                        displayFormat='DD/MM/YY'
                                        enableOutsideDays
                                        isOutsideRange={() => false}
                                        minimumNights={0}
                                      />
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        );

                        return (
                          <TopFilter
                            key={filter}
                            buttonText={t.common[0].OPENING_CLOSING_DATES}
                            modified={isDatepickerModified}
                            noBottom
                          >
                            {isMobile ? (
                              <Scrollbars
                                autoHide
                                style={{ height: 'calc(100vh - 80px)' }}
                              >
                                {dates}
                              </Scrollbars>
                            ) : (
                              dates
                            )}
                          </TopFilter>
                        );

                      case VALUE:
                        const {
                          onPriceRangeChange,
                          onPriceRangeChangeEnd,
                          priceRange,
                          results,
                        } = this.props;

                        let valueButtonText = 'Value';
                        if (priceRange) {
                          const { min, max } = priceRange;
                          if (min && max) {
                            valueButtonText += ` — from ${min} to ${max}`;
                          } else if (min) {
                            valueButtonText += ` — from ${min}`;
                          } else if (max) {
                            valueButtonText += ` — to ${max}`;
                          }
                        }

                        return (
                          <TopFilter
                            key={filter}
                            buttonText={valueButtonText}
                            modified={!!priceRange}
                            onCancel={() => onPriceRangeChange(null)}
                          >
                            <PriceSlider
                              results={results}
                              range={priceRange}
                              onRangeChange={onPriceRangeChange}
                              onRangeChangeEnd={onPriceRangeChangeEnd}
                            />
                          </TopFilter>
                        );
                    }
                  })}
                </section>
                <div className='buttons'>
                  <button
                    className='button'
                    type='submit'
                    onChange={this.onQueryInputChange}

                  >
                    <i className='icon-search' />
                    <span className='button__text'>Apply Filters</span>
                  </button>

                  <button
                    className='button button_minimal'
                    type='button'
                    onClick={onClear}
                  >
                    <span className='button__text'>Clear Filter</span>
                  </button>
                </div>
              </Fragment>
            )}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(TopFiltersDesktop);
