import { Form } from 'informed';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import CreateNew from '../components/CreateNew';
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import Modal from '../components/Modal';
import {
  ROUTE_FETCH_COUNTRIES,
  ROUTE_FETCH_PRODUCTS,
  ROUTE_FETCH_SERVICES
} from '../components/settings/DropdownTree/fetchDropdownTrees';
import Input from '../components/settings/Input';
import MultiInputField from '../components/settings/MultiInput/components/Field';
import TopMessage from '../components/TopMessage';
import highlightFirstError from '../helpers/highlight-first-error';
import languages from '../helpers/languages';
import axios from '../requests/platformApi';
import {
  attemptRegistration, clearExistingCompanyValues, closeValidationError, companyChange, doRegistrationErrors, fetchCities, fetchCompanies, fetchRegistrationInitialStates, saveCompaniesTabDetails, saveInfoTabDetails, saveProductsTabDetails, setRegistrationsValidationsUserType
} from '../store/actions';
import validateRegistrationForm from '../validations/validateRegistration';

var language = languages['en'];
var create = language.registration[0];
var menu = language.menu[0];
var common = language.common[0];
var labels = language.completeregistration[0];

var completeRegistrationSuccessBuyer =
  language.registration_successful_popup2[0];
var completeRegistrationSuccessSeller =
  language.registration_successful_popup3[0];
var completeRegistrationSuccessVerifier =
  language.registration_successful_popup4[0];

const mapStateToProps = (state) => {
  if (state.registration.hasOwnProperty('companySize')) {
    var i = 0;
    state.registration.companySize[i] = {
      label: common.SELECT,
      value: '',
    };

    for (i = 1; i <= 10; i++) {
      state.registration.companySize[i] = {
        label: i,
        value: i,
      };
    }
  } else {
    state.registration.companySize = [];
  }

  if (state.registration.hasOwnProperty('noOfManagementTeam')) {
    var i = 0,
      j = 0;
    state.registration.noOfManagementTeam[i] = {
      label: common.SELECT,
      value: '',
    };

    for (i = 0, j = 1; i <= 100; i += 10, j++) {
      state.registration.noOfManagementTeam[j] = {
        label: i + '%',
        value: i,
      };
    }
  }

  if (state.registration.hasOwnProperty('noOfManagementTeamWomen')) {
    var i = 0;
    state.registration.noOfManagementTeamWomen[i] = {
      label: common.SELECT,
      value: '',
    };

    for (i = 1; i <= 10; i++) {
      state.registration.noOfManagementTeamWomen[i] = {
        label: i,
        value: i,
      };
    }
  } else {
    state.registration.noOfManagementTeamWomen = [];
  }
  if (state.registration.hasOwnProperty('numberOfEmployees')) {
    var i = 0;
    state.registration.numberOfEmployees[i] = {
      label: common.SELECT,
      value: '',
    };

    for (i = 10; i <= 100; i += 10) {
      state.registration.numberOfEmployees[i] = {
        label: i,
        value: i,
      };
    }
  } else {
    state.registration.numberOfEmployees = [];
  }

  if (state.registration.hasOwnProperty('noOfEmployeesWomen')) {
    var i = 0;
    state.registration.noOfEmployeesWomen[i] = {
      label: common.SELECT,
      value: '',
    };

    for (i = 10; i <= 100; i += 10) {
      state.registration.noOfEmployeesWomen[i] = {
        label: i,
        value: i,
      };
    }
  } else {
    state.registration.noOfEmployeesWomen = [];
  }
  if (state.registration.hasOwnProperty('exportingYearExperience')) {
    var i = 0;
    state.registration.exportingYearExperience[i] = {
      label: common.SELECT,
      value: '',
    };

    for (i = 1; i <= 10; i++) {
      state.registration.exportingYearExperience[i] = {
        label: i,
        value: i,
      };
    }

    state.registration.exportingYearExperience[i] = {
      label: common.MORE_THAN_10,
      value: 11,
    };
  } else {
    state.registration.exportingYearExperience = [];
  }
  // //countries dropdown
  // if (state.registration.hasOwnProperty('countries')) {
  //   var countries2 = [];
  //   var i = 0;
  //   countries2[i] = {};
  //   countries2[i]['label'] = common.COUNTRY_SELECT;
  //   countries2[i]['value'] = '';
  //   i = 1;
  //   for (var item in state.registration.countries) {
  //     countries2[i] = {};
  //     countries2[i]['label'] = state.registration.countries[item].country_name;
  //     countries2[i]['value'] = state.registration.countries[item].id;
  //     i++;
  //   }
  // } else {
  //   var countries2 = [];
  // }

  if (state.registration.hasOwnProperty('cities')) {
    var cities = [];
    var i = 0;
    cities[i] = {};
    cities[i]['label'] = common.SELECT_A_CITY;
    cities[i]['value'] = '';
    i = 1;
    for (var item in state.registration.cities) {
      cities[i] = {};
      cities[i]['label'] = state.registration.cities[item].city;
      cities[i]['value'] = state.registration.cities[item].id;
      i++;
    }
  } else {
    var cities = [];
  }

  return {
    companyValidations: state.registration.companyValidation,
    existingCompanyId: state.registration.existingCompanyId,
    existingCompanyDetails: state.registration.existingCompanyDetails,
    countries: state.registration.countries,
    companySize: state.registration.companySize,
    noOfManagementTeam: state.registration.noOfManagementTeam,
    noOfManagementTeamWomen: state.registration.noOfManagementTeamWomen,
    numberOfEmployees: state.registration.numberOfEmployees,
    noOfEmployeesWomen: state.registration.noOfEmployeesWomen,
    exportingYearExperience: state.registration.exportingYearExperience,
    cities: cities,
    //countries2: countries2,
    hasError: state.registration.formHasError,
    errorMessages: state.registration.errorMessages,
    formHasError: state.registration.formHasError,
    mainErrorMessage: state.registration.mainErrorMessage,
    showRegistrationMessage: state.registration.registrationSuccess,
    registrationMessage: state.registration.registrationMessage,
    userId: state.auth.user.id,
    userRole: state.auth.user.userRole,
    gender: state.auth.user.gender,
    countryId: state.auth.user.country,
    lan: state.language.lan,
  };
};

const mapDispatchToProps = {
  fetchRegistrationInitialStates,
  fetchCities,
  setRegistrationsValidationsUserType,
  companyChange,
  saveInfoTabDetails,
  saveCompaniesTabDetails,
  saveProductsTabDetails,
  attemptRegistration,
  closeValidationError,
  doRegistrationErrors,
  fetchCompanies,
  clearExistingCompanyValues,
};

class CompanyRegistration extends React.Component {
  static propTypes = {
    as: PropTypes.string.isRequired,
  };

  constructor() {
    super();
    this.state = {
      companyTypes: [
        { value: '1', label: create.COMPANY_TYPE_LABEL_ONE },
        { value: '2', label: create.COMPANY_TYPE_LABEL_TWO },
        { value: '3', label: create.COMPANY_TYPE_LABEL_THREE },
        { value: '4', label: create.COMPANY_TYPE_LABEL_FOUR },
        { value: '5', label: create.COMPANY_TYPE_LABEL_FIVE },
        { value: '6', label: create.COMPANY_TYPE_LABEL_SIX },
        { value: '7', label: create.COMPANY_TYPE_LABEL_SEVEN },
      ],
      companySizeOptions: [
        { value: '1', label: create.COMPANY_SIZE_LABEL_ONE },
        { value: '2', label: create.COMPANY_SIZE_LABEL_TWO },
        { value: '3', label: create.COMPANY_SIZE_LABEL_THREE },
        { value: '4', label: create.COMPANY_SIZE_LABEL_FOUR },
        { value: '5', label: create.COMPANY_SIZE_LABEL_FIVE },
        { value: '6', label: create.COMPANY_SIZE_LABEL_SIX },
        { value: '7', label: create.COMPANY_SIZE_LABEL_SEVEN },
        { value: '8', label: create.COMPANY_SIZE_LABEL_EIGHT },
        { value: '9', label: create.COMPANY_SIZE_LABEL_NINE },
      ],
      primaryCustomersOptions: [
        {
          value:
            'Multinational Corporation (MNC). Tier 1 or 2 supplier to an MNC',
          label: create.MNC_DETAILS,
        },
        {
          value: 'Small and Medium Enterprises',
          label: create.SMALL_MEDIUM_ENTERPRISES,
        },
        {
          value: 'Local state or national government agencies',
          label: create.LOCAL_STATE_NATIONAL_GOVT_AGENCIES,
        },
        { value: 'NGOs', label: create.NGOS },
        {
          value: 'International Institutions',
          label: create.INTERNATIONAL_INSTITUTIONS,
        },
        { value: 'Wholesalers/ retailers', label: create.WHOLESALE_RETAILERS },
        { value: 'importer/ agent', label: create.IMPORTER_AGENT },
        { value: 'Others', label: create.OTHER_PLEASE_SPECIFY },
      ],
      newCompanyName: '',
      companyNames: [],
      exportDisplay: true,
    };

    this.onCompanyChange = this.onCompanyChange.bind(this);
    this.exportChangeHandle = this.exportChangeHandle.bind(this);
    this.onUserTypeChange = this.onUserTypeChange.bind(this);
    this.searchCompanies = this.searchCompanies.bind(this);
  }

  componentDidMount() {
    language = languages[this.props.lan];
    create = language.registration[0];
    common = language.common[0];
    menu = language.menu[0];
    this.props.fetchRegistrationInitialStates();
    if (this.props.as == 'Verifier') {
      this.props.setRegistrationsValidationsUserType(2);
    }
  }

  async onFinish(multiStepForm) {
    await multiStepForm.onFinish();
    this.props.attemptRegistration(
      multiStepForm.formStates,
      this.props.as,
      this.props.existingCompanyId,
      this.props.countryId
    );
  }

  onCompleteRegistration = (e) => {
    e.preventDefault();
    this.setState({
      isModalOpened: false,
    });
    this.props.history.push('/my-network');

  };

  beforeNextStep(nextStep, multiStepForm) {
    const { currentForm, formStates } = multiStepForm;

    if (currentForm) {
      const { key, formApi } = currentForm;
      const { as } = this.props;
      formStates[key] = formApi.getState();
      const data = validateRegistrationForm(
        formStates,
        key,
        as,
        this.props.existingCompanyId,
        this.state.exportDisplay,
        language
      );
      this.props.doRegistrationErrors('CheckError', data);
      const { hasError, errors } = data;
      if (hasError) {
        highlightFirstError(errors);
        return false;
      }
    }

    return true;
  }

  async saveFormApi(key, formApi, multiStepForm) {
    await multiStepForm.setCurrentForm({ key, formApi });

    const mapColumnsToInputs = {
      companySize: { tabIndex: 2, fieldName: 'companySize' },
      companyType: { tabIndex: 2, fieldName: 'companyType' },
      yearOfEstablishment: { tabIndex: 2, fieldName: 'yearOfEstablishment' },
      managedByWomen: { tabIndex: 2, fieldName: 'managedByWomen' },
      noOfWomenManagementTeam: {
        tabIndex: 2,
        fieldName: 'noOfWomenManagementTeam',
      },
      numberOfEmployees: { tabIndex: 2, fieldName: 'numberOfEmployees' },
      numberOfEmployeesWomen: {
        tabIndex: 2,
        fieldName: 'numberOfEmployeesWomen',
      },
      primaryCustomers: { tabIndex: 3, fieldName: 'primaryCustomers' },
      hasExportExperience: { tabIndex: 3, fieldName: 'hasExportExperience' },
      annualValueOfSales: { tabIndex: 3, fieldName: 'annualValueOfSales' },
      yearsOfExportingExperience: {
        tabIndex: 3,
        fieldName: 'yearsOfExportingExperience',
      },
    };

    if (this.props.hasOwnProperty('as') && this.props.as != 'Verifier') {
      mapColumnsToInputs.noOfManagementTeam = {
        tabIndex: 2,
        fieldName: 'noOfManagementTeam',
      };
    }
    Object.entries(mapColumnsToInputs)
      .filter(([columnName, { tabIndex }]) => tabIndex === key)
      .forEach(([columnName, { tabIndex, fieldName }]) => {
        const existingValue = this.props.existingCompanyDetails[columnName];
        if (existingValue) {
          formApi.setValue(fieldName, existingValue);
        }
      });
  }

  onUserTypeChange(userType, multiStepForm) {
    this.props.setRegistrationsValidationsUserType(userType);
    multiStepForm.currentForm.formApi.setValue('companyName', null);
    multiStepForm.currentForm.formApi.setValue('jobPosition', null);
  }

  exportChangeHandle(exportValue) {
    this.setState({
      exportDisplay: exportValue != 0,
    });
  }

  onCompanyChange(option) {
    if (option === null) {
      return this.props.companyChange({ value: null, isExisting: false });
    }
    const { value } = option;
    const matchingOptions = this.state.companyNames.filter(
      ({ value: optionValue }) => optionValue === value
    ).length;
    const isExisting = matchingOptions.length !== 0;
    this.props.companyChange({ value, isExisting });
    if (!isExisting) {
      this.props.clearExistingCompanyValues();
      this.setState({
        newCompanyName: value,
      });
    }
  }

  async searchCompanies(input) {
    const trimInput = input.trim();
    const { newCompanyName } = this.state;
    const regexp = new RegExp(trimInput, 'i');
    const createdOption =
      newCompanyName && regexp.test(newCompanyName)
        ? [{ value: newCompanyName, label: newCompanyName }]
        : [];
    if (trimInput.length === 0) {
      return { options: createdOption };
    }
    const {
      data: { data: serverOptions },
    } = await axios.get(`/companies?q=${trimInput}`);
    const companyNames = serverOptions.slice(0, 20);
    this.setState({
      companyNames,
    });
    return { options: [...createdOption, ...companyNames] };
  }

  render() {
    const { as } = this.props;
    const { companyTypes, primaryCustomersOptions } = this.state;
    const companyDisabled =
      this.props.companyValidations == false ||
      this.props.existingCompanyId > 0;
    const existingDisabled = this.props.existingCompanyId > 0;

    language = languages[this.props.lan];
    completeRegistrationSuccessBuyer =
      language.registration_successful_popup2[0];
    completeRegistrationSuccessSeller =
      language.registration_successful_popup3[0];
    completeRegistrationSuccessVerifier =
      language.registration_successful_popup4[0];
      labels=language.completeregistration[0];

    return (
      <CustomizedDocumentTitle title={create.COMPLETE_REGISTRATION}>
        <div className='row'>
          <div className='col-md-1'></div>
          <div className='col-md-12'>
            <CreateNew
              pageTitle="Create or Join a Company page"
              fullWidth
              steps={[
                {
                  title: "Create or Join a Company page",
                  nextButton: "Create or Join your business page now"
                },
                {
                  title: create.ORGANISATION_DETAIL,
                  nextButton: common.NEXT_STEP,
                },
                {
                  title: create.PRODUCT_SERVICES_INFO,
                  nextButton: create.REGISTER,
                },
              ]}
              onFinish={this.onFinish.bind(this)}
              beforeNext={this.beforeNextStep.bind(this)}
              renderAfterTitle={() => (
                <Fragment>

                </Fragment>
              )}
              renderStep={(step, multiStepForm) => {
                switch (step) {
                  case 1:
                    return (
                      <Form
                        getApi={(formApi) =>
                          this.saveFormApi(1, formApi, multiStepForm)
                        }
                        render={() => (
                          <Fragment>-
                            <p>{labels.YOURE_HERE_BECAUSE_YOU_TOLD_US_YOU_ARE_AN_EMPLOYEE_OF_A_BUSINESS}</p>
                            <p>{labels.BY_JOINING_OR_CREATING_A_BUSINESS_PAGE}</p>
                            <h3>{labels.START_BY_TELLING_US_YOUR_BUSINESS_NAME}</h3>
                            <Input
                              id='userId'
                              required
                              className='hidden'
                              type='text'
                              value={this.props.userId}
                            />
                            <div className='inputs'>
                              <Input
                                id='companyName'
                                label={create.ORGANISATION_NAME}
                                disabled={!this.props.companyValidations}
                                required={this.props.companyValidations}
                                onChange={this.onCompanyChange}
                                type='select'
                                selectProps={{
                                  async: true,
                                  createable: true,
                                  loadOptions: this.searchCompanies,
                                  searchable: true,
                                }}
                                hasError={
                                  this.props.errorMessages.hasOwnProperty(
                                    'companyName'
                                  ) && this.props.errorMessages.companyName
                                    ? true
                                    : false
                                }
                                errors={
                                  this.props.errorMessages.hasOwnProperty(
                                    'companyName'
                                  ) && this.props.errorMessages.companyName
                                    ? this.props.errorMessages.companyName[0]
                                    : ''
                                }
                                placeholder={create.ORGANISATION_PLACEHOLDER}
                              />
                            </div>
                          </Fragment>
                        )}
                      />
                    );

                  case 2:
                    return (
                      <Form
                        getApi={(formApi) =>
                          this.saveFormApi(2, formApi, multiStepForm)
                        }
                        render={() => (
                          <Fragment>
                            <div className='inputs-group'>
                              <div className='inputs-group__title'>
                                <p className='gray'>{create.COMPANY_MESSAGE}</p>
                              </div>

                              <div className='inputs'>
                                <div className='row'>
                                  <div className='col-sm-6'>
                                    <Input
                                      id='managedByWomen'
                                      label={create.MANAGED_BY_WOMAN}
                                      required={this.props.companyValidations}
                                      disabled={companyDisabled}
                                      type='tab-group'
                                      vertical
                                      hasError={
                                        this.props.errorMessages.hasOwnProperty(
                                          'managedByWomen'
                                        ) &&
                                          this.props.errorMessages.managedByWomen
                                          ? true
                                          : false
                                      }
                                      errors={
                                        this.props.errorMessages.hasOwnProperty(
                                          'managedByWomen'
                                        ) &&
                                          this.props.errorMessages.managedByWomen
                                          ? this.props.errorMessages
                                            .managedByWomen[0]
                                          : ''
                                      }
                                      options={[
                                        { value: 1, label: common.YES },
                                        { value: 0, label: common.NO },
                                      ]}
                                    />

                                    {as !== 'Verifier' && (
                                      <Input
                                        id='noOfManagementTeam'
                                        disabled={existingDisabled}
                                        label={create.WOMEN_PERCENTAGE}
                                        required
                                        hasError={
                                          this.props.errorMessages.hasOwnProperty(
                                            'noOfManagementTeam'
                                          ) &&
                                            this.props.errorMessages
                                              .noOfManagementTeam
                                            ? true
                                            : false
                                        }
                                        errors={
                                          this.props.errorMessages.hasOwnProperty(
                                            'noOfManagementTeam'
                                          ) &&
                                            this.props.errorMessages
                                              .noOfManagementTeam
                                            ? this.props.errorMessages
                                              .noOfManagementTeam[0]
                                            : ''
                                        }
                                        placeholder={
                                          create.WOMEN_PERCENTAGE_PLACEHOLDER
                                        }
                                        vertical
                                      />
                                    )}

                                    <Input
                                      id='noOfWomenManagementTeam'
                                      label={create.WOMEN_IN_MANAGEMENT}
                                      required={this.props.companyValidations}
                                      disabled={companyDisabled}
                                      hasError={
                                        this.props.errorMessages.hasOwnProperty(
                                          'noOfWomenManagementTeam'
                                        ) &&
                                          this.props.errorMessages
                                            .noOfWomenManagementTeam
                                          ? true
                                          : false
                                      }
                                      errors={
                                        this.props.errorMessages.hasOwnProperty(
                                          'noOfWomenManagementTeam'
                                        ) &&
                                          this.props.errorMessages
                                            .noOfWomenManagementTeam
                                          ? this.props.errorMessages
                                            .noOfWomenManagementTeam[0]
                                          : ''
                                      }
                                      placeholder={
                                        create.MANAGEMENT_PLACEHOLDER
                                      }
                                      type='select'
                                      options={this.props.noOfManagementTeam}
                                      vertical
                                    />
                                  </div>

                                  <div className='col-sm-6'>
                                    <Input
                                      id='yearOfEstablishment'
                                      vertical
                                      disabled={companyDisabled}
                                      label={create.ESTABLISHMENT_YEAR}
                                      required={this.props.companyValidations}
                                      hasError={
                                        this.props.errorMessages.hasOwnProperty(
                                          'yearOfEstablishment'
                                        ) &&
                                          this.props.errorMessages
                                            .yearOfEstablishment
                                          ? true
                                          : false
                                      }
                                      errors={
                                        this.props.errorMessages.hasOwnProperty(
                                          'yearOfEstablishment'
                                        ) &&
                                          this.props.errorMessages
                                            .yearOfEstablishment
                                          ? this.props.errorMessages
                                            .yearOfEstablishment[0]
                                          : ''
                                      }
                                      placeholder={String(
                                        new Date().getFullYear()
                                      )}
                                    />

                                    <Input
                                      id='numberOfEmployees'
                                      label={common.NUMBER_OF_EMPLOYEE}
                                      required={this.props.companyValidations}
                                      disabled={companyDisabled}
                                      hasError={
                                        this.props.errorMessages.hasOwnProperty(
                                          'numberOfEmployees'
                                        ) &&
                                          this.props.errorMessages
                                            .numberOfEmployees
                                          ? true
                                          : false
                                      }
                                      errors={
                                        this.props.errorMessages.hasOwnProperty(
                                          'numberOfEmployees'
                                        ) &&
                                          this.props.errorMessages
                                            .numberOfEmployees
                                          ? this.props.errorMessages
                                            .numberOfEmployees[0]
                                          : ''
                                      }
                                      placeholder={
                                        common.TOTAL_EMPLOYEE_PLACEHOLDER
                                      }
                                      vertical
                                      type='numeric'
                                    />

                                    <Input
                                      id='numberOfEmployeesWomen'
                                      label={common.NUMBER_OF_WOMEN_EMPLOYEE}
                                      required={this.props.companyValidations}
                                      disabled={companyDisabled}
                                      hasError={
                                        this.props.errorMessages.hasOwnProperty(
                                          'numberOfEmployeesWomen'
                                        ) &&
                                          this.props.errorMessages
                                            .numberOfEmployeesWomen
                                          ? true
                                          : false
                                      }
                                      errors={
                                        this.props.errorMessages.hasOwnProperty(
                                          'numberOfEmployeesWomen'
                                        ) &&
                                          this.props.errorMessages
                                            .numberOfEmployeesWomen
                                          ? this.props.errorMessages
                                            .numberOfEmployeesWomen[0]
                                          : ''
                                      }
                                      placeholder={
                                        common.WOMEN_NUMBER_PLACEHOLDER
                                      }
                                      type='numeric'
                                      vertical
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        )}
                      />
                    );

                  case 3:
                    return (
                      <Form
                        getApi={(formApi) =>
                          this.saveFormApi(3, formApi, multiStepForm)
                        }
                        render={() => (
                          <Fragment>
                            <div className='inputs-group'>
                              <div className='inputs-group__title'>
                                <p className='gray'>
                                  {create.PRODUCT_SERVICES_INFO}
                                </p>
                              </div>
                              <div className='inputs'>
                                <MultiInputField
                                  field='primaryCustomers'
                                  id='primaryCustomers'
                                  disabled={existingDisabled}
                                  label={create.PRIMARY_CUSTOMER}
                                  placeholder={
                                    create.PRIMARY_CUSTOMER_PLACEHOLDER
                                  }
                                  hasError={
                                    this.props.errorMessages.hasOwnProperty(
                                      'primaryCustomers'
                                    ) &&
                                      this.props.errorMessages.primaryCustomers
                                      ? true
                                      : false
                                  }
                                  errors={
                                    this.props.errorMessages.hasOwnProperty(
                                      'primaryCustomers'
                                    ) &&
                                      this.props.errorMessages.primaryCustomers
                                      ? this.props.errorMessages
                                        .primaryCustomers[0]
                                      : ''
                                  }
                                  required
                                  options={primaryCustomersOptions}
                                />
                              </div>
                            </div>

                            <div
                              className='inputs-group'
                              style={{ display: 'none' }}
                            >
                              <div className='inputs-group__title'>
                                <p className='gray'>
                                  {create.ANNUAL_SALES_VALUE}
                                </p>
                              </div>

                              <div className='row'>
                                <div className='col-sm-6'>
                                  <div className='inputs'>
                                    <Input
                                      id='annualValueOfSales'
                                      disabled={existingDisabled}
                                      label={create.ANNUAL_SALES_VALUE}
                                      required
                                      hasError={
                                        this.props.errorMessages.hasOwnProperty(
                                          'annualValueOfSales'
                                        ) &&
                                          this.props.errorMessages
                                            .annualValueOfSales
                                          ? true
                                          : false
                                      }
                                      errors={
                                        this.props.errorMessages.hasOwnProperty(
                                          'annualValueOfSales'
                                        ) &&
                                          this.props.errorMessages
                                            .annualValueOfSales
                                          ? this.props.errorMessages
                                            .annualValueOfSales[0]
                                          : ''
                                      }
                                      type='number'
                                      placeholder={
                                        create.ANNUAL_EXPORT_PLACEHOLDER
                                      }
                                    />
                                  </div>
                                </div>

                                <div className='col-sm-6'>
                                  <div className='inputs'>
                                    <Input
                                      id='annualValueOfExports'
                                      disabled={existingDisabled}
                                      label={create.ANNUAL_EXPORT_VALUE}
                                      required
                                      hasError={
                                        this.props.errorMessages.hasOwnProperty(
                                          'annualExportValue'
                                        ) &&
                                          this.props.errorMessages
                                            .annualExportValue
                                          ? true
                                          : false
                                      }
                                      errors={
                                        this.props.errorMessages.hasOwnProperty(
                                          'annualExportValue'
                                        ) &&
                                          this.props.errorMessages
                                            .annualExportValue
                                          ? this.props.errorMessages
                                            .annualExportValue[0]
                                          : ''
                                      }
                                      type='number'
                                      placeholder={
                                        create.ANNUAL_EXPORT_PLACEHOLDER
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='inputs-group'>
                              <div className='inputs-group__title'>
                                <p className='gray'>{create.EXPORT_IMPORT}</p>
                              </div>

                              <div className='inputs'>
                                <Input
                                  id='hasExportExperience'
                                  label={create.HAVE_EXPORTING_EXPERIENCE}
                                  required
                                  disabled={existingDisabled}
                                  onChange={this.exportChangeHandle}
                                  hasError={
                                    this.props.errorMessages.hasOwnProperty(
                                      'hasExportExperience'
                                    ) &&
                                      this.props.errorMessages.hasExportExperience
                                      ? true
                                      : false
                                  }
                                  errors={
                                    this.props.errorMessages.hasOwnProperty(
                                      'hasExportExperience'
                                    ) &&
                                      this.props.errorMessages.hasExportExperience
                                      ? this.props.errorMessages
                                        .hasExportExperience[0]
                                      : ''
                                  }
                                  type='tab-group'
                                  options={[
                                    { value: 1, label: common.YES },
                                    { value: 0, label: common.NO },
                                  ]}
                                />
                                {this.state.exportDisplay == true && (
                                  <Fragment>
                                    <Input
                                      id='yearsOfExportingExperience'
                                      label={create.YEARS_EXPORTING_EXPERIENCE}
                                      required
                                      disabled={existingDisabled}
                                      hasError={
                                        this.props.errorMessages.hasOwnProperty(
                                          'yearsOfExportingExperience'
                                        ) &&
                                          this.props.errorMessages
                                            .yearsOfExportingExperience
                                          ? true
                                          : false
                                      }
                                      errors={
                                        this.props.errorMessages.hasOwnProperty(
                                          'yearsOfExportingExperience'
                                        ) &&
                                          this.props.errorMessages
                                            .yearsOfExportingExperience
                                          ? this.props.errorMessages
                                            .yearsOfExportingExperience[0]
                                          : ''
                                      }
                                      placeholder={create.CHOOSE_NUMBER}
                                      type='select'
                                      options={
                                        this.props.exportingYearExperience
                                      }
                                    />

                                    <Input
                                      id='exportToCountries'
                                      label={create.COUNTRIES_EXPORT_TO}
                                      placeholder={create.COUNTRY_PLACEHOLDER}
                                      disabled={existingDisabled}
                                      hasError={
                                        this.props.errorMessages.hasOwnProperty(
                                          'exportToCountries'
                                        ) == true
                                          ? true
                                          : false
                                      }
                                      errors={
                                        this.props.errorMessages.hasOwnProperty(
                                          'exportToCountries'
                                        ) == true
                                          ? this.props.errorMessages[
                                          'exportToCountries'
                                          ][0]
                                          : ''
                                      }
                                      type='dropdown-tree'
                                      dropdownTreeOptions={{
                                        route: ROUTE_FETCH_COUNTRIES,
                                        defaultOpenedToplevel: ['ASIA'],
                                      }}
                                    />

                                    <Input
                                      id='exportFromCountries'
                                      label={create.COUNTRIES_IMPORT_FROM}
                                      placeholder={create.COUNTRY_PLACEHOLDER}
                                      disabled={existingDisabled}
                                      hasError={
                                        this.props.errorMessages.hasOwnProperty(
                                          'exportFromCountries'
                                        ) == true
                                          ? true
                                          : false
                                      }
                                      errors={
                                        this.props.errorMessages.hasOwnProperty(
                                          'exportFromCountries'
                                        ) == true
                                          ? this.props.errorMessages[
                                          'exportFromCountries'
                                          ][0]
                                          : ''
                                      }
                                      type='dropdown-tree'
                                      dropdownTreeOptions={{
                                        route: ROUTE_FETCH_COUNTRIES,
                                        position: 'top',
                                        defaultOpenedToplevel: ['ASIA'],
                                      }}
                                    />
                                  </Fragment>
                                )}

                                <Fragment>
                                  <Input
                                    id='sellingProducts'
                                    label={create.PRODUCT_SELLING}
                                    required={false}
                                    placeholder={
                                      common.CATEGORY_NAME_NUMBER_PLACEHOLDER
                                    }
                                    type='dropdown-tree'
                                    disabled={existingDisabled}
                                    hasError={
                                      this.props.errorMessages.hasOwnProperty(
                                        'sellingProducts'
                                      ) == true
                                        ? true
                                        : false
                                    }
                                    errors={
                                      this.props.errorMessages.hasOwnProperty(
                                        'sellingProducts'
                                      ) == true
                                        ? this.props.errorMessages[
                                        'sellingProducts'
                                        ][0]
                                        : ''
                                    }
                                    dropdownTreeOptions={{
                                      route: ROUTE_FETCH_PRODUCTS,
                                      position: 'top',
                                    }}
                                  />

                                  <Input
                                    id='offeringServices'
                                    label={create.SERVICE_OFFERING}
                                    required={false}
                                    placeholder={
                                      common.CATEGORY_NAME_NUMBER_PLACEHOLDER
                                    }
                                    type='dropdown-tree'
                                    disabled={existingDisabled}
                                    hasError={
                                      this.props.errorMessages.hasOwnProperty(
                                        'offeringServices'
                                      ) == true
                                        ? true
                                        : false
                                    }
                                    errors={
                                      this.props.errorMessages.hasOwnProperty(
                                        'offeringServices'
                                      ) == true
                                        ? this.props.errorMessages[
                                        'offeringServices'
                                        ][0]
                                        : ''
                                    }
                                    dropdownTreeOptions={{
                                      route: ROUTE_FETCH_SERVICES,
                                      position: 'top',
                                    }}
                                  />
                                </Fragment>

                                <Fragment>
                                  <Input
                                    id='lookingForProducts'
                                    label={create.PRODUCT_LOOKING}
                                    required={false}
                                    placeholder={
                                      common.CATEGORY_NAME_NUMBER_PLACEHOLDER
                                    }
                                    type='dropdown-tree'
                                    disabled={existingDisabled}
                                    hasError={
                                      this.props.errorMessages.hasOwnProperty(
                                        'lookingForProducts'
                                      ) == true
                                        ? true
                                        : false
                                    }
                                    errors={
                                      this.props.errorMessages.hasOwnProperty(
                                        'lookingForProducts'
                                      ) == true
                                        ? this.props.errorMessages[
                                        'lookingForProducts'
                                        ][0]
                                        : ''
                                    }
                                    dropdownTreeOptions={{
                                      route: ROUTE_FETCH_PRODUCTS,
                                      position: 'top',
                                    }}
                                  />

                                  <Input
                                    id='lookingForServices'
                                    label={create.SERVICES_LOOKING}
                                    required={false}
                                    placeholder={
                                      common.CATEGORY_NAME_NUMBER_PLACEHOLDER
                                    }
                                    type='dropdown-tree'
                                    disabled={existingDisabled}
                                    hasError={
                                      this.props.errorMessages.hasOwnProperty(
                                        'lookingForServices'
                                      ) == true
                                        ? true
                                        : false
                                    }
                                    errors={
                                      this.props.errorMessages.hasOwnProperty(
                                        'lookingForServices'
                                      ) == true
                                        ? this.props.errorMessages[
                                        'lookingForServices'
                                        ][0]
                                        : ''
                                    }
                                    dropdownTreeOptions={{
                                      route: ROUTE_FETCH_SERVICES,
                                      position: 'top',
                                    }}
                                  />
                                </Fragment>
                              </div>
                            </div>
                          </Fragment>
                        )}
                      />
                    );
                  default:
                    return null;
                }
              }}
            />
          </div>

          <TopMessage
            type='error'
            text={this.props.mainErrorMessage}
            isShown={this.props.formHasError}
            onCloseClick={this.props.closeValidationError}
          />

          <Modal
            isOpen={this.props.showRegistrationMessage == true}
            buttons={{
              right: {
                text: common.CONTINUE,
                className: 'brand',
                onClick: this.onCompleteRegistration.bind(this),
              },
            }}
            children={
              <Fragment>
                <div className="container-2col">
                  <div className="formpage-content">
                    <div className="whiteformtoptext">
                      <h3>Congrats, your company page has been created!</h3>
                      <p>You can now preview your page and personalize it to your liking by adding for example your story, your logo, and more.</p>
                      <p><a href="#" className="btn">Yes! Take me to my company page preview</a></p>
                    </div>
                    <h4>And there’s more! Your SheTrades experience has been upgraded. As a women-owned business…</h4>
                    <p>You can now access to this offering on the SheTrades platform while you’re connected.</p>
                    <p><a href="#">- you can now sell and buy products</a><br />
                      <a href="#">- you are now a member of the SheTrades Kenya Hub</a><br />
                      <a href="#">- you can join local and online events</a><br />
                      <a href="#">- you can access opportunities (including investment)</a><br />
                      <a href="#">- access VLS courses, e-learning etc</a>.</p>
                    <div className="container-2even">
                      <div><Link to="/dashboard" className="btn">To my personal dashboard</Link >
                      </div>
                      <div><Link to="/home" className="btn">To SheTrades Homepage</Link >
                      </div>
                    </div>
                  </div>
                  <div className="sidebg formpictures">
                    <img src="images/temppic04.jpg" alt =""/>
                    <img src="images/storypic-02.jpg" alt =""/>
                    <img src="images/temppic01.jpg" alt =""/>
                  </div>
                </div>
              </Fragment>
            }
          />
        </div>
      </CustomizedDocumentTitle>
    );
  }
}

const List = connect(mapStateToProps, mapDispatchToProps)(CompanyRegistration);
export default withRouter(List);
