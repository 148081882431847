import React from 'react';
import PageNotFound from '../components/PageNotFound';
import Preloader from '../components/Preloader';

const isEmptyArray = (obj) => Array.isArray(obj) && obj.length === 0;

export default (props) => {
  if (props.detail === undefined) {
    return <Preloader />;
  }
  if (isEmptyArray(props.detail)) {
    return <PageNotFound />;
  }
  return props.children;
};
