import { MARKET_TOOLS, E_LEARN_CONTENT } from '../store/actions';

const initialState = { marketTools: [], eLearnContent: [] };

export default function learn(state = initialState, action) {
  switch (action.type) {
    case MARKET_TOOLS:
      return {
        ...state,
        marketTools: action.payload.data,
      };
    case E_LEARN_CONTENT:
      return {
        ...state,
        eLearnContent: action.payload.data,
      };
    default:
      return state;
  }
}
