import React from 'react';
import MultiInput from '../../';
import { asField } from 'informed';

export default asField(
  ({
    fieldApi: { setValue, setTouched },
    fieldState: { value },
    options,
    ...rest
  }) => (
    <MultiInput
      value={value}
      onChange={(value) => {
        setValue(value);
        setTouched(true);
      }}
      options={options}
      {...rest}
    />
  )
);
