import { PREVIEW_ACCESS_ERROR } from '../store/actions';

const initialState = {
  errorMessage: '',
  errorStatus: 200,
};

export default function previewError(state = initialState, action) {
  switch (action.type) {
    case PREVIEW_ACCESS_ERROR:
      return {
        ...state,
        errorMessage: action.payload.message,
        errorStatus: action.payload.response.status,
      };
    default:
      return state;
  }
}
