import { RESET, LOGOUT, resetChats, handleApiErrors } from '../store/actions';
import axios from '../requests/platformApi';
import qs from 'qs';

export const STATUS = 'status';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

const config = {
  headers: {
    Accept: 'application/json',
    Authorization: 'Basic c4aa7808d0d92c6bdcfce0d0105bb34c',
    'Content-Type': 'application/json',
  },
};

export function userSignupRequest(formData) {

  const configForm = {
    headers: {
      Accept: 'application/json',
      Authorization: 'Basic c4aa7808d0d92c6bdcfce0d0105bb34c',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  return () =>
    axios
      .post('/api/v2/register-user-setp1', qs.stringify(formData), configForm)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        console.log(error.errors)
        var returnData = {};
        returnData.errors = error.errors;
        returnData.hasError = true;
        returnData.message = error.message;
        return returnData;
      });
}

export function login(formData) {
  const url = '/api/v2/platform_login';

  const configForm = {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  };
  return () =>
    axios
      .post(url, qs.stringify(formData), configForm)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        return error.response.data;
      });
}

export function logout() {
  return (dispatch) => {
    axios.get('/platform_logout').then(() => {
      localStorage.clear();
      dispatch(setCurrentUser(null));
      if (window.Applozic) {
        window.Applozic.ALApiService.logout({});
        window.Applozic.ALSocket.disconnect();
      }
      dispatch(resetChats());
      dispatch({ type: RESET });
      dispatch({
        type: LOGOUT,
        payload: STATUS,
      });
    });
  };
}

export function deleteAccount(userId) {

  // https://www.shetrades.com/admin/users/changestatus/69934/2
  var url = 'admin/users/changestatus/' + userId + '/2';

  return (dispatch) => {
    axios.get(url).then(() => {
      localStorage.clear();
      dispatch(setCurrentUser(null));
      if (window.Applozic) {
        window.Applozic.ALApiService.logout({});
        window.Applozic.ALSocket.disconnect();
      }
      dispatch(resetChats());
      dispatch({ type: RESET });
      dispatch({
        type: LOGOUT,
        payload: STATUS,
      });
    });
  };
}

export function setCurrentUser(userData) {
  return { type: SET_CURRENT_USER, payload: userData };
}

export function resetPasswordRequest(formData) {
  return () =>
    axios
      .post('/api/v2/password/email', { body: formData }, config)
      .then((res) => {
        return res;
      })
      .catch(function (error) {
        return error;
      });
}

export function resetPasswordAction(formData) {
  return axios
    .post('/api/v2/password/reset', { body: formData }, config)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      return error;
    });
}
