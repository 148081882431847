import DashboardCard from './../components/Dashboard/Card';
import DashboardCardForNews from './../components/Dashboard/CardForNews';
import CardOfMember from './../components/CardOfMember';
import WeatherWidget from './../components/WeatherWidget';
import MemberSuggestion from './../components/Suggestion';
import Calendar from './../components/Calendar';

import languages from '../helpers/languages';

var language = languages['en'];
var dashboard = language.dashboard[0];
var common = language.common[0];
var esDashboard = languages['es'].dashboard[0];
var esCommon = languages['es'].common[0];
// var frDashboard = languages['fr'].dashboard[0];
// var frCommon = languages['fr'].common[0];

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  en: {
    news: {
      id: 1,
      title: dashboard.RECENT_NEWS,
      isRight: false,
      isHidden: false,
      isHiddenMobile: false,
      identifier: 'news',
      limit: 5,
      tabs: [
        {
          key: 'news',
          title: dashboard.RECENT_NEWS,
          source: 'news',
          cardComponent: DashboardCardForNews,
        },
        {
          key: 'video',
          title: common.VIDEO,
          source: 'video',
          cardComponent: DashboardCardForNews,
          cardProps: { isVideo: true },
        },
        {
          key: 'publications',
          title: common.PUBLICATIONS,
          source: 'publications',
          cardComponent: DashboardCardForNews,
        },
      ],
    },
    tenders: {
      id: 2,
      title: common.TENDERS,
      isRight: false,
      isHidden: false,
      isHiddenMobile: false,
      identifier: 'tenders',
      limit: 3,
      tabs: [
        {
          key: 'new',
          title: dashboard.NEW,
          source: 'tenders',
          cardComponent: DashboardCard,
        },
        {
          key: 'following',
          title: dashboard.FOLLOWING,
          source: 'tenders',
          cardComponent: DashboardCard,
        },
        {
          key: 'closed',
          title: dashboard.RECENTLY_CLOSED,
          source: 'tenders',
          cardComponent: DashboardCard,
        },
      ],
    },
    offers: {
      id: 3,
      title: common.OFFERS,
      isRight: false,
      isHidden: true,
      isHiddenMobile: true,
      identifier: 'offers',
      limit: 3,
      tabs: [
        {
          key: 'new',
          title: dashboard.NEW,
          source: 'offers',
          cardComponent: DashboardCard,
        },
        {
          key: 'following',
          title: dashboard.FOLLOWING,
          source: 'offers',
          cardComponent: DashboardCard,
        },
        {
          key: 'closed',
          title: dashboard.RECENTLY_CLOSED,
          source: 'offers',
          cardComponent: DashboardCard,
        },
      ],
    },
    forum: {
      id: 4,
      title: common.FORUM,
      isRight: false,
      isHidden: false,
      isHiddenMobile: false,
      identifier: 'forum',
      limit: 3,
      tabs: [
        {
          key: 'all',
          title: dashboard.ALL_FORUMS,
          source: 'forums',
          cardComponent: DashboardCard,
        },
      ],
    },
    business: {
      id: 5,
      title: common.BUSSINESS_CIRCLE,
      isRight: false,
      isHidden: false,
      isHiddenMobile: false,
      identifier: 'business',
      limit: 3,
      tabs: [
        {
          key: 'all',
          title: common.ALL,
          source: 'members',
          cardComponent: CardOfMember,
        },
        {
          key: 'companies',
          title: common.COMPANIES,
          source: 'members',
          cardComponent: CardOfMember,
        },
        {
          key: 'individuals',
          title: common.INDIVIDUALS,
          source: 'members',
          cardComponent: CardOfMember,
        },
      ],
    },
    time: {
      id: 6,
      title: dashboard.TIME_WEATHER,
      isRight: true,
      isHidden: false,
      isHiddenMobile: false,
      identifier: 'time',
      widget: WeatherWidget,
      widgetProps: {},
    },
    calendar: {
      id: 7,
      title: dashboard.CALENDAR,
      isRight: true,
      isHidden: false,
      isHiddenMobile: false,
      identifier: 'calendar',
      widget: Calendar,
      widgetProps: {},
    },
    // links: {
    //   id: 8,
    //   title: common.USEFUL_LINKS,
    //   identifier: "links",
    //   isRight: true,
    //   isHidden: true,
    //   isHiddenMobile: false,
    // },
    request: {
      id: 9,
      title: common.USEFUL_REQUESTS,
      identifier: 'request',
      isRight: true,
      isHidden: false,
      isHiddenMobile: false,
      widget: MemberSuggestion,
      widgetProps: { userNameWithAvatarProps: { hasRequestedAdd: true } },
    },
    // market: {
    //   id: 10,
    //   title: common.MARKET_TOOLS,
    //   identifier: "market",
    //   isRight: true,
    //   isHidden: true,
    //   isHiddenMobile: true
    // }
  },
  es: {
    news: {
      id: 1,
      title: esDashboard.RECENT_NEWS,
      isRight: false,
      isHidden: false,
      isHiddenMobile: false,
      identifier: 'news',
      limit: 5,
      tabs: [
        {
          key: 'news',
          title: esDashboard.RECENT_NEWS,
          source: 'news',
          cardComponent: DashboardCardForNews,
        },
        {
          key: 'video',
          title: esCommon.VIDEO,
          source: 'video',
          cardComponent: DashboardCardForNews,
          cardProps: { isVideo: true },
        },
        {
          key: 'publications',
          title: esCommon.PUBLICATIONS,
          source: 'publications',
          cardComponent: DashboardCardForNews,
        },
      ],
    },
    tenders: {
      id: 2,
      title: esCommon.TENDERS,
      isRight: false,
      isHidden: false,
      isHiddenMobile: false,
      identifier: 'tenders',
      limit: 3,
      tabs: [
        {
          key: 'new',
          title: esDashboard.NEW,
          source: 'tenders',
          cardComponent: DashboardCard,
        },
        {
          key: 'following',
          title: esDashboard.FOLLOWING,
          source: 'tenders',
          cardComponent: DashboardCard,
        },
        {
          key: 'closed',
          title: esDashboard.RECENTLY_CLOSED,
          source: 'tenders',
          cardComponent: DashboardCard,
        },
      ],
    },
    offers: {
      id: 3,
      title: esCommon.OFFERS,
      isRight: false,
      isHidden: true,
      isHiddenMobile: true,
      identifier: 'offers',
      limit: 3,
      tabs: [
        {
          key: 'new',
          title: esDashboard.NEW,
          source: 'offers',
          cardComponent: DashboardCard,
        },
        {
          key: 'following',
          title: esDashboard.FOLLOWING,
          source: 'offers',
          cardComponent: DashboardCard,
        },
        {
          key: 'closed',
          title: esDashboard.RECENTLY_CLOSED,
          source: 'offers',
          cardComponent: DashboardCard,
        },
      ],
    },
    forum: {
      id: 4,
      title: esCommon.FORUM,
      isRight: false,
      isHidden: false,
      isHiddenMobile: false,
      identifier: 'forum',
      limit: 3,
      tabs: [
        {
          key: 'all',
          title: esDashboard.ALL_FORUMS,
          source: 'forums',
          cardComponent: DashboardCard,
        },
      ],
    },
    business: {
      id: 5,
      title: esCommon.BUSSINESS_CIRCLE,
      isRight: false,
      isHidden: false,
      isHiddenMobile: false,
      identifier: 'business',
      limit: 3,
      tabs: [
        {
          key: 'all',
          title: esCommon.ALL,
          source: 'members',
          cardComponent: CardOfMember,
        },
        {
          key: 'companies',
          title: esCommon.COMPANIES,
          source: 'members',
          cardComponent: CardOfMember,
        },
        {
          key: 'individuals',
          title: esCommon.INDIVIDUALS,
          source: 'members',
          cardComponent: CardOfMember,
        },
      ],
    },
    time: {
      id: 6,
      title: esDashboard.TIME_WEATHER,
      isRight: true,
      isHidden: false,
      isHiddenMobile: false,
      identifier: 'time',
      widget: WeatherWidget,
      widgetProps: {},
    },
    calendar: {
      id: 7,
      title: esDashboard.CALENDAR,
      isRight: true,
      isHidden: false,
      isHiddenMobile: false,
      identifier: 'calendar',
      widget: Calendar,
      widgetProps: {},
    },
    // links: {
    //   id: 8,
    //   title: common.USEFUL_LINKS,
    //   identifier: "links",
    //   isRight: true,
    //   isHidden: true,
    //   isHiddenMobile: false,
    // },
    request: {
      id: 9,
      title: esCommon.USEFUL_REQUESTS,
      identifier: 'request',
      isRight: true,
      isHidden: false,
      isHiddenMobile: false,
      widget: MemberSuggestion,
      widgetProps: { userNameWithAvatarProps: { hasRequestedAdd: true } },
    },
    // market: {
    //   id: 10,
    //   title: common.MARKET_TOOLS,
    //   identifier: "market",
    //   isRight: true,
    //   isHidden: true,
    //   isHiddenMobile: true
    // }
  }
  // fr: {
  //   news: {
  //     id: 1,
  //     title: frDashboard.RECENT_NEWS,
  //     isRight: false,
  //     isHidden: false,
  //     isHiddenMobile: false,
  //     identifier: 'news',
  //     limit: 5,
  //     tabs: [
  //       {
  //         key: 'news',
  //         title: frDashboard.RECENT_NEWS,
  //         source: 'news',
  //         cardComponent: DashboardCardForNews,
  //       },
  //       {
  //         key: 'video',
  //         title: frCommon.VIDEO,
  //         source: 'video',
  //         cardComponent: DashboardCardForNews,
  //         cardProps: { isVideo: true },
  //       },
  //       {
  //         key: 'publications',
  //         title: frCommon.PUBLICATIONS,
  //         source: 'publications',
  //         cardComponent: DashboardCardForNews,
  //       },
  //     ],
  //   },
  //   tenders: {
  //     id: 2,
  //     title: frCommon.TENDERS,
  //     isRight: false,
  //     isHidden: false,
  //     isHiddenMobile: false,
  //     identifier: 'tenders',
  //     limit: 3,
  //     tabs: [
  //       {
  //         key: 'new',
  //         title: frDashboard.NEW,
  //         source: 'tenders',
  //         cardComponent: DashboardCard,
  //       },
  //       {
  //         key: 'following',
  //         title: frDashboard.FOLLOWING,
  //         source: 'tenders',
  //         cardComponent: DashboardCard,
  //       },
  //       {
  //         key: 'closed',
  //         title: frDashboard.RECENTLY_CLOSED,
  //         source: 'tenders',
  //         cardComponent: DashboardCard,
  //       },
  //     ],
  //   },
  //   offers: {
  //     id: 3,
  //     title: frCommon.OFFERS,
  //     isRight: false,
  //     isHidden: true,
  //     isHiddenMobile: true,
  //     identifier: 'offers',
  //     limit: 3,
  //     tabs: [
  //       {
  //         key: 'new',
  //         title: frDashboard.NEW,
  //         source: 'offers',
  //         cardComponent: DashboardCard,
  //       },
  //       {
  //         key: 'following',
  //         title: frDashboard.FOLLOWING,
  //         source: 'offers',
  //         cardComponent: DashboardCard,
  //       },
  //       {
  //         key: 'closed',
  //         title: frDashboard.RECENTLY_CLOSED,
  //         source: 'offers',
  //         cardComponent: DashboardCard,
  //       },
  //     ],
  //   },
  //   forum: {
  //     id: 4,
  //     title: frCommon.FORUM,
  //     isRight: false,
  //     isHidden: false,
  //     isHiddenMobile: false,
  //     identifier: 'forum',
  //     limit: 3,
  //     tabs: [
  //       {
  //         key: 'all',
  //         title: frDashboard.ALL_FORUMS,
  //         source: 'forums',
  //         cardComponent: DashboardCard,
  //       },
  //     ],
  //   },
  //   business: {
  //     id: 5,
  //     title: frCommon.BUSSINESS_CIRCLE,
  //     isRight: false,
  //     isHidden: false,
  //     isHiddenMobile: false,
  //     identifier: 'business',
  //     limit: 3,
  //     tabs: [
  //       {
  //         key: 'all',
  //         title: frCommon.ALL,
  //         source: 'members',
  //         cardComponent: CardOfMember,
  //       },
  //       {
  //         key: 'companies',
  //         title: frCommon.COMPANIES,
  //         source: 'members',
  //         cardComponent: CardOfMember,
  //       },
  //       {
  //         key: 'individuals',
  //         title: frCommon.INDIVIDUALS,
  //         source: 'members',
  //         cardComponent: CardOfMember,
  //       },
  //     ],
  //   },
  //   time: {
  //     id: 6,
  //     title: frDashboard.TIME_WEATHER,
  //     isRight: true,
  //     isHidden: false,
  //     isHiddenMobile: false,
  //     identifier: 'time',
  //     widget: WeatherWidget,
  //     widgetProps: {},
  //   },
  //   calendar: {
  //     id: 7,
  //     title: frDashboard.CALENDAR,
  //     isRight: true,
  //     isHidden: false,
  //     isHiddenMobile: false,
  //     identifier: 'calendar',
  //     widget: Calendar,
  //     widgetProps: {},
  //   },
  //   // links: {
  //   //   id: 8,
  //   //   title: common.USEFUL_LINKS,
  //   //   identifier: "links",
  //   //   isRight: true,
  //   //   isHidden: true,
  //   //   isHiddenMobile: false,
  //   // },
  //   request: {
  //     id: 9,
  //     title: frCommon.USEFUL_REQUESTS,
  //     identifier: 'request',
  //     isRight: true,
  //     isHidden: false,
  //     isHiddenMobile: false,
  //     widget: MemberSuggestion,
  //     widgetProps: { userNameWithAvatarProps: { hasRequestedAdd: true } },
  //   },
  //   // market: {
  //   //   id: 10,
  //   //   title: common.MARKET_TOOLS,
  //   //   identifier: "market",
  //   //   isRight: true,
  //   //   isHidden: true,
  //   //   isHiddenMobile: true
  //   // }
  // },
};
