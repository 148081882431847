import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';

import Tag from '../../../components/Tag';
import Preloader from '../../../components/Preloader';
import Level from './components/Level';
import Node from './components/Node';
import Modal, { ModalBottom } from '../../../components/Modal';
import { search } from './fetchDropdownTrees';

export const TYPE_MODAL = 'modal';
export const TYPE_DROPDOWN = 'dropdown';
export const TYPE_NONE = 'none';

export const CountClearButton = ({ count, onClick, style, t }) => (
  <button className='button button_white' style={style} onClick={onClick}>
    <span className='button__text'>
      {count} {t && t.common[0].ITEMS_SELECTED}
    </span>
    <i className='icon-close' style={{ float: 'right' }} />
  </button>
);

class DropdownTree extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    placeholder: PropTypes.string,
    position: PropTypes.oneOf(['top', 'left', 'right', 'bottom']),
    route: PropTypes.string.isRequired,
    value: PropTypes.string,
    defaultOpenedToplevel: PropTypes.arrayOf(PropTypes.string),
    dropdownType: PropTypes.oneOf([TYPE_MODAL, TYPE_DROPDOWN, TYPE_NONE]),
  };

  static defaultProps = {
    value: null,
    dropdownType: TYPE_DROPDOWN,
  };

  static getMainTags = (valueObj) => {
    if (valueObj === null) return [];

    return Object.values(valueObj).filter(
      ({ checked, childChecked }) => checked || childChecked
    );
  };

  static getSubTags = (valueObj) => {
    if (valueObj === null) return [];

    const getLevelSubTags = (level) => {
      return DropdownTree.getMainTags(level).map(
        ({ id, name, children, checked, childChecked }) => {
          const res = checked ? [{ id, name }] : [];

          if (childChecked) {
            return [...res, getLevelSubTags(children)];
          }

          return res;
        }
      );
    };

    return _.flattenDeep(
      DropdownTree.getMainTags(valueObj)
        .filter(({ children }) => children)
        .map(({ children }) => getLevelSubTags(children))
    );
  };

  static removeNode = (valueObj, id) => {
    return Object.entries(valueObj).reduce((acc, [nodeId, nodeValue]) => {
      let res = {};
      const { children } = nodeValue;
      if (nodeId === id) {
        res = {
          ...nodeValue,
          checked: false,
          childChecked: false,
        };
        if (children) {
          res.children = Node.checkAllAncestors(children, false);
        }
      } else if (children) {
        const newChildren = DropdownTree.removeNode(children, id);
        res = {
          ...nodeValue,
          children: newChildren,
          childChecked: Node.hasCheckedAncestors(newChildren),
        };
      } else {
        res = nodeValue;
      }
      return {
        ...acc,
        [nodeId]: res,
      };
    }, {});
  };

  static syncLevels = (
    dstLevel,
    srcLevel,
    forceExpanded,
    createNewProperties
  ) => {
    if (dstLevel === null) return createNewProperties ? srcLevel : null;
    if (srcLevel === null) return dstLevel;
    if(dstLevel && srcLevel){
    return Object.values(dstLevel).reduce((acc, curr) => {
      const { id, children } = curr;
      const srcCounterpart = srcLevel && srcLevel[id];
      return {
        ...acc,
        [id]: {
          ...curr,
          checked: srcCounterpart
            ? srcCounterpart.checked || false
            : curr.checked,
          childChecked: srcCounterpart
            ? srcCounterpart.childChecked || false
            : curr.childChecked,
          forceExpanded: !!children && forceExpanded,
          children: DropdownTree.syncLevels(
            children,
            srcCounterpart && srcCounterpart.children,
            forceExpanded,
            createNewProperties
          ),
        },
      };
    }, {});
    }else{
      return dstLevel;
    }
  };

  static countSelection(obj) {
    if (obj === null) return 0;
    let count = 0;
    Object.values(obj).forEach(({ checked, children }) => {
      if (children) {
        count += DropdownTree.countSelection(children);
      }
      if (checked) {
        count += 1;
      }
    });
    return count;
  }

  constructor() {
    super();
    this.state = {
      didMount: false,
      input: '',
      isModalOpened: false,
      isSearching: false,
      isSearchFetching: false,
      searchValueObj: null,
      valueObj: null,
      ignoreFocus: false,
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onInputFocus = this.onInputFocus.bind(this);
    this.onSearchTreeChange = this.onSearchTreeChange.bind(this);
    this.onTreeChange = this.onTreeChange.bind(this);
    this.clearAll = this.clearAll.bind(this);
    this.submit = this.submit.bind(this);
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    this.unserialize();
  }

  componentDidUpdate({ value: oldValue }) {
    const { value } = this.props;
    if (oldValue !== value && value !== '') {
      this.unserialize();
    }
  }

  async onInputChange(e) {
    const isSearching = e.target.value.length > 2;
    if (isSearching) {
      const { route } = this.props;
      const { valueObj } = this.state;
      this.setState({
        ...this.state,
        input: e.target.value,
        isModalOpened: true,
        isSearching,
        isSearchFetching: true,
      });
      const response = await search({ query: e.target.value, route });
      this.setState({
        ...this.state,
        isModalOpened: true,
        isSearching,
        isSearchFetching: false,
        searchValueObj: response
          ? DropdownTree.syncLevels(response, valueObj, true)
          : this.state.searchValueObj,
      });
    } else {
      this.setState({
        ...this.state,
        input: e.target.value,
        isModalOpened: true,
        isSearching,
      });
    }
  }

  onInputFocus() {
    if (!this.state.ignoreFocus) {
      this.setState(
        {
          isModalOpened: true,
        },
        () =>
          setTimeout(() => {
            if (this.modalInput) {
              this.modalInput.focus();
            }
          }, 500)
      );
    }
  }

  persist() {
    const { onSubmit } = this.props;
    const { valueObj } = this.state;
    if (onSubmit) {
      onSubmit(JSON.stringify(valueObj));
    }
  }

  async clearAll() {
    await this.onTreeChange(null);
    this.submit();
    this.setState({
      input: '',
      isSearching: false,
    });
  }

  submit() {
    this.persist();
    this.close();
  }

  close() {
    // prevent opening dropdown again on focus when modal portal unmounts on modal animation end
    this.setState({
      ignoreFocus: true,
      isModalOpened: false,
    });
    setTimeout(() => this.setState({ ignoreFocus: false }), 1000);
  }

  onSearchTreeChange(searchValueObj) {
    const { valueObj } = this.state;
    this.setState({
      searchValueObj,
      valueObj: DropdownTree.syncLevels(valueObj, searchValueObj, false, true),
    });
  }

  onTreeChange(valueObj) {
    const { searchValueObj } = this.state;
    return new Promise((resolve) =>
      this.setState(
        {
          ...this.state,
          valueObj,
          searchValueObj:
            valueObj === null
              ? null
              : DropdownTree.syncLevels(searchValueObj, valueObj),
        },
        resolve
      )
    );
  }

  async removeNode(id) {
    const { valueObj: oldValue } = this.state;
    const valueObj = DropdownTree.removeNode(oldValue, id);
    await this.setState({
      valueObj,
    });
    this.persist();
  }

  unserialize() {
    const { value } = this.props;
    try {
      const valueObj = JSON.parse(value);
      this.setState({
        didMount: true,
        valueObj,
        searchValueObj: DropdownTree.syncLevels(
          this.state.searchValueObj,
          valueObj
        ),
      });
    } catch (e) {
      console.log('invalid JSON passed as dropdown tree value:', value);
    }
  }

  render() {
    const {
      placeholder,
      position = 'bottom',
      route,
      dropdownType,
      defaultOpenedToplevel,
      t,
    } = this.props;
    const {
      input,
      isModalOpened,
      valueObj,
      isSearching,
      searchValueObj,
      isSearchFetching,
      didMount,
    } = this.state;
    const mainTags = DropdownTree.getMainTags(valueObj);
    const subTags = DropdownTree.getSubTags(valueObj);
    const selectedItemsCount = DropdownTree.countSelection(valueObj);

    const contents = didMount && (
      <Scrollbars className='dropdown-tree__content' autoHide>
        {isSearchFetching ? (
          <Preloader relative size='small' />
        ) : isSearching ? (
          <Level
            value={searchValueObj}
            onChange={this.onSearchTreeChange}
            route={route}
            shouldFetch={false}
          />
        ) : (
          <Level
            value={valueObj}
            onChange={this.onTreeChange}
            route={route}
            defaultOpened={defaultOpenedToplevel}
          />
        )}
      </Scrollbars>
    );

    return (
      <div className='dropdown-visualize'>
        <div className='input input-box input-box--select input-box--full-width dropdown-input'>
          <input
            className={classnames(['inputbox', { shift: input.length }])}
            type='text'
            value={input}
            onChange={this.onInputChange}
            placeholder={placeholder}
            onFocus={this.onInputFocus}
            onClick={this.onInputFocus}
            ref={(el) => (this.input = el)}
          />

          <span className='icon-dropdown' />

          {dropdownType === TYPE_DROPDOWN && isModalOpened && (
            <div
              className={`dropdown-tree dropdown-tree--${position}-position`}
            >
              {contents}
              <div className='dropdown-tree__action'>
                <div className='content-hidden-line' />

                <div className='buttons buttons_group'>
                  <button
                    className='button '
                    onClick={this.clearAll}
                  >
                    <span className='button__text'>
                      {t.header[0].CLEAR_ALL}
                    </span>
                  </button>

                  <button className='button' onClick={this.submit}>
                    <span className='button__text'>{t.common[0].APPLY}</span>
                  </button>
                </div>
              </div>
            </div>
          )}

          {dropdownType === TYPE_MODAL && (
            <Modal
              isOpen={isModalOpened}
              buttons={{
                left: {
                  text: t.header[0].CLEAR_ALL,
                  className: 'gray',
                  onClick: this.clearAll,
                },
                right: {
                  text: t.common[0].APPLY,
                  className: 'brand',
                  onClick: this.submit,
                },
              }}
              onRequestClose={this.close}
            >
              <Fragment>
                <div className='input input-box input-box--select input-box--full-width dropdown-input'>
                  <input
                    className={classnames([
                      'inputbox',
                      { shift: input.length },
                    ])}
                    type='text'
                    value={input}
                    onChange={this.onInputChange}
                    placeholder={placeholder}
                    ref={(el) => (this.modalInput = el)}
                  />
                </div>
                {contents}
              </Fragment>
            </Modal>
          )}
        </div>

        {dropdownType === TYPE_NONE && (
          <div className='dropdown-tree__content-in-modal'>{contents}</div>
        )}

        {dropdownType !== TYPE_NONE && (
          <div className='dropdown-visualize__result'>
            {mainTags.length ? (
              <ul className='tags result__block result__block--main'>
                {mainTags.map(({ id, name }) => (
                  <Tag type='default' text={name} maxLength={30} key={id}>
                    <i
                      className='icon-close'
                      onClick={this.removeNode.bind(this, id)}
                    />
                  </Tag>
                ))}
              </ul>
            ) : null}

            {subTags.length ? (
              <ul className='tags result__block result__block--sub'>
                {subTags.map(({ id, name }) => (
                  <Tag type='default' text={name} maxLength={30} key={id}>
                    <i
                      className='icon-close'
                      onClick={this.removeNode.bind(this, id)}
                    />
                  </Tag>
                ))}
              </ul>
            ) : null}
          </div>
        )}

        {dropdownType === TYPE_NONE && selectedItemsCount && (
          <CountClearButton
            count={selectedItemsCount}
            onClick={this.clearAll}
            style={{ marginTop: '15px', width: '100%' }}
            t={t}
          />
        )}

        {dropdownType === TYPE_NONE && (
          <ModalBottom
            left={{
              text: t.common[0].CANCEL,
              className: 'gray',
              onClick: this.clearAll,
            }}
            right={{
              text: t.common[0].APPLY,
              className: 'brand',
              onClick: this.submit,
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  t: state.language.t,
});

export default connect(mapStateToProps)(DropdownTree);
