import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import Preloader from '../components/Preloader';
import projectsDataFile from '../data/projects.json';
import languages from '../helpers/languages';
import {
  fetchProjectPages
} from '../store/actions';

var language = languages['en'];
var common = language.common[0];
var  labels = language.Projects[0];

const mapStateToProps = (state) => {
  return {
    lan: state.language.lan,
    user: state.auth.user,
    projectsDataDb: state.projectpages.projectpages
  };
};

const mapDispatchToProps = {
  fetchProjectPages,
};

class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardsLoaded: false,
      isModalOpened: false
    };
  }
  handleLoading(promise) {
    const animationPromise = new Promise((resolve) =>
      setTimeout(resolve, 1000)
    );
    this.setState({ cardsLoaded: false });
    return Promise.all([promise, animationPromise]).then(() => {
      if (!this.unmounted) {
        this.setState({ cardsLoaded: true });
      }
    });
  }

  componentDidMount() {

    this.handleLoading(this.props.fetchProjectPages());
  }


  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    labels = language.Projects[0];
    const { projectsDataDb
    } = this.props;
    const { cardsLoaded } = this.state;

    let projectsData, featuredProjectInfo;
    if (!cardsLoaded) {
      projectsData = projectsDataFile;
    }
    else {
      if (projectsDataDb !== undefined || projectsDataDb !== null) {
        projectsData = projectsDataDb;
      }
    }

    featuredProjectInfo = projectsData.find(pj => pj.featured === 1);

    const shuffle = arr => [...arr].sort(() => Math.random() - 0.5);

    return (
      <CustomizedDocumentTitle title={common.MY_DASHBOARD}>
        <div className='transition-page'>
          {!cardsLoaded ? (
            <Preloader size='medium' relative />
          ) : (
            <div>
              {/*header content */}
              <header>
                <div className="innerheader innerheader-projectsLanding_and_template">
                  <h1>{labels.SHETRADES_PROJECTS}</h1>
                </div>
              </header>
              {/*header content */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container-2col">
                    <div>
                      <p>{labels.UNDER_THE_SHETRADES_PLATFORM}</p></div>
                    <div />
                  </div>
                </div></div>
              {/*our hubs*/}
              <div className="bgwhite">
                <div className="wrap">
                  <div className="ongoingprojects">
                    <h3>{labels.ONGOING_PROJECTS}</h3>
                    <div className="container-2even">
                      {/* <div className="custom-select"><i className="far fa-chevron-down" />
                        <select>
                          <option value={0}>IN_SECTOR:_ALL</option>
                          <option value={1}>Item 1</option>
                          <option value={2}>Item 2</option>
                          <option value={3}>Item 3</option>
                        </select>
                      </div>
                      <div className="custom-select"><i className="far fa-chevron-down" />
                        <select>
                          <option value={0}>IN_COUNTRY:_ALL</option>
                          <option value={1}>Item 1</option>
                          <option value={2}>Item 2</option>
                          <option value={3}>Item 3</option>
                        </select>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="wrap">
                  <div className="commonwealthblock cwbfixed">
                    <section>
                      <span className="commonwealthblockmainpic">
                        <img src={featuredProjectInfo.image} alt={featuredProjectInfo.title} /></span>
                      <Link to={`/project/${featuredProjectInfo.id}`}> <span className="title">{featuredProjectInfo.title}</span> </Link>
                      <p><i className="fas fa-map-marker icoleft" /> {featuredProjectInfo.countries}</p>
                      <p><div dangerouslySetInnerHTML={{ __html: featuredProjectInfo.description }} /></p>
                      <p><Link to={`/project/${featuredProjectInfo.id}`}>{labels.READ_MORE}
                        <i className="fal fa-arrow-right" />
                      </Link>
                      </p>
                    </section>
                    <section><div className="mapbox">
                      <div className="worldmap">
                        <div className="maphomeicon h1"><i className="fas fa-home-alt" /></div>
                        <div className="maphomeicon h2"><i className="fas fa-home-alt" /></div>
                        <div className="maphomeicon h3"><i className="fas fa-home-alt" /></div>
                        <div className="maphomeicon h4"><i className="fas fa-home-alt" /></div>
                        <div className="maphomeicon h5"><i className="fas fa-home-alt" /></div>
                        <div className="maphomeicon h6"><i className="fas fa-home-alt" /></div>
                        <div className="maphomeicon h7"><i className="fas fa-home-alt" /></div>
                      </div>
                    </div>
                    </section>
                    {shuffle(projectsData.filter(pj => pj.featured !== "1"))
                      .map(project => (
                        <section>
                          <span className="commonwealthblockmainpic">
                            <img height="250px" src={project.image} alt="" /></span>
                          <Link to={`/project/${project.id}`}> <span className="title">{project.title}</span> </Link>
                          <p>

                            <div className="project-description" dangerouslySetInnerHTML={{ __html: project.description }} /></p>
                          <p>
                            <Link to={`/project/${project.id}`}>{labels.READ_MORE}
                              <i className="fal fa-arrow-right" />
                            </Link>
                          </p>
                        </section>
                      ))}
                  </div>
                  {/* <Link to="#" className="btnoutline loadbtn"><i className="fas fa-spinner" />labels.LOAD_MORE</Link> */}
                </div>
              </div>
              {/*our hubs */}
              {/*active projects */}
              <div className="bggrey">
                <div className="wrap">
                  <div className="container">
                    <h2>{labels.CHECK_OUT_OR_OTHER_ACTIVE_PROJECTS}</h2>
                  </div>
                </div>
                <div className="commonwealthblock negativemargintop">
                  {shuffle(projectsData).slice(0, 4).map(project => (
                    <section>
                      <span className="commonwealthblockmainpic">
                        <img height="250px" src={project.image} alt="" /></span>
                      <Link to={`/project/${project.id}`}><span className="title">{project.title}</span></Link>
                      <p>
                        <div dangerouslySetInnerHTML={{ __html: project.description }} /></p>
                      <p>
                        <Link to={`/project/${project.id}`}>{labels.READ_MORE}
                          <i className="fal fa-arrow-right" />
                        </Link>
                      </p></section>
                  ))}
                </div>
                <div className="dotbg l2 dotb" />
              </div>
              {/*active projects */}
            </div>

          )}
        </div>
      </CustomizedDocumentTitle>
    );
  }
}

Projects.defaultProps = {
  projectsDataDb: [],
};


export default connect(mapStateToProps, mapDispatchToProps)(Projects);

