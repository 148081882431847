import { connect } from 'react-redux';
import {
  reorderDashboard,
  fetchDashboard,
  toggleSection,
} from '../../../../actions/dashboard';
import Manage from '../layout/Manage';

const mapStateToProps = (state) => {
  const { isPhone } = state.responsive;
  const showPreloader = !state.dashboard.hasFetched;
  if (isPhone) {
    return {
      isPhone,
      showPreloader,
      topLeft: state.dashboard.phone.visible,
      bottomLeft: state.dashboard.phone.hidden,
      t: state.language.t,
    };
  }
  return {
    isPhone,
    showPreloader,
    topLeft: state.dashboard.desktop.visibleLeft,
    topRight: state.dashboard.desktop.visibleRight,
    bottomLeft: state.dashboard.desktop.hiddenLeft,
    bottomRight: state.dashboard.desktop.hiddenRight,
    t: state.language.t,
  };
};

const mapDispatchToProps = {
  reorderDashboard,
  fetchDashboard,
  toggleSection,
};

export default connect(mapStateToProps, mapDispatchToProps)(Manage);
