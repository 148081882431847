import React, { Fragment } from 'react';
import lifecycle from 'react-pure-lifecycle';
import { connect } from 'react-redux';
import Page from '../components/Page';
import Member from '../components/Member';
import Preloader from '../components/Preloader';
import NoDataCard from '../components/Card/NoDataCard';
import CardOfMember from '../components/CardOfMember';
import {
  fetchVerifiers,
  fetchMemberDetails,
  getVerified,
  fetchVerifierStatus,
  // becomeVerifier,
  fetchReviews,
  addReview,
  onVerifierMessageClose,
  onVerifierSuccessMessageClose,
} from '../store/actions';
import { NAME, COUNTRY,DATES } from '../components/Search/TopFilters/types';
// import { getScrollXY, scrollTo } from '../components/Page/Page';
import TopMessage from '../components/TopMessage';
import Modal from '../components/Modal';
import languages from '../helpers/languages';

var language = languages['en'];
var label = language.verifiers[0];
var common = language.common[0];
var menu = language.menu[0];

const mapStateToProps = (state) => ({
  cards: state.verifiers.verifiers,
  showMessage: state.verifiers.messageShow,
  totalItems: state.verifiers.totalItems,
  sortLabel: state.verifiers.sortLabel,
  detail: state.members.memberDetails,
  verifierStatus: state.verifiers.verifierStatus,
  reviews: state.members.reviews,
  totalReviews: state.members.totalReviews,
  search: state.search,
  verifierSuccessMessage: state.verifiers.verifierSuccessMessage,
  checkStatus: state.auth.user.verifier_status,
  userRole: state.auth.user.userRole,
  lan: state.language.lan,
});

const mapDispatchToProps = {
  fetchVerifiers,
  fetchMemberDetails,
  getVerified,
  fetchVerifierStatus,
  // becomeVerifier,
  fetchReviews,
  addReview,
  onVerifierMessageClose,
  onVerifierSuccessMessageClose,
};

const methods = {
  componentDidMount(props) {
    props.fetchVerifiers(window.location.hash,props.search);
    props.fetchVerifierStatus();
    var queryParam = window.location.hash.split('/');
    if (queryParam[3] && queryParam[4]) {
      props.fetchMemberDetails(window.location.hash);
      props.fetchReviews(window.location.hash, 0, 1);
    }
  },
};

const getRenderCards = (props) => (cards) => (
  <div className='cards-list'>
    {cards === null ? (
      <Preloader size='medium' relative />
    ) : cards.length > 0 ? (
      cards.map((card, i) => (
        <CardOfMember
          key={card.id || i}
          verifyInsteadOfAdd='1'
          detailUrl='verification'
          addContactFunction={props.getVerified}
          pageType='verification'
          {...card}
        />
      ))
    ) : (
      <NoDataCard />
    )}
  </div>
);

class BecomeAVerifier extends React.Component {
  constructor() {
    super();
    this.state = {
      isModalOpened: false,
    };
  }

  setIsModalOpened(isModalOpened) {
    this.setState({
      ...this.state,
      isModalOpened,
    });
  }

  onConfirm() {
    const { onConfirm } = this.props;
    onConfirm();
    this.setIsModalOpened(false);
  }

  render() {
    language = languages[this.props.lan];
    label = language.verifiers[0];
    common = language.common[0];
    menu = language.menu[0];
    const toolbar = {
      tabs: {
        values: {
          all: { text: label.ALL_VERIFIERS },
          my: { text: label.MY_VERIFIERS },
          me: { text: label.VERIFIED_BY_ME },
        },
        filterByKeys: (card) => [card.type],
      },
      sort: [
        { orderBy: 'date', order: 'DESC', label: common.RECENT },
         { orderBy: 'date', order: 'ASC', label: common.OLDEST },
      ],
    };
    const { verifierStatus } = this.props;
    const isTouched = verifierStatus === '1' || verifierStatus === '2';

    let buttonText;
    switch (verifierStatus) {
      case '1':
        buttonText = label.PENDING;
        break;
      case '2':
        buttonText = label.VERIFIER;
        break;
      default:
        buttonText = label.BECOME_VERIFIER;
    }

    const buttonStyle = {};
    if (isTouched) {
      buttonStyle.pointerEvents = 'none';
    }

    const { isModalOpened } = this.state;

    return (
      <Fragment>
        <a
          href='javascript: void null'
          onClick={() => this.setIsModalOpened(true)}
          className={
            isTouched ? 'button button_gray button_hover-brand' : 'button'
          }
          style={buttonStyle}
        >
          <span className='button__text'>{buttonText}</span>
        </a>

        <Modal
          isOpen={isModalOpened}
          smallOnMobile
          onRequestClose={() => this.setIsModalOpened(false)}
          buttons={{
            left: {
              text: common.CANCEL,
              className: 'gray',
              onClick: () => this.setIsModalOpened(false),
            },
            right: {
              text: common.CONFIRM,
              className: 'brand',
              onClick: () => this.onConfirm(),
            },
          }}
        >
          <section className='content content_p-margins modal-content__title'>
            <h4>{label.CHANGE_PROFILE}</h4>
            <p>{label.CHANGE_PROFILE_NOTE}</p>
          </section>
        </Modal>
      </Fragment>
    );
  }
}

const PageVerification = (props) => {
  language = languages[props.lan];
  label = language.verifiers[0];
  common = language.common[0];
  menu = language.menu[0];
  var roleFlag = false;
  if (props.userRole === 'member' || typeof props.userRole === 'undefined') {
    roleFlag = false;
  }
  const toolbar = {
    tabs: {
      values: {
        all: { text: label.ALL_VERIFIERS },
        my: { text: label.MY_VERIFIERS },
        me: { text: label.VERIFIED_BY_ME },
      },
      filterByKeys: (card) => [card.type],
    },
    sort: [
      { orderBy: 'date', order: 'DESC', label: common.RECENT },
      { orderBy: 'date', order: 'ASC', label: common.OLDEST },
    ],
  };
  const {
    cards,
    totalItems,
    detail,
    reviews,
    verifierStatus,
    checkStatus,
  } = props;
  let bottom;
  // const onExploreMoreClick = () => {
  //   const top = bottom.getBoundingClientRect().top;
  //   const [, scrollY] = getScrollXY();
  //   scrollTo(top + scrollY - 120);
  // };

  Object.keys(toolbar.tabs.values).forEach(function (key) {
    if (key === 'me' && checkStatus !== 2) {
      delete toolbar.tabs.values[key];
    }
  });

  return (
    <Page
      name={menu.VERIFIES}
      handleIds={true}
      topfilters={[NAME, COUNTRY]}
      canCreateNew={roleFlag}
      renderAfterTitle={() => (
        <div className='after-title flex'>
          <div className='left'>
            <p className='gray'>{label.INFO}</p>
          </div>

          <div className='right'>
            {/* <div className='buttons buttons_flex'>
              <a
                href='javascript: void null'
                className='button '
                onClick={onExploreMoreClick}
              >
                <span className='button__text'>{label.EXPLORE_MORE}</span>
              </a>

              <BecomeAVerifier
                verifierStatus={verifierStatus}
                onConfirm={props.becomeVerifier}
                lan={props.lan}
              />
            </div> */}
          </div>
        </div>
      )}
      toolbar={toolbar}
      cards={cards}
      fetchFunction={props.fetchVerifiers}
      totalItems={totalItems}
      sortLabel={props.sortLabel}
      search={props.search}
      renderCards={getRenderCards(props)}
      cardsPerPage={6}
      renderAfterPagination={() => (
        <Fragment>
          <section
            className='verification-text row'
            ref={(el) => (bottom = el)}
          >
            <div className='col-md-6'>
              <div className='content content_p-margins'>
                <h3>{label.WHY_IT_IS_IMPORTANT}</h3>
              </div>
            </div>

            <div className='col-md-6'>
              <div className='content content_p-margins'>
                <p>{label.WHY_IT_IS_IMPORTANT_INFO_ONE}</p>

                <p>{label.WHY_IT_IS_IMPORTANT_INFO_TWO}</p>
              </div>
            </div>
          </section>

          <section className='verification-text row'>
            <div className='col-md-6'>
              <div className='content content_p-margins'>
                <h3>{label.WHO_CAN_BE_VERIFIER}</h3>
              </div>
            </div>

            <div className='col-md-6'>
              <div className='content content_p-margins'>
                <p>{label.WHO_CAN_BE_VERIFIER_INFO_ONE}</p>

                <p>{label.WHO_CAN_BE_VERIFIER_INFO_TWO}</p>
              </div>
               <div className='buttons'>
                <BecomeAVerifier
                  verifierStatus={verifierStatus}
                  onConfirm={props.becomeVerifier}
                  lan={props.lan}
                />
              </div> 
            </div>
          </section>

          <TopMessage
            isShown={props.showMessage}
            onCloseClick={() => props.onVerifierMessageClose(false)}
            type='success'
            text={label.TOP_MESSAGE}
          />

          <TopMessage
            isShown={props.verifierSuccessMessage}
            onCloseClick={() => props.onVerifierSuccessMessageClose()}
            type='success'
            text={language.registration[0].SUCCESS}
          />
        </Fragment>
      )}
      {...props}
      renderDetail={() => (
        <Member
          {...detail}
          verifyInsteadOfAdd='1'
          addContactFunction={props.getVerified}
          pageType='verificationDetails'
          reviews={reviews}
          totalReviews={props.totalReviews}
          fetchReviewsFunction={props.fetchReviews}
          addReviewFunction={props.addReview}
        />
      )}
    />
  );
};

PageVerification.defaultProps = {
  cards: [],
};

const PageVerificationCycle = lifecycle(methods)(PageVerification);
const List = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageVerificationCycle);

export default List;
