import React from 'react';
import PropTypes from 'prop-types';

const formatNumber = (number) => Math.round(number * 1e6) / 1e6;

const GoogleMapCoordinates = ({ lat, lng }) => (
  <div className='google-map-coordinates'>
    <span className='chat-source-link'>
      {formatNumber(lat)}, {formatNumber(lng)}
    </span>

    <div className='info'>
      {formatNumber(lat)}, {formatNumber(lng)}
    </div>
  </div>
);

GoogleMapCoordinates.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
};

export default GoogleMapCoordinates;
