import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  fetchRecentMessages,
  emitApplozicSocketEvent,
  setApplozicApi,
  toggleApplozicApiFetching,
} from '../../store/actions';
import './lib/Applozic-Web-Plugin-master/public/plugin/js/jquery.min.js';
import './lib/Applozic-Web-Plugin-master/public/applozic.chat.min';
import { APPLOZIC_APP_ID } from '../../constants';

import SockJS from 'sockjs-client';
window.SockJS = SockJS; // applozic wants like this

const APPLOZIC_SOCKET_EVENT_HANDLERS = [
  'onConnectFailed',
  'onConnect',
  'onMessageDelivered',
  'onMessageRead',
  'onMessageDeleted',
  'onConversationDeleted',
  'onUserConnect',
  'onUserDisconnect',
  'onConversationReadFromOtherSource',
  'onConversationRead',
  'onMessageReceived',
  'onMessageSentUpdate',
  'onMessageSent',
  'onUserBlocked',
  'onUserUnblocked',
  'onUserActivated',
  'onUserDeactivated',
  'connectToSocket',
  'onMessage',
  'onTypingStatus',
];

const parseEventName = (eventHandlerName) => {
  if (eventHandlerName.substr(0, 2) === 'on') {
    return (
      eventHandlerName.substr(2, 1).toLowerCase() + eventHandlerName.substr(3)
    );
  }

  return eventHandlerName;
};

const mapStateToProps = (state) => ({
  applozicApi: state.messenger.applozicApi,
  applozicApiFetching: state.messenger.applozicApiFetching,
  user: state.auth.user,
  userId: state.auth.user && state.auth.user.id,
});

const mapDispatchToProps = {
  fetchRecentMessages,
  emitApplozicSocketEvent,
  setApplozicApi,
  toggleApplozicApiFetching,
};

const loadMinimalChat = ({ userId, displayName, avatar }) =>
  new Promise((resolve, reject) => {
    window.Applozic.ALApiService.login({
      data: {
        baseUrl: 'https://apps.applozic.com',
        alUser: {
          applicationId: APPLOZIC_APP_ID,
          userId,
          displayName,
          imageLink: avatar,
        },
      },
      success(response) {
        resolve({ response, applozicApi: window.Applozic });
      },
      error() {
        reject();
      },
    });
  });

class ApplozicProvider extends React.Component {
  static propTypes = {
    applozicApiFetching: PropTypes.bool,
    applozicApi: PropTypes.object,
    emitApplozicSocketEvent: PropTypes.func,
    setApplozicApi: PropTypes.func,
    toggleApplozicApiFetching: PropTypes.func,
    user: PropTypes.object,
    userId: PropTypes.number,
  };

  componentDidMount() {
    this.load();
  }

  componentDidUpdate({ userId: oldUserId }) {
    const { userId } = this.props;
    if (oldUserId !== userId) {
      this.load();
    }
  }

  async load() {
    const {
      applozicApi,
      applozicApiFetching,
      fetchRecentMessages,
      emitApplozicSocketEvent,
      setApplozicApi,
      toggleApplozicApiFetching,
      user,
      userId,
    } = this.props;

    if (!userId || applozicApi || applozicApiFetching) return;

    toggleApplozicApiFetching(true);
    const { displayName, avatar } = user;
    const data = await loadMinimalChat({
      userId,
      displayName,
      avatar,
    }).catch((e) => {
      toggleApplozicApiFetching(false);
      throw e;
    });
    toggleApplozicApiFetching(false);

    if (typeof data === 'object') {
      const {
        applozicApi,
        response: { token, deviceKey, websocketUrl },
      } = data;
      const { ALSocket } = applozicApi;
      const socketEventHandlers = APPLOZIC_SOCKET_EVENT_HANDLERS.reduce(
        (acc, type) => ({
          ...acc,
          [type]: (response) =>
            emitApplozicSocketEvent({ type: parseEventName(type), response }),
        }),
        {}
      );
      ALSocket.init(
        APPLOZIC_APP_ID,
        { token, deviceKey, websocketUrl },
        socketEventHandlers
      );
      setApplozicApi(applozicApi);
      fetchRecentMessages();
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplozicProvider);
