/*
 Adds animation of dropdown opening and closing to element with dropdownRef inside the wrapped component
 by clicking on toggleRef in the wrapped component or outside of it

 Reference to exposing refs to parent components https://reactjs.org/docs/refs-and-the-dom.html#exposing-dom-refs-to-parent-components
 */

import React, { Component } from 'react';
import gsap from "gsap";
import { TweenLite } from 'gsap';
import PropTypes from 'prop-types';
import './elementClosestPolyfill';
import { ScrollToPlugin } from 'gsap/all'

//gsap.registerPlugin(ScrollToPlugin)

function withDropdown(WrappedComponent) {
  return class Dropdown extends Component {
    static propTypes = {
      closeOnClick: PropTypes.bool,
      closeOnToggleClick: PropTypes.bool,
      onToggleEnd: PropTypes.func,
      onToggle: PropTypes.func,
    };

    static defaultProps = {
      closeOnClick: false,
      closeOnToggleClick: true,
      onToggleEnd: () => {},
      onToggle: () => {},
    };

    constructor(props) {
      super(props);
      this.state = {
        opened: false,
      };
      this.toggleElement = null;
      this.dropdownElement = null;
    }

    componentDidMount() {
      document.addEventListener(
        'click',
        this.handleOutsideClick,
        { useCapture: true },
        true
      );
      document.addEventListener(
        'keyup',
        this.handleEscButtonPressed,
        { useCapture: true },
        true
      );
    }

    componentWillUnmount() {
      document.removeEventListener('click', this.handleOutsideClick, true);
      document.removeEventListener('keyup', this.handleEscButtonPressed, true);
    }

    toggle() {
      this.setState(
        {
          opened: !this.state.opened,
        },
        () => {
          TweenLite.fromTo(
            this.dropdownElement,
            0.2,
            {
              autoAlpha: +!this.state.opened,
              y: +this.state.opened * 15,
            },
            {
              autoAlpha: +this.state.opened,
              onComplete: () => {
                if (this.props.onToggleEnd) {
                  this.props.onToggleEnd(this.state.opened);
                }
              },
              y: +!this.state.opened * 15,
            }
          );

          if (this.props.onToggle) {
            this.props.onToggle(this.state.opened);
          }
        }
      );
    }

    handleOutsideClick = (e) => {
      const clickInsideModal = e.target.closest('.ReactModalPortal');
      const dropdownInsideModal =
        this.dropdownElement &&
        this.dropdownElement.closest('.ReactModalPortal');
      if (clickInsideModal !== dropdownInsideModal) return;
      if (!document.documentElement.contains(e.target)) return;
      const { closeOnClick, closeOnToggleClick } = this.props;
      const toggleClicked =
        this.toggleElement && this.toggleElement.contains(e.target);
      const shouldClose =
        this.dropdownElement &&
        (!this.dropdownElement.contains(e.target) || closeOnClick) &&
        (closeOnToggleClick || !toggleClicked);
      if (this.state.opened ? shouldClose : toggleClicked) {
        this.toggle();
      }
    };

    handleEscButtonPressed = (e) => {
      if (e.keyCode === 27 && this.state.opened) {
        this.toggle();
      }
    };

    render() {
      return (
        <WrappedComponent
          toggleRef={(el) => (this.toggleElement = el)}
          dropdownRef={(el) => (this.dropdownElement = el)}
          dropdownOpened={this.state.opened}
          toggleDropdown={this.toggle.bind(this)}
          {...this.props}
        />
      );
    }
  };
}

export default withDropdown;
