/* eslint-disable no-unused-expressions */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import queryString from 'query-string';

import CustomizedTooltip from '../../components/CustomizedTooltip';
import Sort from '../../components/Sort';
import ButtonsList from '../../components/ButtonsList';
import TabsList from './components/TabsList';

const Toolbar = (props) => {
  const {
    tabs,
    light,
    sort,
    withToggleView,
    location,
    useNestedRoutes,
    fetchFunction,
    sortLabel,
    search,
    viewTemplate,
    t,
  } = props;
  const viewFlavours = [
    { id: 'grid', title: t.common[0].GRID_VIEW },
    { id: 'calendar', title: t.common[0].CALENDAR_VIEW },
  ];
  const queryObject = queryString.parse(location.search);

  const links =
    tabs !== null &&
    Object.keys(tabs.values).map((code, i) => {
      const toQueryObject = {
        ...queryObject,
        tab: code,
        page: undefined,
      };

      function isActive(match) {
        if (useNestedRoutes) {
          return match && match.isExact;
        } else if ('tab' in queryObject) {
          return code === queryObject.tab;
        } else if (tabs.filterByKeys) {
          return code === 'all';
        }
        return i === 0;
      }

      const to = useNestedRoutes
        ? code
        : `${location.pathname}?${queryString.stringify(toQueryObject)}`;

      return {
        to,
        isActive,
        onClick: fetchFunction ? () => fetchFunction(to, search) : undefined,
        title: tabs.values[code].text,
      };
    });

  const activeTabCode = useNestedRoutes
    ? location.pathname
    : queryObject.tab || 'all';
  const activeTab =
    tabs && (tabs.values[activeTabCode] || Object.values(tabs.values)[0]);
  const activeTabName = activeTab && activeTab.text;

  return (
    <div
      className={classNames('toolbar', {
        toolbar_white: !light && viewTemplate === 'tabs',
      })}
    >
      {tabs  && (
        <div className='left'>
          {viewTemplate === 'buttons' ? (
            <ButtonsList items={links} />
          ) : (
            <TabsList items={links} activeTabName={activeTabName} />
          )}
        </div>
      )}
      
      {!!sort.length && (
        <div className='right'>
          <Sort
            items={sort}
            fetchFunction={fetchFunction}
            search={search}
            sortLabel={sortLabel}
          />
        </div>
      )}

      {withToggleView && (
        <div className='right'>
          <div className='toggle-view'>
            {viewFlavours.map((flavour, i) => {
              const toQueryObject = {
                ...queryObject,
                view: flavour.id,
              };

              function isActive() {
                if ('view' in queryObject) {
                  return queryObject.view === flavour.id;
                }
                return i === 0;
              }

              return (
                <CustomizedTooltip title={flavour.title} key={flavour.id}>
                  <span>
                    <NavLink
                      to={`${location.pathname}?${queryString.stringify(
                        toQueryObject
                      )}`}
                      isActive={isActive}
                      onClick={() => {
                        fetchFunction
                          ? fetchFunction(
                              `${location.pathname}?${queryString.stringify(
                                toQueryObject
                              )}`,
                              search
                            )
                          : '';
                      }}
                    >
                      <i className={`icon-toggle-view--${flavour.id}`} />
                    </NavLink>
                  </span>
                </CustomizedTooltip>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

Toolbar.propTypes = {
  tabs: PropTypes.object,
  light: PropTypes.bool,
  withToggleView: PropTypes.bool,
  sort: PropTypes.array,
  location: PropTypes.object.isRequired,
  useNestedRoutes: PropTypes.bool,
  fetchFunction: PropTypes.func,
  search: PropTypes.object,
  viewTemplate: PropTypes.oneOf(['tabs', 'buttons']),
  t: PropTypes.object,
};

Toolbar.defaultProps = {
  tabs: null,
  light: false,
  withToggleView: false,
  sort: [],
  fetchFunction: null,
  search: null,
  viewTemplate: 'tabs',
};

const mapStateToProps = (state) => ({
  t: state.language.t,
});

export default connect(mapStateToProps)(withRouter(Toolbar));
