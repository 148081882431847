import React from 'react';
import { connect } from 'react-redux';

import fbIcon from '../static/images/social/facebook.svg';
import twIcon from '../static/images/social/twitter.svg';
import goIcon from '../static/images/social/google.svg';
import liIcon from '../static/images/social/linkedin.svg';
import itcIcon from '../static/images/social/itc2.svg';

const links = [
  { id: 'facebook', name: 'Facebook', img: fbIcon },
  { id: 'google', name: 'Google', img: goIcon },
  { id: 'twitter', name: 'Twitter', img: twIcon },
  { id: 'linkedin', name: 'LinkedIn', img: liIcon },
  { id: 'itc', name: 'Itc', img: itcIcon }
];

const SocialLogin = () => {
  const linksJsx = links.map((el) => (
      <a
        className='itc-social-login-button'
        href={`https://shetrades.com/sociallogin/${el.id}`}
        title={el.name}
        rel="noopener noreferrer"
      >
        <img src={el.img} alt={el.name} />
      </a>
  ));
  return <ul>{linksJsx}</ul>;
};

const mapStateToProps = (state) => ({
  lan: state.language.lan,
});

export default connect(mapStateToProps)(SocialLogin);
