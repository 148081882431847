import { connect } from 'react-redux';
import {
  fetchTopFilterCountries,
  fetchTopFilterEventsType,
  fetchTopFilterEventsSector,
  fetchTopFilterWebinarsSector,
} from '../../../../store/actions';
import {
  countriesChange,
  dateChange,
  focusChange,
  reset,
  nameChange,
  priceRangeChange,
  updateTopFilterTrees,
  priceRangeChangeEnd,
  eventsTypeChange,
  eventsSectorChange,
  webinarsSectorChange,
} from '../../../../reducers/search';
import TopFilters from '../layout/TopFilters';

const mapStateToProps = (state) => ({
  countriesOptions: state.search.countriesTopOption,
  selectedCountries: state.search.selectedCountries,
  eventsTypeOptions: state.search.eventsTypeTopOption,
  selectedEventsType: state.search.selectedEventsType,
  eventsSectorOptions: state.search.eventsSectorTopOption,
  selectedEventsSector: state.search.selectedEventsSector,
  webinarsSectorOptions: state.search.webinarsSectorTopOption,
  selectedWebinarsSector: state.search.selectedWebinarsSector,
  datepicker: state.search.datepicker,
  name: state.search.name,
  isClearButtonDisabled: !state.search.filtersTouched,
  priceRange: state.search.priceRange,
  productId: state.search.productId,
  productIdCount: state.search.productIdCount,
  serviceId: state.search.serviceId,
  serviceIdCount: state.search.serviceIdCount,
  results: state.search.results,
  isMobile: state.responsive.isMobile,
  t: state.language.t,
});

const mapDispatchToProps = {
  onClear: reset,
  onCountriesChange: countriesChange,
  onEventTypeChange: eventsTypeChange,
  onEventSectorChange: eventsSectorChange,
  onWebinarSectorChange: webinarsSectorChange,
  onDatepickerDateChange: dateChange,
  onDatepickerFocusChange: focusChange,
  onNameChange: nameChange,
  onPriceRangeChange: priceRangeChange,
  onPriceRangeChangeEnd: priceRangeChangeEnd,
  onTreeChange: updateTopFilterTrees,
  fetchTopFilterCountries: fetchTopFilterCountries,
  fetchTopFilterEventsType: fetchTopFilterEventsType,
  fetchTopFilterEventsSector: fetchTopFilterEventsSector,
  fetchTopFilterWebinarsSector: fetchTopFilterWebinarsSector,
};

const List = connect(mapStateToProps, mapDispatchToProps)(TopFilters);
export default List;
