/**
 * Save data of several forms rendered conditionally or via router
 * Prevent leaving current route until form is saved
 */

import React from 'react';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  t: state.language.t,
});

class MultiStepForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentForm: null,
      formStates: {},
    };

    this.preventUnload = this.preventUnload.bind(this);
    this.preventUnloadNative = this.preventUnloadNative.bind(this);
  }

  async componentDidMount() {
    const { location } = this.props;
    const { formStates } = this.state;
    const filledTabsCount = Object.keys(formStates).length;
    const queryObject = queryString.parse(location.search);
    if (queryObject.step && queryObject.step - 1 > filledTabsCount) {
      const to = queryString.stringify({
        ...queryObject,
        step: filledTabsCount + 1,
      });
      delete formStates[queryObject.step];
      await this.setState({
        ...this.state,
        formStates,
      });
      this.props.history.push(location.pathname + '?' + to);
    }

    window.addEventListener('beforeunload', this.preventUnloadNative);
    this.unblock = this.props.history.block(this.preventUnload);
    this.initialPathname = this.props.location.pathname;
  }

  componentWillUnmount() {
    if (this.isPristine()) {
      this.cancelEvents();
    }
  }

  async setCurrentForm(newCurrentForm) {
    const { formStates, currentForm } = this.state;

    if (currentForm) {
      const { key, formApi } = currentForm;
      formStates[key] = formApi.getState();
    }

    if (newCurrentForm) {
      const { key, formApi } = newCurrentForm;
      if (formStates[key]) {
        formApi.setState(formStates[key]);
      }
    }

    await this.setState({
      formStates,
      currentForm: newCurrentForm,
    });
  }

  async onFinish() {
    this.cancelEvents();
    await this.setCurrentForm(null);
  }

  isPristine() {
    // if we have some steps saved this means at least 1 step is completely filled
    if (Object.keys(this.state.formStates).length !== 0) {
      return false;
    }

    // if we are on 1st step, check if form was touched
    if (this.state.currentForm && this.state.currentForm.formApi) {
      return this.state.currentForm.formApi.getState().pristine;
    }

    return true;
  }

  preventUnloadNative(e) {
    if (!this.isPristine()) {
      e.preventDefault();
      e.returnValue = this.props.t.common[0].WILL_BE_LOST;
      return this.props.t.common[0].WILL_BE_LOST;
    }
    return undefined;
  }

  preventUnload(nextLocation) {
    if (
      !this.isPristine() &&
      nextLocation.pathname !== this.props.location.pathname
    ) {
      return this.props.t.common[0].WILL_BE_LOST;
    }
    return undefined;
  }

  cancelEvents() {
    window.removeEventListener('beforeunload', this.preventUnloadNative);
    if (this.unblock) {
      this.unblock();
    }
  }

  render() {
    return this.props.children({
      currentForm: this.state.currentForm,
      formStates: this.state.formStates,
      setCurrentForm: this.setCurrentForm.bind(this),
      onFinish: this.onFinish.bind(this),
    });
  }
}

export default withRouter(connect(mapStateToProps)(MultiStepForm));
