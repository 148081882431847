/*
Input component
Encapsulates the view aspect of all types of input elements (checkbox group, select, textarea etc).
Allows the unified data interchange with parent components (e.g. forms) via set of props:
- type
- value
- id
- label
- ... (see all described in PropTypes)

The validation and value manipulation should be performed outside in parent components and result passed via props:
- hasError
- errors

 */

import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { Text, Select, Option, Radio, RadioGroup, Checkbox } from 'informed';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import DropdownTreeField from '../../../components/settings/DropdownTree/components/Field';
import {
  SelectInput,
  SelectInputField,
} from '../../../components/settings/SelectInput';
import ArrayInput from './components/ArrayInput';
import DateInput from './components/DateInput';
import FilesInput from './components/FilesInput';
import LocationInputWithButton from './components/LocationInput/WithButton';
import AutosizeTextareaMaxlength from './components/AutosizeTextareaMaxlength';
import TimeInputField from './components/TimeInput/components/Field';
import EditorInput from './components/EditorInput';
import CheckboxGroupInput from './components/CheckboxGroupInput';
import RangeInput from './components/RangeInput';

export const mergeDateTime = ({ date, time }) => {
  const dateMoment = moment(date);
  const timeMoment = moment(time);

  return dateMoment.hour(timeMoment.hour()).minute(timeMoment.minute());
};

const mapStateToProps = (state) => ({
  isMobile: state.responsive.isMobile,
});

class Input extends Component {
  static propTypes = {
    label: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.array,
    ]),
    type: PropTypes.string,
    hasFlag: PropTypes.bool,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    options: PropTypes.array,
    withAttachFiles: PropTypes.bool,
    textareaProps: PropTypes.object,
    vertical: PropTypes.bool,
    hasError: PropTypes.bool,
    errors: PropTypes.string,
    dropdownTreeOptions: PropTypes.object,
    informedProps: PropTypes.object,
    selectProps: PropTypes.object,
    editorProps: PropTypes.object,
    rangeInputProps: PropTypes.object,
    fileInputProps: PropTypes.object,
    disabled: PropTypes.bool,
    array: PropTypes.bool,
    renderBottom: PropTypes.func,
    formApi: PropTypes.object,
    isMobile: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]),
  };

  static defaultProps = {
    placeholder: '',
    type: 'text',
    hasFlag: false,
    onChange: () => {},
    options: [],
    withAttachFiles: false,
    required: false,
    dropdownTreeOptions: {
      position: 'bottom',
    },
    disabled: false,
    array: false,
    formApi: {},
    selectProps: {},
    textareaProps: {},
    editorProps: {},
    rangeInputProps: {},
    fileInputProps: {},
  };

  render() {
    const {
      label,
      id,
      placeholder = `Enter ${label}`,
      type,
      required,
      hasFlag,
      options,
      vertical,
      hasError,
      errors,
      dropdownTreeOptions,
      disabled,
      array,
      value,
      onChange,
      renderBottom,
      formApi,
      informedProps,
      selectProps,
      textareaProps,
      editorProps,
      rangeInputProps,
      isMobile,
      children,
      fileInputProps,
    } = this.props;

    let inputHtml;

    let bottomHtml;

    switch (type) {
      case 'select':
        const {
          async,
          createable,
          noInformedField,
          ...otherSelectProps
        } = selectProps;
        const resultProps = {
          ...informedProps,
          async,
          createable,
          onChange,
          reactSelectProps: {
            ...otherSelectProps,
            className: 'inputbox',
            disabled,
            placeholder,
            options,
          },
        };
        if (noInformedField) {
          resultProps.value = value;
        } else {
          resultProps.field = id;
        }
        inputHtml = React.createElement(
          noInformedField ? SelectInput : SelectInputField,
          resultProps
        );
        break;

      case 'textarea':
        inputHtml = (
          <AutosizeTextareaMaxlength
            field={id}
            textareaProps={{
              className: 'inputbox',
              rows: 1,
              disabled,
              placeholder,
              required,
              ...textareaProps,
            }}
          />
        );
        break;

      case 'date':
        inputHtml = <DateInput field={id} />;
        break;

      case 'time':
        inputHtml = (
          <div className='row'>
            <div className='col-8'>
              <DateInput field={id + '.date'} />
            </div>

            <div className='col-4'>
              <TimeInputField field={id + '.time'} disabled={disabled} />
            </div>
          </div>
        );
        break;

      case 'checkbox':
        return (
          <div
            className={classNames([
              'input',
              { input_vertical: vertical || isMobile },
            ])}
            data-input-id={id}
          >
            <div className='option option_checkbox'>
              <Checkbox
                type='checkbox'
                id={id}
                field={id}
                required={required}
              />

              <label htmlFor={id}>
                <i className='icon-checkbox' /> {label}
              </label>
            </div>
          </div>
        );

      case 'checkbox-group':
        inputHtml = (
          <CheckboxGroupInput
            field={id}
            id={id}
            options={options}
            {...informedProps}
          />
        );
        break;

      case 'tab-group':
      case 'radio-group': {
        const isTabGroup = type === 'tab-group';
        let groupClassName;

        if (isTabGroup) {
          groupClassName = 'tab-group buttons buttons_group';
        } else {
          groupClassName = 'options-group';
        }

        inputHtml = (
          <RadioGroup
            className='inputbox'
            field={id}
            onChange={(e) => onChange(e.target.value)}
            {...informedProps}
          >
            <div className={groupClassName}>
              {options.map(({ label: optionLabel, value: optionValue }, i) => {
                const optionId = `radio-option_${id}__${i}`;

                if (isTabGroup) {
                  return (
                    <Fragment key={optionId}>
                      <Radio value={optionValue} id={optionId} />

                      <label
                        htmlFor={optionId}
                        title={optionLabel}
                        className='button button_border button_gray button_radio'
                        key={optionId}
                      >
                        <i className='icon-done' />

                        <span className='button__text'>{optionLabel}</span>
                      </label>
                    </Fragment>
                  );
                }

                return (
                  <div className='option option_radio' key={optionId}>
                    <Radio value={optionValue} id={optionId} />

                    <label htmlFor={optionId}>
                      <i className='icon-radio' />
                      <i className='icon-radio--checked' />

                      {optionLabel}
                    </label>
                  </div>
                );
              })}
            </div>
          </RadioGroup>
        );
        break;
      }

      case 'dropdown-tree':
        inputHtml = (
          <DropdownTreeField
            placeholder={placeholder}
            field={id}
            id={id}
            {...informedProps}
            {...dropdownTreeOptions}
          />
        );
        break;

      case 'images':
      case 'files':
        inputHtml = (
          <FilesInput
            imagesOnly={type === 'images'}
            value={value}
            onChange={onChange}
            {...fileInputProps}
          />
        );
        break;

      case 'location':
        inputHtml = (
          <LocationInputWithButton id={id} placeholder={placeholder} />
        );
        break;

      case 'editor':
        inputHtml = <EditorInput field={id} editorProps={editorProps} />;
        break;

      case 'range':
        inputHtml = <RangeInput field={id} {...rangeInputProps} />;
        break;

      default:
        const props = {
          ...informedProps,
          className: 'inputbox',
          field: id,
          placeholder,
          type,
          required,
          disabled,
        };
        if (array) {
          props.inputComponent = Text;
          props.length =
            formApi.getState().values[id] &&
            formApi.getState().values[id].length;
        }
        inputHtml = React.createElement(array ? ArrayInput : Text, props);
    }

    if (hasError && errors) {
      bottomHtml = <span className='input__errors'>{errors}</span>;
    }

    return (
      <div
        className={classNames([
          'input',
          {
            'input_align-top':
              type === 'textarea' || type === 'editor' || array,
          },
          { 'input_flag-top': hasFlag },
          { input_vertical: vertical || isMobile },
          { input_error: hasError },
          { input_disabled: disabled },
        ])}
        data-input-id={id}
      >
        {label && (
          <label className='input__label' htmlFor={id}>
            {label}: {required && <sup>*</sup>}
          </label>
        )}

        <div className='inputbox-wrapper'>
          {hasFlag && (
            <img src='images/flag--tanzania.jpg' alt='' className='flag' />
          )}

          {children || inputHtml}

          {(renderBottom || bottomHtml) && (
            <div className='input__bottom info'>
              {renderBottom ? renderBottom() : bottomHtml}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Input);
