import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Level from '../Level';

class Node extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    route: PropTypes.string,
    value: PropTypes.object,
  };

  static defaultProps = {
    value: {},
  };

  static hasCheckedAncestors(level) {
    const checkedChildren = Object.values(level).filter(
      ({ checked, children }) => {
        return (
          checked || (children ? Node.hasCheckedAncestors(children) : false)
        );
      }
    );
    return !!checkedChildren.length;
  }

  static checkAllAncestors(level, value) {
    if (Array.isArray(level)) {
      return level;
    }

    return Object.entries(level).reduce(
      (acc, [id, nodeValue]) => ({
        ...acc,
        [id]: {
          ...nodeValue,
          checked: value,
          children: nodeValue.children
            ? Node.checkAllAncestors(nodeValue.children, value)
            : undefined,
        },
      }),
      {}
    );
  }

  constructor() {
    super();
    this.state = {
      expanded: false,
      shouldLevelFetch: true,
    };

    this.onChildrenChange = this.onChildrenChange.bind(this);
  }

  componentDidMount() {
    const {
      value: { forceExpanded, forceFetch },
    } = this.props;
    if (forceFetch) {
      this.setState({
        expanded: true,
      });
    } else if (forceExpanded) {
      this.setState({
        shouldLevelFetch: false,
        expanded: true,
      });
    }
  }

  componentDidUpdate(oldProps) {
    const {
      value: { forceExpanded: oldForceExpanded },
    } = oldProps;
    const {
      value: { forceExpanded },
    } = this.props;
    if (forceExpanded !== oldForceExpanded) {
      this.setState({
        shouldLevelFetch: !forceExpanded,
        expanded: forceExpanded,
      });
    }
  }

  toggleExpanded(expanded) {
    this.setState({
      expanded,
    });
  }

  toggleChecked(checked) {
    const { value, onChange } = this.props;
    const { children } = value;
    const levelIsBroken = Array.isArray(children);
    if (onChange && children && !levelIsBroken) {
      const newChildren = Node.checkAllAncestors(children, checked);
      onChange({
        ...value,
        checked,
        children: newChildren,
        childChecked: Node.hasCheckedAncestors(newChildren),
      });
    } else if (onChange) {
      onChange({
        ...value,
        checked,
      });
    }
  }

  onChildrenChange(children) {
    const { value, onChange } = this.props;
    const { children: oldChildren, checked } = value;
    if (!oldChildren && children) {
      onChange({
        ...value,
        children: Node.checkAllAncestors(children, checked),
        childChecked: checked,
      });
    } else {
      onChange({
        ...value,
        children,
        childChecked: Node.hasCheckedAncestors(children),
      });
    }
  }

  render() {
    const { value, route } = this.props;
    const {
      id,
      name,
      checked,
      children,
      childChecked,
      hasChildren,
      forceExpanded,
    } = value;
    const { expanded, shouldLevelFetch } = this.state;

    const levelIsBroken = Array.isArray(children); // || _.isEmpty(children);

    return (
      <li className={classNames({ 'children-show': expanded })}>
        <div
          className={classNames([
            'dropdown-tree__item',
            { 'dropdown-tree__item--child-checked': childChecked },
          ])}
        >
          <span className='dropdown-tree__item-line' />

          <div className='dropdown-tree__item-title'>
            <label title={name}>
              <input
                type='checkbox'
                className='visually-hidden'
                defaultChecked={checked}
                onChange={this.toggleChecked.bind(this, !checked)}
              />
              <span className='decoration' />
              <span className='id'>{id}</span>
              <span className='text'>{name}</span>
            </label>
          </div>
        </div>

        {hasChildren && hasChildren !== 'false' && (
          <div
            className='dropdown-tree__btn'
            onClick={this.toggleExpanded.bind(this, !expanded)}
          />
        )}

        {expanded && !levelIsBroken && (
          <Level
            parentId={id}
            value={children}
            onChange={this.onChildrenChange}
            route={route}
            shouldFetch={shouldLevelFetch}
          />
        )}
      </li>
    );
  }
}

export default Node;
