import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const DraggableFilePreview = (props) => {
  const { preview, name, onIconClick, isDragging, withIcon } = props;

  return (
    <div
      className={classNames([
        'upload-image-preview',
        {
          'upload-image-preview_dragging': isDragging,
          'upload-image-preview_has-preview': !!preview,
        },
      ])}
    >
      {preview && (
        <img src={preview} alt='' className='upload-image-preview__image' />
      )}
      <div className='upload-image-preview__name'>{name}</div>
      {withIcon && (
        <div className='upload-image-preview__icon' onClick={onIconClick}>
          <i className='icon-done' />
          <i className='icon-trash' />
        </div>
      )}
    </div>
  );
};

DraggableFilePreview.propTypes = {
  isDragging: PropTypes.bool,
  name: PropTypes.string,
  onIconClick: PropTypes.func,
  preview: PropTypes.string,
  withIcon: PropTypes.bool,
};

DraggableFilePreview.defaultProps = {
  withIcon: true,
};

export default DraggableFilePreview;
