import {
  VERIFIERS,
  VERIFIER_STATUS,
  VERIFIER_MESSAGE_HIDE,
  PURGE_MEMBERS,
  VERIFIER_SUCCESS_MESSAGE,
  CLOSE_VERIFIER_SUCCESS_MESSAGE,
} from '../store/actions';

const initialState = {
  verifiers: null,
  messageShow: false,
  verifierStatus: '0',
  verifierSuccessMessage: false,
};

export default function verifiers(state = initialState, action) {
  switch (action.type) {
    case VERIFIERS:
      return {
        ...state,
        verifiers: action.payload.data,
        totalItems: action.payload.count,
        messageShow: action.payload.data.messageShow,
        sortLabel: action.payload.sortLabel,
      };
    case VERIFIER_STATUS:
      return {
        ...state,
        messageShow: action.payload.data.messageShow,
        verifierStatus: action.payload.data.verifierStatus,
      };

    case VERIFIER_MESSAGE_HIDE:
      return {
        ...state,
        messageShow: false,
      };
    case VERIFIER_SUCCESS_MESSAGE:
      return {
        ...state,
        verifierSuccessMessage: true,
        messageShow: false,
      };
    case CLOSE_VERIFIER_SUCCESS_MESSAGE:
      return {
        ...state,
        verifierSuccessMessage: false,
        messageShow: false,
      };
    case PURGE_MEMBERS:
      const { memberSection } = action.payload;
      if (memberSection === 'verifiers') {
        return {
          ...initialState,
          verifierStatus: state.verifierStatus,
        };
      }
      return state;

    default:
      return state;
  }
}
