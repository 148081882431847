import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/fr';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CustomizedDocumentTitle from '../components/CustomizedDocumentTitle';
import Preloader from '../components/Preloader';
import Share from '../components/Share';
import cardNews from '../data/news.json';
import cardPublication from '../data/publications.json';
import cardSuccessStories from '../data/success-stories.json';
import cardVideos from '../data/videos.json';
import languages from '../helpers/languages';


var language = languages['en'];
var common = language.common[0];


const mapStateToProps = (state) => {
  return {
    lan: state.language.lan,
  };
};

class Blog extends React.Component {
  state = {
    isModalOpened: false
  };

  componentDidMount() {
    
  }

  render() {
    language = languages[this.props.lan];
    common = language.common[0];
    const { showPreloader } = this.props;
    const {
      isModalOpened,
    } = this.state;
    const latestNew=cardNews[1];
    const latestSuccessStory=cardSuccessStories[1];
    const latestVideo=cardVideos[1];
    const latestPublication = cardPublication[1];
    return (
      <CustomizedDocumentTitle title={common.MY_DASHBOARD}>
        <div className='transition-page'>
          {showPreloader ? (
            <Preloader size='medium' relative />
          ) : (
                <div id="blog">
                {/*top bar */}
                <div id="top_bar">
                  <div className="wrapper">
                    <div className="blog-topbar-firstline">
                      <h2><Link to="/home" className="logo"><img src="images/svg/SheTrades-logo.svg" alt=" " /></Link><br />SheTrades blog</h2>
                    </div>
                    <hr />
                    <div className="blog-topbar-lastline">
                      {/*main nav */}
                      <input id="mobilenavswitcher" name="mobilenavswitcher" type="checkbox" />
                      <label htmlFor="mobilenavswitcher"><i className="far fa-bars" /><i className="fal fa-times" /></label>
                      <ul id="top_menu">
                        <li><Link to="/publicsuccessstories">{common.SUCCESS_STORIES}</Link></li>
                        <li><Link to="/publicvideos">Members interviews</Link></li>
                        <li><Link to="/publicopportunities">Opportunities</Link></li>
                        <li><Link to="/news">SheTrades News</Link></li>
                        <li><Link to="/publicpublications">Publications</Link></li>
                      </ul>
                      {/*main nav */}
                      <form action="/" method="post" className="searchform">
                        <input id="searchcheckbox" name="searchcheckbox" type="checkbox" />
                        <label htmlFor="searchcheckbox"><i className="fal fa-search" /></label>
                        <div className="searchformcontainer">
                          <input name="s" defaultValue required type="text" placeholder="Search for anything…" id /><button name="searchform" value="search" type="submit">search</button></div>
                      </form>
                    </div>
                  </div>
                </div>
                {/*top bar */}
                <div className="bggrey">
                  <div className="wrap">
                    <article className="blogarticle">
                      <div className="container-lr">
                        <div><h2>{latestNew.title}</h2><p>Published on {moment(latestNew.date).format('MMM D, YYYY')} in <Link to="/news">SheTrades News</Link> 
                        by SheTrades Team </p></div>
                        <Share pageUrl={`/news/${latestNew.id}`} pageTitle={latestNew.title} />
                      </div>
                      <picture className="blogpostpicture"><img src={latestNew.bigImage} alt=""/></picture>
                      <p>{latestNew.description} <Link to={`/news/${latestNew.id}`}>Read more…</Link>
                      </p>
                    </article>
                    <article className="blogarticle">
                      <div className="container-lr">
                        <div><h2>{latestSuccessStory.title}</h2><p>Published on  {moment(latestSuccessStory.date).format('MMM D, YYYY')} in <Link to="/publicsucessstories">SheTrades success stories</Link> by Corinne S.</p></div>
                         <Share pageUrl={`/publicsuccessstories/${latestSuccessStory.id}`} pageTitle={latestSuccessStory.title} />
                      </div>
                      <picture className="blogpostpicture">
                        <img src={latestSuccessStory.image} alt=""/></picture>
                      <p>{latestSuccessStory.description}<Link to={`/publicsuccessstories/${latestSuccessStory.id}`} >Read more…</Link></p>
                    </article>
                    <article className="blogarticle">
                      <div className="container-lr">
                        <div><h2>{latestVideo.title}</h2><p>Published on  {moment(latestVideo.date).format('MMM D, YYYY')} in <Link to="/publicsucessstories">SheTrades success stories</Link> by SheTrades Team.</p></div>
                        <Share pageUrl={`/publicvideos/${latestVideo.id}`} pageTitle={latestVideo.title} />
                      </div>
                      <picture className="blogpostpicture">
                        <img src={latestVideo.bigImage} alt=""/></picture>
                      <p>{latestVideo.description}<Link to={`/publicvideos/${latestNew.id}`} >Read more…</Link></p>
                    </article>
                    <article className="blogarticle">
                      <div className="container-lr">
                        <div><h2>{latestPublication.title}</h2><p>Published on  {moment(latestPublication.date).format('MMM D, YYYY')} in <Link to="/publicsucessstories">SheTrades success stories</Link> by Corinne S.</p></div>
                        <Share pageUrl={`/publicpulications/${latestPublication.id}`} pageTitle={latestPublication.title} />
                      </div>
                      <picture className="blogpostpicture">
                        <img src={latestPublication.bigImage} alt=""/></picture>
                      <p>{latestPublication.description}<Link to={`/publicpublications/${latestNew.id}`} >Read more…</Link></p>
                    </article>
                    <p className="pagination">
                      <Link to="to" className="prevpage">
                        <i className="fas fa-arrow-circle-left" />
                        </Link> <Link to="to">1</Link> <Link to="to">2</Link> <Link to="to">3</Link> <Link to="to" className="active">4</Link> <Link to="to">5</Link> <Link to="to" className="nextpage"><i className="fas fa-arrow-circle-right" /></Link></p>
                  </div>
                </div>
                {/*footer links */}
                <div id="footer_links">
                  <div className="wrap">
                    <div className="container-4col">
                      <div><p>Follow us on social media</p>
                        <div className="socialmediaicons">
                          <Link to="to"><i className="fab fa-youtube-square" /></Link>
                          <Link to="to"><i className="fab fa-facebook-square" /></Link>
                          <Link to="to"><i className="fab fa-twitter-square" /></Link>
                          <Link to="to"><i className="fab fa-linkedin" /></Link>
                        </div></div>
                      <div><strong>SheTrades Community</strong>
                        <Link to="/publiccommunitycalender">Community Calendar</Link>
                        <Link to="/publicshetradesblog">SheTrades Blog</Link>
                        <Link to="/publichubs">SheTrades Hubs</Link>
                        <Link to="/publicprojects">Our Projects</Link></div>
                      <div><strong>SheTrades Tools</strong>
                        <Link to="/offers">Sell &amp; Buy</Link>
                        <Link to="/publicopportunities">Opportunities Board</Link>
                        <Link to="/publicoutlook">SheTrades Outlook</Link></div>
                      <div><strong>Useful Links</strong>
                        <Link to="/publiccom">SheTrades.com</Link>
                        <Link to="/publicresources">Resource Centre</Link>
                        <Link to="/publiccovid">COVID-19 Support</Link>
                        <Link to="/publicblog">Media Portal</Link></div>
                    </div>
                  </div>
                </div>
                {/*footer links */}
                {/*footer */}
                <footer>
                  <div className="wrapper">
                    <img src="images/itc-logo.png" srcSet="images/itc-logo.png 1x, images/itc-logo@2x.png 2x, images/itc-logo@3x.png 3x, images/itc-logo@4x.png 4x" alt=""className="footerlogo" />
                    <p>© 2017-2022 Women and Trade Programme - ITC All Rights reserved.</p>
                    <span className="footerbuttons">
                      {/* <Link to="/publiccontactus" className="btn">
                        <i className="fal fa-life-ring" />Get help
                        </Link> */}
                      <Link to="to" className="btn">report abuse</Link>
                      </span>
                  </div>
                </footer>
                {/*footer */}
                ){'}'}
              </div> )}
        </div>
      </CustomizedDocumentTitle>
    );
  }
}

Blog.propTypes = {
  showPreloader: PropTypes.bool,
};

export default connect(mapStateToProps)(Blog);
