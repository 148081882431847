import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import classNames from 'classnames';
import DraggableFilePreview from './components/DraggableFilePreview';

class FilesInput extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array,
    imagesOnly: PropTypes.bool,
    inputAttrs: PropTypes.object,
    sortable: PropTypes.bool,
    t: PropTypes.object,
  };

  static defaultProps = {
    value: [],
    sortable: true,
  };

  constructor(props) {
    super(props);
    this.onReorder = this.onReorder.bind(this);
  }

  removeFile(name) {
    const { onChange, value } = this.props;
    let index;

    for (let i = 0; i < value.length; i += 1) {
      if (value[i].name === name) {
        index = i;
      }
    }

    if (index !== undefined) {
      onChange([...value.slice(0, index), ...value.slice(index + 1)]);
    }
  }

  onDrop(droppedFiles) {
    const { value, maxCount, onChange } = this.props;
    const newFiles = _.uniqWith(
      [...value, ...droppedFiles],
      (a, b) => a.name === b.name
    );
    const limitedNewFiles = maxCount ? newFiles.slice(0, maxCount) : newFiles;
    onChange(limitedNewFiles);
  }

  onReorder({ source, destination }) {
    if (!destination) return;
    const { value, onChange } = this.props;
    const result = Array.from(value);
    const [removed] = result.splice(source.index, 1);
    result.splice(destination.index, 0, removed);
    onChange(result);
  }

  onFileInputChange(e) {
    if (e.target && e.target.files) {
      this.onDrop(Array.from(e.target.files));
      e.target.value = null;
    }
  }

  render() {
    const { value, imagesOnly, inputAttrs, sortable, t } = this.props;

    let draggableHtml;

    if (value && value.length > 0 && sortable) {
      draggableHtml = (
        <div className='upload-images-draggable'>
          <DragDropContext onDragEnd={this.onReorder}>
            <Droppable droppableId='666'>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {value.map((item, index) => (
                    <Draggable
                      key={item.name}
                      draggableId={item.name}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <DraggableFilePreview
                            isDragging={snapshot.isDragging}
                            name={item.name}
                            preview={item.preview}
                            onIconClick={this.removeFile.bind(this, item.name)}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <div className='upload-images-draggable__caption'>
            <span className='info'>
              {t.common[0]['YOU_CAN_SORT_' + (imagesOnly ? 'IMAGES' : 'FILES')]}
            </span>
          </div>
        </div>
      );
    } else if (value && value.length > 0) {
      draggableHtml = (
        <div className='upload-images-draggable'>
          {value.map((item) => (
            <DraggableFilePreview
              key={item.name}
              name={item.name}
              preview={item.preview}
              onIconClick={this.removeFile.bind(this, item.name)}
            />
          ))}
        </div>
      );
    }

    return (
      <div className={classNames({ 'upload-images': imagesOnly })}>
        {imagesOnly ? (
          <Dropzone
            accept='image/jpeg, image/png'
            onDrop={this.onDrop.bind(this)}
            className='input-with-icon dropzone'
            activeClassName='dropzone_active'
            rejectClassName='dropzone_rejected'
          >
            <div className='input-with-icon__input'>
              {t.common[0].PHOTOS_INFO}
            </div>

            <i className='icon-camera' />
          </Dropzone>
        ) : (
          <p>
            <label className='blue clickable'>
              <i className='icon-file' /> {t.common[0].ATTACH_FILES}
              <input
                type='file'
                className='non-visible'
                onChange={this.onFileInputChange.bind(this)}
                {...inputAttrs}
              />
            </label>{' '}
            {t.common[0].DOCUMENTS_CERTIFICATES}
          </p>
        )}

        {draggableHtml}
      </div>
    );
  }
}

const getListStyle = (isDraggingOver) => null;

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
});

const mapStateToProps = (state) => ({
  t: state.language.t,
});

export default connect(mapStateToProps)(FilesInput);
