import React, { Fragment } from 'react';
import lifecycle from 'react-pure-lifecycle';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Page from '../components/Page';
import Detail from '../components/Detail';
import Forum from '../components/Forum';
import DefaultCards from '../components/Page/components/Cards';
import EventCards from '../pages/Events/components/Cards';
import EventDetails from '../pages/Events/components/Details';
import ButtonBookmark from '../components/ButtonBookmark';
import ButtonAbuseReport from '../components/ButtonAbuseReport';
import tenders from '../data/tenders.json';
import events from '../data/events.json';
import topics from '../data/forums.json';
import offers from '../data/offers.json';
import members from '../data/members.json';
import tenderDetail from '../data/tender.json';
import offerDetail from '../data/offer.json';
import forumDetail from '../data/forum.json';
import eventDetail from '../data/event.json';
import memberDetail from '../data/member.json';
import queryString from 'query-string';
import Preloader from '../components/Preloader';
import TopMessage from '../components/TopMessage';
import { renderMembers } from '../pages/Members';
import {
  fetchSearchResult,
  fetchMemberDetails,
  addContact,
  updateContact,
  onMemberMessageClose,
} from '../store/actions';
import languages from '../helpers/languages';

var language = languages['en'];
var common = language.common[0];
var dashboard = language.dashboard[0];
var menu = language.menu[0];

const mapStateToProps = (state) => ({
  query: state.search.query,
  cards: state.searchResult.search,
  totalItems: state.searchResult.totalItems,
  sortLabel: state.searchResult.sortLabel,
  showMessage: state.searchResult.messageShow,
  search: state.search,
  isMobile: state.responsive.isMobile,
  isFetching: state.searchResult.isFetching,
  lan: state.language.lan,
});

const mapDispatchToProps = {
  fetchSearchResult,
  addContact,
  updateContact,
  onMemberMessageClose,
};

const methods = {
  componentDidMount(props) {
    props.fetchSearchResult(window.location.hash, props.search);
  },
};

const PageSearch = (props) => {
  language = languages[props.lan];
  common = language.common[0];
  dashboard = language.dashboard[0];
  menu = language.menu[0];
 
  const toolbar = {
    viewTemplate: 'buttons',
    tabs: {
      values: {
        members: {
          text: common.INDIVIDUALS,
          singleName: common.MEMBERS,
          renderBeforeToolbar: null,
          additionalButtons: null,
          renderCards: (cards) =>
            renderMembers(cards, {
              detailFunction: fetchMemberDetails,
              addContactFunction: props.addContact,
              updateContactFunction: props.updateContact,
              pageType: 'search',
              mini: true,
            }),
          topFilters: false,
          sort: null,
          cards: members,
          detail: memberDetail,
          itemsPerPage: 6,
        },
        tenders: {
          text: common.TENDERS,
          singleName: common.TENDERS,
          renderBeforeToolbar: null,
          additionalButtons() {
            return <ButtonBookmark />;
          },
          renderCards: DefaultCards,
          topFilters: false,
          sort: 'sortDefault',
          cards: tenders,
          detail: tenderDetail,
          itemsPerPage: 9,
        },
        offers: {
          text: common.OFFERS,
          singleName: "offers",
          renderBeforeToolbar: null,
          additionalButtons() {
            return <ButtonBookmark />;
          },
          renderCards: DefaultCards,
          topFilters: false,
          sort: 'sortDefault',
          cards: offers,
          detail: offerDetail,
          itemsPerPage: 9,
        },
        events: {
          text: common.EVENTS,
          singleName: common.EVENTS,
          renderBeforeToolbar: null,
          additionalButtons: null,
          renderCards: EventCards,
          topFilters: false,
          sort: null,
          cards: events,
          detail: eventDetail,
          itemsPerPage: 9,
        },
      },
    },
    sortDefault: [
      { orderBy: 'value', order: 'ASC', label: common.VALUE_LOW_TO_HIGH },
      { orderBy: 'value', order: 'DESC', label: common.VALUE_HIGH_TO_LOW },
      { orderBy: 'date', order: 'ASC', label: common.OLDEST },
      { orderBy: 'date', order: 'DESC', label: common.RECENT },
    ],
    sortTopics: [
      { orderBy: 'date', order: 'ASC', label: common.OLDEST },
      { orderBy: 'date', order: 'DESC', label: common.RECENT },
    ],
  };
  const { location, isFetching } = props;
  const queryObject = queryString.parse(location.search);
  const { query } = queryObject;

  const defaultTab = 'members';
  const { tab } = queryObject;
  const tabIsDefault =
    !('tab' in queryObject) || queryObject.tab === defaultTab;
  const { values } = toolbar.tabs;

  const group = location.pathname.split('/').pop();
  const {
    renderBeforeToolbar,
    topFilters,
    additionalButtons,
    renderCards,
  } = tabIsDefault ? values[defaultTab] : values[tab];
  const { cards, totalItems } = props;
  const { singleName, detail } = values[group] || { singleName: undefined };
  var tabSel = !('tab' in queryObject) ? defaultTab : queryObject.tab;
  const { itemsPerPage } = toolbar.tabs.values[tabSel];

  return (
    <Fragment>
      <Page
        name={`${common.SEARCH_RESULT} "${query ? query : ''}"`}
        canCreateNew={false}
        singleName={singleName}
        queryBack={`tab=${group}`}
        // topfilters={topFilters}
        hasLeftPanel={false}
        renderBeforeToolbar={renderBeforeToolbar}
        toolbar={toolbar}
        isSearchPage={'searchResult'}
        fetchFunction={props.fetchSearchResult}
        cardsPerPage={itemsPerPage}
        totalItems={totalItems}
        sortLabel={props.sortLabel}
        pageName={'search'}
        search={props.search}
        cards={cards.length > 0 &&
          cards.map((el) => ({
          ...el,
          queryParent: tab || defaultTab,
          queryMode: true,
          showId: true,
          renderAdditionalButtons: additionalButtons,
        }))}
        {...props}
        renderCards={(cards, filter, page, cardsPerPage) => {
          if (isFetching) {
            return <Preloader size='medium' relative />;
          }
          return renderCards(cards, filter, page, cardsPerPage, location);
        }}
        renderDetail={(match) => {
          switch (group) {
            case 'topics':
              return <Forum {...detail} />;
            case 'events':
              return EventDetails(match);
            default:
              return <Detail singleName={singleName} {...detail} />;
          }
        }}
      />
      <TopMessage
        isShown={props.showMessage}
        onCloseClick={() => props.onMemberMessageClose(false)}
        type='success'
        text={common.YOU_HAVE_SUCCESSFULLY_SEND_REQUEST}
      />
    </Fragment>
  );
};

PageSearch.propTypes = {
  location: PropTypes.object.isRequired,
};

PageSearch.defaultProps = {
  cards: [],
};

const PageSearchCycle = lifecycle(methods)(PageSearch);
const List = connect(mapStateToProps, mapDispatchToProps)(PageSearchCycle);

export default List;
