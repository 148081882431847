import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import socialShare from '../data/social-share.json';

const Share = (props) => (
  <div className={classNames('sharethis', { share_inline: props.inline })}>
    <p>{props.t.common[0].SHARE_POST}:</p>
    <p className="sharethis_socialicons">
      {socialShare.links.map((el, i) => (
        <a
          target='_blank' rel='noreferrer'
          key={i}
          href={el.apiLink.concat(
            encodeURIComponent(props.pageUrl) + '&text='.concat(props.pageTitle)
          )}
        >
          <i className={`fab fa-${el.label}`} />
        </a>
      ))}
    </p>
  </div>
);

Share.propTypes = {
  inline: PropTypes.bool,
  pageUrl: PropTypes.string,
  pageTitle: PropTypes.string,
  image: PropTypes.string,
  t: PropTypes.object,
};

Share.defaultProps = {
  inline: false,
  pageUrl: null,
  pageTitle: null,
  image: null,
};

const mapStateToProps = (state) => ({
  t: state.language.t,
});

export default connect(mapStateToProps)(Share);
