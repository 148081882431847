import React, { Fragment } from 'react';
import lifecycle from 'react-pure-lifecycle';
import { connect } from 'react-redux';
import Page from '../components/Page';
import Member from '../components/Member';
import NoDataCard from '../components/Card/NoDataCard';
import CardOfMember from '../components/CardOfMember';
import TopMessage from '../components/TopMessage';
import {
  fetchCoaches,
  fetchMemberDetails,
  beCoached,
  fetchCoachStatus,
  becomeCoach,
  fetchReviews,
  addReview,
  onMessageClose,
} from '../store/actions';
import Modal from '../components/Modal';

const toolbar = ({ t }) => ({
  tabs: {
    values: {
      all: { text: t.coaching[0].ALL_COACHES },
      my: { text: t.coaching[0].MY_COACHES },
      mentee: { text: t.coaching[0].MY_MENTEES },
    },
    filterByKeys: (card) => [card.type],
  },
  sort: [
    { orderBy: 'date', order: 'ASC', label: t.common[0].OLDEST },
    { orderBy: 'date', order: 'DESC', label: t.common[0].RECENT },
  ],
});

const mapStateToProps = (state) => {
  return {
    cards: state.coaches.coaches,
    showMessage: state.coaches.messageShow,
    totalItems: state.coaches.totalItems,
    sortLabel: state.coaches.sortLabel,
    detail: state.members.memberDetails,
    CoachStatus: state.coaches.CoachStatus,
    reviews: state.members.reviews,
    totalReviews: state.members.totalReviews,
    userRole: state.auth.user.userRole,
    t: state.language.t,
  };
};
const mapDispatchToProps = {
  fetchCoaches,
  fetchMemberDetails,
  beCoached,
  fetchCoachStatus,
  becomeCoach,
  fetchReviews,
  addReview,
  onMessageClose,
};

const methods = {
  componentDidMount(props) {
    props.fetchCoaches(window.location.hash);
    props.fetchCoachStatus();
    var queryParam = window.location.hash.split('/');
    if (queryParam[3] && queryParam[4]) {
      props.fetchMemberDetails(window.location.hash);
      props.fetchReviews(window.location.hash, 0, 1);
    }
  },
};

class BecomeACoach extends React.Component {
  constructor() {
    super();
    this.state = {
      isModalOpened: false,
    };
  }

  setIsModalOpened(isModalOpened) {
    this.setState({
      ...this.state,
      isModalOpened,
    });
  }

  onConfirm() {
    const { onConfirm } = this.props;
    onConfirm();
    this.setIsModalOpened(false);
  }

  render() {
    var roleFlag = false;
    if (
      this.props.userRole == 'member' ||
      typeof this.props.userRole == 'undefined'
    ) {
      roleFlag = false;
    }
    const { CoachStatus, t } = this.props;
    const isTouched = CoachStatus === '1' || CoachStatus === '2';

    let buttonText;
    switch (CoachStatus) {
      case '1':
        buttonText = t.common[0].PENDING;
        break;
      case '2':
        buttonText = t.coaching[0].COACHES;
        break;
      default:
        buttonText = t.coaching[0].BECOME_MENTOR;
    }

    const buttonStyle = {};
    if (isTouched) {
      buttonStyle.pointerEvents = 'none';
    }

    const { isModalOpened } = this.state;

    return (
      <Fragment>
        <a
          href='javascript: void null'
          onClick={() => this.setIsModalOpened(true)}
          className={
            isTouched ? 'button button_gray button_hover-brand' : 'button'
          }
          style={buttonStyle}
        >
          <span className='button__text'>{buttonText}</span>
        </a>

        <Modal
          isOpen={isModalOpened}
          smallOnMobile
          onRequestClose={() => this.setIsModalOpened(false)}
          buttons={{
            left: {
              text: t.common[0].CANCEL,
              className: 'gray',
              onClick: () => this.setIsModalOpened(false),
            },
            right: {
              text: t.common[0].CONFIRM,
              className: 'brand',
              onClick: () => this.onConfirm(),
            },
          }}
        >
          <section className='content content_p-margins modal-content__title'>
            <h4>{t.coaching[0].POPUP_HEADING}</h4>
            <p>{t.coaching[0].POPUP_PARAGRAPH}</p>
          </section>
        </Modal>
      </Fragment>
    );
  }
}

const PageCoaching = (props) => {
  // eslint-disable-next-line no-shadow

  const { cards, totalItems, detail, CoachStatus, reviews, status, t } = props;

  return (
    <Page
      name={t.menu[0].coaching}
      handleIds={true}
      canCreateNew={false}
      renderAfterTitle={() => (
        <div className='after-title flex'>
          <div className='left'>
            <p className='gray'>{t.coaching[0].TOP_MESSAGE}</p>
          </div>

          <div className='right'>
            <div className='buttons'>
              <BecomeACoach
                CoachStatus={CoachStatus}
                onConfirm={props.becomeCoach}
              />
            </div>
          </div>
        </div>
      )}
      toolbar={toolbar({ t })}
      cards={cards}
      fetchFunction={props.fetchCoaches}
      totalItems={totalItems}
      sortLabel={props.sortLabel}
      // eslint-disable-next-line no-shadow
      renderCards={(cards, filter, page, cardsPerPage) => (
        <div className='cards-list'>
          {cards.length > 0 &&
            cards.map((card, i) => (
              <CardOfMember
                key={card.id || i}
                coaching='1'
                detailUrl='coaching'
                {...card}
                addContactFunction={props.beCoached}
                pageType='coaching'
              />
            ))}
          {cards.length == 0 && <NoDataCard />}
        </div>
      )}
      cardsPerPage={6}
      renderAfterPagination={() => (
        <Fragment>
          <section className='verification-text row'>
            <div className='col-md-6'>
              <div className='content content_p-margins'>
                <h3>{t.coaching[0].SHARING_EXPERIENCE}</h3>
              </div>
            </div>

            <div className='col-md-6'>
              <div className='content content_p-margins'>
                <p>{t.coaching[0].SHARING_ANSWER}</p>
              </div>
            </div>
          </section>

          <section className='verification-text row'>
            <div className='col-md-6'>
              <div className='content content_p-margins'>
                <h3>{t.coaching[0].COACH_QUESTION}</h3>
              </div>
            </div>

            <div className='col-md-6'>
              <div className='content content_p-margins'>
                <p>{t.coaching[0].COACH_ANSWER}</p>
              </div>

              <div className='buttons'>
                <BecomeACoach
                  CoachStatus={CoachStatus}
                  onConfirm={props.becomeCoach}
                  t={t}
                />
              </div>
            </div>
          </section>

          <TopMessage
            isShown={props.showMessage}
            onCloseClick={() => props.onMessageClose(false)}
            type='success'
            text={t.coaching[0].AFTER_SUBMIT}
          />
        </Fragment>
      )}
      {...props}
      renderDetail={(match) => (
        <Member
          {...detail}
          coaching='1'
          addContactFunction={props.beCoached}
          pageType='CoachingDetails'
          reviews={reviews}
          totalReviews={props.totalReviews}
          fetchReviewsFunction={props.fetchReviews}
          addReviewFunction={props.addReview}
        />
      )}
    />
  );
};

PageCoaching.defaultProps = {
  cards: [],
};

const PageCoachingCycle = lifecycle(methods)(PageCoaching);
const List = connect(mapStateToProps, mapDispatchToProps)(PageCoachingCycle);

export default List;
