import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { asField } from 'informed';
import Select from 'react-select';

import { connect } from 'react-redux';

import Input from '../../Input';

const defaultValue = {
  selectedOption: {},
  fields: {},
};

class KeyValueInput extends React.Component {
  static propTypes = {
    keys: PropTypes.array,
    fieldApi: PropTypes.object,
    fieldState: PropTypes.object,
  };

  onSelectChange(selectedOption) {
    const {
      fieldApi: { setValue },
      fieldState: { value = defaultValue },
    } = this.props;
    setValue({
      ...value,
      selectedOption,
    });
  }

  onButtonClick() {
    const {
      fieldApi: { setValue },
      fieldState: { value = defaultValue },
    } = this.props;
    const { selectedOption, fields } = value;
    const { label, value: optionValue } = selectedOption;

    if (!optionValue) return;

    setValue({
      selectedOption: {},
      fields: {
        ...fields,
        [optionValue]: {
          label,
        },
      },
    });
  }

  onFieldChange(e, key) {
    const {
      fieldApi: { setValue },
      fieldState: { value = defaultValue },
    } = this.props;
    setValue({
      ...value,
      fields: {
        ...value.fields,
        [key]: {
          ...value.fields[key],
          value: e.target.value,
        },
      },
    });
  }

  onFieldRemoveClick(key) {
    const {
      fieldApi: { setValue },
      fieldState: { value = defaultValue },
    } = this.props;
    const { fields } = value;
    const { [key]: fieldToRemove, ...restFields } = fields;
    setValue({
      ...value,
      fields: {
        ...restFields,
      },
    });
  }

  getNotEngagedOptions() {
    const {
      fieldState: { value = defaultValue },
      keys,
    } = this.props;
    const { fields } = value;
    const engagedKeys = Object.keys(fields);
    return keys.filter(
      ({ value: optionValue }) => !engagedKeys.includes(optionValue)
    );
  }

  render() {
    const {
      fieldState: { value = defaultValue },
      t,
    } = this.props;
    const { selectedOption, fields } = value;
    const { value: selectedOptionValue } = selectedOption || {};
    const options = this.getNotEngagedOptions();

    return (
      <Fragment>
        <div className='row'>
          <div className='col-7 col-md-9'>
            <div className='input input_no-margin'>
              <Select
                value={selectedOptionValue}
                placeholder={t.createOffer[0].ENTER_FEATURE_NAME}
                onChange={this.onSelectChange.bind(this)}
                options={options}
                searchable
                className='inputbox'
              />
            </div>
          </div>

          <div className='col-5 col-md-3'>
            <button
              type='button'
              className='button button_border button_gray button_with-input'
              onClick={this.onButtonClick.bind(this)}
            >
              <i className='icon-plus'></i>
              <span className='button__text'>{t.common[0].ADD}</span>
            </button>
          </div>
        </div>

        <div className='inputs'>
          {Object.entries(fields).map(([key, { label, value }]) => (
            <div className='array-input-item array-input-item_margin' key={key}>
              <Input
                id={key}
                label={label}
                placeholder={t.createOffer[0].ENTER_FEATURE_VALUE}
              />

              <i
                className='icon-close'
                onClick={this.onFieldRemoveClick.bind(this, key)}
              ></i>
            </div>
          ))}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  t: state.language.t,
});

export default connect(mapStateToProps)(asField(KeyValueInput));
