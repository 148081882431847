export default function (data, tab, language) {
  var errors = {};
  var isThereError = false;
  var fieldError = {};
  var validation = language.forum_validation[0];

  if (data[tab] !== undefined) {
    var values = data[tab]['values'];

    if (
      !values.hasOwnProperty('name') ||
      values.name === '' ||
      values.name === undefined ||
      values.name.trim() === ''
    ) {
      errors.name = [validation.TOPIC_ERROR];
    }

    if (
      !values.hasOwnProperty('description') ||
      values.description === '' ||
      values.description === undefined ||
      values.description.trim() === ''
    ) {
      errors.description = [validation.DESCRIPTION_ERROR];
    }
    if (
      !values.hasOwnProperty('forumMembers') ||
      values.forumMembers === '' ||
      values.forumMembers === undefined
    ) {
      errors.forumMembers = [validation.OPTION_ERROR];
    }

    if (
      !values.hasOwnProperty('forums') ||
      values.forums === '' ||
      values.forums === undefined
    ) {
      errors.forums = [validation.TAG_ERROR];
    }
  }
  isThereError = isEmpty(errors);

  var returnData = {};

  returnData.errors = errors;
  returnData.hasError = isThereError;

  if (returnData.hasError) {
    returnData.message = validation.COMMNON_ERROR;
  } else {
    returnData.message = '';
  }

  return returnData;
}

function isEmpty(data) {
  for (var item in data) {
    return true;
  }

  return false;
}
