import React, { Fragment } from 'react';
import lifecycle from 'react-pure-lifecycle';
import { connect } from 'react-redux';
import {
  ROUTE_FETCH_COUNTRIES,
  ROUTE_FETCH_PRODUCTS,
  ROUTE_FETCH_SERVICES
} from '../../../../components/settings/DropdownTree/fetchDropdownTrees';
import Input from '../../../../components/settings/Input';
import Tab from '../../../../components/settings/Tab';
import languages from '../../../../helpers/languages';
import {
  fetchMyCommitments, fetchSettingsServices, fetchVerifiersList, saveProfileServicesData, searchCountries
} from '../../../../store/actions';


var language = languages['en'];
var common = language.common[0];
var detail_members = language.detail_members[0];
var registration = language.registration[0];
var validation = language.registration_validation[0];

const mapStateToProps = (state) => {
  if (state.setting.hasOwnProperty('setData')) {
    if (state.setting.setData.length > 0) {
      var verifiers = [];
      var i = 0;
      for (var item in state.setting.setData) {
        verifiers[i] = {};
        verifiers[i]['dataId'] = state.setting.setData[item].dataId;
        verifiers[i]['type'] = state.setting.setData[item].type;
        verifiers[i]['name'] = state.setting.setData[item].name;
        verifiers[i]['avatar'] = state.setting.setData[item].filePath;
        verifiers[i]['verified'] =
          state.setting.setData[item].verificationRequested;
        i++;
      }
    } else {
      var verifiers = [];
    }
  } else {
    var verifiers = [];
  }

  return {
    user: state.auth.user,
    defaultData: verifiers,
    userType: state.auth.user.userRole, // TODO: check API
    serviceData: state.setting.serviceData,
    commitments: state.setting.setCommitments,
    setCertifications: state.setting.setCertificates,
    lan: state.language.lan,
  };
};

const mapDispatchToProps = {
  fetchVerifiersList,
  searchCountries,
  fetchSettingsServices,
  fetchMyCommitments,
  saveProfileServicesData,
};

const methods = {
  componentDidMount(props) {
    props.fetchVerifiersList();
    props.searchCountries();
    props.fetchSettingsServices();
    props.fetchMyCommitments();
  },
};

const commitments = {
  marginTop: '30px',
};

const Interest = (props) => {
  language = languages[props.lan];
  common = language.common[0];
  detail_members = language.detail_members[0];
  registration = language.registration[0];
  validation = language.registration_validation[0];
  return (
    <Tab
      title={registration.PRODUCT_SERVICES_INFO}
      showPreloader={!props.serviceData}
      formProps={{
        initialValues: props.serviceData && {
          countries_export: props.serviceData.hasOwnProperty('exportTo')
            ? props.serviceData.exportTo
            : '',
          countries_import: props.serviceData.hasOwnProperty('exportFrom')
            ? props.serviceData.exportFrom
            : '',
          products_selling: props.serviceData.hasOwnProperty(
            'productdataSelling'
          )
            ? props.serviceData.productdataSelling
            : '',
          products_looking_for: props.serviceData.hasOwnProperty(
            'productdataLooking'
          )
            ? props.serviceData.productdataLooking
            : '',
          services_offering: props.serviceData.hasOwnProperty('serviceOffering')
            ? props.serviceData.serviceOffering
            : '',
          services_looking_for: props.serviceData.hasOwnProperty(
            'servicesLooking'
          )
            ? props.serviceData.servicesLooking
            : '',
          certifications: props.serviceData.hasOwnProperty('certificates')
            ? props.serviceData.certificates
            : '',
        },
        onSubmit: (values) => {
          props.saveProfileServicesData(values);
        },
      }}
      renderContents={({ formState }) => (
        <Fragment>
        
          <div className='inputs-group'>
            <div className='inputs-group__title'>
              <p className='gray'>What I am interested</p>
            </div>

            <div className='inputs'>
              <Input
                id='countries_interest'
                label='Countries I am Interested In'
                placeholder={registration.COUNTRY_PLACEHOLDER}
                type='dropdown-tree'
                dropdownTreeOptions={{
                  route: ROUTE_FETCH_COUNTRIES,
                  position: 'top',
                  defaultOpenedToplevel: ['ASIA'],
                }}
              />

                <Input
                  id='sectors_interest'
                  label='Sectors I am interested in'
                  required={props.userType === 'seller' ? true : false}
                  placeholder={common.CATEGORY_NAME_NUMBER_PLACEHOLDER}
                  type='dropdown-tree'
                  dropdownTreeOptions={{
                    route: ROUTE_FETCH_PRODUCTS,
                    position: 'top',
                  }}
                  informedProps={{
                  }}
                  hasError={'products_selling' in formState.errors}
                  errors={formState.errors.products_selling}
            />
            </div>
          </div>
        </Fragment>
      )}
    />
  );
};

const InterestCycle = lifecycle(methods)(Interest);
const List = connect(
  mapStateToProps,
  mapDispatchToProps
)(InterestCycle);
export default List;
