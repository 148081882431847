import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { pageTransition } from '../constants';
import AboutUs from '../pages/AboutUs';
import AllPartners from '../pages/AllPartners';
import Blog from '../pages/Blog';
import BusinessCircle from '../pages/BusinessCircle';
import coaching from '../pages/coaching';
import Commitments from '../pages/Commitments';
import Community from '../pages/Community';
import CompanyRegistration from '../pages/CompanyRegistration';
import CompleteRegistration from '../pages/CompleteRegistration';
import Cookies from '../pages/Cookies';
import Discover from '../pages/Discover';
import Events from '../pages/Events';
import Faq from '../pages/Faq';
import HelpCenter from '../pages/HelpCenter';
import Hub from '../pages/Hub';
import Hubs from '../pages/Hubs';
import Invest from '../pages/Invest';
import InviteFriend from '../pages/InviteFriend';
import Learn from '../pages/Learn';
import MarketTools from '../pages/MarketTools';
import Members from '../pages/Members';
import Messenger from '../pages/Messenger';
import MyFavorites from '../pages/MyFavorites';
import News from '../pages/News';
import Partner from '../pages/Partner';
import Partners from '../pages/Partners';
import ProductPage from '../pages/Product';
import Project from '../pages/Project';
import Projects from '../pages/Projects';
import Publications from '../pages/Publications';
import Registration from '../pages/Registration';
import RequestContacts from '../pages/RequestContacts';
import sat from '../pages/Sat';
import Search from '../pages/Search';
import Settings from '../pages/Settings';
import SuccessStory from '../pages/SuccessStory';
import Verification from '../pages/Verification';
import Video from '../pages/Video';
import Webinars from '../pages/Webinars';
import Dashboard from './Dashboard/Main';
import DashboardManage from './Dashboard/Manage';
import PageNotFound from './PageNotFound';
import PageUnderMaintenance from './PageUnderMaintenance ';
import Profile from './Profile';
import Forums from  '../pages/Forums/Forums';
import BuySell from '../pages/Offers';
import MyNetwork from '../pages/MyNetwork';
import Companies from '../pages/Companies';

const mapStateToProps = (state) => ({
  isMobile: state.responsive.isMobile,
  isLandscape: state.responsive.isLandscape,
});

class ProtectedApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpened: false,
    };
  }

  toggleMenu(isMenuOpened) {
    const { isMobile } = this.props;
    if (isMobile) {
      this.setState({
        isMenuOpened,
      });
    }
  }

  render() {
    const { props } = this;
    return (
      <div>
        <Switch>
          <Route
            render={() => (
              <Fragment>
                <TransitionGroup className='transition-container'>
                  <CSSTransition
                    key={props.location.pathname}
                    {...pageTransition}
                    onEnter={() =>
                      setTimeout(() => {
                        window.scrollTo(0, 0);
                      }, 400)
                    }
                  >
                    <Switch location={props.location}>

                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/'
                        component={Dashboard}
                      />
                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/aboutus'
                        component={AboutUs}
                      />

                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/discover'
                        component={Discover}
                      />
                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/hubs'
                        component={Hubs}
                      />
                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/hub/:id'
                        component={Hub}
                      />
                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/learn'
                        component={Learn}
                      />

                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/en/learn/e-learning'
                        component={Learn}
                      />

                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/product/:id'
                        component={ProductPage}
                      />

                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/partners'
                        component={Partners}
                      />
                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/projects'
                        component={Projects}
                      />
                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/helpcenter'
                        component={HelpCenter}
                      />
                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/community'
                        component={Community}
                      />

                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/allpartners'
                        component={AllPartners}
                      />
                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/partner/:id'
                        component={Partner}
                      />

                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/hub'
                        component={Hub}
                      />

                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/project/:id'
                        component={Project}
                      />
                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/partner'
                        component={Partner}
                      />

                      {/* <Route
                        basename={process.env.BASE_ROUTE} path='/policy'
                        component={() => {
                          window.location.href = '/policy';
                          return null;
                        }}
                      /> */}
                      {/* <Route
                        basename={process.env.BASE_ROUTE} path='/terms'
                        component={() => {
                          window.location.href = '/terms';
                          return null;
                        }}
                      /> */}
                      <Route
                        basename={process.env.BASE_ROUTE} path='/outlook'
                        component={() => {
                          window.location.href = 'https://www.shetrades.com/outlook/home';
                          return null;
                        }}
                      />

                      <Route
                        basename={process.env.BASE_ROUTE} path='/youtube'
                        component={() => {
                          window.location.href = 'https://www.youtube.com/c/Exportimpactforgood';
                          return null;
                        }}
                      />
                      <Route
                        basename={process.env.BASE_ROUTE} path='/facebook'
                        component={() => {
                          window.location.href = 'https://www.facebook.com/ITCSheTrades';
                          return null;
                        }}
                      />
                      <Route
                        basename={process.env.BASE_ROUTE} path='/twitter'
                        component={() => {
                          window.location.href = 'https://twitter.com/ITCnews';
                          return null;
                        }}
                      />
                      <Route
                        basename={process.env.BASE_ROUTE} path='/linkedin'
                        component={() => {
                          window.location.href = 'https://www.linkedin.com/company/international-trade-centre/';
                          return null;
                        }}
                      />

                      <Route basename={process.env.BASE_ROUTE} path='/news' component={News} />

                      <Route exact basename={process.env.BASE_ROUTE} path='/cookies' component={Cookies} />
                      <Route exact basename={process.env.BASE_ROUTE} path='/f-a-q' component={Faq} />
                      
                      <Route basename={process.env.BASE_ROUTE} path='/opportunities' component={PageUnderMaintenance} />

                      <Route basename={process.env.BASE_ROUTE} path='/offers' component={BuySell} />

                      <Route basename={process.env.BASE_ROUTE} path='/forums' component={Forums} />

                      <Route basename={process.env.BASE_ROUTE} path='/events' component={Events} />

                      <Route basename={process.env.BASE_ROUTE} path='/video' component={Video} />

                      <Route basename={process.env.BASE_ROUTE} path='/verification' component={Verification} />

                      <Route
                        basename={process.env.BASE_ROUTE} path='/publications'
                        component={Publications}
                      />

                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/search'
                        component={Search}
                      />
                   
                      <Route
                        basename={process.env.BASE_ROUTE} path='/success-stories'
                        component={SuccessStory}
                      />

                      <Route basename={process.env.BASE_ROUTE} path='/members' component={Members} />

                      <Route
                        basename={process.env.BASE_ROUTE} path='/companies'
                        component={Companies}
                      />

                      <Route
                        basename={process.env.BASE_ROUTE} path='/partners'
                        component={Verification}
                      />

                      <Route
                        basename={process.env.BASE_ROUTE} path='/my-network'
                        component={MyNetwork}
                      />

                      <Route
                        basename={process.env.BASE_ROUTE} path='/request_contacts'
                        component={RequestContacts}
                      />

                      <Route basename={process.env.BASE_ROUTE} path='/invitefriend' component={InviteFriend} />

                      <Route
                        basename={process.env.BASE_ROUTE} path='/my-favorites'
                        component={MyFavorites}
                      />

                      <Route
                        basename={process.env.BASE_ROUTE} path='/business-circle'
                        component={BusinessCircle}
                      />

                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/dashboard'
                        component={Dashboard}
                      />

                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/dashboard/manage'
                        component={DashboardManage}
                      />

                      <Route
                        basename={process.env.BASE_ROUTE} path='/publictools'
                        component={MarketTools}
                      />
                      <Route
                        basename={process.env.BASE_ROUTE} path='/invest'
                        component={Invest}
                      />

                      <Route
                        basename={process.env.BASE_ROUTE} path='/webinars'
                        component={Webinars}
                      />

                      <Route
                        basename={process.env.BASE_ROUTE} path='/coaching'
                        component={coaching}
                      />

                      <Route
                        basename={process.env.BASE_ROUTE} path='/sat'
                        component={sat}
                      />

                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/settings'
                        component={Settings}
                      />

                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/messenger'
                        component={Messenger}
                      />

                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/commitments'
                        component={Commitments}
                      />

                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/blog'
                        component={Blog}
                      />

                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/profile'
                        component={Profile}
                      />

                      {/* <Route exact basename={process.env.BASE_ROUTE} path='/terms' component={terms}/> */}

                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/companyregistration'
                        component={CompanyRegistration}
                      />

                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/registration/buyer'
                        render={() => <Registration as='Buyer' />}
                      />

                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/registration/seller'
                        render={() => <Registration as='Seller' />}
                      />

                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/registration/verifier'
                        render={() => <Registration as='Verifier' />}
                      />


                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/completeregistration/business'
                        render={() => <CompleteRegistration as='Business' />}
                      />

                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/completeregistration/wob'
                        render={() => <CompleteRegistration as='WOB' />}
                      />

                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/completeregistration/partner'
                        render={() => <CompleteRegistration as='Partner' />}
                      />


                      <Route
                        exact
                        basename={process.env.BASE_ROUTE} path='/completeregistration/team'
                        render={() => <CompleteRegistration as='Team' />}
                      />

                      <Route
                        basename={process.env.BASE_ROUTE} path='/adminpanel'
                        component={() => {
                          window.location.href = '/admin/home';
                          return null;
                        }}
                      />

                      <Route
                        basename={process.env.BASE_ROUTE} path='/satforwarder'
                        component={() => {
                          window.location.href = 'https://learn.shetrades.com/course/info.php?id=1555';
                          return null;
                        }}
                      />

                      <Route component={PageNotFound} />
                    </Switch>
                  </CSSTransition>
                </TransitionGroup>
              </Fragment>
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(ProtectedApp));
