import React from 'react';
import ReactSelect from 'react-select';
import find from 'lodash/find';

import InputBlock from './InputBlock';

const customStyles = {
  control: base => ({
    ...base,
    height: 35,
    minHeight: 35
  })
};

class Select extends React.Component {
  render() {
    const { options, ...restProps } = this.props;
    return (
      <InputBlock {...restProps}>
        {({ value, onChange, toggleFocus }) => (
          <ReactSelect
            value={find(options, (option) => option.value === value)}
            onChange={(option) =>
              onChange({
                target: {
                  name: restProps.inputAttrs.name,
                  value: (option && option.value) || '',
                },
              })
            }
            options={options}
            onFocus={toggleFocus.bind(true)}
            onBlur={() => toggleFocus(!!value)}
            placeholder={restProps.inputAttrs.placeholder}
            required={restProps.inputAttrs.required}
            styles={customStyles}
            classNamePrefix="custom-select"
          />
        )}
      </InputBlock>
    );
  }
}

export default Select;
