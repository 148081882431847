import React from 'react';
import PropTypes from 'prop-types';
import formatNumber from '../helpers/format-number';

const Chart = (props) => {
  const {
    title,
    number,
    changePeriod,
    changePercentage,
    valuePrefix,
    valuePostfix,
  } = props;

  const percentageToShow = Math.abs(changePercentage).toFixed(1);

  return (
    <div className='chart'>
      <div className='chart__top'>
        <div className='chart__title'>
          <h4>{title}</h4>
        </div>

        <div className='chart__value'>
          <span className='h2'>
            {valuePrefix && `${valuePrefix} `}
            {formatNumber({ number })}
            {valuePostfix && ` ${valuePostfix}`}
          </span>
        </div>
      </div>
      {changePeriod && (
        <div className='chart__bottom'>
          <div className='chart__period'>{changePeriod}</div>

          <div className='chart__percentage'>
            {changePercentage > 0 ? (
              <span className='profit'>+ {percentageToShow} %</span>
            ) : changePercentage < 0 ? (
              <span className='loss'>- {percentageToShow} %</span>
            ) : (
              <span>No change</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

Chart.propTypes = {
  title: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  changePeriod: PropTypes.string,
  changePercentage: PropTypes.number,
  valuePostfix: PropTypes.string,
  valuePrefix: PropTypes.string,
};

export default Chart;
