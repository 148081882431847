import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from '../../requests/platformApi';

import FormHandler from '../form-handler';
import Form from '../forms/forgot';
import GlobalError from '../global-error';
import Layout from '../layout';
import { email } from '../../validations/validations';
import { addFlashMessage } from '../../actions/flash-messages';
import languages from '../../helpers/languages';

function View(props) {
  const regLang = languages[props.lan].registration[0];
  const onSubmitSuccess = (res) => {
    props.addFlashMessage(regLang.BEEN_SENT, 'success');
  };
  return (
    <Layout title={regLang.RESET_YOUR_PASSWORD}>
      <FormHandler
        initialValues={initialValues}
        submitRequest={submitRequest}
        onSubmitSuccess={onSubmitSuccess}
        mapFieldsToValidations={mapFieldsToValidations}
      >
        {(formState, onChange, onSubmit) => (
          <GlobalError
            defaultText={regLang.PLEASE_ENTER_EMAIL}
            error={formState.globalError}
          >
            <Form
              values={formState.values}
              errors={formState.errors}
              onChange={onChange}
              onSubmit={onSubmit}
              buttonDisabled={formState.buttonDisabled}
            />
          </GlobalError>
        )}
      </FormHandler>
    </Layout>
  );
}

View.propTypes = {
  addFlashMessage: PropTypes.func.isRequired,
  lan: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  lan: state.language.lan,
});

const mapDispatchToProps = { addFlashMessage };

export default connect(mapStateToProps, mapDispatchToProps)(View);

const submitRequest = (data) =>
  axios.post('/passwordResetRequest', data).then(
    (response) => response.data,
    ({ response }) => response.data
  );

const initialValues = {
  email: '',
};

const mapFieldsToValidations = {
  email,
};
