import validator from 'validator';
import PasswordValidator from 'password-validator';

export function email(value) {
  if (!validator.isEmail(value)) {
    return 'Enter valid email address';
  }
  return true;
}

export function name(value) {
  if (!validator.isAlpha(value.replace(/\s/g, ''))) {
    return 'Name can contain only alphabet letters and spaces';
  }
  if (value.trim().length < 3) {
    return 'Name include be at least 3 symbols';
  }
  return true;
}

const pv = new PasswordValidator();
pv.is()
  .min(8)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .not()
  .spaces();

  const oldPv = new PasswordValidator();
oldPv.is()
  .min(6)
  .has()
  .not()
  .spaces();

export function password(value) {
  let passwordVal = pv.validate(value) 
  if(passwordVal === false){
    let list = pv.validate(value, { list: true })
    list.forEach( (fail) => {
      if(fail === 'min'){
        return 'Passwords must be at least 8 characters'
      }else if( fail === 'lowercase'){
        return 'Password should contain at least one lowercase characte'
      }else if( fail === 'uppercase'){
        return 'Password must have at least 1 uppercase letter(s)'
      }else if( fail === 'digits'){
        return 'Password should contain at least one digit';
      }else if( fail === 'spaces'){
        return 'Password must not contain spaces';
      }
    })
  }else{
    return
  }
}

export function oldPassword(value) {
  let passwordVal = pv.validate(value) 
  if(passwordVal === false){
    let list = pv.validate(value, { list: true })
    list.forEach( (fail) => {
      if(fail === 'min'){
        return 'Passwords must be at least 8 characters'
      }else if( fail === 'spaces'){
        return 'Password must not contain spaces';
      }
    })
  }else{
    return
  }
}

export function passwordShouldMatch(key) {
  return function (value, values) {
    if (value !== values[key]) {
      return 'Password should match';
    }
    password(value);
    return true;
  };
}
